import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form as SemanticForm } from 'semantic-ui-react';
import Translations from '../../helpers/Translations';
import Form from '../../helpers/Form';
import { addNewColleagueContact, removeColleagueContact } from '../../actions/SendEmailActions';

class ColleagueEmailsSelect extends Component {
    onAddContact = (event, data, callback, onSetIncorrectEmailCallback) => {
        data.value.forEach((email) => {
            const exists = data.options.find((option) => option.value === email);

            if (exists === undefined) {
                if (
                    Form.check({
                        fieldType: 'email',
                        fieldValue: email,
                    })
                ) {
                    let formData = new FormData();

                    formData.append('email', email);

                    this.props.addNewColleagueContact(email, formData);
                } else {
                    onSetIncorrectEmailCallback(email);
                }
            }
        });

        callback(data);
    };

    onHandleOptionChange = (event, data, emailsInValue, callback, onSetIncorrectEmailCallback) => {
        onSetIncorrectEmailCallback('');

        if (
            event.target.tagName.toLowerCase() === 'i' &&
            this.props.isRemovingColleagueContact === false
        ) {
            if (data.value.length < emailsInValue.length) {
                callback(data);
            } else if (data.value.length > emailsInValue.length) {
                const emailToDelete = data.value.find(
                    (email) => emailsInValue.indexOf(email) === -1
                );

                if (emailToDelete !== undefined) {
                    let formData = new FormData();

                    formData.append('email', emailToDelete);

                    this.props.removeColleagueContact(emailToDelete, formData);
                }
            }
        } else {
            this.onAddContact(event, data, callback, onSetIncorrectEmailCallback);
        }
    };

    render() {
        const { options, value, className, isAddingNewColleagueContact, error } = this.props;

        return (
            <SemanticForm.Dropdown
                error={error === '' ? false : error}
                className={className}
                icon={{ className: 'hidden' }}
                fluid
                multiple
                search
                selection
                allowAdditions
                disabled={isAddingNewColleagueContact}
                loading={isAddingNewColleagueContact}
                additionLabel={Translations.getStatic('add') + ' '}
                value={value}
                options={options}
                onChange={(e, data) =>
                    this.onHandleOptionChange(
                        e,
                        data,
                        value,
                        this.props.onChange,
                        this.props.onSetIncorrectEmail
                    )
                }
                onSearchChange={() => this.props.onSearchChange()}
            />
        );
    }
}

ColleagueEmailsSelect.propTypes = {
    className: PropTypes.string,

    value: PropTypes.array.isRequired,
    options: PropTypes.array.isRequired,
    error: PropTypes.string.isRequired,

    onChange: PropTypes.func.isRequired,
    onSearchChange: PropTypes.func.isRequired,
    onSetIncorrectEmail: PropTypes.func.isRequired,
};

ColleagueEmailsSelect.defaultProps = {
    className: 'darker darker_small multiple-basic',
};

const mapStateToProps = (state) => ({
    isAddingNewColleagueContact: state.isAddingNewColleagueContact,
    isRemovingColleagueContact: state.isRemovingColleagueContact,
});

const mapDispatchToProps = {
    removeColleagueContact,
    addNewColleagueContact,
};

export default connect(mapStateToProps, mapDispatchToProps)(ColleagueEmailsSelect);
