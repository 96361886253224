import Constants from '../helpers/Constants';
import Storages from '../helpers/Storages';
import {
    DISABLE_INTERNAL_INFO,
    DISABLE_TRANSLATION_KEYS_INFO,
    ENABLE_INTERNAL_INFO,
    ENABLE_TRANSLATION_KEYS_INFO,
} from '../actions/InternalInfoActions';

export const internalInfoModel = (
    state = {
        showTranslationKeysInfo: Storages.getLocal(
            Constants.TRANSLATION_KEYS_INFO_LOCAL_STORAGE_KEY,
            false
        ),
        showInternalInfo: Storages.getLocal(Constants.INTERNAL_INFO_LOCAL_STORAGE_KEY, false),
    },
    action
) => {
    switch (action.type) {
        case ENABLE_INTERNAL_INFO:
            return {
                ...state,
                showInternalInfo: true,
            };
        case DISABLE_INTERNAL_INFO:
            return {
                ...state,
                showInternalInfo: false,
            };
        case ENABLE_TRANSLATION_KEYS_INFO:
            return {
                ...state,
                showTranslationKeysInfo: true,
            };
        case DISABLE_TRANSLATION_KEYS_INFO:
            return {
                ...state,
                showTranslationKeysInfo: false,
            };
        default:
            return state;
    }
};
