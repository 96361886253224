import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import CustomToolbar from './CustomToolbar';
import WysiwygHelper from '../../helpers/components/WysiwygHelper';
import Validation from '../../helpers/Validation';
import { WysiwygHandlePaste } from '../../helpers/WysiwygHandlePaste';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Variables from '../../helpers/Variables';

class WysiwygInput extends React.Component {
    state = {
        editorState: EditorState.createEmpty(),
        firstLoad: true,
    };

    componentDidMount = () => {
        this.prepareEditorState(this.props);
    };

    componentDidUpdate = (prevProps) => {
        if (
            this.state.firstLoad === true ||
            this.props.id !== prevProps.id ||
            this.props.lang !== prevProps.lang
        ) {
            this.prepareEditorState(this.props);
        }
    };

    prepareEditorState = (props) => {
        this.setState({
            firstLoad: false,
            editorState:
                props.value === ''
                    ? EditorState.createEmpty()
                    : WysiwygHelper.createEditorStateFromHtml(
                          props.value,
                          Variables.getEditorVariables(props.variables, false)
                      ),
        });
    };

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState: editorState,
        });

        const text = WysiwygHelper.getHtmlFromEditor(editorState);

        if (text !== this.props.value) {
            this.props.onChange(text);
        }
    };

    render() {
        const toolbarConfig = {
            options: [],
        };

        const { editorState } = this.state;

        return (
            <>
                <Editor
                    editorState={editorState}
                    wrapperClassName={
                        'mark-wysiwyg-input-wrapper subject ' +
                        (this.props.error === true && this.props.highlighted === true
                            ? 'error'
                            : '')
                    }
                    editorClassName="mark-wysiwyg-input-editor"
                    toolbarClassName="mark-wysiwyg-input-toolbar"
                    toolbar={toolbarConfig}
                    toolbarCustomButtons={[
                        <CustomToolbar
                            variables={Variables.getEditorVariables(this.props.variables)}
                            toolbarActions={['variables']}
                        />,
                    ]}
                    customDecorators={WysiwygHelper.getDecorators()}
                    onEditorStateChange={this.onEditorStateChange}
                    handlePastedText={(text, html, editorState, onChange) => {
                        const newEditorState = WysiwygHelper.createEditorStateFromHtml(
                            text,
                            Variables.getEditorVariables(this.props.variables, false)
                        );

                        return WysiwygHandlePaste('', '', newEditorState, onChange);
                    }}
                />
                {this.props.error === true && this.props.highlighted === true && (
                    <div className="prompt label">{Validation.getMessage('empty', 'subject')}</div>
                )}
            </>
        );
    }
}

WysiwygInput.propTypes = {
    id: PropTypes.number.isRequired,
    lang: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    variables: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.bool,
};

WysiwygInput.defaultProps = {
    error: false,
    highlighted: false,
};

export default WysiwygInput;
