import React from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../../actions/ModalActions';
import JobDetailPositionDescriptionModal from '../Modals/JobDetailPositionDescriptionModal';
import JobDetailPositionAutoAnswerModal from '../Modals/JobDetailPositionAutoAnswerModal';
import JobDetailPositionQuestionnaireModal from '../Modals/JobDetailPositionQuestionnaireModal';
import JobCandidatesSourcesReactionLinksModal from '../Modals/JobCandidatesSourcesReactionLinksModal';
import CreateCandidateModal from '../Modals/CreateCandidateModal';
import CopyCandidateModal from '../Modals/CopyCandidateModal';
import SendEmailModal from '../Modals/SendEmailModal';
import ChangeStageOfCandidatesModal from '../Modals/ChangeStageOfCandidatesModal';
import ScheduleCandidateEventModal from '../Modals/ScheduleCandidateEventModal';
import SettingsPageLanguageSettingsModal from '../Modals/SettingsPageLanguageSettingsModal';
import SettingsPageGoogleCalendarSettingsModal from '../Modals/SettingsPageGoogleCalendarSettingsModal';
import SettingsPageMicrosoftCalendarSettingsModal from '../Modals/SettingsPageMicrosoftCalendarSettingsModal';
import SettingsPageForwardEventToEmailSettingsModal from '../Modals/SettingsPageForwardEventToEmailSettingsModal';
import DetailEmailModal from '../Modals/DetailEmailModal';
import DetailHistoryItemModal from '../Modals/DetailHistoryItemModal';
import SettingsPageQuestionnaireEditorModal from '../Modals/SettingsPageQuestionnaireEditorModal';
import SettingsPagePipelineTemplateEditorModal from '../Modals/SettingsPagePipelineTemplateEditorModal';
import SettingsPageAutoAnswerEditorModal from '../Modals/SettingsPageAutoAnswerEditorModal';
import SettingsPageEmailTemplateEditorModal from '../Modals/SettingsPageEmailTemplateEditorModal';
import QuestionnaireTemplatePreviewModal from '../Modals/QuestionnaireTemplatePreviewModal';
import EmailTemplatePreviewModal from '../Modals/EmailTemplatePreviewModal';
import AutoAnswerPreviewModal from '../Modals/AutoAnswerPreviewModal';
import PipelineTemplatePreviewModal from '../Modals/PipelineTemplatePreviewModal';
import SettingsPageNewCandidatesEmailsSettingsModal from '../Modals/SettingsPageNewCandidatesEmailsSettingsModal';
import RefuseCandidateWithEmailModal from '../Modals/RefuseCandidateWithEmailModal';
import StatisticsModal from '../Modals/StatisticsModal';
import ContentPageModal from '../Modals/ContentPageModal';
import SendSimpleEmailModal from '../Modals/SendSimpleEmailModal';
import InviteColleagueModal from '../Modals/InviteColleagueModal';
import CandidateGdprAgreementModal from '../Modals/CandidateGdprAgreementModal';
import ScheduledEmailDetailModal from '../Modals/ScheduledEmailDetailModal';
import CandidatesListColumnsOrderEditorModal from '../Modals/CandidatesListColumnsOrderEditorModal';
import SettingsPageTransformStagesModal from '../Modals/SettingsPageTransformStagesModal';
import RequestCandidateQuestionnaireModal from '../Modals/RequestCandidateQuestionnaireModal';
import CompanyCandidatesGdprExpirationSettingsModal from '../Modals/CompanyCandidatesGdprExpirationSettingsModal';
import CompanyExportJobsSettingsModal from '../Modals/CompanyExportJobsSettingsModal';
import JobCandidatesSourcesRecommendationLinksModal from '../Modals/JobCandidatesSourcesRecommendationLinksModal';
import ReactionIframeModal from '../Modals/ReactionIframeModal';
import JobDetailPositionPipelineTemplateModal from '../Modals/JobDetailPositionPipelineTemplateModal';
import GoogleAnalytics from '../../helpers/GoogleAnalytics';
import Constants from '../../helpers/Constants';
import SettingsPageJobViewSettingsModal from '../Modals/SettingsPageJobViewSettingsModal';
import AddCandidateFromTalentPoolModal from '../Modals/AddCandidateFromTalentPoolModal';
import SettingsPageNotesSettingsModal from '../Modals/SettingsPageNotesSettingsModal';
import SettingsPageOutOfOfficeModal from '../Modals/SettingsPageOutOfOfficeModal';
import MoveCandidateModal from '../Modals/MoveCandidateModal';
import AddTagsModal from '../Modals/AddTagsModal';
import ScheduledMassForwardEmailDetailModal from '../Modals/ScheduledMassForwardEmailDetailModal';
import EditJobAdFormModal from '../Modals/EditJobAdFormModal';

const modalLookup = {
    AddCandidateFromTalentPoolModal,
    AddTagsModal,
    AutoAnswerPreviewModal,
    CandidateGdprAgreementModal,
    CandidatesListColumnsOrderEditorModal,
    ChangeStageOfCandidatesModal,
    CompanyCandidatesGdprExpirationSettingsModal,
    CompanyExportJobsSettingsModal,
    ContentPageModal,
    CopyCandidateModal,
    CreateCandidateModal,
    DetailEmailModal,
    DetailHistoryItemModal,
    EmailTemplatePreviewModal,
    InviteColleagueModal,
    JobCandidatesSourcesReactionLinksModal,
    JobCandidatesSourcesRecommendationLinksModal,
    JobDetailPositionAutoAnswerModal,
    JobDetailPositionDescriptionModal,
    EditJobAdFormModal,
    JobDetailPositionQuestionnaireModal,
    JobDetailPositionPipelineTemplateModal,
    MoveCandidateModal,
    PipelineTemplatePreviewModal,
    QuestionnaireTemplatePreviewModal,
    ReactionIframeModal,
    RefuseCandidateWithEmailModal,
    RequestCandidateQuestionnaireModal,
    ScheduleCandidateEventModal,
    ScheduledEmailDetailModal,
    ScheduledMassForwardEmailDetailModal,
    SendEmailModal,
    SendSimpleEmailModal,
    SettingsPageAutoAnswerEditorModal,
    SettingsPageEmailTemplateEditorModal,
    SettingsPageGoogleCalendarSettingsModal,
    SettingsPageJobViewSettingsModal,
    SettingsPageLanguageSettingsModal,
    SettingsPageMicrosoftCalendarSettingsModal,
    SettingsPageNewCandidatesEmailsSettingsModal,
    SettingsPageNotesSettingsModal,
    SettingsPageOutOfOfficeModal,
    SettingsPagePipelineTemplateEditorModal,
    SettingsPageQuestionnaireEditorModal,
    SettingsPageTransformStagesModal,
    SettingsPageForwardEventToEmailSettingsModal,
    StatisticsModal,
};

class ModalManager extends React.Component {
    state = {
        openingModalType: null,
        openingModalProps: null,
    };

    componentDidMount() {
        document.addEventListener('keydown', this.escFunction, false);
    }

    componentDidUpdate(prevState) {
        if (prevState.currentModal.isOpen === false && this.props.currentModal.isOpen === true) {
            this.setState({
                openingModalType: this.props.currentModal.modalType,
                openingModalProps: this.props.currentModal.modalProps,
            });

            GoogleAnalytics.sendModalView(this.props.currentModal.modalType);
        } else if (
            prevState.currentModal.isOpen === true &&
            this.props.currentModal.isOpen === false
        ) {
            setTimeout(() => {
                this.setState({
                    openingModalType: null,
                    openingModalProps: null,
                });

                if (
                    this.props.currentModal.onClose !== undefined &&
                    typeof this.props.currentModal.onClose === 'function'
                ) {
                    this.props.currentModal.onClose();
                }
            }, Constants.MODAL_ANIMATION_TIME_CLOSE);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.escFunction, false);
    }

    escFunction = (event) => {
        if (event.keyCode === 27) {
            this.props.closeModal();
        }
    };

    render() {
        const { openingModalType, openingModalProps } = this.state;

        if (openingModalType !== null) {
            const ModalComponent = modalLookup[openingModalType];

            return (
                <ModalComponent
                    {...openingModalProps}
                    closeModal={() => openingModalProps?.closeModal() ?? null}
                />
            );
        }

        return null;
    }
}

const mapStateToProps = (state) => ({
    currentModal: state.modalLogic,
});

const mapDispatchToProps = {
    closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalManager);
