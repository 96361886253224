import Number from './Number';

class File {
    static getReadableFileSize(sizeInBytes: number): string {
        let unit: string = 'B';
        let precision: number = 0;

        if (sizeInBytes >= 1024) {
            sizeInBytes = sizeInBytes / 1024;
            unit = 'kB';
            precision = 1;
        }

        if (sizeInBytes >= 1024) {
            sizeInBytes = sizeInBytes / 1024;
            unit = 'MB';
        }

        if (sizeInBytes >= 1024) {
            sizeInBytes = sizeInBytes / 1024;
            unit = 'GB';
        }

        return Number.format(sizeInBytes, precision) + ' ' + unit;
    }
}

export default File;
