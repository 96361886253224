import md5 from 'md5';
import Translations from '../Translations';
import File from '../File';

export default class AttachmentAssistantHelper {
    getFileListFromElements(fileInputSelector = 'form[name=applicantActionForm] input[type=file]') {
        const inputs = document.querySelectorAll(fileInputSelector);

        return Array.from(inputs).map((input) => input.files);
    }

    getFileListFromElement(fileInputSelector = 'form[name=applicantActionForm] input[type=file]') {
        const input = document.querySelector(fileInputSelector);

        return Array.from(input.files);
    }

    checkFilesType(files, allowedTypes) {
        const invalidFileType = files.find(
            (file) => allowedTypes.indexOf(file.name.split('.').pop().toLowerCase()) === -1
        );

        if (invalidFileType !== undefined) {
            return [Translations.getStatic('fileTypeNotAllowed')];
        }

        return [];
    }

    checkSingleFilesSizes(files, singleFileSizeUploadLimit) {
        const filesGreaterThanLimit = files.filter((file) => file.size > singleFileSizeUploadLimit);

        if (filesGreaterThanLimit.length > 0) {
            const result = [];

            filesGreaterThanLimit.forEach((file) => {
                result.push(
                    Translations.getStatic('singleFileSizeUploadLimitExceeded') +
                        '\n\n' +
                        file.name +
                        ' (' +
                        File.getReadableFileSize(file.size) +
                        ')'
                );
            });

            return result;
        }

        return [];
    }

    checkTotalFilesSize(totalFileSizeUploadLimit, filesList) {
        let totalFileSize = 0;

        filesList.forEach((file) => {
            totalFileSize += file.size;
        });

        if (totalFileSize > totalFileSizeUploadLimit) {
            alert(Translations.getStatic('totalFileSizeUploadLimitExceeded'));

            return false;
        }

        return true;
    }

    static createAttachmentAssistantHashIdForFileUpload() {
        return md5('createAttachmentAssistantHashIdForFileUpload');
    }

    static createAttachmentAssistantHashIdForEmail() {
        return md5('createAttachmentAssistantHashIdForEmail');
    }

    static createAttachmentAssistantHashIdForForm() {
        return md5('createAttachmentAssistantHashIdForForm');
    }

    static createAttachmentAssistantHashIdForRecommendationForm() {
        return md5('createAttachmentAssistantHashIdForRecommendationForm');
    }

    static createAttachmentAssistantHashIdForCandidateCard(candidateId) {
        return md5('createAttachmentAssistantHashIdForCandidateCard' + candidateId);
    }

    static createAttachmentAssistantHashIdForCandidates(candidateIds = []) {
        return md5(JSON.stringify(candidateIds));
    }

    static getActiveModelFromAssistantAttachmentModel(state, hashId) {
        if (hashId === state.fullHashId) {
            return state.fullModel;
        }

        if (hashId === state.duplicatedHashId) {
            return state.duplicatedModel;
        }

        if (hashId === state.emailHashId) {
            return state.emailModel;
        }

        if (hashId === state.formHashId) {
            return state.formModel;
        }

        if (hashId === state.fileUploadHashId) {
            return state.fileUploadModel;
        }

        if (hashId === state.recommendationFormHashId) {
            return state.recommendationFormModel;
        }

        return state;
    }

    static getAttachmentTypeLabels(language = '') {
        return {
            1: Translations.getStatic('cV', language),
            2: Translations.getStatic('coverLetter', language),
            3: Translations.getStatic('accompanyingLetter', language),
            4: Translations.getStatic('photo', language),
            5: Translations.getStatic('other', language),
            7: Translations.getStatic('questionnaireResults', language),
        };
    }

    static getAttachmentsForEmailPreview(attachmentAssistantModel) {
        if (attachmentAssistantModel.candidateAttachments === undefined) {
            return [];
        }

        if (attachmentAssistantModel.bulkUploadAttachments === undefined) {
            return [];
        }

        const attachmentsForPreview = attachmentAssistantModel.candidateAttachments.map(
            (attachment) => ({
                attachmentId: attachment.attachmentId,
                attachmentTypeId: attachment.attachmentTypeId,
                attachmentFilename: attachment.filename,
                url: attachment.url,
                size: attachment.size,
            })
        );

        attachmentAssistantModel.bulkUploadAttachments.forEach((attachment) => {
            attachmentsForPreview.push({
                attachmentId: null,
                attachmentTypeId: attachment.typeId,
                attachmentFilename: attachment.file.name,
                size: attachment.file.size,
            });
        });

        if (attachmentAssistantModel.templateAttachments !== undefined) {
            attachmentAssistantModel.templateAttachments.forEach((attachment) => {
                if (
                    attachment.hasOwnProperty('removed') === false ||
                    attachment.removed === false
                ) {
                    attachmentsForPreview.push({
                        attachmentId: attachment.id,
                        attachmentTypeId: attachment.attachmentTypeId,
                        attachmentFilename: attachment.filename,
                        url: attachment.url,
                        size: attachment.size,
                    });
                }
            });
        }

        return attachmentsForPreview;
    }

    static getTotalAttachmentsSize(attachmentAssistantModel) {
        return this.getAttachmentsForEmailPreview(attachmentAssistantModel).reduce(
            (totalFileSize, attachment) => totalFileSize + parseInt(attachment.size),
            0
        );
    }

    /**
     * @param attachmentAssistantModel
     * @param jobModel
     * @returns {array}
     */
    static getCandidatesWithExceededAttachmentsSize(attachmentAssistantModel, jobModel) {
        if (attachmentAssistantModel.selectedAttachmentTypes === undefined) {
            return [];
        }

        const templateAttachmentsSize = attachmentAssistantModel.templateAttachments.reduce(
            (totalSize, attachment) => totalSize + attachment.size,
            0
        );
        const uploadedAttachmentsSize = attachmentAssistantModel.bulkUploadAttachments.reduce(
            (totalSize, attachment) => totalSize + attachment.file.size,
            0
        );

        const selectedAttachmentTypeIds = attachmentAssistantModel.selectedAttachmentTypes
            .filter((selectedAttachmentType) => selectedAttachmentType.selected === true)
            .map((selectedAttachmentType) => selectedAttachmentType.typeId);

        return attachmentAssistantModel.selectedAttachmentTypesSizes
            .filter((candidate) => {
                const candidateAttachmentsSize = candidate.attachmentTypesSizes.reduce(
                    (attachmentsSize, attachmentType) =>
                        selectedAttachmentTypeIds.includes(attachmentType.attachmentTypeId) === true
                            ? attachmentsSize + attachmentType.attachmentTypeSize
                            : attachmentsSize,
                    0
                );

                return (
                    candidateAttachmentsSize + uploadedAttachmentsSize + templateAttachmentsSize >
                    attachmentAssistantModel.settings.EMAIL_TOTAL_ATTACHMENTS_MAX_SIZE
                );
            })
            .map((candidate) =>
                jobModel.candidates.find(
                    (jobCandidate) => jobCandidate.id === candidate.candidateId
                )
            );
    }
}
