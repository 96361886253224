import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Input } from 'semantic-ui-react';
import Icon from '../Icon';
import Constants from '../../helpers/Constants';
import Translations from '../../helpers/Translations';
import Language from '../../helpers/Language';
import AttachmentAssistantHelper from '../../helpers/components/AttachmentAssistantHelper';

const AttachmentAssistantActionButton = (props) => {
    const { attachmentTypes, settings, typeCounts, language } = props;

    let actionControl;
    let fileInputs = [];
    let dropdownItems = [];

    const dropdownItemsTitles = AttachmentAssistantHelper.getAttachmentTypeLabels(props.language);

    attachmentTypes.forEach((type) => {
        const typeSettings = settings.attachmentTypeSettings.find(
            (setting) => parseInt(setting.typeId) === parseInt(type.id)
        );

        if (typeSettings === undefined) {
            return null;
        }

        if (props.disabledPhotoOption === true && type.id === Constants.ATTACHMENT_TYPE_PHOTO) {
            return null;
        }

        const allowedFileTypes = typeSettings.allowedFileTypes.map(
            (allowedFileType) => '.' + allowedFileType
        );
        const typeCount =
            typeCounts.hasOwnProperty(parseInt(type.id)) === true
                ? typeCounts[parseInt(type.id)]
                : 1;

        for (let i = 1; i <= typeCount; i++) {
            fileInputs.push(
                <Input
                    maxLength={128}
                    label={type.name}
                    id={(i < typeCount ? 'filledInput' : 'attachmentType') + type.id}
                    name={type.id + '-' + i}
                    key={type.id + '-' + i}
                    type="file"
                    accept={allowedFileTypes.join()}
                    multiple={typeSettings.multipleUpload}
                    onChange={() => props.onAddAttachments(typeSettings.allowedFileTypes, type.id)}
                />
            );
        }

        dropdownItems.push(
            <Dropdown.Item key={'item' + type.id + '-' + typeCount}>
                <label htmlFor={'attachmentType' + type.id}>{dropdownItemsTitles[type.id]}</label>
            </Dropdown.Item>
        );
    });

    if (attachmentTypes.length === 1) {
        actionControl = (
            <>
                <label
                    className={'attachmentButton'}
                    htmlFor={'attachmentType' + attachmentTypes[0].id}
                >
                    <Icon size="mini" className="icon-add_attachment" />
                    {Translations.getStatic('attachment', language)}
                </label>
            </>
        );
    } else {
        actionControl = (
            <Dropdown
                className="dropdown-button_secondary dropdown-button_small"
                icon={{ className: 'hidden' }}
                trigger={
                    <>
                        <Icon size="mini" className="icon-add_attachment" />
                        <span>{Translations.getStatic('addAttachment', language)}</span>
                    </>
                }
            >
                <Dropdown.Menu>{dropdownItems}</Dropdown.Menu>
            </Dropdown>
        );
    }

    return (
        <>
            {actionControl}

            <div className="fileInputs">{fileInputs}</div>
        </>
    );
};

AttachmentAssistantActionButton.propTypes = {
    attachmentTypes: PropTypes.array.isRequired,
    settings: PropTypes.object.isRequired,
    typeCounts: PropTypes.object.isRequired,
    language: PropTypes.string,
    disabledPhotoOption: PropTypes.bool.isRequired,
    onAddAttachments: PropTypes.func.isRequired,
};

AttachmentAssistantActionButton.defaultProps = {
    disabledPhotoOption: false,
    language: Language.get(),
};

export default AttachmentAssistantActionButton;
