import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CandidateSidebarStage from './CandidateSidebarStage';
import CandidateSidebarDuplicates from './CandidateSidebarDuplicates';
import CandidateSidebarInfo from './CandidateSidebarInfo';
import CandidateSidebarTags from './CandidateSidebarTags';
import CandidateSidebarNote from './CandidateSidebarNote';
import CandidateSidebarHistory from './CandidateSidebarHistory';
import CandidateSidebarEvents from './CandidateSidebarEvents';
import Item from '../../Item';
import Divider from '../../Divider';
import Icon from '../../Icon';
import { resetSidebarEditPanel } from '../../../actions/SidebarActions';
import DateFormatted from '../../../helpers/DateFormatted';
import Translations from '../../../helpers/Translations';
import CandidateHelper from '../../../helpers/components/CandidateHelper';
import JobHelper from '../../../helpers/components/JobHelper';
import User from '../../../helpers/User';
import CandidateSidebarColleaguesNotes from './CandidateSidebarColleaguesNotes';
import Constants from '../../../helpers/Constants';
import CandidateSidebarGdprAgreement from './CandidateSidebarGdprAgreement';
import UserRights from '../../../helpers/UserRights';
import { storeCandidatePhoneCallIntoHistory } from '../../../actions/CandidateActions';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';

const CandidateSidebar = (props) => {
    const renderCandidateEvents = (isReadOnly, isDuplicate) => {
        if (isReadOnly === true && props.candidateModel.events.length === 0) {
            return null;
        } else if (isDuplicate === true && props.candidateModel.events.length === 0) {
            return null;
        }

        return (
            <>
                <CandidateSidebarEvents
                    candidateId={props.candidateModel.candidate.id}
                    events={props.candidateModel.events}
                    isReadOnly={isReadOnly}
                    isDuplicate={isDuplicate}
                />
                <Divider />
            </>
        );
    };

    const renderCandidateTags = (isReadOnly, isDuplicate) => {
        if (isReadOnly === true && props.candidateModel.candidate.tags.length === 0) {
            return null;
        } else if (isDuplicate === true && props.candidateModel.candidate.tags.length === 0) {
            return null;
        }

        return (
            <CandidateSidebarTags
                tags={props.candidateModel.candidate.tags}
                companyTags={props.candidateModel.settings.tags}
                candidateId={props.candidateModel.candidate.id}
                isReadOnly={isReadOnly}
                isDuplicate={isDuplicate}
            />
        );
    };

    const containerRef = React.useRef(null);
    const {
        loginManagerModel,
        candidateModel: { candidate, settings, history },
        jobModel,
        isDuplicate,
        sidebarEditPanel,
        sidebarModel,
        visibleSidebar,
        displaySize,
    } = props;

    if (candidate === undefined) {
        return null;
    }

    const isReadOnly = isDuplicate === true || JobHelper.isClosed(candidate.jobStateId) === true;
    const isExternalUser = User.isExternalUser(loginManagerModel);
    const isExternalVisitor = User.isExternalVisitor(loginManagerModel, jobModel);
    const userId = User.getUserId(loginManagerModel);

    const candidateEvents =
        isExternalVisitor === true ? null : renderCandidateEvents(isReadOnly, isDuplicate);
    const candidateTags =
        isExternalVisitor === true ? null : renderCandidateTags(isReadOnly, isDuplicate);

    const candidateNotes = [
        <CandidateSidebarNote
            key={'candidateSidebarNote'}
            candidateId={candidate.id}
            notes={candidate.notes}
            isDuplicate={isDuplicate}
            userId={userId}
            isExternalUser={isExternalUser}
            isSidebarOpen={sidebarModel.isOpen}
        />,
        <CandidateSidebarColleaguesNotes
            key={'candidateSidebarColleaguesNotes'}
            notes={candidate.notes}
            userId={userId}
        />,
    ];

    const SidebarContentPanel = () => (
        <div className="sidebar-content">
            {isReadOnly === false && isExternalVisitor === false && (
                <>
                    <CandidateSidebarStage
                        stage={candidate.stage}
                        candidateRefusalReason={candidate.refusalReason}
                        stages={settings.stages}
                        refusalReasons={settings.refusalReasons}
                        stateId={candidate.stateId}
                        candidateId={candidate.id}
                        jobId={candidate.jobId}
                        hasActiveFreeSubscription={UserRights.hasActiveFreeSubscription(
                            loginManagerModel
                        )}
                    />
                    <Divider hidden />
                </>
            )}

            <CandidateSidebarInfo
                candidateId={candidate.id}
                jobId={candidate.jobId}
                jobPlace={candidate.jobPlace}
                jobTitle={candidate.jobTitle}
                source={candidate.source}
                sourceTags={candidate.sourceTags}
                addDate={candidate.addDate}
                email={candidate.email}
                phone={candidate.phone}
                questionnaireResultsData={candidate.questionnaireResultsData}
                isJobUnpaid={JobHelper.isUnpaid(candidate.jobStateId)}
                recommendation={candidate.recommendation}
                loginManagerModel={loginManagerModel}
                history={history}
                isExternalVisitor={isExternalVisitor}
                onStoreCandidatePhoneCallIntoHistory={props.storeCandidatePhoneCallIntoHistory}
            />
            <Divider />
            {candidate.wasSentToColleague !== false && (
                <>
                    <div className="ui items unstackable">
                        <Item>
                            <Icon className="icon-arrow_date" size="mini" />
                            <Item.Content>
                                {Translations.getStatic('forwarded')}{' '}
                                {DateFormatted.getDate(candidate.wasSentToColleague, 'j.n.Y')}
                            </Item.Content>
                        </Item>
                    </div>
                    <Divider />
                </>
            )}

            {isDuplicate === false &&
                candidate.duplicities.length > 0 &&
                isExternalVisitor === false && (
                    <>
                        <CandidateSidebarDuplicates
                            duplicates={candidate.duplicities}
                            candidateName={candidate.candidateName}
                        />

                        <Divider />
                    </>
                )}

            {candidateEvents}

            {candidateTags}

            {(isDuplicate === false ||
                (candidate.hasOwnProperty('notes') === true && candidate.notes.length > 0)) && (
                <>
                    {isExternalUser === true ? candidateNotes.reverse() : candidateNotes}

                    <Divider />
                </>
            )}

            {isDuplicate === false && isExternalVisitor === false && (
                <>
                    <CandidateSidebarGdprAgreement
                        candidateId={candidate.id}
                        gdprAgreement={candidate.gdprAgreement}
                        jobTitle={candidate.jobTitle}
                    />
                    <Divider />
                </>
            )}

            {isExternalVisitor === false && (
                <CandidateSidebarHistory candidateId={candidate.id} history={history} />
            )}
        </div>
    );

    if (displaySize.width < Constants.WIDTH_NO_MODAL) {
        return <SidebarContentPanel />;
    }

    return (
        <>
            <Box
                sx={{ overflowX: 'hidden', height: '100%' }}
                className={visibleSidebar === true ? 'edit-panel_open' : 'edit-panel_close'}
                ref={containerRef}
            >
                <SidebarContentPanel />
                <Slide
                    className={
                        'ui sidebar-edit sidebar' + (visibleSidebar === true ? ' visible' : ' ')
                    }
                    direction="left"
                    in={sidebarModel.isOpen}
                    container={containerRef.current}
                >
                    <div>{sidebarEditPanel}</div>
                </Slide>
            </Box>
        </>
    );
};

const mapStateToProps = (state, ownProps) => ({
    candidateModel: CandidateHelper.getActiveModelFromCandidateModel(
        state.candidateModel,
        ownProps.candidateId
    ),
    jobModel: state.jobModel,
    loginManagerModel: state.loginManagerModel,
});

const mapDispatchToProps = {
    resetSidebarEditPanel,
    storeCandidatePhoneCallIntoHistory,
};

CandidateSidebar.defaultProps = {
    isDuplicate: false,
};

CandidateSidebar.propTypes = {
    candidateId: PropTypes.number.isRequired,
    isDuplicate: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateSidebar);
