import React from 'react';
import { connect } from 'react-redux';
import Url from '../helpers/Url';
import { openAcceptedCandidateDialog } from '../actions/DialogActions';

class CurrentlyAcceptedCandidatesManager extends React.Component {
    componentDidMount = () => {
        document.getElementById('container').addEventListener('mousedown', this.handleMouseDown);
    };

    componentWillUnmount = () => {
        document.getElementById('container').removeEventListener('mousedown', this.handleMouseDown);
    };

    openDialog = (e, preventDefault = true) => {
        if (preventDefault === true) {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
        }

        this.props.openAcceptedCandidateDialog(this.props.currentlyAcceptedCandidates.jobId);

        return !preventDefault;
    };

    handleMouseDown = (e) => {
        const currentUrl = Url.getUrl();
        const {
            currentlyAcceptedCandidates: { url, candidateIds },
            noModalLogic: { noModalIsOpen, noModalType },
        } = this.props;

        if (url !== currentUrl) {
            return true;
        }

        const event = window.event || e;
        const path = event.path || (event.composedPath && event.composedPath());

        if (noModalIsOpen === true && noModalType === 'DetailCandidateNoModal') {
            const modalWrapper = path.find(
                (pathElement) =>
                    pathElement.className !== undefined &&
                    pathElement.className.indexOf('noModal') > -1
            );

            if (modalWrapper === undefined) {
                return this.openDialog(event);
            }

            const closeButton = path.find(
                (pathElement) =>
                    pathElement.className !== undefined &&
                    pathElement.className.indexOf('square-button-close') > -1
            );

            if (closeButton !== undefined) {
                return this.openDialog(event, false);
            }

            return true;
        } else if (currentUrl.indexOf('pipeline') > -1) {
            const pipelineItem = path.find(
                (pathElement) =>
                    pathElement.className !== undefined &&
                    pathElement.className.indexOf('candidate-card') > -1
            );

            if (pipelineItem === undefined) {
                return this.openDialog(event);
            }

            const candidateId = parseInt(pipelineItem.getAttribute('data-candidate-id'));

            if (candidateIds.includes(candidateId) === false) {
                return this.openDialog(event);
            }

            return true;
        } else {
            const candidateRow = path.find(
                (pathElement) =>
                    pathElement.className !== undefined &&
                    pathElement.className.indexOf('clickable') > -1
            );

            if (candidateRow === undefined) {
                return this.openDialog(event);
            }

            const candidateId = parseInt(candidateRow.getAttribute('data-candidate-id'));

            if (candidateIds.includes(candidateId) === false) {
                return this.openDialog(event);
            }

            return true;
        }
    };

    render = () => {
        return this.props.children;
    };
}

const mapStateToProps = (state) => ({
    currentlyAcceptedCandidates: state.currentlyAcceptedCandidates,
    noModalLogic: state.noModalLogic,
});

const mapDispatchToProps = {
    openAcceptedCandidateDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentlyAcceptedCandidatesManager);
