import React from 'react';
import Translations from '../../../helpers/Translations';
import Url from '../../../helpers/Url';
import Link from '../../../helpers/overridden/Link';
import HeaderHelper from '../../../helpers/components/HeaderHelper';
import SidebarSubmenuList from './SidebarSubmenuList';

const SidebarMenuListItem = ({ item }) => {
    const menuItemsStrings = HeaderHelper.getMenuItemsStrings();

    const title =
        item.hasOwnProperty('title') === true
            ? Translations.getStatic(item.title)
            : menuItemsStrings[item.action];

    return (
        <>
            <h1>{Translations.getStatic(item.title)}</h1>

            {item.hasOwnProperty('submenu') === true ? (
                <SidebarSubmenuList item={item} />
            ) : (
                <ul>
                    <li>
                        {item.hasOwnProperty('url') === true ? (
                            <a href={item.url}>{title}</a>
                        ) : (
                            <Link
                                to={Url.build(
                                    item.action,
                                    [],
                                    'auto',
                                    false,
                                    item?.outsideMark ?? false
                                )}
                                gtmTag={'sidebar-menu-' + (item?.title ?? item?.action)}
                            >
                                {title}
                            </Link>
                        )}
                    </li>
                </ul>
            )}
        </>
    );
};

export default SidebarMenuListItem;
