import React from 'react';
import PropTypes from 'prop-types';
import Divider from '../../Divider';
import Icon from '../../Icon';
import NoResults from '../../NoResults';
import Translations from '../../../helpers/Translations';
import DashboardOffersListRow from './DashboardOffersListRow';
import Sort from '../../../helpers/Sort';

class DashboardActiveOffersList extends React.Component {
    render = () => {
        const { offers, limits, links, user, selectedUserId, selectedUserName, displaySize } =
            this.props;

        const ListTitle = () => {
            if (user.id === selectedUserId) {
                return (
                    <>
                        {Translations.getStatic('myActiveOffers')} ({offers.length.toString()})
                    </>
                );
            } else if (selectedUserId === 0) {
                return (
                    <>
                        {Translations.getStatic('allActiveOffers')} ({offers.length.toString()})
                    </>
                );
            } else {
                return (
                    <>
                        {Translations.getStatic('userActiveOffers')} {selectedUserName} (
                        {offers.length.toString()})
                    </>
                );
            }
        };

        if (offers.length === 0) {
            return (
                <NoResults
                    title={Translations.getStatic('noJobOfferHasBeenCreated')}
                    isDashboard={true}
                    anchor={{
                        url: links.createNewOffer,
                        label: Translations.getStatic('createNewOffer'),
                    }}
                />
            );
        }

        const allOffersLink =
            links.allOffers +
            '&contact_id_for_offers=' +
            ((selectedUserId ?? 0) === 0 ? '' : '' + selectedUserId);

        return (
            <>
                <div className="flex justify between">
                    <h5 className="flex title">
                        <Icon className="icon-briefcase" size="mini" />
                        <div className="content">
                            <ListTitle />
                        </div>
                    </h5>
                </div>

                <Divider hidden className="size_16" />

                <div className={'list'}>
                    <div className="ui items list__jobs unstackable">
                        {Sort.asText(offers, 'createdAt', 'DESC')
                            .slice(0, limits.offersLoadLimit)
                            .map((offer, i) => (
                                <DashboardOffersListRow offer={offer} key={i} selected={false} />
                            ))}
                    </div>
                </div>

                {offers.length > parseInt(limits.offersLoadLimit) && (
                    <>
                        <Divider hidden className="size_8" />
                        <div className={'txt-align'}>
                            <a href={allOffersLink} className="primary-link">
                                {Translations.getStatic('allJobOffers')}
                            </a>
                        </div>
                        {displaySize.isDesktopProfesia === true && (
                            <Divider hidden className="size_24" />
                        )}
                    </>
                )}
            </>
        );
    };
}

DashboardActiveOffersList.propTypes = {
    limits: PropTypes.object.isRequired,
    offers: PropTypes.array.isRequired,
    links: PropTypes.object.isRequired,
};

export default DashboardActiveOffersList;
