class Cookie {
    static get(cookieName: string = ''): string | null {
        if (cookieName === '') {
            return null;
        }

        const cookieNameWithEq: string = cookieName + '=';
        const cookies: string[] = document.cookie.split(';');

        for (let i = 0; i < cookies.length; i++) {
            let cookie: string = cookies[i] ?? '';

            while (cookie.startsWith(' ')) {
                cookie = cookie.substring(1);
            }

            if (cookie.startsWith(cookieNameWithEq)) {
                return cookie.substring(cookieNameWithEq.length, cookie.length);
            }
        }

        return null;
    }
}

export default Cookie;
