import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack, styled } from '@mui/material';
import { CircleIcon } from '@profesia/adamui/components/common/icons';
import { Typography } from '@profesia/adamui/components/common/typography';
const Loader = ({ description, testId = 'loader-stack' }) => {
    return (_jsxs(Stack, { "data-testid": testId, children: [_jsx(LoaderBox, { children: _jsx(CircleIcon, {}) }), _jsx(Typography, { component: "div", textAlign: "center", children: description })] }));
};
const LoaderBox = styled(Box)(({ theme: { spacing } }) => ({
    gap: spacing(0.75),
    margin: spacing(0, 'auto', 3),
    display: 'flex',
    '.MuiSvgIcon-Circle': {
        animation: 'rotation 1.3s linear infinite',
        '&:nth-of-type(2)': {
            animationDelay: '-1.1s',
        },
        '&:nth-of-type(3)': {
            animationDelay: '-0.9s',
        },
    },
    '@keyframes rotation': {
        '0%': {
            transform: 'rotate(0deg)',
        },
        '100%': {
            transform: 'rotate(359deg)',
        },
    },
}));
export default Loader;
