import Strings from './Strings';
import Constants from './Constants';
import Log from './Log';
import Language from './Language';

const MARK_SERVER_PREFIX: string = '/mark';

class Url {
    static get MARK_SERVER_PREFIX(): string {
        return MARK_SERVER_PREFIX;
    }

    /**
     * To get a query string parameter use getQueryStringParameter
     */
    static getUrlParameter(parameterName: string, url: string = this.getPathname()): string {
        const parameters: CommonObjectType = this.getUrlParameters(url);

        return parameters.hasOwnProperty(parameterName) ? parameters[parameterName] : '';
    }

    /**
     * /mark/actionName/parameterName1/parameterValue1/parameterName2/parameterValue2/...
     * /mark/language/actionName/parameterName1/parameterValue1/parameterName2/parameterValue2/...
     * /mark/(applicationId:numeric)/tagUrlName/
     */
    static getUrlParameters(url: string = this.getPathname()): CommonObjectType {
        const pathname: string[] = url.trim().split('/');

        const parameters: CommonObjectType = {};
        let pathnameParts: string[] = [];

        if (pathname[1] !== 'mark') {
            parameters.action = pathname[1];
            parameters.language = Constants.LANGUAGE_SK;
        } else {
            parameters.language = Constants.LANGUAGE_SK;

            if (
                pathname.length >= 3 &&
                (Strings.isNumeric(pathname[2] ?? '') ||
                    Strings.isNumeric((pathname[2] ?? '').replace('O', '')))
            ) {
                parameters.action = 'reaction';

                if (pathname[2]?.startsWith('O')) {
                    parameters.offerId = pathname[2]?.replace('O', '');
                } else {
                    parameters.jobId = pathname[2];
                }

                if (pathname.length >= 4) {
                    parameters.tagUrlName = pathname[3];
                }
            } else {
                parameters.action =
                    pathname[2] === '' || pathname[2] === undefined ? 'main' : pathname[2];
                pathnameParts = pathname.splice(3);
            }

            for (let i: number = 0; i < pathnameParts.length; i += 2) {
                parameters[pathnameParts[i] ?? ''] = pathnameParts[i + 1];
            }
        }

        return parameters;
    }

    /**
     * Get a query string parameter
     * !!! Avoid using links with query string !!!
     */
    static getQueryStringParameter(parameterName: string, queryString: string = '') {
        if (parameterName === '') {
            return '';
        }

        const pathname: string = (
            queryString === '' ? window.location.search.trim() : queryString
        ).replace('?', '');
        const pathnameParts: string[] = pathname.split('&');

        const parameters: CommonObjectType = {};

        pathnameParts.forEach((parameter) => {
            const pair: string[] = parameter.split('=');

            const parameterName: string = pair[0] ?? '';

            if (parameterName !== '') {
                parameters[parameterName] = pair[1];
            }
        });

        return parameters.hasOwnProperty(parameterName) ? parameters[parameterName] : '';
    }

    static getSiteLanguage(forUrl: boolean = false): string {
        const defaultLanguage: string = forUrl ? '' : Constants.LANGUAGE_SK;

        const urlParts: string[] = this.getPathname()
            .replace('mark', '')
            .replace('index.php', '')
            .replace(/^\/*/gi, '')
            .split('/');

        const urlLanguage: string = urlParts[0] ?? '';

        return Constants.ALLOWED_LANGUAGES.includes(urlLanguage) ? urlLanguage : defaultLanguage;
    }

    static getSiteLocale(): string {
        const language: string = this.getSiteLanguage();

        return language === 'cz' ? 'cs' : language;
    }

    static build(
        action: string = '',
        additionalParams: CommonObjectType = {},
        language: string = 'auto',
        absolute: boolean = false,
        outsideMark: boolean = false
    ): string {
        language = language === Constants.LANGUAGE_SK ? '' : language;

        if (language === 'auto') {
            language = this.getSiteLanguage(true);
        }

        const urlParts: string[] = [
            outsideMark ? '' : this.MARK_SERVER_PREFIX.replace(/\//g, ''),
            language,
            action === 'main' ? '' : action,
        ].filter((part: string): boolean => part !== '');

        Object.keys(additionalParams).forEach((objectKey) => {
            if (objectKey !== 'hashtag') {
                const param: string = '' + additionalParams[objectKey];

                urlParts.push(objectKey);
                urlParts.push(param);
            }
        });

        const hashtag: string = additionalParams.hasOwnProperty('hashtag')
            ? '#' + additionalParams.hashtag
            : '';

        let url: string =
            '/' + urlParts.filter((urlPart: string): boolean => urlPart !== '').join('/') + hashtag;

        if (absolute) {
            url = window.location.origin + url;
        }

        return url;
    }

    static buildLoginLink(language: string = 'auto', redirectUrl: string = ''): string {
        let redirectTo: string =
            this.getUrlParameter('action') === 'main'
                ? this.build('dashboard', {}, 'auto', false, true)
                : encodeURIComponent(this.getPathname());

        if (redirectUrl !== '') {
            redirectTo = redirectUrl;
        }

        if (language === 'auto') {
            language = Language.get();
        }

        language = language.replace(Constants.LANGUAGE_CS, Constants.LANGUAGE_CS_PROFESIA);

        const backUrl: string =
            this.build('login') + '?redirectTo=' + redirectTo + (this.isLocal() ? '&local=1' : '');

        let loginLink: string =
            '/login_person.php?action=profesia_login&project=mark&language=' +
            language +
            '&back_url=' +
            encodeURIComponent(backUrl);

        if (this.isLocal()) {
            loginLink = window.location.origin.replace('mark.local', 'test') + loginLink;
        }

        return loginLink;
    }

    static buildLogoutLink(): string {
        return '/logout.php';
    }

    static getTld(url: string = ''): string {
        if (url === '') {
            return '';
        }

        try {
            const matches: string[] = new URL(url).hostname.match(/[a-z]+$/) ?? [];

            return matches[0] ?? '';
        } catch (e) {
            const matches: string[] = url.match(/https?[:\/]+([a-zA-Z-.]+)\/?/) ?? [];
            const domainParts: string[] = matches[1]?.split('.') ?? [];

            return domainParts[domainParts.length - 1] ?? '';
        }
    }

    static isOnline(): boolean {
        return [
            'profesia.sk',
            'www.profesia.sk',

            'profesia.cz',
            'www.profesia.cz',

            'workania.hu',
            'www.workania.hu',
        ].includes(window.location.hostname);
    }

    static isB2bDashboardTest(): boolean {
        return window.location.hostname === 'b2b-dashboard.test.profesia.sk';
    }

    static isNotOnline(): boolean {
        return !this.isOnline();
    }

    static isLocal(): boolean {
        return [
            Constants.PROFESIA_SK_MARK_LOCAL,
            Constants.PROFESIA_SK_LOCAL,
            Constants.PROFESIA_CZ_LOCAL,
        ].includes(window.location.hostname);
    }

    static redirectTo(uri: string, replace: boolean = false): void {
        if (this.getPathname() === uri) {
            this.reload('Reloading "' + this.getPathname() + '"');
        } else {
            Log.message('Redirecting from "' + this.getPathname() + '" to "' + uri + '"');

            if (replace) {
                window.location.replace(uri);
            } else {
                window.location.assign(uri);
            }
        }
    }

    static isMaster(): boolean {
        return [Constants.PROFESIA_SK_MASTER, Constants.PROFESIA_CZ_MASTER].includes(
            window.location.hostname
        );
    }

    static isOnlineOrMaster(): boolean {
        return this.isOnline() || this.isMaster();
    }

    static isMainPage(): boolean {
        return this.getPathname() === Url.build();
    }

    static isPage(action: string, outsideMark: boolean = false): boolean {
        if (outsideMark) {
            return this.getPathname() === Url.build(action, {}, 'auto', false, true);
        }

        return this.getPathname() === Url.build(action);
    }

    static getPathname(): string {
        return window.location.pathname;
    }

    static isWebPage(): boolean {
        const action: string = this.getUrlParameter('action');

        return [
            '',
            'contact',
            'features',
            'articles',
            'pricing',
            'packages',
            'faq',
            'main',
            'login',
        ].includes(action);
    }

    static isAppPage(): boolean {
        return !this.isWebPage();
    }

    static getUrl(): string {
        return this.getPathname() + window.location.search;
    }

    static getFullUrl(link: string): string {
        return window.location.protocol + '//' + window.location.host + link;
    }

    static reload(message: string = ''): void {
        Log.message(message.length === 0 ? 'Reloading ' + window.location.href : message);

        window.location.reload();
    }

    static buildJobLink(
        jobId: string,
        jobViewId: number,
        actionIfAutomatic: string = 'detail'
    ): string {
        let action = actionIfAutomatic;

        switch (jobViewId) {
            case Constants.JOB_VIEW_PIPELINE:
                action = 'pipeline';
                break;

            case Constants.JOB_VIEW_LIST:
                action = 'candidates';
                break;
        }

        return this.build(action, { job: jobId });
    }

    static isJobLink(url: string): boolean {
        const action: string = Url.getUrlParameter('action', url);

        return (
            ['pipeline', 'candidates'].includes(action) && Url.getUrlParameter('job', url) !== ''
        );
    }

    static isItemActive(item: ItemType): boolean {
        if (item.hasOwnProperty('action')) {
            const itemAction: string = item?.action ?? '';

            if (this.isPage(itemAction, item?.outsideMark ?? false)) {
                return true;
            }
        }

        return (
            item.submenu?.find((submenuItem) => {
                if (submenuItem.hasOwnProperty('action')) {
                    return this.isPage(
                        submenuItem?.action ?? '',
                        submenuItem?.outsideMark ?? false
                    );
                }

                return false;
            }) !== undefined
        );
    }

    static buildProfesiaLink(url: string): string {
        const language: string = Language.get();

        return Language.getDefaultLanguage() === language ? url : '/' + language + url;
    }
}

interface CommonObjectType {
    [key: string]: any;
}

interface ItemType {
    hasOwnProperty: (property: string) => boolean;
    submenu?: SubmenuItemType[];
    action?: string;
    outsideMark: boolean;
}

interface SubmenuItemType {
    hasOwnProperty: (property: string) => boolean;
    action?: string;
    outsideMark: boolean;
}

export default Url;
