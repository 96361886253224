import { LoginManagerModelType } from '../../../interfaces/LoginManagerModelType';
import userTypeSkeleton from './userTypeSkeleton';
import activePackageTypeSkeleton from './activePackageTypeSkeleton';
import loggedUserTypeSkeleton from './loggedUserTypeSkeleton';

const loginManagerModelSkeleton: LoginManagerModelType = {
    loggedUser: loggedUserTypeSkeleton,
    user: userTypeSkeleton,
    activePackage: activePackageTypeSkeleton,
    languages: [
        {
            languageId: 1,
            languageCode: 'sk',
            language: 'Slovenčina',
            enabled: true,
        },
        {
            languageId: 2,
            languageCode: 'en',
            language: 'English',
            enabled: true,
        },
        {
            languageId: 3,
            languageCode: 'de',
            language: 'Deutsch',
            enabled: false,
        },
        {
            languageId: 4,
            languageCode: 'cs',
            language: 'Čeština',
            enabled: true,
        },
        {
            languageId: 5,
            languageCode: 'hu',
            language: 'Magyar',
            enabled: true,
        },
    ],
    notifications: [],
    hasCompanyActivatedElasticSearchForCandidates: false,
    hasCompanyActivatedElasticSearchForJobs: false,
    hasCompanyActiveInbox: false,
    loginText: 0,
    mercurePublishUrl: '',
    orderMarkUrl: '',
};

export default loginManagerModelSkeleton;
