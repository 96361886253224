import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SkeletonRectangular } from '@profesia/adamui/components/common/skeleton';
import copy from '../../img/icons/makeACopy.svg';
import Translations from '../helpers/Translations';
import { previousPage } from '../actions/HeaderActions';
import Url from '../helpers/Url';
import UserRights from '../helpers/UserRights';
import { isOrderStepEnabled } from './Job/JobAdvertisement/helpers';
import Image from './Image';

const Header4MenuCopyPositionButton = ({
    displaySize,
    isLoading,
    previousPage,
    loginManagerModel,
}) => {
    const navigate = useNavigate();
    const { isMobile } = displaySize;

    if (isLoading) {
        return (
            <div className="extended-action inverted">
                <SkeletonRectangular width={97} height={18} borderRadius={26} />
            </div>
        );
    }

    const handleClick = () => {
        previousPage(window.location.pathname + window.location.search);
        const url =
            UserRights.isFreeSubscription(loginManagerModel) && !isOrderStepEnabled
                ? 'create-job-ad'
                : 'create-job-ad/content';
        navigate(Url.build(url), { state: { isDuplicateJob: true } });
    };

    if (isMobile) {
        return (
            <span onClick={handleClick} className="txt-navy">
                {Translations.getStatic('makeACopy')}
            </span>
        );
    } else {
        return (
            <div onClick={handleClick} className="extended-action">
                <div className="extended-action__icon-status">
                    <Image size="tiny" src={copy} />
                </div>
                <div className="position-detail-button">{Translations.getStatic('makeACopy')}</div>
            </div>
        );
    }
};

const mapStateToProps = (state) => ({
    displaySize: state.displaySize,
    loginManagerModel: state.loginManagerModel,
});

const mapDispatchToProps = {
    previousPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header4MenuCopyPositionButton);
