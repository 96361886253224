import Screen from '../helpers/Screen';
import { UPDATE_DIMENSIONS } from '../actions/DisplaySizeActions';
import Constants from '../helpers/Constants';

const getScreenState = () => ({
    width: Screen.getWidth(),
    isMobile: Screen.getWidth() <= Constants.RESOLUTION_DESKTOP,
    isDesktop: Screen.getWidth() > Constants.RESOLUTION_DESKTOP,
    isMobileProfesia: Screen.getWidth() <= Constants.RESOLUTION_MOBILE_PROFESIA,
    isTabletProfesia:
        Screen.getWidth() <= Constants.RESOLUTION_TABLET_PROFESIA &&
        Screen.getWidth() > Constants.RESOLUTION_MOBILE_PROFESIA,
    isBigTabletProfesia:
        Screen.getWidth() > Constants.RESOLUTION_TABLET_PROFESIA &&
        Screen.getWidth() <= Constants.RESOLUTION_DESKTOP_PROFESIA,
    isDesktopProfesia: Screen.getWidth() > Constants.RESOLUTION_DESKTOP_PROFESIA,
});

const defaultState = getScreenState();

export const displaySize = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_DIMENSIONS:
            return getScreenState();
        default:
            return state;
    }
};