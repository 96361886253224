import Constants from './Constants';
import {
    TranslationsType,
    TranslationsVariableType,
    VariableType,
} from '../../interfaces/EmailTemplateModelType';
import { CommonObjectType } from '../../interfaces/CommonTypes';
import { LoginManagerModelType } from '../../interfaces/LoginManagerModelType';
import { CandidateEventDataType } from '../../interfaces/ModelTypes';

class Variables {
    static getVariablesForAction(
        variables: VariableType[],
        languageCode: string,
        actionTypes: number[] | null = null,
        userFilledInData: string[] = []
    ): VariableType[] {
        return variables.map((variable) => ({
            ...variable,
            ...variable.translations[languageCode],
            visible:
                actionTypes === null ||
                actionTypes.includes(variable.actionType) ||
                userFilledInData.includes(variable.variableTranslatedKey),
        }));
    }

    static getEditorVariables(
        editorVariables: VariableType[],
        onlyVisible: boolean = true
    ): string[] {
        if (onlyVisible) {
            editorVariables = editorVariables.filter((variable) => variable.visible === true);
        }

        return editorVariables.map((variable) => variable.variableForEditor ?? '');
    }

    static getActionTypes(
        isTemplatePage: boolean = false,
        isEvent: boolean = false,
        isVideoInterview: boolean = false,
        hasJobQuestionnaire: boolean = false,
        isAutoAnswer: boolean = false,
        isOutOfOffice: boolean = false,
        isMassForward: boolean = false
    ): number[] {
        let resultActionTypes: number[];

        if (isOutOfOffice) {
            resultActionTypes = [
                Constants.VARIABLE_ACTION_TYPE_CANDIDATE,
                Constants.VARIABLE_ACTION_TYPE_OTHER,
                Constants.VARIABLE_ACTION_TYPE_POSITION,
                Constants.VARIABLE_ACTION_TYPE_OUT_OF_OFFICE,
            ];
        } else if (isAutoAnswer) {
            resultActionTypes = [
                Constants.VARIABLE_ACTION_TYPE_CANDIDATE,
                Constants.VARIABLE_ACTION_TYPE_OTHER,
                Constants.VARIABLE_ACTION_TYPE_POSITION,
            ];
        } else {
            resultActionTypes = [
                Constants.VARIABLE_ACTION_TYPE_CANDIDATE,
                Constants.VARIABLE_ACTION_TYPE_OTHER,
                Constants.VARIABLE_ACTION_TYPE_POSITION,
                Constants.VARIABLE_ACTION_TYPE_VIDEO_INTERVIEW,
                Constants.VARIABLE_ACTION_TYPE_QUESTIONNAIRE,
                Constants.VARIABLE_ACTION_TYPE_EVENT,
                Constants.VARIABLE_ACTION_TYPE_MULTIPLE_FORWARD,
            ];
        }

        if (!isTemplatePage) {
            if (!isEvent) {
                resultActionTypes = resultActionTypes.filter(
                    (actionType) => actionType !== Constants.VARIABLE_ACTION_TYPE_EVENT
                );
            }

            if (!isVideoInterview) {
                resultActionTypes = resultActionTypes.filter(
                    (actionType) => actionType !== Constants.VARIABLE_ACTION_TYPE_VIDEO_INTERVIEW
                );
            }

            if (!hasJobQuestionnaire) {
                resultActionTypes = resultActionTypes.filter(
                    (actionType) => actionType !== Constants.VARIABLE_ACTION_TYPE_QUESTIONNAIRE
                );
            }

            if (!isMassForward) {
                resultActionTypes = resultActionTypes.filter(
                    (actionType) => actionType !== Constants.VARIABLE_ACTION_TYPE_MULTIPLE_FORWARD
                );
            }
        }

        return resultActionTypes;
    }

    static getUserFilledInData(
        userData: CommonObjectType,
        companyData: CommonObjectType
    ): string[] {
        const filledInData: string[] = [];

        if (userData.name.trim() !== '') {
            filledInData.push('highlightTextareaVariableContactName');
        }

        if (userData.phone !== null && userData.phone.trim() !== '') {
            filledInData.push('highlightTextareaVariableContactPhone');
        }

        if (userData.email !== null && userData.email.trim() !== '') {
            filledInData.push('highlightTextareaVariableContactEmail');
        }

        if (userData.city !== null && userData.city.trim() !== '') {
            filledInData.push('highlightTextareaVariableCity');
        }

        if (companyData.companyName.trim() !== '') {
            filledInData.push('highlightTextareaVariableCompany');
        }

        if (userData.street !== null && userData.street.trim() !== '') {
            filledInData.push('highlightTextareaVariableStreet');
        }

        if (userData.zip !== null && userData.zip.trim() !== '') {
            filledInData.push('highlightTextareaVariableZip');
        }

        if (companyData.url.trim() !== '') {
            filledInData.push('highlightTextareaVariableWeb');
        }

        return filledInData;
    }

    static checkFilledVariables(
        variables: VariableType[],
        textsToCheck: string[],
        actionTypesToCheck: number[] = [],
        dataToCheck: CommonObjectType[] = []
    ): string[] {
        const errors: string[] = [];

        variables.forEach((variable) => {
            if (actionTypesToCheck.includes(variable.actionType)) {
                if (!this.checkFilledVariableType(variable, textsToCheck, dataToCheck)) {
                    errors.push(variable.variableTranslatedKey + 'UsingVariableWithoutData');
                }
            }
        });

        return errors;
    }

    static checkRequiredVariables(
        variables: VariableType[],
        textsToCheck: string[],
        actionTypesToCheck: number[] = []
    ): string[] {
        const errors: string[] = [];

        variables.forEach((variable) => {
            if (actionTypesToCheck.includes(variable.actionType)) {
                if (
                    !this.checkRequiredVariableType(variable, textsToCheck) &&
                    variable.id !== Constants.VARIABLE_OUT_OF_OFFICE_START_AT_ID
                ) {
                    errors.push(variable.variableTranslatedKey + 'VariableNotUsed');
                }
            }
        });

        return errors;
    }

    static checkForbiddenVariables(
        variables: VariableType[],
        textsToCheck: string[],
        actionTypesToCheck: number[] = []
    ): string[] {
        const errors: string[] = [];

        variables.forEach((variable) => {
            if (actionTypesToCheck.includes(variable.actionType)) {
                if (!this.checkForbiddenVariable(variable, textsToCheck)) {
                    errors.push(variable.variableTranslatedKey + 'ForbiddenVariable');
                }
            }
        });

        return errors;
    }

    static checkFilledVariableType(
        variable: VariableType,
        textsToCheck: string[],
        dataToCheck: CommonObjectType[] = []
    ): boolean {
        const containsVariable: boolean = this.containsVariable(
            variable.translations,
            textsToCheck
        );

        if (!containsVariable) {
            return true;
        }

        const checkValue: string | null = this.getValueForVariable(variable.variableTranslatedKey);

        if (checkValue === null) {
            return true;
        }

        return (
            dataToCheck
                .map((item) => item[checkValue])
                .filter((value) => value === undefined || value === null || value === false)
                .length === 0
        );
    }

    static checkRequiredVariableType(variable: VariableType, textsToCheck: string[]): boolean {
        return this.containsVariable(variable.translations, textsToCheck);
    }

    static checkForbiddenVariable(variable: VariableType, textsToCheck: string[]): boolean {
        return !this.containsVariable(variable.translations, textsToCheck);
    }

    static containsVariable(
        variableTranslations: TranslationsType,
        textsToCheck: string[]
    ): boolean {
        let containsVariable: boolean = false;

        Object.keys(variableTranslations).forEach((variableTranslationKey) => {
            const translations: TranslationsVariableType = variableTranslations[
                variableTranslationKey
            ] ?? { variableForEditor: '', variable: '' };

            if (translations.variableForEditor !== '') {
                textsToCheck.forEach((text) => {
                    if (text.includes(translations.variableForEditor)) {
                        containsVariable = true;
                    }
                });
            }
        });

        return containsVariable;
    }

    static getValueForVariable(variable: string): string | null {
        const variableValuePairs: CommonObjectType[] = [
            { variable: 'highlightTextareaVariableCity', value: 'hasUserCity' },
            { variable: 'highlightTextareaVariableCompany', value: 'hasCompanyName' },
            { variable: 'highlightTextareaVariableContactEmail', value: 'hasUserEmail' },
            { variable: 'highlightTextareaVariableContactName', value: 'hasUserName' },
            { variable: 'highlightTextareaVariableContactPhone', value: 'hasUserPhone' },
            { variable: 'highlightTextareaVariableStreet', value: 'hasUserStreet' },
            { variable: 'highlightTextareaVariableZip', value: 'hasUserZip' },
            { variable: 'highlightTextareaVariableWeb', value: 'hasCompanyUrl' },
            { variable: 'highlightTextareaVariableInviteDate', value: 'hasCandidateEvent' },
            { variable: 'highlightTextareaVariableQuestionnaire', value: 'hasJobQuestionnaire' },
            { variable: 'highlightTextareaVariableOutOfOfficeStart', value: 'hasOutOfOfficeStart' },
            { variable: 'highlightTextareaVariableOutOfOfficeEnd', value: 'hasOutOfOfficeEnd' },
            {
                variable: 'highlightTextareaVariableVideoInterviewPin',
                value: 'hasVideoInterviewPin',
            },
            {
                variable: 'highlightTextareaVariableVideoInterviewLink',
                value: 'hasVideoInterviewLink',
            },
            { variable: 'highlightTextareaVariableCandidatesList', value: 'isMassForwarding' },
        ];

        return (
            variableValuePairs.find((variableValuePair) => variableValuePair.variable === variable)
                ?.value ?? null
        );
    }

    static getFilledVariablesCheckParams(
        actionTypesToCheck: number[],
        candidatesData: CommonObjectType,
        loginManagerModel: LoginManagerModelType,
        videoInterviewData: CandidateEventDataType | null = null,
        outOfOffice: CommonObjectType | null = null,
        isMassForwarding: boolean | null = false,
        eventStartAt: boolean | null = null
    ) {
        const appendVideoInterviewParams = (
            actionTypesToCheck: number[],
            videoInterviewData: CandidateEventDataType | null
        ): CommonObjectType => {
            if (actionTypesToCheck.includes(Constants.VARIABLE_ACTION_TYPE_VIDEO_INTERVIEW)) {
                return {
                    hasVideoInterviewPin:
                        videoInterviewData !== null &&
                        videoInterviewData.highlightTextareaVariableVideoInterviewPin.trim() !== '',
                    hasVideoInterviewLink:
                        videoInterviewData !== null &&
                        videoInterviewData.highlightTextareaVariableVideoInterviewLink.trim() !==
                            '',
                };
            }

            return {};
        };

        const appendOutOfOfficeParams = (
            actionTypesToCheck: number[],
            outOfOffice: CommonObjectType | null
        ): CommonObjectType => {
            if (actionTypesToCheck.includes(Constants.VARIABLE_ACTION_TYPE_OUT_OF_OFFICE)) {
                return {
                    xhasOutOfOfficeStart:
                        outOfOffice !== null && outOfOffice.startAtForCheck !== null,
                    hasOutOfOfficeEnd: outOfOffice !== null && outOfOffice.endAtForCheck !== null,
                };
            }

            return {};
        };

        const appendUserParams = (
            actionTypesToCheck: number[],
            loginManagerModel: LoginManagerModelType
        ): CommonObjectType => {
            if (actionTypesToCheck.includes(Constants.VARIABLE_ACTION_TYPE_USER)) {
                return {
                    hasUserCity: loginManagerModel.user?.city.trim() !== '',
                    hasCompanyName: loginManagerModel.company?.companyName.trim() !== '',
                    hasUserName: loginManagerModel.user?.name.trim() !== '',
                    hasUserEmail: loginManagerModel.user?.email.trim() !== '',
                    hasUserPhone: loginManagerModel.user?.phone.trim() !== '',
                    hasUserStreet: loginManagerModel.user?.street.trim() !== '',
                    hasUserZip: loginManagerModel.user?.zip.trim() !== '',
                    hasCompanyUrl: loginManagerModel.company?.url.trim() !== '',
                };
            }

            return {};
        };

        const appendMultipleForwardParams = (
            actionTypesToCheck: number[],
            isMassForwarding: boolean | null
        ): CommonObjectType => {
            if (actionTypesToCheck.includes(Constants.VARIABLE_ACTION_TYPE_MULTIPLE_FORWARD)) {
                return {
                    isMassForwarding,
                };
            }

            return {};
        };

        const dataToCheck = candidatesData.map((candidate: CommonObjectType): CommonObjectType => {
            let result: CommonObjectType = {};

            if (actionTypesToCheck.includes(Constants.VARIABLE_ACTION_TYPE_EVENT)) {
                result = {
                    ...result,
                    hasCandidateEvent: candidate.hasEvent || eventStartAt !== null,
                };
            }

            if (actionTypesToCheck.includes(Constants.VARIABLE_ACTION_TYPE_QUESTIONNAIRE)) {
                result = {
                    ...result,
                    hasJobQuestionnaire: candidate.hasJobQuestionnaire,
                };
            }

            return {
                ...result,
                ...appendVideoInterviewParams(actionTypesToCheck, videoInterviewData),
                ...appendOutOfOfficeParams(actionTypesToCheck, outOfOffice),
                ...appendUserParams(actionTypesToCheck, loginManagerModel),
                ...appendMultipleForwardParams(actionTypesToCheck, isMassForwarding),
            };
        });

        if (dataToCheck.length === 0) {
            dataToCheck.push({
                ...appendVideoInterviewParams(actionTypesToCheck, videoInterviewData),
                ...appendOutOfOfficeParams(actionTypesToCheck, outOfOffice),
                ...appendUserParams(actionTypesToCheck, loginManagerModel),
                ...appendMultipleForwardParams(actionTypesToCheck, isMassForwarding),
            });
        }

        return {
            actionTypesToCheck,
            dataToCheck,
        };
    }

    static getRequiredVariablesCheckParams(actionTypesToCheck: number[]): CommonObjectType {
        return {
            actionTypesToCheck,
        };
    }

    static getForbiddenVariablesCheckParams(actionTypesToCheck: number[]): CommonObjectType {
        return {
            actionTypesToCheck,
        };
    }
}

export default Variables;
