import Form from '../Form';

export default class SourceTagValidatorHelper {
    constructor(sourceTag) {
        this.sourceTag = sourceTag;
    }

    checkSourceTag(sources) {
        const fieldsToCheck = [
            {
                fieldName: 'sourceTag',
                fieldType: 'text',
                fieldValue: this.sourceTag,
            },
            {
                fieldName: 'sourceTagAgainstSources',
                fieldType: 'unique',
                fieldValue: this.sourceTag,
                against: sources,
            },
        ];

        const errors = Form.multiCheck(fieldsToCheck);

        if (errors.length > 0) {
            throw errors;
        }
    }
}
