import 'isomorphic-fetch';
import Ajax from './../helpers/Ajax';
import Time from './../helpers/Time';
import GoogleAnalytics from './../helpers/GoogleAnalytics';
import User from '../helpers/User';
import Log from '../helpers/Log';
import Constants from '../helpers/Constants';
import { resetChunkStatus, updateChunkStatus } from '../actions/ChunkStatusActions';
import Chunk from '../helpers/Chunk';
import AbortControllerHelper from '../helpers/AbortControllerHelper';

const postChunksMiddleware = (store) => (next) => (action) => {
    if (action.hasOwnProperty('meta') === false || action.meta.requestMethod !== 'POST') {
        return next(action);
    }

    if (
        action.hasOwnProperty('payload') === false ||
        action.payload.hasOwnProperty('candidateIds') === false
    ) {
        return next(action);
    }

    if (
        action.hasOwnProperty('payload') === true &&
        action.payload.hasOwnProperty('ignoreChunks') === true &&
        action.payload.ignoreChunks === true
    ) {
        return next(action);
    }

    if (action.payload.candidateIds.length <= Constants.POST_CHUNK_SIZE) {
        return next(action);
    }

    if (
        action.hasOwnProperty('meta') === true &&
        (action.meta.hasOwnProperty('requestData') === true ||
            action.meta.hasOwnProperty('requestRoute') === true)
    ) {
        const chunks = [];
        let chunk = {};

        const chunksCountToPost = Math.ceil(
            action.payload.candidateIds.length / Constants.POST_CHUNK_SIZE
        );

        for (let i = 0; i < chunksCountToPost; i++) {
            const requestBody = new FormData();

            if (action.meta.requestBody instanceof FormData) {
                for (const entry of action.meta.requestBody.entries()) {
                    if (entry[0] === 'candidateIds') {
                        requestBody.set(
                            entry[0],
                            JSON.stringify(
                                JSON.parse(action.meta.requestBody.get('candidateIds')).slice(
                                    i * Constants.POST_CHUNK_SIZE,
                                    (i + 1) * Constants.POST_CHUNK_SIZE
                                )
                            )
                        );
                    } else {
                        requestBody.set(entry[0], entry[1]);
                    }
                }
            } else {
                Object.keys(action.meta.requestBody).forEach((parameter) =>
                    parameter === 'candidateIds'
                        ? requestBody.set(
                              'candidateIds',
                              JSON.stringify(
                                  action.meta.requestBody.candidateIds.slice(
                                      i * Constants.POST_CHUNK_SIZE,
                                      (i + 1) * Constants.POST_CHUNK_SIZE
                                  )
                              )
                          )
                        : requestBody.set(parameter, action.meta.requestBody[parameter])
                );
            }

            chunk = {
                ...action.meta,
                type: action.type,
                payload: action.hasOwnProperty('payload') ? action.payload : {},
                requestBody,
            };

            chunks.push(chunk);
        }

        postChunks(store, chunks);
    }

    return next(action);
};

const postChunks = (store, chunks, chunkIndex = 0, finalResult = {}) => {
    if (chunkIndex === 0) {
        store.dispatch(resetChunkStatus());
    }

    const chunk = chunks[chunkIndex];
    const requestMethod =
        chunk.hasOwnProperty('requestMethod') === true ? chunk.requestMethod : 'GET';
    const requestBody = chunk.hasOwnProperty('requestBody') === true ? chunk.requestBody : '';

    const isLastChunk = chunkIndex + 1 === chunks.length;

    const startTime = Time.getTime();

    fetch(
        Ajax.getFetchLink(chunk),
        Ajax.getFetchParams(requestMethod, requestBody, AbortControllerHelper.getSignal())
    )
        .then((response) => {
            const actionDuration = Time.getTime(startTime, 'milliseconds', 2);

            if (User.consoleLogEnabled(store.getState().loginManagerModel) === true) {
                Log.time(
                    'AJAX request for ' +
                        chunk.type +
                        '_' +
                        (chunkIndex + 1) +
                        ' performed in ' +
                        actionDuration +
                        ' ms'
                );
            }

            GoogleAnalytics.sendActionDuration('Post Chunk Actions', chunk.type, actionDuration);

            return response.json();
        })
        .then((chunkResult) => {
            finalResult = Chunk.mergeResults(finalResult, chunkResult, isLastChunk);

            store.dispatch(updateChunkStatus(chunkIndex + 1, chunks.length));

            if (isLastChunk === true) {
                store.dispatch({
                    type: chunk.onRequestSuccess,

                    payload: {
                        ...finalResult,
                        chunked: true,
                    },

                    meta: {
                        requestPayload: chunk.payload,
                    },

                    error: false,
                });
            } else {
                return postChunks(store, chunks, chunkIndex + 1, finalResult);
            }
        });
};

export default postChunksMiddleware;
