export const REQUEST_GLOBAL_SEARCH_MODEL = 'REQUEST_GLOBAL_SEARCH_MODEL';
export const RECEIVE_GLOBAL_SEARCH_MODEL = 'RECEIVE_GLOBAL_SEARCH_MODEL';
export const REQUEST_CANDIDATE_IDS = 'REQUEST_CANDIDATE_IDS';
export const RECEIVE_CANDIDATE_IDS = 'RECEIVE_CANDIDATE_IDS';
export const LIVE_SEARCH = 'LIVE_SEARCH';
export const LIVE_SEARCH_SUCCESS = 'LIVE_SEARCH_SUCCESS';
export const LIVE_SEARCH_FAILURE = 'LIVE_SEARCH_FAILURE';
export const GLOBAL_SEARCH = 'GLOBAL_SEARCH';
export const GLOBAL_SEARCH_SUCCESS = 'GLOBAL_SEARCH_SUCCESS';
export const GLOBAL_SEARCH_FAILURE = 'GLOBAL_SEARCH_FAILURE';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const CLEAR_LIVE_SEARCH = 'CLEAR_LIVE_SEARCH';
export const LOAD_CANDIDATES = 'LOAD_CANDIDATES';
export const LOAD_CANDIDATES_SUCCESS = 'LOAD_CANDIDATES_SUCCESS';
export const LOAD_CANDIDATES_FAILURE = 'LOAD_CANDIDATES_FAILURE';
export const CLEAR_BASIC_CANDIDATES_DATA = 'CLEAR_BASIC_CANDIDATES_DATA';
export const CLEAR_CANDIDATES_DATA = 'CLEAR_CANDIDATES_DATA';
export const CONFIRM_FILTER = 'CONFIRM_FILTER';

export const requestGlobalSearchModel = (
    candidateKeyword = '',
    fromB2bDashboard = false,
    b2bDashboardCandidatesData = undefined
) => ({
    type: REQUEST_GLOBAL_SEARCH_MODEL,

    payload: {
        candidateName: candidateKeyword,
        fromB2bDashboard,
        b2bDashboardCandidatesData: b2bDashboardCandidatesData,
    },

    meta: {
        requestRoute: 'v1/search/model/candidates',

        requestData: {
            candidateKeyword,
            fromB2bDashboard: fromB2bDashboard === true ? 1 : 0,
            b2bDashboardCandidatesData:
                b2bDashboardCandidatesData === undefined
                    ? null
                    : JSON.stringify(b2bDashboardCandidatesData),
        },

        onRequestSuccess: RECEIVE_GLOBAL_SEARCH_MODEL,
    },
});

export const setSearchText = (searchText) => ({
    type: SET_SEARCH_TEXT,
    payload: {
        searchText,
    },
});

export const clearLiveSearch = () => ({
    type: CLEAR_LIVE_SEARCH,
});

export const liveSearch = (userId, searchText, requestToken, maxResultsCount = 5) => ({
    type: LIVE_SEARCH,

    payload: {
        requestToken: requestToken,
        searchText: searchText,
    },

    meta: {
        requestRoute: 'v1/search/data/candidates/live',

        requestData: {
            userId: userId,
            searchText: encodeURI(searchText),
            requestToken: requestToken,
            maxResultsCount: maxResultsCount,
        },

        onRequestSuccess: LIVE_SEARCH_SUCCESS,
        onRequestFailure: LIVE_SEARCH_FAILURE,
    },
});

export const loadCandidates = (
    candidateIds,
    fromB2bDashboard = false,
    b2bDashboardCandidatesData = undefined
) => ({
    type: LOAD_CANDIDATES,

    payload: {
        candidateIds,
        fromB2bDashboard,
        b2bDashboardCandidatesData: b2bDashboardCandidatesData,
    },

    meta: {
        requestRoute: 'v1/search/data/candidates',

        requestData: {
            candidateIds: JSON.stringify(candidateIds),
            fromB2bDashboard: fromB2bDashboard === true ? 1 : 0,
            b2bDashboardCandidatesData:
                b2bDashboardCandidatesData === undefined
                    ? null
                    : JSON.stringify(b2bDashboardCandidatesData),
        },

        onRequestSuccess: LOAD_CANDIDATES_SUCCESS,
        onRequestFailure: LOAD_CANDIDATES_FAILURE,
    },
});

export const globalSearch = (
    candidateIds,
    candidateKeyword,
    candidateTagIds = '',
    candidateSourceIds = '',
    scoreResult = 0,
    addDate = {},
    fromB2bDashboard = false,
    b2bDashboardCandidatesData = undefined
) => ({
    type: GLOBAL_SEARCH,

    payload: {
        candidateName: candidateKeyword,
        candidateIds,
        fromB2bDashboard,
        b2bDashboardCandidatesData: b2bDashboardCandidatesData,
    },

    meta: {
        requestRoute: 'v1/search/data/candidates/global',

        requestData: {
            candidateKeyword,
            candidateTagIds,
            candidateSourceIds,
            candidateIds: JSON.stringify(candidateIds),
            candidateQuestionnaireResult: scoreResult,
            candidateAddDate: Object.keys(addDate).length > 0 ? JSON.stringify(addDate) : '',
            fromB2bDashboard: fromB2bDashboard === true ? 1 : 0,
            b2bDashboardCandidatesData:
                b2bDashboardCandidatesData === undefined
                    ? null
                    : JSON.stringify(b2bDashboardCandidatesData),
        },

        onRequestSuccess: GLOBAL_SEARCH_SUCCESS,
        onRequestFailure: GLOBAL_SEARCH_FAILURE,
    },
});

export const requestCandidateIds = (
    candidateKeyword,
    candidateTagIds = '',
    candidateSourceIds = '',
    scoreResult = 0,
    addDate = {},
    fromB2bDashboard = false,
    b2bDashboardCandidatesData = undefined
) => ({
    type: REQUEST_CANDIDATE_IDS,

    payload: {
        candidateName: candidateKeyword,
        fromB2bDashboard,
        b2bDashboardCandidatesData: b2bDashboardCandidatesData,
    },

    meta: {
        requestRoute: 'v1/search/data/candidates/ids',

        requestData: {
            candidateKeyword,
            candidateTagIds,
            candidateSourceIds,
            candidateQuestionnaireResult: scoreResult,
            candidateAddDate: Object.keys(addDate).length > 0 ? JSON.stringify(addDate) : '',
            fromB2bDashboard: fromB2bDashboard === true ? 1 : 0,
            b2bDashboardCandidatesData:
                b2bDashboardCandidatesData === undefined
                    ? null
                    : JSON.stringify(b2bDashboardCandidatesData),
        },

        onRequestSuccess: RECEIVE_CANDIDATE_IDS,
    },
});

export const clearBasicCandidatesData = () => ({
    type: CLEAR_BASIC_CANDIDATES_DATA,
});

export const clearCandidatesData = () => ({
    type: CLEAR_CANDIDATES_DATA,
});

export const confirmFilter = () => ({
    type: CONFIRM_FILTER,
});
