import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { getInputIcon } from '@profesia/adamui/components/common/icons/helpers';
//TODO create components in separate files from paths
const mappedIconTypes = {
    checked: {
        primary: {
            path: (_jsxs(_Fragment, { children: [_jsx("rect", { width: "18", height: "18", rx: "4", fill: "#2774F0" }), _jsx("path", { d: "M12.8154 6L8.05878 10.6758L6.17986 8.83319L5 9.99196L8.05957 13L14 7.15802L12.8154 6Z", fill: "#FEFEFE" })] })),
        },
        disabled: {
            path: (_jsxs("g", { opacity: "0.5", children: [_jsx("rect", { width: "18", height: "18", rx: "4", fill: "#7F7F7F" }), _jsx("path", { d: "M12.8154 6L8.05878 10.6758L6.17986 8.83319L5 9.99196L8.05957 13L14 7.15802L12.8154 6Z", fill: "#FEFEFE" })] })),
        },
        collapsible: {
            path: (_jsxs(_Fragment, { children: [_jsx("rect", { width: "18", height: "18", rx: "4", fill: "#2774F0" }), _jsx("rect", { x: "5", y: "8", width: "8", height: "2", fill: "white" })] })),
        },
    },
    unchecked: {
        primary: {
            path: (_jsx("rect", { x: "0.5", y: "0.5", width: "17", height: "17", rx: "3.5", fill: "white", stroke: "#506B95" })),
        },
        disabled: {
            path: (_jsx("rect", { x: "0.5", y: "0.5", width: "17", height: "17", rx: "3.5", fill: "white", stroke: "#AAAAAA" })),
        },
        collapsible: {
            path: (_jsxs(_Fragment, { children: [_jsx("rect", { width: "18", height: "18", rx: "4", fill: "#2774F0" }), _jsx("rect", { x: "5", y: "8", width: "8", height: "2", fill: "white" }), _jsx("rect", { x: "8", y: "13", width: "8", height: "2", transform: "rotate(-90 8 13)", fill: "white" })] })),
        },
    },
};
const CheckboxSquareTreeListIcon = ({ state = 'unchecked', type = 'primary', ...props }) => getInputIcon(mappedIconTypes[state][type].path, 'CheckboxSquareTreeList', state, props);
export default CheckboxSquareTreeListIcon;
