import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AttachmentAssistant from '../AttachmentsAssistant/AttachmentsAssistant';
import QuestionnaireResults from '../QuestionnaireResults/QuestionnaireResults';
import CandidateSidebar from './CandidateSidebar/CandidateSidebar';
import DetailCandidateNoModalTabs from './DetailCandidateNoModalTabs';
import CandidateSidebarNoteEdit from '../Candidate/CandidateSidebar/CandidateSidebarNoteEdit';
import CandidateSidebarHistoryAll from '../Candidate/CandidateSidebar/CandidateSidebarHistoryAll';
import { resetSidebarEditPanel } from '../../actions/SidebarActions';
import Constants from '../../helpers/Constants';
import SidebarHelper from '../../helpers/components/SidebarHelper';
import AttachmentAssistantHelper from '../../helpers/components/AttachmentAssistantHelper';
import CandidateInbox from '../CandidateInbox/CandidateInbox';
import Hooks from '../../helpers/Hooks';
import Slide from '@mui/material/Slide';

const DetailCandidateNoModalContent = (props) => {
    const [visibleSidebar, setVisibleSidebar] = React.useState(false);
    const containerRef = React.useRef(null);

    Hooks.useSkipFirstRender(() => {
        if (props.sidebarModel.isOpen === false) {
            setTimeout(() => {
                setVisibleSidebar(false);
                props.resetSidebarEditPanel(null, { candidateId: props.candidate.id });
            }, 300);
        } else if (props.sidebarModel.isOpen === true) {
            setVisibleSidebar(true);
        }
    }, [props.sidebarModel.isOpen]);

    const renderSidebarEditPanel = ({ sidebarType, sidebarProps }) => {
        const sidebarEditPanelLookup = {
            CandidateSidebarNoteEdit,
            CandidateSidebarHistoryAll,
        };

        if (sidebarType === undefined || sidebarType === null) {
            return null;
        }

        const SidebarEditComponent = sidebarEditPanelLookup[sidebarType];

        return <SidebarEditComponent {...sidebarProps} />;
    };

    const sidebarEditPanel = renderSidebarEditPanel(props.sidebarModel);

    if (props.displaySize.width < Constants.WIDTH_NO_MODAL) {
        return (
            <div className="overflow-auto height_full" ref={containerRef}>
                <Slide
                    className={
                        'ui sidebar-edit sidebar' + (visibleSidebar === true ? ' visible' : ' ')
                    }
                    direction="left"
                    in={props.sidebarModel.isOpen}
                    container={containerRef.current}
                >
                    <div>{sidebarEditPanel}</div>
                </Slide>
                <div className="noModal__sidebar">
                    <CandidateSidebar
                        candidateId={props.candidate.id}
                        displaySize={props.displaySize}
                        isDuplicate={props.isDuplicate}
                        sidebarModel={props.sidebarModel}
                    />
                </div>
                <DetailCandidateNoModalTabs
                    hasJobQuestionnaire={props.hasJobQuestionnaire}
                    activeMenuItem={props.activeMenuItem}
                    attachmentsCount={props.attachmentsCount}
                    hasQuestionnaireResult={props.hasQuestionnaireResult}
                    questionnaireResult={props.questionnaireResult}
                    handleMenuItemClick={props.handleMenuItemClick}
                    candidateInbox={props.candidateInbox}
                    isFetchingCandidateInbox={props.isFetchingCandidateInbox}
                    hasCompanyActiveInbox={props.hasCompanyActiveInbox}
                    isExternalVisitor={props.isExternalVisitor}
                />
                <div className="noModal__content">
                    {props.activeMenuItem === 'attachments' && (
                        <AttachmentAssistant
                            candidateId={props.candidate.id}
                            isDuplicate={props.isDuplicate}
                            modelHashId={AttachmentAssistantHelper.createAttachmentAssistantHashIdForCandidates(
                                [props.candidate.id]
                            )}
                        />
                    )}
                    {props.activeMenuItem === 'questionnaire' && (
                        <QuestionnaireResults
                            candidateId={props.candidate.id}
                            jobLanguageCode={props.candidate.jobLanguageCode}
                        />
                    )}
                    {props.hasCompanyActiveInbox === true && props.activeMenuItem === 'inbox' && (
                        <CandidateInbox candidateId={props.candidate.id} />
                    )}
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="noModal__content">
                {props.activeMenuItem === 'attachments' && (
                    <AttachmentAssistant
                        candidateId={props.candidate.id}
                        isDuplicate={props.isDuplicate}
                        modelHashId={AttachmentAssistantHelper.createAttachmentAssistantHashIdForCandidates(
                            [props.candidate.id]
                        )}
                    />
                )}
                {props.activeMenuItem === 'questionnaire' && (
                    <QuestionnaireResults
                        candidateId={props.candidate.id}
                        jobLanguageCode={props.candidate.jobLanguageCode}
                    />
                )}
                {props.hasCompanyActiveInbox === true && props.activeMenuItem === 'inbox' && (
                    <CandidateInbox candidateId={props.candidate.id} />
                )}
            </div>
            <div className="noModal__sidebar">
                <CandidateSidebar
                    candidateId={props.candidate.id}
                    sidebarEditPanel={sidebarEditPanel}
                    visibleSidebar={visibleSidebar}
                    sidebarModel={props.sidebarModel}
                    displaySize={props.displaySize}
                    isDuplicate={props.isDuplicate}
                />
            </div>
        </>
    );
};

const mapStateToProps = (state, ownProps) => ({
    sidebarModel: SidebarHelper.getActiveModelFromSidebarModel(
        state.sidebarEditPanelLogic,
        ownProps.candidate.id
    ),
    displaySize: state.displaySize,
    jobModel: state.jobModel,
    loginManagerModel: state.loginManagerModel,
});

const mapDispatchToProps = {
    resetSidebarEditPanel,
};

DetailCandidateNoModalContent.propTypes = {
    activeMenuItem: PropTypes.string,
    isDuplicate: PropTypes.bool,
    candidate: PropTypes.object.isRequired,
    displaySize: PropTypes.object.isRequired,
    hasCompanyActiveInbox: PropTypes.bool.isRequired,
};

DetailCandidateNoModalContent.defaultProps = {
    isDuplicate: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailCandidateNoModalContent);
