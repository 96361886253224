import { LoggedUserType } from '../../../interfaces/ModelTypes';

const loggedUserTypeSkeleton: LoggedUserType = {
    userId: 1,
    companyId: 1,
    adminId: 0,
    language: 'sk',
    isExternalUser: false,
    userRole: 1,
};

export default loggedUserTypeSkeleton;
