import React from 'react';
import SidebarMenuListItem from './SidebarMenuListItem';
import Divider from '../../Divider';
import UserRights from '../../../helpers/UserRights';
import { connect } from 'react-redux';
import Constants from '../../../helpers/Constants';
import Skeletons from '../../../helpers/Skeletons';
import headerModelMenuSkeleton from '../../../skeletons/models/headerModelMenuSkeleton';

const SidebarMenuList = ({ headerModelMenu, displaySize, isExternalUser }) => {
    if (isExternalUser === true) {
        return <div />;
    }

    const isMobile = displaySize.isTabletProfesia || displaySize.isMobileProfesia;

    const links = headerModelMenu.filter((item) => {
        return (
            UserRights.isMenuItemHidden(item) === false &&
            (item?.showInSidebar === true ||
                (isMobile === true && item?.showInSidebar === Constants.SHOW_IN_MOBILE_SIDEBAR))
        );
    });

    return (
        <div>
            {links.map((item, i) => (
                <SidebarMenuListItem key={i} item={item} />
            ))}

            <Divider />
        </div>
    );
};

const mapStateToProps = (state) => ({
    displaySize: state.displaySize,
    headerModelMenu: Skeletons.getData(state.headerModel.menu, headerModelMenuSkeleton),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenuList);
