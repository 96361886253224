import Constants from './Constants';
import moment from 'moment';
import Strings from './Strings';

class DateFormatted {
    static getDate(
        datetime: Date | string = 'now',
        dateFormat: string = 'j.n.Y',
        replaceIfDefault: string = '---'
    ): string {
        if (!datetime) {
            return replaceIfDefault ? replaceIfDefault : '';
        }

        let date;

        if (datetime instanceof Date) {
            date = datetime;
        } else {
            if (Strings.isNumeric(datetime)) {
                date = new Date(parseFloat(datetime));
            } else {
                date = datetime === 'now' ? new Date() : new Date(datetime);
            }

            if (!this.isValidDate(date)) {
                date = new Date(this.getAppleDateFormat(datetime));

                if (!this.isValidDate(date)) {
                    return this.replaceIfDefault(datetime, replaceIfDefault);
                }
            }
        }

        datetime = dateFormat
            .replace(/j/gi, date.getDate().toString())
            .replace(/d/gi, ('00' + date.getDate()).slice(-2))
            .replace(/n/gi, (date.getMonth() + 1).toString())
            .replace(/m/gi, ('00' + (date.getMonth() + 1)).slice(-2))
            .replace(/Y/gi, date.getFullYear().toString());

        return this.replaceIfDefault(datetime, replaceIfDefault);
    }

    static replaceIfDefault(datetime: string, replaceWith: string = '---'): string {
        return datetime === Constants.DEFAULT_DATE || datetime === Constants.DEFAULT_DATETIME
            ? replaceWith
            : datetime;
    }

    static getTime(datetime: string = 'now', timeFormat: string = 'H:i:s'): string {
        if (!datetime) {
            return '';
        }

        let date: Date = datetime === 'now' ? new Date() : new Date(datetime);

        if (!this.isValidDate(date)) {
            date = new Date(this.getAppleDateFormat(datetime));

            if (!this.isValidDate(date)) {
                return datetime;
            }
        }

        return timeFormat
            .replace(/H/gi, ('0' + date.getHours()).slice(-2))
            .replace(/i/gi, ('0' + date.getMinutes()).slice(-2))
            .replace(/s/gi, ('0' + date.getSeconds()).slice(-2));
    }

    static getTimestamp(datetime: string = 'now', inMilliseconds: boolean = true): number {
        if (!datetime) {
            return 0;
        }

        let date: Date = datetime === 'now' ? new Date() : new Date(datetime);

        if (!this.isValidDate(date)) {
            date = new Date(this.getAppleDateFormat(datetime));

            if (!this.isValidDate(date)) {
                return 0;
            }
        }

        return inMilliseconds ? date.getTime() : date.getTime() / 1000;
    }

    static getDateTime(
        datetime: string = 'now',
        dateTimeFormat: string = 'j.n.Y H:i:s',
        replaceIfDefault: string = '---'
    ): string {
        if (!datetime) {
            return '';
        }

        let date: Date = datetime === 'now' ? new Date() : new Date(datetime);

        if (!this.isValidDate(date)) {
            date = new Date(this.getAppleDateFormat(datetime));

            if (!this.isValidDate(date)) {
                return this.replaceIfDefault(datetime, replaceIfDefault);
            }
        }

        datetime = dateTimeFormat
            .replace(/j/gi, date.getDate().toString())
            .replace(/d/gi, ('00' + date.getDate()).slice(-2))
            .replace(/n/gi, (date.getMonth() + 1).toString())
            .replace(/m/gi, ('00' + (date.getMonth() + 1)).slice(-2))
            .replace(/Y/gi, date.getFullYear().toString())
            .replace(/H/gi, ('0' + date.getHours()).slice(-2))
            .replace(/i/gi, ('0' + date.getMinutes()).slice(-2))
            .replace(/s/gi, ('0' + date.getSeconds()).slice(-2));

        return this.replaceIfDefault(datetime, replaceIfDefault);
    }

    static addYears(n: number, datetime: string = 'now'): Date {
        const date: Date = datetime === 'now' ? new Date() : new Date(datetime);

        return new Date(date.setFullYear(date.getFullYear() + n));
    }

    static addDays(n: number, datetime: string = 'now'): Date {
        const date: Date = datetime === 'now' ? new Date() : new Date(datetime);

        return new Date(date.setDate(date.getDate() + n));
    }

    static subYears(n: number, datetime: string = 'now'): Date {
        const date: Date = datetime === 'now' ? new Date() : new Date(datetime);

        return new Date(date.setFullYear(date.getFullYear() - n));
    }

    static subMonths(n: number, datetime: string = 'now'): Date {
        const date: Date = datetime === 'now' ? new Date() : new Date(datetime);

        return new Date(date.setMonth(date.getMonth() - n));
    }

    static subDays(n: number, datetime: string = 'now'): Date {
        const date: Date = datetime === 'now' ? new Date() : new Date(datetime);

        return new Date(date.setDate(date.getDate() - n));
    }

    static addMonths(n: number, datetime: string = 'now'): Date {
        const date: Date = datetime === 'now' ? new Date() : new Date(datetime);

        return new Date(date.setMonth(date.getMonth() + n));
    }

    static getUnformattedDate(date: string): string {
        return date
            .replace(/[^0-9.]*/gi, '')
            .split('.')
            .reverse()
            .join('-');
    }

    static isValidDate(dateObject: Date): boolean {
        if (Object.prototype.toString.call(dateObject) === '[object Date]') {
            return !isNaN(dateObject.getTime());
        }

        return false;
    }

    /**
     * Apple devices use datetime in format 'mm/dd/YY' otherwise Date() results in "Invalid Date" message
     */
    static getAppleDateFormat(datetime: string): string {
        const [date, time] = datetime.split(' ');
        const [year, month, day] = date ? date.split('-') : [];

        return [month, day, year].join('/') + (time === undefined ? '' : ' ' + time);
    }

    /**
     * Convert from '27.1.2018 12:40:22' to object needed in the react-datetime datepicker
     */
    static convertFormattedDateToDateObject(formattedDate: string): Date {
        const [date, time] = formattedDate.split(' ');
        let [day, month, year] = date ? date.split('.') : [];
        let [hours, minutes, seconds] = time ? time.split(':') : [];

        return new Date(
            parseInt(year ?? ''),
            parseInt(month ?? ''),
            parseInt(day ?? ''),
            parseInt(hours ?? ''),
            parseInt(minutes ?? ''),
            parseInt(seconds ?? '')
        );
    }

    static isValidFormat(date: string, format: string = 'Y-m-d'): boolean {
        const separator: string =
            format
                .replace('Y', '')
                .replace('m', '')
                .replace('d', '')
                .replace('H', '')
                .replace('i', '')
                .replace('s', '')[0] ?? '-';

        const formatArray: string[] = format.split(separator);
        const dateArray: string[] = date.split(separator);

        let isValid: boolean = true;

        if (dateArray.length !== formatArray.length) {
            return false;
        }

        formatArray.forEach((formatChunk, index) => {
            if (isValid) {
                const dateArrayChunk: string = dateArray[index] ?? '';

                isValid =
                    (formatChunk === 'd' &&
                        !isNaN(parseInt(dateArrayChunk)) &&
                        parseInt(dateArrayChunk) >= 1 &&
                        parseInt(dateArrayChunk) <= 31) ||
                    (formatChunk === 'm' &&
                        !isNaN(parseInt(dateArrayChunk)) &&
                        parseInt(dateArrayChunk) >= 1 &&
                        parseInt(dateArrayChunk) <= 12) ||
                    (formatChunk === 'Y' &&
                        !isNaN(parseInt(dateArrayChunk)) &&
                        dateArrayChunk.length === 4) ||
                    (formatChunk === 'H' &&
                        !isNaN(parseInt(dateArrayChunk)) &&
                        dateArrayChunk.length === 2 &&
                        parseInt(dateArrayChunk) >= 0 &&
                        parseInt(dateArrayChunk) <= 23) ||
                    (formatChunk === 'i' &&
                        !isNaN(parseInt(dateArrayChunk)) &&
                        dateArrayChunk.length === 2 &&
                        parseInt(dateArrayChunk) >= 0 &&
                        parseInt(dateArrayChunk) <= 59) ||
                    (formatChunk === 's' &&
                        !isNaN(parseInt(dateArrayChunk)) &&
                        dateArrayChunk.length === 2 &&
                        parseInt(dateArrayChunk) >= 0 &&
                        parseInt(dateArrayChunk) <= 59);
            }
        });

        return isValid;
    }

    /**
     * Get days count from today to dateTo
     */
    static getDaysCountFromToday(dateTo: string, alwaysPositive: boolean = true): number {
        const unixToday: number = new Date().getTime();
        const unixDateTo: number = new Date(dateTo).getTime();

        const timeDifference: number = (unixDateTo - unixToday) / 1000 / 60 / 60 / 24;

        return alwaysPositive ? Math.max(Math.ceil(timeDifference), 0) : Math.ceil(timeDifference);
    }

    static getDateYearsAgo(years: number = 1, format: string = 'j.n.Y'): string {
        return DateFormatted.getDate(
            '' + (DateFormatted.getTimestamp() - 1000 * 60 * 60 * 24 * 365 * years),
            format
        );
    }

    static getDateTimeYearsAgo(years: number = 1, format: string = 'j.n.Y H:i:s'): string {
        return DateFormatted.getDateTime(
            '' + (DateFormatted.getTimestamp() - 1000 * 60 * 60 * 24 * 365 * years),
            format
        );
    }

    static getDateObject(date: string = 'now'): Date {
        return date === 'now' ? new Date() : new Date(date);
    }

    static isInLastIncompleteWeekOfMonth(day: number, month: number, year: number): boolean {
        const date: Date = new Date(year, month, day);
        const m: moment.Moment = moment(date);

        return m.endOf('month').format('W') === m.format('W') && m.endOf('month').day() !== 0;
    }

    static isInFirstIncompleteWeekOfMonth(day: number, month: number, year: number): boolean {
        const date: Date = new Date(year, month, day);
        const m: moment.Moment = moment(date);

        const startOfDate: Date = new Date(year, month, 1);
        const startOfM: moment.Moment = moment(startOfDate);

        return startOfM.format('W') === m.format('W') && startOfM.day() !== 1;
    }
}

export default DateFormatted;
