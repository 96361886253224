import {
    requestAdditionalServices,
    recieveAdditionalServices,
} from '../../../../../../assets/jsx/actions/JobOrderActions';
import apiRequest from '../apiRequest';
import { PartialOrderRootState } from '../CreateJobOrder';
import {
    AdditionalService,
    JobOrderAdditionalServicesResponse,
    JobOrderTiersResponse,
    TiersService,
    TiersServiceDesc,
    OrderModalContent,
} from '../types';

export const getJobOrderTiers = async (companyId: number, language: string) => {
    const jsonResponse = await apiRequest<JobOrderTiersResponse>(
        `business-model/${companyId}/tiers?language=${language}`,
        {
            method: 'GET',
        }
    );
    return jsonResponse;
};

export const getJobOrderAdditionalServices = async (
    companyId: number,
    selectedTier: string,
    language: string
) => {
    const jsonResponse = await apiRequest<JobOrderAdditionalServicesResponse>(
        `business-model/${companyId}/additional-services/${selectedTier}?language=${language}`,
        {
            method: 'GET',
        }
    );
    return jsonResponse;
};

export const handleGetAdditionalServices = (
    companyId: number,
    selectedTier: string,
    language: string,
    dispatch: any
): Promise<JobOrderAdditionalServicesResponse | null> => {
    return new Promise(async (resolve, reject) => {
        if (!selectedTier) {
            return resolve(null);
        }

        dispatch(requestAdditionalServices());

        try {
            const data = await getJobOrderAdditionalServices(companyId, selectedTier, language);
            dispatch(recieveAdditionalServices(data));
            return resolve(data);
        } catch (error) {
            dispatch(recieveAdditionalServices(null));
            return reject(
                error instanceof Error
                    ? error
                    : new Error('Unknown error occurred loading job order additional services')
            );
        }
    });
};

export const getTierDescItems = (tierService: TiersService, serviceDescs: TiersServiceDesc[]) => {
    return serviceDescs.filter((desc: TiersServiceDesc) => {
        return tierService?.desc.items.includes(desc.id);
    });
};

export type OrderModelState = NonNullable<PartialOrderRootState['orderModel']>;

export const getFinalPriceObject = (state: OrderModelState) => {
    const orderCurrency = state.tiers?.currency;
    const selectedServices = state.selectedAdditionalServices;
    const selectedTier = state.tiers?.services?.find(
        (tier: TiersService) => tier.id === state.selectedTier
    );

    let finalPrice = selectedTier?.price?.brutto || 0;
    selectedServices?.forEach((service: AdditionalService) => {
        finalPrice += service?.price?.brutto || 0;
    });

    return {
        price: finalPrice,
        currency: orderCurrency,
    };
};

export const getOrderModalContent = (
    content: TiersServiceDesc | AdditionalService
): OrderModalContent => {
    const isAdditionalService = (
        content: TiersServiceDesc | AdditionalService
    ): content is AdditionalService => 'price' in content;

    return {
        id: content.id,
        title: isAdditionalService(content)
            ? (content.desc.example?.title ?? content.title)
            : content.example.title,
        desc: isAdditionalService(content)
            ? (content.desc.example?.description ?? content.desc.text)
            : content.example.desc,
        imageUrl: isAdditionalService(content)
            ? (content.desc.example?.imageUrl ?? '')
            : content.example.imageUrl,
    };
};
