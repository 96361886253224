import Form from '../Form';
import Translations from '../Translations';

export default class QuestionnaireValidatorHelper {
    constructor(questionnaire) {
        this.questionnaire = questionnaire;
    }

    checkQuestionnaireTitle() {
        // kontrola nazvu dotaznika
        const fieldsToCheck = [
            {
                fieldName: 'templateTitle',
                fieldType: 'text',
                fieldValue: this.questionnaire.name,
            },
        ];

        return Form.multiCheck(fieldsToCheck);
    }

    checkUniqueQuestionnaireTitle(against) {
        const fieldsToCheck = [
            {
                fieldName: 'uniqueQuestionnaireTitle',
                fieldType: 'unique',
                fieldValue: this.questionnaire.name,
                against: against,
            },
        ];

        return Form.multiCheck(fieldsToCheck);
    }

    prepareQuestionsLengthRule() {
        // kontrola poctu otazok
        return {
            fieldName: 'questionsLength',
            fieldType: 'boolean',
            fieldValue: this.questionnaire.questions.length > 0,
        };
    }

    prepareNotEmptyQuestionsRules() {
        // kontrola ci su vsetky otazky vyplnene
        return this.questionnaire.questions.map((question, index) => {
            return {
                fieldName: 'questionsTitle#' + index,
                fieldType: 'text',
                fieldValue: question.question,
            };
        });
    }

    prepareUniqueQuestionsRules() {
        // kontrola unikatnosti otazok
        return this.questionnaire.questions.map((question, index) => {
            return {
                fieldName: 'questionsUniqueTitle#' + index,
                fieldType: 'unique',
                fieldValue: question.question,
                against: this.questionnaire.questions
                    .filter((compareQuestion, compareIndex) => {
                        return index !== compareIndex;
                    })
                    .map((question) => question.question), // kazda otazka sa kontroluje voci ostatnym otazkam, kontrolovana je odfiltrovana
            };
        });
    }

    prepareNotEmptyAnswersRules() {
        //kontrola ci su vsetky odpovede vyplnene
        let fieldsToCheck = [];
        this.questionnaire.questions.forEach((question, questionIndex) => {
            question.answers.forEach((answer, answerIndex) => {
                fieldsToCheck.push({
                    fieldName: 'answerTitle#' + questionIndex + '#' + answerIndex,
                    fieldType: 'text',
                    fieldValue: answer.answer,
                });
            });
        });

        return fieldsToCheck;
    }

    prepareUniqueAnswersRules() {
        // kontrola unikatnosti odpovedi
        let fieldsToCheck = [];

        this.questionnaire.questions.forEach((question, questionIndex) => {
            question.answers.forEach((answer, answerIndex) => {
                fieldsToCheck.push({
                    // unikatnost odpovedi sa kontroluje v ramci otazky
                    fieldName: 'answerUniqueTitle#' + questionIndex + '#' + answerIndex,
                    fieldType: 'unique',
                    fieldValue: answer.answer,
                    against: question.answers
                        .filter((compareAnswer, compareIndex) => {
                            return answerIndex !== compareIndex;
                        })
                        .map((answer) => answer.answer), // kazda odpoved sa kontroluje voci ostatnym odpovediam z otazky, kontrolovana je odfiltrovana
                });
            });
        });

        return fieldsToCheck;
    }

    checkQuestions() {
        let fieldsToCheck = [this.prepareQuestionsLengthRule()];

        fieldsToCheck = fieldsToCheck.concat(this.prepareNotEmptyQuestionsRules());
        fieldsToCheck = fieldsToCheck.concat(this.prepareUniqueQuestionsRules());
        fieldsToCheck = fieldsToCheck.concat(this.prepareNotEmptyAnswersRules());
        fieldsToCheck = fieldsToCheck.concat(this.prepareUniqueAnswersRules());

        return Form.multiCheck(fieldsToCheck);
    }

    checkQuestionnaire(withAlerts = true, checkTitle = true, against = []) {
        let errors = [];

        if (checkTitle === true) {
            errors = this.checkQuestionnaireTitle();

            if (errors.length > 0) {
                return errors;
            }

            if (against.length > 0) {
                errors = this.checkUniqueQuestionnaireTitle(against);

                if (errors.length > 0) {
                    if (errors.indexOf('uniqueQuestionnaireTitle') > -1 && withAlerts === true) {
                        alert(Translations.getStatic('uniqueQuestionnaireNameError'));
                    }

                    return errors;
                }
            }
        }

        errors = this.checkQuestions();

        if (errors.length > 0) {
            const emptyQuestionErrors = errors.filter(
                (error) => error.search('questionsTitle#') > -1
            );
            const uniqueQuestionErrors = errors.filter(
                (error) => error.search('questionsUniqueTitle#') > -1
            );
            const emptyAnswerErrors = errors.filter((error) => error.search('answerTitle#') > -1);
            const uniqueAnswerErrors = errors.filter(
                (error) => error.search('answerUniqueTitle#') > -1
            );

            if (errors.indexOf('questionsLength') > -1) {
                if (withAlerts === true) {
                    alert(Translations.getStatic('enterQuestionError'));
                }

                return errors;
            } else if (emptyQuestionErrors.length > 0) {
                if (withAlerts === true) {
                    alert(Translations.getStatic('writeAllQuestionsError'));
                }

                return emptyQuestionErrors;
            } else if (uniqueQuestionErrors.length > 0) {
                if (withAlerts === true) {
                    alert(Translations.getStatic('uniqueQuestionsError'));
                }

                return uniqueQuestionErrors.map((error) => error.replace('Unique', ''));
            } else if (emptyAnswerErrors.length > 0) {
                if (withAlerts === true) {
                    alert(Translations.getStatic('writeAllAnswersError'));
                }

                return emptyAnswerErrors;
            } else if (uniqueAnswerErrors.length > 0) {
                if (withAlerts === true) {
                    alert(Translations.getStatic('uniqueAnswersError'));
                }

                return uniqueAnswerErrors.map((error) => error.replace('Unique', ''));
            }
        }

        return [];
    }
}
