import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import { closeModal } from '../../actions/ModalActions';
import SettingsPageCalendarSettingsGoogleCalendar from '../Settings/SettingsPageCalendarSettingsGoogleCalendar';
import { googleLogOut, setGoogleCalendar } from '../../actions/CalendarActions';
import Translations from '../../helpers/Translations';

class SettingsPageGoogleCalendarSettingsModal extends Component {
    state = {
        calendarId: null,
        savedCalendarId: null,
    };

    componentDidMount = () => {
        this.setCalendars();
    };

    componentDidUpdate(prevProps) {
        if (
            JSON.stringify(this.props.google.calendars) !==
            JSON.stringify(prevProps.google.calendars)
        ) {
            this.setCalendars();
        }

        if (
            prevProps.isSynchronizingCalendar === true &&
            this.props.isSynchronizingCalendar === false
        ) {
            this.props.closeModal();
        }
    }

    setCalendars = () => {
        let calendarId = null;
        let savedCalendarId = null;

        if (this.props.google.calendars !== null) {
            const savedCalendar = this.props.google.calendars.find(
                (calendar) => calendar.selected === true
            );

            if (savedCalendar === undefined) {
                const primaryCalendar = this.props.google.calendars.find(
                    (calendar) => calendar.primary === true
                );

                if (primaryCalendar === undefined) {
                    calendarId = this.props.google.calendars[0].calendarId;
                } else {
                    calendarId = primaryCalendar.calendarId;
                }
            } else {
                calendarId = savedCalendar.calendarId;
                savedCalendarId = savedCalendar.calendarId;
            }

            this.setState({
                calendarId,
                savedCalendarId,
            });
        }
    };

    googleCalendarChanged = (calendarId) => {
        this.setState({
            calendarId,
        });
    };

    setCalendarId = () => {
        const formData = new FormData();

        formData.append('calendarId', this.state.calendarId);

        this.props.setGoogleCalendar(this.state.calendarId, formData);
    };

    render() {
        const { isOpen, closeModal, isLoggedInGoogle, isSettingCalendar, googleLogOut } =
            this.props;

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={closeModal}
                closeButtonText={Translations.getStatic('close')}
                actionButton={{
                    visible: isLoggedInGoogle === true && this.state.calendarId !== null,
                    text: Translations.getStatic('saveConnection'),
                    disabled: isSettingCalendar === true,
                    onClick: this.setCalendarId,
                }}
                entityOption={{
                    colorRed: true,
                    visible: isLoggedInGoogle === true,
                    text: Translations.getStatic('signOutGoogle'),
                    disabled: isSettingCalendar === true,
                    onClick: googleLogOut,
                }}
                title={Translations.getStatic('googleCalSettings')}
                content={
                    <SettingsPageCalendarSettingsGoogleCalendar
                        {...this.props}
                        calendarId={this.state.calendarId}
                        googleCalendarChanged={(calendarId) =>
                            this.googleCalendarChanged(calendarId)
                        }
                    />
                }
            />
        );
    }
}

SettingsPageGoogleCalendarSettingsModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    isOpen: state.modalLogic.isOpen,
    user: state.settingsModel.user,
    google: state.settingsModel.calendars.google,
    isLoggedInGoogle: state.settingsModel.calendars.google.isLoggedInGoogle || false,
    isSettingCalendar: state.isSettingCalendar || false,
    isSynchronizingCalendar: state.isSynchronizingCalendar || false,
});

const mapDispatchToProps = {
    closeModal,
    googleLogOut,
    setGoogleCalendar,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingsPageGoogleCalendarSettingsModal);
