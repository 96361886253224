import React from 'react';
import { connect } from 'react-redux';
import Item from '../../Item';
import Icon from '../../Icon';
import DateFormatted from '../../../helpers/DateFormatted';
import PropTypes from 'prop-types';
import { openModal } from '../../../actions/ModalActions';
import Translations from '../../../helpers/Translations';
import Url from '../../../helpers/Url';
import Constants from '../../../helpers/Constants';
import User from '../../../helpers/User';
import Link from '../../../helpers/overridden/Link';
import Popup from '../../Popup';

const SourceInfo = ({ source, recommendation = null, addDate, sourceTags, history }) => {
    if (source.id === Constants.SOURCE_ID_RECOMMENDATION_FORM && recommendation !== null) {
        return Translations.getStatic('recommendationFrom').replace(
            /%recommender%/g,
            recommendation.name + ', ' + recommendation.email
        );
    }

    if (source.id === Constants.SOURCE_ID_MOVED) {
        const lastMoveHistoryItem =
            history !== undefined
                ? history.find(
                      (historyItem) =>
                          historyItem.bulkActions.find(
                              (action) => action.type === Constants.HISTORY_TYPE_MOVE
                          ) !== undefined
                  )
                : undefined;

        let movedFromSourceText = Translations.getStatic('movedFromSourceText').replace(
            /%createdAt%/g,
            DateFormatted.getDate(addDate)
        );

        return lastMoveHistoryItem !== undefined
            ? movedFromSourceText.replace(
                  /%jobTitle%/g,
                  lastMoveHistoryItem.bulkActions.find(
                      (action) => action.type === Constants.HISTORY_TYPE_MOVE
                  ).value.from.title
              )
            : movedFromSourceText;
    }

    return (
        Translations.getStatic('reactionFrom') +
        ' ' +
        DateFormatted.getDate(addDate) +
        ', ' +
        Translations.getSource(source, sourceTags)
    );
};

const CandidateSidebarInfo = ({
    jobTitle,
    jobPlace,
    candidateId,
    source,
    sourceTags,
    email,
    phone,
    isJobUnpaid,
    jobId,
    recommendation,
    questionnaireResultsData,
    loginManagerModel,
    addDate,
    history,
    isExternalVisitor,
    onStoreCandidatePhoneCallIntoHistory,
    openModal,
}) => {
    return (
        <div className="ui items unstackable">
            <Item>
                <Icon className="icon-position_small" size="mini" />
                <Item.Content>
                    <Item.Header as="h4">
                        {isJobUnpaid === true ? (
                            <Link
                                to={Url.buildJobLink(
                                    jobId,
                                    User.getDefaultJobViewId(loginManagerModel)
                                )}
                                className="primary-link txt-medium"
                                gtmTag="candidate-detail-unpaid-job-link"
                            >
                                {jobTitle}
                            </Link>
                        ) : (
                            jobTitle
                        )}
                    </Item.Header>
                    <Item.Meta>{jobPlace}</Item.Meta>
                </Item.Content>
            </Item>
            <Item>
                <Icon className="icon-reaction_small" size="mini" />
                <Item.Content>
                    <SourceInfo
                        source={source}
                        recommendation={recommendation}
                        sourceTags={sourceTags}
                        addDate={addDate}
                        history={history}
                    />
                </Item.Content>
            </Item>

            {questionnaireResultsData !== undefined &&
                questionnaireResultsData.hasOwnProperty('expectedSalary') === true &&
                questionnaireResultsData.expectedSalary !== null &&
                questionnaireResultsData.expectedSalary !== '' && (
                    <Item>
                        <Popup
                            content={Translations.getStatic('expectedSalary')}
                            trigger={
                                <>
                                    <Icon className="icon-expected-salary" size="mini" />
                                    <Item.Content>
                                        {questionnaireResultsData.expectedSalary}
                                    </Item.Content>
                                </>
                            }
                        />
                    </Item>
                )}

            {questionnaireResultsData !== undefined &&
                questionnaireResultsData.hasOwnProperty('disposableDate') === true &&
                questionnaireResultsData.disposableDate !== null &&
                questionnaireResultsData.disposableDate !== '' && (
                    <Item>
                        <Popup
                            content={Translations.getStatic('disposableDate')}
                            trigger={
                                <>
                                    <Icon className="icon-disposable-date" size="mini" />
                                    <Item.Content>
                                        {DateFormatted.getDate(
                                            questionnaireResultsData.disposableDate
                                        )}
                                    </Item.Content>
                                </>
                            }
                        />
                    </Item>
                )}

            <Item>
                <Icon className="icon-phone_small" size="mini" />
                <Item.Content>
                    {isExternalVisitor === false && phone ? (
                        <a
                            className="secondary-link"
                            href={`tel:${phone}`}
                            onClick={() => onStoreCandidatePhoneCallIntoHistory(candidateId)}
                        >
                            {phone}
                        </a>
                    ) : (
                        <span>{phone ? phone : '-'}</span>
                    )}
                </Item.Content>
            </Item>
            <Item>
                <Icon className="icon-email_small" size="mini" />
                <Item.Content>
                    {isExternalVisitor === false && email ? (
                        <a
                            className="secondary-link"
                            onClick={() =>
                                openModal('SendEmailModal', {
                                    candidateIds: [candidateId],
                                    emailType: Constants.EMAIL_TYPE_TO_CANDIDATE,
                                })
                            }
                        >
                            {email}
                        </a>
                    ) : (
                        <span>{email ? email : '-'}</span>
                    )}
                </Item.Content>
            </Item>
        </div>
    );
};

const mapDispatchToProps = {
    openModal,
};

CandidateSidebarInfo.propTypes = {
    jobId: PropTypes.number.isRequired,
    jobTitle: PropTypes.string.isRequired,
    jobPlace: PropTypes.string.isRequired,
    source: PropTypes.object.isRequired,
    addDate: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    isJobUnpaid: PropTypes.bool.isRequired,
};

export default connect(null, mapDispatchToProps)(CandidateSidebarInfo);
