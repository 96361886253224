import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '../Button';
import Grid from '../Grid';
import Heading from '../Heading';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import { closeModal, openModalSecond } from '../../actions/ModalActions';
import Translations from '../../helpers/Translations';
import DateFormatted from '../../helpers/DateFormatted';
import MarkDialog from '../Modal/MarkDialog';
import { deleteUserOutOfOfficeEmail } from '../../actions/OutOfOfficeEmailActions';
import SuccessMessage from '../../helpers/SuccessMessage';
import Sort from '../../helpers/Sort';

class SettingsPageOutOfOfficeModal extends Component {
    state = {
        dialogOpen: false,
        deleteOutOfOfficeEmailId: null,
    };

    componentDidUpdate = (prevProps) => {
        if (
            SuccessMessage.canShowSuccessMessage(
                prevProps,
                this.props,
                'isDeletingUserOutOfOfficeEmail'
            ) &&
            this.props.outOfOfficeEmailModel.hasOwnProperty('isSuccess') === true &&
            this.props.outOfOfficeEmailModel.isSuccess === true
        ) {
            this.closeDeleteOutOfOfficeEmailDialog();
        }
    };

    openCreateOutOfOfficeEmailModal = () => {
        this.props.openModalSecond('EditOutOfOfficeEmailModal');
    };
    openEditOutOfOfficeEmailModal = (outOfOfficeEmail) => {
        this.props.openModalSecond('EditOutOfOfficeEmailModal', { outOfOfficeEmail });
    };
    openDeleteOutOfOfficeEmailDialog = (outOfOfficeEmailId) => {
        this.setState({ dialogOpen: true, deleteOutOfOfficeEmailId: outOfOfficeEmailId });
    };
    closeDeleteOutOfOfficeEmailDialog = () => {
        this.setState({ dialogOpen: false, deleteOutOfOfficeEmailId: null });
    };

    render() {
        const { isOpen, closeModal, outOfOfficeEmails, isDeletingUserOutOfOfficeEmail } =
            this.props;

        return (
            <>
                <MarkModal
                    isOpen={isOpen}
                    actionButton={{ visible: false }}
                    closeModal={closeModal}
                    title={Translations.getStatic('outOfOffice')}
                    content={
                        <>
                            <Heading as="h3">
                                {Translations.getStatic('outOfOfficesListTitle')}
                            </Heading>

                            <Grid>
                                {outOfOfficeEmails.length > 0 ? (
                                    Sort.asText(outOfOfficeEmails, 'startAt', 'DESC').map(
                                        (outOfOfficeEmail) => (
                                            <Grid.Row key={outOfOfficeEmail.id}>
                                                <Grid.Column>
                                                    <div className="segment stage new-stage">
                                                        <Grid>
                                                            <Grid.Row
                                                                verticalAlign="middle"
                                                                className="flex justify between"
                                                            >
                                                                <Grid.Column width={10}>
                                                                    {DateFormatted.getDate(
                                                                        outOfOfficeEmail.startAt
                                                                    )}{' '}
                                                                    -{' '}
                                                                    {DateFormatted.getDate(
                                                                        outOfOfficeEmail.endAt
                                                                    )}
                                                                </Grid.Column>
                                                                <Grid.Column
                                                                    textAlign="right"
                                                                    width={6}
                                                                >
                                                                    <Button
                                                                        className="primary-button"
                                                                        onClick={() =>
                                                                            this.openEditOutOfOfficeEmailModal(
                                                                                outOfOfficeEmail
                                                                            )
                                                                        }
                                                                    >
                                                                        {Translations.getStatic(
                                                                            'edit'
                                                                        )}
                                                                    </Button>
                                                                    <Button
                                                                        className="secondary-button negative"
                                                                        onClick={() =>
                                                                            this.openDeleteOutOfOfficeEmailDialog(
                                                                                outOfOfficeEmail.id
                                                                            )
                                                                        }
                                                                    >
                                                                        {Translations.getStatic(
                                                                            'delete'
                                                                        )}
                                                                    </Button>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </div>
                                                </Grid.Column>
                                            </Grid.Row>
                                        )
                                    )
                                ) : (
                                    <Grid.Row>
                                        <Grid.Column>
                                            {Translations.getStatic('emptyOutOfOfficesList')}
                                        </Grid.Column>
                                    </Grid.Row>
                                )}

                                <Grid.Row>
                                    <Grid.Column>
                                        <br />
                                        <div
                                            role="link"
                                            title={Translations.getStatic('addOutOfOffice')}
                                            className="primary-link"
                                            onClick={this.openCreateOutOfOfficeEmailModal}
                                        >
                                            {Translations.getStatic('addOutOfOffice')}
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </>
                    }
                />

                {this.state.dialogOpen === true && (
                    <MarkDialog
                        message={{
                            title: Translations.getStatic('deleteOufOfOfficeEmail'),
                            infoLines: [Translations.getStatic('deleteOufOfOfficeEmailQuestion')],
                            iconName: 'icon-removeJob',
                        }}
                        actions={[
                            {
                                title: Translations.getStatic('remove'),
                                action: () =>
                                    this.props.deleteUserOutOfOfficeEmail(
                                        this.state.deleteOutOfOfficeEmailId
                                    ),
                                type: 'negative',
                                loading: isDeletingUserOutOfOfficeEmail,
                                disabled: isDeletingUserOutOfOfficeEmail,
                            },
                            {
                                title: Translations.getStatic('cancel'),
                                action: () => this.closeDeleteOutOfOfficeEmailDialog(),
                            },
                        ]}
                    />
                )}
            </>
        );
    }
}

SettingsPageOutOfOfficeModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    isOpen: state.modalLogic.isOpen,
    user: state.settingsModel.user,
    outOfOfficeEmails: state.settingsModel.outOfOfficeEmails,
    outOfOfficeEmailModel: state.outOfOfficeEmailModel,
    isDeletingUserOutOfOfficeEmail: state.isDeletingUserOutOfOfficeEmail,
});

const mapDispatchToProps = {
    closeModal,
    openModalSecond,
    deleteUserOutOfOfficeEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPageOutOfOfficeModal);
