import React from 'react';
import { connect } from 'react-redux';
import { liveSearch, setSearchText, clearLiveSearch } from '../../actions/GlobalSearchActions';
import LoadingCircle from '@profesia/adamui/components/common/loadings/LoadingCircle';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Button from '../Button';
import Grid from '../Grid';
import Heading from '../Heading';
import Icon from '../Icon';
import CandidateAvatar from '../CandidateAvatar';
import Url from '../../helpers/Url';
import Constants from '../../helpers/Constants';
import Translations from '../../helpers/Translations';
import JobHelper from '../../helpers/components/JobHelper';
import CandidateDetailOpenHelper from '../../helpers/components/CandidateDetailOpenHelper';
import { closeSidebarSearch } from '../../actions/SidebarHeaderActions';
import Link from '../../helpers/overridden/Link';
import User from '../../helpers/User';
import InternalInfo from '../InternalInfo/InternalInfo';

class GlobalSearchInput extends React.Component {
    state = {
        selectedId: '',
        started: false,
        open: true,
    };

    componentDidMount = () => {
        this.timer = null;
        this.blurTimer = null;
    };

    componentWillUnmount = () => {
        clearTimeout(this.timer);
        clearTimeout(this.blurTimer);
    };

    handleResultSelect = (result, searchText, selectItem) => {
        if (result.data.type === Constants.GLOBAL_SEARCH_TYPE_CANDIDATE) {
            if (
                CandidateDetailOpenHelper.openCandidateDetail({
                    candidateId: result.data.id,
                    ...result.data,
                }) === true
            ) {
                this.setState({
                    selectedId: result.data.id,
                });

                selectItem
                    .closest('.' + this.props.searchClassName)
                    .querySelector('input')
                    .blur();

                if (this.props.isOpenSearch === true) {
                    this.props.closeSidebarSearch();
                }
            }
        } else if (result.data.type === Constants.GLOBAL_SEARCH_TYPE_GLOBAL_LINK) {
            this.props.clearLiveSearch();
        }
    };

    handleSearch = (value) => {
        const maxResultsCount = Constants.GLOBAL_SEARCH_RESULTS_COUNT;
        const requestToken = value + '-' + maxResultsCount;

        if (value.length === 0) {
            this.props.clearLiveSearch();
        } else {
            this.props.setSearchText(value);
        }

        this.setState({
            started: false,
        });

        if (this.timer !== null) {
            clearTimeout(this.timer);
        }

        this.timer = setTimeout(() => {
            if (
                value.length >= 3 &&
                this.props.liveSearchModel.results.hasOwnProperty(requestToken) === false
            ) {
                this.setState({
                    started: true,
                });

                this.props.liveSearch(
                    User.getUserId(this.props.loginManagerModel),
                    value,
                    requestToken,
                    maxResultsCount + 1
                );
            }
        }, 400);
    };

    handleClearInput = () => {
        this.props.clearLiveSearch();
    };

    render = () => {
        const { loginManagerModel } = this.props;
        const { started, selectedId } = this.state;
        const { isFetching, results, searchText, requestToken } = this.props.liveSearchModel;

        const preparedResults =
            results.hasOwnProperty(requestToken) === true
                ? results[requestToken].map((result, i) =>
                      Object.assign(
                          {},
                          {
                              key: result.id,
                              data: Object.assign({}, result, {
                                  type: Constants.GLOBAL_SEARCH_TYPE_CANDIDATE,
                                  last: i === results[requestToken].length - 1,
                              }),
                              title: result.firstname + ' ' + result.surname,
                          }
                      )
                  )
                : [];

        let options = {
            candidates: {
                name: 'candidates',
                results: preparedResults.slice(0, Constants.GLOBAL_SEARCH_RESULTS_COUNT),
            },
            searchPage: {
                name: 'searchPageLink',
                result: {
                    key: 'searchPageLink',
                    data: {
                        type: Constants.GLOBAL_SEARCH_TYPE_GLOBAL_LINK,
                        url: Url.build('search', { searchText: encodeURI(searchText) }),
                    },
                    title:
                        preparedResults.length > Constants.GLOBAL_SEARCH_RESULTS_COUNT
                            ? Translations.getStatic('displayAllCandidates')
                            : Translations.getStatic('advancedSearch'),
                },
            },
        };

        if (
            preparedResults.length === 0 &&
            searchText.length >= 3 &&
            isFetching === false &&
            started === true
        ) {
            options = Object.assign(
                {},
                {
                    empty: {
                        name: 'empty',
                        result: {
                            key: 'empty',
                            data: {
                                type: Constants.GLOBAL_SEARCH_TYPE_EMPTY,
                            },
                            title:
                                Translations.getStatic('notFoundCandidateName') +
                                ' "' +
                                searchText +
                                '"',
                        },
                    },
                },
                options
            );
        }

        return (
            <Grid>
                <Grid.Column>
                    <div className={'form-group__search-input ' + this.props.searchClassName}>
                        {(Url.isNotOnline() === true ||
                            User.isSuperAdmin(loginManagerModel) === true) &&
                            loginManagerModel.hasCompanyActivatedElasticSearchForCandidates ===
                                true && <InternalInfo text={'Using Elasticsearch'} />}

                        <TextField
                            autoFocus={true}
                            input={{ autoFocus: true }}
                            value={searchText}
                            endAdornment={
                                <InputAdornment position="end">
                                    {isFetching && <LoadingCircle size="16px" />}
                                </InputAdornment>
                            }
                            onChange={(e) => {
                                this.handleSearch(e.target.value);
                            }}
                        />
                        <div className="results transition">
                            <div className="category">
                                <div className="result">
                                    {options.candidates.results.map((result, i) => (
                                        <div
                                            key={i}
                                            onClick={(e) =>
                                                this.handleResultSelect(
                                                    result,
                                                    searchText,
                                                    e.target
                                                )
                                            }
                                            className={
                                                'candidate-card candidate-universal' +
                                                (result.data.id === selectedId ? ' active' : '')
                                            }
                                        >
                                            <div className="card-block_top">
                                                <div className="box box_1">
                                                    <CandidateAvatar
                                                        surname={result.data.surname}
                                                        firstname={result.data.firstname}
                                                        profilePhoto={result.data.profilePhoto}
                                                    />
                                                </div>
                                                <div className="box box_2">
                                                    <h5 className="ellipsis">
                                                        {result.title}
                                                        {result.data.isLocked === true ? (
                                                            <Icon className="icon-lock mini" />
                                                        ) : null}
                                                        {JobHelper.isUnpaid(
                                                            result.data.jobStateId
                                                        ) === true ? (
                                                            <Icon name={'exclamation circle'} />
                                                        ) : null}
                                                    </h5>
                                                    <div className="ellipsis two">
                                                        <span className="text-small">
                                                            {result.data.jobTitle}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="box box_3">
                                                    <h4>&nbsp;</h4>
                                                    {result.data?.hasDuplicities === true && (
                                                        <span className="tag tag-primary">
                                                            {Translations.getStatic('duplicity')}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {options.empty && (
                                <div className="global-search__notResults">
                                    <Heading as="h6" icon>
                                        <Icon className="icon-notResults" />
                                        {options.empty.result.title}
                                    </Heading>
                                </div>
                            )}

                            {options.searchPage && (
                                <div className="global-search__link">
                                    <Link
                                        to={options.searchPage.result.data.url}
                                        gtmTag="advanced-search"
                                    >
                                        <Button icon>
                                            {options.searchPage.result.title}
                                            <Icon className="icon-arrow_right-blue" size="small" />
                                        </Button>
                                    </Link>
                                </div>
                            )}
                        </div>
                        {isFetching === false && searchText.length > 0 && (
                            <Button
                                icon={{ className: 'button icon-cancel' }}
                                onClick={this.handleClearInput}
                            />
                        )}
                    </div>
                </Grid.Column>
            </Grid>
        );
    };
}

const mapStateToProps = (state) => ({
    loginManagerModel: state.loginManagerModel,
    liveSearchModel: state.liveSearchModel,
    isOpenSearch: state.sidebarHeaderLogic.isOpenSearch,
});

const mapDispatchToProps = {
    setSearchText,
    clearLiveSearch,
    liveSearch,
    closeSidebarSearch,
};

GlobalSearchInput.defaultProps = {
    searchClassName: 'global-search',
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearchInput);
