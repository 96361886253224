import {
    RECEIVE_QUESTIONNAIRES_SETTINGS_MODEL,
    REQUEST_QUESTIONNAIRES_SETTINGS_MODEL,
    PROCESS_QUESTIONNAIRE,
    PROCESS_QUESTIONNAIRE_FAILURE,
    PROCESS_QUESTIONNAIRE_SUCCESS,
    REMOVE_QUESTIONNAIRE,
    REMOVE_QUESTIONNAIRE_FAILURE,
    REMOVE_QUESTIONNAIRE_SUCCESS,
} from '../actions/QuestionnairesSettingsActions';
import TemplateAssistantHelper from '../helpers/components/TemplateAssistantHelper';

export const questionnairesSettingsModel = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_QUESTIONNAIRES_SETTINGS_MODEL:
            return {
                ...state,
                isSuccess: false,
            };
        case RECEIVE_QUESTIONNAIRES_SETTINGS_MODEL:
            return {
                ...state,
                ...action.payload,
            };
        case PROCESS_QUESTIONNAIRE_SUCCESS:
            let questionnaires =
                action.meta.requestPayload.savingMode ===
                TemplateAssistantHelper.TEMPLATE_SAVING_MODE_UPDATE
                    ? state.questionnaires.filter(
                          (questionnaire) => questionnaire.id !== action.meta.requestPayload.id
                      )
                    : state.questionnaires;

            questionnaires.push(action.payload.questionnaire);

            return {
                ...state,
                questionnaires: questionnaires,
                isSuccess: true,
            };
        case REMOVE_QUESTIONNAIRE:
            return {
                ...state,
                removedQuestionnaire: state.questionnaires.find(
                    (questionnaire) => parseInt(action.payload.id) === questionnaire.id
                ),
                questionnaires: state.questionnaires.filter(
                    (questionnaire) => parseInt(action.payload.id) !== questionnaire.id
                ),
            };
        case REMOVE_QUESTIONNAIRE_FAILURE:
            return {
                ...state,
                questionnaires: state.questionnaires.concat([state.removedQuestionnaire]),
                isSuccess: true,
            };
        case REMOVE_QUESTIONNAIRE_SUCCESS:
            return {
                ...state,
                isSuccess: true,
            };
        default:
            return state;
    }
};

export const isFetchingQuestionnairesSettings = (state = false, action) => {
    switch (action.type) {
        case REQUEST_QUESTIONNAIRES_SETTINGS_MODEL:
            return true;
        case RECEIVE_QUESTIONNAIRES_SETTINGS_MODEL:
            return false;
        default:
            return state;
    }
};

export const isProcessingQuestionnaire = (state = false, action) => {
    switch (action.type) {
        case PROCESS_QUESTIONNAIRE:
        case REMOVE_QUESTIONNAIRE:
            return true;
        case PROCESS_QUESTIONNAIRE_SUCCESS:
        case PROCESS_QUESTIONNAIRE_FAILURE:
        case REMOVE_QUESTIONNAIRE_SUCCESS:
        case REMOVE_QUESTIONNAIRE_FAILURE:
            return false;
        default:
            return state;
    }
};
