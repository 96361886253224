export const DRAG_START = 'DRAG_START';
export const DRAG_STOP = 'DRAG_STOP';

export const startDrag = () => ({
    type: DRAG_START,
});

export const stopDrag = () => ({
    type: DRAG_STOP,
});
