import {
    REQUEST_CLOSE_JOB_MODEL,
    RECEIVE_CLOSE_JOB_MODEL,
    ENABLE_CLOSE_JOB_STEP,
    DISABLE_SENDING_EMAIL,
    ACTIVATE_CLOSE_JOB_STEP,
    ENABLE_SENDING_EMAIL,
    ENABLE_REFUSING_CANDIDATES,
    DISABLE_REFUSING_CANDIDATES,
    SET_CANDIDATE_IDS_FOR_EMAIL_SENDING,
    SET_CANDIDATES_EMAIL_SENDING_STATE,
} from '../actions/CloseJobActions';
import CloseJobHelper from '../helpers/components/CloseJobHelper';
import {
    CLOSE_JOB_DETAIL,
    CLOSE_JOB_FAILURE_DETAIL,
    CLOSE_JOB_SUCCESS_DETAIL,
} from '../actions/JobDetailActions';
import { CLOSE_JOB_FAILURE } from '../actions/JobsListActions';

export const isFetchingCloseJobModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_CLOSE_JOB_MODEL:
            return true;
        case RECEIVE_CLOSE_JOB_MODEL:
            return false;
        default:
            return state;
    }
};

export const isClosingJob = (state = false, action) => {
    switch (action.type) {
        case CLOSE_JOB_DETAIL:
            return true;
        case CLOSE_JOB_SUCCESS_DETAIL:
        case CLOSE_JOB_FAILURE_DETAIL:
            return false;
        default:
            return state;
    }
};

const defaultState = {
    jobId: null,
};

export const closeJobModel = (state = defaultState, action) => {
    switch (action.type) {
        case REQUEST_CLOSE_JOB_MODEL:
            return defaultState;
        case RECEIVE_CLOSE_JOB_MODEL:
            return {
                ...state,
                ...action.payload,
            };
        case ACTIVATE_CLOSE_JOB_STEP:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    formSteps: state.settings.formSteps.map((step) => ({
                        ...step,
                        active: action.payload.stepAction === step.action,
                    })),
                },
            };
        case ENABLE_CLOSE_JOB_STEP:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    formSteps: state.settings.formSteps.map((step) => ({
                        ...step,
                        enabled: action.payload.stepAction === step.action ? true : step.enabled,
                    })),
                },
            };
        case ENABLE_SENDING_EMAIL:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    enabledSendingEmail: true,
                    emailSendingParams: {
                        emailSendingState: CloseJobHelper.getDefaultEmailSendingState(),
                        candidateIds: CloseJobHelper.getCandidateIdsForEmailSending(
                            state.candidates,
                            CloseJobHelper.getDefaultEmailSendingState()
                        ),
                    },
                },
            };
        case DISABLE_SENDING_EMAIL:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    enabledSendingEmail: false,
                    emailSendingParams: {
                        emailSendingState: CloseJobHelper.getDefaultEmailSendingState(),
                        candidateIds: [],
                    },
                },
            };
        case ENABLE_REFUSING_CANDIDATES:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    enabledRefusingCandidates: true,
                },
            };

        case DISABLE_REFUSING_CANDIDATES:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    enabledRefusingCandidates: false,
                },
            };
        case SET_CANDIDATE_IDS_FOR_EMAIL_SENDING:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    emailSendingParams: {
                        ...state.settings.emailSendingParams,
                        candidateIds: action.payload.candidateIds,
                    },
                },
            };
        case SET_CANDIDATES_EMAIL_SENDING_STATE:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    emailSendingParams: {
                        ...state.settings.emailSendingParams,
                        emailSendingState: action.payload.emailSendingState,
                    },
                },
            };
        case CLOSE_JOB_SUCCESS_DETAIL:
            return {
                ...state,
                isSuccess: true,
            };
        default:
            return state;
    }
};
