import React from 'react';
import Grid from '../Grid';
import Icon from '../Icon';
import Heading from '../Heading';
import PropTypes from 'prop-types';
import CandidateEmailAttachmentColumn from './CandidateEmailAttachmentColumn';
import BulkUploadEmailAttachmentColumn from './BulkUploadEmailAttachmentColumn';
import Translations from '../../helpers/Translations';
import Language from '../../helpers/Language';

class FileUploadAttachmentsList extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        candidateAttachments: this.props.candidateAttachments.map((attachment) => ({
            ...attachment,
            checked: true,
        })),
    };

    attachmentToggleChecked = (attachmentId, checked) => {
        this.setState({
            candidateAttachments: this.state.candidateAttachments.map((attachment) => ({
                ...attachment,
                checked: attachment.attachmentId === attachmentId ? checked : attachment.checked,
            })),
        });
    };

    render() {
        const {
            attachmentTypes,
            language,
            bulkUploadAttachments,
            settings,
            onAddAttachmentToEmail,
            onRemoveAttachmentFromEmail,
            onRemoveAttachmentFromBulk,
        } = this.props;

        return (
            <>
                <Grid className="data-block data-block_primary">
                    <Grid.Row className="padding-bottom_none">
                        <Grid.Column>
                            <Heading as="h5" className="data-block__header">
                                <Icon className="icon-link" size="mini" />
                                <Heading.Content>
                                    {Translations.getStatic('attachments', language)}
                                </Heading.Content>
                            </Heading>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="padding-top_none">
                        <Grid.Column>
                            <div className="ui items unstackable">
                                {this.state.candidateAttachments
                                    .filter(
                                        (attachment) =>
                                            attachment.hasOwnProperty('removed') === false ||
                                            attachment.removed === false
                                    )
                                    .map((attachment, i) => (
                                        <CandidateEmailAttachmentColumn
                                            key={'candidateAttachment' + i}
                                            language={language}
                                            attachment={attachment}
                                            removeAttachmentFromEmail={() =>
                                                onRemoveAttachmentFromEmail(attachment.attachmentId)
                                            }
                                            addAttachmentToEmail={() =>
                                                onAddAttachmentToEmail(attachment)
                                            }
                                            attachmentToggleChecked={this.attachmentToggleChecked}
                                        />
                                    ))}
                                {bulkUploadAttachments
                                    .filter((attachment) =>
                                        attachmentTypes.includes(attachment.typeId)
                                    )
                                    .map((attachment, i) => {
                                        return (
                                            <BulkUploadEmailAttachmentColumn
                                                key={'bulkAttachment' + i}
                                                language={language}
                                                attachment={attachment}
                                                attachmentTypeName={
                                                    settings.attachmentTypes.find(
                                                        (attachmentType) =>
                                                            attachmentType.id === attachment.typeId
                                                    ).name
                                                }
                                                onRemoveAttachmentFromBulk={() =>
                                                    onRemoveAttachmentFromBulk(i)
                                                }
                                            />
                                        );
                                    })}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </>
        );
    }
}

FileUploadAttachmentsList.propTypes = {
    candidateAttachments: PropTypes.array.isRequired,
    language: PropTypes.string,
    attachmentTypes: PropTypes.array.isRequired,
    bulkUploadAttachments: PropTypes.array.isRequired,
    liveUploadAttachments: PropTypes.array.isRequired,
    settings: PropTypes.object.isRequired,
    onRemoveAttachmentFromEmail: PropTypes.func,
    onRemoveAttachmentFromBulk: PropTypes.func,
};

FileUploadAttachmentsList.defaultProps = {
    language: Language.get(),
};

export default FileUploadAttachmentsList;
