import { RECIEVE_JOB_ORDER_ADDITIONAL_SERVICES, RECIEVE_JOB_ORDER_TIERS, REQUEST_JOB_ORDER_ADDITIONAL_SERVICES, REQUEST_JOB_ORDER_TIERS, TOGGLE_JOB_ORDER_SELECTED_ADDITIONAL_SERVICE, SET_JOB_ORDER_SELECTED_TIER, CLEAR_JOB_ORDER_SELECTED_ADDITIONAL_SERVICE, UPDATE_JOB_ORDER_FINAL_PRICE, CLEAR_JOB_ORDER_DATA, SET_JOB_ORDER_FORM_VALIDATION, SET_JOB_ORDER_VALIDATION_DATA } from '../actions/JobOrderActions';
import { getFinalPriceObject } from '../components/Job/JobAdvertisement/order/utils';

export const orderModel = (state = {}, action) => {
    switch (action.type) {
        case RECIEVE_JOB_ORDER_TIERS:
            return {
                ...state,
                tiers: action.payload,
            };
        case RECIEVE_JOB_ORDER_ADDITIONAL_SERVICES:
            return {
                ...state,
                additionalServices: action.payload,
            };
        case SET_JOB_ORDER_SELECTED_TIER:
            return {
                ...state,
                selectedTier: action.payload,
            };
        case TOGGLE_JOB_ORDER_SELECTED_ADDITIONAL_SERVICE:
            return {
                ...state,
                selectedAdditionalServices: state.selectedAdditionalServices?.some(
                    (item) => item.id === action.payload.id
                )
                    ? state.selectedAdditionalServices?.filter(
                          (item) => item.id !== action.payload.id
                      )
                    : [...(state.selectedAdditionalServices || []), action.payload],
            };
        case CLEAR_JOB_ORDER_SELECTED_ADDITIONAL_SERVICE:
            return {
                ...state,
                selectedAdditionalServices: [],
            };
        case UPDATE_JOB_ORDER_FINAL_PRICE:
            return {
                ...state,
                finalPrice: getFinalPriceObject(state),
            };
        case CLEAR_JOB_ORDER_DATA:
            return {};
        case SET_JOB_ORDER_FORM_VALIDATION:
            return {
                ...state,
                validation: {
                    ...state.validation,
                    isFormValidationActive: true,
                },
            };
        case SET_JOB_ORDER_VALIDATION_DATA:
            return {
                ...state,
                validation: {
                    ...state.validation,
                    isPzrWellSpecified: action.payload,
                },
            }
        default:
            return state;
    }
};

export const isFetchingJobOrderTiers = (state = false, action) => {
    switch (action.type) {
        case REQUEST_JOB_ORDER_TIERS:
            return true;

        case RECIEVE_JOB_ORDER_TIERS:
            return false;

        default:
            return state;
    }
};

export const isFetchingJobOrderAdditionalServices = (state = false, action) => {
    switch (action.type) {
        case REQUEST_JOB_ORDER_ADDITIONAL_SERVICES:
            return true;

        case RECIEVE_JOB_ORDER_ADDITIONAL_SERVICES:
            return false;

        default:
            return state;
    }
};