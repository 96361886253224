import {
    REQUEST_HEADER,
    RECEIVE_HEADER,
    JOB_DETAIL_HEADER,
    PREVIOUS_PAGE,
} from './../actions/HeaderActions';

export const headerModel = (state = {}, action) => {
    switch (action.type) {
        case RECEIVE_HEADER:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const jobDetailHeader = (state = null, action) => {
    switch (action.type) {
        case JOB_DETAIL_HEADER:
            return {
                ...state,
                ...action.payload.jobData,
            };
        default:
            return state;
    }
};

export const backButton = (state = { location: 'mark/jobs' }, action) => {
    switch (action.type) {
        case PREVIOUS_PAGE:
            return {
                location: action.payload.location,
            };
        default:
            return state;
    }
};

export const isFetchingHeader = (state = false, action) => {
    switch (action.type) {
        case REQUEST_HEADER:
            return true;

        case RECEIVE_HEADER:
            return false;

        default:
            return state;
    }
};
