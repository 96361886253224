export const REQUEST_GOOGLE_CALENDAR_DATA = 'REQUEST_GOOGLE_CALENDAR_DATA';
export const RECEIVE_GOOGLE_CALENDAR_DATA = 'RECEIVE_GOOGLE_CALENDAR_DATA';
export const AUTHENTICATE_GOOGLE = 'AUTHENTICATE_GOOGLE';
export const AUTHENTICATE_GOOGLE_SUCCESS = 'AUTHENTICATE_GOOGLE_SUCCESS';
export const AUTHENTICATE_GOOGLE_FAILURE = 'AUTHENTICATE_GOOGLE_FAILURE';
export const GOOGLE_LOG_OUT = 'GOOGLE_LOG_OUT';
export const GOOGLE_LOG_OUT_SUCCESS = 'GOOGLE_LOG_OUT_SUCCESS';
export const SET_GOOGLE_CALENDAR = 'SET_GOOGLE_CALENDAR';
export const SET_GOOGLE_CALENDAR_SUCCESS = 'SET_GOOGLE_CALENDAR_SUCCESS';
export const REQUEST_MICROSOFT_CALENDAR_DATA = 'REQUEST_MICROSOFT_CALENDAR_DATA';
export const RECEIVE_MICROSOFT_CALENDAR_DATA = 'RECEIVE_MICROSOFT_CALENDAR_DATA';
export const AUTHENTICATE_MICROSOFT = 'AUTHENTICATE_MICROSOFT';
export const AUTHENTICATE_MICROSOFT_SUCCESS = 'AUTHENTICATE_MICROSOFT_SUCCESS';
export const AUTHENTICATE_MICROSOFT_FAILURE = 'AUTHENTICATE_MICROSOFT_FAILURE';
export const MICROSOFT_LOG_OUT = 'MICROSOFT_LOG_OUT';
export const MICROSOFT_LOG_OUT_SUCCESS = 'MICROSOFT_LOG_OUT_SUCCESS';
export const SET_MICROSOFT_CALENDAR = 'SET_MICROSOFT_CALENDAR';
export const SET_MICROSOFT_CALENDAR_SUCCESS = 'SET_MICROSOFT_CALENDAR_SUCCESS';

export const authenticateGoogle = (formData) => ({
    type: AUTHENTICATE_GOOGLE,

    meta: {
        requestRoute: 'v1/calendar/action/authenticate/google',
        requestMethod: 'POST',

        requestBody: formData,

        onRequestSuccess: AUTHENTICATE_GOOGLE_SUCCESS,
        onRequestFailure: AUTHENTICATE_GOOGLE_FAILURE,
    },
});

export const requestGoogleCalendarData = () => ({
    type: REQUEST_GOOGLE_CALENDAR_DATA,

    meta: {
        requestRoute: 'v1/calendar/data/google',
        onRequestSuccess: RECEIVE_GOOGLE_CALENDAR_DATA,
    },
});

export const googleLogOut = () => ({
    type: GOOGLE_LOG_OUT,

    meta: {
        requestRoute: 'v1/calendar/action/log-out/google',
        requestMethod: 'POST',
        onRequestSuccess: GOOGLE_LOG_OUT_SUCCESS,
    },
});

export const setGoogleCalendar = (calendarId, formData) => ({
    type: SET_GOOGLE_CALENDAR,

    payload: {
        calendarId,
    },

    meta: {
        requestRoute: 'v1/calendar/action/set-calendar/google',
        requestMethod: 'POST',

        requestBody: formData,

        onRequestSuccess: SET_GOOGLE_CALENDAR_SUCCESS,
    },
});

export const authenticateMicrosoft = (formData) => ({
    type: AUTHENTICATE_MICROSOFT,

    meta: {
        requestRoute: 'v1/calendar/action/authenticate/microsoft',
        requestMethod: 'POST',

        requestBody: formData,

        onRequestSuccess: AUTHENTICATE_MICROSOFT_SUCCESS,
        onRequestFailure: AUTHENTICATE_MICROSOFT_FAILURE,
    },
});

export const requestMicrosoftCalendarData = () => ({
    type: REQUEST_MICROSOFT_CALENDAR_DATA,

    meta: {
        requestRoute: 'v1/calendar/data/microsoft',
        onRequestSuccess: RECEIVE_MICROSOFT_CALENDAR_DATA,
    },
});

export const microsoftLogOut = () => ({
    type: MICROSOFT_LOG_OUT,

    meta: {
        requestRoute: 'v1/calendar/action/log-out/microsoft',
        requestMethod: 'POST',
        onRequestSuccess: MICROSOFT_LOG_OUT_SUCCESS,
    },
});

export const setMicrosoftCalendar = (calendarId, formData) => ({
    type: SET_MICROSOFT_CALENDAR,

    payload: {
        calendarId,
    },

    meta: {
        requestRoute: 'v1/calendar/action/set-calendar/microsoft',
        requestMethod: 'POST',

        requestBody: formData,

        onRequestSuccess: SET_MICROSOFT_CALENDAR_SUCCESS,
    },
});
