import React from 'react';
import { connect } from 'react-redux';
import { Image } from 'semantic-ui-react';
import hamburgerIcon from './../../img/icons/hamburger.svg';
import { openSidebarMenu } from '../actions/SidebarHeaderActions';

const Header4MenuExtendedButtonHamburger = (props) => (
    <div onClick={props.openSidebarMenu} className="extended-action">
        <div className="extended-action__icon">
            <Image size="tiny" src={hamburgerIcon} />
        </div>
    </div>
);

const mapDispatchToProps = {
    openSidebarMenu,
};

export default connect(null, mapDispatchToProps)(Header4MenuExtendedButtonHamburger);
