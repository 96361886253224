import React from 'react';
import Grid from '../Grid';
import Divider from '../Divider';
import PropTypes from 'prop-types';
import WysiwygEditor from '../WysiwygEditor/WysiwygEditor';
import WysiwygInput from '../WysiwygEditor/WysiwygInput';
import ErrorFocus from '../ErrorFocus';
import Translations from '../../helpers/Translations';
import Form from '../../helpers/Form';
import Validation from '../../helpers/Validation';

class EmailMessageEditor extends React.Component {
    constructor(props) {
        super(props);
    }

    checkField = (fieldValue) => {
        return Form.check({
            fieldType: 'wysiwyg',
            fieldValue,
        });
    };

    render = () => {
        const { emailData, variables, errors, predefinedLanguageCode, cursorOnStart } = this.props;
        const { subject, id, text } = emailData;

        let variableErrors = [];

        if (
            this.props.errors.length > 0 &&
            this.props.errors.find((error) => error.indexOf('UsingVariableWithoutData') > -1) !==
                undefined
        ) {
            this.props.setErrorFocus();

            this.props.errors.forEach((error) => {
                if (error.indexOf('UsingVariableWithoutData') > -1) {
                    variableErrors.push(
                        <Grid.Row key={error}>
                            <Grid.Column width={16}>
                                <div className="prompt label error">
                                    {Validation.getMessage('variable', error)}
                                </div>
                                <Divider hidden />
                            </Grid.Column>
                        </Grid.Row>
                    );
                }
            });
        }

        if (
            this.props.errors.length > 0 &&
            this.props.errors.find((error) => error.indexOf('VariableNotUsed') > -1) !== undefined
        ) {
            this.props.setErrorFocus();

            this.props.errors.forEach((error) => {
                if (error.indexOf('VariableNotUsed') > -1) {
                    variableErrors.push(
                        <Grid.Row key={error}>
                            <Grid.Column width={16}>
                                <div className="prompt label error">
                                    {Validation.getMessage('variable', error)}
                                </div>
                                <Divider hidden />
                            </Grid.Column>
                        </Grid.Row>
                    );
                }
            });
        }

        if (
            this.props.errors.length > 0 &&
            this.props.errors.find((error) => error.indexOf('ForbiddenVariable') > -1) !== undefined
        ) {
            this.props.setErrorFocus();

            this.props.errors.forEach((error) => {
                if (error.indexOf('ForbiddenVariable') > -1) {
                    variableErrors.push(
                        <Grid.Row key={error}>
                            <Grid.Column width={16}>
                                <div className="prompt label error">
                                    {Validation.getMessage('variable', error)}
                                </div>
                                <Divider hidden />
                            </Grid.Column>
                        </Grid.Row>
                    );
                }
            });
        }

        return (
            <>
                <Grid.Row className="horizontal-row">
                    <Grid.Column computer={4} tablet={16} verticalAlign="middle">
                        <label>{Translations.getStatic('subject')}</label>
                    </Grid.Column>
                    <Grid.Column computer={12} tablet={16} verticalAlign="middle">
                        <WysiwygInput
                            fluid
                            error={Array.isArray(errors) === true && errors.indexOf('subject') > -1}
                            highlighted={this.checkField(subject) === false}
                            id={id === null || id === undefined ? 0 : id}
                            value={subject}
                            lang={predefinedLanguageCode}
                            variables={variables}
                            onChange={(value) =>
                                this.props.onUpdateEmailTemplate({ subject: value })
                            }
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <WysiwygEditor
                            error={errors.indexOf('message') > -1}
                            highlighted={this.checkField(text) === false}
                            id={id === null || id === undefined ? 0 : id}
                            lang={predefinedLanguageCode}
                            value={text}
                            cursorOnStart={cursorOnStart}
                            variables={variables}
                            multipleForwardingAsSingleEmail={
                                this.props.multipleForwardingAsSingleEmail
                            }
                            onChange={(value) => this.props.onUpdateEmailTemplate({ text: value })}
                        />
                    </Grid.Column>
                </Grid.Row>

                <ErrorFocus>{variableErrors}</ErrorFocus>
            </>
        );
    };
}

EmailMessageEditor.propTypes = {
    errors: PropTypes.array.isRequired,
    languageId: PropTypes.number.isRequired,
    emailData: PropTypes.object.isRequired,
    variables: PropTypes.array.isRequired,
    onUpdateEmailTemplate: PropTypes.func.isRequired,
    cursorOnStart: PropTypes.bool,
};

EmailMessageEditor.defaultProps = {
    errors: [],
    cursorOnStart: false,
};

export default EmailMessageEditor;
