import Translations from '../Translations';

export default class HeaderHelper {
    static getMenuItemsStrings() {
        return {
            home: Translations.getStatic('home'),
            dashboard: Translations.getStatic('dashboard'),
            jobs: Translations.getStatic('jobs'),
            statistics: Translations.getStatic('stats'),
            database: Translations.getStatic('databaseCV'),
            pipeline: Translations.getStatic('pipeline'),
            candidates: Translations.getStatic('candidates'),
            detail: Translations.getStatic('offerDetails'),
            'candidate-sources': Translations.getStatic('candidatesSources'),
            help: Translations.getStatic('help'),
            settings: Translations.getStatic('settings'),
            dashboard: Translations.getStatic('dashboard'),
            filter: Translations.getStatic('filter'),
            advertisement: Translations.getStatic('advertisement'),
            admin: 'Admin',
        };
    }
}
