import {
    REQUEST_GDPR_MANAGER_MODEL,
    RECEIVE_GDPR_MANAGER_MODEL,
    CONFIRM_GDPR_AGREEMENT,
    CONFIRM_GDPR_AGREEMENT_SUCCESS,
    CONFIRM_GDPR_AGREEMENT_FAILURE,
    REQUEST_GDPR_MANAGER_EXTENDED_MODEL,
    RECEIVE_GDPR_MANAGER_EXTENDED_MODEL,
} from '../actions/GdprActions';

export const isFetchingGdprManagerModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_GDPR_MANAGER_MODEL:
        case REQUEST_GDPR_MANAGER_EXTENDED_MODEL:
            return true;

        case RECEIVE_GDPR_MANAGER_MODEL:
        case RECEIVE_GDPR_MANAGER_EXTENDED_MODEL:
            return false;

        default:
            return state;
    }
};

export const isConfirmingGdprAgreement = (state = false, action) => {
    switch (action.type) {
        case CONFIRM_GDPR_AGREEMENT:
            return true;

        case CONFIRM_GDPR_AGREEMENT_SUCCESS:
        case CONFIRM_GDPR_AGREEMENT_FAILURE:
            return false;

        default:
            return state;
    }
};

const defaultManagerState = {};

export const gdprManagerModel = (state = defaultManagerState, action) => {
    switch (action.type) {
        case REQUEST_GDPR_MANAGER_MODEL:
            return defaultManagerState;

        case RECEIVE_GDPR_MANAGER_MODEL:
        case RECEIVE_GDPR_MANAGER_EXTENDED_MODEL:
            return {
                ...state,
                ...action.payload,
            };

        case CONFIRM_GDPR_AGREEMENT_SUCCESS:
            let trial = {};

            if (
                action.payload.hasOwnProperty('loginData') === true &&
                action.payload.loginData.hasOwnProperty('hasAllowedTrial') === true
            ) {
                trial = {
                    hasAllowedTrial: action.payload.loginData.hasAllowedTrial,
                };
            }

            return {
                ...state,
                ...trial,
                hasCompanyGdprAgreement: action.payload.hasCompanyGdprAgreement,
            };

        default:
            return state;
    }
};
