import React from 'react';
import Grid from '../Grid';
import Item from '../Item';
import Image from '../Image';
import PropTypes from 'prop-types';
import Translations from '../../helpers/Translations';
import fileImage from '../../../img/icons/file.svg';

const BulkUploadAttachmentColumn = ({
    attachment,
    attachmentTypeName,
    onRemoveAttachmentFromBulk,
    isDuplicate,
    language,
}) => (
    <Item className="data-block__content attachment">
        <Image size="large" src={fileImage} />
        <Grid verticalAlign="middle" container>
            <Grid.Row>
                <Grid.Column width={12}>
                    <Item.Header as="h4" className="ellipsis">
                        {attachmentTypeName}
                    </Item.Header>
                    <Item.Meta className="ellipsis">
                        <span>{attachment.file.name}</span>
                    </Item.Meta>
                </Grid.Column>

                {isDuplicate === false && (
                    <Grid.Column width={4} textAlign="right">
                        <a onClick={onRemoveAttachmentFromBulk} className="link secondary-link">
                            {Translations.getStatic('delete', language)}
                        </a>
                    </Grid.Column>
                )}
            </Grid.Row>
        </Grid>
    </Item>
);

BulkUploadAttachmentColumn.propTypes = {
    attachment: PropTypes.object.isRequired,
    attachmentTypeName: PropTypes.string.isRequired,
    onRemoveAttachmentFromBulk: PropTypes.func.isRequired,
    isDuplicate: PropTypes.bool.isRequired,
};

export default BulkUploadAttachmentColumn;
