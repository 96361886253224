import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { openJob, closeJob, activateJob } from '../actions/JobDetailActions';
import PropTypes from 'prop-types';
import Translations from '../helpers/Translations';
import menuDotsImage from '../../img/icons/menu-dots.svg';
import Image from './Image';
import JobHelper from '../helpers/components/JobHelper';
import { openCloseJobUncontactedCandidateDialog } from '../actions/DialogActions';

// TODO: Vyhodiť do samostatného súboru a nahradiť vo všetkých volaniach MARK-3044
const ExtendedButton = ({ toggleActiveClass, open, dropdownActive, displaySize, allOptions }) => {
    return (
        <div onClick={toggleActiveClass} className={'extended-action menu action-menu ' + (dropdownActive === true ? 'active' : 'inactive')}>
            <div className="extended-action__icon-status">
                <Image size={displaySize.isMobileProfesia ? 'small' : 'tiny'} src={menuDotsImage} />
            </div>

            <div className={'extended-action__dropdown'}>
                <Dropdown icon={{ className: 'hidden' }} trigger={displaySize.isMobile === false} open={open} onClick={toggleActiveClass} onClose={toggleActiveClass} direction={'left'}>
                    <Dropdown.Menu className="extended-list">
                        {allOptions.map((option, i) => {
                            if (option.content) {
                                return <Dropdown.Item key={option.key || i} content={option.content} />;
                            }
                            return <Dropdown.Item key={i} {...option} />;
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    );
};

const Header4MenuEllipsisButton = (props) => {
    const [isActiveDropdown, setIsActiveDropdown] = useState(false);

    const handleCloseJob = () => {
        props.closeJob(props.jobId);
        setIsActiveDropdown(true);
    };

    const handleOpenJob = () => {
        props.openJob(props.jobId);
    };

    const toggleActiveClass = () => {
        setIsActiveDropdown(!isActiveDropdown);
    };

    const { stateId, jobId, displaySize, isExternalVisitor, isMobile, positionActionButton, copyPositionButton } = props;

    const dropdownActive = isActiveDropdown === true && isExternalVisitor === false;

    const getBaseOptions = () => {
        const optionsMap = {
            unpaid: [
                {
                    text: Translations.getStatic('activateJob'),
                    onClick: () => props.activateJob(jobId),
                },
            ],
            open: [
                {
                    text: Translations.getStatic('deleteJob'),
                    onClick: handleCloseJob,
                },
            ],
            closed: [
                {
                    text: Translations.getStatic('activateJobs'),
                    onClick: handleOpenJob,
                },
            ],
        };

        if (JobHelper.isPaid(stateId) === false) return optionsMap.unpaid;
        if (JobHelper.isOpen(stateId)) return optionsMap.open;
        if (JobHelper.isClosed(stateId)) return optionsMap.closed;

        return [];
    };

    const getMobileButtons = () => {
        if (!displaySize.isMobile || isExternalVisitor) return [];

        const mobileOptions = [];

        if (positionActionButton) {
            mobileOptions.push({
                content: positionActionButton,
                key: 'position-action',
            });
        }

        if (copyPositionButton) {
            mobileOptions.push({
                content: copyPositionButton,
                key: 'copy-position',
            });
        }

        return mobileOptions;
    };

    const baseOptions = getBaseOptions();

    if (baseOptions.length === 0 && !isMobile) {
        return null;
    }

    const allOptions = [...getMobileButtons(), ...baseOptions];

    return <ExtendedButton open={dropdownActive} toggleActiveClass={toggleActiveClass} dropdownActive={dropdownActive} displaySize={displaySize} allOptions={allOptions} />;
};

const mapStateToProps = (state) => ({
    displaySize: state.displaySize,
});

const mapDispatchToProps = {
    openJob,
    closeJob,
    activateJob,
    openCloseJobUncontactedCandidateDialog,
};

Header4MenuEllipsisButton.propTypes = {
    stateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    jobId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    isExternalVisitor: PropTypes.bool.isRequired,
    candidates: PropTypes.array.isRequired,
    isMobile: PropTypes.bool,
    positionActionButton: PropTypes.node,
    copyPositionButton: PropTypes.node,
};

Header4MenuEllipsisButton.defaultProps = {
    isMobile: false,
    positionActionButton: null,
    copyPositionButton: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header4MenuEllipsisButton);
