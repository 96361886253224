import React from 'react';
import { connect } from 'react-redux';
import MarkModal from '../Modal/MarkModal';
import QuestionnairePreview from '../Questionnaire/QuestionnairePreview';
import { closeModal, closeModalSecond } from '../../actions/ModalActions';
import Translations from '../../helpers/Translations';

const DetailQuestionnaireModal = (props) => {
    const {
        questionnaireData,
        isModalSecond,
        isOpenModal,
        isOpenModalSecond,
        closeModal,
        closeModalSecond,
    } = props;

    return (
        <MarkModal
            isModalSecond={isModalSecond}
            isOpen={isModalSecond ? isOpenModalSecond : isOpenModal}
            closeModal={isModalSecond ? closeModalSecond : closeModal}
            title={Translations.getStatic('questionnairePreview')}
            actionButton={{ visible: false }}
            content={<QuestionnairePreview questionnaireData={questionnaireData} />}
        />
    );
};

const mapStateToProps = (state) => ({
    isOpenModal: state.modalLogic.isOpen,
    isOpenModalSecond: state.modalSecondLogic.isOpen,
});

const mapDispatchToProps = {
    closeModal,
    closeModalSecond,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailQuestionnaireModal);
