import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import { closeModal, openModal } from '../../actions/ModalActions';
import { connect } from 'react-redux';
import { setErrorFocus } from '../../actions/ErrorActions';
import SuccessMessage from '../../helpers/SuccessMessage';
import Translations from '../../helpers/Translations';
import AttachmentAssistantHelper from '../../helpers/components/AttachmentAssistantHelper';
import CandidateGdprAgreement from '../Candidate/CandidateGdprAgreement';
import CandidateGdprAgreementValidatorHelper from '../../helpers/components/CandidateGdprAgreementValidatorHelper';
import { saveCandidateGdprAgreement } from '../../actions/ActionWithCandidateActions';
import Constants from '../../helpers/Constants';
import { openCopyIntoTalentPoolDialog } from '../../actions/DialogActions';
import LoadingComponent from '../LoadingComponent';

class CandidateGdprAgreementModal extends Component {
    state = {
        errors: [],
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            SuccessMessage.canShowSuccessMessage(
                prevProps,
                this.props,
                'isSavingCandidateGdprAgreement'
            ) &&
            this.props.candidateActionModel.hasOwnProperty('isSuccess') === true &&
            this.props.candidateActionModel.isSuccess === true
        ) {
            this.props.closeModal(() => {
                if (this.props.copyCandidate === true) {
                    this.props.openModal('CopyCandidateModal', {
                        candidateIds: [this.props.candidateId],
                    });
                }

                if (this.props.moveCandidate === true) {
                    this.props.openModal('MoveCandidateModal', {
                        candidateIds: [this.props.candidateId],
                    });
                }

                if (this.props.copyIntoTalentPool === true) {
                    this.props.openCopyIntoTalentPoolDialog({
                        candidateId: this.props.candidateId,
                    });
                }
            });
        }
    }

    saveCandidateGdprAgreement = () => {
        try {
            const {
                candidateId,
                candidateActionModel,
                attachmentAssistantModel: { bulkUploadAttachments, settings },
            } = this.props;

            const validator = new CandidateGdprAgreementValidatorHelper(
                candidateActionModel.candidateGdprAgreementParams,
                bulkUploadAttachments
            );
            validator.checkCandidateGdprAgreement(
                settings.totalFileSizeUploadLimit,
                this.props.copyCandidate === true ||
                    this.props.moveCandidate === true ||
                    this.props.copyIntoTalentPool === true
            );

            const actionData = new FormData();

            actionData.append(
                'candidateGdprAgreementParams',
                JSON.stringify(candidateActionModel.candidateGdprAgreementParams)
            );
            actionData.append('candidateId', candidateId);

            bulkUploadAttachments.forEach((attachment) => {
                actionData.append(Constants.ATTACHMENT_TYPE_OTHER + '[]', attachment.file);
            });

            this.props.saveCandidateGdprAgreement(
                actionData,
                candidateId,
                AttachmentAssistantHelper.createAttachmentAssistantHashIdForCandidates([
                    candidateId,
                ])
            );

            this.setState({
                errors: [],
            });
        } catch (error) {
            if (Array.isArray(error) === true) {
                this.setState({
                    errors: error,
                });

                this.props.setErrorFocus();
            }
        }
    };

    render = () => {
        const {
            isOpen,
            isSavingCandidateGdprAgreement,
            copyCandidate,
            moveCandidate,
            jobTitle,
            candidateId,
            gdprAgreement,
        } = this.props;
        const { errors } = this.state;

        const children =
            isSavingCandidateGdprAgreement === true ? (
                <LoadingComponent text={Translations.getStatic('saving') + '...'} />
            ) : (
                <CandidateGdprAgreement
                    candidateId={candidateId}
                    gdprAgreement={gdprAgreement}
                    errors={errors}
                    moveCandidate={moveCandidate || copyCandidate}
                    jobTitle={jobTitle}
                />
            );

        return (
            <>
                <MarkModal
                    isOpen={isOpen}
                    closeModal={this.props.closeModal}
                    actionButton={{
                        text: Translations.getStatic('save'),
                        disabled: isSavingCandidateGdprAgreement,
                        loading: isSavingCandidateGdprAgreement,
                        onClick: () => this.saveCandidateGdprAgreement(),
                    }}
                    title={Translations.getStatic(
                        copyCandidate === true || moveCandidate === true
                            ? 'addGdprAgreement'
                            : 'editGdprAgreement'
                    )}
                    content={children}
                    isLoadingData={false}
                />
            </>
        );
    };
}

CandidateGdprAgreementModal.propTypes = {
    candidateId: PropTypes.number.isRequired,
    isOpen: PropTypes.bool.isRequired,
    gdprAgreement: PropTypes.object,
    copyCandidate: PropTypes.bool,
    moveCandidate: PropTypes.bool,
    copyIntoTalentPool: PropTypes.bool,
};

CandidateGdprAgreementModal.defaultProps = {
    gdprAgreement: null,
    copyCandidate: false,
    moveCandidate: false,
    copyIntoTalentPool: false,
};

const mapStateToProps = (state) => ({
    isOpen: state.modalLogic.isOpen,
    candidateActionModel: state.candidateActionModel,
    isSavingCandidateGdprAgreement: state.isSavingCandidateGdprAgreement,

    attachmentAssistantModel: AttachmentAssistantHelper.getActiveModelFromAssistantAttachmentModel(
        state.attachmentAssistantModel,
        AttachmentAssistantHelper.createAttachmentAssistantHashIdForFileUpload()
    ),

    loginManagerModel: state.loginManagerModel,
});

const mapDispatchToProps = {
    closeModal,
    openModal,
    setErrorFocus,
    saveCandidateGdprAgreement,
    openCopyIntoTalentPoolDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateGdprAgreementModal);
