import Constants from '../../Constants';

export default class RefuseCandidateReducer {
    static process(state, action) {
        if (state.hasOwnProperty('candidates') === false) {
            return state;
        }

        if (
            action.meta.requestPayload.reducerType ===
            Constants.CANDIDATES_REDUCER_TYPE_B2B_DASHBOARD
        ) {
            return this.processRefuseActionOnB2bDashboard(state, action);
        }

        return state;
    }

    static processRefuseActionOnB2bDashboard(state, action) {
        let result = {
            ...state,
        };

        if (
            action.payload.hasOwnProperty('result') === true &&
            state.hasOwnProperty('activeOffers') === true
        ) {
            const refusedCandidate = state.candidates.find(
                (candidate) => candidate.id === action.payload.result[0].candidateId
            );

            result = {
                ...result,
                activeOffers: state.activeOffers.map((offer) => {
                    if (refusedCandidate.jobId === offer.jobId) {
                        return {
                            ...offer,
                            counts: {
                                ...offer.counts,
                                newCandidatesCount: offer.counts.newCandidatesCount - 1,
                            },
                        };
                    }

                    return offer;
                }),

                newCandidatesTotalCount: result.newCandidatesTotalCount - 1,
                candidatesInNewStage: result.candidatesInNewStage.filter(
                    (candidate) => candidate.id !== refusedCandidate.id
                ),
                candidates: result.candidates.filter(
                    (candidate) => candidate.id !== refusedCandidate.id
                ),
                candidateIdsPairs: result.candidateIdsPairs.filter(
                    (pair) => pair.id !== refusedCandidate.id
                ),

                changedNewCandidates:
                    state.changedNewCandidates === undefined
                        ? [refusedCandidate]
                        : state.changedNewCandidates.concat([refusedCandidate]),
            };
        }

        return result;
    }
}
