import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext, useWatch } from 'react-hook-form';
import { Box } from '@mui/material';
import HiddenFields from '@profesia/adamui/components/common/HiddenFields';
import { InputField } from '@profesia/adamui/components/common/input';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { trackEvent } from '@profesia/mark-offer-form/utils/track/track';
import { LOCATION_JOB_AD, NAME_EXPERIENCE_IN_POSITION, } from '@profesia/mark-offer-form/utils/track/constants';
import { FORM_SKILLS_EXPERIENCE_AT_POSITION } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { NARROW_INPUT_WIDTH } from '@profesia/mark-offer-form/components/content/form/constants';
const ExperienceAtPosition = () => {
    const { setValue, control } = useFormContext();
    const value = useWatch({ name: FORM_SKILLS_EXPERIENCE_AT_POSITION, control });
    const t = useGetFixedT('skills.otherSkills.practiceAtPosition');
    return (_jsx(Box, { pt: 1, children: _jsx(HiddenFields, { buttonLabel: t('practice'), shouldBeHidden: !value, children: _jsx(InputField, { label: t('practice'), value: value || '', onChange: ({ target }) => setValue(FORM_SKILLS_EXPERIENCE_AT_POSITION, target.value), inputWidth: NARROW_INPUT_WIDTH, "data-testid": "experience-input" }), onClick: () => trackEvent(LOCATION_JOB_AD, NAME_EXPERIENCE_IN_POSITION, null) }) }));
};
export default ExperienceAtPosition;
