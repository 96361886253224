import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from 'react';
const LogoContext = createContext({
    base64Logo: null,
    sourceImgLogo: null,
    isEdited: true,
    setIsEdited: () => {
        throw new Error('setIsEdited must be used within a LogoProvider');
    },
    setBase64Logo: () => {
        throw new Error('setBase64Logo must be used within a LogoProvider');
    },
    setSourceImgLogo: () => {
        throw new Error('setSourceImgLogo must be used within a LogoProvider');
    },
    setCropData: () => {
        throw new Error('setCropData must be used within a LogoProvider');
    },
});
const LogoProvider = ({ children, base64Logo: initialBase64Logo, sourceImgLogo: initialSourceImgLogo, isEdited: initialIsEdited, cropData: initialCropData, }) => {
    const [base64Logo, setBase64Logo] = useState(initialBase64Logo);
    const [sourceImgLogo, setSourceImgLogo] = useState(initialSourceImgLogo ?? '');
    const [isEdited, setIsEdited] = useState(initialIsEdited);
    const [cropData, setCropData] = useState(initialCropData);
    return (_jsx(LogoContext.Provider, { value: {
            base64Logo,
            setBase64Logo,
            sourceImgLogo,
            setSourceImgLogo,
            isEdited,
            setIsEdited,
            cropData,
            setCropData,
        }, children: children }));
};
export const useLogoContext = () => useContext(LogoContext);
export default LogoProvider;
