import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import { closeModal, openModalSecond } from '../../actions/ModalActions';
import { connect } from 'react-redux';
import LoadingComponent from '../LoadingComponent';
import ScheduleCandidateEvent from '../Candidate/ScheduleCandidateEvent';
import {
    scheduleCandidateEvent,
    deleteCandidateEvent,
} from '../../actions/ActionWithCandidateActions';
import { setErrorFocus } from '../../actions/ErrorActions';
import SendEmailHelper from '../../helpers/components/SendEmailHelper';
import Constants from '../../helpers/Constants';
import SuccessMessage from '../../helpers/SuccessMessage';
import MarkDialog from '../Modal/MarkDialog';
import ScheduleCandidateEventValidatorHelper from '../../helpers/components/ScheduleCandidateEventValidatorHelper';
import Translations from '../../helpers/Translations';
import AttachmentAssistantHelper from '../../helpers/components/AttachmentAssistantHelper';
import DateFormatted from '../../helpers/DateFormatted';
import {
    openAllRecipientsWithoutEmailsDialog,
    openSomeRecipientsWithoutEmailsDialog,
} from '../../actions/DialogActions';
import Arrays from '../../helpers/Arrays';
import ConflictsInCalendarDialog from '../Dialogs/ConflictsInCalendarDialog';
import Variables from '../../helpers/Variables';

class ScheduleCandidateEventModal extends Component {
    state = {
        isDeleteModalOpen: false,
        errors: [],
        isConflictsInCalendarDialogOpen: false,
    };

    componentDidUpdate = (prevProps) => {
        if (
            (SuccessMessage.canShowSuccessMessage(
                prevProps,
                this.props,
                'isSchedulingCandidateEvent'
            ) ||
                SuccessMessage.canShowSuccessMessage(
                    prevProps,
                    this.props,
                    'isDeletingCandidateEvent'
                )) &&
            this.props.candidateActionModel.hasOwnProperty('isSuccess') === true &&
            this.props.candidateActionModel.isSuccess === true
        ) {
            this.props.closeModal();
        }
    };

    openDeleteModal = () => {
        this.setState({
            isDeleteModalOpen: true,
        });
    };

    closeDeleteModal = () => {
        this.setState({
            isDeleteModalOpen: false,
        });
    };

    openConflictsInCalendarDialog = (conflicts) => {
        this.setState({
            isConflictsInCalendarDialogOpen: true,
            conflicts,
        });
    };

    closeConflictsInCalendarDialog = () => {
        this.setState({
            isConflictsInCalendarDialogOpen: false,
            conflicts: [],
        });
    };

    deleteCandidateEvent = (eventId) => {
        let actionData = new FormData();

        actionData.append('eventId', eventId);
        actionData.append('candidateId', this.props.candidateIds[0]);

        this.props.deleteCandidateEvent(actionData, eventId, this.props.candidateIds[0]);

        this.closeDeleteModal();
    };

    scheduleEvent = (excludedCandidateIdsFromSendEmail = [], checkCalendarConflicts = true) => {
        const candidateIds = Arrays.getUniqueArray(this.props.candidateIds);

        const {
            candidateActionModel,
            sendEmailModel,
            attachmentAssistantModel,
            templateAssistant,
            templatesForDropdownSelector,
            loginManagerModel,
            eventType,
        } = this.props;

        try {
            const { scheduleEventParams, nextUserEvents } = candidateActionModel;

            const validator = new ScheduleCandidateEventValidatorHelper(scheduleEventParams);

            if (checkCalendarConflicts === true) {
                validator.checkCandidateEvent(
                    this.props.eventId > 0
                        ? nextUserEvents.filter(
                              (event) => parseInt(event.id) !== parseInt(this.props.eventId)
                          )
                        : nextUserEvents
                );
            } else {
                validator.checkCandidateEvent();
            }

            const requiredVariablesActionTypesToCheck =
                eventType === Constants.CANDIDATE_EVENT_TYPE_VIDEO_INTERVIEW
                    ? [Constants.VARIABLE_ACTION_TYPE_VIDEO_INTERVIEW]
                    : [];

            let actionData = new FormData();

            if (scheduleEventParams.sendEmail === true) {
                actionData = SendEmailHelper.prepareEmail(
                    sendEmailModel,
                    attachmentAssistantModel,
                    templateAssistant,
                    templatesForDropdownSelector,
                    candidateIds,
                    Constants.EMAIL_TYPE_TO_CANDIDATE,
                    {
                        variables: sendEmailModel.settings.variables,
                        filledVariables: Variables.getFilledVariablesCheckParams(
                            [
                                Constants.VARIABLE_ACTION_TYPE_USER,
                                Constants.VARIABLE_ACTION_TYPE_EVENT,
                                Constants.VARIABLE_ACTION_TYPE_VIDEO_INTERVIEW,
                                Constants.VARIABLE_ACTION_TYPE_QUESTIONNAIRE,
                            ],
                            candidateActionModel.candidates,
                            loginManagerModel,
                            sendEmailModel.settings.videoInterview,
                            null,
                            null,
                            scheduleEventParams.startAt
                        ),
                        forbiddenVariables: Variables.getForbiddenVariablesCheckParams([
                            Constants.VARIABLE_ACTION_TYPE_MULTIPLE_FORWARD,
                        ]),
                        requiredVariables: Variables.getRequiredVariablesCheckParams(
                            requiredVariablesActionTypesToCheck
                        ),
                    },
                    excludedCandidateIdsFromSendEmail
                );
            }

            actionData.append('scheduleEventParams', JSON.stringify(scheduleEventParams));
            actionData.append('candidateIds', JSON.stringify(candidateIds));

            this.props.scheduleCandidateEvent(
                actionData,
                this.props.eventId,
                candidateIds,
                candidateActionModel.changeStageOfCandidatesParams.sendEmail === true
            );

            this.setState({
                errors: [],
            });
        } catch (error) {
            if (Array.isArray(error) === true) {
                SendEmailHelper.processRecipientsErrors(
                    error,
                    this.props.openAllRecipientsWithoutEmailsDialog,
                    () =>
                        this.props.openSomeRecipientsWithoutEmailsDialog({
                            candidateIds: candidateIds,
                            onSkip: (excludedCandidateIdsFromSendEmail) =>
                                this.scheduleEvent(excludedCandidateIdsFromSendEmail),
                        })
                );

                this.setState({
                    errors: error,
                });

                this.props.setErrorFocus();
            } else if (
                typeof error === 'object' &&
                error !== null &&
                error.hasOwnProperty('errorName') === true
            ) {
                this.openConflictsInCalendarDialog(error.conflicts);

                this.setState({
                    errors: [error.errorName],
                });

                this.props.setErrorFocus();
            }
        }
    };

    render = () => {
        const {
            isOpen,
            candidateIds,
            candidateActionModel,
            sendEmailModel,
            attachmentAssistantModel,
            eventId,
            eventType,
            eventData,
        } = this.props;

        const { scheduleEventParams } = candidateActionModel;

        let children;

        if (this.props.isSchedulingCandidateEvent === true) {
            children = (
                <LoadingComponent
                    text={Translations.getStatic('saving') + '...'}
                    showProgress={candidateIds.length > Constants.POST_CHUNK_SIZE}
                />
            );
        } else if (this.props.isDeletingCandidateEvent === true) {
            children = <LoadingComponent text={Translations.getStatic('deletingEvent')} />;
        } else {
            children = (
                <ScheduleCandidateEvent
                    candidateIds={candidateIds}
                    eventId={eventId}
                    eventType={eventType}
                    eventData={eventData}
                    errors={this.state.errors}
                />
            );
        }

        const from =
            sendEmailModel.settings && sendEmailModel.settings.companyUsers
                ? sendEmailModel.settings.companyUsers.find(
                      (user) => user.id === sendEmailModel.sender
                  )?.email
                : '';
        const to = sendEmailModel.recipients.map((recipient) => recipient.value);
        const body = sendEmailModel.emailTemplateData ? sendEmailModel.emailTemplateData.text : '';
        const subject = sendEmailModel.emailTemplateData
            ? sendEmailModel.emailTemplateData.subject
            : '';
        const languageId = sendEmailModel.emailTemplateData
            ? sendEmailModel.emailTemplateData.languageId
            : 1;
        const useCompanyTemplate = true;

        const emailData = {
            from,
            to,
            bcc: sendEmailModel.bcc.map((bccEmail) => bccEmail.value),
            subject,
            body,
            attachments:
                AttachmentAssistantHelper.getAttachmentsForEmailPreview(attachmentAssistantModel),
            attachmentSettings: attachmentAssistantModel.settings,
            languageId,
            candidateId: candidateIds.length > 0 ? candidateIds[0] : 0,
            customEventDateTime: DateFormatted.getTimestamp(scheduleEventParams.startAt, false),
            useCompanyTemplate,
            senderId: sendEmailModel.sender,
        };

        let primaryButtonText;

        if (this.props.eventId > 0) {
            primaryButtonText =
                scheduleEventParams.sendEmail === true
                    ? Translations.getStatic('saveAndSend')
                    : Translations.getStatic('save');
        } else {
            primaryButtonText =
                scheduleEventParams.sendEmail === true
                    ? Translations.getStatic('createEventAndSend')
                    : Translations.getStatic('createEvent');
        }

        const { isConflictsInCalendarDialogOpen, isDeleteModalOpen, conflicts } = this.state;

        const {
            isFetchingCandidateEventModel,
            isFetchingCandidatesForAction,
            isGettingVideoInterviewData,
            isFetchingAttachmentAssistantModel,
            isFetchingSendEmailModel,
        } = this.props;

        return (
            <>
                {isDeleteModalOpen === true && (
                    <MarkDialog
                        message={{
                            type: 'exclamation',
                            title:
                                Translations.getStatic('eventDeletion') +
                                ': ' +
                                scheduleEventParams.eventName,
                            infoLines: [Translations.getStatic('confirmEventDelete')],
                        }}
                        actions={[
                            {
                                title: Translations.getStatic('delete'),
                                action: () => this.deleteCandidateEvent(scheduleEventParams.id),
                                type: 'negative',
                            },
                            {
                                title: Translations.getStatic('cancel'),
                                action: () => this.closeDeleteModal(),
                            },
                        ]}
                    />
                )}

                {isConflictsInCalendarDialogOpen === true && (
                    <ConflictsInCalendarDialog
                        conflicts={conflicts}
                        onClose={this.closeConflictsInCalendarDialog}
                        onCreate={() => {
                            this.closeConflictsInCalendarDialog();
                            this.scheduleEvent([], false);
                        }}
                    />
                )}

                <MarkModal
                    isOpen={isOpen}
                    closeModal={this.props.closeModal}
                    isLoadingData={
                        isFetchingCandidateEventModel === true ||
                        isFetchingCandidatesForAction === true ||
                        isGettingVideoInterviewData === true ||
                        isFetchingAttachmentAssistantModel === true ||
                        isFetchingSendEmailModel === true
                    }
                    actionButton={{
                        text: primaryButtonText,
                        disabled: this.props.isSchedulingCandidateEvent,
                        loading: this.props.isSchedulingCandidateEvent,
                        onClick: () => this.scheduleEvent(),
                    }}
                    entityOption={{
                        colorRed: true,
                        text: Translations.getStatic('deleteEvent'),
                        visible:
                            this.props.eventId > 0 &&
                            this.props.isFetchingCandidateEventModel === false &&
                            this.props.isDeletingCandidateEvent === false,
                        onClick: this.openDeleteModal,
                    }}
                    entityOptionSecond={{
                        text: Translations.getStatic('emailPreview'),
                        visible: scheduleEventParams.sendEmail,
                        disabled: this.props.isSchedulingCandidateEvent,
                        loading: this.props.isSchedulingCandidateEvent,
                        onClick: () =>
                            this.props.openModalSecond('DetailEmailModal', {
                                title: Translations.getStatic('emailPreview'),
                                emailData,
                            }),
                    }}
                    title={Translations.getStatic(
                        this.props.eventId > 0 ? 'editEvent' : 'createEvent'
                    )}
                    content={children}
                />
            </>
        );
    };
}

ScheduleCandidateEventModal.propTypes = {
    candidateIds: PropTypes.array.isRequired,
    isOpen: PropTypes.bool.isRequired,
    eventId: PropTypes.number,
    eventType: PropTypes.number,
    eventData: PropTypes.object,
};

ScheduleCandidateEventModal.defaultProps = {
    eventId: 0,
    eventType: Constants.CANDIDATE_EVENT_TYPE_INTERVIEW,
};

const mapStateToProps = (state) => ({
    isOpen: state.modalLogic.isOpen,
    candidateActionModel: state.candidateActionModel,
    isSchedulingCandidateEvent: state.isSchedulingCandidateEvent,
    isDeletingCandidateEvent: state.isDeletingCandidateEvent,
    isFetchingCandidateEventModel: state.isFetchingCandidateEventModel,
    sendEmailModel: state.sendEmailModel,

    attachmentAssistantModel: AttachmentAssistantHelper.getActiveModelFromAssistantAttachmentModel(
        state.attachmentAssistantModel,
        AttachmentAssistantHelper.createAttachmentAssistantHashIdForEmail()
    ),

    templateAssistant: state.templateAssistant,
    templatesForDropdownSelector: state.templatesForDropdownSelector.templates,
    loginManagerModel: state.loginManagerModel,
    isFetchingCandidatesForAction: state.isFetchingCandidatesForAction,
    isGettingVideoInterviewData: state.isGettingVideoInterviewData,
    isFetchingAttachmentAssistantModel: state.isFetchingAttachmentAssistantModel,
    isFetchingSendEmailModel: state.isFetchingSendEmailModel,
});

const mapDispatchToProps = {
    closeModal,
    openModalSecond,
    scheduleCandidateEvent,
    deleteCandidateEvent,
    setErrorFocus,
    openAllRecipientsWithoutEmailsDialog,
    openSomeRecipientsWithoutEmailsDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleCandidateEventModal);
