import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from '@profesia/adamui/components/common/dropdown/SelectDefault';
import Form from '../Form';
import Grid from '../Grid';
import Heading from '../Heading';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import { closeModal } from '../../actions/ModalActions';
import { setUserDefaultPublicNotes } from '../../actions/SettingsActions';
import SuccessActionStatus from '../../helpers/SuccessActionStatus';
import Translations from '../../helpers/Translations';
import Constants from '../../helpers/Constants';

class SettingsPageNotesSettingsModal extends Component {
    state = {
        defaultPublicNotes: this.props.defaultPublicNotes,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.defaultPublicNotes !== prevProps.defaultPublicNotes) {
            this.setState({
                defaultPublicNotes: this.props.defaultPublicNotes,
            });
        }

        if (SuccessActionStatus.isActionSuccessful(prevProps, this.props)) {
            prevProps.closeModal();
        }
    }

    selectDefaultPublicNotes = (defaultPublicNotes) => {
        this.setState({
            defaultPublicNotes,
        });
    };

    setUserDefaultPublicNotes = (defaultPublicNotes) => {
        this.props.setUserDefaultPublicNotes(
            defaultPublicNotes,
            this.prepareUserDefaultPublicNotesDataToSave(defaultPublicNotes)
        );
    };

    prepareUserDefaultPublicNotesDataToSave = (defaultPublicNotes) => {
        const formData = new FormData();

        formData.append('defaultPublicNotes', defaultPublicNotes);

        return formData;
    };

    render() {
        const { isOpen, closeModal, noSpacing, isSettingDefaultPublicNotes } = this.props;
        const { defaultPublicNotes } = this.state;

        const publicNotesOptions = [
            {
                label: Translations.getStatic('turnedOff'),
                id: Constants.DEFAULT_PUBLIC_NOTES_OFF,
            },
            {
                label: Translations.getStatic('turnedOn'),
                id: Constants.DEFAULT_PUBLIC_NOTES_ON,
            },
        ];

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={closeModal}
                actionButton={{
                    text: Translations.getStatic('save'),
                    disabled: isSettingDefaultPublicNotes === true,
                    onClick: () => this.setUserDefaultPublicNotes(defaultPublicNotes),
                }}
                title={Translations.getStatic('notesSettingsChange')}
                content={
                    <>
                        <Heading as="h3">{Translations.getStatic('notesSettings')}</Heading>
                        <Form>
                            <Grid className={noSpacing === true ? 'padding-top_none' : ''}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Field>
                                            <Select
                                                value={defaultPublicNotes}
                                                label={Translations.getStatic(
                                                    'sharedNotesSettings'
                                                )}
                                                options={publicNotesOptions}
                                                onChange={(value) =>
                                                    this.selectDefaultPublicNotes(value)
                                                }
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </>
                }
            />
        );
    }
}

SettingsPageNotesSettingsModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    isOpen: state.modalLogic.isOpen,
    isSettingDefaultPublicNotes: state.isSettingDefaultPublicNotes,
    actionSuccessStatus: state.actionSuccessStatus,
    defaultPublicNotes: state.settingsModel.user.defaultPublicNotes,
});

const mapDispatchToProps = {
    closeModal,
    setUserDefaultPublicNotes,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPageNotesSettingsModal);
