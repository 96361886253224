import React from 'react';
import PropTypes from 'prop-types';
import Translations from '../../helpers/Translations';
import MarkDialog from './../Modal/MarkDialog';

class RemoveGlobalSourceTagDialog extends React.Component {
    render() {
        return (
            <MarkDialog
                message={{
                    title: Translations.getStatic('removeSharedSourceTagTitle'),
                    infoLines: [Translations.getStatic('removeSharedSourceTagText')],
                    iconName: 'icon-message-info',
                }}
                actions={[
                    {
                        title: Translations.getStatic('delete'),
                        action: this.props.onRemoveGlobalSourceTag,
                        type: 'negative',
                    },
                    {
                        title: Translations.getStatic('cancel'),
                        action: this.props.onCancel,
                    },
                ]}
            />
        );
    }
}

RemoveGlobalSourceTagDialog.propTypes = {
    onRemoveGlobalSourceTag: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default RemoveGlobalSourceTagDialog;
