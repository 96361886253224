import React from 'react';
import { SkeletonRectangular } from '@profesia/adamui/components/common/skeleton';
import { Stack } from '@mui/material';
import Link from '../../helpers/overridden/Link';
import Url from '../../helpers/Url';
import Translations from '../../helpers/Translations';

const Header4MenuDetailSkeleton = ({ backButton }) => {
    const backButtonLocation = backButton.location.replace(/^(mark\/)*/, '');

    return (
        <div className="template-header header-extended_dark">
            <div className="header__row">
                <div className="square-button">
                    <Link
                        to={
                            backButtonLocation === 'dashboard'
                                ? Url.build(backButtonLocation, {}, 'auto', false, true)
                                : Url.build(backButtonLocation)
                        }
                        gtmTag="menu-back-button"
                    >
                        {Translations.getStatic('back')}
                    </Link>
                </div>

                <Stack
                    flex={1}
                    mx={3}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    className="inverted"
                >
                    <Stack spacing={3}>
                        <SkeletonRectangular width={414} height={18} borderRadius={26} />

                        <Stack spacing={2} direction="row">
                            {[...Array(5)].map((_e, i) => (
                                <SkeletonRectangular
                                    key={i}
                                    width={51}
                                    height={10}
                                    borderRadius={26}
                                />
                            ))}
                        </Stack>
                    </Stack>

                    <Stack spacing={2} direction="row" alignItems="end">
                        {[...Array(2)].map((_e, i) => (
                            <SkeletonRectangular key={i} width={97} height={18} borderRadius={26} />
                        ))}
                    </Stack>
                </Stack>
            </div>
        </div>
    );
};

export default Header4MenuDetailSkeleton;
