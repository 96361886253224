import React from 'react';
import { NavLink } from 'react-router-dom';
import Url from '../../../../helpers/Url';
import Translations from '../../../../helpers/Translations';

const MainMenu = () => (
    <ul className="main-menu">
        <li>
            <NavLink to={Url.MARK_SERVER_PREFIX + '/'}>
                {Translations.getStatic('mainNavHome')}
            </NavLink>
        </li>
        <li>
            <NavLink to={Url.build('features')}>
                {Translations.getStatic('mainNavFeatures')}
            </NavLink>
        </li>
        <li>
            <NavLink to={Url.build('packages')}>
                {Translations.getStatic('mainNavPackages')}
            </NavLink>
        </li>
        <li>
            <NavLink to={Url.build('faq')}>{Translations.getStatic('mainNavFaq')}</NavLink>
        </li>
    </ul>
);

export default MainMenu;
