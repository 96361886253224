import { createContext } from 'react';
const TagFieldContext = createContext({
    onPaperButtonClick: () => { },
    onChange: () => { },
    values: [],
    input: '',
    hasReachedLimit: false,
    isLoading: false,
    groupCount: null,
    selectedGroup: null,
    isHighlightingEnabled: true,
    isSelectGroupDisabled: false,
});
export default TagFieldContext;
