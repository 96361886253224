import {
    REQUEST_COMPANY_JOBS_MODEL,
    RECEIVE_COMPANY_JOBS_MODEL,
} from '../actions/CompanyJobsActions';

export const isFetchingCompanyJobsModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_COMPANY_JOBS_MODEL:
            return true;
        case RECEIVE_COMPANY_JOBS_MODEL:
            return false;
        default:
            return state;
    }
};

export const companyJobsModel = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_COMPANY_JOBS_MODEL:
            return {};
        case RECEIVE_COMPANY_JOBS_MODEL:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
