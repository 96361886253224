import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { RadioButtons } from '@profesia/adamui/components/common/input';
import { FORM_LANGUAGES_REQUIRED } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { useGetRequiredOptions, LanguageRequirements } from '@profesia/mark-offer-form/codebooks';
const LanguagesRequired = ({ selectedLanguagesCount }) => {
    const requiredOptions = useGetRequiredOptions();
    const { setValue, control } = useFormContext();
    const values = useWatch({
        name: FORM_LANGUAGES_REQUIRED,
        control,
        defaultValue: LanguageRequirements.AtLeastOne,
    });
    useEffect(() => {
        if (selectedLanguagesCount === 1) {
            setValue(FORM_LANGUAGES_REQUIRED, LanguageRequirements.AtLeastOne, {
                shouldDirty: false,
            });
        }
    }, [selectedLanguagesCount]);
    if (selectedLanguagesCount < 2) {
        return null;
    }
    return (_jsx(RadioButtons, { value: values, options: requiredOptions, onChange: (_, requiredOptionId) => {
            setValue(FORM_LANGUAGES_REQUIRED, requiredOptionId);
        } }));
};
export default LanguagesRequired;
