export const GET_TALENT_POOL_CANDIDATES = 'GET_TALENT_POOL_CANDIDATES';
export const GET_TALENT_POOL_CANDIDATES_SUCCESS = 'GET_TALENT_POOL_CANDIDATES_SUCCESS';
export const GET_TALENT_POOL_CANDIDATES_FAILURE = 'GET_TALENT_POOL_CANDIDATES_FAILURE';

export const getTalentPoolCandidates = () => ({
    type: GET_TALENT_POOL_CANDIDATES,

    meta: {
        requestRoute: 'v1/talent-pool/data/candidates',
        onRequestSuccess: GET_TALENT_POOL_CANDIDATES_SUCCESS,
        onRequestFailure: GET_TALENT_POOL_CANDIDATES_FAILURE,
    },
});
