import React from 'react';
import PropTypes from 'prop-types';
import Translations from '../../helpers/Translations';
import MarkDialog from '../Modal/MarkDialog';

const AnonymizeCandidatesDialog = (props) => {
    const { title, isAnonymizingCandidates } = props;

    const infoLines =
        props.infoLines.length === 0
            ? [Translations.getStatic('confirmCandidateAnonymization') + '?']
            : props.infoLines;

    return (
        <MarkDialog
            message={{
                type: 'exclamation',
                title: title,
                infoLines: infoLines,
                checkbox: {
                    show: true,
                    label: Translations.getStatic('sendConfirmationToCandidate'),
                    value: props.sendConfirmation,
                    onChange: props.onChangeSendConfirmation,
                },
            }}
            showProgress={isAnonymizingCandidates}
            actions={[
                {
                    title: Translations.getStatic('anonymize'),
                    action: props.onAnonymize,
                    type: 'negative',
                    loading: isAnonymizingCandidates,
                },
                {
                    title: Translations.getStatic('cancel'),
                    action: props.onCancel,
                    disabled: isAnonymizingCandidates,
                },
            ]}
        />
    );
};

AnonymizeCandidatesDialog.propTypes = {
    isAnonymizingCandidates: PropTypes.bool.isRequired,
    onAnonymize: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onChangeSendConfirmation: PropTypes.func.isRequired,
    sendConfirmation: PropTypes.bool,
    title: PropTypes.string,
    infoLines: PropTypes.array,
};

AnonymizeCandidatesDialog.defaultProps = {
    title: Translations.getStatic('anonymizeCandidate'),
    infoLines: [],
    sendConfirmation: false,
};

export default AnonymizeCandidatesDialog;
