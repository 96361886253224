import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { openSidebarMenu, closeSidebarMenu } from '../../../actions/SidebarHeaderActions';
import Divider from '../../Divider';
import Icon from '../../Icon';
import User from '../../../helpers/User';
import SidebarMenuFooter from './SidebarMenuFooter';
import SidebarMenuList from './SidebarMenuList';
import { useLocation } from 'react-router-dom';

const SidebarMenu = ({ loginManagerModel, isOpenMenu, closeSidebarMenu, className = '' }) => {
    const location = useLocation();

    useEffect(() => {
        closeSidebarMenu();

        window.removeEventListener('click', closeSidebarMenu);
    }, [location.pathname]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (isOpenMenu === true) {
                window.addEventListener('click', closeSidebarMenu);
            } else {
                window.removeEventListener('click', closeSidebarMenu);
            }
        }, 100);

        return () => {
            window.removeEventListener('click', closeSidebarMenu);
            clearTimeout(timeout);
        };
    }, [isOpenMenu]);

    const isExternalUser = User.isExternalUser(loginManagerModel) === true;
    const companyName = isExternalUser === true ? '' : User.getCompanyName(loginManagerModel);
    const userEmail = User.getUserEmail(loginManagerModel) ?? '';

    return (
        <div
            className={[
                'sidebar-nav',
                isOpenMenu === true ? 'sidebar-nav-open' : '',
                className,
            ].join(' ')}
        >
            <div className="menu-wrapper">
                <div
                    className={
                        'left menu transition extended-list' +
                        (isOpenMenu === true ? ' visible' : '')
                    }
                    onClick={(e) => e.stopPropagation()}
                >
                    <ul className="navbar__options">
                        <li className="option__item">
                            <Icon
                                className="icon-nav-close"
                                size="mini"
                                onClick={closeSidebarMenu}
                            />
                        </li>
                    </ul>

                    <div className="username">{companyName}</div>
                    <div className="email">{userEmail}</div>

                    <Divider />

                    <div className="menu-wrapper_space-between">
                        <SidebarMenuList isExternalUser={isExternalUser} />
                        <SidebarMenuFooter />
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    isOpenMenu: state.sidebarHeaderLogic.isOpenMenu,
    loginManagerModel: state.loginManagerModel,
});

const mapDispatchToProps = {
    openSidebarMenu,
    closeSidebarMenu,
};

SidebarMenu.propTypes = {
    isOpenMenu: PropTypes.bool,
    openSidebarMenu: PropTypes.func.isRequired,
    closeSidebarMenu: PropTypes.func.isRequired,
    className: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
