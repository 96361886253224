export const SAVE_USER_TESTING = 'SAVE_USER_TESTING';
export const SAVE_USER_TESTING_SUCCESS = 'SAVE_USER_TESTING_SUCCESS';
export const SAVE_USER_TESTING_FAILURE = 'SAVE_USER_TESTING_FAILURE';
export const UPDATE_USER_TESTING = 'UPDATE_USER_TESTING';
export const UPDATE_USER_TESTING_SUCCESS = 'UPDATE_USER_TESTING_SUCCESS';
export const UPDATE_USER_TESTING_FAILURE = 'UPDATE_USER_TESTING_FAILURE';

export const saveUserTesting = (formData) => ({
    type: SAVE_USER_TESTING,

    meta: {
        requestRoute: 'v1/user-testing/action/save',
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: SAVE_USER_TESTING_SUCCESS,
        onRequestFailure: SAVE_USER_TESTING_FAILURE,
    },
});

export const updateUserTesting = (formData) => ({
    type: UPDATE_USER_TESTING,

    meta: {
        requestRoute: 'v1/user-testing/action/update',
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: UPDATE_USER_TESTING_SUCCESS,
        onRequestFailure: UPDATE_USER_TESTING_FAILURE,
    },
});
