import React, { Component } from 'react';
import { connect } from 'react-redux';
import JobAutoAnswerEditor from '../Job/JobAutoAnswerEditor';
import { updateAutoAnswerData } from '../../actions/JobDetailActions';
import { requestAutoAnswerEditorModel } from '../../actions/AutoAnswerEditorActions';
import { closeModal } from '../../actions/ModalActions';
import MarkModal from '../Modal/MarkModal';
import PropTypes from 'prop-types';
import {
    saveAsChangedAutoAnswerTemplate,
    saveAsNewAutoAnswerTemplate,
} from '../../actions/TemplateAssistantActions';
import { fetchAutoAnswerTemplates } from '../../actions/TemplateDropdownSelectorActions';
import TemplateAssistantHelper from '../../helpers/components/TemplateAssistantHelper';
import SuccessActionStatus from '../../helpers/SuccessActionStatus';
import Translations from '../../helpers/Translations';
import AttachmentAssistantHelper from '../../helpers/components/AttachmentAssistantHelper';
import TemplateAttachmentsHelper from '../../helpers/components/TemplateAttachmentsHelper';
import AutoAnswerValidatorHelper from '../../helpers/components/AutoAnswerValidatorHelper';
import User from '../../helpers/User';

class JobDetailPositionAutoAnswerModal extends Component {
    state = {
        errors: [],
    };

    componentDidUpdate = (prevProps) => {
        if (SuccessActionStatus.isActionSuccessful(prevProps, this.props)) {
            this.props.closeModal();
        }
    };

    prepareFormDataForSave(jobId, autoAnswerData, isUsingAutoAnswer) {
        const templateSavingMode = TemplateAssistantHelper.getTemplateSavingMode(
            this.props.templateAssistant.autoAnswer
        );

        let formData = new FormData();

        formData.append('jobId', JSON.stringify(jobId));
        formData.append('autoAnswerData', JSON.stringify(autoAnswerData));
        formData.append('isUsingAutoAnswer', isUsingAutoAnswer === true ? 1 : 0);
        formData.append('autoAnswerSavingMode', templateSavingMode);

        if (isUsingAutoAnswer === true) {
            formData = TemplateAttachmentsHelper.prepareFormData(
                formData,
                this.props.attachmentAssistantModel
            );
        }

        return formData;
    }

    checkAutoAnswerData(autoAnswerEditorModel) {
        const validator = new AutoAnswerValidatorHelper(autoAnswerEditorModel.autoAnswerData);

        const errors = validator.checkAutoAnswer(
            true,
            this.props.templateAssistant.autoAnswer.isNewTemplate,
            this.props.templatesForDropdownSelector.map((template) => template.text)
        );

        if (errors.length > 0) {
            throw errors;
        }
    }

    disableAutoAnswer(jobId) {
        this.props.updateAutoAnswerData(this.prepareFormDataForSave(jobId, null, false));
        this.props.requestAutoAnswerEditorModel(0);
        this.props.closeModal();
    }

    enableAutoAnswer(jobId, autoAnswerData, isUsingAutoAnswer) {
        this.props.updateAutoAnswerData(
            this.prepareFormDataForSave(jobId, autoAnswerData, isUsingAutoAnswer)
        );
        this.props.requestAutoAnswerEditorModel(0);
        this.props.closeModal();
    }

    handleCloseModal() {
        this.props.requestAutoAnswerEditorModel(0);
        this.props.closeModal();
    }

    handleSaveAutoAnswer(jobId, disableAutoAnswer) {
        try {
            this.checkAutoAnswerData(this.props.autoAnswerEditorModel);

            const autoAnswerData = this.props.autoAnswerEditorModel.autoAnswerData;

            disableAutoAnswer === true
                ? this.disableAutoAnswer(jobId)
                : this.enableAutoAnswer(jobId, autoAnswerData, true);
        } catch (errors) {
            this.setState({ errors });
        }
    }

    render() {
        const { templateAssistant, isOpen, jobModel, isFetchingAutoAnswerEditorModel } = this.props;

        const isUsingAutoAnswer = jobModel.job.emailTemplateId !== null;
        const autoAnswerId =
            jobModel.job.emailTemplateId === null ? 0 : jobModel.job.emailTemplateId;

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={() => this.handleCloseModal()}
                title={Translations.getStatic('editInfoAboutAutoAnswer')}
                isLoadingData={isFetchingAutoAnswerEditorModel === true}
                actionButton={{
                    text: Translations.getStatic('save'),
                    onClick: () => this.handleSaveAutoAnswer(jobModel.job.id, false),
                }}
                entityOption={{
                    colorRed: true,
                    visible: isUsingAutoAnswer,
                    text: Translations.getStatic('turnOffAutoAnswer'),
                    onClick: () => this.handleSaveAutoAnswer(jobModel.job.id, true),
                }}
                content={
                    <JobAutoAnswerEditor
                        isModal={true}
                        autoAnswerId={autoAnswerId}
                        isNewTemplate={templateAssistant.autoAnswer.isNewTemplate}
                        isChangedTemplate={templateAssistant.autoAnswer.isChangedTemplate}
                        onStartFetchingTemplates={(languageId) =>
                            this.props.fetchAutoAnswerTemplates(
                                User.getUserId(this.props.loginManagerModel),
                                languageId
                            )
                        }
                        onSaveAsChangedTemplate={this.props.saveAsChangedAutoAnswerTemplate}
                        onSaveAsNewTemplate={this.props.saveAsNewAutoAnswerTemplate}
                        errors={this.state.errors}
                    />
                }
            />
        );
    }
}

const mapStateToProps = (state) => ({
    jobModel: state.jobModel,
    autoAnswerEditorModel: state.autoAnswerEditorModel,
    loginManagerModel: state.loginManagerModel,
    isOpen: state.modalLogic.isOpen,
    templateAssistant: state.templateAssistant,
    templatesForDropdownSelector: state.templatesForDropdownSelector.templates,
    actionSuccessStatus: state.actionSuccessStatus,
    isFetchingAutoAnswerEditorModel: state.isFetchingAutoAnswerEditorModel,

    attachmentAssistantModel: AttachmentAssistantHelper.getActiveModelFromAssistantAttachmentModel(
        state.attachmentAssistantModel,
        AttachmentAssistantHelper.createAttachmentAssistantHashIdForEmail()
    ),
});

const mapDispatchToProps = {
    requestAutoAnswerEditorModel,
    updateAutoAnswerData,
    fetchAutoAnswerTemplates,
    saveAsChangedAutoAnswerTemplate,
    saveAsNewAutoAnswerTemplate,
    closeModal,
};

JobDetailPositionAutoAnswerModal.propTypes = {
    jobModel: PropTypes.object.isRequired,
    autoAnswerEditorModel: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    updateAutoAnswerData: PropTypes.func.isRequired,
    loginManagerModel: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobDetailPositionAutoAnswerModal);
