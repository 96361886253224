import React from 'react';
import { SkeletonCircular, SkeletonRectangular } from '@profesia/adamui/components/common/skeleton';
import { Stack } from '@mui/material';

const QuestionnaireResultsSkeleton = () => (
    <div className="questionnaire list">
        <div className="ui grid data-block">
            <div className="row padding-bottom_none">
                <div className="column">
                    <div className="ui items unstackable">
                        <div className="item data-block__content questionnaire-result">
                            <Stack spacing={1} p={1}>
                                <SkeletonRectangular width={244} height={18} borderRadius={26} />
                                <SkeletonRectangular width={116} height={10} borderRadius={26} />
                                <SkeletonRectangular width={188} height={10} borderRadius={26} />
                            </Stack>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row padding-bottom_none">
                <div className="column">
                    <Stack flex={1} pt={2} spacing={4}>
                        {[...Array(2)].map((e, i) => (
                            <Stack flex={1} spacing={2} direction="row" key={i}>
                                <SkeletonCircular size={48} />

                                <Stack flex={1} spacing={3} pt={2}>
                                    <SkeletonRectangular width={92} height={18} borderRadius={26} />

                                    <Stack flex={1} spacing={2}>
                                        {[...Array(3)].map((e, j) => (
                                            <SkeletonRectangular
                                                height={8}
                                                borderRadius={26}
                                                key={j}
                                            />
                                        ))}
                                    </Stack>
                                </Stack>
                            </Stack>
                        ))}
                    </Stack>
                </div>
            </div>
        </div>
    </div>
);

export default QuestionnaireResultsSkeleton;
