import { getSelectedBlock } from 'draftjs-utils';
import { Modifier, EditorState, ContentBlock, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { OrderedMap } from 'immutable';
import { AnyParamsFunctionType } from '../../interfaces/CommonTypes';

export const WysiwygHandlePaste = (
    text: string,
    html: string,
    editorState: EditorState,
    onChange: AnyParamsFunctionType
): boolean => {
    const selectedBlock: ContentBlock = getSelectedBlock(editorState);

    if (selectedBlock && selectedBlock.getType() === 'code') {
        const contentState: ContentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            text,
            editorState.getCurrentInlineStyle()
        );

        onChange(EditorState.push(editorState, contentState, 'insert-characters'));

        return true;
    } else if (html) {
        const contentBlock: { contentBlocks: ContentBlock[]; entityMap?: any } = htmlToDraft(html);

        let contentState: ContentState = editorState.getCurrentContent();

        contentBlock.entityMap.forEach((value: { [key: string]: any }, key: string) => {
            contentState = contentState.mergeEntityData(key, value);
        });

        contentState = Modifier.replaceWithFragment(
            contentState,
            editorState.getSelection(),
            OrderedMap(contentBlock.contentBlocks)
        );

        onChange(EditorState.push(editorState, contentState, 'insert-characters'));

        return true;
    }

    return false;
};
