import React from 'react';
import { Box, styled } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import Image from '../../../Image';
import Translations from '../../../../helpers/Translations';
import iconGreenFlag from '../../../../../img/icons/icon-green-flag.svg';
import iconGreyFlag from '../../../../../img/icons/icon-grey-flag.svg';

interface BadgeNewCandidateProps {
    isClicked: boolean;
    onClick?: (event: React.MouseEvent) => void;
    variant?: 'stage' | 'detail';
}

const BadgeNewCandidate: React.FC<BadgeNewCandidateProps> = ({
    isClicked,
    onClick,
    variant = 'detail',
}) => {
    return (
        <StyledTag isClicked={isClicked} onClick={onClick} variant={variant}>
            <Image
                src={variant === 'detail' && isClicked ? iconGreyFlag : iconGreenFlag}
                style={{ width: 8, height: 10 }}
            />
            {Translations.getStatic('newCandidate')}
        </StyledTag>
    );
};

const StyledTag = styled(Box, {
    shouldForwardProp: (prop) => !['isClicked', 'variant'].includes(prop as string),
})<{ isClicked: boolean; variant: 'stage' | 'detail' }>(
    ({ theme: { palette, spacing, typography }, isClicked, variant }) => ({
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: spacing(0.5),
        height: 20,
        padding: spacing(0.5),
        borderRadius: 2,
        fontSize: 11,
        lineHeight: '11px',
        fontWeight: typography.fontWeightBold,

        ...(variant === 'stage' && {
            backgroundColor: '#D5F2B74D',
            color: getColor(palette.secondaryGreen),
            cursor: 'inherit',
        }),

        ...(variant === 'detail' && {
            backgroundColor: isClicked ? 'transparent' : '#D5F2B74D',
            color: isClicked
                ? `${getColor(palette.black4)} !important`
                : `${getColor(palette.secondaryGreen)} !important`,
            border: isClicked
                ? `1px solid ${getColor(palette.neutralLight4)}`
                : `1px solid ${getColor(palette.secondaryGreen)}`,
            cursor: 'pointer',
            transition: 'all 0.2s ease',
            '&:hover': {
                opacity: 0.8,
            },
        }),
    })
);

export default BadgeNewCandidate;
