import React from 'react';
import Message from '../Message';
import Grid from '../Grid';
import Image from '../Image';
import Divider from '../Divider';
import Heading from '../Heading';
import Icon from '../Icon';
import PropTypes from 'prop-types';
import BulkUploadAttachmentColumn from './BulkUploadAttachmentColumn';
import CandidateAttachmentColumn from './CandidateAttachmentColumn';
import LiveUploadAttachmentColumn from './LiveUploadAttachmentColumn';
import CvViewer from '../PdfViewer/CvViewer';
import Constants from '../../helpers/Constants';
import Translations from '../../helpers/Translations';
import Language from '../../helpers/Language';
import attachmentsEmptyImage from '../../../img/icons/attachments-empty.svg';

class CandidateAttachmentsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            firstCv: undefined,
        };
    }

    componentDidMount() {
        this.prepareFirstCvForPreview(this.props, this.state);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.candidateId !== this.props.candidateId && this.state.firstCv !== undefined) {
            this.setState({
                firstCv: undefined,
            });
        }

        this.prepareFirstCvForPreview(this.props, this.state);
    }

    prepareFirstCvForPreview = (props, state) => {
        const { candidateId, candidateAttachments } = props;

        let firstCv = state.firstCv;

        if (firstCv !== undefined) {
            const deleted = candidateAttachments.find(
                (attachment) => parseInt(attachment.attachmentId) === parseInt(firstCv.attachmentId)
            );

            if (deleted === undefined) {
                firstCv = undefined;

                this.setState({
                    firstCv,
                });
            }
        }

        if (parseInt(candidateId) > 0 && firstCv === undefined) {
            const firstPdfCv = candidateAttachments.find(
                (attachment) =>
                    parseInt(attachment.attachmentTypeId) === Constants.ATTACHMENT_TYPE_CV &&
                    attachment.hasPdf === true
            );

            if (firstPdfCv !== undefined) {
                this.setState({
                    firstCv: firstPdfCv,
                });
            }
        }
    };

    render() {
        const {
            candidateId,
            language,
            bulkUploadAttachments,
            candidateAttachments,
            liveUploadAttachments,
            attachmentTypes,
            settings,
            isError,
            divider,
            isDuplicate,
            onRemoveCandidateAttachment,
            onRemoveAttachmentFromBulk,
        } = this.props;

        let cvPreview = null;
        let hasCv = this.state.firstCv !== undefined;
        let sortedCandidateAttachments = candidateAttachments
            .filter((attachment) => attachmentTypes.includes(attachment.attachmentTypeId))
            .sort((first, second) => {
                if (first.attachmentTypeId > second.attachmentTypeId) {
                    return 1;
                }

                if (first.attachmentTypeId < second.attachmentTypeId) {
                    return -1;
                }

                return 0;
            });

        if (hasCv === true) {
            cvPreview = (
                <CvViewer
                    file={{
                        downloadUrl: this.state.firstCv.downloadUrl,
                        url: this.state.firstCv.url,
                    }}
                    showPagesCount={10}
                />
            );
        }

        let content;

        if (
            candidateAttachments.length === 0 &&
            liveUploadAttachments.length === 0 &&
            bulkUploadAttachments.length === 0
        ) {
            content = <Image className="auto attachment-placeholder" src={attachmentsEmptyImage} />;
        } else {
            content = (
                <>
                    {cvPreview}

                    <Grid className="data-block data-block_primary">
                        <Grid.Row className="padding-bottom_none">
                            <Grid.Column>
                                {divider && <Divider />}
                                <Heading as="h5" className="data-block__header">
                                    <Icon className="icon-link" size="mini" />
                                    <Heading.Content>
                                        {Translations.getStatic(
                                            'allAttachments',
                                            this.props.language
                                        )}
                                    </Heading.Content>
                                </Heading>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className="padding-top_none">
                            <Grid.Column>
                                <div className="ui items unstackable">
                                    {sortedCandidateAttachments
                                        .filter(
                                            (attachment) =>
                                                attachment.hasOwnProperty('removed') === false ||
                                                attachment.removed === false
                                        )
                                        .map((attachment, i) => (
                                            <CandidateAttachmentColumn
                                                key={'candidateAttachment' + i}
                                                language={language}
                                                isDuplicate={isDuplicate}
                                                attachment={attachment}
                                                attachmentTypeName={
                                                    settings.attachmentTypes.find(
                                                        (attachmentType) =>
                                                            attachmentType.id ===
                                                            attachment.attachmentTypeId
                                                    ).name
                                                }
                                                onRemoveCandidateAttachment={() =>
                                                    onRemoveCandidateAttachment(
                                                        candidateId,
                                                        attachment.attachmentId
                                                    )
                                                }
                                            />
                                        ))}
                                    {bulkUploadAttachments
                                        .filter((attachment) =>
                                            this.props.attachmentTypes.includes(attachment.typeId)
                                        )
                                        .map((attachment, i) => (
                                            <BulkUploadAttachmentColumn
                                                key={'bulkAttachment' + i}
                                                language={language}
                                                isDuplicate={isDuplicate}
                                                attachment={attachment}
                                                attachmentTypeName={
                                                    settings.attachmentTypes.find(
                                                        (attachmentType) =>
                                                            attachmentType.id === attachment.typeId
                                                    ).name
                                                }
                                                onRemoveAttachmentFromBulk={() =>
                                                    onRemoveAttachmentFromBulk(i)
                                                }
                                            />
                                        ))}
                                    {liveUploadAttachments.map((attachment, i) => (
                                        <LiveUploadAttachmentColumn
                                            key={'liveAttachment' + i}
                                            language={language}
                                            attachment={attachment}
                                        />
                                    ))}
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </>
            );
        }

        return (
            <>
                {isError === true ? (
                    <Message icon negative>
                        <Icon className="icon-exclamation" size="mini" />
                        <Message.Content>
                            {Translations.getStatic('addYourCv', language)}.
                        </Message.Content>
                    </Message>
                ) : null}

                {content}
            </>
        );
    }
}

CandidateAttachmentsList.propTypes = {
    candidateId: PropTypes.number.isRequired,
    attachmentTypes: PropTypes.array.isRequired,
    candidateAttachments: PropTypes.array.isRequired,
    bulkUploadAttachments: PropTypes.array.isRequired,
    liveUploadAttachments: PropTypes.array.isRequired,
    settings: PropTypes.object.isRequired,
    onRemoveCandidateAttachment: PropTypes.func.isRequired,
    onRemoveAttachmentFromBulk: PropTypes.func.isRequired,
    isError: PropTypes.bool,
    isDuplicate: PropTypes.bool,
    divider: PropTypes.bool.isRequired,
    language: PropTypes.string,
};

CandidateAttachmentsList.defaultProps = {
    isError: false,
    title: '~strings.otherAttachments~',
    divider: true,
    candidateAttachments: [],
    language: Language.get(),
};

export default CandidateAttachmentsList;
