import {
    RECEIVE_PIPELINE_TEMPLATE_EDITOR_MODEL,
    REQUEST_PIPELINE_TEMPLATE_EDITOR_MODEL,
    SET_PIPELINE_STAGES,
    ENABLE_SWITCHABLE_FIXED_STAGE,
    DISABLE_SWITCHABLE_FIXED_STAGE,
    UPDATE_PIPELINE_TEMPLATE,
} from '../actions/PipelineTemplateEditorActions';
import { CLEAR_CREATE_JOB_DATA } from '../actions/CreateJobActions';
import PipelineHelper from '../helpers/components/PipelineHelper';
import { MODAL_OPEN } from '../actions/ModalActions';
import { SAVE_AS_NEW_PIPELINE_TEMPLATE } from '../actions/TemplateAssistantActions';

const getStagesObjectForPipelineEditor = (fixedStages, stages) => ({
    pipelineStagesForEditor: stages.slice(
        PipelineHelper.getEnabledFixedStages(fixedStages, stages).length
    ),
    enabledSwitchableFixedStages: PipelineHelper.getEnabledSwitchableFixedStages(
        fixedStages,
        stages
    ),
});

export const isFetchingPipelineTemplateEditorModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_PIPELINE_TEMPLATE_EDITOR_MODEL:
            return true;
        case RECEIVE_PIPELINE_TEMPLATE_EDITOR_MODEL:
            return false;
        default:
            return state;
    }
};

export const pipelineTemplateEditorModel = (state = {}, action) => {
    let enabledSwitchableFixedStages = [];

    switch (action.type) {
        case REQUEST_PIPELINE_TEMPLATE_EDITOR_MODEL:
        case CLEAR_CREATE_JOB_DATA:
            return {};
        case MODAL_OPEN:
            if (action.payload.modalType === 'JobDetailPositionPipelineTemplateModal') {
                return {};
            }

            return state;
        case RECEIVE_PIPELINE_TEMPLATE_EDITOR_MODEL:
            const preparedStages = getStagesObjectForPipelineEditor(
                action.payload.settings.pipeline.fixedStagesFromStart,
                action.payload.pipelineTemplateData.pipeline.stages
            );

            return {
                ...state,
                ...action.payload,
                ...preparedStages,
                pipelineTemplateData: {
                    ...action.payload.pipelineTemplateData,
                    changeHash: PipelineHelper.createChangeHash(
                        preparedStages.pipelineStagesForEditor,
                        preparedStages.enabledSwitchableFixedStages
                    ),
                },
            };
        case UPDATE_PIPELINE_TEMPLATE:
            return {
                ...state,
                pipelineTemplateData: {
                    ...state.pipelineTemplateData,
                    ...action.payload.pipelineTemplateData,
                },
            };
        case SET_PIPELINE_STAGES:
            return {
                ...state,
                pipelineStagesForEditor: action.payload.stages,
                pipelineTemplateData: {
                    ...state.pipelineTemplateData,
                    currentChangeHash: PipelineHelper.createChangeHash(
                        action.payload.stages,
                        state.enabledSwitchableFixedStages
                    ),
                },
            };
        case ENABLE_SWITCHABLE_FIXED_STAGE:
            enabledSwitchableFixedStages = state.enabledSwitchableFixedStages;

            if (
                enabledSwitchableFixedStages.find(
                    (switchableStage) => action.payload.stage.stageId === switchableStage.stageId
                ) !== undefined
            ) {
                return state;
            }

            enabledSwitchableFixedStages.push(action.payload.stage);

            return {
                ...state,
                enabledSwitchableFixedStages: enabledSwitchableFixedStages,
                pipelineTemplateData: {
                    ...state.pipelineTemplateData,
                    currentChangeHash: PipelineHelper.createChangeHash(
                        state.pipelineStagesForEditor,
                        enabledSwitchableFixedStages
                    ),
                },
            };
        case DISABLE_SWITCHABLE_FIXED_STAGE:
            enabledSwitchableFixedStages = state.enabledSwitchableFixedStages;

            if (
                enabledSwitchableFixedStages.find(
                    (switchableStage) => action.payload.stage.stageId === switchableStage.stageId
                ) === undefined
            ) {
                return state;
            }

            enabledSwitchableFixedStages = enabledSwitchableFixedStages.filter(
                (switchableStage) => action.payload.stage.stageId !== switchableStage.stageId
            );

            return {
                ...state,
                enabledSwitchableFixedStages: enabledSwitchableFixedStages,
                pipelineTemplateData: {
                    ...state.pipelineTemplateData,
                    currentChangeHash: PipelineHelper.createChangeHash(
                        state.pipelineStagesForEditor,
                        enabledSwitchableFixedStages
                    ),
                },
            };
        case SAVE_AS_NEW_PIPELINE_TEMPLATE:
            if (state.pipelineTemplateData === undefined) {
                return state;
            }

            if (
                state.pipelineTemplateData.isVisible === false &&
                state.pipelineTemplateData.name !== ''
            ) {
                return {
                    ...state,
                    pipelineTemplateData: {
                        ...state.pipelineTemplateData,
                        name: '',
                    },
                };
            }

            return state;
        default:
            return state;
    }
};
