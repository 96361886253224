export const SAVE_AS_NEW_AUTO_ANSWER_TEMPLATE = 'SAVE_AS_NEW_AUTO_ANSWER_TEMPLATE';
export const SAVE_AS_CHANGED_AUTO_ANSWER_TEMPLATE = 'SAVE_AS_CHANGED_AUTO_ANSWER_TEMPLATE';
export const SAVE_AS_NEW_EMAIL_TEMPLATE = 'SAVE_AS_NEW_EMAIL_TEMPLATE';
export const SAVE_AS_CHANGED_EMAIL_TEMPLATE = 'SAVE_AS_CHANGED_EMAIL_TEMPLATE';
export const SAVE_AS_NEW_QUESTIONNAIRE_TEMPLATE = 'SAVE_AS_NEW_QUESTIONNAIRE_TEMPLATE';
export const SAVE_AS_CHANGED_QUESTIONNAIRE_TEMPLATE = 'SAVE_AS_CHANGED_QUESTIONNAIRE_TEMPLATE';
export const SAVE_AS_NEW_PIPELINE_TEMPLATE = 'SAVE_AS_NEW_PIPELINE_TEMPLATE';
export const SAVE_AS_CHANGED_PIPELINE_TEMPLATE = 'SAVE_AS_CHANGED_PIPELINE_TEMPLATE';

export const saveAsNewAutoAnswerTemplate = (isNew) => ({
    type: SAVE_AS_NEW_AUTO_ANSWER_TEMPLATE,
    payload: {
        isNew,
    },
});

export const saveAsChangedAutoAnswerTemplate = (isChanged) => ({
    type: SAVE_AS_CHANGED_AUTO_ANSWER_TEMPLATE,
    payload: {
        isChanged,
    },
});

export const saveAsNewEmailTemplate = (isNew) => ({
    type: SAVE_AS_NEW_EMAIL_TEMPLATE,
    payload: {
        isNew,
    },
});

export const saveAsChangedEmailTemplate = (isChanged) => ({
    type: SAVE_AS_CHANGED_EMAIL_TEMPLATE,
    payload: {
        isChanged,
    },
});

export const saveAsNewQuestionnaireTemplate = (isNew) => ({
    type: SAVE_AS_NEW_QUESTIONNAIRE_TEMPLATE,
    payload: {
        isNew,
    },
});

export const saveAsChangedQuestionnaireTemplate = (isChanged) => ({
    type: SAVE_AS_CHANGED_QUESTIONNAIRE_TEMPLATE,
    payload: {
        isChanged,
    },
});

export const saveAsNewPipelineTemplate = (isNew) => ({
    type: SAVE_AS_NEW_PIPELINE_TEMPLATE,
    payload: {
        isNew,
    },
});

export const saveAsChangedPipelineTemplate = (isChanged) => ({
    type: SAVE_AS_CHANGED_PIPELINE_TEMPLATE,
    payload: {
        isChanged,
    },
});
