import {
    RECEIVE_REACTION_FORM_MODEL,
    REQUEST_REACTION_FORM_MODEL,
    SET_REACTION_FORM_ERRORS,
    SAVE_REACTION,
    SAVE_REACTION_FAILURE,
    SAVE_REACTION_SUCCESS,
} from '../actions/ReactionFormActions';

export const isFetchingReactionFormModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_REACTION_FORM_MODEL:
            return true;
        case RECEIVE_REACTION_FORM_MODEL:
            return false;
        default:
            return state;
    }
};

export const isSavingReaction = (state = false, action) => {
    switch (action.type) {
        case SAVE_REACTION:
            return true;
        case SAVE_REACTION_FAILURE:
        case SAVE_REACTION_SUCCESS:
            return false;
        default:
            return state;
    }
};
export const reactionFormModel = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_REACTION_FORM_MODEL:
            return {};
        case RECEIVE_REACTION_FORM_MODEL:
            return {
                ...state,
                settings: action.payload.settings,
                job: action.payload.job,
                errors: [],
            };
        case SAVE_REACTION:
            return {
                ...state,
                errors: [],
                isSuccess: false,
            };
        case SAVE_REACTION_SUCCESS:
            return {
                ...state,
                isSuccess: true,
            };
        case SET_REACTION_FORM_ERRORS:
            return {
                ...state,
                errors: action.payload.errors,
            };
        default:
            return state;
    }
};
