import React, { Component } from 'react';
import { connect } from 'react-redux';
import Icon from '../Icon';
import { closeModal, openModalSecond, closeModalSecond } from '../../actions/ModalActions';
import MarkModal from '../Modal/MarkModal';
import PropTypes from 'prop-types';
import {
    saveAsChangedPipelineTemplate,
    saveAsNewPipelineTemplate,
} from '../../actions/TemplateAssistantActions';
import { fetchPipelineTemplates } from '../../actions/TemplateDropdownSelectorActions';
import Translations from '../../helpers/Translations';
import JobPipelineEditor from '../Job/JobPipelineEditor';
import MarkDialog from '../Modal/MarkDialog';
import UserRights from '../../helpers/UserRights';
import { openNotAllowedFeatureDialog } from '../../actions/DialogActions';
import TemplateAssistantHelper from '../../helpers/components/TemplateAssistantHelper';
import SuccessMessage from '../../helpers/SuccessMessage';
import {
    checkStagesForTransform,
    processPipelineTemplateForJob,
} from '../../actions/JobDetailActions';
import PipelineHelper from '../../helpers/components/PipelineHelper';
import JobHelper from '../../helpers/components/JobHelper';
import PipelineTemplateValidatorHelper from '../../helpers/components/PipelineTemplateValidatorHelper';
import LoadingComponent from '../LoadingComponent';
import User from '../../helpers/User';

class JobDetailPositionPipelineTemplateModal extends Component {
    state = {
        openChangePipelineTemplateDialog: false,
        errors: [],
    };

    componentDidUpdate = (prevProps) => {
        if (
            SuccessMessage.canShowSuccessMessage(
                prevProps,
                this.props,
                'isCheckingStagesForTransform'
            ) === true
        ) {
            const { jobModel } = this.props;

            if (
                jobModel.hasOwnProperty('pipelineChange') === true &&
                jobModel.pipelineChange.hasOwnProperty('pipelineStagesToBeChangedForJob') ===
                    true &&
                jobModel.pipelineChange.fromModal === true
            ) {
                const {
                    pipelineChange: { pipelineStagesToBeChangedForJob, newPipelineTemplateStages },
                } = jobModel;

                JobHelper.handlePipelineChangeForJob(
                    pipelineStagesToBeChangedForJob,
                    newPipelineTemplateStages,
                    () => this.closeChangePipelineTemplateDialog(),
                    (modalType, modalProps) => this.props.openModalSecond(modalType, modalProps),
                    (changedStages) => this.processPipelineTemplateForJob(changedStages),
                    () => this.processPipelineTemplateForJob()
                );
            }
        }

        if (
            SuccessMessage.canShowSuccessMessage(
                prevProps,
                this.props,
                'isProcessingPipelineTemplateForJob'
            ) === true
        ) {
            this.props.closeModalSecond();
            this.handleCloseModal();
        }
    };

    openChangePipelineTemplateDialog = () => {
        this.setState({
            openChangePipelineTemplateDialog: true,
        });
    };

    closeChangePipelineTemplateDialog = () => {
        this.setState({
            openChangePipelineTemplateDialog: false,
        });
    };

    handleCloseModal = () => {
        this.props.closeModal();
    };

    processPipelineTemplateForJob = (changedStages = null) => {
        const {
            jobModel,
            pipelineTemplateEditorModel: {
                pipelineTemplateData,
                pipelineStagesForEditor,
                enabledSwitchableFixedStages,
            },
            templateAssistant,
            templatesForDropdownSelector,
            loginManagerModel,
        } = this.props;

        const validator = new PipelineTemplateValidatorHelper(
            pipelineTemplateData,
            pipelineStagesForEditor
        );

        const errors = validator.checkPipelineTemplate(
            true,
            templateAssistant.pipelineTemplate.isNewTemplate,
            templatesForDropdownSelector.map((template) => template.text)
        );

        if (errors.length === 0) {
            this.setState({
                errors: [],
            });

            const hasAllowedMultiPipelines =
                UserRights.hasAllowedMultiPipelines(loginManagerModel.activePackage) === true;
            const templateSavingMode = TemplateAssistantHelper.getTemplateSavingMode(
                templateAssistant.pipelineTemplate
            );
            const pipelineTemplateSavingMode =
                templateSavingMode === TemplateAssistantHelper.TEMPLATE_SAVING_MODE_NO_TEMPLATE &&
                hasAllowedMultiPipelines === false
                    ? TemplateAssistantHelper.TEMPLATE_SAVING_MODE_UPDATE
                    : templateSavingMode;

            let formData = new FormData();

            formData.append(
                'pipelineTemplateData',
                JSON.stringify({
                    templateData: pipelineTemplateData,
                    enabledSwitchableFixedStages: enabledSwitchableFixedStages,
                    pipelineStagesForEditor: pipelineStagesForEditor,
                })
            );

            formData.append('pipelineTemplateSavingMode', pipelineTemplateSavingMode);

            if (changedStages !== null) {
                formData.append('changedStages', JSON.stringify(changedStages));
            }

            this.props.processPipelineTemplateForJob(jobModel.job.id, formData);
        } else {
            this.setState({
                errors,
            });
        }
    };

    handlePipelineTemplateSaving = () => {
        const {
            jobModel,
            templateAssistant,
            loginManagerModel,
            pipelineTemplateEditorModel: { pipelineTemplateData },
        } = this.props;
        const { currentChangeHash, changeHash } = pipelineTemplateData;

        const hasAllowedMultiPipelines =
            UserRights.hasAllowedMultiPipelines(loginManagerModel.activePackage) === true;
        const hasAllowedEditablePipelines =
            UserRights.hasAllowedEditablePipelines(loginManagerModel.activePackage) === true;
        const templateSavingMode = TemplateAssistantHelper.getTemplateSavingMode(
            templateAssistant.pipelineTemplate
        );

        if (hasAllowedMultiPipelines === true) {
            if (
                jobModel.settings.pipelineTemplate.id === pipelineTemplateData.id &&
                (currentChangeHash === undefined || currentChangeHash === changeHash)
            ) {
                this.handleCloseModal();
            } else {
                this.checkStagesForTransform();
            }
        } else if (hasAllowedEditablePipelines === true) {
            if (templateSavingMode === TemplateAssistantHelper.TEMPLATE_SAVING_MODE_NO_TEMPLATE) {
                this.openChangePipelineTemplateDialog();
            } else if (templateSavingMode === TemplateAssistantHelper.TEMPLATE_SAVING_MODE_UPDATE) {
                this.checkStagesForTransform();
            } else {
                this.props.openNotAllowedFeatureDialog({
                    title: Translations.getStatic('pipelineTemplates'),
                    package: UserRights.getPackageWithAllowedMultiPipelines(),
                });
            }
        }
    };

    checkStagesForTransform = () => {
        const {
            jobModel,
            pipelineTemplateEditorModel,
            templateAssistant,
            templatesForDropdownSelector,
        } = this.props;
        const {
            pipelineTemplateData,
            enabledSwitchableFixedStages,
            pipelineStagesForEditor,
            settings: {
                pipeline: { fixedStagesFromStart },
            },
        } = pipelineTemplateEditorModel;

        const validator = new PipelineTemplateValidatorHelper(
            pipelineTemplateData,
            pipelineStagesForEditor
        );

        const errors = validator.checkPipelineTemplate(
            true,
            templateAssistant.pipelineTemplate.isNewTemplate,
            templatesForDropdownSelector.map((template) => template.text)
        );

        if (errors.length === 0) {
            this.setState({
                errors: [],
            });

            const newPipelineTemplateStages = PipelineHelper.getPipelineFromStages(
                fixedStagesFromStart,
                pipelineStagesForEditor,
                enabledSwitchableFixedStages
            );

            const formData = new FormData();

            formData.append('jobId', jobModel.job.id);
            formData.append('newPipelineTemplateStages', JSON.stringify(newPipelineTemplateStages));

            this.props.checkStagesForTransform(formData, true);
        } else {
            this.setState({
                errors,
            });
        }
    };

    render() {
        const {
            templateAssistant,
            isOpen,
            jobModel,
            loginManagerModel,
            pipelineTemplateEditorModel,
            isCheckingStagesForTransform,
            isProcessingPipelineTemplateForJob,
            isOpenSecond,
            isFetchingPipelineTemplateEditorModel,
        } = this.props;

        const pipelineTemplateId =
            jobModel.settings.pipelineTemplate === null ? 0 : jobModel.settings.pipelineTemplate.id;

        const isPerformingAction =
            isCheckingStagesForTransform === true ||
            (isProcessingPipelineTemplateForJob === true && isOpenSecond === false);

        return (
            <>
                <MarkModal
                    isOpen={isOpen}
                    closeModal={() => this.handleCloseModal()}
                    isLoadingData={isFetchingPipelineTemplateEditorModel}
                    actionButton={{
                        text: Translations.getStatic('save'),
                        loading: isPerformingAction === true,
                        onClick: () => this.handlePipelineTemplateSaving(),
                    }}
                    title={Translations.getStatic('selectionProcessSettings')}
                    content={
                        isPerformingAction === true ? (
                            <LoadingComponent text={Translations.getStatic('setting')} />
                        ) : (
                            <JobPipelineEditor
                                editFromJob={true}
                                isModal={true}
                                pipelineTemplateId={pipelineTemplateId}
                                errors={this.state.errors}
                                isNewTemplate={templateAssistant.pipelineTemplate.isNewTemplate}
                                isChangedTemplate={
                                    templateAssistant.pipelineTemplate.isChangedTemplate
                                }
                                onStartFetchingTemplates={() =>
                                    this.props.fetchPipelineTemplates(
                                        User.getUserId(loginManagerModel)
                                    )
                                }
                                onSaveAsNewTemplate={this.props.saveAsNewPipelineTemplate}
                                onSaveAsChangedTemplate={this.props.saveAsChangedPipelineTemplate}
                            />
                        )
                    }
                />

                {this.state.openChangePipelineTemplateDialog === true && (
                    <MarkDialog
                        closeOnDimmerClick={false}
                        message={{
                            iconName: 'icon-jobs',
                            title: Translations.getStatic('selectionProcessSettings'),
                            infoLines: [
                                Translations.getStatic(
                                    'changePipelineTemplateDialogContent'
                                ).replace(
                                    '%openJobsCount%',
                                    pipelineTemplateEditorModel.settings.openJobsWithPipelineCount
                                ),
                            ],
                        }}
                        actions={[
                            {
                                title: Translations.getStatic('editJob'),
                                type: 'positive',
                                action: () =>
                                    this.props.openNotAllowedFeatureDialog({
                                        title: Translations.getStatic('pipelineTemplates'),
                                        package: UserRights.getPackageWithAllowedMultiPipelines(),
                                    }),
                                icon: <Icon className="icon-upsell-star-invert" size="small" />,
                                disabled: isCheckingStagesForTransform === true,
                            },
                            {
                                title: Translations.getStatic('editAll'),
                                action: this.checkStagesForTransform,
                                loading: isCheckingStagesForTransform === true,
                            },
                            {
                                title: Translations.getStatic('cancel'),
                                type: 'negative',
                                action: this.closeChangePipelineTemplateDialog,
                                disabled: isCheckingStagesForTransform === true,
                            },
                        ]}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    jobModel: state.jobModel,
    pipelineTemplateEditorModel: state.pipelineTemplateEditorModel,
    loginManagerModel: state.loginManagerModel,
    isOpenSecond: state.modalSecondLogic.isOpen,
    isOpen: state.modalLogic.isOpen,
    templateAssistant: state.templateAssistant,
    templatesForDropdownSelector: state.templatesForDropdownSelector.templates,
    isCheckingStagesForTransform: state.isCheckingStagesForTransform,
    isProcessingPipelineTemplateForJob: state.isProcessingPipelineTemplateForJob,
    isFetchingPipelineTemplateEditorModel: state.isFetchingPipelineTemplateEditorModel,
});

const mapDispatchToProps = {
    fetchPipelineTemplates,
    saveAsChangedPipelineTemplate,
    saveAsNewPipelineTemplate,
    closeModal,
    openModalSecond,
    closeModalSecond,
    openNotAllowedFeatureDialog,
    checkStagesForTransform,
    processPipelineTemplateForJob,
};

JobDetailPositionPipelineTemplateModal.propTypes = {
    jobModel: PropTypes.object.isRequired,
    pipelineTemplateEditorModel: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    loginManagerModel: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobDetailPositionPipelineTemplateModal);
