import React from 'react';
import { connect } from 'react-redux';
import Select from '@profesia/adamui/components/common/dropdown/SelectDefault';
import Form from '../Form';
import Grid from '../Grid';
import Heading from '../Heading';
import LoadingComponent from '../LoadingComponent';
import Translations from '../../helpers/Translations';

class SettingsPageEventToEmailForwarding extends React.Component {
    render = () => {
        const { isSettingEventToEmailForwarding, hasActiveEventForwarding } = this.props;

        if (isSettingEventToEmailForwarding === true) {
            return <LoadingComponent />;
        }

        return (
            <>
                <Heading as="h3">{Translations.getStatic('connectToExternalCalendar')}</Heading>
                <Form>
                    <Grid className={this.props.noSpacing === true ? 'padding-top_none' : ''}>
                        <Grid.Row>
                            <Grid.Column>
                                <Form.Field>
                                    <Select
                                        value={hasActiveEventForwarding}
                                        label={Translations.getStatic(
                                            'defaultEventToEmailForwarding'
                                        )}
                                        options={[
                                            {
                                                label: Translations.getStatic('turnedOff'),
                                                id: false,
                                            },
                                            {
                                                label: Translations.getStatic('turnedOn'),
                                                id: true,
                                            },
                                        ]}
                                        onChange={(value) =>
                                            this.props.setActiveEventForwarding(value)
                                        }
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </>
        );
    };
}

const mapStateToProps = (state) => ({
    isSettingEventToEmailForwarding: state.isSettingEventToEmailForwarding,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPageEventToEmailForwarding);
