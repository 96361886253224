import Constants from './../helpers/Constants';

export const REQUEST_JOB_MODEL = 'REQUEST_JOB_MODEL';
export const RECEIVE_JOB_MODEL = 'RECEIVE_JOB_MODEL';
export const FAILURE_JOB_MODEL = 'FAILURE_JOB_MODEL';
export const GET_POSITION_DATA = 'GET_POSITION_DATA';
export const GET_POSITION_SUCCESS = 'GET_POSITION_SUCCESS';
export const GET_POSITION_FAILURE = 'GET_POSITION_FAILURE';
export const CHANGE_CANDIDATE_STAGE = 'CHANGE_CANDIDATE_STAGE';
export const CHANGE_CANDIDATE_STAGE_SUCCESS = 'CHANGE_CANDIDATE_STAGE_SUCCESS';
export const CHANGE_CANDIDATE_STAGE_FAILURE = 'CHANGE_CANDIDATE_STAGE_FAILURE';
export const UPDATE_QUESTIONNAIRE_DATA = 'UPDATE_QUESTIONNAIRE_DATA';
export const UPDATE_QUESTIONNAIRE_DATA_SUCCESS = 'UPDATE_QUESTIONNAIRE_DATA_SUCCESS';
export const UPDATE_QUESTIONNAIRE_DATA_FAILURE = 'UPDATE_QUESTIONNAIRE_DATA_FAILURE';
export const UPDATE_AUTOANSWER_DATA = 'UPDATE_AUTOANSWER_DATA';
export const UPDATE_AUTOANSWER_DATA_SUCCESS = 'UPDATE_AUTOANSWER_DATA_SUCCESS';
export const UPDATE_AUTOANSWER_DATA_FAILURE = 'UPDATE_AUTOANSWER_DATA_FAILURE';
export const OPEN_JOB_DETAIL = 'OPEN_JOB_DETAIL';
export const CLOSE_JOB_DETAIL = 'CLOSE_JOB_DETAIL';
export const ACTIVATE_JOB_DETAIL = 'ACTIVATE_JOB_DETAIL';
export const OPEN_JOB_SUCCESS_DETAIL = 'OPEN_JOB_SUCCESS_DETAIL';
export const OPEN_JOB_FAILURE_DETAIL = 'OPEN_JOB_FAILURE_DETAIL';
export const CLOSE_JOB_SUCCESS_DETAIL = 'CLOSE_JOB_SUCCESS_DETAIL';
export const CLOSE_JOB_FAILURE_DETAIL = 'CLOSE_JOB_FAILURE_DETAIL';
export const ACTIVATE_JOB_SUCCESS_DETAIL = 'ACTIVATE_JOB_SUCCESS_DETAIL';
export const ACTIVATE_JOB_FAILURE_DETAIL = 'ACTIVATE_JOB_FAILURE_DETAIL';
export const CREATE_SOURCE_TAG = 'CREATE_SOURCE_TAG';
export const CREATE_SOURCE_TAG_SUCCESS = 'CREATE_SOURCE_TAG_SUCCESS';
export const CREATE_SOURCE_TAG_FAILURE = 'CREATE_SOURCE_TAG_FAILURE';
export const DLETE_SOURCE_TAG = 'DLETE_SOURCE_TAG';
export const DLETE_SOURCE_TAG_SUCCESS = 'DLETE_SOURCE_TAG_SUCCESS';
export const DLETE_SOURCE_TAG_FAILURE = 'DLETE_SOURCE_TAG_FAILURE';
export const UPDATE_JOB = 'UPDATE_JOB';
export const UPDATE_JOB_SUCCESS = 'UPDATE_JOB_SUCCESS';
export const UPDATE_JOB_FAILURE = 'UPDATE_JOB_FAILURE';
export const LOAD_FULL_CANDIDATES_DATA_FOR_JOB = 'LOAD_FULL_CANDIDATES_DATA_FOR_JOB';
export const LOAD_FULL_CANDIDATES_DATA_FOR_JOB_SUCCESS =
    'LOAD_FULL_CANDIDATES_DATA_FOR_JOB_SUCCESS';
export const LOAD_FULL_CANDIDATES_DATA_FOR_JOB_FAILURE =
    'LOAD_FULL_CANDIDATES_DATA_FOR_JOB_FAILURE';
export const LOAD_PIPELINE_CANDIDATES_DATA_FOR_JOB = 'LOAD_PIPELINE_CANDIDATES_DATA_FOR_JOB';
export const LOAD_PIPELINE_CANDIDATES_DATA_FOR_JOB_SUCCESS = 'LOAD_PIPELINE_CANDIDATES_DATA_FOR_JOB_SUCCESS';
export const LOAD_PIPELINE_CANDIDATES_DATA_FOR_JOB_FAILURE = 'LOAD_PIPELINE_CANDIDATES_DATA_FOR_JOB_FAILURE';    
export const RESET_FULL_CANDIDATES_DATA = 'RESET_FULL_CANDIDATES_DATA';
export const SET_EMPTY_FULL_CANDIDATES_DATA = 'SET_EMPTY_FULL_CANDIDATES_DATA';
export const ENABLE_REACTION_FORM = 'ENABLE_REACTION_FORM';
export const ENABLE_REACTION_FORM_SUCCESS = 'ENABLE_REACTION_FORM_SUCCESS';
export const ENABLE_REACTION_FORM_FAILURE = 'ENABLE_REACTION_FORM_FAILURE';
export const DISABLE_REACTION_FORM = 'DISABLE_REACTION_FORM';
export const DISABLE_REACTION_FORM_SUCCESS = 'DISABLE_REACTION_FORM_SUCCESS';
export const DISABLE_REACTION_FORM_FAILURE = 'DISABLE_REACTION_FORM_FAILURE';
export const ADD_EXTERNAL_USER = 'ADD_EXTERNAL_USER';
export const ADD_EXTERNAL_USER_SUCCESS = 'ADD_EXTERNAL_USER_SUCCESS';
export const ADD_EXTERNAL_USER_FAILURE = 'ADD_EXTERNAL_USER_FAILURE';
export const REMOVE_EXTERNAL_USER = 'REMOVE_EXTERNAL_USER';
export const REMOVE_EXTERNAL_USER_SUCCESS = 'REMOVE_EXTERNAL_USER_SUCCESS';
export const REMOVE_EXTERNAL_USER_FAILURE = 'REMOVE_EXTERNAL_USER_FAILURE';
export const CREATE_RECOMMENDATION_TAG = 'CREATE_RECOMMENDATION_TAG';
export const CREATE_RECOMMENDATION_TAG_SUCCESS = 'CREATE_RECOMMENDATION_TAG_SUCCESS';
export const CREATE_RECOMMENDATION_TAG_FAILURE = 'CREATE_RECOMMENDATION_TAG_FAILURE';
export const DELETE_RECOMMENDATION_TAG = 'DELETE_RECOMMENDATION_TAG';
export const DELETE_RECOMMENDATION_TAG_SUCCESS = 'DELETE_RECOMMENDATION_TAG_SUCCESS';
export const DELETE_RECOMMENDATION_TAG_FAILURE = 'DELETE_RECOMMENDATION_TAG_FAILURE';
export const ENABLE_RECOMMENDATION_TAG = 'ENABLE_RECOMMENDATION_TAG';
export const ENABLE_RECOMMENDATION_TAG_SUCCESS = 'ENABLE_RECOMMENDATION_TAG_SUCCESS';
export const ENABLE_RECOMMENDATION_TAG_FAILURE = 'ENABLE_RECOMMENDATION_TAG_FAILURE';
export const APPLY_PIPELINE_TEMPLATE_TO_JOB = 'APPLY_PIPELINE_TEMPLATE_TO_JOB';
export const APPLY_PIPELINE_TEMPLATE_TO_JOB_SUCCESS = 'APPLY_PIPELINE_TEMPLATE_TO_JOB_SUCCESS';
export const APPLY_PIPELINE_TEMPLATE_TO_JOB_FAILURE = 'APPLY_PIPELINE_TEMPLATE_TO_JOB_FAILURE';
export const APPLY_DEFAULT_PIPELINE_TEMPLATE_TO_JOB = 'APPLY_DEFAULT_PIPELINE_TEMPLATE_TO_JOB';
export const APPLY_DEFAULT_PIPELINE_TEMPLATE_TO_JOB_SUCCESS =
    'APPLY_DEFAULT_PIPELINE_TEMPLATE_TO_JOB_SUCCESS';
export const APPLY_DEFAULT_PIPELINE_TEMPLATE_TO_JOB_FAILURE =
    'APPLY_DEFAULT_PIPELINE_TEMPLATE_TO_JOB_FAILURE';
export const CHECK_STAGES_FOR_TRANSFORM = 'CHECK_STAGES_FOR_TRANSFORM';
export const CHECK_STAGES_FOR_TRANSFORM_SUCCESS = 'CHECK_STAGES_FOR_TRANSFORM_SUCCESS';
export const CHECK_STAGES_FOR_TRANSFORM_FAILURE = 'CHECK_STAGES_FOR_TRANSFORM_FAILURE';
export const PROCESS_PIPELINE_TEMPLATE_FOR_JOB = 'PROCESS_PIPELINE_TEMPLATE_FOR_JOB';
export const PROCESS_PIPELINE_TEMPLATE_FOR_JOB_SUCCESS =
    'PROCESS_PIPELINE_TEMPLATE_FOR_JOB_SUCCESS';
export const PROCESS_PIPELINE_TEMPLATE_FOR_JOB_FAILURE =
    'PROCESS_PIPELINE_TEMPLATE_FOR_JOB_FAILURE';
export const CHANGE_EXTERNAL_USER_ROLE = 'CHANGE_EXTERNAL_USER_ROLE';
export const CHANGE_EXTERNAL_USER_ROLE_SUCCESS = 'CHANGE_EXTERNAL_USER_ROLE_SUCCESS';
export const CHANGE_EXTERNAL_USER_ROLE_FAILURE = 'CHANGE_EXTERNAL_USER_ROLE_FAILURE';
export const SET_SELECTED_CANDIDATE_ID = 'SET_SELECTED_CANDIDATE_ID';
export const CANDIDATE_CLICKED = 'CANDIDATE_CLICKED';

export const requestJobModel = (jobId = null) => ({
    type: REQUEST_JOB_MODEL,

    meta: {
        requestRoute: 'v1/job/model/detail/' + (jobId === null ? 0 : jobId),

        onRequestSuccess: RECEIVE_JOB_MODEL,
        onRequestFailure: FAILURE_JOB_MODEL,
    },
});

export const getPositionData = (positionId = null) => ({
    type: GET_POSITION_DATA,

    meta: {
        isDefaultApiPrefixDisabled: true,
        requestRoute: '/api/v1/position/' + (positionId ?? 0),

        onRequestSuccess: GET_POSITION_SUCCESS,
        onRequestFailure: GET_POSITION_FAILURE,
    },
});

export const changeCandidateStage = (
    formData,
    candidateId,
    stage,
    positionInStage = 0,
    reducerType
) => ({
    type: CHANGE_CANDIDATE_STAGE,

    payload: {
        candidateId,
        stage,
        positionInStage,
        stageId: stage.stageId,
        stageName: stage.stageName,
        reducerType,
    },

    meta: {
        requestRoute: 'v1/candidate/action/set-stage',
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: CHANGE_CANDIDATE_STAGE_SUCCESS,
        onRequestFailure: CHANGE_CANDIDATE_STAGE_FAILURE,
    },
});

export const updateQuestionnaireData = (formData) => ({
    type: UPDATE_QUESTIONNAIRE_DATA,
    meta: {
        requestRoute: 'v1/job/action/set-questionnaire',
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: UPDATE_QUESTIONNAIRE_DATA_SUCCESS,
        onRequestFailure: UPDATE_QUESTIONNAIRE_DATA_FAILURE,
    },
});

export const updateAutoAnswerData = (formData) => ({
    type: UPDATE_AUTOANSWER_DATA,
    meta: {
        requestRoute: 'v1/job/action/set-auto-answer',
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: UPDATE_AUTOANSWER_DATA_SUCCESS,
        onRequestFailure: UPDATE_AUTOANSWER_DATA_FAILURE,
    },
});

export const closeJob = (jobId, actionData = '') => ({
    type: CLOSE_JOB_DETAIL,

    payload: {
        jobId,
        stateId: Constants.JOB_STATE_CLOSED,
    },

    meta: {
        requestRoute: 'v1/job/action/close/' + jobId,
        requestMethod: 'POST',
        requestBody: actionData,

        onRequestSuccess: CLOSE_JOB_SUCCESS_DETAIL,
        onRequestFailure: CLOSE_JOB_FAILURE_DETAIL,
    },
});

export const openJob = (jobId) => ({
    type: OPEN_JOB_DETAIL,

    payload: {
        jobId,
        stateId: Constants.JOB_STATE_OPEN,
    },

    meta: {
        requestRoute: 'v1/job/action/open/' + jobId,
        requestMethod: 'POST',

        onRequestSuccess: OPEN_JOB_SUCCESS_DETAIL,
        onRequestFailure: OPEN_JOB_FAILURE_DETAIL,
    },
});

export const activateJob = (jobId) => ({
    type: ACTIVATE_JOB_DETAIL,

    payload: {
        jobId,
        stateId: Constants.JOB_STATE_OPEN,
    },

    meta: {
        requestRoute: 'v1/job/action/pay/' + jobId,
        requestMethod: 'POST',

        onRequestSuccess: ACTIVATE_JOB_SUCCESS_DETAIL,
        onRequestFailure: ACTIVATE_JOB_FAILURE_DETAIL,
    },
});

export const createSourceTag = (formData) => ({
    type: CREATE_SOURCE_TAG,

    meta: {
        requestRoute: 'v1/source-tag/action/create',
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: CREATE_SOURCE_TAG_SUCCESS,
        onRequestFailure: CREATE_SOURCE_TAG_FAILURE,
    },
});

export const deleteSourceTag = (formData) => ({
    type: DLETE_SOURCE_TAG,

    meta: {
        requestRoute: 'v1/source-tag/action/delete',
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: DLETE_SOURCE_TAG_SUCCESS,
        onRequestFailure: DLETE_SOURCE_TAG_FAILURE,
    },
});

export const updateJob = (jobId, formData) => ({
    type: UPDATE_JOB,

    payload: {
        jobData: formData,
    },

    meta: {
        requestRoute: 'v1/job/action/update/' + jobId,
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: UPDATE_JOB_SUCCESS,
        onRequestFailure: UPDATE_JOB_FAILURE,
    },
});

export const loadFullCandidatesDataForJob = (jobId, candidateIds) => ({
    type: LOAD_FULL_CANDIDATES_DATA_FOR_JOB,

    meta: {
        requestRoute: 'v1/job/data/candidates',

        requestData: {
            candidateIds: JSON.stringify(candidateIds),
            jobId,
        },

        onRequestSuccess: LOAD_FULL_CANDIDATES_DATA_FOR_JOB_SUCCESS,
        onRequestFailure: LOAD_FULL_CANDIDATES_DATA_FOR_JOB_FAILURE,
    },

    payload: {
        jobId,
        candidateIds,
    },
});

export const addExternalUser = (formData) => ({
    type: ADD_EXTERNAL_USER,

    meta: {
        requestRoute: 'v1/job/action/add-external-user',
        requestMethod: 'POST',

        requestBody: formData,

        onRequestSuccess: ADD_EXTERNAL_USER_SUCCESS,
        onRequestFailure: ADD_EXTERNAL_USER_FAILURE,
    },
});

export const removeExternalUser = (formData) => ({
    type: REMOVE_EXTERNAL_USER,

    meta: {
        requestRoute: 'v1/job/action/remove-external-user',
        requestMethod: 'POST',

        requestBody: formData,

        onRequestSuccess: REMOVE_EXTERNAL_USER_SUCCESS,
        onRequestFailure: REMOVE_EXTERNAL_USER_FAILURE,
    },
});

export const resetFullCandidatesData = () => ({
    type: RESET_FULL_CANDIDATES_DATA,
});

export const setEmptyFullCandidatesData = () => ({
    type: SET_EMPTY_FULL_CANDIDATES_DATA,
});

export const enableReactionForm = (jobId) => ({
    type: ENABLE_REACTION_FORM,

    meta: {
        requestRoute: 'v1/job/action/enable-reaction-form/' + jobId,
        requestMethod: 'POST',

        onRequestSuccess: ENABLE_REACTION_FORM_SUCCESS,
        onRequestFailure: ENABLE_REACTION_FORM_FAILURE,
    },
});

export const disableReactionForm = (jobId) => ({
    type: DISABLE_REACTION_FORM,

    meta: {
        requestRoute: 'v1/job/action/disable-reaction-form/' + jobId,
        requestMethod: 'POST',

        onRequestSuccess: DISABLE_REACTION_FORM_SUCCESS,
        onRequestFailure: DISABLE_REACTION_FORM_FAILURE,
    },
});

export const createRecommendationTag = (formData) => ({
    type: CREATE_RECOMMENDATION_TAG,

    meta: {
        requestRoute: 'v1/recommendation-tag/action/create',
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: CREATE_RECOMMENDATION_TAG_SUCCESS,
        onRequestFailure: CREATE_RECOMMENDATION_TAG_FAILURE,
    },
});

export const deleteRecommendationTag = (formData) => ({
    type: DELETE_RECOMMENDATION_TAG,

    meta: {
        requestRoute: 'v1/recommendation-tag/action/delete',
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: DELETE_RECOMMENDATION_TAG_SUCCESS,
        onRequestFailure: DELETE_RECOMMENDATION_TAG_FAILURE,
    },
});

export const enableRecommendationTag = (formData) => ({
    type: ENABLE_RECOMMENDATION_TAG,

    meta: {
        requestRoute: 'v1/recommendation-tag/action/enable',
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: ENABLE_RECOMMENDATION_TAG_SUCCESS,
        onRequestFailure: ENABLE_RECOMMENDATION_TAG_FAILURE,
    },
});

export const applyDefaultPipelineTemplateToJob = (jobId) => ({
    type: APPLY_DEFAULT_PIPELINE_TEMPLATE_TO_JOB,

    payload: {
        jobId,
    },

    meta: {
        requestRoute: 'v1/pipeline-template/action/apply-default-to-job/' + jobId,
        requestMethod: 'POST',

        onRequestSuccess: APPLY_DEFAULT_PIPELINE_TEMPLATE_TO_JOB_SUCCESS,
        onRequestFailure: APPLY_DEFAULT_PIPELINE_TEMPLATE_TO_JOB_FAILURE,
    },
});

export const applyPipelineTemplateToJob = (jobId, formData) => ({
    type: APPLY_PIPELINE_TEMPLATE_TO_JOB,

    meta: {
        requestRoute: 'v1/pipeline-template/action/apply-to-job/' + jobId,
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: APPLY_PIPELINE_TEMPLATE_TO_JOB_SUCCESS,
        onRequestFailure: APPLY_PIPELINE_TEMPLATE_TO_JOB_FAILURE,
    },
});

export const processPipelineTemplateForJob = (jobId, formData) => ({
    type: PROCESS_PIPELINE_TEMPLATE_FOR_JOB,

    payload: {
        jobId,
    },

    meta: {
        requestRoute: 'v1/pipeline-template/action/process-for-job/' + jobId,
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: PROCESS_PIPELINE_TEMPLATE_FOR_JOB_SUCCESS,
        onRequestFailure: PROCESS_PIPELINE_TEMPLATE_FOR_JOB_FAILURE,
    },
});

export const checkStagesForTransform = (formData, fromModal = false) => ({
    type: CHECK_STAGES_FOR_TRANSFORM,

    payload: {
        fromModal,
    },

    meta: {
        requestRoute: 'v1/pipeline-template/action/check-stages-for-transform-for-job',
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: CHECK_STAGES_FOR_TRANSFORM_SUCCESS,
        onRequestFailure: CHECK_STAGES_FOR_TRANSFORM_FAILURE,
    },
});

export const changeExternalUserRole = (formData) => ({
    type: CHANGE_EXTERNAL_USER_ROLE,

    meta: {
        requestRoute: 'v1/job/action/change-external-user-role',
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: CHANGE_EXTERNAL_USER_ROLE_SUCCESS,
        onRequestFailure: CHANGE_EXTERNAL_USER_ROLE_FAILURE,
    },
});

export const setSelectedCandidateId = (candidateId) => ({
    type: SET_SELECTED_CANDIDATE_ID,

    payload: {
        candidateId,
    },
});

export const setIsClickedCandidate = (candidateId, isClicked) => ({
    type: CANDIDATE_CLICKED,
    payload: {
        candidateId,
        isClicked,
    },
});