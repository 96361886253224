import {
    GET_TALENT_POOL_CANDIDATES,
    GET_TALENT_POOL_CANDIDATES_FAILURE,
    GET_TALENT_POOL_CANDIDATES_SUCCESS,
} from '../actions/TalentPoolActions';

export const talentPoolModel = (state = {}, action) => {
    switch (action.type) {
        case GET_TALENT_POOL_CANDIDATES:
            return {};
        case GET_TALENT_POOL_CANDIDATES_SUCCESS:
            return {
                ...state,
                candidates: action.payload.candidates,
            };
        default:
            return state;
    }
};

export const isFetchingTalentPoolCandidates = (state = false, action) => {
    switch (action.type) {
        case GET_TALENT_POOL_CANDIDATES:
            return true;

        case GET_TALENT_POOL_CANDIDATES_SUCCESS:
        case GET_TALENT_POOL_CANDIDATES_FAILURE:
            return false;

        default:
            return state;
    }
};
