import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { SimpleLink } from '@profesia/adamui/components/common/Link';
import { SectionWrapperColorVariant } from '@profesia/adamui/components/common/types';
import { useMobile } from '@profesia/adamui/utils/responsive';
import BoxedSectionWrapper from '@profesia/mark-offer-form/components/content/form/components/BoxedSectionWrapper';
import { useGetFixedT, useTranslationInPageLanguage, useGetLocale, } from '@profesia/mark-offer-form/i18n';
import { useOptionsContext } from '@profesia/mark-offer-form/services/OptionsContext';
import { useLogoContext } from '@profesia/mark-offer-form/services/LogoContext';
import { getConfiguration } from '@profesia/mark-offer-form/configuration';
import { FORM_COMPANY_BUSINESS_AREA, FORM_COMPANY_CHARACTERISTICS, FORM_COMPANY_LOGO_IMAGE_EXTENSION, FORM_COMPANY_LOGO_SOURCE_IMG, FORM_COMPANY_LOGO_SOURCE_IMG_URL, FORM_COMPANY_LOGO_CROPPER_DATA, FORM_COMPANY_LOGO_CROPPED_IMG, FORM_COMPANY_LOGO_CROPPED_IMG_URL, } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import CompanyModal from './Modal';
import CompanyDetailsContent from './Content';
const CompanyDetails = () => {
    const currentLanguage = useGetLocale();
    const t = useGetFixedT('companyDetails');
    const pageT = useTranslationInPageLanguage('companyDetails');
    const { form } = getConfiguration();
    const isMobile = useMobile('sm');
    const editLink = pageT(isMobile ? 'links.editLinkShort' : 'links.editLinkFull');
    const { isEditMode } = useOptionsContext();
    const { setBase64Logo, setSourceImgLogo, setCropData } = useLogoContext();
    const { getValues, setValue, reset } = useFormContext();
    const characteristics = getValues(FORM_COMPANY_CHARACTERISTICS);
    const businessArea = getValues(FORM_COMPANY_BUSINESS_AREA);
    const imgExtension = getValues(FORM_COMPANY_LOGO_IMAGE_EXTENSION);
    const sourceImgUrl = getValues(FORM_COMPANY_LOGO_SOURCE_IMG_URL);
    const cropperData = getValues(FORM_COMPANY_LOGO_CROPPER_DATA);
    const croppedImgUrl = getValues(FORM_COMPANY_LOGO_CROPPED_IMG_URL);
    const [company, setCompany] = useState({
        characteristics: characteristics[currentLanguage],
        businessArea: { id: businessArea.id, name: businessArea.names[currentLanguage] },
        logo: {
            sourceImg: sourceImgUrl,
            imgExtension: imgExtension,
            cropperData: cropperData,
            croppedImg: croppedImgUrl,
        },
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => {
        setIsModalOpen(true);
        if (isEditMode) {
            form.openModalSecond();
        }
    };
    const handleSubmitData = (data) => {
        setCompany({
            characteristics: data.characteristics,
            businessArea: {
                id: data.businessArea.id,
                name: data.businessArea.names[data.languageFlag],
            },
            logo: {
                sourceImg: data.logo?.sourceImg || null,
                imgExtension: data.logo?.imgExtension || null,
                cropperData: data.logo?.cropperData,
                croppedImg: data.logo?.croppedImg || null,
            },
        });
        setValue(FORM_COMPANY_CHARACTERISTICS, {
            ...characteristics,
            [data.languageFlag]: data.characteristics,
        });
        setValue(FORM_COMPANY_BUSINESS_AREA, data.businessArea);
        reset({
            FORM_COMPANY_CHARACTERISTICS,
            FORM_COMPANY_BUSINESS_AREA,
            FORM_COMPANY_LOGO_IMAGE_EXTENSION,
            FORM_COMPANY_LOGO_SOURCE_IMG,
            FORM_COMPANY_LOGO_CROPPER_DATA,
            FORM_COMPANY_LOGO_CROPPED_IMG,
        }, { keepValues: true, keepDirty: true });
        handleCloseModal();
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
        if (isEditMode) {
            form.closeModalSecond();
        }
    };
    useEffect(() => {
        setBase64Logo(croppedImgUrl || null);
        setSourceImgLogo(sourceImgUrl || null);
        setCropData(cropperData);
    }, []);
    useEffect(() => {
        setCompany({
            characteristics: characteristics[currentLanguage],
            businessArea: {
                id: businessArea.id,
                name: businessArea.names[currentLanguage],
            },
            logo: company.logo,
        });
    }, [currentLanguage]);
    return (_jsxs(_Fragment, { children: [_jsx(BoxedSectionWrapper, { title: t('title'), endComponent: _jsx(SimpleLink, { variantType: "primary", variant: "body2", onClick: handleOpenModal, children: editLink }), variant: SectionWrapperColorVariant.Dark, children: _jsx(CompanyDetailsContent, { characteristics: company.characteristics, businessAreaName: company.businessArea.name, croppedImg: company.logo?.croppedImg || null }) }), isModalOpen ? (_jsx(CompanyModal, { company: { company }, onSubmit: handleSubmitData, onClose: handleCloseModal, isOpen: isModalOpen })) : null] }));
};
export default CompanyDetails;
