import React from 'react';
import { SkeletonRectangular } from '@profesia/adamui/components/common/skeleton';
import { Stack } from '@mui/material';

const SettingsMenuSkeleton = ({ itemsCount = 6 }) => (
    <div className="settings-content_left">
        <div className="menu-wrapper">
            <div className="menu-settings">
                <Stack spacing={4} pl={4}>
                    {[...Array(itemsCount)].map((e, i) => (
                        <SkeletonRectangular width={237} height={16} borderRadius={26} key={i} />
                    ))}
                </Stack>
            </div>
        </div>
    </div>
);

export default SettingsMenuSkeleton;
