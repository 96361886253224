import Arrays from './Arrays';
import { CommonObjectType } from '../../interfaces/CommonTypes';

class Chunk {
    static mergeResults(
        finalResult: CommonObjectType,
        chunkResult: CommonObjectType,
        isLastChunk: boolean = true
    ): CommonObjectType {
        if (Object.keys(finalResult).length === 0) {
            return chunkResult;
        }

        Object.keys(chunkResult).forEach((objectKey) => {
            if (Array.isArray(chunkResult[objectKey]) === true) {
                if (chunkResult[objectKey].length > 0) {
                    finalResult[objectKey] = finalResult.hasOwnProperty(objectKey)
                        ? finalResult[objectKey].concat(chunkResult[objectKey])
                        : chunkResult[objectKey];

                    if (isLastChunk) {
                        finalResult[objectKey] = Arrays.getUniqueArrayOfUnknownObjects(
                            finalResult[objectKey]
                        );
                    }
                }
            } else {
                finalResult[objectKey] = chunkResult[objectKey];
            }
        });

        return finalResult;
    }

    static getToastMessagesFromActionByCondition(
        action: CommonObjectType,
        toastMessages: CommonObjectType[]
    ): CommonObjectType[] {
        if (toastMessages.length === 0) {
            return [];
        }

        const evaluateSingleCandidateCondition = (payload: CommonObjectType): boolean => {
            return (
                payload.hasOwnProperty('candidate') ||
                payload.hasOwnProperty('candidateId') ||
                (payload.hasOwnProperty('candidates') && payload.candidates.length === 1) ||
                (payload.hasOwnProperty('candidateIds') && payload.candidateIds.length === 1)
            );
        };

        const evaluateMultipleCandidatesCondition = (payload: CommonObjectType): boolean => {
            return (
                (payload.hasOwnProperty('candidates') && payload.candidates.length > 1) ||
                (payload.hasOwnProperty('candidateIds') && payload.candidateIds.length > 1)
            );
        };

        const evaluateCondition = (condition: string, action: CommonObjectType): boolean => {
            switch (condition) {
                case 'single':
                    if (
                        action.hasOwnProperty('payload') &&
                        evaluateSingleCandidateCondition(action.payload)
                    ) {
                        return true;
                    }

                    return (
                        action.hasOwnProperty('meta') &&
                        action.meta.hasOwnProperty('requestPayload') &&
                        evaluateSingleCandidateCondition(action.meta.requestPayload)
                    );

                case 'multiple':
                    if (
                        action.hasOwnProperty('payload') &&
                        evaluateMultipleCandidatesCondition(action.payload)
                    ) {
                        return true;
                    }

                    return (
                        action.hasOwnProperty('meta') &&
                        action.meta.hasOwnProperty('requestPayload') &&
                        evaluateMultipleCandidatesCondition(action.meta.requestPayload)
                    );

                default:
                    return false;
            }
        };

        let singleConditionMessages: CommonObjectType[] = toastMessages.filter(
            (message) =>
                message.condition === 'single' && evaluateCondition(message.condition, action)
        );
        const multipleConditionMessages: CommonObjectType[] = toastMessages.filter(
            (message) =>
                message.condition === 'multiple' && evaluateCondition(message.condition, action)
        );
        const multipleConditionMessageActionTypes: CommonObjectType[] = multipleConditionMessages
            .map((message) => message.actionType)
            .filter((actionType) => actionType !== null);

        if (multipleConditionMessageActionTypes.length > 0) {
            singleConditionMessages = singleConditionMessages.filter(
                (message) =>
                    message.actionType === null ||
                    !multipleConditionMessageActionTypes.includes(message.actionType)
            );
        }

        return singleConditionMessages.concat(multipleConditionMessages);
    }
}

export default Chunk;
