export const REQUEST_JOBS_GENERATOR_MODEL = 'REQUEST_JOBS_GENERATOR_MODEL';
export const RECEIVE_JOBS_GENERATOR_MODEL = 'RECEIVE_JOBS_GENERATOR_MODEL';
export const GENERATE_JOBS = 'GENERATE_JOBS';
export const GENERATE_JOBS_SUCCESS = 'GENERATE_JOBS_SUCCESS';
export const GENERATE_JOBS_FAILURE = 'GENERATE_JOBS_FAILURE';
export const SYNC_USER = 'SYNC_USER';

export const requestJobsGeneratorModel = (companyId) => ({
    type: REQUEST_JOBS_GENERATOR_MODEL,

    meta: {
        requestRoute: 'v1/admin/model/jobs-generator/' + companyId,
        onRequestSuccess: RECEIVE_JOBS_GENERATOR_MODEL,
    },
});

export const syncUser = (companyId, userId) => ({
    type: SYNC_USER,

    meta: {
        requestRoute: 'v1/admin/sync-user/' + companyId + '/' + userId,
        onRequestSuccess: RECEIVE_JOBS_GENERATOR_MODEL,
    },
});

export const generateJobs = (formData) => ({
    type: GENERATE_JOBS,

    meta: {
        requestRoute: 'v1/admin/action/generate-jobs',
        requestMethod: 'POST',

        requestBody: formData,

        onRequestSuccess: GENERATE_JOBS_SUCCESS,
        onRequestFailure: GENERATE_JOBS_FAILURE,
    },
});
