import React, { Component } from 'react';
import { connect } from 'react-redux';
import DetailCandidateNoModalTabs from './DetailCandidateNoModalTabs';
import DetailCandidateNoModalContent from './DetailCandidateNoModalContent';
import LoadingComponent from '../LoadingComponent';
import {
    requestCandidateModel,
    requestCandidateEvents,
    requestCandidateHistory,
} from '../../actions/CandidateActions';
import PropTypes from 'prop-types';
import Translations from '../../helpers/Translations';
import Constants from '../../helpers/Constants';
import InternalInfo from '../InternalInfo/InternalInfo';

class DetailCandidateReadOnlyContent extends Component {
    state = {
        activeMenuItem: 'attachments',
        candidateName: this.props.candidateName,
        attachmentsCount: this.props.attachmentsCount,
        questionnaireResult: this.props.questionnaireResult,
        hasQuestionnaireResult: this.props.hasQuestionnaireResult,
    };

    componentDidMount = () => {
        this.props.requestCandidateModel(this.props.candidateId);
        this.props.requestCandidateEvents(this.props.candidateId);
        this.props.requestCandidateHistory(this.props.candidateId);
    };

    handleMenuItemClick = (itemId) => this.setState({ activeMenuItem: itemId });

    render() {
        const {
            candidateModel: { candidate, events },
            displaySize,
            actionButton,
            loginManagerModel: { hasCompanyActiveInbox },
        } = this.props;
        const {
            activeMenuItem,
            candidateName,
            attachmentsCount,
            questionnaireResult,
            hasQuestionnaireResult,
        } = this.state;

        return (
            <>
                <div className="template-header header-extended_light">
                    <div className="header__row">
                        <div className="col-left">
                            <div className="square-button">
                                <div role="link" onClick={this.props.closeNoModal}>
                                    {Translations.getStatic('close')}
                                </div>
                            </div>
                            <div
                                className={
                                    'nav-grid' +
                                    (displaySize.width < Constants.WIDTH_NO_MODAL
                                        ? ' flex column justify center'
                                        : '')
                                }
                            >
                                <div className="nav-grid__title">
                                    <h3 className="ellipsis">
                                        {candidateName}
                                        <InternalInfo text={this.props.candidateId} />
                                    </h3>
                                </div>
                                {displaySize.width >= Constants.WIDTH_NO_MODAL && (
                                    <DetailCandidateNoModalTabs
                                        activeMenuItem={activeMenuItem}
                                        attachmentsCount={attachmentsCount}
                                        hasQuestionnaireResult={hasQuestionnaireResult}
                                        questionnaireResult={questionnaireResult}
                                        handleMenuItemClick={this.handleMenuItemClick}
                                        hasCompanyActiveInbox={hasCompanyActiveInbox}
                                    />
                                )}
                            </div>
                        </div>
                        {actionButton.visible === true && (
                            <div className="col-right">
                                <div
                                    className={
                                        'square-button icon ' +
                                        (actionButton.loading === true
                                            ? 'square-button_loading'
                                            : 'square-button_accept')
                                    }
                                >
                                    <div
                                        role="link"
                                        onClick={
                                            actionButton.disabled === true
                                                ? (e) => e.preventDefault()
                                                : actionButton.onClick
                                        }
                                    >
                                        {actionButton.text}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {candidate !== undefined && events !== undefined ? (
                    <DetailCandidateNoModalContent
                        candidate={candidate}
                        activeMenuItem={activeMenuItem}
                        attachmentsCount={attachmentsCount}
                        hasQuestionnaireResult={hasQuestionnaireResult}
                        questionnaireResult={questionnaireResult}
                        handleMenuItemClick={this.handleMenuItemClick}
                        isDuplicate={true}
                    />
                ) : (
                    <LoadingComponent />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    displaySize: state.displaySize,
    loginManagerModel: state.loginManagerModel,
});

const mapDispatchToProps = {
    requestCandidateModel,
    requestCandidateEvents,
    requestCandidateHistory,
};

DetailCandidateReadOnlyContent.propTypes = {
    candidateModel: PropTypes.object.isRequired,
    closeNoModal: PropTypes.func.isRequired,
    candidateId: PropTypes.number.isRequired,
    candidateName: PropTypes.string.isRequired,
    attachmentsCount: PropTypes.number.isRequired,
    questionnaireResult: PropTypes.number,
};

DetailCandidateReadOnlyContent.defaultProps = {
    questionnaireResult: null,
    attachmentsCount: 0,
    candidateName: '',
    actionButton: {
        visible: false,
        text: '~strings.save~',
        loading: false,
        disabled: false,
        onClick: (e) => e.preventDefault(),
    },
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailCandidateReadOnlyContent);
