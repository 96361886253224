import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@mui/material';
import AvatarContainer from '@profesia/adamui/components/common/Avatar/AvatarContainer';
const AvatarPhoto = ({ src, size = 'standard', ...otherProps }) => {
    return (_jsx(AvatarContainer, { size: size, showDefaultBorder: false, ...otherProps, children: _jsx(AvatarImage, { src: src }) }));
};
const AvatarImage = styled('img')(() => ({
    height: 'inherit',
    width: 'inherit',
    borderRadius: '50%',
}));
export default AvatarPhoto;
