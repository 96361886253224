import React from 'react';
import PropTypes from 'prop-types';
import { InputField } from '@profesia/adamui/components/common/input';
import Button from '../../Button';
import Grid from '../../Grid';
import Divider from '../../Divider';
import Translations from '../../../helpers/Translations';

const QuestionnaireEditorAnswersQuestion = (props) => {
    const moveUp =
        props.first === true ? null : (
            <Button
                icon={{ className: 'button icon-up_dark small' }}
                onClick={() => props.onReorderQuestionItem(props.index, props.index - 1)}
            />
        );

    const moveDown =
        props.last === true ? null : (
            <Button
                icon={{ className: 'button icon-down_dark small' }}
                onClick={() => props.onReorderQuestionItem(props.index, props.index + 1)}
            />
        );

    return (
        <Grid key={props.index}>
            <Grid.Column className="questionnaire-box main-column">
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={1} verticalAlign="middle">
                            <span className="text-form__label">{props.index + 1}.</span>
                        </Grid.Column>
                        <Grid.Column width={14} verticalAlign="middle">
                            <InputField
                                maxLength={5000}
                                value={props.question.question}
                                onChange={(e) =>
                                    props.onUpdateQuestionItem(props.index, e.target.value)
                                }
                            />
                        </Grid.Column>
                        <Grid.Column width={1} verticalAlign="middle" textAlign="right">
                            <Button
                                icon={{ className: 'button icon-cancel' }}
                                onClick={() => props.onDeleteQuestionItem(props.index)}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider className="size_12 multiple" />
                <Grid>{props.answers}</Grid>
                <Divider hidden className="size_8" />
                <Grid>
                    <Grid.Column width={2} />
                    <Grid.Column width={14}>
                        <a
                            className="secondary-link"
                            onClick={() => props.onAddAnswerItem(props.index)}
                        >
                            {Translations.getStatic('addNewAnswer')}
                        </a>
                    </Grid.Column>
                </Grid>
                {props.question.hasOwnProperty('answers') === true &&
                    props.question.answers.find(
                        (answer) =>
                            answer.hasOwnProperty('isRequired') === true &&
                            answer.isRequired === true
                    ) !== undefined && (
                        <>
                            <Divider />
                            <Grid>
                                <Grid.Column width={16}>
                                    * {Translations.getStatic('requiredQuestionAnswerUsed')}
                                </Grid.Column>
                            </Grid>
                        </>
                    )}
            </Grid.Column>
            <Grid.Column className="button-column">
                {moveUp}
                {moveDown}
            </Grid.Column>
        </Grid>
    );
};

QuestionnaireEditorAnswersQuestion.propTypes = {
    answers: PropTypes.array.isRequired,
    question: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    first: PropTypes.bool.isRequired,
    last: PropTypes.bool.isRequired,
    onDeleteQuestionItem: PropTypes.func.isRequired,
    onUpdateQuestionItem: PropTypes.func.isRequired,
    onAddAnswerItem: PropTypes.func.isRequired,
    onReorderQuestionItem: PropTypes.func.isRequired,
};

export default QuestionnaireEditorAnswersQuestion;
