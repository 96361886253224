import {
    convertToRaw,
    convertFromRaw,
    convertFromHTML,
    EditorState,
    ContentState,
    CompositeDecorator,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Variable from '../../components/WysiwygEditor/Variable';
import Link from '../../components/WysiwygEditor/Link';
import nl2br from 'nl2br';

const VARIABLE_ENTITY_TYPE = 'VARIABLE';
const VARIABLE_ENTITY_MUTABILITY = 'Immutable';

export default class WysiwygHelper {
    static getHtmlFromEditor(editorState) {
        return draftToHtml(convertToRaw(editorState.getCurrentContent()));
    }

    static findVariableEntitiesStrategy(contentBlock, callback, contentState) {
        contentBlock.findEntityRanges((character) => {
            const entityKey = character.getEntity();

            return entityKey !== null && contentState.getEntity(entityKey).getType() === 'VARIABLE';
        }, callback);
    }

    static findLinkEntitiesStrategy(contentBlock, callback, contentState) {
        contentBlock.findEntityRanges((character) => {
            const entityKey = character.getEntity();

            return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
        }, callback);
    }

    static getDecorators() {
        return [
            {
                strategy: this.findVariableEntitiesStrategy,
                component: Variable,
            },
            {
                strategy: this.findLinkEntitiesStrategy,
                component: Link,
            },
        ];
    }

    static findVariableEntitiesInHtml(html, contentState, variableList) {
        const regex = /\(([^)]+?)\)/gm;

        let entities = [];
        let match;

        while ((match = regex.exec(html)) !== null) {
            const contentStateWithEntity = contentState.createEntity(
                VARIABLE_ENTITY_TYPE,
                VARIABLE_ENTITY_MUTABILITY
            );

            if (variableList.indexOf(match[0]) > -1) {
                entities.push({
                    key: contentStateWithEntity.getLastCreatedEntityKey(),
                    offset: match.index,
                    length: match[0].length,
                    entity: {
                        type: VARIABLE_ENTITY_TYPE,
                        mutability: VARIABLE_ENTITY_MUTABILITY,
                    },
                });
            }
        }

        return entities;
    }

    static createEditorStateFromHtml(html, variableList, cursorOnStart = false) {
        if (html === '' || html === null || html === undefined) {
            return EditorState.createEmpty();
        }

        const blocksFromHTML = convertFromHTML(html);
        const contentBlocks =
            blocksFromHTML !== null && blocksFromHTML.contentBlocks !== null
                ? blocksFromHTML.contentBlocks
                : [];

        if (contentBlocks.length === 0) {
            return EditorState.createEmpty();
        }

        let contentState = ContentState.createFromBlockArray(
            contentBlocks,
            blocksFromHTML.entityMap
        );

        let rawContent = convertToRaw(contentState);
        let { blocks, entityMap } = rawContent;

        const newEntityMap = {
            ...entityMap,
        };

        const newBlocks = blocks.map((block) => {
            const entities = this.findVariableEntitiesInHtml(
                block.text,
                contentState,
                variableList
            );

            for (let i = 0; i < entities.length; i++) {
                const entity = entities[i];
                newEntityMap[parseInt(entity.key)] = entity.entity;
            }

            return {
                ...block,

                entityRanges: block.entityRanges.concat(
                    entities.map((entity) => ({
                        key: parseInt(entity.key),
                        offset: parseInt(entity.offset),
                        length: parseInt(entity.length),
                    }))
                ),
            };
        });

        contentState = convertFromRaw({
            blocks: newBlocks,
            entityMap: newEntityMap,
        });

        let state = EditorState.createWithContent(
            contentState,
            new CompositeDecorator(WysiwygHelper.getDecorators())
        );

        if (cursorOnStart === false) {
            state = EditorState.moveFocusToEnd(state);
        }

        return state;
    }

    static convertOldToNewText(text, variableList = []) {
        const converted = this.createEditorStateFromHtml(nl2br(text), variableList);

        return this.getHtmlFromEditor(converted);
    }

    static createVariableString(variable) {
        return '(' + variable + ')';
    }
}
