import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { TagField } from '@profesia/adamui/components/common/input';
import { useGetFixedT, useGetLocale } from '@profesia/mark-offer-form/i18n';
import { getCountryCoordinates } from '@profesia/mark-offer-form/codebooks';
import { useOptionsContext } from '@profesia/mark-offer-form/services/OptionsContext';
import { useAsyncOptionsTagFieldAdapter } from '@profesia/mark-offer-form/components/content/common/tagFieldAdapter';
import { getAutocompletePredictions } from '@profesia/mark-offer-form/services/googleAutocompleteService';
import { getError } from '@profesia/mark-offer-form/components/content/form';
import { FORM_POSITION_PLACE_ADDRESS } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import useToastWithTranslation from '@profesia/mark-offer-form/services/useToastWithTranslation';
const GooglePlaceAutocomplete = ({ autocompleteService, placeholder, }) => {
    const t = useGetFixedT('common');
    const { isEditMode } = useOptionsContext();
    const { setValue, setError, control, formState: { errors }, } = useFormContext();
    const values = useWatch({ name: FORM_POSITION_PLACE_ADDRESS, control });
    const [errorMessage, setErrorMessage] = useState('');
    const googleMapsCore = useMapsLibrary('core');
    const currentLanguage = useGetLocale();
    const addToast = useToastWithTranslation();
    const coordinates = getCountryCoordinates(currentLanguage);
    const error = getError(errors.positionPlace?.address) || '';
    const placesOptions = {
        language: currentLanguage,
        ...(coordinates && googleMapsCore
            ? {
                locationBias: new googleMapsCore.LatLngBounds(new googleMapsCore.LatLng(coordinates)),
            }
            : null),
    };
    const getPlace = async (args) => {
        try {
            return await getAutocompletePredictions(autocompleteService, placesOptions, args.inputValue);
        }
        catch (error) {
            setError(FORM_POSITION_PLACE_ADDRESS, {
                type: 'custom',
                message: t('responseError.googlePlaceAutocomplete'),
            });
            addToast({ message: 'common.responseError.googlePlaceAutocomplete', variant: 'error' });
            return [];
        }
    };
    const arePlacesSame = (existingValue, newValue) => {
        if (existingValue.length !== newValue.length) {
            return false;
        }
        const existingValueIds = existingValue.map((item) => item.id).sort();
        const newValueIds = newValue.map((item) => item.id).sort();
        return existingValueIds.every((id, index) => id === newValueIds[index]);
    };
    const optionsProps = useAsyncOptionsTagFieldAdapter(getPlace);
    useEffect(() => {
        if (!isEditMode && (!error || values.length > 0)) {
            setValue(FORM_POSITION_PLACE_ADDRESS, []);
        }
    }, []);
    useEffect(() => {
        if (errorMessage !== error) {
            setErrorMessage(error);
        }
    }, [error, errorMessage]);
    return (_jsx(TagField, { filterSelectedOptions: true, shouldCloseOnLimitReached: true, noOptionsText: t('comboTagger.noOptions'), placeholder: { empty: placeholder, filled: '' }, limit: 1, inputFieldLabelProps: {
            required: true,
        }, options: values, size: "large", value: values, onChange: (newValues) => {
            if (!arePlacesSame(values, newValues) && (!error || newValues.length > 0)) {
                setValue(FORM_POSITION_PLACE_ADDRESS, newValues);
            }
        }, optionsProps: { ...optionsProps }, errorMessage: errorMessage }));
};
export default GooglePlaceAutocomplete;
