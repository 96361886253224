import {
    REQUEST_JOB_MODEL,
    RECEIVE_JOB_MODEL,
    FAILURE_JOB_MODEL,
    GET_POSITION_DATA,
    GET_POSITION_SUCCESS,
    GET_POSITION_FAILURE,
    OPEN_JOB_DETAIL,
    CLOSE_JOB_DETAIL,
    ACTIVATE_JOB_SUCCESS_DETAIL,
    OPEN_JOB_FAILURE_DETAIL,
    CLOSE_JOB_FAILURE_DETAIL,
    ACTIVATE_JOB_FAILURE_DETAIL,
    CREATE_SOURCE_TAG,
    DLETE_SOURCE_TAG,
    CREATE_SOURCE_TAG_SUCCESS,
    CREATE_SOURCE_TAG_FAILURE,
    DLETE_SOURCE_TAG_SUCCESS,
    DLETE_SOURCE_TAG_FAILURE,
    UPDATE_QUESTIONNAIRE_DATA,
    UPDATE_QUESTIONNAIRE_DATA_SUCCESS,
    UPDATE_QUESTIONNAIRE_DATA_FAILURE,
    UPDATE_AUTOANSWER_DATA,
    UPDATE_AUTOANSWER_DATA_SUCCESS,
    UPDATE_AUTOANSWER_DATA_FAILURE,
    UPDATE_JOB,
    UPDATE_JOB_SUCCESS,
    UPDATE_JOB_FAILURE,
    OPEN_JOB_SUCCESS_DETAIL,
    CLOSE_JOB_SUCCESS_DETAIL,
    CHANGE_CANDIDATE_STAGE,
    LOAD_FULL_CANDIDATES_DATA_FOR_JOB,
    LOAD_FULL_CANDIDATES_DATA_FOR_JOB_FAILURE,
    LOAD_FULL_CANDIDATES_DATA_FOR_JOB_SUCCESS,
    LOAD_PIPELINE_CANDIDATES_DATA_FOR_JOB,
    LOAD_PIPELINE_CANDIDATES_DATA_FOR_JOB_FAILURE,
    LOAD_PIPELINE_CANDIDATES_DATA_FOR_JOB_SUCCESS,
    RESET_FULL_CANDIDATES_DATA,
    SET_EMPTY_FULL_CANDIDATES_DATA,
    ENABLE_REACTION_FORM,
    ENABLE_REACTION_FORM_SUCCESS,
    ENABLE_REACTION_FORM_FAILURE,
    DISABLE_REACTION_FORM,
    DISABLE_REACTION_FORM_SUCCESS,
    DISABLE_REACTION_FORM_FAILURE,
    ADD_EXTERNAL_USER,
    ADD_EXTERNAL_USER_SUCCESS,
    ADD_EXTERNAL_USER_FAILURE,
    REMOVE_EXTERNAL_USER,
    REMOVE_EXTERNAL_USER_SUCCESS,
    REMOVE_EXTERNAL_USER_FAILURE,
    CREATE_RECOMMENDATION_TAG,
    CREATE_RECOMMENDATION_TAG_FAILURE,
    CREATE_RECOMMENDATION_TAG_SUCCESS,
    DELETE_RECOMMENDATION_TAG,
    DELETE_RECOMMENDATION_TAG_SUCCESS,
    DELETE_RECOMMENDATION_TAG_FAILURE,
    ENABLE_RECOMMENDATION_TAG,
    ENABLE_RECOMMENDATION_TAG_FAILURE,
    ENABLE_RECOMMENDATION_TAG_SUCCESS,
    APPLY_DEFAULT_PIPELINE_TEMPLATE_TO_JOB,
    APPLY_DEFAULT_PIPELINE_TEMPLATE_TO_JOB_FAILURE,
    APPLY_DEFAULT_PIPELINE_TEMPLATE_TO_JOB_SUCCESS,
    APPLY_PIPELINE_TEMPLATE_TO_JOB,
    APPLY_PIPELINE_TEMPLATE_TO_JOB_SUCCESS,
    APPLY_PIPELINE_TEMPLATE_TO_JOB_FAILURE,
    CHECK_STAGES_FOR_TRANSFORM,
    CHECK_STAGES_FOR_TRANSFORM_FAILURE,
    CHECK_STAGES_FOR_TRANSFORM_SUCCESS,
    PROCESS_PIPELINE_TEMPLATE_FOR_JOB,
    PROCESS_PIPELINE_TEMPLATE_FOR_JOB_FAILURE,
    PROCESS_PIPELINE_TEMPLATE_FOR_JOB_SUCCESS,
    CHANGE_EXTERNAL_USER_ROLE_SUCCESS,
    CHANGE_EXTERNAL_USER_ROLE_FAILURE,
    CHANGE_EXTERNAL_USER_ROLE,
    SET_SELECTED_CANDIDATE_ID,
    CANDIDATE_CLICKED,
} from '../actions/JobDetailActions';
import Constants from '../helpers/Constants';
import {
    CANDIDATE_REMOVE_TAG_SUCCESS,
    CANDIDATE_ADD_TAG_SUCCESS,
    CANDIDATE_SET_NOTE_SUCCESS,
    GET_CANDIDATE,
    GET_CANDIDATE_SUCCESS,
    GET_CANDIDATE_FAILURE,
} from '../actions/CandidateActions';
import { SAVE_CANDIDATE_SUCCESS } from '../actions/CreateCandidateActions';
import {
    CHANGE_CANDIDATES_STAGE_SUCCESS,
    ANONYMIZE_CANDIDATES_SUCCESS,
    CHANGE_CANDIDATES_STAGE,
    SAVE_CANDIDATE_GDPR_AGREEMENT_SUCCESS,
} from '../actions/ActionWithCandidateActions';
import CandidatesHelper from '../helpers/components/CandidatesHelper';
import DateFormatted from '../helpers/DateFormatted';
import {
    FILTER_CANDIDATES_BY_NAME,
    FILTER_CANDIDATES_BY_NOT_CONTACTED,
    FILTER_CANDIDATES_BY_SCORE_RANGE,
    FILTER_CANDIDATES_BY_SOURCE_TYPE_COLLECTION,
    FILTER_CANDIDATES_BY_TAG_LIST,
    FILTER_CANDIDATES_BY_STATE_ACTIVE,
    FILTER_CANDIDATES_BY_STATE_PASSIVE,
    FILTER_CANDIDATES_BY_ANONYMIZED,
    FILTER_CANDIDATES_BY_STATE_RESET,
    FILTER_CANDIDATES_BY_STATE,
    FILTER_CANDIDATES_BY_STAGE_COLLECTION,
    FILTER_CANDIDATES_BY_PHASE,
    FILTER_CANDIDATES_BY_REFUSED_AND_NOT_CONTACTED,
    FILTER_CANDIDATES_BY_NO_GDPR_AGREEMENT,
    RESET_FILTER,
} from '../actions/CandidatesFilterActions';
import { SORT_CANDIDATES_BY } from '../actions/CandidatesListActions';
import CandidatesReducer from '../helpers/generalReducers/CandidatesReducer';
import { SAVE_CANDIDATES_LIST_COLUMNS_ORDER_SUCCESS } from '../actions/CandidatesListColumnsOrderActions';
import { UPDATE_POSITION_SUCCESS } from '../actions/CreateJobActions';
import { NO_MODAL_CLOSE } from '../actions/ModalActions';

export const jobModel = (state = {}, action) => {
    let stages = [];
    let candidates = [];
    let change = {};

    const updateJobState = (state, payload, updateTime = false) => ({
        ...state,
        job: {
            ...(state.job ?? {}),
            id: state.job?.id ?? payload.id,
            position: payload,
            jobTitle: payload.title,
            updatedAt: updateTime
                ? DateFormatted.getDateTime(new Date(), 'Y-m-d H:i:s')
                : state.job?.updatedAt,
            user: {
                ...(state.job?.user ?? {}),
                id: payload.userId,
            },
        },
    });

    switch (action.type) {
        case RECEIVE_JOB_MODEL:
            return {
                ...state,
                ...action.payload,
                job: {
                    ...state.job,
                    ...action.payload.job,
                    position: state.job?.position ?? null,
                },
            };

        case GET_POSITION_SUCCESS:
            return updateJobState(state, action.payload);

        case UPDATE_POSITION_SUCCESS:
            return updateJobState(state, action.payload, true);

        case OPEN_JOB_DETAIL:
        case CLOSE_JOB_DETAIL:
            if (state.hasOwnProperty('job') === false) {
                return state;
            }

            return {
                ...state,

                job: {
                    ...state.job,
                    stateId:
                        state.job.id === action.payload.jobId
                            ? action.payload.stateId
                            : state.job.stateId,
                },
            };

        case OPEN_JOB_SUCCESS_DETAIL:
        case CLOSE_JOB_SUCCESS_DETAIL:
            return {
                ...state,

                job: {
                    ...action.payload.job,
                    position: state.job.position,
                },
            };

        case ACTIVATE_JOB_SUCCESS_DETAIL:
            if (state.hasOwnProperty('job') === false) {
                return state;
            }

            return {
                ...state,

                job: {
                    ...state.job,
                    stateId: action.payload.stateId,
                },
            };

        case OPEN_JOB_FAILURE_DETAIL:
            return {
                ...state,

                job: {
                    ...state.job,
                    stateId:
                        state.job.id === action.meta.requestPayload.jobId
                            ? Constants.JOB_STATE_CLOSED
                            : state.job.stateId,
                },
            };

        case CLOSE_JOB_FAILURE_DETAIL:
            return {
                ...state,

                job: {
                    ...state.job,
                    stateId:
                        state.job.id === action.meta.requestPayload.jobId
                            ? Constants.JOB_STATE_UNPAID
                            : state.job.stateId,
                },
            };

        case ACTIVATE_JOB_FAILURE_DETAIL:
            if (state.hasOwnProperty('job') === false) {
                return state;
            }

            return {
                ...state,

                job: {
                    ...state.job,
                    stateId:
                        state.job.id === action.meta.requestPayload.jobId
                            ? Constants.JOB_STATE_UNPAID
                            : state.job.stateId,
                },
            };

        case CANDIDATE_ADD_TAG_SUCCESS:
            if (state.hasOwnProperty('candidates') === true) {
                return {
                    ...state,

                    candidates: state.candidates.map((candidate) => {
                        const candidateData = action.payload.candidates.find(
                            (payloadCandidate) => candidate.id === payloadCandidate.id
                        );
                        const candidateHistoryData = action.payload.candidatesHistory.find(
                            (payloadCandidate) => candidate.candidateId === payloadCandidate.id
                        );

                        return candidateData === undefined
                            ? candidate
                            : {
                                  ...candidate,
                                  tags: candidateData.tags,
                                  history:
                                      candidateHistoryData === undefined
                                          ? candidate.history
                                          : candidateHistoryData.history,
                              };
                    }),

                    settings: {
                        ...state.settings,
                        tags: action.payload.tags,
                    },

                    ...CandidatesHelper.mergeBulkFullCandidatesDataIntoState(
                        state,
                        (candidateFromState) => {
                            const candidateData = action.payload.candidates.find(
                                (payloadCandidate) => candidateFromState.id === payloadCandidate.id
                            );

                            return candidateData === undefined
                                ? null
                                : {
                                      tags: candidateData.tags,
                                  };
                        }
                    ),
                };
            }

            return state;

        case CANDIDATE_REMOVE_TAG_SUCCESS:
            if (state.hasOwnProperty('candidates') === true) {
                change = {
                    tags: action.payload.candidate.tags,
                };

                return {
                    ...state,

                    candidates: state.candidates.map((candidate) =>
                        candidate.id === action.payload.candidate.id
                            ? {
                                  ...candidate,
                                  ...change,
                              }
                            : candidate
                    ),

                    ...CandidatesHelper.mergeFullCandidatesDataIntoState(
                        state,
                        (candidateFromState) =>
                            candidateFromState.id === action.payload.candidate.id,
                        change
                    ),
                };
            }
            return state;

        case CANDIDATE_SET_NOTE_SUCCESS:
            if (state.hasOwnProperty('candidates') === true) {
                change = action.payload.candidate;

                return {
                    ...state,

                    candidates: state.candidates.map((candidate) =>
                        candidate.id === action.payload.candidate.id
                            ? {
                                  ...candidate,
                                  ...change,
                              }
                            : candidate
                    ),

                    ...CandidatesHelper.mergeFullCandidatesDataIntoState(
                        state,
                        (candidateFromState) =>
                            candidateFromState.id === action.payload.candidate.id,
                        change
                    ),
                };
            }
            return state;

        case CREATE_SOURCE_TAG_SUCCESS:
        case DLETE_SOURCE_TAG_SUCCESS:
            return {
                ...state,

                settings: {
                    ...state.settings,
                    sourceTags: action.payload.sourceTags,
                },
            };

        case CREATE_RECOMMENDATION_TAG_SUCCESS:
        case DELETE_RECOMMENDATION_TAG_SUCCESS:
        case ENABLE_RECOMMENDATION_TAG_SUCCESS:
            return {
                ...state,

                settings: {
                    ...state.settings,
                    recommendationTags: action.payload.recommendationTags,
                },
            };

        case UPDATE_AUTOANSWER_DATA:
            return {
                ...state,

                originalAutoAnswerData: {
                    id: state.job.emailTemplateId,
                    data: state.job.emailTemplateData,
                },
            };

        case UPDATE_AUTOANSWER_DATA_SUCCESS:
            return {
                ...state,

                job: {
                    ...state.job,
                    emailTemplateId: action.payload.autoAnswerId,
                    emailTemplateData: action.payload.autoAnswerData,
                },
            };

        case UPDATE_AUTOANSWER_DATA_FAILURE:
            return {
                ...state,

                job: {
                    ...state.job,
                    emailTemplateId: state.originalAutoAnswerData.id,
                    emailTemplateData: state.originalAutoAnswerData.data,
                },
            };

        case UPDATE_QUESTIONNAIRE_DATA:
            return {
                ...state,

                originalQuestionnaireData: {
                    id: state.job.questionnaireId,
                    data: state.job.questionnaireData,
                },
            };

        case UPDATE_QUESTIONNAIRE_DATA_SUCCESS:
            return {
                ...state,

                job: {
                    ...state.job,
                    questionnaireId: action.payload.questionnaireId,
                    questionnaireData: action.payload.questionnaireData,
                },
            };

        case UPDATE_QUESTIONNAIRE_DATA_FAILURE:
            return {
                ...state,

                job: {
                    ...state.job,
                    questionnaireId: state.originalQuestionnaireData.id,
                    questionnaireData: state.originalQuestionnaireData.data,
                },
            };

        case UPDATE_JOB:
            return {
                ...state,
                originalJobData: state.job,
            };

        case UPDATE_JOB_SUCCESS:
            return {
                ...state,
                job: Object.assign({}, state.job, action.payload.jobData),
                originalJobData: null,
            };

        case UPDATE_JOB_FAILURE:
            return {
                ...state,
                job: state.originalJobData,
                originalJobData: null,
            };

        case SAVE_CANDIDATE_SUCCESS:
            if (state.hasOwnProperty('candidates') === false) {
                return state;
            }

            const prepareCandidatesAfterSaveCandidate = () => {
                const stateCandidatesParams = ['candidates', 'fullCandidates'];

                let candidatesResult = {};

                stateCandidatesParams.forEach((stateCandidateParam) => {
                    if (state.hasOwnProperty(stateCandidateParam) === true) {
                        candidates =
                            state[stateCandidateParam] === undefined
                                ? []
                                : state[stateCandidateParam];

                        if (parseInt(action.meta.requestPayload.candidateId) === 0) {
                            candidates.push({
                                ...action.payload.candidate,
                                positionInStage: -1,
                            });

                            candidates = CandidatesHelper.rearrangeCandidatesPositionInStage(
                                candidates,
                                [action.payload.candidate.stage.id]
                            );
                        } else {
                            candidates = candidates.map((candidate) =>
                                action.meta.requestPayload.candidateId === candidate.id
                                    ? action.payload.candidate
                                    : candidate
                            );
                        }

                        candidatesResult[stateCandidateParam] = candidates;
                    }
                });

                return candidatesResult;
            };

            return {
                ...state,
                ...prepareCandidatesAfterSaveCandidate(),
            };

        case CHANGE_CANDIDATES_STAGE_SUCCESS:
            stages =
                state.settings.pipeline === null
                    ? state.settings.stages
                    : state.settings.pipeline.stages;

            const selectedStage = stages.find(
                (stage) => stage.stageId === action.meta.requestPayload.stageId
            );

            change = {
                stage: {
                    ...selectedStage,
                    id: selectedStage.stageId,
                    name: selectedStage.stageName,
                },

                stateId: Constants.CANDIDATE_STATE_ACTIVE,
            };

            return {
                ...state,

                candidates: state.candidates.map((candidate) =>
                    action.meta.requestPayload.candidateIds.includes(candidate.id) === true
                        ? {
                              ...candidate,
                              ...change,
                          }
                        : candidate
                ),

                ...CandidatesHelper.mergeFullCandidatesDataIntoState(
                    state,
                    (candidateFromState) =>
                        action.meta.requestPayload.candidateIds.includes(candidateFromState.id) ===
                        true,
                    change
                ),
            };

        case ANONYMIZE_CANDIDATES_SUCCESS:
            if (state.hasOwnProperty('candidates') === false) {
                return state;
            }

            const replaceCandidateAfterAnonymize = (candidate) => {
                const replaceCandidate = action.payload.candidates.find(
                    (c) => c.id === candidate.id
                );

                return replaceCandidate === undefined ? candidate : replaceCandidate;
            };

            return {
                ...state,
                candidates: state.candidates.map((candidate) =>
                    replaceCandidateAfterAnonymize(candidate)
                ),
                ...CandidatesHelper.mergeFullCandidatesDataIntoStateWithCallback(
                    state,
                    () => true,
                    (candidate) => replaceCandidateAfterAnonymize(candidate)
                ),
            };

        case CHANGE_CANDIDATE_STAGE:
            if (state.hasOwnProperty('candidates') === false) {
                return state;
            }

            return {
                ...state,
                candidates: CandidatesHelper.moveCandidatesToPositionInStage(
                    state.candidates,
                    [action.payload.candidateId],
                    action.payload.stage,
                    action.payload.positionInStage
                ),
                fullCandidates: CandidatesHelper.moveCandidatesToPositionInStage(
                    state.fullCandidates,
                    [action.payload.candidateId],
                    action.payload.stage,
                    action.payload.positionInStage
                ),
            };

        case CHANGE_CANDIDATES_STAGE:
            if (state.hasOwnProperty('candidates') === false) {
                return state;
            }

            return {
                ...state,
                candidates: CandidatesHelper.moveCandidatesToPositionInStage(
                    state.candidates,
                    action.payload.candidateIds,
                    action.payload.stage,
                    action.payload.positionInStage
                ),
                fullCandidates: CandidatesHelper.moveCandidatesToPositionInStage(
                    state.fullCandidates,
                    action.payload.candidateIds,
                    action.payload.stage,
                    action.payload.positionInStage
                ),
            };

        case SAVE_CANDIDATE_GDPR_AGREEMENT_SUCCESS:
            if (
                state.hasOwnProperty('candidates') === false ||
                state.candidates === undefined ||
                state.hasOwnProperty('fullCandidates') === false ||
                state.fullCandidates === undefined
            ) {
                return state;
            }

            return {
                ...state,

                candidates: state.candidates.map((candidate) =>
                    candidate.id === action.meta.requestPayload.candidateId
                        ? {
                              ...candidate,
                              gdprAgreement: action.payload.candidateGdprAgreement,
                              attachmentsCount: action.payload.candidateAttachmentsCount,
                          }
                        : candidate
                ),

                fullCandidates: state.fullCandidates.map((candidate) =>
                    candidate.id === action.meta.requestPayload.candidateId
                        ? {
                              ...candidate,
                              gdprAgreement: action.payload.candidateGdprAgreement,
                              attachmentsCount: action.payload.candidateAttachmentsCount,
                          }
                        : candidate
                ),
            };

        case RESET_FULL_CANDIDATES_DATA:
            return {
                ...state,
                fullCandidates: undefined,
                fetchedCandidateIds: [],
            };

        case SET_EMPTY_FULL_CANDIDATES_DATA:
            return {
                ...state,
                fullCandidates: [],
                fetchedCandidateIds: [],
            };

        case CANDIDATE_CLICKED:
            return {
                ...state,
                fullCandidates: state.fullCandidates?.map((candidate) =>
                    candidate.id === action.payload.candidateId
                        ? { ...candidate, isClicked: action.payload.isClicked }
                        : candidate
                ),
            };

        case LOAD_FULL_CANDIDATES_DATA_FOR_JOB_SUCCESS:
            return {
                ...state,

                fullCandidates:
                    state.fullCandidates === undefined
                        ? action.payload.fullCandidates
                        : state.fullCandidates.concat(action.payload.fullCandidates),

                fetchedCandidateIds: state.fetchedCandidateIds.concat(
                    action.payload.fetchedCandidateIds
                ),
            };
        
        case LOAD_PIPELINE_CANDIDATES_DATA_FOR_JOB_SUCCESS:
            return {
                ...state,

                fullCandidates:
                    state.fullCandidates === undefined
                        ? action.payload.pipelineCandidatesData
                        : { ...state.fullCandidates, ...action.payload.pipelineCandidatesData },

                fetchedCandidateIds: state.fetchedCandidateIds.concat(
                    action.payload.fetchedPipelineCandidateIds
                ),
            };

        case FILTER_CANDIDATES_BY_NAME:
        case FILTER_CANDIDATES_BY_NOT_CONTACTED:
        case FILTER_CANDIDATES_BY_SCORE_RANGE:
        case FILTER_CANDIDATES_BY_SOURCE_TYPE_COLLECTION:
        case FILTER_CANDIDATES_BY_TAG_LIST:
        case FILTER_CANDIDATES_BY_STATE_ACTIVE:
        case FILTER_CANDIDATES_BY_STATE_PASSIVE:
        case FILTER_CANDIDATES_BY_ANONYMIZED:
        case FILTER_CANDIDATES_BY_STATE_RESET:
        case FILTER_CANDIDATES_BY_STATE:
        case FILTER_CANDIDATES_BY_STAGE_COLLECTION:
        case FILTER_CANDIDATES_BY_PHASE:
        case FILTER_CANDIDATES_BY_REFUSED_AND_NOT_CONTACTED:
        case FILTER_CANDIDATES_BY_NO_GDPR_AGREEMENT:
        case SORT_CANDIDATES_BY:
        case RESET_FILTER:
            if (state.hasOwnProperty('candidates') === false || state.candidates === undefined) {
                return state;
            }

            return {
                ...state,
                fullCandidates: undefined,
                fetchedCandidateIds: [],
            };

        case ENABLE_REACTION_FORM_SUCCESS:
        case DISABLE_REACTION_FORM_FAILURE:
            return {
                ...state,
                job: {
                    ...state.job,
                    hasEnabledReactionForm: true,
                },
            };

        case DISABLE_REACTION_FORM_SUCCESS:
        case ENABLE_REACTION_FORM_FAILURE:
            return {
                ...state,
                job: {
                    ...state.job,
                    hasEnabledReactionForm: false,
                },
            };

        case ADD_EXTERNAL_USER_SUCCESS:
        case ADD_EXTERNAL_USER_FAILURE:
        case REMOVE_EXTERNAL_USER_SUCCESS:
        case REMOVE_EXTERNAL_USER_FAILURE:
        case CHANGE_EXTERNAL_USER_ROLE_SUCCESS:
        case CHANGE_EXTERNAL_USER_ROLE_FAILURE:
            return {
                ...state,

                settings: {
                    ...state.settings,
                    ...action.payload,
                },
            };

        case SAVE_CANDIDATES_LIST_COLUMNS_ORDER_SUCCESS:
            if (
                state.hasOwnProperty('settings') === false ||
                state.settings.hasOwnProperty('candidatesListColumnsOrder') === false
            ) {
                return state;
            }

            return {
                ...state,
                settings: {
                    ...state.settings,
                    candidatesListColumnsOrder: action.payload.candidatesListColumnsOrder,
                },
            };

        case APPLY_DEFAULT_PIPELINE_TEMPLATE_TO_JOB_SUCCESS:
        case APPLY_PIPELINE_TEMPLATE_TO_JOB_SUCCESS:
        case PROCESS_PIPELINE_TEMPLATE_FOR_JOB_SUCCESS:
            return {
                ...state,

                job: {
                    ...state.job,
                    pipelineId: action.payload.pipelineTemplate.pipeline.id,
                },

                settings: {
                    ...state.settings,
                    pipeline: action.payload.pipelineTemplate.pipeline,
                    pipelineTemplate: action.payload.pipelineTemplate,
                },

                pipelineChange: {},
                fullCandidates: undefined,
                fetchedCandidateIds: [],

                candidates:
                    action.payload.hasOwnProperty('changedStages') === true &&
                    action.payload.changedStages !== null &&
                    action.payload.changedStages.length > 0
                        ? state.candidates.map((candidate) => {
                              const changedStage = action.payload.changedStages.find(
                                  (stage) => stage.fromStageId === candidate.stage.id
                              );

                              if (changedStage === undefined) {
                                  return candidate;
                              }

                              const stageFromPipeline =
                                  action.payload.pipelineTemplate.pipeline.stages.find(
                                      (stage) => stage.stageId === changedStage.toStageId
                                  );

                              if (stageFromPipeline === undefined) {
                                  return candidate;
                              }

                              return {
                                  ...candidate,

                                  stage: {
                                      ...stageFromPipeline,
                                      id: stageFromPipeline.stageId,
                                      name: stageFromPipeline.stageName,
                                  },
                              };
                          })
                        : state.candidates,
            };

        case CHECK_STAGES_FOR_TRANSFORM:
            return {
                ...state,

                pipelineChange: {
                    ...state.pipelineChange,
                    fromModal: action.payload.fromModal,
                },
            };

        case CHECK_STAGES_FOR_TRANSFORM_SUCCESS:
            return {
                ...state,

                pipelineChange: {
                    ...state.pipelineChange,
                    pipelineStagesToBeChangedForJob: action.payload.pipelineStagesToBeChangedForJob,
                    newPipelineTemplateStages: action.payload.newPipelineTemplateStages,
                    newPipelineTemplateId: action.payload.newPipelineTemplateId,
                },
            };

        case GET_CANDIDATE:
            return {
                ...state,
                candidateForDetail: undefined,
            };

        case GET_CANDIDATE_SUCCESS:
            return {
                ...state,
                candidateForDetail: action.payload.candidate,
            };

        case GET_CANDIDATE_FAILURE:
            return {
                ...state,
                candidateForDetail: {},
            };

        default:
            return CandidatesReducer.process(state, action);
    }
};

export const isFetchingSourceTag = (state = false, action) => {
    switch (action.type) {
        case CREATE_SOURCE_TAG:
        case DLETE_SOURCE_TAG:
            return true;

        case CREATE_SOURCE_TAG_SUCCESS:
        case CREATE_SOURCE_TAG_FAILURE:
        case DLETE_SOURCE_TAG_SUCCESS:
        case DLETE_SOURCE_TAG_FAILURE:
            return false;

        default:
            return state;
    }
};

export const isProcessingRecommendationTag = (state = false, action) => {
    switch (action.type) {
        case CREATE_RECOMMENDATION_TAG:
        case DELETE_RECOMMENDATION_TAG:
        case ENABLE_RECOMMENDATION_TAG:
            return true;

        case CREATE_RECOMMENDATION_TAG_SUCCESS:
        case CREATE_RECOMMENDATION_TAG_FAILURE:
        case DELETE_RECOMMENDATION_TAG_SUCCESS:
        case DELETE_RECOMMENDATION_TAG_FAILURE:
        case ENABLE_RECOMMENDATION_TAG_SUCCESS:
        case ENABLE_RECOMMENDATION_TAG_FAILURE:
            return false;

        default:
            return state;
    }
};

export const isFetchingJobModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_JOB_MODEL:
            return true;

        case RECEIVE_JOB_MODEL:
        case FAILURE_JOB_MODEL:
            return false;

        default:
            return state;
    }
};

export const isFetchingGetPositionData = (state = false, action) => {
    switch (action.type) {
        case GET_POSITION_DATA:
            return true;

        case GET_POSITION_SUCCESS:
        case GET_POSITION_FAILURE:
            return false;

        default:
            return state;
    }
};

export const isFetchingCandidatesForJob = (state = false, action) => {
    switch (action.type) {
        case LOAD_FULL_CANDIDATES_DATA_FOR_JOB:
            return true;

        case LOAD_FULL_CANDIDATES_DATA_FOR_JOB_SUCCESS:
        case LOAD_FULL_CANDIDATES_DATA_FOR_JOB_FAILURE:
            return false;

        default:
            return state;
    }
};

export const isFetchingPipelineCandidatesForJob = (state = false, action) => {
    switch (action.type) {
        case LOAD_PIPELINE_CANDIDATES_DATA_FOR_JOB:
            return true;

        case LOAD_PIPELINE_CANDIDATES_DATA_FOR_JOB_SUCCESS:
        case LOAD_PIPELINE_CANDIDATES_DATA_FOR_JOB_FAILURE:
            return false;

        default:
            return state;
    }
};

export const isUpdatingJobReactionForm = (state = false, action) => {
    switch (action.type) {
        case ENABLE_REACTION_FORM:
        case DISABLE_REACTION_FORM:
            return true;

        case ENABLE_REACTION_FORM_SUCCESS:
        case ENABLE_REACTION_FORM_FAILURE:
        case DISABLE_REACTION_FORM_FAILURE:
        case DISABLE_REACTION_FORM_SUCCESS:
            return false;

        default:
            return state;
    }
};

export const isSavingExternalUser = (state = false, action) => {
    switch (action.type) {
        case ADD_EXTERNAL_USER:
        case CHANGE_EXTERNAL_USER_ROLE:
            return true;

        case ADD_EXTERNAL_USER_SUCCESS:
        case ADD_EXTERNAL_USER_FAILURE:
        case CHANGE_EXTERNAL_USER_ROLE_SUCCESS:
        case CHANGE_EXTERNAL_USER_ROLE_FAILURE:
            return false;

        default:
            return state;
    }
};

export const isRemovingExternalUser = (state = false, action) => {
    switch (action.type) {
        case REMOVE_EXTERNAL_USER:
            return true;

        case REMOVE_EXTERNAL_USER_SUCCESS:
        case REMOVE_EXTERNAL_USER_FAILURE:
            return false;

        default:
            return state;
    }
};

export const isApplyingDefaultPipelineTemplateToJob = (state = false, action) => {
    switch (action.type) {
        case APPLY_DEFAULT_PIPELINE_TEMPLATE_TO_JOB:
            return true;

        case APPLY_DEFAULT_PIPELINE_TEMPLATE_TO_JOB_SUCCESS:
        case APPLY_DEFAULT_PIPELINE_TEMPLATE_TO_JOB_FAILURE:
            return false;

        default:
            return state;
    }
};

export const isApplyingPipelineTemplateToJob = (state = false, action) => {
    switch (action.type) {
        case APPLY_PIPELINE_TEMPLATE_TO_JOB:
            return true;

        case APPLY_PIPELINE_TEMPLATE_TO_JOB_SUCCESS:
        case APPLY_PIPELINE_TEMPLATE_TO_JOB_FAILURE:
            return false;

        default:
            return state;
    }
};

export const isCheckingStagesForTransform = (state = false, action) => {
    switch (action.type) {
        case CHECK_STAGES_FOR_TRANSFORM:
            return true;

        case CHECK_STAGES_FOR_TRANSFORM_SUCCESS:
        case CHECK_STAGES_FOR_TRANSFORM_FAILURE:
            return false;

        default:
            return state;
    }
};

export const isProcessingPipelineTemplateForJob = (state = false, action) => {
    switch (action.type) {
        case PROCESS_PIPELINE_TEMPLATE_FOR_JOB:
            return true;

        case PROCESS_PIPELINE_TEMPLATE_FOR_JOB_SUCCESS:
        case PROCESS_PIPELINE_TEMPLATE_FOR_JOB_FAILURE:
            return false;

        default:
            return state;
    }
};

export const isFetchingCandidateForDetail = (state = false, action) => {
    switch (action.type) {
        case GET_CANDIDATE:
            return true;
        case GET_CANDIDATE_SUCCESS:
        case GET_CANDIDATE_FAILURE:
            return false;
        default:
            return state;
    }
};

export const isSelectedCandidateIdInStage = (state = false, action) => {
    switch (action.type) {
        case SET_SELECTED_CANDIDATE_ID:
            return action.payload.candidateId;
        case NO_MODAL_CLOSE:
            return null;
        default:
            return state;
    }
};