import React from 'react';
import PropTypes from 'prop-types';
import TemplateAssistantNewTemplateHeader from './TemplateAssistantTemplateHeader';
import TemplateAssistantActionButton from './TemplateAssistantActionButton';
import Constants from '../../helpers/Constants';

class TemplateAssistantSettings extends React.Component {
    constructor(props) {
        super(props);

        this.saveChangesInTemplate = this.saveChangesInTemplate.bind(this);
        this.doNotSaveChangesInTemplate = this.doNotSaveChangesInTemplate.bind(this);
    }

    saveChangesInTemplate() {
        this.props.onSaveAsChangedTemplate(true);
    }

    doNotSaveChangesInTemplate() {
        this.props.onSaveAsChangedTemplate(false);
    }

    render() {
        return this.props.isNewTemplate === true ? (
            <TemplateAssistantNewTemplateHeader
                templateData={this.props.templateData}
                onUpdateTemplate={this.props.onUpdateTemplate}
                onHideNewTemplateHeader={() => this.props.onSaveAsNewTemplate(false)}
                isParentTemplateAssistantSettings={true}
                customMessageForTemplateChangesWillSaveAfterAction={
                    this.props.customMessageForTemplateChangesWillSaveAfterAction
                }
                errors={this.props.errors}
                templateType={this.props.templateType}
                settings={this.props.settings}
            />
        ) : (
            <TemplateAssistantActionButton
                isModal={this.props.isModal}
                templateData={this.props.templateData}
                strings={this.props.strings}
                isChangedTemplate={this.props.isChangedTemplate}
                onShowNewTemplateHeader={() => this.props.onSaveAsNewTemplate(true)}
                onDoNotSaveChangesInTemplate={this.doNotSaveChangesInTemplate}
                onSaveChangesInTemplate={this.saveChangesInTemplate}
                customMessageForTemplateChangesWillSaveAfterAction={
                    this.props.customMessageForTemplateChangesWillSaveAfterAction
                }
                allowedTemplateSavingActions={this.props.allowedTemplateSavingActions}
            />
        );
    }
}

TemplateAssistantSettings.propTypes = {
    templateData: PropTypes.shape({
        isPrivate: PropTypes.bool.isRequired,
        isTemplate: PropTypes.bool.isRequired,
        isEditable: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
    strings: PropTypes.shape({
        saveChangesInTemplate: PropTypes.string.isRequired,
        saveAsNewTemplate: PropTypes.string.isRequired,
        doNotSaveChangesInTemplate: PropTypes.string.isRequired,
    }).isRequired,
    isChangedTemplate: PropTypes.bool.isRequired,
    isNewTemplate: PropTypes.bool.isRequired,
    onUpdateTemplate: PropTypes.func.isRequired,
    onSaveAsChangedTemplate: PropTypes.func.isRequired,
    onSaveAsNewTemplate: PropTypes.func.isRequired,
    templateType: PropTypes.string.isRequired,
    errors: PropTypes.array.isRequired,
    settings: PropTypes.object,
    allowedTemplateSavingActions: PropTypes.array,
};

TemplateAssistantSettings.defaultProps = {
    errors: [],
    allowedTemplateSavingActions: Constants.TEMPLATE_ASSISTANT_ALL_SAVING_OPTIONS,
};

export default TemplateAssistantSettings;
