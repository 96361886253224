import { EventSourcePolyfill } from '../../js/eventsource';
import Browser from './Browser';
import { AnyParamsFunctionType, CommonObjectType } from '../../interfaces/CommonTypes';

const TOPIC_CHANGE_CANDIDATE: string = 'ws/mark/change/candidate/%candidateId%';
const TOPIC_CHANGE_JOB: string = 'ws/mark/change/job/%jobId%';
const TOPIC_EMAIL_RECEIVED: string = 'ws/mark/email/received/%userId%';

class WebSocketWatcher {
    static get TOPIC_CHANGE_CANDIDATE(): string {
        return TOPIC_CHANGE_CANDIDATE;
    }

    static get TOPIC_CHANGE_JOB(): string {
        return TOPIC_CHANGE_JOB;
    }

    static get TOPIC_EMAIL_RECEIVED(): string {
        return TOPIC_EMAIL_RECEIVED;
    }

    static subscribe(
        url: string,
        topic: string,
        callback: AnyParamsFunctionType
    ): EventSourcePolyfill | null {
        if (!url) {
            return null;
        }

        const urlObject: URL = new URL(url);

        urlObject.searchParams.append('topic', topic);

        const eventSource: EventSourcePolyfill = new EventSourcePolyfill(urlObject);

        eventSource.onmessage = (event: MessageEvent): void => {
            const parsedData: any = JSON.parse(event.data);

            callback(parsedData);
        };

        return eventSource;
    }

    static unsubscribe(eventSource: EventSourcePolyfill | null): void {
        if (eventSource !== undefined && eventSource !== null) {
            eventSource.close();
        }
    }

    static buildTopic(topic: string, data: CommonObjectType = {}): string {
        Object.keys(data).forEach(
            (dataProperty: string | number) =>
                (topic = topic.replace('%' + dataProperty + '%', data[dataProperty].toString()))
        );

        return topic;
    }

    static externalChange(data: { browserTabId?: string }): boolean {
        const browserTabId: string = Browser.getBrowserTabId() ?? '';
        const dataBrowserTabId: string = data.browserTabId ?? '';

        return dataBrowserTabId === '' || dataBrowserTabId !== browserTabId;
    }
}

export default WebSocketWatcher;
