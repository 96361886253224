import React from 'react';
import PropTypes from 'prop-types';
import MarkDialog from '../Modal/MarkDialog';
import { connect } from 'react-redux';
import { closeNotAllowedFeatureDialog } from '../../actions/DialogActions';
import Translations from '../../helpers/Translations';
import Url from '../../helpers/Url';

const NotAllowedFeatureDialog = (props) => {
    const onCancel = () => {
        props.closeNotAllowedFeatureDialog();

        if (
            props.notAllowedFeatureDialogOpen.hasOwnProperty('dialogProps') === true &&
            props.notAllowedFeatureDialogOpen.dialogProps.hasOwnProperty('onCloseCallback') === true
        ) {
            props.notAllowedFeatureDialogOpen.dialogProps.onCloseCallback();
        }
    };

    const { open, dialogProps } = props.notAllowedFeatureDialogOpen;

    return (
        open === true && (
            <MarkDialog
                closeOnDimmerClick={false}
                message={{
                    iconName: 'icon-upsell',
                    title: dialogProps.title,
                    infoLines: [
                        Translations.getStatic('upsellDialogueText') +
                            ' ' +
                            dialogProps.package.name,
                    ],
                }}
                actions={[
                    {
                        title: Translations.getStatic('buyNow'),
                        action: () => window.open(Url.build('packages'), '_blank'),
                        type: 'positive',
                    },
                    {
                        title: Translations.getStatic('cancel'),
                        action: onCancel,
                    },
                ]}
            />
        )
    );
};

const mapStateToProps = (state) => ({
    notAllowedFeatureDialogOpen: state.notAllowedFeatureDialogOpen,
    loginManagerModel: state.loginManagerModel,
});

const mapDispatchToProps = {
    closeNotAllowedFeatureDialog,
};

NotAllowedFeatureDialog.defaultProps = {
    notAllowedFeatureDialogOpen: {
        dialogProps: {
            title: '',
            package: {},
        },
    },
};

NotAllowedFeatureDialog.propTypes = {
    notAllowedFeatureDialogOpen: PropTypes.shape({
        dialogProps: PropTypes.shape({
            title: PropTypes.string.isRequired,
            package: PropTypes.object.isRequired,
        }),
    }),
};

export default connect(mapStateToProps, mapDispatchToProps)(NotAllowedFeatureDialog);
