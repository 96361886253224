import {
    REQUEST_CANDIDATE_MODEL,
    RECEIVE_CANDIDATE_MODEL,
    RECEIVE_CANDIDATE_EVENTS,
    REQUEST_CANDIDATE_HISTORY,
    RECEIVE_CANDIDATE_HISTORY,
    REQUEST_CANDIDATE_INBOX,
    RECEIVE_CANDIDATE_INBOX,
    CANDIDATE_ADD_TAG,
    CANDIDATE_ADD_TAG_SUCCESS,
    CANDIDATE_ADD_TAG_FAILURE,
    CANDIDATE_REMOVE_TAG,
    CANDIDATE_REMOVE_TAG_SUCCESS,
    CANDIDATE_REMOVE_TAG_FAILURE,
    CANDIDATE_SET_NOTE,
    CANDIDATE_SET_NOTE_SUCCESS,
    CANDIDATE_SET_NOTE_FAILURE,
    SET_CANDIDATE_AS_FAVORITE,
    UNSET_CANDIDATE_AS_FAVORITE_FAILURE,
    UNSET_CANDIDATE_AS_FAVORITE,
    SET_CANDIDATE_AS_FAVORITE_FAILURE,
    SET_CANDIDATE_INBOX_MESSAGE_AS_READ,
    SET_CANDIDATE_INBOX_MESSAGE_AS_READ_FAILURE,
} from '../actions/CandidateActions';
import {
    CHANGE_CANDIDATE_STAGE,
    CHANGE_CANDIDATE_STAGE_SUCCESS,
    CHANGE_CANDIDATE_STAGE_FAILURE,
} from '../actions/JobDetailActions';
import {
    SCHEDULE_CANDIDATE_EVENT_SUCCESS,
    DELETE_CANDIDATE_EVENT_SUCCESS,
    REFUSE_CANDIDATE_AND_SEND_EMAIL_SUCCESS,
    COPY_CANDIDATES_SUCCESS,
    SEND_EMAIL_SUCCESS,
    SAVE_CANDIDATE_GDPR_AGREEMENT_SUCCESS,
    REMOVE_CANDIDATES_FROM_TALENT_POOL_SUCCESS,
    MOVE_CANDIDATES_SUCCESS,
} from '../actions/ActionWithCandidateActions';
import { SAVE_CANDIDATE_SUCCESS } from '../actions/CreateCandidateActions';
import {
    UPLOAD_ATTACHMENTS_LIVE_SUCCESS,
    REMOVE_CANDIDATE_ATTACHMENT,
    REMOVE_CANDIDATE_ATTACHMENT_FAILURE,
} from '../actions/AttachmentAssistantActions';
import Constants from '../helpers/Constants';
import CandidatesHelper from '../helpers/components/CandidatesHelper';
import {
    NO_MODAL_SECOND_OPEN,
    NO_MODAL_OPEN,
    NO_MODAL_CLOSE,
    MODAL_SECOND_OPEN,
} from '../actions/ModalActions';
import CandidateHelper from '../helpers/components/CandidateHelper';
import { OPEN_CANDIDATE_CARD } from '../actions/CandidateCardActions';

const commonReducer = (state, action) => {
    switch (action.type) {
        case REQUEST_CANDIDATE_MODEL:
            return {};

        case RECEIVE_CANDIDATE_MODEL:
            return Object.assign({}, state, action.payload);

        case RECEIVE_CANDIDATE_EVENTS:
            return {
                ...state,
                events: action.payload.events,
            };

        case RECEIVE_CANDIDATE_HISTORY:
            return {
                ...state,
                history: action.payload.history,
            };

        case RECEIVE_CANDIDATE_INBOX:
            return {
                ...state,
                inbox: action.payload.inbox,
            };

        default:
            return state;
    }
};

const fullDetailReducer = (state, action) => {
    switch (action.type) {
        case SCHEDULE_CANDIDATE_EVENT_SUCCESS:
            const isEditEvent = state.events.some(
                (event) => event.id === action.meta.requestPayload.eventId
            );

            if (isEditEvent) {
                return {
                    ...state,

                    events: state.events.map((event) => {
                        if (event.id === action.meta.requestPayload.eventId) {
                            return action.payload.event;
                        }

                        return event;
                    }),
                };
            }

            return {
                ...state,
                events: state.events.concat([action.payload.event]),
            };

        case CHANGE_CANDIDATE_STAGE:
            const stage = state.settings.stages.find(
                (stage) => stage.stageId === action.payload.stageId
            );

            return {
                ...state,

                candidate: {
                    ...state.candidate,

                    stage: {
                        id: stage.stageId,
                        name: stage.stageId,
                        isDefault: stage.isDefault,
                    },

                    stateId: Constants.CANDIDATE_STATE_ACTIVE,
                },
            };

        case CHANGE_CANDIDATE_STAGE_SUCCESS:
        case CHANGE_CANDIDATE_STAGE_FAILURE:
            return {
                ...state,

                candidate: {
                    ...state.candidate,
                    stage: action.payload.stage,
                    stateId: Constants.CANDIDATE_STATE_ACTIVE,
                },
            };

        case DELETE_CANDIDATE_EVENT_SUCCESS:
            return {
                ...state,
                events:
                    state.hasOwnProperty('events') === true
                        ? state.events.filter(
                              (event) => event.id !== action.meta.requestPayload.eventId
                          )
                        : [],
            };

        case CANDIDATE_ADD_TAG_SUCCESS:
            const candidate = action.payload.candidates.find(
                (candidate) => candidate.id === state.candidate.id
            );
            const candidateHistory = action.payload.candidatesHistory.find(
                (candidate) => candidate.candidateId === state.candidate.id
            );

            return {
                ...state,

                candidate: {
                    ...state.candidate,
                    tags: candidate === undefined ? state.tags : candidate.tags,
                    history:
                        candidateHistory === undefined ? state.history : candidateHistory.history,
                },

                settings: {
                    ...state.settings,
                    tags: action.payload.tags,
                },
            };

        case CANDIDATE_REMOVE_TAG_SUCCESS:
            return {
                ...state,

                candidate: {
                    ...state.candidate,
                    tags: action.payload.candidate.tags,
                },
            };

        case CANDIDATE_SET_NOTE_SUCCESS:
            return {
                ...state,

                candidate: {
                    ...state.candidate,
                    ...action.payload.candidate,
                },
            };

        case REFUSE_CANDIDATE_AND_SEND_EMAIL_SUCCESS:
            return {
                ...state,

                candidate:
                    action.meta.requestPayload.candidateIds.includes(state.candidate.id) === true
                        ? {
                              ...state.candidate,
                              ...CandidateHelper.getCandidateDataAfterRefuse(
                                  state.candidate.id,
                                  action.meta.requestPayload,
                                  action.payload
                              ),
                          }
                        : state.candidate,
            };

        case SAVE_CANDIDATE_GDPR_AGREEMENT_SUCCESS:
            return {
                ...state,

                candidate: {
                    ...state.candidate,
                    gdprAgreement: action.payload.candidateGdprAgreement,
                    attachmentsCount: action.payload.candidateAttachmentsCount,
                },
            };

        case SAVE_CANDIDATE_SUCCESS:
            return {
                ...state,
                candidate:
                    action.meta.requestPayload.candidateId > 0
                        ? action.payload.candidate
                        : state.candidate,
            };

        case UPLOAD_ATTACHMENTS_LIVE_SUCCESS:
            return {
                ...state,
                candidate: CandidatesHelper.updateCandidateData(state.candidate, {
                    attachmentsCount: action.payload.candidateAttachments.length,
                }),
            };

        case REMOVE_CANDIDATE_ATTACHMENT:
            return {
                ...state,
                candidate: CandidatesHelper.updateCandidateData(state.candidate, {
                    attachmentsCount: state.candidate.attachmentsCount - 1,
                }),
            };

        case REMOVE_CANDIDATE_ATTACHMENT_FAILURE:
            return {
                ...state,
                candidate: CandidatesHelper.updateCandidateData(state.candidate, {
                    attachmentsCount: state.candidate.attachmentsCount + 1,
                }),
            };

        case COPY_CANDIDATES_SUCCESS:
            return {
                ...state,

                candidate: {
                    ...state.candidate,

                    stateId:
                        action.meta.requestPayload.copyParams.removeCandidate === true &&
                        action.meta.requestPayload.candidateIds.includes(state.candidate.id) ===
                            true
                            ? Constants.CANDIDATE_STATE_REFUSED
                            : state.candidate.stateId,

                    wasSentToColleague:
                        action.meta.requestPayload.candidateIds.includes(state.candidate.id) ===
                            true &&
                        action.payload.candidatesSentToColleague.find(
                            (candidateSentToColleague) =>
                                candidateSentToColleague.candidateId === state.candidate.id
                        ) !== undefined
                            ? action.payload.candidatesSentToColleague.find(
                                  (candidateSentToColleague) =>
                                      candidateSentToColleague.candidateId === state.candidate.id
                              ).wasSentToColleague
                            : state.candidate.wasSentToColleague,
                },
            };

        case SEND_EMAIL_SUCCESS:
            return {
                ...state,

                candidate: CandidatesHelper.updateCandidateData(state.candidate, {
                    wasSentToColleague:
                        action.meta.requestPayload.candidateIds.includes(state.candidate.id) ===
                            true &&
                        action.payload.candidatesSentToColleague.find(
                            (candidateSentToColleague) =>
                                candidateSentToColleague.candidateId === state.candidate.id
                        ) !== undefined
                            ? action.payload.candidatesSentToColleague.find(
                                  (candidateSentToColleague) =>
                                      candidateSentToColleague.candidateId === state.candidate.id
                              ).wasSentToColleague
                            : state.candidate.wasSentToColleague,
                }),
            };

        case SET_CANDIDATE_AS_FAVORITE:
        case UNSET_CANDIDATE_AS_FAVORITE:
        case SET_CANDIDATE_AS_FAVORITE_FAILURE:
        case UNSET_CANDIDATE_AS_FAVORITE_FAILURE:
            return {
                ...state,

                candidate: {
                    ...state.candidate,

                    isFavorite:
                        action.type === SET_CANDIDATE_AS_FAVORITE ||
                        action.type === UNSET_CANDIDATE_AS_FAVORITE_FAILURE,
                },
            };

        case REMOVE_CANDIDATES_FROM_TALENT_POOL_SUCCESS:
            return {
                ...state,

                candidate: {
                    ...state.candidate,

                    stateId: Constants.CANDIDATE_STATE_DELETED,
                },
            };

        case SET_CANDIDATE_INBOX_MESSAGE_AS_READ:
            return {
                ...state,

                inbox: {
                    ...state.inbox,

                    messages: state.inbox.messages.map((message) =>
                        message.id === action.payload.messageId
                            ? {
                                  ...message,
                                  isUnread: false,
                              }
                            : message
                    ),
                },
            };

        case SET_CANDIDATE_INBOX_MESSAGE_AS_READ_FAILURE:
            return {
                ...state,

                inbox: {
                    ...state.inbox,

                    messages: state.inbox.messages.map((message) =>
                        message.id === action.meta.requestPayload.messageId
                            ? {
                                  ...message,
                                  isUnread: true,
                              }
                            : message
                    ),
                },
            };

        default:
            return state;
    }
};

const generalCandidateModelReducer = (state = {}, action, isFull = true) => {
    state = commonReducer(state, action);

    if (isFull === true) {
        state = fullDetailReducer(state, action);
        state = CandidateHelper.refreshHistory(state, action);
        state = CandidateHelper.refreshInbox(state, action);
    }

    return state;
};

const canUpdateDetailOfType = (action, compareId) => {
    return (
        compareId !== null &&
        ((action.hasOwnProperty('payload') === true &&
            action.payload.hasOwnProperty('candidateId') === true &&
            action.payload.candidateId === compareId) ||
            (action.hasOwnProperty('meta') === true &&
                action.meta.hasOwnProperty('requestPayload') === true &&
                action.meta.requestPayload.hasOwnProperty('candidateId') === true &&
                action.meta.requestPayload.candidateId === compareId) ||
            (action.hasOwnProperty('payload') === true &&
                action.payload.hasOwnProperty('candidateIds') === true &&
                action.payload.candidateIds.includes(compareId) === true) ||
            (action.hasOwnProperty('meta') === true &&
                action.meta.hasOwnProperty('requestPayload') === true &&
                action.meta.requestPayload.hasOwnProperty('candidateIds') === true &&
                action.meta.requestPayload.candidateIds.includes(compareId) === true))
    );
};

const defaultState = {
    fullDetailId: null,
    anonymizedDetailId: null,
    duplicatedDetailId: null,
    candidateCardDetailId: null,
    fullDetail: {},
    anonymizedDetail: {},
    duplicatedDetail: {},
    candidateCardDetail: {},
};

export const candidateModel = (state = defaultState, action) => {
    switch (action.type) {
        case NO_MODAL_OPEN:
            return {
                ...state,

                fullDetailId:
                    action.payload.modalType === 'DetailCandidateNoModal'
                        ? action.payload.modalProps.candidateId
                        : state.fullDetailId,

                anonymizedDetailId:
                    action.payload.modalType === 'DetailAnonymizedCandidateNoModal'
                        ? action.payload.modalProps.candidateId
                        : state.anonymizedDetailId,

                anonymizedDetail:
                    action.payload.modalType === 'DetailAnonymizedCandidateNoModal'
                        ? {}
                        : state.anonymizedDetail,
            };

        case NO_MODAL_SECOND_OPEN:
        case MODAL_SECOND_OPEN:
            if (
                action.payload.modalProps === undefined ||
                action.payload.modalProps.candidateId === undefined
            ) {
                return state;
            }

            return {
                ...state,
                duplicatedDetailId: action.payload.modalProps.candidateId,
            };

        case OPEN_CANDIDATE_CARD:
            return {
                ...state,
                candidateCardDetailId: action.payload.candidateId,
            };

        case NO_MODAL_CLOSE:
            return {
                ...state,
                fullDetail: {},
                fullDetailId: null,
            };

        default:
            return {
                ...state,

                fullDetail:
                    canUpdateDetailOfType(action, state.fullDetailId) === true
                        ? generalCandidateModelReducer(state.fullDetail, action)
                        : state.fullDetail,

                anonymizedDetail:
                    canUpdateDetailOfType(action, state.anonymizedDetailId) === true
                        ? generalCandidateModelReducer(state.anonymizedDetail, action, false)
                        : state.anonymizedDetail,

                duplicatedDetail:
                    canUpdateDetailOfType(action, state.duplicatedDetailId) === true
                        ? generalCandidateModelReducer(state.duplicatedDetail, action, false)
                        : state.duplicatedDetail,

                candidateCardDetail:
                    canUpdateDetailOfType(action, state.candidateCardDetailId) === true
                        ? generalCandidateModelReducer(state.candidateCardDetail, action, false)
                        : state.candidateCardDetail,
            };
    }
};

export const isFetchingCandidateNote = (state = false, action) => {
    switch (action.type) {
        case CANDIDATE_SET_NOTE:
            return true;

        case CANDIDATE_SET_NOTE_SUCCESS:
        case CANDIDATE_SET_NOTE_FAILURE:
            return false;

        default:
            return state;
    }
};

export const isFetchingCandidateHistory = (state = false, action) => {
    switch (action.type) {
        case REQUEST_CANDIDATE_HISTORY:
            return true;

        case RECEIVE_CANDIDATE_HISTORY:
            return false;

        default:
            return state;
    }
};

export const isFetchingCandidateInbox = (state = false, action) => {
    switch (action.type) {
        case REQUEST_CANDIDATE_INBOX:
            return true;

        case RECEIVE_CANDIDATE_INBOX:
            return false;

        default:
            return state;
    }
};

export const isFetchingCandidateTag = (state = false, action) => {
    switch (action.type) {
        case CANDIDATE_ADD_TAG:
        case CANDIDATE_REMOVE_TAG:
            return true;

        case CANDIDATE_ADD_TAG_SUCCESS:
        case CANDIDATE_ADD_TAG_FAILURE:
        case CANDIDATE_REMOVE_TAG_SUCCESS:
        case CANDIDATE_REMOVE_TAG_FAILURE:
            return false;

        default:
            return state;
    }
};
