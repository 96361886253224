export const handleZoomIn = (cropperRef) => {
    cropperRef.current?.zoom(0.1);
};
export const handleZoomOut = (cropperRef) => {
    cropperRef.current?.zoom(-0.1);
};
export const handleRotateLeft = (cropperRef) => {
    cropperRef.current?.rotate(-90);
};
export const handleRotateRight = (cropperRef) => {
    cropperRef.current?.rotate(90);
};
export const handleMoveLeft = (cropperRef) => {
    cropperRef.current?.move(10, 0);
};
export const handleMoveRight = (cropperRef) => {
    cropperRef.current?.move(-10, 0);
};
export const handleMoveUp = (cropperRef) => {
    cropperRef.current?.move(0, 10);
};
export const handleMoveDown = (cropperRef) => {
    cropperRef.current?.move(0, -10);
};
export const updateCroppedImage = (cropperRef, setCroppedImage, onCropChange, imgFormat) => {
    const cropper = cropperRef.current;
    if (cropper) {
        const format = `image/${imgFormat || "jpeg"}`;
        const croppedCanvas = cropper.getCroppedCanvas({
            fillColor: '#fff',
        });
        if (croppedCanvas) {
            const croppedDataUrl = croppedCanvas.toDataURL(format);
            const imgExtension = imgFormat?.toLowerCase();
            setCroppedImage(croppedDataUrl);
            const cropData = cropper.getData();
            onCropChange(croppedDataUrl, imgExtension, {
                x: Math.round(cropData.x),
                y: Math.round(cropData.y),
                width: Math.round(cropData.width),
                height: Math.round(cropData.height),
                rotate: Math.round(cropData.rotate),
                scaleX: Math.round(cropData.scaleX),
                scaleY: Math.round(cropData.scaleY),
            });
        }
    }
};
export const handleCropDebounced = (debounceTimeout, cropperRef, setCroppedImage, onCropChange, imgFormat) => {
    if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
        updateCroppedImage(cropperRef, setCroppedImage, onCropChange, imgFormat);
    }, 100);
};
