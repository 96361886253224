import { ENABLE_PAGE_TRANSITION, DISABLE_PAGE_TRANSITION } from '../actions/TransitionsPageActions';

export const allowTransition = (state = true, action) => {
    switch (action.type) {
        case ENABLE_PAGE_TRANSITION:
            return true;
        case DISABLE_PAGE_TRANSITION:
            return false;
        default:
            return state;
    }
};
