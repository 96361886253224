import Form from '../Form';
import Translations from '../Translations';

export default class JobValidatorHelper {
    constructor(job) {
        this.job = job;
    }

    checkJob() {
        const fieldsToCheck = [
            {
                fieldName: 'jobTitle',
                fieldType: 'text',
                fieldValue: this.job.jobTitle,
            },
            {
                fieldName: 'jobLanguage',
                fieldType: 'text',
                fieldValue: this.job.jobLanguage,
            },
            {
                fieldName: 'user',
                fieldType: 'numeric',
                fieldValue: this.job.user.id,
            },
            {
                fieldName: 'validTill',
                fieldType: 'text',
                fieldValue: this.job.validTill,
            },
            {
                fieldName: 'jobPlace',
                fieldType: 'text',
                fieldValue: this.job.jobPlace,
            },
            {
                fieldName: 'jobDescription',
                fieldType: 'text',
                fieldValue: this.job.jobDescription,
            },
            {
                fieldName: 'jobSalary',
                fieldType: 'text',
                fieldValue: this.job.jobSalary,
            },
        ];

        let errors = Form.multiCheck(fieldsToCheck);

        if (errors.length > 0) {
            return errors;
        }

        return [];
    }
}
