import React from 'react';
import { Box, styled } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { getColor } from '@profesia/adamui/styles/colors';
import CandidateAvatar from '../../../../CandidateAvatar';
import CandidateDetailOpenHandler from '../../../../CandidateDetailOpenHandler';
import DateFormatted from '../../../../../helpers/DateFormatted';
import Translations from '../../../../../helpers/Translations';
import BadgeNewCandidate from '../BadgeNewCandidate';
import { FullCandidate } from './StageCandidatesModal';

interface CandidateRowProps {
    candidate: FullCandidate;
    isSelected: boolean;
    onClick: () => void;
}

const CandidateRow: React.FC<CandidateRowProps> = ({ candidate, isSelected, onClick }) => (
    <StyledCandidateDetailOpenHandler
        key={candidate.id}
        candidate={candidate}
        onClick={onClick}
        preventNavigation={true}
        isSelected={isSelected}
    >
        <StyledTableCell>
            <AvatarWrapper>
                <CandidateAvatar
                    profilePhoto={candidate.profilePhoto}
                    firstname={candidate.firstname}
                    surname={candidate.surname}
                />
                <NameContainer>
                    <CandidateName isNew={candidate?.isClicked}>
                        {candidate.candidateName}
                    </CandidateName>
                    {!candidate?.isClicked && (
                        <BadgeNewCandidate isClicked={candidate.isClicked} variant="stage" />
                    )}
                </NameContainer>
            </AvatarWrapper>
        </StyledTableCell>
        <SpacerCell />
        <DuplicityCell>
            {candidate?.hasDuplicities && (
                <span className="tag tag-primary">{Translations.getStatic('duplicity')}</span>
            )}
        </DuplicityCell>
        <DateCell>
            <DateText>{DateFormatted.getDate(candidate.addDate)}</DateText>
        </DateCell>
    </StyledCandidateDetailOpenHandler>
);

const StyledCandidateDetailOpenHandler = styled(CandidateDetailOpenHandler, {
    shouldForwardProp: (prop) => !['isSelected'].includes(prop as string),
})<{ isSelected?: boolean }>(({ theme: { palette }, isSelected }) => ({
    height: '48px',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    backgroundColor: isSelected ? getColor(palette.neutralLight1) : 'transparent',
    display: 'table-row',

    '&:hover': {
        backgroundColor: isSelected ? getColor(palette.neutralLight1) : getColor(palette.black6),
    },

    '& td': {
        verticalAlign: 'middle',
    },

    '&:last-child td': {
        borderBottom: 'none',
    },
}));

const CandidateName = styled('span')<{ isNew: boolean }>(({ theme: { typography }, isNew }) => ({
    fontWeight: isNew ? typography.fontWeightRegular : typography.fontWeightBold,
}));

const StyledTableCell = styled(TableCell)({
    padding: '0 0 0 12px',
});

const SpacerCell = styled(TableCell)({
    width: '100%',
    minWidth: 8,
});

const DuplicityCell = styled(TableCell)({
    padding: '0 32px 0 0',
    textAlign: 'right',
});

const DateCell = styled(TableCell)({
    padding: '0 12px 0 0',
    textAlign: 'right',
});

const AvatarWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
});

const NameContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
});

const DateText = styled('span')(({ theme: { typography, palette } }) => ({
    display: 'block',
    textAlign: 'right',
    fontSize: 12,
    fontWeight: typography.fontWeightMedium,
    color: getColor(palette.black3),
}));

export default CandidateRow;
