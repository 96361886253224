import {
    REQUEST_COMPANY_EMAIL_TEMPLATES_MODEL,
    RECEIVE_COMPANY_EMAIL_TEMPLATES_MODEL,
    COMPANY_EMAIL_TEMPLATE_ACTIVATE,
    COMPANY_EMAIL_TEMPLATE_ACTIVATE_SUCCESS,
    COMPANY_EMAIL_TEMPLATE_ACTIVATE_FAILURE,
    COMPANY_EMAIL_TEMPLATE_DEACTIVATE,
    COMPANY_EMAIL_TEMPLATE_DEACTIVATE_SUCCESS,
    COMPANY_EMAIL_TEMPLATE_DEACTIVATE_FAILURE,
    COMPANY_EMAIL_TEMPLATE_UPDATE,
    COMPANY_EMAIL_TEMPLATE_UPDATE_SUCCESS,
    COMPANY_EMAIL_TEMPLATE_UPDATE_FAILURE,
    COMPANY_EMAIL_TEMPLATE_CREATE,
    COMPANY_EMAIL_TEMPLATE_CREATE_SUCCESS,
    COMPANY_EMAIL_TEMPLATE_CREATE_FAILURE,
    COMPANY_EMAIL_TEMPLATE_ADD,
    COMPANY_EMAIL_TEMPLATE_ADD_CANCEL,
} from '../actions/CompanyEmailTemplatesActions';
import Arrays from '../helpers/Arrays';

export const companyEmailTemplatesModel = (state = {}, action) => {
    switch (action.type) {
        case RECEIVE_COMPANY_EMAIL_TEMPLATES_MODEL:
            return {
                ...state,
                ...action.payload,
            };

        case COMPANY_EMAIL_TEMPLATE_ACTIVATE:
            return {
                ...state,

                companyEmailTemplates: state.companyEmailTemplates.map((companyEmailTemplate) => ({
                    ...companyEmailTemplate,
                    isActive:
                        companyEmailTemplate.id === action.payload.companyEmailTemplateId
                            ? true
                            : companyEmailTemplate.isActive,
                })),
            };

        case COMPANY_EMAIL_TEMPLATE_ACTIVATE_FAILURE:
            return {
                ...state,

                companyEmailTemplates: state.companyEmailTemplates.map((companyEmailTemplate) => ({
                    ...companyEmailTemplate,
                    isActive:
                        companyEmailTemplate.id ===
                        action.meta.requestPayload.companyEmailTemplateId
                            ? false
                            : companyEmailTemplate.isActive,
                })),
            };

        case COMPANY_EMAIL_TEMPLATE_DEACTIVATE:
            return {
                ...state,

                companyEmailTemplates: state.companyEmailTemplates.map((companyEmailTemplate) => ({
                    ...companyEmailTemplate,
                    isActive:
                        companyEmailTemplate.id === action.payload.companyEmailTemplateId
                            ? false
                            : companyEmailTemplate.isActive,
                })),
            };

        case COMPANY_EMAIL_TEMPLATE_DEACTIVATE_FAILURE:
            return {
                ...state,

                companyEmailTemplates: state.companyEmailTemplates.map((companyEmailTemplate) => ({
                    ...companyEmailTemplate,
                    isActive:
                        companyEmailTemplate.id ===
                        action.meta.requestPayload.companyEmailTemplateId
                            ? true
                            : companyEmailTemplate.isActive,
                })),
            };

        case COMPANY_EMAIL_TEMPLATE_ACTIVATE_SUCCESS:
        case COMPANY_EMAIL_TEMPLATE_DEACTIVATE_SUCCESS:
        case COMPANY_EMAIL_TEMPLATE_UPDATE_SUCCESS:
        case COMPANY_EMAIL_TEMPLATE_CREATE_SUCCESS:
            return {
                ...state,

                companyEmailTemplates: state.companyEmailTemplates.map((companyEmailTemplate) =>
                    companyEmailTemplate.id === action.meta.requestPayload.companyEmailTemplateId
                        ? action.payload.companyEmailTemplate
                        : companyEmailTemplate
                ),
            };

        case COMPANY_EMAIL_TEMPLATE_ADD:
            return {
                ...state,

                companyEmailTemplates: Arrays.prepend(
                    {
                        id: 'new',
                        companyId: '',
                        templateContent: '',
                        logo: '',
                        logoWidth: 660,
                        logoHeight: 0,
                        isActive: false,
                    },
                    state.companyEmailTemplates
                ),
            };

        case COMPANY_EMAIL_TEMPLATE_ADD_CANCEL:
            return {
                ...state,
                companyEmailTemplates: state.companyEmailTemplates.filter(
                    (companyEmailTemplate) => companyEmailTemplate.id !== 'new'
                ),
            };

        default:
            return state;
    }
};

export const isFetchingCompanyEmailTemplatesModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_COMPANY_EMAIL_TEMPLATES_MODEL:
            return true;

        case RECEIVE_COMPANY_EMAIL_TEMPLATES_MODEL:
            return false;

        default:
            return state;
    }
};
