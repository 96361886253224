import React from 'react';
import { SkeletonRectangular } from '@profesia/adamui/components/common/skeleton';
import { Stack } from '@mui/material';
import JobPipelineColumnSkeleton from './JobPipelineColumnSkeleton';

const JobPipelineSkeleton = () => (
    <div className="list">
        <div className="list__controls_extended">
            <Stack
                flex={1}
                pt={1}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <SkeletonRectangular width={244} height={18} borderRadius={26} />

                <Stack spacing={2} direction="row" justifyContent="right">
                    <SkeletonRectangular width={120} height={48} borderRadius={4} />
                    <SkeletonRectangular width={120} height={48} borderRadius={4} />
                </Stack>
            </Stack>
        </div>

        <div className="scrolling-wrapper">
            <div className="list__pipeline">
                <div className="wrapper clearfix">
                    <JobPipelineColumnSkeleton itemsCount={5} />
                    <JobPipelineColumnSkeleton itemsCount={3} />
                    <JobPipelineColumnSkeleton itemsCount={4} />
                    <JobPipelineColumnSkeleton itemsCount={2} />
                </div>
            </div>
        </div>
    </div>
);

export default JobPipelineSkeleton;
