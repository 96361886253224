import React from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import Button from './Button';
import Icon from './Icon';
import Url from '../helpers/Url';
import WithRouterProp from '../helpers/WithRouterProp';
import Translations from '../helpers/Translations';
import UserRights from '../helpers/UserRights';
import User from '../helpers/User';
import Skeletons from '../helpers/Skeletons';
import loginManagerModelSkeleton from '../skeletons/models/loginManagerModelSkeleton';

const HeaderLogin = ({ loginManagerModel, fixed, navigate }) => {
    if (Url.getUrlParameter('action') === 'login') {
        return null;
    }

    if (Skeletons.isSkeleton(loginManagerModel)) {
        return null;
    }

    if (User.isLoggedIn(loginManagerModel) === true) {
        const userName = User.getUserName(loginManagerModel);

        return (
            <Dropdown
                className={'icon'}
                as={'span'}
                text={userName}
                icon={<Icon className="icon-nav-menu" size="small" />}
            >
                <Dropdown.Menu direction={'left'} className="extended-list">
                    {UserRights.isForbiddenToEnterMark(loginManagerModel) === true ? null : (
                        <Dropdown.Item onClick={() => navigate(Url.build('dashboard'))}>
                            <span className="txt-bold">{Translations.getStatic('goToMark')}</span>
                        </Dropdown.Item>
                    )}

                    <Dropdown.Item onClick={() => window.open(location.origin, '_blank')}>
                        <span className="txt-bold">{Translations.getStatic('profesia')}</span>
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => navigate(Url.build('logout'))}>
                        <span className="txt-bold">{Translations.getStatic('logoutTitle')}</span>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    return (
        <Button
            className="btn btn-white"
            primary={fixed}
            onClick={() => Url.redirectTo(Url.buildLoginLink())}
            style={{ marginLeft: '0.5em' }}
        >
            {loginManagerModel.loginText}
        </Button>
    );
};

const mapStateToProps = (state) => ({
    loginManagerModel: Skeletons.getData(state.loginManagerModel, loginManagerModelSkeleton),
});

export default connect(mapStateToProps, null)(WithRouterProp(HeaderLogin));
