export const SIDEBAR_OPEN = 'SIDEBAR_OPEN';
export const SIDEBAR_RESET = 'SIDEBAR_RESET';
export const SIDEBAR_CLOSE = 'SIDEBAR_CLOSE';

export const openSidebarEditPanel = (sidebarType, sidebarProps) => {
    return {
        type: SIDEBAR_OPEN,
        payload: {
            sidebarType,
            sidebarProps,
        },
    };
};

export const resetSidebarEditPanel = (sidebarType, sidebarProps) => {
    return {
        type: SIDEBAR_RESET,
        payload: {
            sidebarType,
            sidebarProps,
        },
    };
};

export const closeSidebarEditPanel = (sidebarType, sidebarProps) => {
    return {
        type: SIDEBAR_CLOSE,
        payload: {
            sidebarType,
            sidebarProps,
        },
    };
};
