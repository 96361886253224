import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingComponent from '../LoadingComponent';
import {
    createRecommendationTag,
    deleteRecommendationTag,
    enableRecommendationTag,
} from '../../actions/JobDetailActions';
import { closeModal } from '../../actions/ModalActions';
import { InputField } from '@profesia/adamui/components/common/input';
import Form from '../Form';
import Button from '../Button';
import Message from '../Message';
import Grid from '../Grid';
import Item from '../Item';
import Image from '../Image';
import Divider from '../Divider';
import PropTypes from 'prop-types';
import RadioToggle from '../RadioToggle';
import MarkModal from '../Modal/MarkModal';
import Translations from '../../helpers/Translations';
import Strings from '../../helpers/Strings';
import linkImage from '../../../img/icons/link.svg';
import RecommendationTagValidatorHelper from '../../helpers/components/RecommendationTagValidatorHelper';
import Url from '../../helpers/Url';

class JobCandidatesSourcesRecommendationLinksModal extends Component {
    state = {
        name: '',
        errors: [],
    };

    handleRecommendationTagDelete = (recommendationTagId) => {
        const formData = new FormData();

        formData.append('recommendationTagId', recommendationTagId);
        formData.append('jobId', this.props.jobId);

        this.props.deleteRecommendationTag(formData);
    };

    handleRecommendationTagEnable = (recommendationTagId) => {
        const formData = new FormData();

        formData.append('recommendationTagId', recommendationTagId);
        formData.append('jobId', this.props.jobId);

        this.props.enableRecommendationTag(formData);
    };

    handleChangeInputName = (e) => this.setState({ [e.target.name]: e.target.value });

    handleSubmit = () => {
        const { name } = this.state;
        const { settings } = this.props;
        const recommendationTagValidator = new RecommendationTagValidatorHelper(
            Strings.removeDiacritics(name.trim().toLowerCase())
        );

        try {
            recommendationTagValidator.checkRecommendationTag(
                settings.recommendationTags.map((recommendationTag) =>
                    Strings.removeDiacritics(recommendationTag.tagName.toLowerCase())
                )
            );

            this.setState({
                errors: [],
            });

            const formData = new FormData();

            formData.append('recommendationTagName', name.trim());
            formData.append('jobId', this.props.jobId);

            this.props.createRecommendationTag(formData);
        } catch (errors) {
            this.setState({
                errors,
            });
        }
    };

    handleSwitchableRecommendationTag = (recommendationTag) => {
        if (recommendationTag.isVisible === true) {
            this.handleRecommendationTagDelete(recommendationTag.id);
        } else if (recommendationTag.isVisible === false) {
            this.handleRecommendationTagEnable(recommendationTag.id);
        }
    };

    render() {
        const { settings, isOpen, isProcessingRecommendationTag } = this.props;
        const { name, errors } = this.state;

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={this.props.closeModal}
                title={Translations.getStatic('recommendationForm')}
                actionButton={{ visible: false }}
                content={
                    isProcessingRecommendationTag === true ? (
                        <LoadingComponent text={Translations.getStatic('saving')} />
                    ) : (
                        <>
                            <Form onSubmit={this.handleSubmit}>
                                <Grid>
                                    <Grid.Row className="from-group__row">
                                        <Grid.Column width={12} verticalAlign="bottom">
                                            <Form.Field>
                                                <InputField
                                                    maxLength={128}
                                                    error={errors.includes('recommendationTag')}
                                                    label={Translations.getStatic(
                                                        'addRecommendationLink'
                                                    )}
                                                    name="name"
                                                    onChange={this.handleChangeInputName}
                                                    placeholder={Translations.getStatic(
                                                        'fillRecommendationName'
                                                    )}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column width={4} verticalAlign="bottom">
                                            <Form.Field>
                                                <Button
                                                    disabled={name.trim().length === 0}
                                                    content={Translations.getStatic('add')}
                                                    className="secondary-button mini"
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row className="from-group__row">
                                        <Grid.Column width={16} verticalAlign="bottom">
                                            <Message
                                                error
                                                icon={'exclamation'}
                                                visible={errors.includes('recommendationTagUnique')}
                                            >
                                                <Message.Content>
                                                    {Translations.getStatic(
                                                        'notUniqueRecommendationTag'
                                                    )}
                                                </Message.Content>
                                            </Message>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Form>
                            <Divider />
                            <Grid className="padding-top_none">
                                <Grid.Row>
                                    <Grid.Column>
                                        <div className="ui items list attachment-list unstackable">
                                            {settings.recommendationTags.map(
                                                (recommendationTag) => {
                                                    const staticRecommendationItems = [
                                                        Translations.getStatic(
                                                            'internalRecommendation'
                                                        ),
                                                        Translations.getStatic('recruitmentAgency'),
                                                    ];

                                                    if (
                                                        staticRecommendationItems.includes(
                                                            recommendationTag.tagName
                                                        )
                                                    ) {
                                                        return (
                                                            <Item
                                                                key={recommendationTag.id}
                                                                className="attachment__box"
                                                            >
                                                                <Image
                                                                    size="large"
                                                                    src={linkImage}
                                                                />
                                                                <Grid
                                                                    verticalAlign="middle"
                                                                    container
                                                                    className={
                                                                        this.props.displaySize
                                                                            .isMobile === true
                                                                            ? 'overflow-hidden'
                                                                            : ''
                                                                    }
                                                                >
                                                                    <Grid.Row>
                                                                        <Grid.Column width={11}>
                                                                            <Item.Header
                                                                                as="h4"
                                                                                className="ellipsis"
                                                                            >
                                                                                {
                                                                                    recommendationTag.tagName
                                                                                }
                                                                            </Item.Header>
                                                                            <Item.Meta className="ellipsis">
                                                                                {Url.getFullUrl(
                                                                                    recommendationTag.recommendationLink
                                                                                )}
                                                                            </Item.Meta>
                                                                        </Grid.Column>
                                                                        <Grid.Column
                                                                            width={5}
                                                                            textAlign="right"
                                                                        >
                                                                            <RadioToggle
                                                                                label={
                                                                                    recommendationTag.isVisible ===
                                                                                    true
                                                                                        ? Translations.getStatic(
                                                                                              'turnedOn'
                                                                                          )
                                                                                        : Translations.getStatic(
                                                                                              'turnedOff'
                                                                                          )
                                                                                }
                                                                                checked={
                                                                                    recommendationTag.isVisible ===
                                                                                    true
                                                                                }
                                                                                loading={
                                                                                    isProcessingRecommendationTag
                                                                                }
                                                                                onChange={() =>
                                                                                    this.handleSwitchableRecommendationTag(
                                                                                        recommendationTag
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                </Grid>
                                                            </Item>
                                                        );
                                                    } else {
                                                        return (
                                                            <Item
                                                                key={recommendationTag.id}
                                                                className="attachment__box"
                                                            >
                                                                <Image
                                                                    size="large"
                                                                    src={linkImage}
                                                                />
                                                                <Grid
                                                                    verticalAlign="middle"
                                                                    container
                                                                    className={
                                                                        this.props.displaySize
                                                                            .isMobile === true
                                                                            ? 'overflow-hidden'
                                                                            : ''
                                                                    }
                                                                >
                                                                    <Grid.Row>
                                                                        <Grid.Column width={11}>
                                                                            <Item.Header
                                                                                as="h4"
                                                                                className="ellipsis"
                                                                            >
                                                                                {
                                                                                    recommendationTag.tagName
                                                                                }
                                                                            </Item.Header>
                                                                            <Item.Meta className="ellipsis">
                                                                                {Url.getFullUrl(
                                                                                    recommendationTag.recommendationLink
                                                                                )}
                                                                            </Item.Meta>
                                                                        </Grid.Column>
                                                                        <Grid.Column
                                                                            width={5}
                                                                            textAlign="right"
                                                                        >
                                                                            <a
                                                                                className="link secondary-link"
                                                                                onClick={() =>
                                                                                    this.handleRecommendationTagDelete(
                                                                                        recommendationTag.id
                                                                                    )
                                                                                }
                                                                            >
                                                                                {Translations.getStatic(
                                                                                    'delete'
                                                                                )}
                                                                            </a>
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                </Grid>
                                                            </Item>
                                                        );
                                                    }
                                                }
                                            )}
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </>
                    )
                }
            />
        );
    }
}

const mapStateToProps = (state) => ({
    isOpen: state.modalLogic.isOpen,
    settings: state.jobModel.settings,
    jobId: state.jobModel.job.id,
    isProcessingRecommendationTag: state.isProcessingRecommendationTag,
    displaySize: state.displaySize,
});

const mapDispatchToProps = {
    closeModal,
    createRecommendationTag,
    deleteRecommendationTag,
    enableRecommendationTag,
};

JobCandidatesSourcesRecommendationLinksModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isProcessingRecommendationTag: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    createRecommendationTag: PropTypes.func.isRequired,
    deleteRecommendationTag: PropTypes.func.isRequired,
    settings: PropTypes.shape({
        recommendationTags: PropTypes.array.isRequired,
    }),
    jobId: PropTypes.number.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JobCandidatesSourcesRecommendationLinksModal);
