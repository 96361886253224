export const REQUEST_HEADER = 'REQUEST_HEADER';
export const RECEIVE_HEADER = 'RECEIVE_HEADER';
export const JOB_DETAIL_HEADER = 'JOB_DETAIL_HEADER';
export const PREVIOUS_PAGE = 'PREVIOUS_PAGE';

export const requestHeader = () => ({
    type: REQUEST_HEADER,

    meta: {
        requestRoute: 'v1/header/model',
        onRequestSuccess: RECEIVE_HEADER,
    },
});

export const jobDetailHeaderData = (jobData) => ({
    type: JOB_DETAIL_HEADER,

    payload: {
        jobData,
    },
});

export const previousPage = (location) => ({
    type: PREVIOUS_PAGE,

    payload: {
        location,
    },
});
