import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from '@profesia/adamui/components/common/dropdown/SelectDefault';
import Form from '../Form';
import Grid from '../Grid';
import Divider from '../Divider';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import { closeModal } from '../../actions/ModalActions';
import { setNewCandidatesEmails } from '../../actions/SettingsActions';
import LoadingComponent from '../LoadingComponent';
import Translations from '../../helpers/Translations';

class SettingsPageNewCandidatesEmailsSettingsModal extends Component {
    state = {
        newCandidatesEmails: this.props.user.newCandidatesEmails,
    };

    componentDidUpdate(prevProps) {
        if (this.props.user.newCandidatesEmails !== prevProps.user.newCandidatesEmails) {
            this.setState({
                newCandidatesEmails: this.props.user.newCandidatesEmails,
            });
        }

        if (
            prevProps.isSettingNewCandidatesEmails === true &&
            this.props.isSettingNewCandidatesEmails === false
        ) {
            prevProps.closeModal();
        }
    }

    selectNewCandidatesEmails = (newCandidatesEmails) => {
        this.setState({
            newCandidatesEmails,
        });
    };

    setNewCandidatesEmails = (newCandidatesEmails) => {
        this.props.setNewCandidatesEmails(
            newCandidatesEmails,
            this.props.user.newCandidatesEmails,
            this.prepareNewCandidatesEmailsDataToSave(newCandidatesEmails)
        );
    };

    prepareNewCandidatesEmailsDataToSave = (newCandidatesEmails) => {
        const formData = new FormData();

        formData.append('newCandidatesEmails', newCandidatesEmails);

        return formData;
    };

    render() {
        const { isOpen, closeModal, isSettingNewCandidatesEmails, noSpacing } = this.props;
        const { newCandidatesEmails } = this.state;

        const newCandidatesEmailsOptions = this.props.newCandidatesEmailsOptions.map(
            (newCandidatesEmailsOption) => ({
                label: newCandidatesEmailsOption.option,
                id: newCandidatesEmailsOption.value.toString(),
            })
        );

        const newCandidatesEmailsSelectedOption = this.props.newCandidatesEmailsOptions.find(
            (newCandidatesEmailsOption) =>
                this.state.newCandidatesEmails.toString() ===
                newCandidatesEmailsOption.value.toString()
        );

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={closeModal}
                actionButton={{
                    text: Translations.getStatic('save'),
                    disabled: isSettingNewCandidatesEmails === true,
                    onClick: () => this.setNewCandidatesEmails(newCandidatesEmails),
                }}
                title={Translations.getStatic('newCandidatesAlerts')}
                content={
                    isSettingNewCandidatesEmails === true ? (
                        <LoadingComponent text={Translations.getStatic('setting')} />
                    ) : (
                        <>
                            <Form>
                                <Grid className={noSpacing === true ? 'padding-top_none' : ''}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <h5>{newCandidatesEmailsSelectedOption.title}</h5>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Divider hidden className={'size_4'} />
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Field>
                                                <Select
                                                    value={newCandidatesEmails}
                                                    options={newCandidatesEmailsOptions}
                                                    onChange={(value) =>
                                                        this.selectNewCandidatesEmails(
                                                            parseInt(value)
                                                        )
                                                    }
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Form>
                        </>
                    )
                }
            />
        );
    }
}

SettingsPageNewCandidatesEmailsSettingsModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    isOpen: state.modalLogic.isOpen,
    user: state.settingsModel.user,
    newCandidatesEmailsOptions: state.settingsModel.newCandidatesEmailsOptions,
    newCandidatesEmails: state.settingsModel.user.newCandidatesEmails,
    isSettingNewCandidatesEmails: state.settingsModel.isSettingNewCandidatesEmails || false,
});

const mapDispatchToProps = {
    closeModal,
    setNewCandidatesEmails,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingsPageNewCandidatesEmailsSettingsModal);
