import React from 'react';
import Grid from '../Grid';
import PropTypes from 'prop-types';
import TemplateAttachmentColumn from './TemplateAttachmentColumn';

class TemplateAttachmentsList extends React.Component {
    render() {
        const { templateAttachments } = this.props;

        if (templateAttachments.length === 0) {
            return null;
        }

        return (
            <Grid className="data-block data-block_primary">
                <Grid.Row className="padding-top_none">
                    <Grid.Column>
                        <div className="ui items unstackable">
                            {templateAttachments
                                .filter(
                                    (attachment) =>
                                        attachment.hasOwnProperty('removed') === false ||
                                        attachment.removed === false
                                )
                                .map((attachment) => (
                                    <TemplateAttachmentColumn
                                        key={attachment.id}
                                        attachment={attachment}
                                        onRemoveTemplateAttachment={() =>
                                            this.props.onRemoveTemplateAttachment(attachment.id)
                                        }
                                    />
                                ))}
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

TemplateAttachmentsList.propTypes = {
    templateAttachments: PropTypes.array.isRequired,
    onRemoveTemplateAttachment: PropTypes.func.isRequired,
};

export default TemplateAttachmentsList;
