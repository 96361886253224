import Constants from '../helpers/Constants';

export const FETCH_AUTO_ANSWER_TEMPLATES = 'FETCH_AUTO_ANSWER_TEMPLATES';
export const FETCH_AUTO_ANSWER_TEMPLATES_SUCCESS = 'FETCH_AUTO_ANSWER_TEMPLATES_SUCCESS';
export const FETCH_AUTO_ANSWER_TEMPLATES_FAILURE = 'FETCH_AUTO_ANSWER_TEMPLATES_FAILURE';
export const FETCH_QUESTIONNAIRE_TEMPLATES = 'FETCH_QUESTIONNAIRE_TEMPLATES';
export const FETCH_QUESTIONNAIRE_TEMPLATES_SUCCESS = 'FETCH_QUESTIONNAIRE_TEMPLATES_SUCCESS';
export const FETCH_QUESTIONNAIRE_TEMPLATES_FAILURE = 'FETCH_QUESTIONNAIRE_TEMPLATES_FAILURE';
export const FETCH_EMAIL_TEMPLATES = 'FETCH_EMAIL_TEMPLATES';
export const FETCH_EMAIL_TEMPLATES_SUCCESS = 'FETCH_EMAIL_TEMPLATES_SUCCESS';
export const FETCH_EMAIL_TEMPLATES_FAILURE = 'FETCH_EMAIL_TEMPLATES_FAILURE';
export const FETCH_PIPELINE_TEMPLATES = 'FETCH_PIPELINE_TEMPLATES';
export const FETCH_PIPELINE_TEMPLATES_SUCCESS = 'FETCH_PIPELINE_TEMPLATES_SUCCESS';
export const FETCH_PIPELINE_TEMPLATES_FAILURE = 'FETCH_PIPELINE_TEMPLATES_FAILURE';

export const fetchAutoAnswerTemplates = (userId, languageId) => ({
    type: FETCH_AUTO_ANSWER_TEMPLATES,
    payload: {
        type: Constants.TEMPLATE_TYPE_AUTO_ANSWER,
    },
    meta: {
        requestRoute: 'v1/auto-answer/data/templates-for-dropdown',
        requestData: {
            userId: userId,
            languageId: languageId,
        },

        onRequestSuccess: FETCH_AUTO_ANSWER_TEMPLATES_SUCCESS,
        onRequestFailure: FETCH_AUTO_ANSWER_TEMPLATES_FAILURE,
    },
});

export const fetchQuestionnaireTemplates = (userId) => ({
    type: FETCH_QUESTIONNAIRE_TEMPLATES,
    payload: {
        type: Constants.TEMPLATE_TYPE_QUESTIONNAIRE,
    },
    meta: {
        requestRoute: 'v1/questionnaire/data/templates-for-dropdown',
        requestData: {
            userId: userId,
        },

        onRequestSuccess: FETCH_QUESTIONNAIRE_TEMPLATES_SUCCESS,
        onRequestFailure: FETCH_QUESTIONNAIRE_TEMPLATES_FAILURE,
    },
});

export const fetchEmailTemplates = (userId, languageId) => ({
    type: FETCH_EMAIL_TEMPLATES,
    payload: {
        type: Constants.TEMPLATE_TYPE_EMAIL,
    },
    meta: {
        requestRoute: 'v1/email-template/data/templates-for-dropdown',
        requestData: {
            userId: userId,
            languageId: languageId,
        },

        onRequestSuccess: FETCH_EMAIL_TEMPLATES_SUCCESS,
        onRequestFailure: FETCH_EMAIL_TEMPLATES_FAILURE,
    },
});

export const fetchPipelineTemplates = (userId) => ({
    type: FETCH_PIPELINE_TEMPLATES,
    payload: {
        type: Constants.TEMPLATE_TYPE_PIPELINE,
    },
    meta: {
        requestRoute: 'v1/pipeline-template/data/templates-for-dropdown',
        requestData: {
            userId,
        },

        onRequestSuccess: FETCH_PIPELINE_TEMPLATES_SUCCESS,
        onRequestFailure: FETCH_PIPELINE_TEMPLATES_FAILURE,
    },
});
