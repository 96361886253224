import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from '@profesia/adamui/components/common/dropdown/SelectDefault';
import Form from '../Form';
import Grid from '../Grid';
import Heading from '../Heading';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import { closeModal } from '../../actions/ModalActions';
import { setUserLanguage } from '../../actions/SettingsActions';
import SuccessActionStatus from '../../helpers/SuccessActionStatus';
import Translations from '../../helpers/Translations';
import Language from '../../helpers/Language';

class SettingsPageLanguageSettingsModal extends Component {
    state = {
        languageId: this.props.user.languageId,
    };

    componentDidUpdate(prevProps) {
        if (this.props.user.languageId !== prevProps.user.languageId) {
            this.setState({
                languageId: this.props.user.languageId,
            });
        }

        if (SuccessActionStatus.isActionSuccessful(prevProps, this.props)) {
            prevProps.closeModal();
        }
    }

    selectLanguage = (languageId) => {
        this.setState({
            languageId,
        });
    };

    setUserLanguage = (languageId) => {
        const languageCode = this.props.languages.find(
            (language) => languageId === language.languageId
        ).languageCode;

        Language.set(languageCode);

        this.props.setUserLanguage(
            languageId,
            languageCode,
            this.prepareUserLanguageDataToSave(languageId)
        );
    };

    prepareUserLanguageDataToSave = (languageId) => {
        const formData = new FormData();

        formData.append('languageId', languageId);

        return formData;
    };

    render() {
        const { isOpen, closeModal, isSettingLanguage, noSpacing } = this.props;
        const { languageId } = this.state;

        const languages = this.props.languages.map((language) => ({
            label: language.language,
            id: language.languageId,
        }));

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={closeModal}
                actionButton={{
                    text: Translations.getStatic('save'),
                    disabled: isSettingLanguage === true,
                    onClick: () => this.setUserLanguage(languageId),
                }}
                title={Translations.getStatic('languageChange')}
                content={
                    <>
                        <Heading as="h3">{Translations.getStatic('languageSettings')}</Heading>
                        <Form>
                            <Grid className={noSpacing === true ? 'padding-top_none' : ''}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Field>
                                            <Select
                                                value={languageId}
                                                label={Translations.getStatic(
                                                    'communicationLanguage'
                                                )}
                                                options={languages}
                                                onChange={(value) => this.selectLanguage(value)}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </>
                }
            />
        );
    }
}

SettingsPageLanguageSettingsModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    languages: PropTypes.arrayOf(
        PropTypes.shape({
            languageId: PropTypes.number.isRequired,
            language: PropTypes.string.isRequired,
        })
    ),
};

const mapStateToProps = (state) => ({
    isOpen: state.modalLogic.isOpen,
    languages: state.loginManagerModel.languages.filter((language) => language.enabled === true),
    language: state.settingsModel.user.language,
    user: state.settingsModel.user,
    isSettingLanguage: state.settingsModel.isSettingLanguage || false,
    actionSuccessStatus: state.actionSuccessStatus,
});

const mapDispatchToProps = {
    closeModal,
    setUserLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPageLanguageSettingsModal);
