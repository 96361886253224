import React from 'react';
import Grid from '../Grid';
import Item from '../Item';
import PropTypes from 'prop-types';
import Translations from '../../helpers/Translations';

const DetailEmailAttachmentColumn = ({ attachment }) => {
    return (
        <Item className="data-block__content attachment basic">
            <Grid verticalAlign="middle" container>
                <Grid.Row>
                    <Grid.Column computer={12} tablet={11}>
                        <Item.Header as="h5" className="ellipsis">
                            {attachment.hasOwnProperty('url') === true &&
                            attachment.url !== null ? (
                                <a href={attachment.url} target="_blank">
                                    {attachment.attachmentFilename}
                                </a>
                            ) : (
                                attachment.attachmentFilename
                            )}
                        </Item.Header>
                    </Grid.Column>
                    <Grid.Column computer={4} textAlign="right">
                        {attachment.hasOwnProperty('url') === true && attachment.url !== null && (
                            <a
                                target="_blank"
                                href={attachment.url}
                                className="link secondary-link"
                            >
                                {Translations.getStatic('show')}
                            </a>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Item>
    );
};

DetailEmailAttachmentColumn.propTypes = {
    attachment: PropTypes.object.isRequired,
};

export default DetailEmailAttachmentColumn;
