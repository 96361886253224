import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext, useWatch } from 'react-hook-form';
import { Box } from '@mui/material';
import { SelectDefault } from '@profesia/adamui/components/common/dropdown';
import { DEFAULT_PERIOD, useGetPeriodOptions } from '@profesia/mark-offer-form/codebooks';
import { FORM_SALARY_PERIOD } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
const Period = () => {
    const t = useGetFixedT('salaryType');
    const periods = useGetPeriodOptions();
    const { setValue, control } = useFormContext();
    const value = useWatch({ name: FORM_SALARY_PERIOD, control });
    return (_jsx(Box, { maxWidth: 110, flexGrow: 1, children: _jsx(SelectDefault, { color: "primary", options: periods, value: value || DEFAULT_PERIOD, onChange: (value) => setValue(FORM_SALARY_PERIOD, value), label: t('period') }) }));
};
export default Period;
