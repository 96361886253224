export default class TemplateAttachmentsHelper {
    /**
     * @param formData
     * @param attachmentAssistantModel
     * @param withTemplateAttachments
     * @returns {*}
     */
    static prepareFormData(formData, attachmentAssistantModel, withTemplateAttachments = true) {
        if (
            withTemplateAttachments === true &&
            attachmentAssistantModel.bulkUploadAttachments !== undefined
        ) {
            attachmentAssistantModel.bulkUploadAttachments.forEach((attachment) => {
                formData.append('templateAttachments[]', attachment.file);
            });
        }

        formData.append(
            'templateAttachmentIdsToRemove',
            JSON.stringify(
                attachmentAssistantModel.templateAttachments
                    .filter(
                        (attachment) =>
                            attachment.hasOwnProperty('removed') === true &&
                            attachment.removed === true
                    )
                    .map((attachment) => attachment.id)
            )
        );

        formData.append(
            'templateAttachmentIdsToSend',
            JSON.stringify(
                attachmentAssistantModel.templateAttachments
                    .filter(
                        (attachment) =>
                            attachment.hasOwnProperty('removed') === false ||
                            attachment.removed === false
                    )
                    .map((attachment) => attachment.id)
            )
        );

        return formData;
    }

    static prepareFormDataCreateJob(
        formDataObj,
        attachmentAssistantModel,
        withTemplateAttachments = true
    ) {
        if (
            withTemplateAttachments === true &&
            attachmentAssistantModel.bulkUploadAttachments !== undefined
        ) {
            attachmentAssistantModel.bulkUploadAttachments.forEach((attachment) => {
                if (!Array.isArray(formDataObj['templateAttachments[]'])) {
                    formDataObj['templateAttachments[]'] = [];
                }
                formDataObj['templateAttachments[]'].push(attachment.file);
            });
        }

        formDataObj['templateAttachmentIdsToRemove'] = attachmentAssistantModel.templateAttachments
            .filter(
                (attachment) =>
                    attachment.hasOwnProperty('removed') === true && attachment.removed === true
            )
            .map((attachment) => attachment.id);

        formDataObj['templateAttachmentIdsToSend'] = attachmentAssistantModel.templateAttachments
            .filter(
                (attachment) =>
                    attachment.hasOwnProperty('removed') === false || attachment.removed === false
            )
            .map((attachment) => attachment.id);

        return formDataObj;
    }
}
