import React from 'react';
import { Radio } from 'semantic-ui-react';
import { LoadingCircle } from '@profesia/adamui/components/common/loadings';
import PropTypes from 'prop-types';

const RadioToggle = ({ label, onChange, checked = true, loading = false, className }) => {
    return (
        <div className="radio-toggle">
            <span className="toggle-label">
                {loading === true ? <LoadingCircle size="16px" /> : label}
            </span>

            <Radio toggle className={className} checked={checked} onChange={onChange} />
        </div>
    );
};

RadioToggle.propTypes = {
    label: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    className: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};

export default RadioToggle;
