export const ENABLE_TRANSLATION_KEYS_INFO = 'ENABLE_TRANSLATION_KEYS_INFO';
export const DISABLE_TRANSLATION_KEYS_INFO = 'DISABLE_TRANSLATION_KEYS_INFO';
export const ENABLE_INTERNAL_INFO = 'ENABLE_INTERNAL_INFO';
export const DISABLE_INTERNAL_INFO = 'DISABLE_INTERNAL_INFO';

export const enableTranslationKeysInfo = () => ({
    type: ENABLE_TRANSLATION_KEYS_INFO,
});

export const disableTranslationKeysInfo = () => ({
    type: DISABLE_TRANSLATION_KEYS_INFO,
});

export const enableInternalInfo = () => ({
    type: ENABLE_INTERNAL_INFO,
});

export const disableInternalInfo = () => ({
    type: DISABLE_INTERNAL_INFO,
});
