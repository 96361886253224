import React, { Component } from 'react';
import { connect } from 'react-redux';
import Item from '../../Item';
import Icon from '../../Icon';
import { openSidebarEditPanel } from '../../../actions/SidebarActions';
import PropTypes from 'prop-types';
import Translations from '../../../helpers/Translations';
import Constants from '../../../helpers/Constants';
import Storages from '../../../helpers/Storages';

class CandidateSidebarNote extends Component {
    onHandleNoteEdit = (candidateId, note, isPublic) => {
        this.props.openSidebarEditPanel('CandidateSidebarNoteEdit', {
            note,
            candidateId,
            isPublic,
        });
    };

    render() {
        const { candidateId, isDuplicate, isExternalUser, userId, loginManagerModel } = this.props;

        const note = this.props.notes.find(
            (note) => note.hasOwnProperty('user') === true && note.user.id === userId
        );

        const unsavedNote = Storages.getLocal(`candidateNoteId_${candidateId}`);

        let sectionTitle = (
            <Item.Header>
                <p>{Translations.getStatic('myNotes')}</p>
            </Item.Header>
        );

        if (note === undefined || note.candidateNoteText === '') {
            sectionTitle = (
                <>
                    {unsavedNote === null ? (
                        <a
                            className="secondary-link"
                            onClick={() =>
                                this.onHandleNoteEdit(
                                    candidateId,
                                    '',
                                    isExternalUser === false &&
                                        loginManagerModel.user.defaultPublicNotes ===
                                            Constants.DEFAULT_PUBLIC_NOTES_ON
                                )
                            }
                        >
                            {Translations.getStatic('addNote')}
                        </a>
                    ) : (
                        <a
                            className="secondary-link"
                            onClick={() =>
                                this.onHandleNoteEdit(
                                    candidateId,
                                    unsavedNote.note,
                                    isExternalUser === false &&
                                        loginManagerModel.user.defaultPublicNotes ===
                                            Constants.DEFAULT_PUBLIC_NOTES_ON
                                )
                            }
                        >
                            {Translations.getStatic('notes')}{' '}
                            <span className="txt-disabled text-small">
                                ({Translations.getStatic('unsavedChanges')})
                            </span>
                        </a>
                    )}
                </>
            );
        } else if (isDuplicate === false) {
            sectionTitle = (
                <Item.Header>
                    {unsavedNote !== null && unsavedNote.note !== note.candidateNoteText ? (
                        <a
                            className="secondary-link"
                            onClick={() =>
                                this.onHandleNoteEdit(
                                    candidateId,
                                    unsavedNote.note,
                                    isExternalUser === false &&
                                        loginManagerModel.user.defaultPublicNotes ===
                                            Constants.DEFAULT_PUBLIC_NOTES_ON
                                )
                            }
                        >
                            {Translations.getStatic('notes')}{' '}
                            <span className="txt-disabled text-small">
                                ({Translations.getStatic('unsavedChanges')})
                            </span>
                        </a>
                    ) : (
                        <a
                            className="secondary-link"
                            onClick={() =>
                                this.onHandleNoteEdit(
                                    candidateId,
                                    note.candidateNoteText,
                                    note.isPublic
                                )
                            }
                        >
                            {Translations.getStatic('notes')}{' '}
                            {note.isPublic === true && (
                                <span>({Translations.getStatic('sharedPlural')})</span>
                            )}
                        </a>
                    )}
                </Item.Header>
            );
        }

        return (
            <div className="ui items unstackable">
                <Item>
                    <Icon className="icon-notes_small" size="mini" />
                    <Item.Content>
                        {sectionTitle}
                        {note === undefined || note.candidateNoteText === '' ? null : (
                            <p>{note.candidateNoteText}</p>
                        )}
                    </Item.Content>
                </Item>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loginManagerModel: state.loginManagerModel,
});

const mapDispatchToProps = {
    openSidebarEditPanel,
};

CandidateSidebarNote.propTypes = {
    notes: PropTypes.arrayOf(
        PropTypes.shape({
            candidateNoteCreatedAt: PropTypes.string,
            candidateNoteId: PropTypes.number,
            candidateNoteText: PropTypes.string,
            userId: PropTypes.number,
            isPublic: PropTypes.bool,
        })
    ),

    candidateId: PropTypes.number.isRequired,
    isDuplicate: PropTypes.bool.isRequired,
    isExternalUser: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateSidebarNote);
