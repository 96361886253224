import Url from '../../../helpers/Url';
import handleErrorResponse from '../../../helpers/handleErrorResponse';

const apiRequest = async <T>(url: string, options: RequestInit = {}): Promise<T> => {
    const defaultHeaders = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    const config = {
        ...options,
        headers: {
            ...defaultHeaders,
            ...options.headers,
        },
    };

    try {
        const response = await fetch(`${Url.MARK_SERVER_PREFIX}/api/v1/${url}`, config);
        handleErrorResponse(response);
        return response.json();
    } catch (error) {
        throw error;
    }
};

export default apiRequest;
