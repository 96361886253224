import { Language } from './services/types';
const configuration = {
    api: {
        getJobTitleAiSuggestions: () => new Promise((resolve) => resolve([])),
        generateTextWithAi: () => new Promise((resolve) => resolve('')),
        getCodebooks: () => new Promise((resolve) => resolve({
            jobPositions: {
                groups: {},
                positions: [],
            },
            languages: {
                favourites: [],
                others: [],
            },
            regions: [],
        })),
        getTranslations: () => new Promise((resolve) => resolve({})),
        getOtherSkills: () => new Promise((resolve) => resolve([])),
        getBusinessAreas: () => new Promise((resolve) => resolve([])),
        getSalaryFromPlaty: () => new Promise((resolve) => resolve({})),
        postForm: () => new Promise((resolve) => resolve(null)),
        updateForm: () => new Promise((resolve) => resolve(null)),
        getContactPersons: () => new Promise((resolve) => resolve([
            {
                academicDegree: '',
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                id: 0,
            },
        ])),
        updateContactPerson: () => new Promise((resolve) => resolve()),
        updateCompanyDetails: () => new Promise((resolve) => resolve({
            businessArea: {
                id: 0,
                names: { sk: '', cs: '', en: '', de: '', hu: '' },
            },
            characteristics: '',
            languageFlag: Language.Sk,
            logo: null,
        })),
    },
    form: {
        onCancel: () => console.log('onClose has been triggered'),
        onSubmit: () => { },
        openModalSecond: () => { },
        closeModalSecond: () => { },
        openModalThird: () => { },
        closeModalThird: () => { },
    },
};
export const configure = ({ api, form }) => {
    if (api) {
        configuration.api = { ...configuration.api, ...api };
    }
    if (form) {
        configuration.form = { ...configuration.form, ...form };
    }
    return configuration;
};
export const getConfiguration = () => configuration;
