import {
    OPEN_NO_CANDIDATE_ACCESS_DIALOG,
    CLOSE_NO_CANDIDATE_ACCESS_DIALOG,
    OPEN_NO_ORDER_ACCESS_DIALOG,
    CLOSE_NO_ORDER_ACCESS_DIALOG,
    OPEN_ALL_RECIPIENTS_WITHOUT_EMAILS_DIALOG,
    CLOSE_ALL_RECIPIENTS_WITHOUT_EMAILS_DIALOG,
    OPEN_SOME_RECIPIENTS_WITHOUT_EMAILS_DIALOG,
    CLOSE_SOME_RECIPIENTS_WITHOUT_EMAILS_DIALOG,
    OPEN_SOME_RECIPIENTS_WITHOUT_EMAILS_DIALOG_SUCCESS,
    OPEN_SOME_RECIPIENTS_WITHOUT_EMAILS_DIALOG_FAILURE,
    OPEN_NOT_ALLOWED_FEATURE_DIALOG,
    CLOSE_NOT_ALLOWED_FEATURE_DIALOG,
    OPEN_CLOSE_JOB_UNCONTACTED_CANDIDATE_DIALOG,
    CLOSE_JOB_CLOSE_UNCONTACTED_CANDIDATE_DIALOG,
    OPEN_CLOSE_JOB_CONFIRMATION_DIALOG,
    OPEN_CLOSE_JOB_CONFIRMATION_DIALOG_WITH_CHECK,
    OPEN_CLOSE_JOB_CONFIRMATION_DIALOG_WITH_CHECK_SUCCESS,
    OPEN_CLOSE_JOB_CONFIRMATION_DIALOG_WITH_CHECK_FAILURE,
    CLOSE_JOB_CLOSE_CONFIRMATION_DIALOG,
    OPEN_ACCEPTED_CANDIDATE_DIALOG,
    OPEN_ACCEPTED_CANDIDATE_DIALOG_FAILURE,
    OPEN_ACCEPTED_CANDIDATE_DIALOG_SUCCESS,
    CLOSE_ACCEPTED_CANDIDATE_DIALOG,
    OPEN_CANCEL_CLOSE_JOB_WIZARD_DIALOG,
    CLOSE_CANCEL_CLOSE_JOB_WIZARD_DIALOG,
    OPEN_COPY_INTO_TALENT_POOL_DIALOG,
    CLOSE_COPY_INTO_TALENT_POOL_DIALOG,
    OPEN_REMOVE_FROM_TALENT_POOL_DIALOG,
    CLOSE_REMOVE_FROM_TALENT_POOL_DIALOG,
    OPEN_GDPR_AGREEMENT_FILTER_TESTING_DIALOG,
    OPEN_GDPR_AGREEMENT_FILTER_TESTING_NEWSLETTER_DIALOG,
    CLOSE_GDPR_AGREEMENT_FILTER_TESTING_DIALOG,
    CLOSE_GDPR_AGREEMENT_FILTER_TESTING_NEWSLETTER_DIALOG,
    OPEN_NO_JOB_ACCESS_DIALOG,
    CLOSE_NO_JOB_ACCESS_DIALOG,
    OPEN_DASHBOARD_STATISTICS_TESTING_DIALOG,
    CLOSE_DASHBOARD_STATISTICS_TESTING_DIALOG,
    OPEN_DASHBOARD_STATISTICS_TESTING_NEWSLETTER_DIALOG,
    CLOSE_DASHBOARD_STATISTICS_TESTING_NEWSLETTER_DIALOG,
    OPEN_DASHBOARD_NEWS_TESTING_DIALOG,
    CLOSE_DASHBOARD_NEWS_TESTING_DIALOG,
    OPEN_DASHBOARD_NEWS_TESTING_NEWSLETTER_DIALOG,
    CLOSE_DASHBOARD_NEWS_TESTING_NEWSLETTER_DIALOG,
} from '../actions/DialogActions';

export const noCandidateAccessDialogOpen = (state = false, action) => {
    switch (action.type) {
        case OPEN_NO_CANDIDATE_ACCESS_DIALOG:
            return true;
        case CLOSE_NO_CANDIDATE_ACCESS_DIALOG:
            return false;
        default:
            return state;
    }
};

export const noJobAccessDialogOpen = (state = false, action) => {
    switch (action.type) {
        case OPEN_NO_JOB_ACCESS_DIALOG:
            return true;
        case CLOSE_NO_JOB_ACCESS_DIALOG:
            return false;
        default:
            return state;
    }
};

export const noOrderAccessDialogOpen = (state = false, action) => {
    switch (action.type) {
        case OPEN_NO_ORDER_ACCESS_DIALOG:
            return true;
        case CLOSE_NO_ORDER_ACCESS_DIALOG:
            return false;
        default:
            return state;
    }
};

export const allRecipientsWithoutEmailsDialog = (state = false, action) => {
    switch (action.type) {
        case OPEN_ALL_RECIPIENTS_WITHOUT_EMAILS_DIALOG:
            return {
                ...state,
                open: true,
            };

        case CLOSE_ALL_RECIPIENTS_WITHOUT_EMAILS_DIALOG:
            return {
                open: false,
            };

        default:
            return state;
    }
};

export const someRecipientsWithoutEmailsDialog = (state = false, action) => {
    switch (action.type) {
        case OPEN_SOME_RECIPIENTS_WITHOUT_EMAILS_DIALOG:
            return {
                open: true,
                dialogProps: action.payload.dialogProps,
            };
        case OPEN_SOME_RECIPIENTS_WITHOUT_EMAILS_DIALOG_SUCCESS:
            return {
                ...state,
                candidates: action.payload.candidates,
            };
        case CLOSE_SOME_RECIPIENTS_WITHOUT_EMAILS_DIALOG:
            return {
                open: false,
            };
        default:
            return state;
    }
};

export const isFetchingCandidatesForSomeRecipientsWithoutEmailsDialog = (state = false, action) => {
    switch (action.type) {
        case OPEN_SOME_RECIPIENTS_WITHOUT_EMAILS_DIALOG:
            return true;
        case OPEN_SOME_RECIPIENTS_WITHOUT_EMAILS_DIALOG_SUCCESS:
        case OPEN_SOME_RECIPIENTS_WITHOUT_EMAILS_DIALOG_FAILURE:
            return false;
        default:
            return state;
    }
};

export const notAllowedFeatureDialogOpen = (state = { open: false }, action) => {
    switch (action.type) {
        case OPEN_NOT_ALLOWED_FEATURE_DIALOG:
            return {
                open: true,
                dialogProps: action.payload.dialogProps,
            };

        case CLOSE_NOT_ALLOWED_FEATURE_DIALOG:
            return {
                open: false,
            };

        default:
            return state;
    }
};

export const copyIntoTalentPoolDialogOpen = (state = false, action) => {
    switch (action.type) {
        case OPEN_COPY_INTO_TALENT_POOL_DIALOG:
            return {
                open: true,
                dialogProps: action.payload.dialogProps,
            };
        case CLOSE_COPY_INTO_TALENT_POOL_DIALOG:
            return {
                open: false,
            };
        default:
            return state;
    }
};

export const removeFromTalentPoolDialog = (state = { open: false }, action) => {
    switch (action.type) {
        case OPEN_REMOVE_FROM_TALENT_POOL_DIALOG:
            return {
                open: true,
                dialogProps: action.payload.dialogProps,
            };
        case CLOSE_REMOVE_FROM_TALENT_POOL_DIALOG:
            return {
                open: false,
            };
        default:
            return state;
    }
};

export const confirmCloseJobUncontactedDialog = (state = { open: false }, action) => {
    switch (action.type) {
        case OPEN_CLOSE_JOB_UNCONTACTED_CANDIDATE_DIALOG:
            return {
                open: true,
                ...action.payload,
            };
        case CLOSE_JOB_CLOSE_UNCONTACTED_CANDIDATE_DIALOG:
            return {
                open: false,
            };
        default:
            return state;
    }
};

export const confirmCloseJobDialog = (state = { open: false }, action) => {
    switch (action.type) {
        case OPEN_CLOSE_JOB_CONFIRMATION_DIALOG:
        case OPEN_CLOSE_JOB_CONFIRMATION_DIALOG_WITH_CHECK:
            return {
                open: true,
                ...action.payload,
            };
        case OPEN_CLOSE_JOB_CONFIRMATION_DIALOG_WITH_CHECK_SUCCESS:
            return {
                ...state,
                candidates: action.payload.candidates,
            };
        case CLOSE_JOB_CLOSE_CONFIRMATION_DIALOG:
            return {
                open: false,
            };
        default:
            return state;
    }
};

export const isFetchingCandidatesForConfirmCloseJobDialog = (state = false, action) => {
    switch (action.type) {
        case OPEN_CLOSE_JOB_CONFIRMATION_DIALOG_WITH_CHECK:
            return true;
        case OPEN_CLOSE_JOB_CONFIRMATION_DIALOG_WITH_CHECK_SUCCESS:
        case OPEN_CLOSE_JOB_CONFIRMATION_DIALOG_WITH_CHECK_FAILURE:
            return false;
        default:
            return state;
    }
};

export const acceptedCandidateDialog = (state = { open: false }, action) => {
    switch (action.type) {
        case OPEN_ACCEPTED_CANDIDATE_DIALOG:
            return {
                open: true,
                ...action.payload,
            };
        case OPEN_ACCEPTED_CANDIDATE_DIALOG_SUCCESS:
            return {
                ...state,
                candidates: action.payload.candidates,
            };
        case CLOSE_ACCEPTED_CANDIDATE_DIALOG:
            return {
                open: false,
            };
        default:
            return state;
    }
};

export const isFetchingCandidatesForAcceptedCandidateDialog = (state = false, action) => {
    switch (action.type) {
        case OPEN_ACCEPTED_CANDIDATE_DIALOG:
            return true;
        case OPEN_ACCEPTED_CANDIDATE_DIALOG_SUCCESS:
        case OPEN_ACCEPTED_CANDIDATE_DIALOG_FAILURE:
            return false;
        default:
            return state;
    }
};

export const cancelCloseJobWizardDialog = (state = { open: false }, action) => {
    switch (action.type) {
        case OPEN_CANCEL_CLOSE_JOB_WIZARD_DIALOG:
            return {
                ...state,
                open: true,
            };

        case CLOSE_CANCEL_CLOSE_JOB_WIZARD_DIALOG:
            return {
                open: false,
            };

        default:
            return state;
    }
};

export const gdprAgreementFilterTestingDialog = (state = { open: false }, action) => {
    switch (action.type) {
        case OPEN_GDPR_AGREEMENT_FILTER_TESTING_DIALOG:
            return {
                ...state,
                open: true,
            };

        case CLOSE_GDPR_AGREEMENT_FILTER_TESTING_DIALOG:
            return {
                open: false,
            };

        default:
            return state;
    }
};

export const gdprAgreementFilterTestingNewsletterDialog = (state = { open: false }, action) => {
    switch (action.type) {
        case OPEN_GDPR_AGREEMENT_FILTER_TESTING_NEWSLETTER_DIALOG:
            return {
                ...state,
                open: true,
            };

        case CLOSE_GDPR_AGREEMENT_FILTER_TESTING_NEWSLETTER_DIALOG:
            return {
                open: false,
            };

        default:
            return state;
    }
};

export const dashboardStatisticsTestingDialog = (state = { open: false }, action) => {
    switch (action.type) {
        case OPEN_DASHBOARD_STATISTICS_TESTING_DIALOG:
            return {
                ...state,
                open: true,
            };

        case CLOSE_DASHBOARD_STATISTICS_TESTING_DIALOG:
            return {
                open: false,
            };

        default:
            return state;
    }
};

export const dashboardStatisticsTestingNewsletterDialog = (state = { open: false }, action) => {
    switch (action.type) {
        case OPEN_DASHBOARD_STATISTICS_TESTING_NEWSLETTER_DIALOG:
            return {
                ...state,
                open: true,
            };

        case CLOSE_DASHBOARD_STATISTICS_TESTING_NEWSLETTER_DIALOG:
            return {
                open: false,
            };

        default:
            return state;
    }
};

export const dashboardNewsTestingDialog = (state = { open: false }, action) => {
    switch (action.type) {
        case OPEN_DASHBOARD_NEWS_TESTING_DIALOG:
            return {
                ...state,
                open: true,
            };

        case CLOSE_DASHBOARD_NEWS_TESTING_DIALOG:
            return {
                open: false,
            };

        default:
            return state;
    }
};

export const dashboardNewsTestingNewsletterDialog = (state = { open: false }, action) => {
    switch (action.type) {
        case OPEN_DASHBOARD_NEWS_TESTING_NEWSLETTER_DIALOG:
            return {
                ...state,
                open: true,
            };

        case CLOSE_DASHBOARD_NEWS_TESTING_NEWSLETTER_DIALOG:
            return {
                open: false,
            };

        default:
            return state;
    }
};
