import {
    SAVE_CANDIDATES_LIST_COLUMNS_ORDER,
    SAVE_CANDIDATES_LIST_COLUMNS_ORDER_FAILURE,
    SAVE_CANDIDATES_LIST_COLUMNS_ORDER_SUCCESS,
} from '../actions/CandidatesListColumnsOrderActions';

export const isSavingCandidatesListColumnsOrder = (state = false, action) => {
    switch (action.type) {
        case SAVE_CANDIDATES_LIST_COLUMNS_ORDER:
            return true;
        case SAVE_CANDIDATES_LIST_COLUMNS_ORDER_SUCCESS:
        case SAVE_CANDIDATES_LIST_COLUMNS_ORDER_FAILURE:
            return false;
        default:
            return state;
    }
};

const defaultState = {
    isSuccess: false,
};

export const candidatesListColumnsOrderModel = (state = defaultState, action) => {
    switch (action.type) {
        case SAVE_CANDIDATES_LIST_COLUMNS_ORDER:
            return defaultState;
        case SAVE_CANDIDATES_LIST_COLUMNS_ORDER_SUCCESS:
            return {
                ...state,
                isSuccess: true,
            };
        case SAVE_CANDIDATES_LIST_COLUMNS_ORDER_FAILURE:
            return {
                ...state,
                isSuccess: false,
            };
        default:
            return state;
    }
};
