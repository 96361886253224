import React from 'react';
import Form from '../Form';
import Grid from '../Grid';
import Item from '../Item';
import PropTypes from 'prop-types';

const EmailTemplatePreview = (props) => (
    <Grid>
        <Grid.Column>
            <Form>
                <div className="ui items">
                    <Item>
                        <Item.Content>
                            <Item.Header
                                className="parent"
                                as="h3"
                                dangerouslySetInnerHTML={{
                                    __html: props.emailTemplateData.subject,
                                }}
                            />
                            <Item.Description>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: props.emailTemplateData.highlightedText,
                                    }}
                                />
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </div>
            </Form>
        </Grid.Column>
    </Grid>
);

EmailTemplatePreview.propTypes = {
    emailTemplateData: PropTypes.object.isRequired,
};

EmailTemplatePreview.defaultProps = {};

export default EmailTemplatePreview;
