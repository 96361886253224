import {
    REQUEST_COMPANY_EXPORT_REQUESTS_MODEL,
    RECEIVE_COMPANY_EXPORT_REQUESTS_MODEL,
    COMPANY_EXPORT_REQUEST_ACTIVATE,
    COMPANY_EXPORT_REQUEST_ACTIVATE_SUCCESS,
    COMPANY_EXPORT_REQUEST_ACTIVATE_FAILURE,
    COMPANY_EXPORT_REQUEST_DEACTIVATE,
    COMPANY_EXPORT_REQUEST_DEACTIVATE_SUCCESS,
    COMPANY_EXPORT_REQUEST_DEACTIVATE_FAILURE,
    COMPANY_EXPORT_REQUEST_UPDATE,
    COMPANY_EXPORT_REQUEST_UPDATE_SUCCESS,
    COMPANY_EXPORT_REQUEST_UPDATE_FAILURE,
    COMPANY_EXPORT_REQUEST_CREATE,
    COMPANY_EXPORT_REQUEST_CREATE_SUCCESS,
    COMPANY_EXPORT_REQUEST_CREATE_FAILURE,
    COMPANY_EXPORT_REQUEST_ADD,
    COMPANY_EXPORT_REQUEST_ADD_CANCEL,
} from '../actions/CompanyExportRequestsActions';
import Arrays from '../helpers/Arrays';

export const companyExportRequestsModel = (state = {}, action) => {
    switch (action.type) {
        case RECEIVE_COMPANY_EXPORT_REQUESTS_MODEL:
            return {
                ...state,
                ...action.payload,
            };

        case COMPANY_EXPORT_REQUEST_ACTIVATE:
            return {
                ...state,

                companyExportRequests: state.companyExportRequests.map((companyExportRequest) => ({
                    ...companyExportRequest,
                    isActive:
                        companyExportRequest.id === action.payload.companyExportRequestId
                            ? true
                            : companyExportRequest.isActive,
                })),
            };

        case COMPANY_EXPORT_REQUEST_ACTIVATE_FAILURE:
            return {
                ...state,

                companyExportRequests: state.companyExportRequests.map((companyExportRequest) => ({
                    ...companyExportRequest,
                    isActive:
                        companyExportRequest.id ===
                        action.meta.requestPayload.companyExportRequestId
                            ? false
                            : companyExportRequest.isActive,
                })),
            };

        case COMPANY_EXPORT_REQUEST_DEACTIVATE:
            return {
                ...state,

                companyExportRequests: state.companyExportRequests.map((companyExportRequest) => ({
                    ...companyExportRequest,
                    isActive:
                        companyExportRequest.id === action.payload.companyExportRequestId
                            ? false
                            : companyExportRequest.isActive,
                })),
            };

        case COMPANY_EXPORT_REQUEST_DEACTIVATE_FAILURE:
            return {
                ...state,

                companyExportRequests: state.companyExportRequests.map((companyExportRequest) => ({
                    ...companyExportRequest,
                    isActive:
                        companyExportRequest.id ===
                        action.meta.requestPayload.companyExportRequestId
                            ? true
                            : companyExportRequest.isActive,
                })),
            };

        case COMPANY_EXPORT_REQUEST_ACTIVATE_SUCCESS:
        case COMPANY_EXPORT_REQUEST_DEACTIVATE_SUCCESS:
        case COMPANY_EXPORT_REQUEST_UPDATE_SUCCESS:
        case COMPANY_EXPORT_REQUEST_CREATE_SUCCESS:
            return {
                ...state,

                companyExportRequests: state.companyExportRequests.map((companyExportRequest) =>
                    companyExportRequest.id === action.meta.requestPayload.companyExportRequestId
                        ? action.payload.companyExportRequest
                        : companyExportRequest
                ),
            };

        case COMPANY_EXPORT_REQUEST_ADD:
            return {
                ...state,

                companyExportRequests: Arrays.prepend(
                    {
                        id: 'new',
                        companyId: '',
                        jobsCount: '',
                        stateId: 0,
                    },
                    state.companyExportRequests
                ),
            };

        case COMPANY_EXPORT_REQUEST_ADD_CANCEL:
            return {
                ...state,
                companyExportRequests: state.companyExportRequests.filter(
                    (companyExportRequest) => companyExportRequest.id !== 'new'
                ),
            };

        default:
            return state;
    }
};

export const isFetchingCompanyExportRequestsModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_COMPANY_EXPORT_REQUESTS_MODEL:
            return true;

        case RECEIVE_COMPANY_EXPORT_REQUESTS_MODEL:
            return false;

        default:
            return state;
    }
};
