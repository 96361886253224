import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Translations from '../../helpers/Translations';
import ColleagueEmailsSelect from './ColleagueEmailsSelect';
import CheckForm from '../../helpers/Form';

class BccSelect extends Component {
    state = {
        incorrectBccEmail: '',
    };

    setIncorrectBccEmail = (email) => {
        this.setState({
            incorrectBccEmail: email,
        });
    };

    onSetEmailBcc = (data) => {
        let selectedBcc;
        let bccArray = [];

        for (let i = 0; i < data.value.length; i++) {
            selectedBcc = data.options.find((bcc) => bcc.value === data.value[i]);

            if (selectedBcc !== undefined) {
                bccArray.push({ value: selectedBcc.value, text: selectedBcc.text });
            } else if (
                CheckForm.check({
                    fieldType: 'email',
                    fieldValue: data.value[i],
                }) === true
            ) {
                bccArray.push({ value: data.value[i], text: data.value[i] });
            }
        }

        this.props.onSetEmailBcc(bccArray);
    };

    render() {
        const { options, value, className } = this.props;
        const { incorrectBccEmail } = this.state;

        const getBccError = () => {
            if (incorrectBccEmail !== '') {
                return Translations.getStatic('invalidRecipientEmail').replace(
                    /%email%/g,
                    incorrectBccEmail
                );
            }

            return '';
        };

        return (
            <ColleagueEmailsSelect
                value={value}
                className={className}
                error={getBccError()}
                options={options}
                onChange={(data) => this.onSetEmailBcc(data)}
                onSearchChange={() => this.setIncorrectBccEmail('')}
                onSetIncorrectEmail={(email) => this.setIncorrectBccEmail(email)}
            />
        );
    }
}

BccSelect.propTypes = {
    value: PropTypes.array.isRequired,
    options: PropTypes.array.isRequired,
    onSetEmailBcc: PropTypes.func.isRequired,
    className: PropTypes.string,
};

BccSelect.defaultProps = {
    className: 'darker darker_small multiple-basic',
};

export default BccSelect;
