import SendEmailValidatorHelper from './SendEmailValidatorHelper';
import EmailTemplateValidatorHelper from './EmailTemplateValidatorHelper';
import Constants from '../Constants';
import TemplateAssistantHelper from './TemplateAssistantHelper';
import md5 from 'md5';
import TemplateAttachmentsHelper from './TemplateAttachmentsHelper';
import Translations from '../Translations';
import User from '../User';
import Arrays from '../Arrays';

export default class SendEmailHelper {
    static filterNotEmptyEmailsFromRecipients(recipients) {
        return recipients.filter(
            (recipient) =>
                recipient.value !== '' && recipient.value !== null && recipient.value !== undefined
        );
    }

    static prepareEmail(
        sendEmailModel,
        attachmentAssistantModel,
        templateAssistant,
        templatesForDropdownSelector,
        candidateIds = [],
        emailType = Constants.EMAIL_TYPE_TO_COLLEAGUE,
        variableCheckParams = {
            variables: [],
            filledVariables: {},
            requiredVariables: {},
            forbiddenVariables: {},
        },
        excludedCandidateIdsFromSendEmail = []
    ) {
        const sendEmailValidator = new SendEmailValidatorHelper(
            sendEmailModel.recipients,
            sendEmailModel.emailTemplateData.text,
            sendEmailModel.emailTemplateData.subject,
            sendEmailModel.sendAt,
            candidateIds,
            excludedCandidateIdsFromSendEmail,
            emailType
        );

        const emailErrors = sendEmailValidator.checkSendEmail(false, variableCheckParams);

        if (emailErrors.length > 0) {
            throw emailErrors;
        }

        const emailTemplateValidatorHelper = new EmailTemplateValidatorHelper(
            sendEmailModel.emailTemplateData,
            emailType
        );

        const errors = emailTemplateValidatorHelper.checkEmailTemplate(
            true,
            templateAssistant.emailTemplate.isNewTemplate,
            templatesForDropdownSelector.map((emailTemplate) => emailTemplate.text)
        );

        if (errors.length > 0) {
            throw false;
        }

        let formData = new FormData();

        formData.append('emailTemplateData', JSON.stringify(sendEmailModel.emailTemplateData));
        formData.append(
            'videoInterviewData',
            JSON.stringify(sendEmailModel.settings.videoInterview)
        );
        formData.append(
            'emailTemplateSavingMode',
            TemplateAssistantHelper.getTemplateSavingMode(templateAssistant.emailTemplate)
        );
        formData.append('messageHistory', JSON.stringify(sendEmailModel.messageHistory));

        formData.append(
            'recipients',
            sendEmailModel.recipients.length > 0
                ? JSON.stringify(
                      this.filterNotEmptyEmailsFromRecipients(sendEmailModel.recipients).map(
                          (recipient) => recipient.value
                      )
                  )
                : ''
        );

        formData.append(
            'bcc',
            sendEmailModel.bcc.length > 0
                ? JSON.stringify(
                      this.filterNotEmptyEmailsFromRecipients(sendEmailModel.bcc).map(
                          (bcc) => bcc.value
                      )
                  )
                : ''
        );

        formData.append('sender', sendEmailModel.sender);

        formData.append(
            'excludedCandidateIdsFromSendEmail',
            excludedCandidateIdsFromSendEmail.length > 0
                ? JSON.stringify(excludedCandidateIdsFromSendEmail)
                : ''
        );

        attachmentAssistantModel.bulkUploadAttachments.forEach((attachment) => {
            formData.append(attachment.typeId + '[]', attachment.file);
        });

        if (candidateIds.length <= 1) {
            formData.append(
                'selectedCandidateAttachmentIds',
                attachmentAssistantModel.hasOwnProperty('candidateAttachments') === true &&
                    attachmentAssistantModel.candidateAttachments.length > 0
                    ? JSON.stringify(
                          attachmentAssistantModel.candidateAttachments.map(
                              (attachment) => attachment.attachmentId
                          )
                      )
                    : ''
            );
        } else {
            formData.append(
                'selectedAttachmentTypeIds',
                attachmentAssistantModel.hasOwnProperty('selectedAttachmentTypes') === true
                    ? JSON.stringify(
                          attachmentAssistantModel.selectedAttachmentTypes
                              .filter((type) => type.selected === true)
                              .map((type) => type.typeId)
                      )
                    : ''
            );
        }

        formData.append('emailType', emailType);
        formData.append('sendAt', JSON.stringify(sendEmailModel.sendAt));

        formData = TemplateAttachmentsHelper.prepareFormData(
            formData,
            attachmentAssistantModel,
            false
        );

        return formData;
    }

    static createChangeHash(text, subject, type, languageId) {
        return md5(
            JSON.stringify({
                text: text,
                subject,
                type,
                languageId,
            })
        );
    }

    static processRecipientsErrors(
        errors,
        onOpenAllRecipientsWithoutEmailsDialog,
        onOpenSomeRecipientsWithoutEmailsDialog
    ) {
        if (errors.indexOf('allRecipientsWithoutEmails') > -1) {
            onOpenAllRecipientsWithoutEmailsDialog();
        } else if (errors.indexOf('someRecipientsWithoutEmails') > -1) {
            onOpenSomeRecipientsWithoutEmailsDialog();
        }
    }

    static getDefaultEmailVisibleFields(emailType = Constants.EMAIL_TYPE_TO_CANDIDATE) {
        if (emailType === Constants.EMAIL_TYPE_TO_CANDIDATE) {
            return [
                Constants.EMAIL_EDITOR_FIELD_SENDER,
                Constants.EMAIL_EDITOR_FIELD_RECIPIENTS_INFO,
                Constants.EMAIL_EDITOR_FIELD_BCC,
                Constants.EMAIL_EDITOR_FIELD_SEND_AT,
            ];
        }

        return [
            Constants.EMAIL_EDITOR_FIELD_SENDER,
            Constants.EMAIL_EDITOR_FIELD_RECIPIENTS,
            Constants.EMAIL_EDITOR_FIELD_BCC,
            Constants.EMAIL_EDITOR_FIELD_SEND_AT,
        ];
    }

    /**
     *
     * @param subject
     * @returns {string}
     */
    static prepareSubjectForEmailHistory(subject) {
        return (subject.indexOf('Re: ') === 0 ? '' : 'Re: ') + subject;
    }

    static getSenders(companyUsers, loginManagerModel) {
        let senders = companyUsers.map((companyUser) => ({
            key: companyUser.id,
            label: companyUser.name + ' <' + companyUser.email + '>',
            id: companyUser.id,
        }));

        const loggedUserId = User.getUserId(loginManagerModel);

        if (senders.find((sender) => sender.id === loggedUserId) === undefined) {
            const loggedUserEmail = User.getUserEmail(loginManagerModel);

            senders = Arrays.prepend(
                {
                    key: loggedUserId,
                    label: Translations.getStatic('youPlural') + ' <' + loggedUserEmail + '>',
                    id: loggedUserId,
                },
                senders
            );
        }

        return senders;
    }

    static isMassForwarding(candidateIds, modalType, modalProps) {
        return (
            modalType === 'SendEmailModal' &&
            candidateIds !== undefined &&
            Array.isArray(candidateIds) === true &&
            candidateIds.length > 1 &&
            modalProps !== undefined &&
            modalProps.hasOwnProperty('emailType') === true &&
            modalProps.emailType === Constants.EMAIL_TYPE_TO_COLLEAGUE
        );
    }
}
