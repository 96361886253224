import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { FormFooter } from '@profesia/adamui/components/common/modal';
import ImageCropper from '@profesia/adamui/components/common/ImageCropper/index';
import { useTranslationInPageLanguage } from '@profesia/mark-offer-form/i18n';
import Modal from '@profesia/adamui/components/common/modal/Modal';
import { useLogoContext } from '@profesia/mark-offer-form/services/LogoContext';
import { FORM_COMPANY_LOGO_IMAGE, FORM_COMPANY_LOGO_SOURCE_IMG, FORM_COMPANY_LOGO_CROPPER_DATA, FORM_COMPANY_LOGO_CROPPED_IMG, FORM_COMPANY_LOGO_IMAGE_EXTENSION, } from '@profesia/mark-offer-form/components/content/form/valuePaths';
const EditLogoModal = ({ isOpen, onClose, isModalSecond, sourceImgLogoUrl, }) => {
    const commonT = useTranslationInPageLanguage('common');
    const t = useTranslationInPageLanguage('companyDetails.editLogoModal');
    const formData = useForm();
    const { setValue } = useFormContext();
    const { sourceImgLogo, cropData: contextCropData, setBase64Logo, setSourceImgLogo, setIsEdited, } = useLogoContext();
    const [editedLogo, setEditedLogo] = useState(null);
    const [initialCropData, setInitialCropData] = useState();
    const onUse = () => {
        setBase64Logo(editedLogo?.croppedImg ?? '');
        setSourceImgLogo(editedLogo?.sourceImg ?? '');
        setValue(FORM_COMPANY_LOGO_SOURCE_IMG, editedLogo?.sourceImg);
        setValue(FORM_COMPANY_LOGO_CROPPER_DATA, editedLogo?.cropperData);
        setValue(FORM_COMPANY_LOGO_IMAGE_EXTENSION, editedLogo?.imgExtension);
        setValue(FORM_COMPANY_LOGO_CROPPED_IMG, editedLogo?.croppedImg);
        setIsEdited(true);
        onClose();
    };
    const onDelete = () => {
        setBase64Logo(null);
        setSourceImgLogo(null);
        setEditedLogo(null);
        setIsEdited(true);
        setValue(FORM_COMPANY_LOGO_IMAGE, { removeLogo: true });
        onClose();
    };
    const handleCropChange = (base64, imgExtension, cropData) => {
        setEditedLogo({
            croppedImg: base64,
            imgExtension: imgExtension,
            sourceImg: sourceImgLogo ?? '',
            cropperData: cropData,
        });
    };
    useEffect(() => {
        if (isOpen) {
            setInitialCropData(editedLogo?.cropperData ?? contextCropData);
        }
    }, [isOpen]);
    return (_jsx(Modal, { isOpen: isOpen, onClose: onClose, spacing: 4, isModalSecond: isModalSecond, title: t('title'), closeButtonText: commonT('actions.cancel'), shouldUseOffsetTop: false, content: _jsx(FormProvider, { ...formData, children: _jsx(ImageCropper, { imageSrc: (sourceImgLogo || sourceImgLogoUrl) ?? '', mainLabel: t('companyLogo'), previewLabel: t('preview'), aspect: 2.4, onCropChange: handleCropChange, imgFormat: "jpeg", maxCropperBoxHeight: 264, maxPreviewBoxHeight: 100 }) }), footer: _jsx(FormFooter, { leftSideActions: [
                {
                    label: t('deleteLogo'),
                    variant: 'destructive',
                    onClick: onDelete,
                },
            ], submitAction: onUse, submitButtonText: t('useButton'), onClose: onClose, closeButtonText: t('cancelButton') }) }));
};
export default EditLogoModal;
