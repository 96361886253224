import React, { Component } from 'react';
import { connect } from 'react-redux';
import MarkModal from '../Modal/MarkModal';
import { closeModal } from '../../actions/ModalActions';
import SuccessMessage from '../../helpers/SuccessMessage';
import Translations from '../../helpers/Translations';
import Form from '../Form';
import { InputField } from '@profesia/adamui/components/common/input';
import Grid from '../Grid';
import Divider from '../Divider';
import NotificationMessage from '../NotificationMessage';
import Validation from '../../helpers/Validation';
import { saveCompanyCandidatesGdprExpirationSettings } from '../../actions/CandidatesGdprSettingsActions';

class CompanyCandidatesGdprExpirationSettingsModal extends Component {
    state = {
        candidatesGdprExpirationMonths:
            this.props.companySettingsModel.candidatesGdprExpirationMonths,
        errors: [],
    };

    componentDidUpdate = (prevProps) => {
        if (
            SuccessMessage.canShowSuccessMessage(
                prevProps,
                this.props,
                'isSavingCompanyCandidatesGdprExpirationSettings'
            ) === true
        ) {
            this.props.closeModal();
        }
    };

    changeCandidatesGdprExpirationMonths = (candidatesGdprExpirationMonths) => {
        candidatesGdprExpirationMonths = candidatesGdprExpirationMonths.substr(0, 3);
        candidatesGdprExpirationMonths = isNaN(parseInt(candidatesGdprExpirationMonths))
            ? ''
            : parseInt(candidatesGdprExpirationMonths);

        this.setState({
            candidatesGdprExpirationMonths,
        });
    };

    saveCompanyCandidatesGdprExpirationSettings = () => {
        if (
            isNaN(parseInt(this.state.candidatesGdprExpirationMonths)) === false &&
            parseInt(this.state.candidatesGdprExpirationMonths) >= 1
        ) {
            this.setState({
                errors: [],
            });

            this.props.saveCompanyCandidatesGdprExpirationSettings(
                this.props.companySettingsModel.loggedUser.companyId,
                this.state.candidatesGdprExpirationMonths
            );
        } else {
            this.setState({
                errors: ['candidatesGdprExpirationMonths'],
            });
        }
    };

    render() {
        const { isOpen, isSavingCompanyCandidatesGdprExpirationSettings } = this.props;

        return (
            <MarkModal
                title={Translations.getStatic('anonymizationSettings')}
                isOpen={isOpen}
                closeModal={this.props.closeModal}
                isLoadingData={false}
                actionButton={{
                    text: Translations.getStatic('save'),
                    disabled: isSavingCompanyCandidatesGdprExpirationSettings,
                    loading: isSavingCompanyCandidatesGdprExpirationSettings,
                    onClick: this.saveCompanyCandidatesGdprExpirationSettings,
                }}
                content={
                    <Form>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <NotificationMessage
                                        title={Translations.getStatic('anonymization')}
                                        description={Translations.getStatic(
                                            'automaticAnonymizationSettingsInfo'
                                        )}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className="horizontal-row">
                                <Grid.Column width={8} verticalAlign="middle">
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <Divider hidden />
                                                <div className="field">
                                                    <Grid>
                                                        <Grid.Row>
                                                            <Grid.Column>
                                                                <label htmlFor="candidatesGdprExpirationMonths">
                                                                    {Translations.getStatic(
                                                                        'anonymizeAfter'
                                                                    )}
                                                                </label>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid.Column width={6}>
                                                                <InputField
                                                                    name="candidatesGdprExpirationMonths"
                                                                    value={
                                                                        this.state
                                                                            .candidatesGdprExpirationMonths
                                                                    }
                                                                    error={
                                                                        this.state.errors.includes(
                                                                            'candidatesGdprExpirationMonths'
                                                                        ) === true
                                                                    }
                                                                    onChange={(e) =>
                                                                        this.changeCandidatesGdprExpirationMonths(
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                />
                                                            </Grid.Column>
                                                            <Grid.Column
                                                                width={10}
                                                                verticalAlign="middle"
                                                            >
                                                                {Translations.countDependantStatic(
                                                                    this.state
                                                                        .candidatesGdprExpirationMonths,
                                                                    [
                                                                        'monthLocative',
                                                                        'monthsLocative',
                                                                        'monthsLocative',
                                                                    ],
                                                                    false
                                                                )}
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>

                                        {this.state.errors.includes(
                                            'candidatesGdprExpirationMonths'
                                        ) === true && (
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <div className="prompt label">
                                                        {Validation.getMessage('greaterThan0')}
                                                    </div>
                                                    <Divider hidden />
                                                </Grid.Column>
                                            </Grid.Row>
                                        )}
                                    </Grid>
                                </Grid.Column>
                                <Grid.Column width={8} />
                            </Grid.Row>
                        </Grid>
                    </Form>
                }
            />
        );
    }
}

const mapStateToProps = (state) => ({
    isOpen: state.modalLogic.isOpen,
    companySettingsModel: state.companySettingsModel,
    isSavingCompanyCandidatesGdprExpirationSettings:
        state.isSavingCompanyCandidatesGdprExpirationSettings,
});

const mapDispatchToProps = {
    closeModal,
    saveCompanyCandidatesGdprExpirationSettings,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyCandidatesGdprExpirationSettingsModal);
