import React from 'react';
import { connect } from 'react-redux';
import WithRouterProp from '../../helpers/WithRouterProp';
import WebSocketWatcher from '../../helpers/WebSocketWatcher';
import addNotification from 'react-push-notification';
import Translations from '../../helpers/Translations';
import Url from '../../helpers/Url';
import { addNotificationMessage } from '../../actions/NotificationsCenterActions';

class ReceivedEmailWebSocket extends React.Component {
    state = {};

    isSupported = () =>
        'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;

    componentDidMount() {
        if (this.state.subscription !== undefined) {
            this.unsubscribe(this.state.subscription);
        }

        const subscription = WebSocketWatcher.subscribe(
            this.props.mercurePublishUrl,
            WebSocketWatcher.buildTopic(WebSocketWatcher.TOPIC_EMAIL_RECEIVED, {
                userId: this.props.userId,
            }),
            (data) => {
                const candidateUrl = Url.build('candidates', {
                    job: data.message.job.id,
                    candidate: data.message.candidate.id,
                    tab: 'inbox',
                });

                if (this.isSupported() === true) {
                    addNotification({
                        title: Translations.getStatic('mainTitle'),
                        message:
                            Translations.getStatic('message') +
                            ' ' +
                            Translations.getStatic('from').toLowerCase() +
                            ': ' +
                            data.message.candidate.candidateName +
                            '\r\n' +
                            data.message.subject,
                        native: true,
                        duration: 30000,
                        silent: false,
                        onClick: () => {
                            window.focus();
                            this.props.navigate(candidateUrl);
                        },
                    });
                }

                this.props.addNotificationMessage(data.message);
            }
        );

        this.setState({
            subscription,
        });

        window.addEventListener('beforeunload', () => this.unsubscribe(subscription));
    }

    componentWillUnmount() {
        this.unsubscribe(this.state.subscription);
        window.removeEventListener('beforeunload', () => this.unsubscribe(this.state.subscription));
    }

    unsubscribe = (subscription) => {
        WebSocketWatcher.unsubscribe(subscription);
    };

    render() {
        return this.props.children || null;
    }
}

const mapStateToProps = (state) => ({
    loginManagerModel: state.loginManagerModel,
    jobModel: state.jobModel,
    candidateModel: state.candidateModel,
});

const mapDispatchToProps = {
    addNotificationMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(WithRouterProp(ReceivedEmailWebSocket));
