import React, { Component } from 'react';
import { connect } from 'react-redux';
import DetailCandidateNoModal from '../Modals/DetailCandidateNoModal';
import DetailAnonymizedCandidateNoModal from '../Modals/DetailAnonymizedCandidateNoModal';
import { closeNoModal } from '../../actions/ModalActions';
import GoogleAnalytics from '../../helpers/GoogleAnalytics';
import Constants from '../../helpers/Constants';
import WithRouterProp from '../../helpers/WithRouterProp';
import Url from '../../helpers/Url';

class NoModalManager extends Component {
    state = {
        openingNoModalType: null,
        openingNoModalProps: null,
    };

    componentDidMount() {
        this.bodyElement = document.getElementsByTagName('body')[0];
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentNoModal.noModalIsOpen === false) {
            this.bodyElement.classList.remove('changeNoModal');
        }

        if (
            Url.getUrlParameter('action', this.props.location.pathname) !==
                Url.getUrlParameter('action', prevProps.location.pathname) &&
            this.props.currentNoModal.noModalIsOpen === true
        ) {
            this.props.closeNoModal();
        }

        if (
            prevProps.currentNoModal.noModalIsOpen === true &&
            this.props.currentNoModal.noModalIsOpen === true
        ) {
            const self = this;
            const closeWindowAnimationTime = 200;

            if (
                prevProps.currentNoModal.noModalProps !== undefined &&
                prevProps.currentNoModal.noModalProps.hasOwnProperty('candidateId') === true &&
                this.props.currentNoModal.noModalProps.hasOwnProperty('candidateId') === true
            ) {
                const prevCandidateId = prevProps.currentNoModal.noModalProps.candidateId;
                const currCandidateId = this.props.currentNoModal.noModalProps.candidateId;

                if (prevCandidateId !== currCandidateId) {
                    this.removeClasses();
                    this.bodyElement.classList.add('changeNoModal');
                    this.bodyElement.classList.add('slide');
                }
            }

            setTimeout(() => {
                self.bodyElement.classList.remove('slide');
                this.toggleDetailCandidateOpening(prevProps);
            }, closeWindowAnimationTime);
        } else {
            this.toggleDetailCandidateOpening(prevProps);
        }

        if (this.props.currentNoModalSecondIsOpen === true) {
            this.bodyElement.classList.add('overflowHidden_duplicate');
            this.bodyElement.classList.remove('overflowHiddenOut_duplicate');
            this.bodyElement.classList.remove('overflowHiddenOut');
        } else if (
            this.props.currentNoModalSecondIsOpen === false &&
            prevProps.currentNoModalSecondIsOpen === true
        ) {
            this.bodyElement.classList.add('overflowHiddenOut_duplicate');
            this.bodyElement.classList.remove('overflowHidden_duplicate');
        }

        if (this.props.currentModalIsOpen === true) {
            this.bodyElement.classList.add('overflowHidden');
            this.bodyElement.classList.remove('overflowHiddenOut');
            this.bodyElement.classList.remove('overflowHiddenOut_duplicate');
        } else if (
            prevProps.currentModalIsOpen === true &&
            this.props.currentModalIsOpen === false
        ) {
            this.bodyElement.classList.add('overflowHiddenOut');
            this.bodyElement.classList.remove('overflowHidden');
        }

        if (
            this.props.currentNoModalDashboardIsOpen === true &&
            this.props.currentNoModal.noModalIsOpen === true
        ) {
            this.bodyElement.classList.add('overflowHidden_dashboard');
            this.bodyElement.classList.remove('overflowHiddenOut_dashboard');
        } else if (
            prevProps.currentNoModal.noModalIsOpen === true &&
            this.props.currentNoModal.noModalIsOpen === false
        ) {
            this.bodyElement.classList.add('overflowHiddenOut_dashboard');
            this.bodyElement.classList.remove('overflowHidden_dashboard');
        }

        if (this.props.currentNoModal.noModalIsOpen === false) {
            this.bodyElement.classList.remove('overflowHiddenOut');
            this.bodyElement.classList.remove('overflowHiddenOut_duplicate');
        }

        if (this.props.currentNoModalDashboardIsOpen === false) {
            this.bodyElement.classList.remove('overflowHiddenOut_dashboard');
        }
    }

    componentWillUnmount() {
        this.bodyElement.classList.remove('overflowHidden');
    }

    removeClasses = () => {
        this.bodyElement.classList.remove('overflowHiddenOut');
        this.bodyElement.classList.remove('overflowHiddenOut_duplicate');
        this.bodyElement.classList.remove('overflowHiddenOut_dashboard');
    };

    toggleDetailCandidateOpening(prevProps) {
        const isOpenNewCandidate =
            this.props.currentNoModal.noModalIsOpen === true &&
            prevProps.currentNoModal.noModalProps !== undefined
                ? prevProps.currentNoModal.noModalProps.hasOwnProperty('candidateId') === true &&
                  this.props.currentNoModal.noModalProps.hasOwnProperty('candidateId') === true &&
                  prevProps.currentNoModal.noModalProps.candidateId !==
                      this.props.currentNoModal.noModalProps.candidateId
                : false;

        if (
            isOpenNewCandidate === true ||
            (prevProps.currentNoModal.noModalIsOpen === false &&
                this.props.currentNoModal.noModalIsOpen === true)
        ) {
            this.setState({
                openingNoModalType: this.props.currentNoModal.noModalType,
                openingNoModalProps: this.props.currentNoModal.noModalProps,
            });

            GoogleAnalytics.sendModalView(this.props.currentNoModal.noModalType);
        } else if (
            prevProps.currentNoModal.noModalIsOpen === true &&
            this.props.currentNoModal.noModalIsOpen === false
        ) {
            setTimeout(() => {
                this.setState({
                    openingNoModalType: null,
                    openingNoModalProps: null,
                });
            }, Constants.MODAL_ANIMATION_TIME_CLOSE);
        }
    }

    render() {
        const { openingNoModalType, openingNoModalProps } = this.state;

        const modalLookup = {
            DetailCandidateNoModal,
            DetailAnonymizedCandidateNoModal,
        };

        if (openingNoModalType !== null) {
            const ModalComponent = modalLookup[openingNoModalType];

            if (this.props.currentNoModalDashboardIsOpen === true) {
                return (
                    <div className="modal-wrapper modal-wrapper--open">
                        <ModalComponent {...openingNoModalProps} />
                    </div>
                );
            }

            return <ModalComponent {...openingNoModalProps} />;
        }

        return null;
    }
}

const mapStateToProps = (state) => ({
    currentNoModal: state.noModalLogic,
    currentNoModalSecondIsOpen: state.noModalSecondLogic.noModalSecondIsOpen,
    currentNoModalDashboardIsOpen: state.noModalDashboardLogic.noModalDashboardIsOpen,
    currentModalIsOpen: state.modalLogic.isOpen,
});

const mapDispatchToProps = {
    closeNoModal,
};

NoModalManager.defaultProps = {
    currentNoModal: {
        noModalIsOpen: false,
    },
};

export default connect(mapStateToProps, mapDispatchToProps)(WithRouterProp(NoModalManager));
