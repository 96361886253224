import {
    SAVE_USER_TESTING,
    SAVE_USER_TESTING_FAILURE,
    SAVE_USER_TESTING_SUCCESS,
    UPDATE_USER_TESTING,
    UPDATE_USER_TESTING_SUCCESS,
    UPDATE_USER_TESTING_FAILURE,
} from '../actions/UserTestingActions';

export const isSavingUserTesting = (state = false, action) => {
    switch (action.type) {
        case SAVE_USER_TESTING:
            return true;
        case SAVE_USER_TESTING_SUCCESS:
        case SAVE_USER_TESTING_FAILURE:
            return false;
        default:
            return state;
    }
};

export const isUpdatingUserTesting = (state = false, action) => {
    switch (action.type) {
        case UPDATE_USER_TESTING:
            return true;
        case UPDATE_USER_TESTING_SUCCESS:
        case UPDATE_USER_TESTING_FAILURE:
            return false;
        default:
            return state;
    }
};

const defaultState = {
    id: null,
    isSuccess: false,
};

export const userTestingModel = (state = defaultState, action) => {
    switch (action.type) {
        case SAVE_USER_TESTING:
        case SAVE_USER_TESTING_FAILURE:
            return defaultState;

        case SAVE_USER_TESTING_SUCCESS:
        case UPDATE_USER_TESTING_SUCCESS:
            return {
                userTesting: action.payload.userTesting,
                isSuccess: true,
            };

        case UPDATE_USER_TESTING:
            return {
                ...state,
                isSuccess: false,
            };

        case UPDATE_USER_TESTING_FAILURE:
            return defaultState;

        default:
            return state;
    }
};
