import { CommonObjectType } from '../../interfaces/CommonTypes';

class SuccessActionStatus {
    static isActionSuccessful(
        propsBefore: CommonObjectType,
        propsAfter: CommonObjectType
    ): boolean {
        return propsBefore.actionSuccessStatus === false && propsAfter.actionSuccessStatus === true;
    }
}

export default SuccessActionStatus;
