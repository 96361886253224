import {
    REQUEST_JOB_QUESTIONNAIRE_RESULTS_MODEL,
    RECEIVE_JOB_QUESTIONNAIRE_RESULTS_MODEL,
} from '../actions/JobQuestionnaireResultsActions';

export const jobQuestionnaireResultsModel = (state = {}, action) => {
    switch (action.type) {
        case RECEIVE_JOB_QUESTIONNAIRE_RESULTS_MODEL:
            return {
                ...state,
                ...action.payload,
            };

        default:
            return state;
    }
};

export const isFetchingJobQuestionnaireResultsModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_JOB_QUESTIONNAIRE_RESULTS_MODEL:
            return true;

        case RECEIVE_JOB_QUESTIONNAIRE_RESULTS_MODEL:
            return false;

        default:
            return state;
    }
};
