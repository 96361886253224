import React from 'react';
import { connect } from 'react-redux';
import { SkeletonRectangular } from '@profesia/adamui/components/common/skeleton';
import { Stack } from '@mui/material';

const NewJobDetailPositionSkeleton = ({ displaySize }) => (
    <div className="list">
        <div className="list__data">
            <div className="ui grid">
                <div className={(displaySize.isMobile ? 'sixteen' : 'ten') + ' wide column'}>
                    <div className="ui grid data-block data-block_primary">
                        <Stack flex={1} mt={3.5} mb={0.75}>
                            <SkeletonRectangular width={218} height={10} borderRadius={26} />
                        </Stack>
                        <div className="row">
                            <div className="column">
                                <div className="data-block__content" style={{ padding: '18px' }}>
                                    <Stack
                                        direction={displaySize.isMobileProfesia ? 'column' : 'row'}
                                        spacing={2}
                                        justifyContent="space-between"
                                    >
                                        <Stack
                                            flex={1}
                                            spacing={2}
                                            alignItems="center"
                                            direction={
                                                displaySize.isMobileProfesia ? 'row' : 'column'
                                            }
                                        >
                                            <SkeletonRectangular
                                                width={36}
                                                height={16}
                                                borderRadius={26}
                                            />
                                            <SkeletonRectangular
                                                width={88}
                                                height={8}
                                                borderRadius={26}
                                            />
                                        </Stack>
                                        <Stack
                                            flex={1}
                                            spacing={2}
                                            alignItems="center"
                                            direction={
                                                displaySize.isMobileProfesia ? 'row' : 'column'
                                            }
                                        >
                                            <SkeletonRectangular
                                                width={36}
                                                height={16}
                                                borderRadius={26}
                                            />
                                            <SkeletonRectangular
                                                width={88}
                                                height={8}
                                                borderRadius={26}
                                            />
                                        </Stack>
                                        <Stack
                                            flex={1}
                                            spacing={2}
                                            alignItems="center"
                                            direction={
                                                displaySize.isMobileProfesia ? 'row' : 'column'
                                            }
                                        >
                                            <SkeletonRectangular
                                                width={36}
                                                height={16}
                                                borderRadius={26}
                                            />
                                            <SkeletonRectangular
                                                width={88}
                                                height={8}
                                                borderRadius={26}
                                            />
                                        </Stack>
                                        <Stack
                                            flex={1}
                                            spacing={2}
                                            alignItems="center"
                                            direction={
                                                displaySize.isMobileProfesia ? 'row' : 'column'
                                            }
                                        >
                                            <SkeletonRectangular
                                                width={36}
                                                height={16}
                                                borderRadius={26}
                                            />
                                            <SkeletonRectangular
                                                width={88}
                                                height={8}
                                                borderRadius={26}
                                            />
                                        </Stack>
                                        <Stack
                                            flex={1}
                                            spacing={2}
                                            alignItems="center"
                                            direction={
                                                displaySize.isMobileProfesia ? 'row' : 'column'
                                            }
                                        >
                                            <SkeletonRectangular
                                                width={36}
                                                height={16}
                                                borderRadius={26}
                                            />
                                            <SkeletonRectangular
                                                width={88}
                                                height={8}
                                                borderRadius={26}
                                            />
                                        </Stack>
                                        <Stack
                                            flex={1}
                                            spacing={2}
                                            alignItems="center"
                                            direction={
                                                displaySize.isMobileProfesia ? 'row' : 'column'
                                            }
                                        >
                                            <SkeletonRectangular
                                                width={36}
                                                height={16}
                                                borderRadius={26}
                                            />
                                            <SkeletonRectangular
                                                width={88}
                                                height={8}
                                                borderRadius={26}
                                            />
                                        </Stack>
                                    </Stack>
                                </div>
                            </div>
                        </div>
                        <Stack flex={1} mt={1.75} mb={0.75}>
                            <SkeletonRectangular width={218} height={10} borderRadius={26} />
                        </Stack>
                        <div className="row">
                            <div className="column">
                                <div className="data-block__content">
                                    <Stack flex={1} spacing={4} mt={0.5}>
                                        <Stack flex={1} spacing={1.5} mb={2}>
                                            <SkeletonRectangular
                                                width={244}
                                                height={18}
                                                borderRadius={26}
                                            />
                                            <SkeletonRectangular
                                                width={116}
                                                height={10}
                                                borderRadius={26}
                                            />
                                        </Stack>

                                        <Stack
                                            direction={
                                                displaySize.isMobileProfesia ? 'column' : 'row'
                                            }
                                            spacing={2}
                                            pb={2}
                                            justifyContent="space-between"
                                        >
                                            <Stack flex={1} spacing={1.5}>
                                                <Stack spacing={1.5} mb={1.5}>
                                                    <SkeletonRectangular
                                                        width={137}
                                                        height={8}
                                                        borderRadius={26}
                                                    />
                                                    <SkeletonRectangular
                                                        width={306}
                                                        height={8}
                                                        borderRadius={26}
                                                    />
                                                </Stack>
                                                <Stack spacing={1.5}>
                                                    <SkeletonRectangular
                                                        width={137}
                                                        height={8}
                                                        borderRadius={26}
                                                    />
                                                    <SkeletonRectangular
                                                        width={306}
                                                        height={8}
                                                        borderRadius={26}
                                                    />
                                                </Stack>
                                            </Stack>
                                            <Stack flex={1} spacing={1.5}>
                                                <Stack spacing={1.5} mb={1.5}>
                                                    <SkeletonRectangular
                                                        width={137}
                                                        height={8}
                                                        borderRadius={26}
                                                    />
                                                    <SkeletonRectangular
                                                        width={306}
                                                        height={8}
                                                        borderRadius={26}
                                                    />
                                                </Stack>
                                                <Stack spacing={1.5}>
                                                    <SkeletonRectangular
                                                        width={137}
                                                        height={8}
                                                        borderRadius={26}
                                                    />
                                                    <SkeletonRectangular
                                                        width={306}
                                                        height={8}
                                                        borderRadius={26}
                                                    />
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                        <Stack flex={1} spacing={2}>
                                            <SkeletonRectangular
                                                width={110}
                                                height={18}
                                                borderRadius={26}
                                            />
                                            <SkeletonRectangular height={8} borderRadius={26} />
                                            <SkeletonRectangular height={8} borderRadius={26} />
                                            <SkeletonRectangular
                                                width={334}
                                                height={8}
                                                borderRadius={26}
                                            />
                                        </Stack>
                                        <Stack flex={1} spacing={2}>
                                            <SkeletonRectangular
                                                width={110}
                                                height={18}
                                                borderRadius={26}
                                            />
                                            <SkeletonRectangular height={8} borderRadius={26} />
                                            <SkeletonRectangular height={8} borderRadius={26} />
                                            <SkeletonRectangular height={8} borderRadius={26} />
                                            <SkeletonRectangular
                                                width={334}
                                                height={8}
                                                borderRadius={26}
                                            />
                                        </Stack>
                                        <Stack flex={1} spacing={2}>
                                            <SkeletonRectangular
                                                width={110}
                                                height={18}
                                                borderRadius={26}
                                            />
                                            <SkeletonRectangular height={8} borderRadius={26} />
                                            <SkeletonRectangular height={8} borderRadius={26} />
                                            <SkeletonRectangular
                                                width={334}
                                                height={8}
                                                borderRadius={26}
                                            />
                                        </Stack>
                                    </Stack>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const mapStateToProps = (state) => ({
    displaySize: state.displaySize,
});

export default connect(mapStateToProps, null)(NewJobDetailPositionSkeleton);
