import {
    REQUEST_SUBSCRIPTIONS_MODEL,
    RECEIVE_SUBSCRIPTIONS_MODEL,
    CHANGE_SUBSCRIPTION,
    CHANGE_SUBSCRIPTION_SUCCESS,
    CREATE_NEW_SUBSCRIPTION,
    CREATE_NEW_SUBSCRIPTION_SUCCESS,
} from '../actions/SubscriptionActions';

export const subscriptionsModel = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_SUBSCRIPTIONS_MODEL:
            return {};

        case RECEIVE_SUBSCRIPTIONS_MODEL:
        case CREATE_NEW_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };

        case CHANGE_SUBSCRIPTION:
            return {
                ...state,
                subscriptions: state.subscriptions.map((subscription) =>
                    subscription.id === action.payload.id
                        ? {
                              ...subscription,
                              ...action.payload,
                          }
                        : subscription
                ),
            };

        case CHANGE_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                subscriptions: state.subscriptions.map((subscription) =>
                    subscription.id === action.payload.subscription.id
                        ? action.payload.subscription
                        : subscription
                ),
            };

        default:
            return state;
    }
};

export const isFetchingSubscriptionsModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_SUBSCRIPTIONS_MODEL:
            return true;

        case RECEIVE_SUBSCRIPTIONS_MODEL:
            return false;

        default:
            return state;
    }
};
