import React, { Fragment } from 'react';
import 'react-pdf/src/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Document, Page, pdfjs } from 'react-pdf';
import PropTypes from 'prop-types';
import { InputField } from '@profesia/adamui/components/common/input';
import Button from '../Button';
import Divider from '../Divider';
import Icon from '../Icon';
import md5 from 'md5';
import Translations from '../../helpers/Translations';
import Version from '../../helpers/Version';
import Constants from '../../helpers/Constants';
import Popup from '../Popup';

pdfjs.GlobalWorkerOptions.workerSrc =
    '/mark/build/js/pdf.worker.' + Version.getJsVersionHash() + '.js';
pdfjs.GlobalWorkerOptions.cMapUrl = '/mark/build/js/pdf_viewer/cmaps/';
pdfjs.GlobalWorkerOptions.cMapPacked = true;

class CvViewer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            numPages: 0,
            pageStatuses: {},
            imgStatus: 'visible',
            showPrintIframe: false,
            documentWithPassword: {
                hasPassword: false,
                password: '',
            },
        };
    }

    onDocumentLoad = (pdfDocument) => {
        const numPages = pdfDocument._pdfInfo.numPages;

        const pageStatuses = {};

        for (let i = 1; i <= numPages; i++) {
            pageStatuses[i] = 'hidden';
        }

        this.setState({
            numPages: numPages > this.props.showPagesCount ? this.props.showPagesCount : numPages,
            pageStatuses: pageStatuses,
            documentWithPassword: {
                hasPassword: false,
                password: '',
            },
        });
    };

    onPageRender = (page) => {
        let pageStatuses = this.state.pageStatuses;
        pageStatuses[page] = 'visible';

        let imgVisible = false;

        for (let page in pageStatuses) {
            if (pageStatuses.hasOwnProperty(page)) {
                if (pageStatuses[page] === 'hidden') {
                    imgVisible = true;
                }
            }
        }

        this.setState({
            pageStatuses: pageStatuses,
            imgStatus: imgVisible === true ? 'visible' : 'hidden',
        });
    };

    printIframe = () => {
        const pdfIframe = document.getElementById(this.getIframeId());

        try {
            pdfIframe.contentWindow.focus();
            pdfIframe.contentWindow.print();
        } catch (e) {
            window.open(window.location.origin + this.props.file.url, '_blank');
        }
    };

    printTrigger = () => {
        const pdfIframe = document.getElementById(this.getIframeId());

        if (pdfIframe !== null) {
            this.printIframe();
        }

        this.setState({
            showPrintIframe: true,
        });
    };

    replaceDocumentForPasswordInput = (callback, reason) => {
        this.setState({
            documentWithPassword: {
                ...this.state.documentWithPassword,
                hasPassword: true,
                callback,
                reason,
                incorrect: reason === Constants.INCORRECT_PASSWORD_IN_CV_VIEWER_ID,
            },
        });
    };

    fillPassword = (password) => {
        this.setState({
            documentWithPassword: {
                ...this.state.documentWithPassword,
                password,
            },
        });
    };

    confirmPassword = () => {
        this.state.documentWithPassword.callback(this.state.documentWithPassword.password);
    };

    getIframeId() {
        return md5('iFramePdfPreview_' + this.props.file.url);
    }

    render() {
        const pages = [];

        for (let i = 1; i <= this.state.numPages; i++) {
            pages.push(
                <Fragment key={i}>
                    <Page
                        pageNumber={i}
                        width={616}
                        onRenderSuccess={() => this.onPageRender(i)}
                        className={this.state.pageStatuses[i]}
                    />
                    <Divider />
                </Fragment>
            );
        }

        const imgPreview =
            this.props.file.hasOwnProperty('png') === true && this.props.file.png !== null ? (
                <img src={this.props.file.png} className={this.state.imgStatus} alt={''} />
            ) : null;

        return (
            <div className={(this.state.numPages > 0 ? 'loaded ' : '') + 'markPdfViewer'}>
                {imgPreview}

                {this.state.documentWithPassword.hasPassword === false && (
                    <div className="markPdfViewer-toolbar">
                        {this.props.file.hasOwnProperty('downloadUrl') === true ? (
                            <Popup
                                content={Translations.getStatic('download')}
                                trigger={
                                    <Button
                                        as="a"
                                        target="_blank"
                                        href={this.props.file.downloadUrl}
                                    >
                                        <Icon className="icon-print-download" />
                                    </Button>
                                }
                            />
                        ) : null}

                        <Popup
                            content={Translations.getStatic('print')}
                            trigger={
                                <Button onClick={this.printTrigger}>
                                    <Icon className="icon-print" />
                                </Button>
                            }
                        />

                        {this.props.file.hasOwnProperty('url') === true ? (
                            <Popup
                                position="bottom"
                                content={Translations.getStatic('maximize')}
                                trigger={
                                    <Button as="a" target="_blank" href={this.props.file.url}>
                                        <Icon className="icon-print-maximize" />
                                    </Button>
                                }
                            />
                        ) : null}

                        {this.state.showPrintIframe === true && (
                            <iframe
                                onLoad={() => this.printIframe()}
                                className="iFramePdfPreview"
                                id={this.getIframeId()}
                                src={window.location.origin + this.props.file.url}
                            />
                        )}
                    </div>
                )}

                <Document
                    file={this.props.file.url}
                    onLoadSuccess={(pdfDocument) => this.onDocumentLoad(pdfDocument)}
                    onPassword={(callback, reason) =>
                        this.replaceDocumentForPasswordInput(callback, reason)
                    }
                    className={
                        this.state.documentWithPassword.hasPassword === true ? 'password' : ''
                    }
                >
                    {pages}
                </Document>

                {this.state.documentWithPassword.hasPassword === true && (
                    <div className={'markPdfViewer-password-input'}>
                        <div className="flex align end">
                            <div className="form-group">
                                <label htmlFor="password">
                                    {Translations.getStatic('attachmentPasswordLabel')}
                                </label>
                                <InputField
                                    maxLength={128}
                                    error={this.state.documentWithPassword.incorrect === true}
                                    value={this.state.documentWithPassword.password}
                                    type="password"
                                    placeholder={Translations.getStatic('password')}
                                    onChange={(e) => this.fillPassword(e.target.value)}
                                />
                            </div>
                            <Button
                                className="secondary-button small"
                                onClick={this.confirmPassword}
                            >
                                {Translations.getStatic('unlock')}
                            </Button>
                        </div>
                        {this.state.documentWithPassword.incorrect === true && (
                            <p className="txt-red">
                                {Translations.getStatic('incorrectPasswordFilled')}
                            </p>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

CvViewer.propTypes = {
    file: PropTypes.shape({
        png: PropTypes.string,
        downloadUrl: PropTypes.string,
        url: PropTypes.string,
    }).isRequired,
    showPagesCount: PropTypes.number,
};

CvViewer.defaultProps = {
    showPagesCount: 100,
};

export default CvViewer;
