import React from 'react';
import { connect } from 'react-redux';
import Url from '../../helpers/Url';
import JobChangeWebSocket from './JobChangeWebSocket';
import { requestJobModel } from '../../actions/JobDetailActions';
import {
    requestCandidateEvents,
    requestCandidateHistory,
    requestCandidateInbox,
    requestCandidateModel,
} from '../../actions/CandidateActions';
import ReceivedEmailWebSocket from './ReceivedEmailWebSocket';
import loginManagerModelSkeleton from '../../skeletons/models/loginManagerModelSkeleton';
import Skeletons from '../../helpers/Skeletons';

class WebSocketsListener extends React.Component {
    updateCandidate = (candidateId) => {
        this.props.requestCandidateModel(candidateId);
        this.props.requestCandidateEvents(candidateId);
        this.props.requestCandidateHistory(candidateId);
        this.props.requestCandidateInbox(candidateId);
    };

    render() {
        const action = Url.getUrlParameter('action');
        const { loginManagerModel, jobModel, candidateModel } = this.props;
        const { job } = jobModel;

        const listeners = [];

        if (['pipeline', 'candidates'].includes(action) === true && job !== undefined) {
            listeners.push(
                <JobChangeWebSocket
                    job={job}
                    mercurePublishUrl={loginManagerModel.mercurePublishUrl}
                    candidateModel={candidateModel}
                    onRequestJobModel={this.props.requestJobModel}
                    onUpdateCandidate={this.updateCandidate}
                />
            );
        }

        if (
            loginManagerModel.hasOwnProperty('user') === true &&
            loginManagerModel.hasCompanyActiveInbox === true
        ) {
            listeners.push(
                <ReceivedEmailWebSocket
                    userId={loginManagerModel.user.id}
                    mercurePublishUrl={loginManagerModel.mercurePublishUrl}
                />
            );
        }

        return listeners.map((listener, i) => <span key={i}>{listener}</span>);
    }
}

const mapStateToProps = (state) => ({
    loginManagerModel: Skeletons.getData(state.loginManagerModel, loginManagerModelSkeleton),
    jobModel: state.jobModel,
    candidateModel: state.candidateModel,
});

const mapDispatchToProps = {
    requestJobModel,
    requestCandidateModel,
    requestCandidateEvents,
    requestCandidateHistory,
    requestCandidateInbox,
};

export default connect(mapStateToProps, mapDispatchToProps)(WebSocketsListener);
