import { CommonObjectType } from '../../interfaces/CommonTypes';

class Objects {
    static deleteProperty(object: CommonObjectType, property: string = ''): CommonObjectType {
        if (!object.hasOwnProperty(property)) {
            return object;
        }

        const { [property]: propertyToDelete, ...objectWithoutProperty } = object;

        return objectWithoutProperty;
    }

    static deleteProperties(object: CommonObjectType, properties: string[] = []): CommonObjectType {
        properties.forEach((property) => (object = this.deleteProperty(object, property)));

        return object;
    }
}

export default Objects;
