import React from 'react';
import { connect } from 'react-redux';
import Translations from '../../helpers/Translations';
import MarkDialog from '../Modal/MarkDialog';
import { closeCopyIntoTalentPoolDialog } from '../../actions/DialogActions';
import CandidatesReducer from '../../helpers/generalReducers/CandidatesReducer';
import { copyCandidateIntoTalentPool } from '../../actions/ActionWithCandidateActions';
import SuccessMessage from '../../helpers/SuccessMessage';

class CopyIntoTalentPoolDialog extends React.Component {
    componentDidUpdate = (prevProps) => {
        if (
            SuccessMessage.canShowSuccessMessage(prevProps, this.props, 'isCopyingCandidates') &&
            this.props.candidateActionModel.hasOwnProperty('isSuccess') === true &&
            this.props.candidateActionModel.isSuccess === true
        ) {
            this.props.closeCopyIntoTalentPoolDialog();
        }
    };

    copyIntoTalentPool = () => {
        const candidateId = this.props.copyIntoTalentPoolDialogOpen.dialogProps.candidateId;

        const actionData = new FormData();

        actionData.append('candidateId', candidateId);

        this.props.copyCandidateIntoTalentPool(
            actionData,
            candidateId,
            CandidatesReducer.getReducerType()
        );
    };

    render() {
        const { isCopyingCandidates } = this.props;

        return (
            this.props.copyIntoTalentPoolDialogOpen.open === true && (
                <MarkDialog
                    closeOnDimmerClick={false}
                    message={{
                        iconName: 'icon-message-favourites',
                        title: Translations.getStatic('copyIntoTalentPool'),
                        infoLines: [Translations.getStatic('copyIntoTalentPoolDialogText')],
                    }}
                    actions={[
                        {
                            type: 'positive',
                            title: Translations.getStatic('copy'),
                            action: this.copyIntoTalentPool,
                            loading: isCopyingCandidates,
                        },
                        {
                            title: Translations.getStatic('cancel'),
                            action: this.props.closeCopyIntoTalentPoolDialog,
                            disabled: isCopyingCandidates,
                        },
                    ]}
                />
            )
        );
    }
}

const mapStateToProps = (state) => ({
    copyIntoTalentPoolDialogOpen: state.copyIntoTalentPoolDialogOpen,
    isCopyingCandidates: state.isCopyingCandidates,
    candidateActionModel: state.candidateActionModel,
});

const mapDispatchToProps = {
    closeCopyIntoTalentPoolDialog,
    copyCandidateIntoTalentPool,
};

export default connect(mapStateToProps, mapDispatchToProps)(CopyIntoTalentPoolDialog);
