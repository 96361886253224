import React from 'react';
import Button from '../Button';
import Grid from '../Grid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Arrays from '../../helpers/Arrays';
import CandidatesListColumnsOrderHelper from '../../helpers/components/CandidatesListColumnsOrderHelper';

class ColumnsOrderEditor extends React.Component {
    onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const columns = Arrays.reorderArray(
            this.props.columns,
            result.source.index,
            result.destination.index
        );

        this.props.setColumnsOrder(columns);
    };

    reorderStages = (fromIndex, toIndex) => {
        const columns = Arrays.reorderArray(this.props.columns, fromIndex, toIndex);

        this.props.setColumnsOrder(columns);
    };

    render = () => {
        const { columns } = this.props;

        return (
            <Grid>
                <Grid.Column className="padding-top_none padding-left_none padding-right_none">
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {columns.map((column, index) => {
                                        const reorderButtons = [];

                                        reorderButtons.push(
                                            index > 0 ? (
                                                <Button
                                                    className="small"
                                                    key={'up'}
                                                    icon={{ className: 'icon-up_dark small' }}
                                                    onClick={() =>
                                                        this.reorderStages(index, index - 1)
                                                    }
                                                />
                                            ) : (
                                                <Button
                                                    className="small"
                                                    key={'up'}
                                                    icon={{ className: 'icon-up_dark small' }}
                                                    disabled
                                                />
                                            )
                                        );

                                        reorderButtons.push(
                                            index < columns.length - 1 ? (
                                                <Button
                                                    className="small"
                                                    key={'down'}
                                                    icon={{ className: 'icon-down_dark small' }}
                                                    onClick={() =>
                                                        this.reorderStages(index, index + 1)
                                                    }
                                                />
                                            ) : (
                                                <Button
                                                    className="small"
                                                    key={'down'}
                                                    icon={{ className: 'icon-down_dark small' }}
                                                    disabled
                                                />
                                            )
                                        );

                                        return (
                                            <Draggable
                                                key={index}
                                                draggableId={'' + (index + 1)}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <Grid>
                                                            <Grid.Row columns={2}>
                                                                <Grid.Column
                                                                    width={14}
                                                                    verticalAlign="middle"
                                                                >
                                                                    <div className="segment  stage">
                                                                        <div className="vertical-align">
                                                                            <h4 className="txt-regular">
                                                                                {CandidatesListColumnsOrderHelper.getColumnName(
                                                                                    column
                                                                                )}
                                                                            </h4>
                                                                        </div>
                                                                    </div>
                                                                </Grid.Column>
                                                                <Grid.Column
                                                                    width={2}
                                                                    verticalAlign="middle"
                                                                    className="padding-left_none"
                                                                >
                                                                    {reorderButtons}
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })}

                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Grid.Column>
            </Grid>
        );
    };
}

export default ColumnsOrderEditor;
