export const REQUEST_CONNECTED_USERS_MODEL = 'REQUEST_CONNECTED_USERS_MODEL';
export const RECEIVE_CONNECTED_USERS_MODEL = 'RECEIVE_CONNECTED_USERS_MODEL';
export const CONNECTED_USERS_ADD = 'CONNECTED_USERS_ADD';
export const CONNECTED_USERS_ADD_SUCCESS = 'CONNECTED_USERS_ADD_SUCCESS';
export const CONNECTED_USERS_ADD_FAILURE = 'CONNECTED_USERS_ADD_FAILURE';
export const CONNECTED_USERS_DELETE = 'CONNECTED_USERS_DELETE';
export const CONNECTED_USERS_DELETE_SUCCESS = 'CONNECTED_USERS_DELETE_SUCCESS';
export const CONNECTED_USERS_DELETE_FAILURE = 'CONNECTED_USERS_DELETE_FAILURE';

export const requestConnectedUsersModel = () => ({
    type: REQUEST_CONNECTED_USERS_MODEL,

    meta: {
        requestRoute: 'v1/admin/model/connected-users',
        onRequestSuccess: RECEIVE_CONNECTED_USERS_MODEL,
    },
});

export const connectedUsersDelete = (connectedUsersId) => ({
    type: CONNECTED_USERS_DELETE,

    payload: {
        connectedUsersId,
    },

    meta: {
        requestMethod: 'POST',
        requestRoute: 'v1/admin/connected-users/action/delete',

        requestBody: JSON.stringify({
            connectedUsersId,
        }),

        onRequestSuccess: CONNECTED_USERS_DELETE_SUCCESS,
        onRequestFailure: CONNECTED_USERS_DELETE_FAILURE,
    },
});

export const connectedUsersAdd = (userId, withUserId) => ({
    type: CONNECTED_USERS_ADD,

    payload: {
        userId,
        withUserId,
    },

    meta: {
        requestMethod: 'POST',
        requestRoute: 'v1/admin/connected-users/action/add',

        requestBody: JSON.stringify({
            userId,
            withUserId,
        }),

        onRequestSuccess: CONNECTED_USERS_ADD_SUCCESS,
        onRequestFailure: CONNECTED_USERS_ADD_FAILURE,
    },
});
