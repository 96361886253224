export const palette = {
    primaryOrange: {
        main: '#FFB100',
    },
    primaryBlue: {
        main: '#2774F0',
    },
    primaryBlueLight: {
        main: '#EEF4FE',
    },
    primaryNavy: {
        main: '#212C3D',
    },
    primaryGray: {
        main: '#2D2D2B',
    },
    secondaryGreen: {
        main: '#23AD4B',
    },
    secondaryRed: {
        main: '#C23E3E',
    },
    secondaryTiger: {
        main: '#EA6334',
    },
    secondaryViolet: {
        main: '#8D34EA',
    },
    neutralDark0: {
        main: '#2D3B53',
    },
    neutralDark1: {
        main: '#384B69',
    },
    neutralDark2: {
        main: '#445B7F',
    },
    neutralDark3: {
        main: '#506B95',
    },
    neutralDark4: {
        main: '#5673A0',
    },
    white: {
        main: '#FFFFFF',
    },
    neutralLight0: {
        main: '#F6F6F6',
    },
    neutralLight1: {
        main: '#EEEFF1',
    },
    neutralLight2: {
        main: '#E9E9E9',
    },
    neutralLight3: {
        main: '#DADADA',
    },
    neutralLight4: {
        main: '#CCCCCC',
    },
    neutralLight5: {
        main: '#B4B4B4',
    },
    black0: {
        main: '#000000',
    },
    black1: {
        main: '#2A2A2A',
    },
    black2: {
        main: '#555555',
    },
    black3: {
        main: '#7F7F7F',
    },
    black4: {
        main: '#AAAAAA',
    },
    black5: {
        main: '#D4D4D4',
    },
    black6: {
        main: '#F6F6F6',
    },
    extendedOchre1: {
        main: '#E18D2B',
    },
    extendedOchre2: {
        main: '#F6973A',
    },
    extendedOchre3: {
        main: '#F5B63E',
    },
    extendedOchre4: {
        main: '#F7D18F',
    },
    extendedOchre5: {
        main: '#F5DEBB',
    },
    extendedBurgandy1: {
        main: '#B31E44',
    },
    extendedBurgandy2: {
        main: '#E43219',
    },
    extendedBurgandy3: {
        main: '#E0523E',
    },
    extendedBurgandy4: {
        main: '#F7C192',
    },
    extendedBurgandy5: {
        main: '#F7D8BE',
    },
    extendedRoyal1: {
        main: '#0D2860',
    },
    extendedRoyal2: {
        main: '#203E9A',
    },
    extendedRoyal3: {
        main: '#4B6DBD',
    },
    extendedRoyal4: {
        main: '#6C8CCC',
    },
    extendedRoyal5: {
        main: '#AAC2EF',
    },
    extendedPrussian1: {
        main: '#123F63',
    },
    extendedPrussian2: {
        main: '#3E6582',
    },
    extendedPrussian3: {
        main: '#66A3BC',
    },
    extendedPrussian4: {
        main: '#8ACCDD',
    },
    extendedPrussian5: {
        main: '#B7E1EA',
    },
    extendedGreen1: {
        main: '#269990',
    },
    extendedGreen2: {
        main: '#45ADA7',
    },
    extendedGreen3: {
        main: '#82DD8D',
    },
    extendedGreen4: {
        main: '#BCE796',
    },
    extendedGreen5: {
        main: '#D5F2B7',
    },
    extendedGreen6: {
        main: '#EAF9DB',
    },
    extendedGreen7: {
        main: '#149439',
    },
};
export const getColor = (color) => {
    if (!color) {
        console.warn('Color is undefined');
        return '';
    }
    return color.main || '';
};
