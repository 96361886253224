import {
    RECEIVE_SEND_EMAIL_MODEL,
    REQUEST_SEND_EMAIL_MODEL,
    UPDATE_EMAIL_TEMPLATE,
    SET_EMAIL_BCC,
    SET_EMAIL_RECIPIENTS,
    ADD_NEW_COLLEAGUE_CONTACT,
    ADD_NEW_COLLEAGUE_CONTACT_SUCCESS,
    ADD_NEW_COLLEAGUE_CONTACT_FAILURE,
    PREPARE_EMAIL_TO_PREVIEW,
    PREPARE_EMAIL_TO_PREVIEW_SUCCESS,
    REMOVE_COLLEAGUE_CONTACT,
    REMOVE_COLLEAGUE_CONTACT_SUCCESS,
    REMOVE_COLLEAGUE_CONTACT_FAILURE,
    SET_SEND_EMAIL_AT,
    SET_EMAIL_SENDER,
    GET_VIDEO_INTERVIEW_DATA,
    GET_VIDEO_INTERVIEW_DATA_SUCCESS,
    GET_VIDEO_INTERVIEW_DATA_FAILURE,
} from '../actions/SendEmailActions';
import WysiwygHelper from '../helpers/components/WysiwygHelper';
import { MODAL_OPEN } from '../actions/ModalActions';
import SendEmailHelper from '../helpers/components/SendEmailHelper';
import Constants from '../helpers/Constants';
import Translations from '../helpers/Translations';
import Storages from '../helpers/Storages';

export const isFetchingSendEmailModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_SEND_EMAIL_MODEL:
            return true;

        case RECEIVE_SEND_EMAIL_MODEL:
            return false;

        default:
            return state;
    }
};

export const isFetchingPreviewEmailModel = (state = false, action) => {
    switch (action.type) {
        case PREPARE_EMAIL_TO_PREVIEW:
            return true;
        case PREPARE_EMAIL_TO_PREVIEW_SUCCESS:
            return false;
        default:
            return state;
    }
};

export const isAddingNewColleagueContact = (state = false, action) => {
    switch (action.type) {
        case ADD_NEW_COLLEAGUE_CONTACT:
            return true;
        case ADD_NEW_COLLEAGUE_CONTACT_SUCCESS:
        case ADD_NEW_COLLEAGUE_CONTACT_FAILURE:
            return false;
        default:
            return state;
    }
};

export const isRemovingColleagueContact = (state = false, action) => {
    switch (action.type) {
        case REMOVE_COLLEAGUE_CONTACT:
            return true;
        case REMOVE_COLLEAGUE_CONTACT_SUCCESS:
        case REMOVE_COLLEAGUE_CONTACT_FAILURE:
            return false;
        default:
            return state;
    }
};

export const isGettingVideoInterviewData = (state = false, action) => {
    switch (action.type) {
        case GET_VIDEO_INTERVIEW_DATA:
            return true;
        case GET_VIDEO_INTERVIEW_DATA_SUCCESS:
        case GET_VIDEO_INTERVIEW_DATA_FAILURE:
            return false;
        default:
            return state;
    }
};

const defaultState = {
    recipients: [],
    bcc: [],
    sender: null,
    sendAt: {
        type: Constants.SEND_EMAIL_IMMEDIATELY,
        datetime: null,
    },
};

export const sendEmailModel = (state = defaultState, action) => {
    let colleaguesEmails = [];
    let text = '';
    let converted = '';

    switch (action.type) {
        case MODAL_OPEN:
            if (
                action.hasOwnProperty('payload') === true &&
                [
                    'SendEmailModal',
                    'CopyCandidateModal',
                    'ChangeStageOfCandidatesModal',
                    'ScheduleCandidateEventModal',
                    'RefuseCandidateWithEmailModal',
                    'SettingsPageEmailTemplateEditorModal',
                ].includes(action.payload.modalType) === true
            ) {
                return defaultState;
            }

            return state;

        case REQUEST_SEND_EMAIL_MODEL:
            return {
                ...state,
                recipients: action.payload.recipients,
                bcc: action.payload.bcc,
                sender: action.payload.sender,
            };

        case RECEIVE_SEND_EMAIL_MODEL:
            const { type, languageId, id } = action.payload.emailTemplateData;

            let subject = action.payload.emailTemplateData.subject;

            if (action.meta.requestPayload.messageHistory !== null) {
                subject = SendEmailHelper.prepareSubjectForEmailHistory(
                    action.meta.requestPayload.messageHistory.subject
                );
            } else if (
                action.meta.requestPayload.emailTemplateType ===
                    Constants.EMAIL_TEMPLATE_TYPE_COLLEAGUE_FORWARDING &&
                id === null
            ) {
                subject =
                    '<p>' + Translations.getStatic('newCandidateEmailForwardingSubject') + '</p>\n';
            }

            text = action.payload.emailTemplateData.text;

            if (
                action.meta.requestPayload.emailTemplateType ===
                    Constants.EMAIL_TEMPLATE_TYPE_COLLEAGUE_FORWARDING &&
                id === null &&
                action.meta.requestPayload.isMassForwarding === true &&
                Storages.getLocal('multipleForwardingAsSingleEmail', true) === true
            ) {
                text =
                    '<p>' +
                    WysiwygHelper.createVariableString(
                        Translations.getStatic('highlightTextareaVariableCandidatesList')
                    ) +
                    '</p>\n';
            }

            converted = WysiwygHelper.convertOldToNewText(text);

            colleaguesEmails = [];

            if (
                action.payload.hasOwnProperty('settings') === true &&
                action.payload.settings.hasOwnProperty('colleaguesEmails') === true &&
                action.payload.settings.hasOwnProperty('userData') === true
            ) {
                colleaguesEmails = action.payload.settings.colleaguesEmails || [];

                if (
                    action.hasOwnProperty('meta') === true &&
                    action.meta.hasOwnProperty('requestPayload') === true &&
                    action.meta.requestPayload.hasOwnProperty('bcc') === true &&
                    action.payload.settings.userData.hasActiveEventForwarding === true &&
                    action.meta.requestPayload.bcc.length > 0
                ) {
                    const bccNotInColleaguesEmails = action.meta.requestPayload.bcc.filter(
                        (bcc) => colleaguesEmails.includes(bcc) === false
                    );

                    if (bccNotInColleaguesEmails.length > 0) {
                        bccNotInColleaguesEmails.forEach((bcc) => {
                            colleaguesEmails.push(bcc.value);
                        });
                    }
                }
            }

            return {
                ...state,
                ...action.payload,

                settings: {
                    ...action.payload.settings,
                    colleaguesEmails: colleaguesEmails,
                },

                emailTemplateData: {
                    ...action.payload.emailTemplateData,
                    subject: subject,
                    text: converted,
                    changeHash: SendEmailHelper.createChangeHash(
                        converted,
                        subject,
                        type.id,
                        languageId
                    ),
                },

                messageHistory: action.meta.requestPayload.messageHistory,
            };

        case UPDATE_EMAIL_TEMPLATE:
            const emailTemplateData = {
                ...state.emailTemplateData,
                ...action.payload.emailTemplateData,
            };

            return {
                ...state,

                emailTemplateData: {
                    ...emailTemplateData,
                    currentChangeHash: SendEmailHelper.createChangeHash(
                        emailTemplateData.text,
                        emailTemplateData.subject,
                        emailTemplateData.type.id,
                        emailTemplateData.languageId
                    ),
                },
            };

        case SET_EMAIL_RECIPIENTS:
            return {
                ...state,
                recipients: action.payload.recipients,
            };

        case SET_EMAIL_BCC:
            return {
                ...state,
                bcc: action.payload.bcc,
            };

        case SET_EMAIL_SENDER:
            return {
                ...state,
                sender: action.payload.sender,
            };

        case ADD_NEW_COLLEAGUE_CONTACT_SUCCESS:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    colleaguesEmails: action.payload.colleaguesEmails,
                },
            };
        case REMOVE_COLLEAGUE_CONTACT:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    colleaguesEmails: state.settings.colleaguesEmails.filter(
                        (email) => email !== action.payload.email
                    ),
                },
            };
        case REMOVE_COLLEAGUE_CONTACT_FAILURE:
            colleaguesEmails = state.settings.colleaguesEmails;
            colleaguesEmails.push(action.meta.requestPayload.email);

            return {
                ...state,
                settings: {
                    ...state.settings,
                    colleaguesEmails: colleaguesEmails,
                },
            };
        case SET_SEND_EMAIL_AT:
            return {
                ...state,
                sendAt: {
                    ...state.sendAt,
                    ...action.payload.sendAtParams,
                },
            };

        case GET_VIDEO_INTERVIEW_DATA_SUCCESS:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    videoInterview: action.payload.interviewVariablesData,
                },
            };

        case GET_VIDEO_INTERVIEW_DATA:
        case GET_VIDEO_INTERVIEW_DATA_FAILURE:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    videoInterview: null,
                },
            };

        default:
            return state;
    }
};

export const previewEmailModel = (state = {}, action) => {
    switch (action.type) {
        case PREPARE_EMAIL_TO_PREVIEW_SUCCESS:
            return {
                ...state,
                ...action.payload.data,
            };
        default:
            return state;
    }
};
