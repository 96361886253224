import { lazy } from 'react';
import Storages from './Storages';
import Url from './Url';

const lazyWithReload = (componentImport: () => any) =>
    lazy(async () => {
        const pageHasAlreadyBeenForceRefreshed = Storages.getLocal('pageRefreshed', false);

        try {
            const component: any = await componentImport();

            Storages.setLocal('pageRefreshed', false);

            return component;
        } catch (error) {
            if (pageHasAlreadyBeenForceRefreshed === false) {
                Storages.setLocal('pageRefreshed', true);

                return Url.reload('Reload: Lazy load failed ' + componentImport.toString());
            }

            return import('./../components/Errors/OldVersion.jsx');
        }
    });

export default lazyWithReload;
