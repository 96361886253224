import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { requestGdprManagerModel } from '../../actions/GdprActions';
import Url from '../../helpers/Url';
import Gdpr from '../../helpers/Gdpr';
import { useLocation } from 'react-router-dom';
import Authentication from '../../helpers/Authentication';
import gdprManagerModelSkeleton from '../../skeletons/models/gdprManagerModelSkeleton';
import loginManagerModelSkeleton from '../../skeletons/models/loginManagerModelSkeleton';
import Skeletons from '../../helpers/Skeletons';

const GdprManager = (props) => {
    const location = useLocation();
    const [lastUrl, setLastUrl] = useState(location.pathname);

    useEffect(() => {
        const action =
            location.pathname.replace(Url.MARK_SERVER_PREFIX, '') === '/gdpr/trial'
                ? 'gdpr/trial'
                : Url.getUrlParameter('action');

        if (action === 'gdpr') {
            setLastUrl(Url.build('dashboard'));
        } else if (action !== 'gdpr' && action !== 'gdpr/trial' && lastUrl !== Url.build(action)) {
            setLastUrl(Url.build(action));
        }

        const { gdprManagerModel, isFetchingGdprManagerModel } = props;

        if (
            Authentication.isAccessibleForUnlogged(action) === false &&
            isFetchingGdprManagerModel === false &&
            gdprManagerModel.hasOwnProperty('hasCompanyGdprAgreement') === false
        ) {
            props.requestGdprManagerModel();
        }
    });

    const action = Url.getUrlParameter('action');

    if (Authentication.isAccessibleForUnlogged(action) === true) {
        return props.children;
    }

    const { gdprManagerModel, isFetchingGdprManagerModel, loginManagerModel } = props;

    if (isFetchingGdprManagerModel === true || Object.keys(gdprManagerModel).length === 0) {
    } else {
        const needGdprAgreement = Gdpr.needGdprAgreement(
            gdprManagerModel,
            loginManagerModel,
            action
        );

        if (needGdprAgreement === true) {
            return null;
        }
    }

    return props.children;
};

const mapStateToProps = (state) => ({
    isFetchingGdprManagerModel: state.isFetchingGdprManagerModel,
    gdprManagerModel: Skeletons.getData(state.gdprManagerModel, gdprManagerModelSkeleton),
    loginManagerModel: Skeletons.getData(state.loginManagerModel, loginManagerModelSkeleton),
});

const mapDispatchToProps = {
    requestGdprManagerModel,
};

export default connect(mapStateToProps, mapDispatchToProps)(GdprManager);
