import {
    SAVE_AS_CHANGED_AUTO_ANSWER_TEMPLATE,
    SAVE_AS_NEW_AUTO_ANSWER_TEMPLATE,
    SAVE_AS_CHANGED_EMAIL_TEMPLATE,
    SAVE_AS_NEW_EMAIL_TEMPLATE,
    SAVE_AS_CHANGED_QUESTIONNAIRE_TEMPLATE,
    SAVE_AS_NEW_QUESTIONNAIRE_TEMPLATE,
    SAVE_AS_CHANGED_PIPELINE_TEMPLATE,
    SAVE_AS_NEW_PIPELINE_TEMPLATE,
} from '../actions/TemplateAssistantActions';
import {
    UPDATE_QUESTIONNAIRE_DATA_SUCCESS,
    UPDATE_AUTOANSWER_DATA_SUCCESS,
} from '../actions/JobDetailActions';
import {
    ENABLE_QUESTIONNAIRE,
    DISABLE_QUESTIONNAIRE,
    ENABLE_AUTO_ANSWER,
    DISABLE_AUTO_ANSWER,
    RECEIVE_CREATE_JOB_MODEL,
} from '../actions/CreateJobActions';
import { MODAL_OPEN } from '../actions/ModalActions';

export const templateAssistant = (
    state = {
        autoAnswer: {
            isNewTemplate: false,
            isChangedTemplate: false,
        },

        emailTemplate: {
            isNewTemplate: false,
            isChangedTemplate: false,
        },

        questionnaire: {
            isNewTemplate: false,
            isChangedTemplate: false,
        },

        pipelineTemplate: {
            isNewTemplate: false,
            isChangedTemplate: false,
        },
    },
    action
) => {
    switch (action.type) {
        case SAVE_AS_NEW_AUTO_ANSWER_TEMPLATE:
            return Object.assign({}, state, {
                autoAnswer: Object.assign({}, state.autoAnswer, {
                    isNewTemplate: action.payload.isNew,
                }),
            });

        case SAVE_AS_CHANGED_AUTO_ANSWER_TEMPLATE:
            return Object.assign({}, state, {
                autoAnswer: Object.assign({}, state.autoAnswer, {
                    isChangedTemplate: action.payload.isChanged,
                }),
            });

        case SAVE_AS_NEW_EMAIL_TEMPLATE:
            return Object.assign({}, state, {
                emailTemplate: Object.assign({}, state.emailTemplate, {
                    isNewTemplate: action.payload.isNew,
                }),
            });

        case SAVE_AS_CHANGED_EMAIL_TEMPLATE:
            return Object.assign({}, state, {
                emailTemplate: Object.assign({}, state.emailTemplate, {
                    isChangedTemplate: action.payload.isChanged,
                }),
            });

        case SAVE_AS_NEW_QUESTIONNAIRE_TEMPLATE:
            return Object.assign({}, state, {
                questionnaire: Object.assign({}, state.questionnaire, {
                    isNewTemplate: action.payload.isNew,
                }),
            });

        case SAVE_AS_CHANGED_QUESTIONNAIRE_TEMPLATE:
            return Object.assign({}, state, {
                questionnaire: Object.assign({}, state.questionnaire, {
                    isChangedTemplate: action.payload.isChanged,
                }),
            });

        case SAVE_AS_NEW_PIPELINE_TEMPLATE:
            return {
                ...state,
                pipelineTemplate: {
                    ...state.pipelineTemplate,
                    isNewTemplate: action.payload.isNew,
                },
            };

        case SAVE_AS_CHANGED_PIPELINE_TEMPLATE:
            return {
                ...state,
                pipelineTemplate: {
                    ...state.pipelineTemplate,
                    isChangedTemplate: action.payload.isChanged,
                },
            };

        case ENABLE_QUESTIONNAIRE:
        case DISABLE_QUESTIONNAIRE:
        case UPDATE_QUESTIONNAIRE_DATA_SUCCESS:
            return Object.assign({}, state, {
                questionnaire: Object.assign({}, state.questionnaire, {
                    isChangedTemplate: false,
                    isNewTemplate: false,
                }),
            });

        case ENABLE_AUTO_ANSWER:
        case DISABLE_AUTO_ANSWER:
        case UPDATE_AUTOANSWER_DATA_SUCCESS:
            return Object.assign({}, state, {
                autoAnswer: Object.assign({}, state.autoAnswer, {
                    isChangedTemplate: false,
                    isNewTemplate: false,
                }),
            });

        case RECEIVE_CREATE_JOB_MODEL:
            return {
                ...state,

                autoAnswer: {
                    isNewTemplate: false,
                    isChangedTemplate: false,
                },

                questionnaire: {
                    isNewTemplate: false,
                    isChangedTemplate: false,
                },

                pipelineTemplate: {
                    isNewTemplate: false,
                    isChangedTemplate: false,
                },
            };

        case MODAL_OPEN:
            return {
                ...state,

                questionnaire:
                    [
                        'JobDetailPositionQuestionnaireModal',
                        'SettingsPageQuestionnaireEditorModal',
                    ].includes(action.payload.modalType) === true
                        ? {
                              ...state.questionnaire,
                              isChangedTemplate: false,
                              isNewTemplate: false,
                          }
                        : state.questionnaire,

                autoAnswer:
                    [
                        'JobDetailPositionAutoAnswerModal',
                        'SettingsPageAutoAnswerEditorModal',
                    ].includes(action.payload.modalType) === true
                        ? {
                              ...state.autoAnswer,
                              isChangedTemplate: false,
                              isNewTemplate: false,
                          }
                        : state.autoAnswer,

                emailTemplate:
                    ['SettingsPageEmailTemplateEditorModal'].includes(action.payload.modalType) ===
                    true
                        ? {
                              ...state.emailTemplate,
                              isChangedTemplate: false,
                              isNewTemplate: false,
                          }
                        : state.emailTemplate,

                pipelineTemplate:
                    [
                        'JobDetailPositionPipelineTemplateModal',
                        'SettingsPagePipelineTemplateEditorModal',
                    ].includes(action.payload.modalType) === true
                        ? {
                              ...state.pipelineTemplate,
                              isChangedTemplate: false,
                              isNewTemplate: false,
                          }
                        : state.pipelineTemplate,
            };

        default:
            return state;
    }
};
