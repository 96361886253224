import React from 'react';
import Url from '../../helpers/Url';
import JobPipelineSkeleton from './JobPipelineSkeleton';
import JobCandidatesListPageSkeleton from './JobCandidatesListPageSkeleton';
import NewJobDetailPositionSkeleton from './NewJobDetailPositionSkeleton';
import JobDetailPositionSkeleton from './JobDetailPositionSkeleton';
import JobCandidatesSourcesSkeleton from './JobCandidatesSourcesSkeleton';
import LoadingComponent from '../../components/LoadingComponent';
import DashboardSkeleton from './DashboardSkeleton';
import SettingsPageSkeleton from './SettingsPageSkeleton';
import StatisticsPageSkeleton from './StatisticsPageSkeleton';
import GlobalSearchPageSkeleton from './GlobalSearchPageSkeleton';
import JobsListPageSkeleton from './JobsListPageSkeleton';
import JobStatisticsSkeleton from './JobStatisticsSkeleton';

const SuspenseFallbackSkeleton = () => {
    let action = Url.getUrlParameter('action');
    let job = Url.getUrlParameter('job');
    const urlParams = new URLSearchParams(window.location.search);

    if (action === 'login') {
        const redirectTo = decodeURIComponent(Url.getQueryStringParameter('redirectTo'));

        if (redirectTo !== '') {
            action = Url.getUrlParameter('action', redirectTo);
            job = Url.getUrlParameter('job', redirectTo);
        }
    }

    switch (action) {
        case 'dashboard':
            return <DashboardSkeleton />;

        case 'jobs':
            return <JobsListPageSkeleton />;

        case 'search':
        case 'new-candidates':
            return <GlobalSearchPageSkeleton />;

        case 'statistics':
            return job === '' ? <StatisticsPageSkeleton /> : <JobStatisticsSkeleton />;

        case 'settings':
            return <SettingsPageSkeleton />;

        case 'pipeline':
            return <JobPipelineSkeleton />;

        case 'candidates':
            return <JobCandidatesListPageSkeleton />;

        case 'detail':
            return urlParams.has('new')
                ? <NewJobDetailPositionSkeleton/>
                : <JobDetailPositionSkeleton />;

        case 'candidate-sources':
            return <JobCandidatesSourcesSkeleton />;

        default:
            return <LoadingComponent />;
    }
};

export default SuspenseFallbackSkeleton;
