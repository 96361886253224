export const REQUEST_CANDIDATE_MODEL = 'REQUEST_CANDIDATE_MODEL';
export const RECEIVE_CANDIDATE_MODEL = 'RECEIVE_CANDIDATE_MODEL';
export const REQUEST_CANDIDATE_EVENTS = 'REQUEST_CANDIDATE_EVENTS';
export const RECEIVE_CANDIDATE_EVENTS = 'RECEIVE_CANDIDATE_EVENTS';
export const CANDIDATE_ADD_TAG = 'CANDIDATE_ADD_TAG';
export const CANDIDATE_ADD_TAG_SUCCESS = 'CANDIDATE_ADD_TAG_SUCCESS';
export const CANDIDATE_ADD_TAG_FAILURE = 'CANDIDATE_ADD_TAG_FAILURE';
export const CANDIDATE_REMOVE_TAG = 'CANDIDATE_REMOVE_TAG';
export const CANDIDATE_REMOVE_TAG_SUCCESS = 'CANDIDATE_REMOVE_TAG_SUCCESS';
export const CANDIDATE_REMOVE_TAG_FAILURE = 'CANDIDATE_REMOVE_TAG_FAILURE';
export const CANDIDATE_SET_NOTE = 'CANDIDATE_SET_NOTE';
export const CANDIDATE_SET_NOTE_SUCCESS = 'CANDIDATE_SET_NOTE_SUCCESS';
export const CANDIDATE_SET_NOTE_FAILURE = 'CANDIDATE_SET_NOTE_FAILURE';
export const REQUEST_CANDIDATE_HISTORY = 'REQUEST_CANDIDATE_HISTORY';
export const RECEIVE_CANDIDATE_HISTORY = 'RECEIVE_CANDIDATE_HISTORY';
export const SET_CANDIDATE_AS_FAVORITE = 'SET_CANDIDATE_AS_FAVORITE';
export const SET_CANDIDATE_AS_FAVORITE_SUCCESS = 'SET_CANDIDATE_AS_FAVORITE_SUCCESS';
export const SET_CANDIDATE_AS_FAVORITE_FAILURE = 'SET_CANDIDATE_AS_FAVORITE_FAILURE';
export const UNSET_CANDIDATE_AS_FAVORITE = 'UNSET_CANDIDATE_AS_FAVORITE';
export const UNSET_CANDIDATE_AS_FAVORITE_SUCCESS = 'UNSET_CANDIDATE_AS_FAVORITE_SUCCESS';
export const UNSET_CANDIDATE_AS_FAVORITE_FAILURE = 'UNSET_CANDIDATE_AS_FAVORITE_FAILURE';
export const GET_CANDIDATE = 'GET_CANDIDATE';
export const GET_CANDIDATE_SUCCESS = 'GET_CANDIDATE_SUCCESS';
export const GET_CANDIDATE_FAILURE = 'GET_CANDIDATE_FAILURE';
export const SET_CANDIDATE_INBOX_MESSAGE_AS_READ = 'SET_CANDIDATE_INBOX_MESSAGE_AS_READ';
export const SET_CANDIDATE_INBOX_MESSAGE_AS_READ_SUCCESS =
    'SET_CANDIDATE_INBOX_MESSAGE_AS_READ_SUCCESS';
export const SET_CANDIDATE_INBOX_MESSAGE_AS_READ_FAILURE =
    'SET_CANDIDATE_INBOX_MESSAGE_AS_READ_FAILURE';
export const REQUEST_CANDIDATE_INBOX = 'REQUEST_CANDIDATE_INBOX';
export const RECEIVE_CANDIDATE_INBOX = 'RECEIVE_CANDIDATE_INBOX';
export const STORE_CANDIDATE_PHONE_CALL_INTO_HISTORY = 'STORE_CANDIDATE_PHONE_CALL_INTO_HISTORY';
export const STORE_CANDIDATE_PHONE_CALL_INTO_HISTORY_SUCCESS =
    'STORE_CANDIDATE_PHONE_CALL_INTO_HISTORY_SUCCESS';
export const STORE_CANDIDATE_PHONE_CALL_INTO_HISTORY_FAILURE =
    'STORE_CANDIDATE_PHONE_CALL_INTO_HISTORY_FAILURE';

export const requestCandidateModel = (candidateId = null) => ({
    type: REQUEST_CANDIDATE_MODEL,

    payload: {
        candidateId,
    },

    meta: {
        requestRoute: 'v1/candidate/model/' + (candidateId === null ? 0 : candidateId),
        onRequestSuccess: RECEIVE_CANDIDATE_MODEL,
    },
});

export const requestCandidateEvents = (candidateId = null) => ({
    type: REQUEST_CANDIDATE_EVENTS,

    payload: {
        candidateId,
    },

    meta: {
        requestRoute: 'v1/candidate/data/events',
        requestData: {
            candidateId: candidateId === null ? 0 : candidateId,
        },
        onRequestSuccess: RECEIVE_CANDIDATE_EVENTS,
    },
});

export const requestCandidateHistory = (candidateId = null) => ({
    type: REQUEST_CANDIDATE_HISTORY,

    payload: {
        candidateId,
    },

    meta: {
        requestRoute: 'v1/candidate/data/history',
        requestData: {
            candidateId: candidateId === null ? 0 : candidateId,
        },
        onRequestSuccess: RECEIVE_CANDIDATE_HISTORY,
    },
});

export const requestCandidateInbox = (candidateId = null) => ({
    type: REQUEST_CANDIDATE_INBOX,

    payload: {
        candidateId,
    },

    meta: {
        requestRoute: 'v1/candidate/data/inbox',

        requestData: {
            candidateId: candidateId === null ? 0 : candidateId,
        },

        onRequestSuccess: RECEIVE_CANDIDATE_INBOX,
    },
});

export const candidateAddTag = (candidateIds, formData) => ({
    type: CANDIDATE_ADD_TAG,

    payload: {
        candidateIds,
    },

    meta: {
        requestRoute: 'v1/candidate/action/add-tag',
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: CANDIDATE_ADD_TAG_SUCCESS,
        onRequestFailure: CANDIDATE_ADD_TAG_FAILURE,
    },
});

export const candidateRemoveTag = (formData, candidateId) => ({
    type: CANDIDATE_REMOVE_TAG,

    payload: {
        candidateId,
    },

    meta: {
        requestRoute: 'v1/candidate/action/remove-tag',
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: CANDIDATE_REMOVE_TAG_SUCCESS,
        onRequestFailure: CANDIDATE_REMOVE_TAG_FAILURE,
    },
});

export const candidateSetNote = (formData, candidateId) => ({
    type: CANDIDATE_SET_NOTE,

    payload: {
        candidateId,
    },

    meta: {
        requestRoute: 'v1/candidate/action/set-note',
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: CANDIDATE_SET_NOTE_SUCCESS,
        onRequestFailure: CANDIDATE_SET_NOTE_FAILURE,
    },
});

export const setCandidateAsFavorite = (candidateId) => ({
    type: SET_CANDIDATE_AS_FAVORITE,

    payload: {
        candidateId,
    },

    meta: {
        requestRoute: 'v1/candidate/action/set-as-favorite',
        requestMethod: 'POST',
        requestData: {
            candidateId,
        },

        onRequestSuccess: SET_CANDIDATE_AS_FAVORITE_SUCCESS,
        onRequestFailure: SET_CANDIDATE_AS_FAVORITE_FAILURE,
    },
});

export const unsetCandidateAsFavorite = (candidateId) => ({
    type: UNSET_CANDIDATE_AS_FAVORITE,

    payload: {
        candidateId,
    },

    meta: {
        requestRoute: 'v1/candidate/action/unset-as-favorite',
        requestMethod: 'POST',
        requestData: {
            candidateId,
        },

        onRequestSuccess: UNSET_CANDIDATE_AS_FAVORITE_SUCCESS,
        onRequestFailure: UNSET_CANDIDATE_AS_FAVORITE_FAILURE,
    },
});

export const getCandidate = (candidateId) => ({
    type: GET_CANDIDATE,

    payload: {
        candidateId,
    },

    meta: {
        requestRoute: 'v1/candidate/data/' + candidateId,
        onRequestSuccess: GET_CANDIDATE_SUCCESS,
        onRequestFailure: GET_CANDIDATE_FAILURE,
    },
});

export const setCandidateInboxMessageAsRead = (candidateId, messageId) => ({
    type: SET_CANDIDATE_INBOX_MESSAGE_AS_READ,

    payload: {
        candidateId,
        messageId,
    },

    meta: {
        requestRoute: 'v1/candidate/action/set-message-as-read',
        requestMethod: 'POST',
        requestData: {
            candidateId,
            messageId,
        },

        onRequestSuccess: SET_CANDIDATE_INBOX_MESSAGE_AS_READ_SUCCESS,
        onRequestFailure: SET_CANDIDATE_INBOX_MESSAGE_AS_READ_FAILURE,
    },
});

export const storeCandidatePhoneCallIntoHistory = (candidateId) => ({
    type: STORE_CANDIDATE_PHONE_CALL_INTO_HISTORY,

    payload: {
        candidateId,
    },

    meta: {
        requestRoute: 'v1/candidate/action/store-phone-call',
        requestMethod: 'POST',
        requestData: {
            candidateId,
        },

        onRequestSuccess: STORE_CANDIDATE_PHONE_CALL_INTO_HISTORY_SUCCESS,
        onRequestFailure: STORE_CANDIDATE_PHONE_CALL_INTO_HISTORY_FAILURE,
    },
});
