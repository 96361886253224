import React from 'react';
import { SkeletonCircular, SkeletonRectangular } from '@profesia/adamui/components/common/skeleton';
import { Stack } from '@mui/material';
import AttachmentsAssistantSkeleton from './AttachmentsAssistantSkeleton';

const DetailCandidateNoModalContentSkeleton = () => (
    <>
        <div className="noModal__content">
            <div className="list__controls_extended">
                <AttachmentsAssistantSkeleton />
            </div>
        </div>

        <div className="noModal__sidebar">
            <div className="pushable edit-panel_close">
                <div className="pusher sidebar-content">
                    <div className="ui items list__jobs">
                        <Stack spacing={4.5}>
                            <Stack direction="row" spacing={2.5}>
                                <SkeletonRectangular width={236} height={48} borderRadius={4} />
                                <SkeletonRectangular width={48} height={48} borderRadius={4} />
                            </Stack>

                            <Stack spacing={5.5}>
                                <Stack spacing={3}>
                                    {[...Array(2)].map((e, i) => (
                                        <Stack
                                            spacing={1.5}
                                            direction="row"
                                            alignItems="center"
                                            key={i}
                                        >
                                            <SkeletonCircular size={18} />
                                            <SkeletonRectangular
                                                width={187}
                                                height={10}
                                                borderRadius={26}
                                            />
                                        </Stack>
                                    ))}
                                </Stack>

                                <Stack spacing={3}>
                                    {[...Array(8)].map((e, i) => (
                                        <Stack
                                            spacing={1.5}
                                            direction="row"
                                            alignItems="center"
                                            key={i}
                                        >
                                            <SkeletonCircular size={18} />
                                            <SkeletonRectangular
                                                width={187}
                                                height={10}
                                                borderRadius={26}
                                            />
                                        </Stack>
                                    ))}
                                </Stack>
                            </Stack>
                        </Stack>
                    </div>
                </div>
            </div>
        </div>
    </>
);

export default DetailCandidateNoModalContentSkeleton;
