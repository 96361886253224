import React from 'react';
import { SkeletonCircular, SkeletonRectangular } from '@profesia/adamui/components/common/skeleton';
import { Stack } from '@mui/material';

const JobCandidateSourcesSkeleton = () => (
    <div className="list">
        <div className="list__header_basic">
            <Stack mt={3}>
                <SkeletonRectangular width={245} height={18} borderRadius={26} />
            </Stack>
        </div>

        <div className="list__data skeleton">
            <div className="ui grid">
                <div className="ten wide column">
                    <div className="ui grid data-block data-block_primary">
                        <div className="row">
                            <div className="column">
                                <div className="ui items unstackable">
                                    {[...Array(2)].map((e, i) => (
                                        <div
                                            className="item data-block__content channel skeleton"
                                            key={i}
                                        >
                                            <Stack flex={1} mb={3}>
                                                <Stack
                                                    flex={1}
                                                    spacing={2}
                                                    pb={3}
                                                    direction="row"
                                                    alignItems="start"
                                                >
                                                    <SkeletonCircular size={48} />

                                                    <Stack flex={1} spacing={3}>
                                                        <Stack spacing={1}>
                                                            <SkeletonRectangular
                                                                width={92}
                                                                height={18}
                                                                borderRadius={26}
                                                            />
                                                            <SkeletonRectangular
                                                                width={60}
                                                                height={18}
                                                                borderRadius={26}
                                                            />
                                                        </Stack>

                                                        <Stack flex={1} spacing={1}>
                                                            <SkeletonRectangular
                                                                height={18}
                                                                borderRadius={26}
                                                            />
                                                            <SkeletonRectangular
                                                                height={18}
                                                                borderRadius={26}
                                                            />
                                                            <SkeletonRectangular
                                                                height={18}
                                                                borderRadius={26}
                                                            />
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="six wide column">
                    <div className="ui grid data-block data-block_secondary">
                        <div className="row">
                            <div className="column">
                                {[...Array(3)].map((e, i) => (
                                    <div className="data-block__content skeleton" key={i}>
                                        <Stack spacing={1}>
                                            <SkeletonRectangular
                                                width={245}
                                                height={18}
                                                borderRadius={26}
                                            />
                                            <SkeletonRectangular
                                                width={116}
                                                height={10}
                                                borderRadius={26}
                                            />
                                            <SkeletonRectangular
                                                width={188}
                                                height={10}
                                                borderRadius={26}
                                            />
                                        </Stack>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default JobCandidateSourcesSkeleton;
