import React from 'react';
import PropTypes from 'prop-types';

const Heading = ({ as, children, className, icon, textAlign }) => {
    const Tag = as;

    return (
        <Tag
            className={`mark-header ${className} ${textAlign ? 'aligned ' + textAlign : ''} ${icon ? 'icon' : ''}`}
        >
            {children}
        </Tag>
    );
};

const Subheader = (props) => (
    <div {...props} className={`sub header ${props.className}`}>
        {props.children}
    </div>
);
const Content = (props) => (
    <div {...props} className={`content ${props.className}`}>
        {props.children}
    </div>
);

Heading.Subheader = Subheader;
Heading.Content = Content;

Heading.propTypes = {
    as: PropTypes.string,
    className: PropTypes.string,
    textAlign: PropTypes.string,
};

Heading.defaultProps = {
    as: 'h3',
    className: '',
    textAlign: '',
    icon: false,
};

export default Heading;
