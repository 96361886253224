import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    setRefuseCandidatesParams,
    requestRefuseCandidatesModel,
    getCandidatesForAction,
} from '../../actions/ActionWithCandidateActions';
import MarkForm from '../Form';
import { CardNotification } from '@profesia/adamui/components/common/card';
import { InputField } from '@profesia/adamui/components/common/input';
import Select from '@profesia/adamui/components/common/dropdown/SelectDefault';
import Checkbox from '@profesia/adamui/components/common/input/checkbox/Checkbox';
import Grid from '../Grid';
import Heading from '../Heading';
import Translations from '../../helpers/Translations';
import Constants from '../../helpers/Constants';
import Validation from '../../helpers/Validation';
import Form, { FormFieldTypeEnum } from '../../helpers/Form';
import SendEmail from '../Email/SendEmail';
import SendEmailHelper from '../../helpers/components/SendEmailHelper';
import FormSkeleton from '../../skeletons/components/FormSkeleton';
import Track from '../../helpers/Track';
import {
    TRACK_FUNNEL_NAME_SEND_EMAIL_CHECKED,
    TRACK_FUNNEL_NAME_SEND_EMAIL_UNCHECKED,
    TRACK_FUNNEL_TYPE_EXCLUDE_CANDIDATE,
} from '../../helpers/ConstantsTrack';
import Language from '../../helpers/Language';
import Divider from '../Divider';
import UserRights from '../../helpers/UserRights';

class RefuseCandidate extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        this.props.requestRefuseCandidatesModel();
        this.props.getCandidatesForAction(this.props.candidateIds);

        this.props.setRefuseCandidatesParams({
            refusalReasonId: this.props.refusalReasonId,
            sendEmail:
                Constants.MARK_2604 &&
                UserRights.hasActiveFreeSubscription(this.props.loginManagerModel),
        });
    };

    getDefaultEmailTemplateId() {
        switch (Language.get()) {
            case Constants.LANGUAGE_SK:
                return Constants.UNSUITABLE_CANDIDATE_EMAIL_TEMPLATE_SK;

            case Constants.LANGUAGE_CS:
                return Constants.UNSUITABLE_CANDIDATE_EMAIL_TEMPLATE_CS;

            case Constants.LANGUAGE_EN:
                return Constants.UNSUITABLE_CANDIDATE_EMAIL_TEMPLATE_EN;

            case Constants.LANGUAGE_HU:
                return Constants.UNSUITABLE_CANDIDATE_EMAIL_TEMPLATE_HU;

            case Constants.LANGUAGE_DE:
                return Constants.UNSUITABLE_CANDIDATE_EMAIL_TEMPLATE_DE;
        }
    }

    render() {
        if (
            this.props.isFetchingCandidatesForAction === true ||
            this.props.isFetchingRefuseCandidatesModel === true ||
            this.props.candidateActionModel.hasOwnProperty('refusalReasons') === false
        ) {
            return <FormSkeleton />;
        }

        const { errors, candidateActionModel, candidateIds, loginManagerModel } = this.props;
        const { refuseCandidateParams, refusalReasons, candidates } = candidateActionModel;
        const { sendEmail, customRefusalReason } = refuseCandidateParams;
        const emailToCandidateTitle =
            candidateIds.length > 1
                ? Translations.getStatic('emailToCandidates')
                : Translations.getStatic('emailToCandidate');
        const sendEmailToCandidateTitle =
            candidateIds.length > 1
                ? Translations.getStatic('sendEmailToCandidates')
                : Translations.getStatic('sendEmailToCandidate');

        let refusalReasonsItems = refusalReasons.map((reason) => ({
            label: Translations.getStatic(reason.refusalReason),
            id: reason.id,
        }));

        const refusalReasonId =
            refuseCandidateParams.refusalReasonId === null || refusalReasons.length === 0
                ? Constants.REFUSAL_REASON_WITHOUT_REASON_ID
                : refuseCandidateParams.refusalReasonId;

        if (refusalReasonId !== refuseCandidateParams.refusalReasonId) {
            this.props.setRefuseCandidatesParams({
                refusalReasonId,
            });
        }

        const MARK_2604 =
            Constants.MARK_2604 && UserRights.hasActiveFreeSubscription(loginManagerModel);

        return (
            <>
                <MarkForm>
                    {candidateIds.length > 1 && (
                        <Heading className="highlighted border" as="h3">
                            {Translations.getStatic('chosenCandidates')}
                            {': '}
                            {candidateIds.length}
                        </Heading>
                    )}

                    {MARK_2604 && (
                        <Grid className={'keep-padding-top'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <CardNotification
                                        title={''}
                                        variant={'infoNoTitle'}
                                        description={Translations.getStatic('refusalReasonInfoBox')}
                                    />

                                    <Divider hidden className="size_24" />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    )}

                    <Heading as="h3">{Translations.getStatic('refusalReason')}</Heading>

                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <MarkForm.Field
                                    error={
                                        this.props.errors.includes('refusalReason') &&
                                        Form.check({
                                            fieldType: FormFieldTypeEnum.numeric,
                                            fieldValue: refusalReasonId,
                                        }) === false
                                            ? Validation.getMessage('empty', 'selectRefusalReason')
                                            : false
                                    }
                                >
                                    <Select
                                        label={Translations.getStatic('selectRefusalReason')}
                                        value={refusalReasonId}
                                        options={refusalReasonsItems}
                                        onChange={(value) =>
                                            this.props.setRefuseCandidatesParams({
                                                refusalReasonId: value,
                                                customRefusalReason: '',
                                            })
                                        }
                                    />
                                </MarkForm.Field>
                            </Grid.Column>
                        </Grid.Row>

                        {refusalReasonId === Constants.REFUSAL_REASON_OTHER_ID && (
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <MarkForm.Field
                                        error={
                                            this.props.errors.includes('customRefusalReason') &&
                                            Form.check({
                                                fieldType: FormFieldTypeEnum.text,
                                                fieldValue: customRefusalReason,
                                            }) === false
                                                ? Validation.getMessage(
                                                      'empty',
                                                      'fillRefusalReason'
                                                  )
                                                : false
                                        }
                                    >
                                        <InputField
                                            maxLength={128}
                                            autoFocus
                                            value={customRefusalReason}
                                            label={Translations.getStatic('fillRefusalReason')}
                                            onChange={(event) =>
                                                this.props.setRefuseCandidatesParams({
                                                    customRefusalReason: event.target.value,
                                                })
                                            }
                                        />
                                    </MarkForm.Field>
                                </Grid.Column>
                            </Grid.Row>
                        )}
                    </Grid>
                </MarkForm>

                <MarkForm>
                    <Heading as="h3">{emailToCandidateTitle}</Heading>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <MarkForm.Field>
                                    <Checkbox
                                        checked={sendEmail}
                                        label={sendEmailToCandidateTitle}
                                        onChange={(e) => {
                                            this.props.setRefuseCandidatesParams({
                                                sendEmail: e.target.checked,
                                            });

                                            Track.funnel(
                                                3,
                                                e.target.checked
                                                    ? TRACK_FUNNEL_NAME_SEND_EMAIL_CHECKED
                                                    : TRACK_FUNNEL_NAME_SEND_EMAIL_UNCHECKED,
                                                TRACK_FUNNEL_TYPE_EXCLUDE_CANDIDATE
                                            );
                                        }}
                                    />
                                </MarkForm.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </MarkForm>

                {sendEmail === true && (
                    <SendEmail
                        visibleFields={SendEmailHelper.getDefaultEmailVisibleFields()}
                        recipients={
                            this.props.sendEmailModel.hasOwnProperty('recipients') === true &&
                            this.props.sendEmailModel.recipients.length > 0
                                ? this.props.sendEmailModel.recipients
                                : candidates
                                      .filter(
                                          (candidate) =>
                                              candidateIds.includes(candidate.id) === true &&
                                              candidate.isAnonymized === false
                                      )
                                      .map((candidate) => ({
                                          value: candidate.email,
                                          text: candidate.candidateName,
                                          candidateId: candidate.id,
                                      }))
                        }
                        bcc={
                            this.props.sendEmailModel.hasOwnProperty('bcc') === true &&
                            this.props.sendEmailModel.bcc.length > 0
                                ? this.props.sendEmailModel.bcc
                                : []
                        }
                        noSpacing={true}
                        errors={errors}
                        emailTemplateType={Constants.EMAIL_TEMPLATE_TYPE_REFUSING}
                        defaultEmailTemplateId={MARK_2604 ? this.getDefaultEmailTemplateId() : null}
                    />
                )}
            </>
        );
    }
}

RefuseCandidate.propTypes = {
    candidateIds: PropTypes.array.isRequired,
    refusalReasonId: PropTypes.number,
};

RefuseCandidate.defaultProps = {
    refusalReasonId: null,
};

const mapStateToProps = (state) => ({
    sendEmailModel: state.sendEmailModel,
    candidateActionModel: state.candidateActionModel,
    isFetchingRefuseCandidatesModel: state.isFetchingRefuseCandidatesModel,
    isFetchingCandidatesForAction: state.isFetchingCandidatesForAction,
    loginManagerModel: state.loginManagerModel,
});

const mapDispatchToProps = {
    requestRefuseCandidatesModel,
    setRefuseCandidatesParams,
    getCandidatesForAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(RefuseCandidate);
