import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import { closeModal, openModalSecond } from '../../actions/ModalActions';
import { connect } from 'react-redux';
import SendEmailHelper from '../../helpers/components/SendEmailHelper';
import { sendCandidatesQuestionnaireRequest } from '../../actions/ActionWithCandidateActions';
import Constants from '../../helpers/Constants';
import SendEmailToCandidates from '../Candidate/SendEmailToCandidates';
import SuccessMessage from '../../helpers/SuccessMessage';
import Translations from '../../helpers/Translations';
import AttachmentAssistantHelper from '../../helpers/components/AttachmentAssistantHelper';
import Arrays from '../../helpers/Arrays';
import {
    openAllRecipientsWithoutEmailsDialog,
    openSomeRecipientsWithoutEmailsDialog,
} from '../../actions/DialogActions';
import LoadingComponent from '../LoadingComponent';
import Variables from '../../helpers/Variables';

class RequestCandidateQuestionnaireModal extends Component {
    state = {
        errors: [],
    };

    componentDidUpdate = (prevProps) => {
        if (
            SuccessMessage.canShowSuccessMessage(
                prevProps,
                this.props,
                'isSendingCandidatesQuestionnaireRequest'
            ) &&
            this.props.candidateActionModel.hasOwnProperty('isSuccess') === true &&
            this.props.candidateActionModel.isSuccess === true
        ) {
            this.props.closeModal();
        }
    };

    sendCandidatesQuestionnaireRequests = (excludedCandidateIdsFromSendEmail = []) => {
        const candidateIds = Arrays.getUniqueArray(this.props.candidateIds);

        const {
            candidateActionModel,
            sendEmailModel,
            attachmentAssistantModel,
            templateAssistant,
            templatesForDropdownSelector,
            loginManagerModel,
        } = this.props;

        try {
            const actionData = SendEmailHelper.prepareEmail(
                sendEmailModel,
                attachmentAssistantModel,
                templateAssistant,
                templatesForDropdownSelector,
                candidateIds,
                Constants.EMAIL_TYPE_TO_CANDIDATE,
                {
                    variables: sendEmailModel.settings.variables,
                    filledVariables: Variables.getFilledVariablesCheckParams(
                        [
                            Constants.VARIABLE_ACTION_TYPE_USER,
                            Constants.VARIABLE_ACTION_TYPE_EVENT,
                            Constants.VARIABLE_ACTION_TYPE_VIDEO_INTERVIEW,
                            Constants.VARIABLE_ACTION_TYPE_QUESTIONNAIRE,
                        ],
                        candidateActionModel.candidates,
                        loginManagerModel,
                        sendEmailModel.settings.videoInterview
                    ),
                    forbiddenVariables: Variables.getForbiddenVariablesCheckParams([
                        Constants.VARIABLE_ACTION_TYPE_MULTIPLE_FORWARD,
                    ]),
                    requiredVariables: Variables.getRequiredVariablesCheckParams([
                        Constants.VARIABLE_ACTION_TYPE_QUESTIONNAIRE,
                    ]),
                },
                excludedCandidateIdsFromSendEmail
            );

            actionData.append('candidateIds', JSON.stringify(candidateIds));

            this.props.sendCandidatesQuestionnaireRequest(actionData, candidateIds);

            this.setState({
                errors: [],
            });
        } catch (error) {
            if (error !== false && Array.isArray(error) === true) {
                SendEmailHelper.processRecipientsErrors(
                    error,
                    this.props.openAllRecipientsWithoutEmailsDialog,
                    () =>
                        this.props.openSomeRecipientsWithoutEmailsDialog({
                            candidateIds: candidateIds,
                            onSkip: (excludedCandidateIdsFromSendEmail) =>
                                this.refuseAndSendEmail(excludedCandidateIdsFromSendEmail),
                        })
                );

                this.setState({
                    errors: error,
                });
            }
        }
    };

    render() {
        const { isOpen, sendEmailModel, attachmentAssistantModel, candidateIds, multipleType } =
            this.props;
        const { errors } = this.state;

        const children =
            this.props.isSendingCandidatesQuestionnaireRequest === true ? (
                <LoadingComponent
                    text={Translations.getStatic('requestingQuestionnaireInProgress') + '...'}
                    showProgress={candidateIds.length > Constants.POST_CHUNK_SIZE}
                />
            ) : (
                <SendEmailToCandidates
                    errors={errors}
                    candidateIds={candidateIds}
                    multipleType={multipleType}
                    emailTemplateType={Constants.EMAIL_TEMPLATE_TYPE_QUESTIONNAIRE_REQUEST}
                />
            );

        const emailData = {
            from:
                sendEmailModel.settings && sendEmailModel.settings.companyUsers
                    ? sendEmailModel.settings.companyUsers.find(
                          (user) => user.id === sendEmailModel.sender
                      ).email
                    : '',
            to: sendEmailModel.recipients.map((recipient) => recipient.value),
            bcc: sendEmailModel.bcc.map((bccEmail) => bccEmail.value),
            subject: sendEmailModel.emailTemplateData
                ? sendEmailModel.emailTemplateData.subject
                : '',
            body: sendEmailModel.emailTemplateData ? sendEmailModel.emailTemplateData.text : '',
            attachments:
                AttachmentAssistantHelper.getAttachmentsForEmailPreview(attachmentAssistantModel),
            attachmentSettings: attachmentAssistantModel.settings,
            languageId: sendEmailModel.emailTemplateData
                ? sendEmailModel.emailTemplateData.languageId
                : 1,
            candidateId: candidateIds.length > 0 ? candidateIds[0] : 0,
            senderId: sendEmailModel.sender,
        };

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={this.props.closeModal}
                actionButton={{
                    text: Translations.getStatic('sendQuestionnaireRequest'),
                    disabled: this.props.isSendingCandidatesQuestionnaireRequest,
                    loading: this.props.isSendingCandidatesQuestionnaireRequest,
                    onClick: () => this.sendCandidatesQuestionnaireRequests(),
                }}
                entityOption={{
                    text: Translations.getStatic('emailPreview'),
                    visible: true,
                    disabled: this.props.isSendingCandidatesQuestionnaireRequest,
                    loading: this.props.isSendingCandidatesQuestionnaireRequest,
                    onClick: () =>
                        this.props.openModalSecond('DetailEmailModal', {
                            title: Translations.getStatic('emailPreview'),
                            emailData,
                        }),
                }}
                title={Translations.getStatic('sendQuestionnaireRequestTitle')}
                content={children}
            />
        );
    }
}

RequestCandidateQuestionnaireModal.propTypes = {
    candidateIds: PropTypes.array.isRequired,
    isOpen: PropTypes.bool.isRequired,
    multipleType: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    isOpen: state.modalLogic.isOpen,
    candidateActionModel: state.candidateActionModel,
    isSendingCandidatesQuestionnaireRequest: state.isSendingCandidatesQuestionnaireRequest,
    sendEmailModel: state.sendEmailModel,

    attachmentAssistantModel: AttachmentAssistantHelper.getActiveModelFromAssistantAttachmentModel(
        state.attachmentAssistantModel,
        AttachmentAssistantHelper.createAttachmentAssistantHashIdForEmail()
    ),

    templateAssistant: state.templateAssistant,
    templatesForDropdownSelector: state.templatesForDropdownSelector.templates,
    loginManagerModel: state.loginManagerModel,
});

const mapDispatchToProps = {
    closeModal,
    openModalSecond,
    sendCandidatesQuestionnaireRequest,
    openAllRecipientsWithoutEmailsDialog,
    openSomeRecipientsWithoutEmailsDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestCandidateQuestionnaireModal);
