import React from 'react';
import PropTypes from 'prop-types';
import SendEmail from '../Email/SendEmail';
import Constants from '../../helpers/Constants';
import Divider from '../Divider';
import Heading from '../Heading';
import { connect } from 'react-redux';
import { getCandidatesForAction } from '../../actions/ActionWithCandidateActions';
import Translations from '../../helpers/Translations';
import SendEmailHelper from '../../helpers/components/SendEmailHelper';
import FormSkeleton from '../../skeletons/components/FormSkeleton';

class SendEmailToCandidates extends React.Component {
    componentDidMount = () => {
        if (
            this.props.candidateActionModel.hasOwnProperty('candidates') === false &&
            this.props.isFetchingCandidatesForAction === false
        ) {
            this.props.getCandidatesForAction(this.props.candidateIds);
        }

        if (this.props.candidateActionModel.hasOwnProperty('candidates') === true) {
            const sortFn = (a, b) => a - b;
            const candidateIdsFromStore = JSON.stringify(
                this.props.candidateActionModel.candidates
                    .map((candidate) => candidate.id)
                    .sort(sortFn)
            );
            const candidateIds = JSON.stringify(this.props.candidateIds.sort(sortFn));

            if (
                candidateIds !== candidateIdsFromStore &&
                this.props.isFetchingCandidatesForAction === false
            ) {
                this.props.getCandidatesForAction(this.props.candidateIds);
            }
        }
    };

    componentDidUpdate = (prevProps) => {
        if (
            prevProps.isFetchingCandidatesForAction === false &&
            JSON.stringify(prevProps.candidateIds) !== JSON.stringify(this.props.candidateIds)
        ) {
            this.props.getCandidatesForAction(this.props.candidateIds);
        }
    };

    render = () => {
        if (
            this.props.isFetchingCandidatesForAction === true ||
            this.props.candidateActionModel.hasOwnProperty('candidates') === false ||
            this.props.candidateActionModel.candidates.length === 0
        ) {
            return <FormSkeleton />;
        }

        const { candidateIds, candidateActionModel, errors, emailTemplateType } = this.props;

        const { candidates } = candidateActionModel;

        return (
            <>
                {this.props.multipleType === true && (
                    <>
                        <Heading as="h3" className="highlighted border">
                            {Translations.getStatic('chosenCandidates') +
                                ': ' +
                                candidateIds.length}
                        </Heading>
                        <Divider hidden className="small" />
                    </>
                )}

                <SendEmail
                    errors={errors}
                    visibleFields={SendEmailHelper.getDefaultEmailVisibleFields()}
                    emailTemplateType={emailTemplateType}
                    recipients={candidates
                        .filter(
                            (candidate) =>
                                candidateIds.includes(candidate.id) === true &&
                                candidate.isAnonymized === false
                        )
                        .map((candidate) => ({
                            value: candidate.email,
                            text: candidate.candidateName,
                            candidateId: candidate.id,
                        }))}
                    bcc={
                        this.props.sendEmailModel.hasOwnProperty('bcc') === true &&
                        this.props.sendEmailModel.bcc.length > 0
                            ? this.props.sendEmailModel.bcc
                            : []
                    }
                    messageHistory={this.props.messageHistory}
                />
            </>
        );
    };
}

SendEmailToCandidates.propTypes = {
    candidateIds: PropTypes.array.isRequired,
    multipleType: PropTypes.bool.isRequired,
    errors: PropTypes.array,
    emailTemplateType: PropTypes.number,
    messageHistory: PropTypes.object,
};

SendEmailToCandidates.defaultProps = {
    multipleType: false,
    errors: [],
    emailTemplateType: Constants.EMAIL_TEMPLATE_TYPE_OTHER,
    messageHistory: null,
};

const mapStateToProps = (state) => ({
    sendEmailModel: state.sendEmailModel,
    candidateActionModel: state.candidateActionModel,
    isFetchingCandidatesForAction: state.isFetchingCandidatesForAction,
});

const mapDispatchToProps = {
    getCandidatesForAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SendEmailToCandidates);
