import Strings from './Strings';
import Constants from './Constants';
import Log from './Log';
import { CommonObjectType } from '../../interfaces/CommonTypes';

class Sort {
    static byColumn(
        data: CommonObjectType[],
        columnName: string,
        direction: string = 'ASC',
        mode: string = Constants.SORT_TYPE_TEXT
    ) {
        if (data.length === 0) {
            Log.warning(
                'Source data empty - nothing to sort for ' +
                    JSON.stringify([columnName, direction, mode])
            );

            return data;
        }

        if (mode === Constants.SORT_TYPE_OBJECT) {
            return this.asObject(data, columnName, direction);
        }

        if (data[0]?.hasOwnProperty(columnName) === false) {
            Log.warning(
                'Column "' +
                    columnName +
                    '" does NOT exist; use one of existing columns: ' +
                    JSON.stringify(Object.keys(data[0]))
            );

            return data;
        }

        if (mode === Constants.SORT_TYPE_NUMERIC) {
            return this.asNumeric(data, columnName, direction);
        }

        return this.asText(data, columnName, direction);
    }

    static asObject(
        data: CommonObjectType[],
        columnName: string,
        direction: string = 'ASC'
    ): CommonObjectType[] {
        const objectPath: string[] = columnName.split('.');

        if (data[0]?.hasOwnProperty(objectPath[0] ?? '') === false) {
            Log.warning(
                'Object "' +
                    objectPath[0] +
                    '" does NOT exist; use one of existing columns: ' +
                    JSON.stringify(Object.keys(data[0]))
            );

            return data;
        }

        const getValueFromObjectPath = (object: CommonObjectType, objectPath: string[]) => {
            let value: CommonObjectType = object;

            objectPath.forEach((attr: string, iterator: number) => {
                if (
                    value.hasOwnProperty(attr) &&
                    (typeof value[attr] === 'object' || iterator === objectPath.length - 1)
                ) {
                    value = value[attr];
                }
            });

            return value;
        };

        const dataForSort: CommonObjectType[] = data
            .map((item) => ({
                item,
                sortValue: getValueFromObjectPath(item, objectPath),
            }))
            .filter((item) => item.sortValue !== null);

        if (dataForSort.length < data.length) {
            return data;
        }

        const directionMultiplier: number = direction.toUpperCase() === 'ASC' ? 1 : -1;

        return dataForSort
            .sort((a, b) => {
                if (isNaN(a.sortValue)) {
                    return directionMultiplier * ('' + a.sortValue).localeCompare('' + b.sortValue);
                }

                return directionMultiplier * (parseFloat(a.sortValue) - parseFloat(b.sortValue));
            })
            .map((item) => item.item);
    }

    static asNumeric(
        data: CommonObjectType[],
        columnName: string = '',
        direction: string = 'ASC'
    ): CommonObjectType[] {
        if (columnName !== '' && !this.isNumericSortPossible(data, columnName)) {
            Log.warning('Sorting as numeric is NOT possible - sorting as text instead');

            return this.asText(data, columnName, direction);
        }

        const directionMultiplier: number = direction.toUpperCase() === 'ASC' ? 1 : -1;

        return data.sort(
            (a, b) => directionMultiplier * (parseFloat(a[columnName]) - parseFloat(b[columnName]))
        );
    }

    static numericArray(data: number[], direction: string = 'ASC'): number[] {
        const directionMultiplier: number = direction.toUpperCase() === 'ASC' ? 1 : -1;

        return data.sort((a, b) => directionMultiplier * (a - b));
    }

    static asText(
        data: CommonObjectType[],
        columnName: string = '',
        direction: string = 'ASC'
    ): CommonObjectType[] {
        const directionMultiplier: number = direction.toUpperCase() === 'ASC' ? 1 : -1;

        if (columnName === '') {
            return data.sort((a, b) => directionMultiplier * ('' + a).localeCompare('' + b));
        }

        return data.sort(
            (a, b) => directionMultiplier * ('' + a[columnName]).localeCompare('' + b[columnName])
        );
    }

    static asBoolean(
        data: CommonObjectType[],
        columnName: string,
        direction: string = 'ASC'
    ): CommonObjectType[] {
        const directionMultiplier: number = direction.toUpperCase() === 'ASC' ? 1 : -1;

        return data.sort((a, b) => {
            if (
                !a.hasOwnProperty(columnName) ||
                !b.hasOwnProperty(columnName) ||
                a[columnName] === b[columnName]
            ) {
                return 0;
            }

            if (b[columnName] === false) {
                return directionMultiplier;
            }

            return directionMultiplier * -1;
        });
    }

    static isNumericSortPossible(data: CommonObjectType[], columnName: string = ''): boolean {
        const dataMissingColumnName: CommonObjectType[] = data.filter(
            (item) => !item.hasOwnProperty(columnName)
        );

        if (dataMissingColumnName.length > 0) {
            Log.warning(
                'Numeric sort NOT possible - column "' +
                    columnName +
                    '" does NOT exist for ' +
                    dataMissingColumnName.length +
                    ' records, e. g. ' +
                    JSON.stringify(dataMissingColumnName[0])
            );

            return false;
        }

        const dataInColumnNameNotNumeric: CommonObjectType[] = data.filter(
            (item) => !Strings.isNumeric(item[columnName])
        );

        if (dataInColumnNameNotNumeric.length > 0) {
            Log.warning(
                'Numeric sort NOT possible - data in column "' +
                    columnName +
                    '" is not a numeric value for ' +
                    dataInColumnNameNotNumeric.length +
                    ' records, e. g. ' +
                    JSON.stringify(dataInColumnNameNotNumeric[0])
            );

            return false;
        }

        return true;
    }
}

export default Sort;
