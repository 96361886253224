import React from 'react';
import { SkeletonRectangular } from '@profesia/adamui/components/common/skeleton';
import { Stack } from '@mui/material';

const DashboardJobItemSkeleton = () => (
    <Stack flex={1} mt={1.5} mb={1} py={1} direction="row" justifyContent="space-between">
        <Stack spacing={2.375}>
            <SkeletonRectangular width={244} height={18} borderRadius={26} />
            <SkeletonRectangular width={116} height={10} borderRadius={26} />
            <SkeletonRectangular width={188} height={10} borderRadius={26} />
        </Stack>
        <Stack spacing={1} alignItems="center" justifyContent="start">
            <SkeletonRectangular width={42} height={26} borderRadius={26} />
            <SkeletonRectangular width={84} height={18} borderRadius={26} />
        </Stack>
    </Stack>
);

export default DashboardJobItemSkeleton;
