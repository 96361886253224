import {
    RECEIVE_EMAIL_TEMPLATES_SETTINGS_MODEL,
    REQUEST_EMAIL_TEMPLATES_SETTINGS_MODEL,
    PROCESS_EMAIL_TEMPLATE,
    PROCESS_EMAIL_TEMPLATE_FAILURE,
    PROCESS_EMAIL_TEMPLATE_SUCCESS,
    REMOVE_EMAIL_TEMPLATE,
    REMOVE_EMAIL_TEMPLATE_FAILURE,
    REMOVE_EMAIL_TEMPLATE_SUCCESS,
} from '../actions/EmailTemplatesSettingsActions';
import TemplateAssistantHelper from '../helpers/components/TemplateAssistantHelper';

export const emailTemplatesSettingsModel = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_EMAIL_TEMPLATES_SETTINGS_MODEL:
            return {
                ...state,
                isSuccess: false,
            };
        case RECEIVE_EMAIL_TEMPLATES_SETTINGS_MODEL:
            return {
                ...state,
                ...action.payload,
            };
        case PROCESS_EMAIL_TEMPLATE_SUCCESS:
            let emailTemplates =
                action.meta.requestPayload.savingMode ===
                TemplateAssistantHelper.TEMPLATE_SAVING_MODE_UPDATE
                    ? state.emailTemplates.filter(
                          (emailTemplate) => emailTemplate.id !== action.meta.requestPayload.id
                      )
                    : state.emailTemplates;

            emailTemplates.push(action.payload.emailTemplate);

            return {
                ...state,
                emailTemplates: emailTemplates,
                isSuccess: true,
            };
        case REMOVE_EMAIL_TEMPLATE:
            return {
                ...state,
                removedEmailTemplate: state.emailTemplates.find(
                    (emailTemplate) => parseInt(action.payload.id) === emailTemplate.id
                ),
                emailTemplates: state.emailTemplates.filter(
                    (emailTemplate) => parseInt(action.payload.id) !== emailTemplate.id
                ),
            };
        case REMOVE_EMAIL_TEMPLATE_FAILURE:
            return {
                ...state,
                emailTemplates: state.emailTemplates.concat([state.removedEmailTemplate]),
                isSuccess: true,
            };
        case REMOVE_EMAIL_TEMPLATE_SUCCESS:
            return {
                ...state,
                isSuccess: true,
            };
        default:
            return state;
    }
};

export const isFetchingEmailTemplatesSettings = (state = false, action) => {
    switch (action.type) {
        case REQUEST_EMAIL_TEMPLATES_SETTINGS_MODEL:
            return true;
        case RECEIVE_EMAIL_TEMPLATES_SETTINGS_MODEL:
            return false;
        default:
            return state;
    }
};

export const isProcessingEmailTemplate = (state = false, action) => {
    switch (action.type) {
        case PROCESS_EMAIL_TEMPLATE:
        case REMOVE_EMAIL_TEMPLATE:
            return true;
        case PROCESS_EMAIL_TEMPLATE_SUCCESS:
        case PROCESS_EMAIL_TEMPLATE_FAILURE:
        case REMOVE_EMAIL_TEMPLATE_SUCCESS:
        case REMOVE_EMAIL_TEMPLATE_FAILURE:
            return false;
        default:
            return state;
    }
};
