import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    getCandidatesForAction,
    requestAddTagsModel,
} from '../../actions/ActionWithCandidateActions';
import Heading from '../Heading';
import Translations from '../../helpers/Translations';
import CandidateSidebarTags from './CandidateSidebar/CandidateSidebarTags';
import Arrays from '../../helpers/Arrays';
import FormSkeleton from '../../skeletons/components/FormSkeleton';

class AddTags extends React.Component {
    componentDidMount() {
        this.props.requestAddTagsModel();
        this.props.getCandidatesForAction(this.props.candidateIds);
    }

    render = () => {
        const { candidateActionModel, candidateIds, isFetchingCandidatesForAction, tags, errors } =
            this.props;

        if (
            isFetchingCandidatesForAction === true ||
            candidateActionModel.hasOwnProperty('tags') === false
        ) {
            return <FormSkeleton />;
        }

        const companyTags = Arrays.getUniqueArrayOfObjects(
            candidateActionModel.tags.concat(tags),
            'tagId'
        );

        return (
            <>
                {candidateIds.length > 1 && (
                    <Heading className="highlighted border" as="h3">
                        {Translations.getStatic('chosenCandidates')}
                        {': '}
                        {candidateIds.length}
                    </Heading>
                )}

                <CandidateSidebarTags
                    isFetchingCandidateTag={false}
                    tags={tags}
                    companyTags={companyTags}
                    candidateId={candidateIds[0]}
                    errors={errors}
                    isReadOnly={false}
                    isBulk={true}
                    onAddTag={(tag) => this.props.onAddTag(tag)}
                    onRemoveTag={(tagId) => this.props.onRemoveTag(tagId)}
                />
            </>
        );
    };
}

AddTags.propTypes = {
    candidateIds: PropTypes.array.isRequired,
};

AddTags.defaultProps = {};

const mapStateToProps = (state) => ({
    candidateActionModel: state.candidateActionModel,
    candidateModel: state.candidateModel,
    isFetchingCandidatesForAction: state.isFetchingCandidatesForAction,
});

const mapDispatchToProps = {
    requestAddTagsModel,
    getCandidatesForAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTags);
