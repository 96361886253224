import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import Button from '../Button';
import { EditorState, Modifier } from 'draft-js';
import Translations from '../../helpers/Translations';

class CustomToolbar extends React.Component {
    insertVariable = (variable) => {
        const { editorState, onChange } = this.props;

        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity('VARIABLE', 'Immutable');
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        const content = Modifier.replaceText(
            contentStateWithEntity,
            editorState.getSelection(),
            variable,
            null,
            entityKey
        );

        let newEditorState = EditorState.push(editorState, content, 'insert-characters');

        newEditorState = EditorState.moveSelectionToEnd(newEditorState);
        newEditorState = EditorState.moveFocusToEnd(newEditorState);

        onChange(newEditorState);
    };

    render() {
        return (
            <Button.Group className="float-left">
                {this.props.toolbarActions.includes('variables') === true ? (
                    <Dropdown
                        button
                        className="icon small options-overflow"
                        icon={{ className: 'icon-subject_small' }}
                        text={Translations.getStatic('insertVariable')}
                    >
                        <Dropdown.Menu>
                            {this.props.variables.map((variable) => (
                                <Dropdown.Item
                                    text={variable}
                                    key={variable}
                                    onClick={() => this.insertVariable(variable)}
                                />
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                ) : null}
            </Button.Group>
        );
    }
}

CustomToolbar.propTypes = {
    variables: PropTypes.array.isRequired,
    toolbarActions: PropTypes.array,
};

CustomToolbar.defaultProps = {
    toolbarActions: ['variables', 'bold', 'italic'],
};

export default CustomToolbar;
