export const SET_PAGE_ERROR = 'SET_PAGE_ERROR';
export const UNSET_PAGE_ERROR = 'UNSET_PAGE_ERROR';
export const SET_ERROR_FOCUS = 'SET_ERROR_FOCUS';
export const UNSET_ERROR_FOCUS = 'UNSET_ERROR_FOCUS';

export const setPageError = () => ({
    type: SET_PAGE_ERROR,
});

export const unsetPageError = () => ({
    type: UNSET_PAGE_ERROR,
});

export const setErrorFocus = () => ({
    type: SET_ERROR_FOCUS,
});

export const unsetErrorFocus = () => ({
    type: UNSET_ERROR_FOCUS,
});
