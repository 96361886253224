import { Dispatch, Action } from 'redux';
import { CompanyDetailsUpdateResponse, Logo } from '@profesia/mark-offer-form/services/types';
import apiRequest from './apiRequest';
import { CompanyData, CompanyDataPayload, RemoveLogo } from './types';
import { SET_COMPANY_DATA } from '../../../actions/LoginActions';
import Language from '../../../helpers/Language';
import { stripBase64Prefix } from './helpers';

const buildLogoPayload = (logo: any): { data: Logo } | RemoveLogo | null => {
    if (logo?.removeLogo) return { removeLogo: true };

    if (!logo) return null;

    return {
        data: {
            imgExtension: logo.imgExtension || null,
            sourceImg: stripBase64Prefix(logo.sourceImg),
            cropperData: logo.cropperData,
            croppedImg: stripBase64Prefix(logo.croppedImg),
        },
    };
};

const updateCompanyDetails = async (
    companyData: CompanyData,
    dispatch: Dispatch<Action>
): Promise<CompanyDetailsUpdateResponse> => {
    const { companyId, businessAreaId, characteristics, languageFlag, logo } = companyData;
    const pageLanguage = Language.get();

    if (!companyId) {
        throw new Error('Company ID is required.');
    }

    const payload: CompanyDataPayload = {
        businessAreaId: businessAreaId,
        shortCharacteristic: characteristics,
        languageFlag: languageFlag,
        logo: buildLogoPayload(logo),
    };

    try {
        const response = await apiRequest<CompanyDetailsUpdateResponse>(`company/${companyId}`, {
            method: 'PUT',
            body: JSON.stringify(payload),
        });

        dispatch({
            type: SET_COMPANY_DATA,
            payload: {
                businessArea: response.businessArea,
                companyCharacteristics: { [languageFlag]: companyData.characteristics },
                ...(pageLanguage === languageFlag && {
                    characteristics: companyData.characteristics,
                }),
                logo: companyData.logo,
            },
        });

        return response;
    } catch (error) {
        throw new Error('Failed to update company details');
    }
};

export default updateCompanyDetails;
