import {
    RECEIVE_STATISTICS_MODEL,
    REQUEST_STATISTICS_MODEL,
    REQUEST_STATISTICS_MONTHLY_DATA,
    RECEIVE_STATISTICS_MONTHLY_DATA,
} from '../actions/StatisticsActions';

export const isFetchingStatisticsModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_STATISTICS_MODEL:
            return true;
        case RECEIVE_STATISTICS_MODEL:
            return false;
        default:
            return state;
    }
};

export const isFetchingStatisticsMonthlyData = (state = false, action) => {
    switch (action.type) {
        case REQUEST_STATISTICS_MONTHLY_DATA:
            return true;
        case RECEIVE_STATISTICS_MONTHLY_DATA:
            return false;
        default:
            return state;
    }
};

export const statisticsModel = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_STATISTICS_MODEL:
            return {};
        case RECEIVE_STATISTICS_MODEL:
            return {
                ...state,
                settings: action.payload.settings,
            };
        default:
            return state;
    }
};

export const statisticsMonthlyData = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_STATISTICS_MONTHLY_DATA:
            return {};
        case RECEIVE_STATISTICS_MONTHLY_DATA:
            return {
                ...state,
                month: action.payload.month,
                monthData: action.payload.monthData,
            };
        default:
            return state;
    }
};
