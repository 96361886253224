import React, { Component } from 'react';
import { connect } from 'react-redux';
import { requestDashboardModel, getDataForUser } from '../../../actions/b2b/DashboardActions';
import Skeletons from '../../../helpers/Skeletons';
import loginManagerModelSkeleton from '../../../skeletons/models/loginManagerModelSkeleton';
import dashboardModelSkeleton from '../../../skeletons/models/b2b/dashboardModelSkeleton';
import DashboardActiveOffersList from './DashboardActiveOffersList';
import Divider from '../../Divider';
import Translations from '../../../helpers/Translations';
import Select from '@profesia/adamui/components/common/dropdown/SelectDefault';
import Grid from '../../Grid';
import User from '../../../helpers/User';
import Constants from '../../../helpers/Constants';
import { openNoModal } from '../../../actions/ModalActions';
import DashboardNewCandidates from './DashboardNewCandidates';
import DashboardStatistics from './DashboardStatistics';
import DashboardProfesiaNews from './DashboardProfesiaNews';
import Url from '../../../helpers/Url';
import DashboardDYTipsSpace from './DashboardDYTipsSpace';
import { previousPage } from '../../../actions/HeaderActions';
import B2bDashboardSkeleton from '../../../skeletons/components/B2bDashboardSkeleton';
import Storages from '../../../helpers/Storages';

class Dashboard extends Component {
    state = {
        isVisibleLabel: false,
    };

    componentDidMount = () => {
        const selectedUser = Storages.getSession(Constants.B2B_DASHBOARD_USER_STORAGE_KEY, null);

        if (User.allowedToB2bDashboard(this.props.loginManagerModel) === false) {
            this.redirectToProfesiaDashboard();
        } else {
            this.props.requestDashboardModel(selectedUser === null ? null : selectedUser.userId);
        }

        this.props.previousPage(Url.build(Url.getUrlParameter('action')));

        this.setState(
            selectedUser === null
                ? {
                      userId: null,
                  }
                : selectedUser
        );
    };

    componentDidUpdate(prevProps) {
        if (
            (Skeletons.isSkeleton(prevProps.loginManagerModel) === true &&
                Skeletons.isSkeleton(this.props.loginManagerModel) === false) ||
            (Skeletons.isSkeleton(prevProps.dashboardModel) === true &&
                Skeletons.isSkeleton(this.props.dashboardModel) === false &&
                Skeletons.isSkeleton(this.props.loginManagerModel) === false)
        ) {
            const selectedUser = Storages.getSession(
                Constants.B2B_DASHBOARD_USER_STORAGE_KEY,
                null
            );

            this.setState(
                selectedUser === null
                    ? {
                          userId: User.getUserId(this.props.loginManagerModel),
                      }
                    : selectedUser
            );
        }

        if (
            Skeletons.isSkeleton(prevProps.loginManagerModel) === true &&
            Skeletons.isSkeleton(this.props.loginManagerModel) === false
        ) {
            if (User.allowedToB2bDashboard(this.props.loginManagerModel) === false) {
                this.redirectToProfesiaDashboard();
            }
        }
    }

    redirectToProfesiaDashboard = () => {
        Url.redirectTo(
            '/company_details.php' + User.getLoggedAdminParams(this.props.loginManagerModel)
        );
    };

    switchDashboard = (selected) => {
        const selectedUser = {
            userId: parseInt(selected.value),
            userName: selected.text,
        };

        this.setState(selectedUser);

        Storages.setSession(Constants.B2B_DASHBOARD_USER_STORAGE_KEY, selectedUser);

        this.props.getDataForUser(parseInt(selected.value));
    };

    render() {
        const { displaySize, dashboardModel, isFetchingDashboardDataForUser, loginManagerModel } =
            this.props;

        if (
            Skeletons.isSkeleton(loginManagerModel) === true ||
            Skeletons.isSkeleton(dashboardModel) === true ||
            isFetchingDashboardDataForUser === true
        ) {
            return <B2bDashboardSkeleton />;
        }

        if (
            Skeletons.isSkeleton(loginManagerModel) === false &&
            User.allowedToB2bDashboard(loginManagerModel) === false
        ) {
            return null;
        }

        const usersInDashboardSwitcher = dashboardModel.users
            .filter(
                (user) =>
                    user.userRole.id !== Constants.USER_ROLE_EXTERNAL_EDITOR &&
                    user.userRole.id !== Constants.USER_ROLE_EXTERNAL_VISITOR
            )
            .map((user) => ({
                key: user.id,
                label: user.name,
                id: user.id,
            }));

        usersInDashboardSwitcher.unshift({
            key: 0,
            label: Translations.getStatic('allUsers'),
            id: 0,
        });

        const showDashboardSwitcher =
            usersInDashboardSwitcher.length > 1 && Skeletons.isSkeleton(dashboardModel) === false;
        const { newCandidatesLimitForPreview } = dashboardModel.limits;

        return (
            <div className="page">
                <div className="template-header header-basic">
                    <div className="width_full">
                        <Grid>
                            <Grid.Row verticalAlign={'middle'} columns={4}>
                                <Grid.Column computer={10} tablet={10} mobile={16}>
                                    <div className="header__title">
                                        <h1>{Translations.getStatic('home')}</h1>
                                    </div>
                                </Grid.Column>
                                <Grid.Column
                                    width={3}
                                    textAlign={'right'}
                                    hidden={displaySize.isMobile === true}
                                >
                                    {showDashboardSwitcher === true &&
                                        this.state.isVisibleLabel === true && (
                                            <span className="text-form__label">
                                                {Translations.getStatic('userDashboardB2b')}
                                            </span>
                                        )}
                                </Grid.Column>
                                <Grid.Column
                                    computer={displaySize.isMobile === true ? 6 : 3}
                                    tablet={6}
                                    mobile={10}
                                    className={
                                        displaySize.isMobile === true ? '' : 'padding-left_none'
                                    }
                                >
                                    {showDashboardSwitcher === true &&
                                        Skeletons.isSkeleton(dashboardModel) === false && (
                                            <div
                                                className={
                                                    'header__element' +
                                                    (displaySize.isMobile === true
                                                        ? ''
                                                        : ' padding-left_none')
                                                }
                                            >
                                                <Select
                                                    className="ellipsis compact selection MuiSelect-light"
                                                    options={usersInDashboardSwitcher}
                                                    value={this.state.userId ?? ''}
                                                    onChange={(value) =>
                                                        this.switchDashboard(
                                                            usersInDashboardSwitcher.find(
                                                                (user) => user.value === value
                                                            )
                                                        )
                                                    }
                                                    onMouseEnter={() =>
                                                        this.setState({ isVisibleLabel: true })
                                                    }
                                                    onMouseLeave={() =>
                                                        this.setState({ isVisibleLabel: false })
                                                    }
                                                />
                                            </div>
                                        )}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </div>

                <div className="page-content" id="scrollableDiv">
                    <div className="page-content_left">
                        {displaySize.isDesktopProfesia === false && (
                            <>
                                <Divider hidden className="size_8" />

                                <DashboardNewCandidates
                                    isLocked={
                                        dashboardModel.activeOffers.filter(
                                            (offer) => offer.hasLockedCandidates === true
                                        ).length > 0
                                    }
                                    candidatesInNewStage={dashboardModel.candidatesInNewStage.slice(
                                        0,
                                        parseInt(newCandidatesLimitForPreview)
                                    )}
                                    newCandidatesTotalCount={parseInt(
                                        dashboardModel.newCandidatesTotalCount
                                    )}
                                />

                                {displaySize.isMobileProfesia === true ? (
                                    <Divider hidden className="size_16 multiple" />
                                ) : (
                                    <Divider hidden className="size_24" />
                                )}
                            </>
                        )}

                        <DashboardActiveOffersList
                            limits={dashboardModel.limits}
                            offers={dashboardModel.activeOffers}
                            links={dashboardModel.settings.links}
                            user={this.props.loginManagerModel.user}
                            selectedUserId={this.state.userId}
                            selectedUserName={this.state.userName}
                            displaySize={displaySize}
                        />
                    </div>

                    {
                        <div
                            className={
                                'page-content_right' +
                                (Skeletons.isSkeleton(dashboardModel) === true ? ' skeleton' : '')
                            }
                        >
                            {displaySize.isDesktopProfesia === true && (
                                <>
                                    <Divider hidden className="size_32" />
                                    <DashboardNewCandidates
                                        isLocked={
                                            dashboardModel.activeOffers.filter(
                                                (offer) => offer.hasLockedCandidates === true
                                            ).length > 0
                                        }
                                        candidatesInNewStage={dashboardModel.candidatesInNewStage.slice(
                                            0,
                                            parseInt(newCandidatesLimitForPreview)
                                        )}
                                        newCandidatesTotalCount={parseInt(
                                            dashboardModel.newCandidatesTotalCount
                                        )}
                                    />
                                </>
                            )}

                            {Skeletons.isSkeleton(dashboardModel) === false && (
                                <>
                                    <DashboardDYTipsSpace />
                                    {displaySize.isMobileProfesia === true ? (
                                        <Divider hidden className="size_16 multiple" />
                                    ) : (
                                        <Divider hidden className="size_24" />
                                    )}
                                    <DashboardStatistics />
                                    {displaySize.isMobileProfesia === true ? (
                                        <Divider hidden className="size_16 multiple" />
                                    ) : (
                                        <Divider hidden className="size_24" />
                                    )}
                                    <DashboardProfesiaNews />
                                </>
                            )}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    displaySize: state.displaySize,
    loginManagerModel: Skeletons.getData(state.loginManagerModel, loginManagerModelSkeleton),
    dashboardModel: Skeletons.getData(state.b2bDashboardModel, dashboardModelSkeleton),
    isFetchingB2bDashboardModel: state.isFetchingB2bDashboardModel,
    isFetchingDashboardDataForUser: state.isFetchingB2bDashboardDataForUser,
});

const mapDispatchToProps = {
    requestDashboardModel,
    getDataForUser,
    openNoModal,
    previousPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
