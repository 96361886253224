class Time {
    /**
     * @param {number} startTime    use previously returned startTime to get duration
     * @param {string} unit         default: microseconds, milliseconds, seconds
     * @param {number} precision   sub-decimal precision
     */
    static getTime(startTime: number = 0, unit: string = 'microseconds', precision: number = 2) {
        if (startTime === 0) {
            return performance.now() * 1000;
        }

        const time: number = performance.now() * 1000 - startTime;

        switch (unit) {
            case 'milliseconds':
                return Math.round((time / 1000) * 10 ** precision) / 10 ** precision;

            case 'seconds':
                return Math.round((time / 1000 / 1000) * 10 ** precision) / 10 ** precision;

            case 'microseconds':
            default:
                return Math.round(time * 10 ** precision) / 10 ** precision;
        }
    }
}

export default Time;
