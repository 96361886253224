import {
    REQUEST_CANDIDATE_QUESTIONNAIRE_MODEL,
    RECEIVE_CANDIDATE_QUESTIONNAIRE_MODEL,
    SET_CANDIDATE_QUESTIONNAIRE_ERRORS,
    SAVE_CANDIDATE_QUESTIONNAIRE,
    SAVE_CANDIDATE_QUESTIONNAIRE_FAILURE,
    SAVE_CANDIDATE_QUESTIONNAIRE_SUCCESS,
} from '../actions/CandidateQuestionnaireActions';

export const isFetchingCandidateQuestionnaireModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_CANDIDATE_QUESTIONNAIRE_MODEL:
            return true;
        case RECEIVE_CANDIDATE_QUESTIONNAIRE_MODEL:
            return false;
        default:
            return state;
    }
};

export const isSavingCandidateQuestionnaire = (state = false, action) => {
    switch (action.type) {
        case SAVE_CANDIDATE_QUESTIONNAIRE:
            return true;
        case SAVE_CANDIDATE_QUESTIONNAIRE_SUCCESS:
        case SAVE_CANDIDATE_QUESTIONNAIRE_FAILURE:
            return false;
        default:
            return state;
    }
};
export const candidateQuestionnaireModel = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_CANDIDATE_QUESTIONNAIRE_MODEL:
            return {};
        case RECEIVE_CANDIDATE_QUESTIONNAIRE_MODEL:
            return {
                ...state,
                settings: action.payload.settings,
                job: action.payload.job,
                errors: [],
            };
        case SAVE_CANDIDATE_QUESTIONNAIRE:
            return {
                ...state,
                errors: [],
                isSuccess: false,
            };
        case SAVE_CANDIDATE_QUESTIONNAIRE_SUCCESS:
            return {
                ...state,
                isSuccess: true,
            };
        case SET_CANDIDATE_QUESTIONNAIRE_ERRORS:
            return {
                ...state,
                errors: action.payload.errors,
            };
        default:
            return state;
    }
};
