import Constants from '../../Constants';
import CandidatesHelper from '../../components/CandidatesHelper';
import Url from '../../Url';

export default class CopyCandidatesReducer {
    static process(state, action) {
        if (state.hasOwnProperty('candidates') === false) {
            return state;
        }

        if (
            action.meta.requestPayload.reducerType ===
            Constants.CANDIDATES_REDUCER_TYPE_B2B_DASHBOARD
        ) {
            return this.processCopyActionOnB2bDashboard(state, action);
        }

        if (
            action.meta.requestPayload.reducerType === Constants.CANDIDATES_REDUCER_TYPE_JOB_DETAIL
        ) {
            return this.processCopyActionOnJobDetail(state, action);
        }

        if (
            action.meta.requestPayload.reducerType ===
            Constants.CANDIDATES_REDUCER_TYPE_GLOBAL_SEARCH
        ) {
            return this.processCopyActionOnGlobalSearch(state, action);
        }

        return this.processCopyActionOnDashboard(state, action);
    }

    static prepareCandidateAfterCopy(action, candidate) {
        let newCandidate = {
            ...candidate,
            ...CandidatesHelper.prepareWasCandidateSentToColleague(
                action.meta.requestPayload,
                action.payload.candidatesSentToColleague,
                candidate
            ),
        };

        if (
            action.meta.requestPayload.copyParams.removeCandidate === true &&
            action.meta.requestPayload.candidateIds.includes(candidate.id) === true
        ) {
            newCandidate = {
                ...newCandidate,
                stateId: Constants.CANDIDATE_STATE_REFUSED,
            };
        }

        return newCandidate;
    }

    static processNewDuplicities(newCandidates, originalCandidates) {
        const changedCandidates = [];

        newCandidates.forEach((candidate) => {
            if (candidate?.hasDuplicities === true && candidate.duplicities.length > 0) {
                const originalCandidatesWithNewDuplicities = originalCandidates.filter(
                    (original) => candidate.duplicities.includes(original.id) === true
                );

                if (originalCandidatesWithNewDuplicities.length > 0) {
                    originalCandidatesWithNewDuplicities.forEach((original) => {
                        if (
                            original?.hasDuplicities !== true ||
                            original.duplicities.length > 0 ||
                            original.duplicities.includes(candidate.id) === false
                        ) {
                            changedCandidates.push({
                                ...original,
                                hasDuplicities: true,
                                duplicities: original.duplicities.concat([candidate.id]),
                            });
                        }
                    });
                }
            }
        });

        return originalCandidates.map((originalCandidate) => {
            const changedCandidate = changedCandidates.find(
                (changedCandidate) => originalCandidate.id === changedCandidate.id
            );

            if (changedCandidate === undefined) {
                return originalCandidate;
            }

            return {
                ...originalCandidate,
                ...changedCandidate,
            };
        });
    }

    static processCopyActionOnB2bDashboard(state, action) {
        let result = {
            ...state,
        };

        if (action.payload.newCandidates.length > 0) {
            if (state.hasOwnProperty('activeOffers') === true) {
                const newCandidate = action.payload.newCandidates[0];

                const newJobId = newCandidate.jobId;
                const newStageId = newCandidate.stage.id;
                const firstOriginalCandidate = state.candidates.find(
                    (candidate) =>
                        action.meta.requestPayload.candidateIds.includes(candidate.id) === true
                );

                result = {
                    ...result,
                    activeOffers: state.activeOffers.map((offer) => {
                        if (newJobId === offer.jobId) {
                            return {
                                ...offer,
                                counts: {
                                    activeCandidatesCount:
                                        newStageId === Constants.CANDIDATE_STAGE_NEW_ID
                                            ? offer.counts.activeCandidatesCount + 1
                                            : offer.counts.activeCandidatesCount,
                                    allCandidatesCount:
                                        newStageId === Constants.CANDIDATE_STAGE_NEW_ID
                                            ? offer.counts.allCandidatesCount + 1
                                            : offer.counts.allCandidatesCount,
                                    newCandidatesCount: offer.counts.newCandidatesCount + 1,
                                },
                            };
                        }

                        if (
                            action.meta.requestPayload.copyParams.removeCandidate === true &&
                            firstOriginalCandidate !== undefined &&
                            firstOriginalCandidate.jobId === offer.jobId
                        ) {
                            return {
                                ...offer,
                                counts: {
                                    activeCandidatesCount: offer.counts.activeCandidatesCount - 1,
                                    allCandidatesCount: offer.counts.allCandidatesCount - 1,
                                    newCandidatesCount: offer.counts.newCandidatesCount - 1,
                                },
                            };
                        }

                        return offer;
                    }),
                };

                const isNewCandidateFromActiveOffers =
                    state.activeOffers.map((offer) => offer.jobId).includes(newCandidate.jobId) ===
                    true;

                if (isNewCandidateFromActiveOffers === true) {
                    result = {
                        ...result,
                        newCandidatesTotalCount: result.newCandidatesTotalCount + 1,
                        candidatesInNewStage: result.candidatesInNewStage.concat([newCandidate]),
                        candidates: result.candidates.concat([newCandidate]),
                        candidateIdsPairs: result.candidateIdsPairs.concat([
                            {
                                id: newCandidate.id,
                                jobId: newCandidate.jobId,
                            },
                        ]),
                    };
                }

                if (action.meta.requestPayload.copyParams.removeCandidate) {
                    result = {
                        ...result,
                        newCandidatesTotalCount: result.newCandidatesTotalCount - 1,
                        candidatesInNewStage: result.candidatesInNewStage.filter(
                            (candidate) =>
                                action.meta.requestPayload.candidateIds.includes(candidate.id) ===
                                false
                        ),
                        candidates: result.candidates.filter(
                            (candidate) =>
                                action.meta.requestPayload.candidateIds.includes(candidate.id) ===
                                false
                        ),
                        candidateIdsPairs: result.candidateIdsPairs.filter(
                            (pair) =>
                                action.meta.requestPayload.candidateIds.includes(pair.id) === false
                        ),
                    };
                }
            }
        }

        return result;
    }

    static processCopyActionOnDashboard(state, action) {
        let result = {};

        if (action.payload.newCandidates.length > 0) {
            result = {
                ...state,
                candidates: this.processNewDuplicities(
                    action.payload.newCandidates,
                    state.candidates.map((candidate) =>
                        this.prepareCandidateAfterCopy(action, candidate)
                    )
                ).concat(action.payload.newCandidates),
            };

            if (state.hasOwnProperty('jobs') === true) {
                const newJobId = action.payload.newCandidates[0].jobId;
                const newStageId = action.payload.newCandidates[0].stage.id;
                const firstOriginalCandidate = state.candidates.find(
                    (candidate) =>
                        action.meta.requestPayload.candidateIds.includes(candidate.id) === true
                );

                result = {
                    ...result,
                    jobs: state.jobs.map((job) => {
                        if (newJobId === job.id) {
                            return {
                                ...job,
                                countActiveApplicants:
                                    newStageId === Constants.CANDIDATE_STAGE_NEW_ID
                                        ? job.countActiveApplicants + 1
                                        : job.countActiveApplicants,
                                countAllApplicants:
                                    newStageId === Constants.CANDIDATE_STAGE_NEW_ID
                                        ? job.countAllApplicants + 1
                                        : job.countAllApplicants,
                                countNewApplicants: job.countNewApplicants + 1,
                            };
                        }

                        if (
                            action.meta.requestPayload.copyParams.removeCandidate === true &&
                            firstOriginalCandidate !== undefined &&
                            firstOriginalCandidate.jobId === job.id
                        ) {
                            return {
                                ...job,
                                countActiveApplicants: job.countActiveApplicants - 1,
                                countAllApplicants: job.countAllApplicants - 1,
                                countNewApplicants: job.countNewApplicants - 1,
                            };
                        }

                        return job;
                    }),
                    jobsBasicData: state.jobsBasicData.map((job) => {
                        if (newJobId === job.id) {
                            return {
                                ...job,
                                countNewApplicants: job.countNewApplicants + 1,
                            };
                        }

                        if (
                            action.meta.requestPayload.copyParams.removeCandidate === true &&
                            firstOriginalCandidate !== undefined &&
                            firstOriginalCandidate.jobId === job.id
                        ) {
                            return {
                                ...job,
                                countNewApplicants: job.countNewApplicants - 1,
                            };
                        }

                        return job;
                    }),
                };
            }
        }

        return result;
    }

    static processCopyActionOnJobDetail(state, action) {
        if (state.hasOwnProperty('fullCandidates') === false) {
            return state;
        }

        let result = {};

        if (action.payload.newCandidates.length > 0) {
            result = {
                ...state,
                candidates: state.candidates.map((candidate) =>
                    this.prepareCandidateAfterCopy(action, candidate)
                ),
                ...CandidatesHelper.mergeFullCandidatesDataIntoStateWithCallback(
                    state,
                    (candidate) =>
                        action.meta.requestPayload.candidateIds.includes(candidate.id) === true,
                    (candidate) => ({
                        ...candidate,
                        ...this.prepareCandidateAfterCopy(action, candidate),
                    })
                ),
            };

            if (result.fullCandidates !== undefined) {
                result = {
                    ...result,
                    fullCandidates: this.processNewDuplicities(
                        action.payload.newCandidates,
                        result.fullCandidates
                    ),
                };
            }

            if (
                parseInt(Url.getUrlParameter('job')) ===
                parseInt(action.payload.newCandidates[0].jobId)
            ) {
                result = {
                    ...result,
                    candidates: result.candidates.concat(
                        CandidatesHelper.getBasicCandidatesDataForJobDetail(
                            action.payload.newCandidates
                        )
                    ),
                    fullCandidates:
                        result.fullCandidates === undefined
                            ? action.payload.newCandidates
                            : result.fullCandidates.concat(action.payload.newCandidates),
                    fetchedCandidateIds:
                        result.fetchedCandidateIds === undefined
                            ? action.payload.newCandidates.map((candidate) => candidate.id)
                            : result.fetchedCandidateIds.concat(
                                  action.payload.newCandidates.map((candidate) => candidate.id)
                              ),
                };
            }
        }

        return result;
    }

    static processCopyActionOnGlobalSearch(state, action) {
        if (state.hasOwnProperty('basicCandidatesData') === false) {
            return state;
        }

        let result = {};

        if (action.payload.newCandidates.length > 0) {
            result = {
                ...state,
                basicCandidatesData: state.basicCandidatesData
                    .map((candidate) => this.prepareCandidateAfterCopy(action, candidate))
                    .concat(
                        CandidatesHelper.getBasicCandidatesDataForGlobalSearch(
                            action.payload.newCandidates
                        )
                    ),
                fetchedCandidateIds:
                    state.fetchedCandidateIds === undefined
                        ? action.payload.newCandidates.map((candidate) => candidate.id)
                        : state.fetchedCandidateIds.concat(
                              action.payload.newCandidates.map((candidate) => candidate.id)
                          ),
                ...CandidatesHelper.mergeFullCandidatesDataIntoStateWithCallback(
                    state,
                    (candidate) =>
                        action.meta.requestPayload.candidateIds.includes(candidate.id) === true,
                    (candidate) => ({
                        ...candidate,
                        ...this.prepareCandidateAfterCopy(action, candidate),
                    }),
                    'candidates'
                ),
                candidateIds: state.candidateIds.concat(
                    action.payload.newCandidates.map((candidate) => candidate.id)
                ),
            };

            result = {
                ...result,
                candidates: this.processNewDuplicities(
                    action.payload.newCandidates,
                    result.candidates
                ).concat(action.payload.newCandidates),
            };
        }

        return result;
    }
}
