import React from 'react';
import Form from '../Form';
import Grid from '../Grid';
import Divider from '../Divider';
import Heading from '../Heading';
import PropTypes from 'prop-types';
import AutoAnswerEditor from '../Email/AutoAnswerEditor';
import TemplateAssistantSettings from '../TemplateAssistant/TemplateAssistantSettings';
import TemplateDropdownSelector from '../TemplateAssistant/TemplateDropdownSelector';
import { connect } from 'react-redux';
import {
    requestAutoAnswerEditorModel,
    updateAutoAnswer,
} from '../../actions/AutoAnswerEditorActions';
import Translations from '../../helpers/Translations';
import Language from '../../helpers/Language';
import Constants from '../../helpers/Constants';
import AttachmentAssistantHelper from '../../helpers/components/AttachmentAssistantHelper';
import AttachmentAssistant from '../AttachmentsAssistant/AttachmentsAssistant';
import FormSkeleton from '../../skeletons/components/FormSkeleton';

class JobAutoAnswerEditor extends React.Component {
    constructor(props) {
        super(props);

        const predefinedLanguageId = this.props.languages.find(
            (language) => language.languageCode === Language.get()
        ).languageId;

        this.state = {
            selectedAutoAnswerTemplate:
                props.autoAnswerEditorModel.hasOwnProperty('autoAnswerData') === true &&
                props.autoAnswerEditorModel.autoAnswerData.id !== null
                    ? props.autoAnswerEditorModel.autoAnswerData.id
                    : props.autoAnswerId,

            languageId: predefinedLanguageId,
        };

        this.selectAutoAnswerTemplate = this.selectAutoAnswerTemplate.bind(this);
        this.disableAutoAnswer = this.disableAutoAnswer.bind(this);
    }

    componentDidMount() {
        let requestAutoAnswerEditorModel = false;

        if (this.props.autoAnswerEditorModel.hasOwnProperty('autoAnswerData') === false) {
            requestAutoAnswerEditorModel = true;
        } else {
            const id =
                this.props.autoAnswerEditorModel.autoAnswerData.id === null
                    ? 0
                    : parseInt(this.props.autoAnswerEditorModel.autoAnswerData.id);

            if (id !== parseInt(this.state.selectedAutoAnswerTemplate)) {
                requestAutoAnswerEditorModel = true;
            }
        }

        if (requestAutoAnswerEditorModel === true) {
            this.props.requestAutoAnswerEditorModel(
                this.state.selectedAutoAnswerTemplate,
                this.state.languageId
            );
        }
    }

    componentDidUpdate() {
        if (
            this.props.autoAnswerEditorModel.hasOwnProperty('autoAnswerData') &&
            this.props.autoAnswerEditorModel.autoAnswerData.languageId !== this.state.languageId
        ) {
            this.setState({
                languageId: this.props.autoAnswerEditorModel.autoAnswerData.languageId,
            });
        }
    }

    changeLanguage = (languageId) => {
        this.setState({
            languageId: languageId,
        });

        this.props.requestAutoAnswerEditorModel(0, languageId);
        this.props.onSaveAsChangedTemplate(false);
        this.props.onSaveAsNewTemplate(false);
    };

    selectAutoAnswerTemplate(autoAnswerId) {
        if (parseInt(autoAnswerId) !== parseInt(this.state.selectedAutoAnswerTemplate)) {
            this.setState({
                selectedAutoAnswerTemplate: autoAnswerId,
            });

            this.props.requestAutoAnswerEditorModel(autoAnswerId, this.state.languageId);
            this.props.onSaveAsChangedTemplate(false);
            this.props.onSaveAsNewTemplate(false);
        }
    }

    disableAutoAnswer() {
        this.props.requestAutoAnswerEditorModel(0, this.state.languageId);
        this.props.onDisableAutoAnswer();
    }

    render() {
        const { isFetchingAutoAnswerEditorModel, modalType } = this.props;

        if (isFetchingAutoAnswerEditorModel === true) {
            return <FormSkeleton />;
        }

        let autoAnswerEditorContent = null;
        let hasChanges = false;

        if (this.props.autoAnswerEditorModel.hasOwnProperty('autoAnswerData') === true) {
            const settings = (
                <TemplateAssistantSettings
                    isModal={this.props.isModal}
                    templateData={this.props.autoAnswerEditorModel.autoAnswerData}
                    strings={{
                        saveChangesInTemplate: Translations.getStatic('saveChangesInAutoAnswer'),
                        saveAsNewTemplate: Translations.getStatic('saveAsNewAutoAnswer'),
                        doNotSaveChangesInTemplate: Translations.getStatic(
                            'doNotSaveChangesInAutoAnswer'
                        ),
                    }}
                    errors={this.props.errors}
                    isChangedTemplate={this.props.isChangedTemplate}
                    isNewTemplate={this.props.isNewTemplate}
                    onUpdateTemplate={this.props.updateAutoAnswer}
                    onSaveAsChangedTemplate={this.props.onSaveAsChangedTemplate}
                    onSaveAsNewTemplate={this.props.onSaveAsNewTemplate}
                    templateType={Constants.TEMPLATE_TYPE_AUTO_ANSWER}
                />
            );

            hasChanges =
                this.props.autoAnswerEditorModel.autoAnswerData.hasOwnProperty(
                    'currentChangeHash'
                ) === true &&
                this.props.autoAnswerEditorModel.autoAnswerData.currentChangeHash !==
                    this.props.autoAnswerEditorModel.autoAnswerData.changeHash;

            const actionsWrapper =
                hasChanges === true ? (
                    <div className={'template-assistant-settings'}>{settings}</div>
                ) : null;

            autoAnswerEditorContent = (
                <>
                    {actionsWrapper}

                    {modalType && <Divider className="column" />}

                    <Heading
                        as="h3"
                        className={!modalType ? 'padding-left_none template-assistant' : ''}
                    >
                        {Translations.getStatic('message')}
                    </Heading>
                    <div className={!modalType ? 'light-group' : 'light-modal'}>
                        <AutoAnswerEditor
                            languageId={this.state.languageId}
                            emailData={this.props.autoAnswerEditorModel.autoAnswerData}
                            settings={this.props.autoAnswerEditorModel.settings}
                            errors={this.props.errors}
                            onUpdateEmailTemplate={this.props.updateAutoAnswer}
                        />

                        <Grid className="padding-top_none">
                            <Grid.Row>
                                <Grid.Column>
                                    <AttachmentAssistant
                                        candidateId={0}
                                        templateAttachments={
                                            this.props.autoAnswerEditorModel.hasOwnProperty(
                                                'autoAnswerData'
                                            ) === true
                                                ? this.props.autoAnswerEditorModel.autoAnswerData
                                                      .attachments
                                                : []
                                        }
                                        modelHashId={AttachmentAssistantHelper.createAttachmentAssistantHashIdForEmail()}
                                        isFileUploadOnly={true}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            {!modalType && <Divider hidden className="size_16" />}
                        </Grid>
                    </div>
                </>
            );
        }

        return (
            <>
                <Heading
                    as="h3"
                    className={!modalType ? 'padding-left_none padding-right_none' : ''}
                >
                    {Translations.getStatic('autoResponse')}
                    {(this.props.isModal === undefined || this.props.isModal === false) &&
                    this.props.onDisableAutoAnswer !== null ? (
                        <div
                            role="link"
                            className={
                                'primary-link' +
                                (this.props.displaySize.isMobile === true ? '' : ' float-right')
                            }
                            onClick={this.disableAutoAnswer}
                        >
                            {Translations.getStatic('removeAutoResponse')}
                        </div>
                    ) : (
                        ''
                    )}
                </Heading>

                <TemplateDropdownSelector
                    onStartFetchingData={() =>
                        this.props.onStartFetchingTemplates(this.state.languageId)
                    }
                    onSelectedValue={this.selectAutoAnswerTemplate}
                    emptyItem={null}
                    changeSelectedItem={hasChanges}
                    defaultValue={this.state.selectedAutoAnswerTemplate}
                    languageFilter={{
                        languages: this.props.languages,
                        languageId: this.state.languageId,
                        onChangeLanguage: this.changeLanguage,
                    }}
                    templatesDropdownLabel={Translations.getStatic('autoAnswerTemplate')}
                    templateType={Constants.TEMPLATE_TYPE_AUTO_ANSWER}
                />

                <Form>{autoAnswerEditorContent}</Form>

                <Divider hidden className="small" />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isFetchingAutoAnswerEditorModel: state.isFetchingAutoAnswerEditorModel,
    autoAnswerEditorModel: state.autoAnswerEditorModel,
    languages: state.loginManagerModel.languages,
    displaySize: state.displaySize,
    modalType: state.modalLogic.modalType,
});

const mapDispatchToProps = {
    requestAutoAnswerEditorModel,
    updateAutoAnswer,
};

const JobAutoAnswerEditorContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(JobAutoAnswerEditor);

JobAutoAnswerEditorContainer.propTypes = {
    languages: PropTypes.array,
    isModal: PropTypes.bool,
    errors: PropTypes.array.isRequired,
    autoAnswerId: PropTypes.number.isRequired,
    isNewTemplate: PropTypes.bool.isRequired,
    isChangedTemplate: PropTypes.bool.isRequired,
    onSaveAsNewTemplate: PropTypes.func.isRequired,
    onSaveAsChangedTemplate: PropTypes.func.isRequired,
    onStartFetchingTemplates: PropTypes.func.isRequired,
    onDisableAutoAnswer: PropTypes.func,
    modalType: PropTypes.string,
};

JobAutoAnswerEditorContainer.defaultProps = {
    languages: [],
};

export default JobAutoAnswerEditorContainer;
