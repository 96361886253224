import { CommonObjectType } from '../../interfaces/CommonTypes';

class Skeletons {
    static getData(data: CommonObjectType, skeletonData: CommonObjectType): CommonObjectType {
        if (
            typeof data === 'undefined' ||
            data === null ||
            (typeof data === 'object' && Object.keys(data).length === 0)
        ) {
            return {
                ...skeletonData,
                isSkeleton: true,
            };
        }

        return data;
    }

    static isSkeleton(data: CommonObjectType): boolean {
        return data?.isSkeleton ?? false;
    }
}

export default Skeletons;
