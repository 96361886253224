import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { alpha, Stack, styled } from '@mui/material';
import { AiStarIcon } from '@profesia/adamui/components/common/icons';
import { Typography, TypographySpan } from '@profesia/adamui/components/common/typography';
import { getColor } from '@profesia/adamui/styles/colors';
import { fontWeights } from '@profesia/adamui/styles/fonts';
const TooltipContent = ({ text }) => {
    if (!text) {
        return null;
    }
    return (_jsxs(Stack, { gap: 0.5, children: [_jsxs(AiLabel, { children: [_jsx(AiStarIcon, {}), _jsx(Typography, { color: "secondaryViolet", fontWeight: fontWeights.fontWeightMedium, children: "AI" })] }), _jsx(TypographySpan, { children: text })] }));
};
const AiLabel = styled(Stack)(({ theme: { spacing, palette } }) => ({
    backgroundColor: alpha(getColor(palette.secondaryViolet), 0.1),
    borderRadius: 2,
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: spacing(0, 0.75),
    gap: spacing(0.5),
}));
export default TooltipContent;
