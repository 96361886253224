import React from 'react';
import { styled } from '@mui/material';
import MenuItem from './Menu/MenuItem';
import HeaderHelper from '../helpers/components/HeaderHelper';

interface MobileHeaderNavBarProps {
    jobId: number;
    hasStatistics: boolean;
}

interface MenuItemType {
    action: string;
    activeActions: string[];
    clickable: boolean;
    visible: boolean;
    name?: string;
}

const menuItemsBase: MenuItemType[] = [
    {
        action: 'detail',
        activeActions: ['job'],
        clickable: true,
        visible: true,
        name: 'dashboard',
    },
    {
        action: 'candidates',
        activeActions: ['job'],
        clickable: true,
        visible: true,
        name: 'filter',
    },
    {
        action: 'candidate-sources',
        activeActions: ['job'],
        clickable: true,
        visible: true,
    },
];

const statisticsMenuItem: MenuItemType = {
    action: 'statistics',
    activeActions: ['job'],
    clickable: true,
    visible: true,
};

const MobileHeaderNavBar = ({ jobId, hasStatistics }: MobileHeaderNavBarProps) => {
    const menuItems = hasStatistics ? [...menuItemsBase, statisticsMenuItem] : menuItemsBase;

    return (
        <TabsContainer className="nav-grid">
            <div className="nav-grid__tabs">
                {menuItems.map((item) => (
                    <MenuItem key={item.action} action={item.action} item={item} jobId={jobId + '?new'} menuItemsStrings={HeaderHelper.getMenuItemsStrings()} />
                ))}
            </div>
        </TabsContainer>
    );
};

const TabsContainer = styled('div')({
    marginTop: '4px ',
    width: '100% !important',
});

export default MobileHeaderNavBar;
