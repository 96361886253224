import React, { Component } from 'react';
import { connect } from 'react-redux';
import CandidateSidebarHistoryItem from './CandidateSidebarHistoryItem';
import Select from '@profesia/adamui/components/common/dropdown/SelectDefault';
import Button from '../../Button';
import Item from '../../Item';
import Divider from '../../Divider';
import Icon from '../../Icon';
import { closeSidebarEditPanel } from '../../../actions/SidebarActions';
import PropTypes from 'prop-types';
import Translations from '../../../helpers/Translations';
import CandidateHistoryHelper from '../../../helpers/components/CandidateHistoryHelper';
import CandidateHelper from '../../../helpers/components/CandidateHelper';

class CandidateSidebarHistory extends Component {
    state = {
        selectedEvent: CandidateHistoryHelper.getCandidateHistoryFilterOptions[0].id,
        isDropdownOpen: false,
    };

    onSelectEvents = (value) => {
        this.setState({
            selectedEvent: value,
        });
    };

    render() {
        const { history, sidebarButton, isAnonymized, candidateId } = this.props;

        return (
            <>
                <div className="ui items unstackable">
                    <Item>
                        <Icon className="icon-history_small" size="mini" />
                        <Item.Content>
                            <Item.Header as="h4">{Translations.getStatic('candidateHistory')}</Item.Header>
                        </Item.Content>
                    </Item>
                </div>

                <div className="candidate-history-all-filter selection">
                    <Select
                        value={this.state.selectedEvent}
                        options={CandidateHistoryHelper.getCandidateHistoryFilterOptions}
                        onChange={(value) => this.onSelectEvents(value)}
                    />
                </div>

                <Divider className="size_24" hidden />

                <div className="auto-scroll auto-scroll_buttons candidate-history-all">
                    <div className="ui items unstackable">
                        {history
                            .filter(
                                (item) =>
                                    CandidateHistoryHelper.showHistoryItem(
                                        item,
                                        this.state.selectedEvent
                                    ) === true
                            )
                            .map((item, i) => (
                                <Item key={i}>
                                    <Icon className="icon-list-item" size="mini" />
                                    <Item.Content>
                                        <h6>
                                            {CandidateHistoryHelper.getHistoryItemHeader(
                                                item,
                                                this.props.candidateModel.candidate
                                            )}
                                        </h6>

                                        <CandidateSidebarHistoryItem
                                            candidateId={candidateId}
                                            item={item}
                                            isAnonymized={isAnonymized}
                                            selectedEvent={this.state.selectedEvent}
                                        />
                                    </Item.Content>
                                </Item>
                            ))}
                    </div>
                    {sidebarButton === true && (
                        <Button
                            className="float-right secondary-button"
                            onClick={() =>
                                this.props.closeSidebarEditPanel('CandidateSidebarHistoryAll', {
                                    candidateId,
                                })
                            }
                        >
                            {Translations.getStatic('cancel')}
                        </Button>
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        candidateModel: CandidateHelper.getActiveModelFromCandidateModel(
            state.candidateModel,
            ownProps.candidateId
        ),
    };
};

const mapDispatchToProps = {
    closeSidebarEditPanel,
};

CandidateSidebarHistory.defaultProps = {
    sidebarButton: true,
    isAnonymized: false,
};

CandidateSidebarHistory.propTypes = {
    history: PropTypes.array.isRequired,
    sidebarButton: PropTypes.bool.isRequired,
    isAnonymized: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateSidebarHistory);
