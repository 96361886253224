import React from 'react';
import { connect } from 'react-redux';
import Item from '../../Item';
import Icon from '../../Icon';
import { openSidebarEditPanel } from '../../../actions/SidebarActions';
import PropTypes from 'prop-types';
import Translations from '../../../helpers/Translations';
import Sort from '../../../helpers/Sort';

const CandidateSidebarColleaguesNotes = (props) => {
    const { notes, userId } = props;

    const colleaguesNotes = Sort.byColumn(notes, 'candidateNoteCreatedAt', 'DESC')
        .filter((note) => {
            if (
                note.hasOwnProperty('candidateNoteText') === false ||
                note.candidateNoteText === ''
            ) {
                return false;
            }

            return note.hasOwnProperty('user') === false || note.user.id !== userId;
        })
        .map((note) => ({
            candidateNoteId: note.candidateNoteId,
            candidateNoteText: note.candidateNoteText,
            isPublic: note.isPublic,
            userEmail: note.user.email,
        }));

    if (colleaguesNotes.length === 0) {
        return null;
    }

    return (
        <div className="ui items unstackable">
            <Item>
                <Icon className="icon-notes_small" size="mini" />
                <Item.Content>
                    <p>{Translations.getStatic('colleaguesNotes')}</p>

                    {colleaguesNotes.map((colleagueNote) => (
                        <p key={colleagueNote.candidateNoteId}>
                            <span className="tag tag-secondary">{colleagueNote.userEmail}</span>
                            {' ' + colleagueNote.candidateNoteText}
                        </p>
                    ))}
                </Item.Content>
            </Item>
        </div>
    );
};

const mapDispatchToProps = {
    openSidebarEditPanel,
};

CandidateSidebarColleaguesNotes.propTypes = {
    notes: PropTypes.array.isRequired,
    userId: PropTypes.number.isRequired,
};

export default connect(null, mapDispatchToProps)(CandidateSidebarColleaguesNotes);
