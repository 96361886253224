import React from 'react';
import { connect } from 'react-redux';
import { openModal } from '../../../actions/ModalActions';
import DateFormatted from '../../../helpers/DateFormatted';
import PropTypes from 'prop-types';
import Translations from '../../../helpers/Translations';
import Constants from '../../../helpers/Constants';
import CandidateHelper from '../../../helpers/components/CandidateHelper';
import CandidateHistoryHelper from '../../../helpers/components/CandidateHistoryHelper';

const CandidateSidebarHistoryItem = ({
    candidateModel,
    item,
    openModal,
    isAnonymized = false,
    selectedEvent = CandidateHistoryHelper.getCandidateHistoryFilterOptions[0].id,
}) => {
    if ((candidateModel?.candidate ?? null) === null) {
        return null;
    }

    const historyItemText = {
        tag: {
            text: Translations.getStatic('tagsChange'),
        },

        stage: {
            text: {
                first: Translations.getStatic('createIntoStage'),
                other: Translations.getStatic('editStageTo'),
                fromReactionForm: Translations.getStatic('candidateCreatedFromReactionForm'),
                fromProfesia: Translations.getStatic('candidateCreatedFromProfesia'),
                fromRecommendationForm: Translations.getStatic(
                    'candidateCreatedFromRecommendationForm'
                ),
            },
        },

        state: {
            text: Translations.getStatic('refusing'),
        },

        note: {
            text: Translations.getStatic('notesChange'),
        },

        copy: {
            valueType: {
                fromText: Translations.getStatic('copyingFrom'),
                intoText: Translations.getStatic('copyingInto'),
            },
        },

        move: {
            valueType: {
                fromText: Translations.getStatic('movingFrom'),
                intoText: Translations.getStatic('movingInto'),
            },
        },

        email: {
            sendType: {
                colleagueText: Translations.getStatic('colleagueForwarding'),
                candidateText: Translations.getStatic('emailSending'),
                autoReplyText: Translations.getStatic('autoAnswer'),
                fromCandidateText: Translations.getStatic('emailFromCandidate'),
                outOfOfficeEmailText: Translations.getStatic('outOfOfficeHistoryItem'),
            },
        },

        event: {
            valueType: {
                createdText: Translations.getStatic('eventCreating'),
                editedText: Translations.getStatic('eventModifying'),
            },
        },

        scheduledEmail: {
            scheduledText: Translations.getStatic('scheduledEmailsHistoryScheduled'),
            canceledText: Translations.getStatic('scheduledEmailsHistoryCanceled'),
            sentText: Translations.getStatic('scheduledEmailsHistorySent'),
            errorText: Translations.getStatic('scheduledEmailsHistoryError'),
        },

        scheduledMassForwardEmail: {
            scheduledText: Translations.getStatic('scheduledMassForwardEmailsHistoryScheduled'),
            canceledText: Translations.getStatic('scheduledMassForwardEmailsHistoryCanceled'),
            sentText: Translations.getStatic('scheduledMassForwardEmailsHistorySent'),
            errorText: Translations.getStatic('scheduledMassForwardEmailsHistoryError'),
        },

        attachment: {
            added: Translations.getStatic('attachmentAdded'),
            removed: Translations.getStatic('attachmentRemoved'),
        },

        basicData: {
            changed: Translations.getStatic('dataChanged'),
        },

        phoneCall: {
            text: Translations.getStatic('candidatePhoneCall'),
        },
    };

    const getScheduledEmailText = (action) => {
        if (action.value === null) {
            return 'Email';
        }

        switch (action.value.actionType) {
            case Constants.SCHEDULED_EMAIL_ACTION_TYPE_SENT:
                return historyItemText[action.type].sentText;

            case Constants.SCHEDULED_EMAIL_ACTION_TYPE_CANCELLED:
                return historyItemText[action.type].canceledText;

            case Constants.SCHEDULED_EMAIL_ACTION_TYPE_ERROR:
                return historyItemText[action.type].errorText;

            default:
                return historyItemText[action.type].scheduledText;
        }
    };

    const getScheduledMassForwardEmailText = (action) => {
        if (action.value === null) {
            return 'Email';
        }

        switch (action.value.actionType) {
            case Constants.SCHEDULED_EMAIL_ACTION_TYPE_SENT:
                return historyItemText[action.type].sentText;

            case Constants.SCHEDULED_EMAIL_ACTION_TYPE_CANCELLED:
                return historyItemText[action.type].canceledText;

            case Constants.SCHEDULED_EMAIL_ACTION_TYPE_ERROR:
                return historyItemText[action.type].errorText;

            default:
                return historyItemText[action.type].scheduledText;
        }
    };

    const getEmailSendTypeText = (action) => {
        if (action.value === null) {
            return 'Email';
        }

        switch (action.value.emailType) {
            case Constants.EMAIL_TYPE_AUTO_REPLY:
                return historyItemText[action.type].sendType.autoReplyText;

            case Constants.EMAIL_TYPE_TO_COLLEAGUE:
                return historyItemText[action.type].sendType.colleagueText;

            case Constants.EMAIL_TYPE_FROM_CANDIDATE:
                return historyItemText[action.type].sendType.fromCandidateText;

            case Constants.EMAIL_TYPE_OUT_OF_OFFICE:
                return historyItemText[action.type].sendType.outOfOfficeEmailText;

            default:
                return historyItemText[action.type].sendType.candidateText;
        }
    };

    const getStageText = (action) => {
        const isFromSomeReactionForm = (sourceId) => {
            return (
                [
                    Constants.SOURCE_ID_REACTION_FORM,
                    Constants.SOURCE_ID_FACEBOOK,
                    Constants.SOURCE_ID_TWITTER,
                    Constants.SOURCE_ID_LINKEDIN,
                ].indexOf(sourceId) > -1
            );
        };

        const isFromProfesia = (sourceId) => {
            return sourceId === Constants.SOURCE_ID_PROFESIA;
        };

        const isFromRecommendationForm = (sourceId) => {
            return sourceId === Constants.SOURCE_ID_RECOMMENDATION_FORM;
        };

        const isFirst = action.hasOwnProperty('isFirst') === true && action.isFirst === true;

        if (isFirst) {
            if (
                action.value.id === Constants.CANDIDATE_STAGE_NEW_ID &&
                candidateModel.hasOwnProperty('candidate') === true
            ) {
                if (isFromSomeReactionForm(candidateModel.candidate.source.id) === true) {
                    return historyItemText[action.type].text.fromReactionForm;
                }

                if (isFromProfesia(candidateModel.candidate.source.id) === true) {
                    return historyItemText[action.type].text.fromProfesia;
                }

                if (isFromRecommendationForm(candidateModel.candidate.source.id) === true) {
                    return historyItemText[action.type].text.fromRecommendationForm;
                }
            }

            return historyItemText[action.type].text.first;
        }

        return historyItemText[action.type].text.other + ' ' + Translations.getStage(action.value);
    };

    const getHistoryItemModalAction = (text, bulkAction, historyItem) => {
        if (bulkAction.type === 'scheduledEmail') {
            return (
                <div
                    className="secondary-link"
                    role="link"
                    onClick={() =>
                        openModal('ScheduledEmailDetailModal', {
                            scheduledEmail: bulkAction.value.scheduledEmail,
                            actionType: bulkAction.value.actionType,
                            title: text,
                        })
                    }
                >
                    {text}
                </div>
            );
        }

        if (bulkAction.type === 'scheduledMassForwardEmail') {
            return (
                <div
                    className="secondary-link"
                    role="link"
                    onClick={() =>
                        openModal('ScheduledMassForwardEmailDetailModal', {
                            scheduledMassForwardEmail: bulkAction.value.scheduledMassForwardEmail,
                            actionType: bulkAction.value.actionType,
                            title: text,
                        })
                    }
                >
                    {text}
                </div>
            );
        }

        if (bulkAction.type === 'email') {
            return (
                <div
                    className="secondary-link"
                    role="link"
                    onClick={() =>
                        openModal('DetailEmailModal', {
                            isOpenFromHistory: true,
                            emailData: bulkAction.value,
                            title: text,
                        })
                    }
                >
                    {text}
                </div>
            );
        }

        return (
            <div
                className="secondary-link"
                role="link"
                onClick={() =>
                    openModal('DetailHistoryItemModal', {
                        bulkAction,
                        historyItem,
                        title: text,
                        candidate: candidateModel.candidate,
                    })
                }
            >
                {text}
            </div>
        );
    };

    return item.bulkActions
        .filter(
            (bulkAction) =>
                CandidateHistoryHelper.showHistoryBulkAction(bulkAction, selectedEvent) === true
        )
        .map((bulkAction, i) => {
            switch (bulkAction.type) {
                case 'copy':
                    const copyText =
                        parseInt(bulkAction.value.from.id) ===
                        parseInt(candidateModel.candidate.jobId)
                            ? historyItemText[bulkAction.type].valueType.intoText +
                              ' ' +
                              bulkAction.value.into.title
                            : historyItemText[bulkAction.type].valueType.fromText +
                              ' ' +
                              bulkAction.value.from.title;

                    return (
                        <h5 className="txt-regular" key={i}>
                            {isAnonymized === true
                                ? copyText
                                : getHistoryItemModalAction(copyText, bulkAction, item)}
                        </h5>
                    );

                case 'move':
                    const moveText =
                        parseInt(bulkAction.value.from.id) ===
                        parseInt(candidateModel.candidate.jobId)
                            ? historyItemText[bulkAction.type].valueType.intoText +
                              ' ' +
                              bulkAction.value.into.title
                            : historyItemText[bulkAction.type].valueType.fromText +
                              ' ' +
                              bulkAction.value.from.title;

                    return (
                        <h5 className="txt-regular" key={i}>
                            {isAnonymized === true
                                ? moveText
                                : getHistoryItemModalAction(moveText, bulkAction, item)}
                        </h5>
                    );

                case 'email':
                    const emailSendTypeText = getEmailSendTypeText(bulkAction);

                    return (
                        <h5 className="txt-regular " key={i}>
                            {isAnonymized === true
                                ? emailSendTypeText
                                : getHistoryItemModalAction(emailSendTypeText, bulkAction, item)}
                        </h5>
                    );

                case 'event':
                    return (
                        <h5 className="txt-regular" key={i}>
                            {bulkAction.value.type === 'edited'
                                ? historyItemText[bulkAction.type].valueType.editedText
                                : historyItemText[bulkAction.type].valueType.createdText}{' '}
                            <b>{bulkAction.value.name}</b> {Translations.getStatic('inTermine')}{' '}
                            {DateFormatted.getDateTime(bulkAction.value.startAt, 'j.n.Y - H:i')}
                        </h5>
                    );

                case 'stage':
                    return (
                        <h5 className="txt-regular" key={i}>
                            {getHistoryItemModalAction(getStageText(bulkAction), bulkAction, item)}
                        </h5>
                    );

                case 'scheduledEmail':
                    const scheduledEmailText = getScheduledEmailText(bulkAction);

                    return (
                        <h5 className="txt-regular" key={i}>
                            {isAnonymized === true
                                ? scheduledEmailText
                                : getHistoryItemModalAction(scheduledEmailText, bulkAction, item)}
                        </h5>
                    );

                case 'scheduledMassForwardEmail':
                    const scheduledMassForwardEmailText =
                        getScheduledMassForwardEmailText(bulkAction);

                    return (
                        <h5 className="txt-regular" key={i}>
                            {isAnonymized === true
                                ? scheduledMassForwardEmailText
                                : getHistoryItemModalAction(
                                      scheduledMassForwardEmailText,
                                      bulkAction,
                                      item
                                  )}
                        </h5>
                    );

                case 'attachment':
                    const text =
                        bulkAction.value.actionType === Constants.HISTORY_ATTACHMENT_ADDED
                            ? historyItemText[bulkAction.type].added
                            : historyItemText[bulkAction.type].removed;

                    return (
                        <h5 className="txt-regular" key={i}>
                            {isAnonymized === true
                                ? text
                                : getHistoryItemModalAction(text, bulkAction, item)}
                        </h5>
                    );

                case 'basicData':
                    return (
                        <h5 className="txt-regular" key={i}>
                            {isAnonymized === true
                                ? historyItemText[bulkAction.type].changed
                                : getHistoryItemModalAction(
                                      historyItemText[bulkAction.type].changed,
                                      bulkAction,
                                      item
                                  )}
                        </h5>
                    );

                case 'note':
                case 'tag':
                    return (
                        <h5 className="txt-regular " key={i}>
                            {isAnonymized === true
                                ? historyItemText[bulkAction.type].text
                                : getHistoryItemModalAction(
                                      historyItemText[bulkAction.type].text,
                                      bulkAction,
                                      item
                                  )}
                        </h5>
                    );

                default:
                    return (
                        <h5 className="txt-regular " key={i}>
                            {getHistoryItemModalAction(
                                historyItemText[bulkAction.type].text,
                                bulkAction,
                                item
                            )}
                        </h5>
                    );
            }
        });
};

const mapDispatchToProps = {
    openModal,
};

const mapStateToProps = (state, ownProps) => ({
    candidateModel: CandidateHelper.getActiveModelFromCandidateModel(
        state.candidateModel,
        ownProps.candidateId
    ),
});

CandidateSidebarHistoryItem.propTypes = {
    candidateId: PropTypes.number.isRequired,

    item: PropTypes.shape({
        bulkActions: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string.isRequired,

                value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            })
        ),

        createdAt: PropTypes.string.isRequired,
    }),

    isAnonymized: PropTypes.bool,
    selectedEvent: PropTypes.string,
};
export default connect(mapStateToProps, mapDispatchToProps)(CandidateSidebarHistoryItem);
