export const SET_CURRENTLY_ACCEPTED_CANDIDATES = 'SET_CURRENTLY_ACCEPTED_CANDIDATES';
export const CLEAR_CURRENTLY_ACCEPTED_CANDIDATES = 'CLEAR_CURRENTLY_ACCEPTED_CANDIDATES';
export const REMOVE_FROM_CURRENTLY_ACCEPTED_CANDIDATES =
    'REMOVE_FROM_CURRENTLY_ACCEPTED_CANDIDATES';

export const setCurrentlyAcceptedCandidates = (jobId, candidateIds, url) => ({
    type: SET_CURRENTLY_ACCEPTED_CANDIDATES,
    payload: {
        jobId,
        candidateIds,
        url,
    },
});

export const clearCurrentlyAcceptedCandidates = () => ({
    type: CLEAR_CURRENTLY_ACCEPTED_CANDIDATES,
});

export const removeFromCurrentlyAcceptedCandidates = (candidateIds) => ({
    type: REMOVE_FROM_CURRENTLY_ACCEPTED_CANDIDATES,
    payload: {
        candidateIds,
    },
});
