import Form from '../Form';
import DateFormatted from '../DateFormatted';
import Arrays from '../Arrays';

export default class ScheduleCandidateEventValidatorHelper {
    constructor(candidateEvent) {
        this.candidateEvent = candidateEvent;
    }

    checkCandidateEvent(nextUserEvents = []) {
        const fieldsToCheck = [
            {
                fieldName: 'eventName',
                fieldType: 'text',
                fieldValue: this.candidateEvent.eventName,
            },
            {
                fieldName: 'dateForCheck',
                fieldType: 'date',
                fieldValue: this.candidateEvent.dateForCheck,
            },
        ];

        let errors = Form.multiCheck(fieldsToCheck);

        if (errors.length > 0) {
            throw errors;
        }

        if (nextUserEvents.length > 0) {
            const candidateEventStartTimestamp = DateFormatted.getTimestamp(
                this.candidateEvent.startAt
            );
            const candidateEventEndTimestamp = DateFormatted.getTimestamp(
                this.candidateEvent.endAt
            );

            const conflicts = Arrays.getUniqueArrayOfObjects(
                nextUserEvents
                    .filter((nextEvent) => {
                        const nextEventStartTimestamp = DateFormatted.getTimestamp(
                            nextEvent.startAt
                        );
                        const nextEventEndTimestamp = DateFormatted.getTimestamp(nextEvent.endAt);

                        return (
                            candidateEventStartTimestamp < nextEventEndTimestamp &&
                            nextEventStartTimestamp < candidateEventEndTimestamp
                        );
                    })
                    .map((conflictedEvent) => ({
                        ...conflictedEvent,
                        uniqueHash:
                            conflictedEvent.eventName +
                            '_' +
                            conflictedEvent.startAt +
                            '_' +
                            conflictedEvent.endAt,
                    })),
                'uniqueHash'
            );

            if (conflicts.length > 0) {
                throw {
                    errorName: 'conflictsInCalendarError',
                    conflicts: conflicts,
                };
            }
        }

        return true;
    }
}
