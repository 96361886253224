import React from 'react';
import { useSelector } from 'react-redux';
import Header4MenuNewDetail from './Header4MenuNewDetail';
import MobileHeader4MenuNewDetail from './MobileHeader4MenuNewDetail';
import Header4MenuDetailSkeleton from '../skeletons/components/Header4MenuDetailSkeleton';
import Skeletons from '../helpers/Skeletons';
import loginManagerModelSkeleton from '../skeletons/models/loginManagerModelSkeleton';

interface RootState {
    loginManagerModel: any;
    jobModel: any;
    isFetchingJobModel: boolean;
    displaySize: {
        isMobileProfesia: boolean;
        width: number;
        isMobile: boolean;
    };
    backButton: {
        location: string;
    };
}

interface WrapperProps {
    action?: string;
}

const WrapperHeader4MenuNewDetail: React.FC<WrapperProps> = (props) => {
    const loginManagerModel = useSelector((state: RootState) => Skeletons.getData(state.loginManagerModel, loginManagerModelSkeleton));
    const jobModel = useSelector((state: RootState) => Skeletons.getData(state.jobModel, []));
    const isFetchingJobModel = useSelector((state: RootState) => state.isFetchingJobModel);
    const displaySize = useSelector((state: RootState) => state.displaySize);
    const backButton = useSelector((state: RootState) => state.backButton);

    if (isFetchingJobModel === true || !jobModel || Skeletons.isSkeleton(loginManagerModel) === true || Skeletons.isSkeleton(jobModel) === true || !jobModel.hasOwnProperty('job')) {
        return <Header4MenuDetailSkeleton backButton={backButton} />;
    }

    if (displaySize.isMobileProfesia) {
        return <MobileHeader4MenuNewDetail backButton={backButton} loginManagerModel={loginManagerModel} jobModel={jobModel} isFetchingJobModel={isFetchingJobModel} displaySize={displaySize} {...props} />;
    }

    return <Header4MenuNewDetail backButton={backButton} loginManagerModel={loginManagerModel} jobModel={jobModel} isFetchingJobModel={isFetchingJobModel} displaySize={displaySize} {...props} />;
};

export default WrapperHeader4MenuNewDetail;
