import React from 'react';
import { connect } from 'react-redux';
import Translations from '../../helpers/Translations';
import MarkDialog from '../Modal/MarkDialog';
import { closeRemoveFromTalentPoolDialog } from '../../actions/DialogActions';
import CandidatesReducer from '../../helpers/generalReducers/CandidatesReducer';
import { removeCandidateFromTalentPool } from '../../actions/ActionWithCandidateActions';
import SuccessMessage from '../../helpers/SuccessMessage';

class RemoveFromTalentPoolDialog extends React.Component {
    componentDidUpdate = (prevProps) => {
        if (
            SuccessMessage.canShowSuccessMessage(
                prevProps,
                this.props,
                'isRemovingCandidateFromTalentPool'
            ) &&
            this.props.candidateActionModel.hasOwnProperty('isSuccess') === true &&
            this.props.candidateActionModel.isSuccess === true
        ) {
            this.props.closeRemoveFromTalentPoolDialog();
        }
    };

    removeFromTalentPool = () => {
        const candidateId = this.props.removeFromTalentPoolDialog.dialogProps.candidateId;

        const actionData = new FormData();

        actionData.append('candidateId', candidateId);

        this.props.removeCandidateFromTalentPool(
            actionData,
            candidateId,
            CandidatesReducer.getReducerType()
        );
    };

    render() {
        const { isRemovingCandidateFromTalentPool, removeFromTalentPoolDialog } = this.props;

        return (
            this.props.removeFromTalentPoolDialog.open === true && (
                <MarkDialog
                    closeOnDimmerClick={false}
                    message={{
                        type: 'exclamation',
                        title: Translations.getStatic('removeFromTalentPoolDialogTitle'),
                        infoLines: [
                            Translations.getStatic('removeFromTalentPoolDialogText').replace(
                                /%candidateName%/g,
                                removeFromTalentPoolDialog.dialogProps.candidateName
                            ),
                        ],
                    }}
                    actions={[
                        {
                            type: 'negative',
                            title: Translations.getStatic('removeFromTalentPoolAction'),
                            action: this.removeFromTalentPool,
                            loading: isRemovingCandidateFromTalentPool,
                        },
                        {
                            title: Translations.getStatic('cancel'),
                            action: this.props.closeRemoveFromTalentPoolDialog,
                            disabled: isRemovingCandidateFromTalentPool,
                        },
                    ]}
                />
            )
        );
    }
}

const mapStateToProps = (state) => ({
    removeFromTalentPoolDialog: state.removeFromTalentPoolDialog,
    isRemovingCandidateFromTalentPool: state.isRemovingCandidateFromTalentPool,
    candidateActionModel: state.candidateActionModel,
});

const mapDispatchToProps = {
    closeRemoveFromTalentPoolDialog,
    removeCandidateFromTalentPool,
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoveFromTalentPoolDialog);
