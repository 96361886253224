import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import md5 from 'md5';
import {
    requestAttachmentAssistantModel,
    addAttachmentsInBulk,
    removeAttachmentFromBulk,
    uploadAttachmentsLive,
    removeCandidateAttachment,
    addAttachmentToEmail,
    removeAttachmentFromEmail,
    removeTemplateAttachment,
} from '../../actions/AttachmentAssistantActions';
import AttachmentAssistantHelper from '../../helpers/components/AttachmentAssistantHelper';
import FileUploadAttachmentsList from './FileUploadAttachmentsList';
import CandidateAttachmentsList from './CandidateAttachmentsList';
import AttachmentAssistantActionButton from './AttachmentAssistantActionButton';
import Constants from '../../helpers/Constants';
import Language from '../../helpers/Language';
import User from '../../helpers/User';
import TemplateAttachmentsList from './TemplateAttachmentsList';
import Divider from '../Divider';
import Translations from '../../helpers/Translations';
import AttachmentsAssistantSkeleton from '../../skeletons/components/AttachmentsAssistantSkeleton';

class AttachmentsAssistant extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            attachmentTypeCounts: {},
            hashId: this.props.modelHashId,
            errors: [],
        };
    }

    componentDidMount() {
        if (
            this.props.attachmentAssistantModel.hasOwnProperty('candidateId') === false ||
            parseInt(this.props.attachmentAssistantModel.candidateId) !== this.props.candidateId
        ) {
            this.props.requestAttachmentAssistantModel(
                this.props.candidateId,
                this.state.hashId,
                this.props.language,
                this.props.templateAttachments
            );
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.candidateId !== prevProps.candidateId) {
            this.props.requestAttachmentAssistantModel(
                this.props.candidateId,
                this.state.hashId,
                prevProps.language,
                this.props.templateAttachments
            );
        }

        if (
            prevProps.attachmentAssistantModel.hasOwnProperty('settings') === false &&
            this.props.attachmentAssistantModel.hasOwnProperty('settings') === true
        ) {
            const attachmentTypes = this.props.attachmentAssistantModel.settings.attachmentTypes;
            let attachmentTypeCounts = {};

            for (let i = 0; i < attachmentTypes.length; i++) {
                attachmentTypeCounts[attachmentTypes[i].id] = 1;
            }

            this.setState({
                attachmentTypeCounts: attachmentTypeCounts,
            });
        }
    }

    onAddAttachments = (fileTypesAllowed, typeId) => {
        const liveUpload =
            parseInt(this.props.candidateId) > 0 && this.props.isFileUploadOnly === false;
        const { settings } = this.props.attachmentAssistantModel;
        const attachmentAssistantHelper = new AttachmentAssistantHelper();
        const selector =
            '.' +
            Constants.ATTACHMENT_ASSISTANT_CLASS +
            ' input[type=file][id=attachmentType' +
            typeId +
            ']';

        let files = attachmentAssistantHelper.getFileListFromElement(selector);

        const filesTypeErrors = attachmentAssistantHelper.checkFilesType(files, fileTypesAllowed);
        const singleFilesSizeErrors = attachmentAssistantHelper.checkSingleFilesSizes(
            files,
            settings.singleFileSizeUploadLimit
        );

        if (filesTypeErrors.length === 0 && singleFilesSizeErrors.length === 0) {
            const uploadHash = md5(Date.now() + JSON.stringify(files));

            files = files.map((file) => ({
                typeId: typeId,
                file: file,
                uploadHash: uploadHash,
            }));

            let attachmentTypeCounts = this.state.attachmentTypeCounts;
            attachmentTypeCounts[typeId]++;

            this.setState({
                attachmentTypeCounts: attachmentTypeCounts,
            });

            if (liveUpload === true) {
                let formData = new FormData();

                files.forEach((file) => {
                    formData.append(file.typeId + '[]', file.file);
                });

                this.props.uploadAttachmentsLive(
                    this.props.candidateId,
                    files,
                    formData,
                    uploadHash,
                    this.state.hashId
                );
            } else {
                this.props.addAttachmentsInBulk(files, this.state.hashId);
            }

            this.setState({
                errors: [],
            });
        } else {
            this.setState({
                errors: filesTypeErrors.concat(singleFilesSizeErrors),
            });
        }
    };

    render() {
        if (this.props.attachmentAssistantModel.hasOwnProperty('candidateAttachments') === false) {
            return <AttachmentsAssistantSkeleton />;
        }

        const {
            settings,
            bulkUploadAttachments,
            candidateAttachments,
            liveUploadAttachments,
            templateAttachments,
        } = this.props.attachmentAssistantModel;

        let language =
            this.props.language ?? this.props.attachmentAssistantModel?.language ?? Language.get();

        const attachmentTypes =
            this.props.isFileUploadOnly === true
                ? settings.attachmentTypes.filter(
                      (attachmentType) =>
                          attachmentType.useType === Constants.ATTACHMENT_USE_TYPE_FILE_UPLOAD
                  )
                : settings.attachmentTypes.filter(
                      (attachmentType) =>
                          attachmentType.useType === Constants.ATTACHMENT_USE_TYPE_CANDIDATE
                  );

        const AttachmentsListComponent =
            this.props.isFileUploadOnly === true ? (
                <FileUploadAttachmentsList
                    candidateId={this.props.candidateId}
                    language={language}
                    attachmentTypes={attachmentTypes.map((attachmentType) => attachmentType.id)}
                    bulkUploadAttachments={bulkUploadAttachments}
                    candidateAttachments={candidateAttachments}
                    liveUploadAttachments={liveUploadAttachments}
                    settings={settings}
                    onRemoveAttachmentFromBulk={(attachmentKey) =>
                        this.props.removeAttachmentFromBulk(attachmentKey, this.state.hashId)
                    }
                    onRemoveAttachmentFromEmail={(attachmentId) =>
                        this.props.removeAttachmentFromEmail(attachmentId, this.state.hashId)
                    }
                    onAddAttachmentToEmail={(attachment) =>
                        this.props.addAttachmentToEmail(attachment, this.state.hashId)
                    }
                    onRemoveCandidateAttachment={(candidateId, attachmentId) =>
                        this.props.removeCandidateAttachment(
                            candidateId,
                            attachmentId,
                            this.state.hashId
                        )
                    }
                />
            ) : (
                <CandidateAttachmentsList
                    language={language}
                    candidateId={this.props.candidateId}
                    attachmentTypes={attachmentTypes.map((attachmentType) => attachmentType.id)}
                    bulkUploadAttachments={bulkUploadAttachments}
                    candidateAttachments={candidateAttachments}
                    liveUploadAttachments={liveUploadAttachments}
                    settings={settings}
                    title={this.props.title}
                    isError={this.props.isError}
                    divider={this.props.divider}
                    isDuplicate={this.props.isDuplicate}
                    onRemoveAttachmentFromBulk={(attachmentKey) =>
                        this.props.removeAttachmentFromBulk(attachmentKey, this.state.hashId)
                    }
                    onRemoveAttachmentFromEmail={(attachmentKey) =>
                        this.props.removeAttachmentFromEmail(attachmentKey, this.state.hashId)
                    }
                    onRemoveCandidateAttachment={(candidateId, attachmentId) =>
                        this.props.removeCandidateAttachment(
                            candidateId,
                            attachmentId,
                            this.state.hashId
                        )
                    }
                />
            );

        const hasSomePhoto =
            bulkUploadAttachments.find(
                (attachment) => attachment.typeId === Constants.ATTACHMENT_TYPE_PHOTO
            ) !== undefined ||
            liveUploadAttachments.find(
                (attachment) => attachment.typeId === Constants.ATTACHMENT_TYPE_PHOTO
            ) !== undefined ||
            candidateAttachments.find(
                (attachment) => attachment.attachmentTypeId === Constants.ATTACHMENT_TYPE_PHOTO
            ) !== undefined;

        const isExternalVisitor = User.isExternalVisitor(
            this.props.loginManagerModel,
            this.props.jobModel
        );
        const mobileVisible =
            this.props.mobileVisible === false && this.props.displaySize.isMobile === true
                ? false
                : true;

        const totalAttachmentsSize = AttachmentAssistantHelper.getTotalAttachmentsSize(
            this.props.attachmentAssistantModel
        );

        return (
            <div
                className={
                    Constants.ATTACHMENT_ASSISTANT_CLASS +
                    ' list' +
                    (candidateAttachments.length === 0 &&
                    liveUploadAttachments.length === 0 &&
                    bulkUploadAttachments.length === 0
                        ? ' content-vertical'
                        : '')
                }
            >
                {AttachmentsListComponent}

                {
                    <TemplateAttachmentsList
                        templateAttachments={templateAttachments}
                        onRemoveTemplateAttachment={(attachmentId) =>
                            this.props.removeTemplateAttachment(attachmentId, this.state.hashId)
                        }
                    />
                }

                {this.props.isDuplicate === false &&
                    settings.hasUserAccess === true &&
                    mobileVisible === true &&
                    isExternalVisitor === false && (
                        <AttachmentAssistantActionButton
                            language={language}
                            disabledPhotoOption={hasSomePhoto}
                            attachmentTypes={attachmentTypes}
                            settings={settings}
                            typeCounts={this.state.attachmentTypeCounts}
                            onAddAttachments={this.onAddAttachments}
                        />
                    )}

                {this.props.isEmailSending === true &&
                totalAttachmentsSize > settings.emailTotalAttachmentsMaxSize ? (
                    <>
                        <Divider hidden className="size_16" />
                        <div className="prompt label">
                            {Translations.getStatic('emailAttachmentsMaxSizeExceededSingle')}
                        </div>
                    </>
                ) : null}

                {this.state.errors.length > 0 &&
                    this.state.errors.map((error, i) => (
                        <div className="content-wrap" key={i}>
                            <Divider hidden className="size_16" />
                            <div className="prompt label error">{error}</div>
                        </div>
                    ))}
            </div>
        );
    }
}

AttachmentsAssistant.propTypes = {
    candidateId: PropTypes.number.isRequired,
    modelHashId: PropTypes.string.isRequired,
    isFileUploadOnly: PropTypes.bool,
    isError: PropTypes.bool,
    isDuplicate: PropTypes.bool.isRequired,
    title: PropTypes.string,
    divider: PropTypes.bool,
    language: PropTypes.string,
    mobileVisible: PropTypes.bool,
    templateAttachments: PropTypes.array,
    isEmailSending: PropTypes.bool,
};

AttachmentsAssistant.defaultProps = {
    isFileUploadOnly: false,
    isError: false,
    isDuplicate: false,
    language: Language.get(),
    mobileVisible: false,
    templateAttachments: [],
    isEmailSending: false,
};

const mapStateToProps = (state, ownProps) => ({
    displaySize: state.displaySize,

    attachmentAssistantModel: AttachmentAssistantHelper.getActiveModelFromAssistantAttachmentModel(
        state.attachmentAssistantModel,
        ownProps.modelHashId
    ),

    loginManagerModel: state.loginManagerModel,
    jobModel: state.jobModel,
});

const mapDispatchToProps = {
    requestAttachmentAssistantModel,
    addAttachmentsInBulk,
    removeAttachmentFromBulk,
    addAttachmentToEmail,
    removeAttachmentFromEmail,
    uploadAttachmentsLive,
    removeCandidateAttachment,
    removeTemplateAttachment,
};

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentsAssistant);
