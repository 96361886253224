import {
    SET_PAGE_ERROR,
    UNSET_PAGE_ERROR,
    SET_ERROR_FOCUS,
    UNSET_ERROR_FOCUS,
} from '../actions/ErrorActions';

export const isPageError = (state = false, action) => {
    switch (action.type) {
        case SET_PAGE_ERROR:
            return true;

        case UNSET_PAGE_ERROR:
            return false;

        default:
            return state;
    }
};

export const isErrorFocused = (state = false, action) => {
    switch (action.type) {
        case SET_ERROR_FOCUS:
            return true;

        case UNSET_ERROR_FOCUS:
            return false;

        default:
            return state;
    }
};
