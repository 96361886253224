import { Dispatch, Action } from 'redux';
import { SET_USER_DATA } from '../../../actions/LoginActions';
import apiRequest from './apiRequest';
import { UpdateContactPersonPayload, UpdateContactPerson } from './types';

const updateContactPerson = async (
    contactPerson: UpdateContactPerson,
    dispatch: Dispatch<Action>
) => {
    const { id, academicDegree, firstName, lastName, phone, email } = contactPerson;

    if (!id) {
        throw new Error('Contact person ID is required.');
    }

    const payload: UpdateContactPersonPayload = {
        title: academicDegree || '',
        firstName: firstName || '',
        lastName: lastName || '',
        contactEmail: email,
        contactPhone: phone || '',
    };

    try {
        await apiRequest(`company/user/${id}`, {
            method: 'PUT',
            body: JSON.stringify(payload),
        });
        dispatch({
            type: SET_USER_DATA,
            payload: {
                title: academicDegree || '',
                firstname: firstName || '',
                surname: lastName || '',
                name: `${academicDegree} ${firstName} ${lastName}`.trim() || '',
                email: email,
                phone: phone || '',
            },
        });
    } catch (error) {
        throw new Error('Failed to update contact person');
    }
};

export default updateContactPerson;
