import React, { Component } from 'react';
import { connect } from 'react-redux';
import Sort from '../../../helpers/Sort';
import DashboardModalOffersListItem from './DashboardModalOffersListItem';

class DashboardModalOffersList extends Component {
    allOffersAreLocked = (offers) => {
        return offers.filter((offer) => offer.hasLockedCandidates === false).length === 0;
    };

    existsNotLockedOfferWithCompleteCandidatesBeforeOffer = (offerIterator, offers, candidates) => {
        const previousOffers = offers.slice(0, offerIterator).reverse();

        if (previousOffers.length === 0) {
            return true;
        }

        const previousNotLockedCandidatesOffer = previousOffers.find(
            (offer) => offer.hasLockedCandidates === false
        );

        if (previousNotLockedCandidatesOffer === undefined) {
            return false;
        }

        const fullCandidates = Sort.asText(candidates, 'addDate', 'DESC').filter(
            (candidate) => candidate.jobId === previousNotLockedCandidatesOffer.jobId
        );

        return (
            fullCandidates.length ===
            parseInt(previousNotLockedCandidatesOffer.counts.newCandidatesCount)
        );
    };

    render = () => {
        const {
            dashboardModel: { activeOffers, candidates },
        } = this.props;

        const offers = Sort.asText(activeOffers, 'createdAt', 'DESC').filter(
            (offer) => parseInt(offer.counts.newCandidatesCount) > 0
        );
        let showLoader = true;

        return (
            <div
                className={
                    'list dashboard-list' +
                    (this.props.hasLockedCandidates === true ? ' locked' : '')
                }
            >
                {offers.map((offer, i) => {
                    const fullCandidates = Sort.asText(candidates, 'addDate', 'DESC').filter(
                        (candidate) => candidate.jobId === offer.jobId
                    );
                    const completeLoadedCandidates =
                        offer.hasLockedCandidates === true ||
                        fullCandidates.length === parseInt(offer.counts.newCandidatesCount);

                    const existsNotLockedOfferWithCompleteCandidatesBeforeOffer =
                        this.existsNotLockedOfferWithCompleteCandidatesBeforeOffer(
                            i,
                            offers,
                            candidates
                        );
                    const allOffersAreLocked = this.allOffersAreLocked(offers);

                    const result = (
                        <DashboardModalOffersListItem
                            key={offer.id}
                            offer={offer}
                            existsNotLockedOfferWithCompleteCandidatesBeforeOffer={
                                existsNotLockedOfferWithCompleteCandidatesBeforeOffer
                            }
                            allOffersAreLocked={allOffersAreLocked}
                            completeLoadedCandidates={completeLoadedCandidates}
                            showLoader={completeLoadedCandidates === false && showLoader === true}
                            candidates={fullCandidates}
                        />
                    );

                    if (completeLoadedCandidates === false && showLoader === true) {
                        showLoader = false;
                    }

                    return result;
                })}
            </div>
        );
    };
}

const mapStateToProps = (state) => ({
    dashboardModel: state.b2bDashboardModel,
});

const mapDispatchToProps = {};

DashboardModalOffersList.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardModalOffersList);
