import {
    RECEIVE_JOBS_LIST_MODEL,
    REQUEST_JOBS_LIST_MODEL,
    RECEIVE_JOBS_LIST_DATA,
    OPEN_JOB,
    OPEN_JOB_FAILURE,
    CLOSE_JOB,
    CLOSE_JOB_SUCCESS,
    CLOSE_JOB_FAILURE,
    ACTIVATE_JOB,
    ACTIVATE_JOB_FAILURE,
    FILTER_JOBS_BY_JOB_TITLE,
    FILTER_JOBS_BY_STATE_ID,
    FILTER_JOBS_BY_STATE_REASON_ID,
    FILTER_JOBS_BY_USER_ID,
    FILTER_JOBS_BY_ADD_DATE,
    FILTER_JOBS_WITH_ACTIVE_OFFER,
    SORT_JOBS_BY,
    LOAD_JOBS_SUCCESS,
    LOAD_JOBS,
    LOAD_JOBS_FAILURE,
    JOBS_EXPORT_REQUEST,
    JOBS_EXPORT_REQUEST_FAILURE,
    JOBS_EXPORT_REQUEST_SUCCESS,
} from '../actions/JobsListActions';
import Constants from '../helpers/Constants';
import JobsHelper from '../helpers/components/JobsHelper';
import Storages from '../helpers/Storages';
import DateFormatted from '../helpers/DateFormatted';

export const isFetchingJobsListModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_JOBS_LIST_MODEL:
            return true;

        case RECEIVE_JOBS_LIST_MODEL:
            return false;

        default:
            return state;
    }
};

export const isFetchingJobsDetails = (state = false, action) => {
    switch (action.type) {
        case LOAD_JOBS:
            return true;

        case LOAD_JOBS_SUCCESS:
        case LOAD_JOBS_FAILURE:
        case FILTER_JOBS_BY_ADD_DATE:
        case FILTER_JOBS_BY_JOB_TITLE:
        case FILTER_JOBS_BY_STATE_ID:
        case FILTER_JOBS_BY_USER_ID:
        case FILTER_JOBS_WITH_ACTIVE_OFFER:
            return false;

        default:
            return state;
    }
};

export const isRequestingJobsExport = (state = false, action) => {
    switch (action.type) {
        case JOBS_EXPORT_REQUEST:
            return true;

        case JOBS_EXPORT_REQUEST_SUCCESS:
        case JOBS_EXPORT_REQUEST_FAILURE:
            return false;

        default:
            return state;
    }
};

export const jobsListModel = (state = {}, action) => {
    const defaultFilter = {
        searched: [],
        usingSearch: false,
        searchText: '',
        stateId: Constants.JOBS_LIST_FILTER_JOB_STATE_DEFAULT,
        stateReasonId: Constants.JOBS_LIST_FILTER_JOB_STATE_REASON_DEFAULT,
        userId: null,
        withActiveOffer: false,

        addDate: {
            dateFrom: null,
            dateTo: null,
            timespan: Constants.JOBS_LIST_FILTER_BY_ADD_DATE_DEFAULT_VALUE,
        },
    };

    switch (action.type) {
        case REQUEST_JOBS_LIST_MODEL:
            return {
                filter: state.hasOwnProperty('filter') === true ? state.filter : defaultFilter,
                sort: Storages.getLocal('jobsListSortBy', Constants.JOBS_LIST_SORT_BY_DEFAULT),
            };

        case RECEIVE_JOBS_LIST_MODEL:
            const filter = state.hasOwnProperty('filter') === true ? state.filter : defaultFilter;

            if (filter.userId === null) {
                filter.userId =
                    action.payload.hasOwnProperty('settings') === true &&
                    isNaN(parseInt(action.payload.settings.userData.id)) === false
                        ? parseInt(action.payload.settings.userData.id)
                        : Constants.JOBS_LIST_FILTER_USER_ID_ALL;
            }

            return {
                ...state,
                ...action.payload,
                filter,
                sort: Storages.getLocal('jobsListSortBy', Constants.JOBS_LIST_SORT_BY_DEFAULT),
            };

        case LOAD_JOBS_SUCCESS:
            return {
                ...state,
                jobs:
                    state.hasOwnProperty('jobs') === true && state.jobs !== undefined
                        ? state.jobs.concat(action.payload.jobs)
                        : action.payload.jobs,
            };

        case RECEIVE_JOBS_LIST_DATA:
            return {
                ...state,
                ...action.payload,

                filter: {
                    ...state.filter,
                    ...JobsHelper.filterResults(
                        action.payload.jobsBasicData,
                        state.filter.searchText
                    ),
                },
            };

        case FILTER_JOBS_BY_JOB_TITLE:
            return {
                ...state,

                filter: {
                    ...state.filter,
                    ...JobsHelper.filterResults(state.jobsBasicData, action.payload.searchText),
                },

                jobs: undefined,
            };

        case FILTER_JOBS_BY_STATE_ID:
            return {
                ...state,

                filter: {
                    ...state.filter,
                    stateId: action.payload.stateId,
                },

                jobs: undefined,
            };

        case FILTER_JOBS_BY_STATE_REASON_ID:
            return {
                ...state,

                filter: {
                    ...state.filter,
                    stateReasonId: action.payload.stateReasonId,
                },

                jobs: undefined,
            };

        case FILTER_JOBS_BY_USER_ID:
            return {
                ...state,

                filter: {
                    ...state.filter,
                    userId: action.payload.userId,
                },

                jobs: undefined,
                jobsBasicData: undefined,
            };

        case FILTER_JOBS_BY_ADD_DATE:
            return {
                ...state,

                filter: {
                    ...state.filter,

                    addDate: {
                        dateFrom: action.payload.dateFrom,
                        dateTo: action.payload.dateTo,
                        timespan: action.payload.timespan,
                    },
                },

                jobs: undefined,
            };

        case FILTER_JOBS_WITH_ACTIVE_OFFER:
            const result = {
                ...state,

                filter: {
                    ...state.filter,
                    withActiveOffer: action.payload.withActiveOffer,
                },

                jobs: undefined,
            };

            if (action.payload.withActiveOffer === true) {
                return {
                    ...result,
                    jobsBasicData: undefined,
                };
            }

            return result;

        case SORT_JOBS_BY:
            return {
                ...state,

                jobs: action.payload.withReload === true ? undefined : state.jobs,

                sort: {
                    sortBy: action.payload.sortBy,
                    sortDirection: action.payload.sortDirection,
                    sortType: action.payload.sortType,
                },
            };

        case OPEN_JOB:
        case CLOSE_JOB:
            return {
                ...state,

                jobs: state.jobs.map((job) => ({
                    ...job,
                    stateId: job.id === action.payload.jobId ? action.payload.stateId : job.stateId,
                    endDate: DateFormatted.getDate('now', 'Y-m-d'),
                })),

                jobsBasicData: state.jobsBasicData.map((job) => ({
                    ...job,
                    stateId: job.id === action.payload.jobId ? action.payload.stateId : job.stateId,
                })),
            };

        case CLOSE_JOB_SUCCESS:
            return {
                ...state,

                jobs: state.jobs.map((job) =>
                    job.id === action.payload.job.jobId ? action.payload.job : job
                ),
            };

        case ACTIVATE_JOB:
            if (state.hasOwnProperty('jobsBasicData') === false) {
                return state;
            }

            return {
                ...state,

                jobs: state.jobs.map((job) => ({
                    ...job,
                    stateId: job.id === action.payload.jobId ? action.payload.stateId : job.stateId,
                })),

                jobsBasicData: state.jobsBasicData.map((job) => ({
                    ...job,
                    stateId: job.id === action.payload.jobId ? action.payload.stateId : job.stateId,
                })),
            };

        case OPEN_JOB_FAILURE:
            return {
                ...state,

                jobs: state.jobs.map((job) => ({
                    ...job,
                    stateId:
                        job.id === action.meta.requestPayload.jobId
                            ? Constants.JOB_STATE_CLOSED
                            : job.stateId,
                })),

                jobsBasicData: state.jobsBasicData.map((job) => ({
                    ...job,
                    stateId:
                        job.id === action.meta.requestPayload.jobId
                            ? Constants.JOB_STATE_CLOSED
                            : job.stateId,
                })),
            };

        case CLOSE_JOB_FAILURE:
            return {
                ...state,

                jobs: state.jobs.map((job) => ({
                    ...job,
                    stateId:
                        job.id === action.meta.requestPayload.jobId
                            ? Constants.JOB_STATE_OPEN
                            : job.stateId,
                })),

                jobsBasicData: state.jobsBasicData.map((job) => ({
                    ...job,
                    stateId:
                        job.id === action.meta.requestPayload.jobId
                            ? Constants.JOB_STATE_OPEN
                            : job.stateId,
                })),
            };

        case ACTIVATE_JOB_FAILURE:
            if (state.hasOwnProperty('jobsBasicData') === false) {
                return state;
            }

            return {
                ...state,

                jobs: state.jobs.map((job) => ({
                    ...job,
                    stateId:
                        job.id === action.meta.requestPayload.jobId
                            ? Constants.JOB_STATE_UNPAID
                            : job.stateId,
                })),

                jobsBasicData: state.jobsBasicData.map((job) => ({
                    ...job,
                    stateId:
                        job.id === action.meta.requestPayload.jobId
                            ? Constants.JOB_STATE_UNPAID
                            : job.stateId,
                })),
            };

        default:
            return state;
    }
};
