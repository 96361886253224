import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import { closeModal } from '../../actions/ModalActions';
import { processEmailTemplate } from '../../actions/EmailTemplatesSettingsActions';
import SettingsEmailTemplateEditor from '../Settings/SettingsEmailTemplateEditor';
import SuccessMessage from '../../helpers/SuccessMessage';
import EmailTemplateValidatorHelper from '../../helpers/components/EmailTemplateValidatorHelper';
import TemplateAssistantHelper from '../../helpers/components/TemplateAssistantHelper';
import Translations from '../../helpers/Translations';
import AttachmentAssistantHelper from '../../helpers/components/AttachmentAssistantHelper';
import TemplateAttachmentsHelper from '../../helpers/components/TemplateAttachmentsHelper';

class SettingsPageEmailTemplateEditorModal extends Component {
    state = {
        errors: [],
    };

    componentDidUpdate = (prevProps) => {
        if (
            SuccessMessage.canShowSuccessMessage(
                prevProps,
                this.props,
                'isProcessingEmailTemplate'
            ) &&
            this.props.emailTemplatesSettingsModel.hasOwnProperty('isSuccess') === true &&
            this.props.emailTemplatesSettingsModel.isSuccess === true
        ) {
            this.props.closeModal();
        }
    };

    processEmailTemplate = () => {
        const {
            emailTemplateId,
            sendEmailModel,
            emailTemplatesSettingsModel,
            templateAssistant,
            attachmentAssistantModel,
        } = this.props;
        const { emailTemplateData } = sendEmailModel;
        const { emailTemplates } = emailTemplatesSettingsModel;

        const templateSavingMode = TemplateAssistantHelper.getTemplateSavingMode(
            templateAssistant.emailTemplate
        );

        let checkAgainst =
            templateSavingMode === TemplateAssistantHelper.TEMPLATE_SAVING_MODE_UPDATE
                ? emailTemplates.filter(
                      (emailTemplate) => parseInt(emailTemplate.id) !== parseInt(emailTemplateId)
                  )
                : emailTemplates;

        checkAgainst = checkAgainst.map((emailTemplate) => emailTemplate.name);

        const validator = new EmailTemplateValidatorHelper(emailTemplateData);
        const errors = validator.checkEmailTemplate(true, true, checkAgainst);

        this.setState({ errors });

        if (errors.length === 0) {
            let formData = new FormData();

            formData.append('emailTemplateData', JSON.stringify(emailTemplateData));
            formData.append('emailTemplateSavingMode', templateSavingMode);

            formData = TemplateAttachmentsHelper.prepareFormData(
                formData,
                attachmentAssistantModel
            );

            this.props.processEmailTemplate(
                formData,
                parseInt(emailTemplateId),
                templateSavingMode
            );
        }
    };

    render() {
        const { isOpen, isFetchingSendEmailModel, isProcessingEmailTemplate } = this.props;

        const isPerformingAction = isFetchingSendEmailModel || isProcessingEmailTemplate;

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={this.props.closeModal}
                isLoadingData={isFetchingSendEmailModel}
                actionButton={{
                    text: Translations.getStatic('save'),
                    disabled: isPerformingAction,
                    loading: isPerformingAction,
                    onClick: this.processEmailTemplate,
                }}
                title={Translations.getStatic('emailTemplateSettings')}
                content={
                    <SettingsEmailTemplateEditor
                        errors={this.state.errors}
                        emailTemplateId={this.props.emailTemplateId}
                        isCopy={this.props.isCopy}
                    />
                }
            />
        );
    }
}

SettingsPageEmailTemplateEditorModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isCopy: PropTypes.bool.isRequired,
    emailTemplateId: PropTypes.number.isRequired,
};

SettingsPageEmailTemplateEditorModal.defaultProps = {
    emailTemplateId: 0,
    isCopy: false,
};

const mapStateToProps = (state) => ({
    isOpen: state.modalLogic.isOpen,
    sendEmailModel: state.sendEmailModel,
    emailTemplatesSettingsModel: state.emailTemplatesSettingsModel,
    templateAssistant: state.templateAssistant,
    isFetchingSendEmailModel: state.isFetchingSendEmailModel,
    isProcessingEmailTemplate: state.isProcessingEmailTemplate,

    attachmentAssistantModel: AttachmentAssistantHelper.getActiveModelFromAssistantAttachmentModel(
        state.attachmentAssistantModel,
        AttachmentAssistantHelper.createAttachmentAssistantHashIdForEmail()
    ),
});

const mapDispatchToProps = {
    closeModal,
    processEmailTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPageEmailTemplateEditorModal);
