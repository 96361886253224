import React from 'react';
import PropTypes from 'prop-types';
import Translations from '../../helpers/Translations';

const DetailCandidateNoModalTabs = ({
    activeMenuItem,
    attachmentsCount,
    hasQuestionnaireResult,
    questionnaireResult,
    hasJobQuestionnaire,
    handleMenuItemClick,
    candidateInbox,
    isFetchingCandidateInbox,
    hasCompanyActiveInbox,
    isExternalVisitor,
}) => (
    <ul className="nav-grid__tabs">
        <li className="tab__item">
            <div
                role="link"
                id="attachments"
                className={'item__link' + (activeMenuItem === 'attachments' ? ' active' : '')}
                onClick={() => handleMenuItemClick('attachments')}
            >
                {Translations.getStatic('attachments')} ({attachmentsCount})
            </div>
        </li>

        {(hasQuestionnaireResult === true || hasJobQuestionnaire === true) && (
            <li className="tab__item">
                <div
                    role="link"
                    id="questionnaire"
                    className={'item__link' + (activeMenuItem === 'questionnaire' ? ' active' : '')}
                    onClick={() => handleMenuItemClick('questionnaire')}
                >
                    {hasQuestionnaireResult === true
                        ? Translations.getStatic('questionnaire') +
                          (questionnaireResult === null ? '' : ' ' + questionnaireResult + '%')
                        : Translations.getStatic('unfilledQuestionnaire')}
                </div>
            </li>
        )}

        {hasCompanyActiveInbox === true &&
            candidateInbox !== undefined &&
            isFetchingCandidateInbox === false &&
            candidateInbox.hasOwnProperty('messages') === true &&
            isExternalVisitor === false && (
                <li className="tab__item">
                    <div
                        role="link"
                        id="inbox"
                        className={'item__link' + (activeMenuItem === 'inbox' ? ' active' : '')}
                        onClick={() => handleMenuItemClick('inbox')}
                    >
                        {Translations.getStatic('communication')} (
                        {
                            candidateInbox.messages.filter((message) => message.isUnread === true)
                                .length
                        }
                        /{candidateInbox.messages.length})
                    </div>
                </li>
            )}
    </ul>
);

DetailCandidateNoModalTabs.propTypes = {
    activeMenuItem: PropTypes.string,
    attachmentsCount: PropTypes.number,
    hasQuestionnaireResult: PropTypes.bool,
    questionnaireResult: PropTypes.number,
    handleMenuItemClick: PropTypes.func.isRequired,
    hasJobQuestionnaire: PropTypes.bool,
    hasCompanyActiveInbox: PropTypes.bool.isRequired,
};

export default DetailCandidateNoModalTabs;
