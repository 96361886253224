import React from 'react';
import JobItemSkeleton from './JobItemSkeleton';

const JobsListItemSkeleton = () => (
    <div className="item">
        <div className="content">
            <JobItemSkeleton />
        </div>
    </div>
);

export default JobsListItemSkeleton;
