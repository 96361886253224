import Constants from '../helpers/Constants';

export const REQUEST_SEND_EMAIL_MODEL = 'REQUEST_SEND_EMAIL_MODEL';
export const RECEIVE_SEND_EMAIL_MODEL = 'RECEIVE_SEND_EMAIL_MODEL';
export const UPDATE_EMAIL_TEMPLATE = 'UPDATE_EMAIL_TEMPLATE';
export const SET_EMAIL_RECIPIENTS = 'SET_EMAIL_RECIPIENTS';
export const SET_EMAIL_BCC = 'SET_EMAIL_BCC';
export const SET_EMAIL_SENDER = 'SET_EMAIL_SENDER';
export const ADD_NEW_COLLEAGUE_CONTACT = 'ADD_NEW_COLLEAGUE_CONTACT';
export const ADD_NEW_COLLEAGUE_CONTACT_SUCCESS = 'ADD_NEW_COLLEAGUE_CONTACT_SUCCESS';
export const ADD_NEW_COLLEAGUE_CONTACT_FAILURE = 'ADD_NEW_COLLEAGUE_CONTACT_FAILURE';
export const PREPARE_EMAIL_TO_PREVIEW = 'PREPARE_EMAIL_TO_PREVIEW';
export const PREPARE_EMAIL_TO_PREVIEW_SUCCESS = 'PREPARE_EMAIL_TO_PREVIEW_SUCCESS';
export const PREPARE_EMAIL_TO_PREVIEW_FAILURE = 'PREPARE_EMAIL_TO_PREVIEW_FAILURE';
export const REMOVE_COLLEAGUE_CONTACT = 'REMOVE_COLLEAGUE_CONTACT';
export const REMOVE_COLLEAGUE_CONTACT_SUCCESS = 'REMOVE_COLLEAGUE_CONTACT_SUCCESS';
export const REMOVE_COLLEAGUE_CONTACT_FAILURE = 'REMOVE_COLLEAGUE_CONTACT_FAILURE';
export const SET_SEND_EMAIL_AT = 'SET_SEND_EMAIL_AT';
export const GET_VIDEO_INTERVIEW_DATA = 'GET_VIDEO_INTERVIEW_DATA';
export const GET_VIDEO_INTERVIEW_DATA_SUCCESS = 'GET_VIDEO_INTERVIEW_DATA_SUCCESS';
export const GET_VIDEO_INTERVIEW_DATA_FAILURE = 'GET_VIDEO_INTERVIEW_DATA_FAILURE';

export const requestSendEmailModel = (
    emailTemplateId = null,
    sender = null,
    recipients = [],
    bcc = [],
    predefinedLanguageId = null,
    copy = false,
    emailTemplateType = Constants.EMAIL_TEMPLATE_TYPE_OTHER,
    messageHistory = null,
    isMassForwarding = false
) => ({
    type: REQUEST_SEND_EMAIL_MODEL,

    meta: {
        requestRoute: 'v1/email/model/send/' + (emailTemplateId === null ? 0 : emailTemplateId),

        requestData: {
            copy: copy ? 1 : 0,
            predefinedLanguageId: predefinedLanguageId === null ? 0 : predefinedLanguageId,
        },

        onRequestSuccess: RECEIVE_SEND_EMAIL_MODEL,
    },

    payload: {
        recipients,
        bcc,
        sender,
        emailTemplateType,
        messageHistory,
        isMassForwarding,
    },
});

export const updateEmailTemplate = (emailTemplateData) => ({
    type: UPDATE_EMAIL_TEMPLATE,
    payload: {
        emailTemplateData,
    },
});

export const setEmailRecipients = (recipients) => ({
    type: SET_EMAIL_RECIPIENTS,
    payload: {
        recipients,
    },
});

export const setEmailBcc = (bcc) => ({
    type: SET_EMAIL_BCC,
    payload: {
        bcc,
    },
});

export const setEmailSender = (sender) => ({
    type: SET_EMAIL_SENDER,
    payload: {
        sender,
    },
});

export const addNewColleagueContact = (email, formData) => ({
    type: ADD_NEW_COLLEAGUE_CONTACT,

    payload: {
        email,
    },

    meta: {
        requestRoute: 'v1/email/action/add-new-colleague-contact',
        requestMethod: 'POST',

        requestBody: formData,

        onRequestSuccess: ADD_NEW_COLLEAGUE_CONTACT_SUCCESS,
        onRequestFailure: ADD_NEW_COLLEAGUE_CONTACT_FAILURE,
    },
});

export const prepareEmailToPreview = (formData) => ({
    type: PREPARE_EMAIL_TO_PREVIEW,

    meta: {
        requestRoute: 'v1/email/data/prepare-email-preview',
        requestMethod: 'POST',

        requestBody: formData,

        onRequestSuccess: PREPARE_EMAIL_TO_PREVIEW_SUCCESS,
        onRequestFailure: PREPARE_EMAIL_TO_PREVIEW_FAILURE,
    },
});

export const removeColleagueContact = (email, formData) => ({
    type: REMOVE_COLLEAGUE_CONTACT,

    payload: {
        email,
    },

    meta: {
        requestRoute: 'v1/email/action/remove-colleague-contact',
        requestMethod: 'POST',

        requestBody: formData,

        onRequestSuccess: REMOVE_COLLEAGUE_CONTACT_SUCCESS,
        onRequestFailure: REMOVE_COLLEAGUE_CONTACT_FAILURE,
    },
});

export const setSendEmailAt = (sendAtParams) => ({
    type: SET_SEND_EMAIL_AT,
    payload: {
        sendAtParams,
    },
});

export const getVideoInterviewData = () => ({
    type: GET_VIDEO_INTERVIEW_DATA,

    meta: {
        requestRoute: 'v1/video-interview/data',
        requestMethod: 'POST',

        onRequestSuccess: GET_VIDEO_INTERVIEW_DATA_SUCCESS,
        onRequestFailure: GET_VIDEO_INTERVIEW_DATA_FAILURE,
    },
});
