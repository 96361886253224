import {
    RECEIVE_ATTACHMENT_ASSISTANT_MODEL,
    REQUEST_ATTACHMENT_ASSISTANT_MODEL,
    ADD_ATTACHMENTS_IN_BULK,
    REMOVE_ATTACHMENT_FROM_BULK,
    UPLOAD_ATTACHMENTS_LIVE,
    UPLOAD_ATTACHMENTS_LIVE_SUCCESS,
    REMOVE_CANDIDATE_ATTACHMENT,
    REMOVE_CANDIDATE_ATTACHMENT_SUCCESS,
    REMOVE_CANDIDATE_ATTACHMENT_FAILURE,
    ADD_ATTACHMENT_TO_EMAIL,
    REMOVE_ATTACHMENT_FROM_EMAIL,
    RECEIVE_MULTI_ATTACHMENT_ASSISTANT_MODEL,
    REQUEST_MULTI_ATTACHMENT_ASSISTANT_MODEL,
    ADD_INTO_SELECTED_ATTACHMENT_TYPES,
    REMOVE_FROM_SELECTED_ATTACHMENT_TYPES,
    REMOVE_TEMPLATE_ATTACHMENT,
} from '../actions/AttachmentAssistantActions';
import {
    MODAL_OPEN,
    NO_MODAL_SECOND_OPEN,
    NO_MODAL_OPEN,
    MODAL_SECOND_OPEN,
} from '../actions/ModalActions';
import AttachmentAssistantHelper from '../helpers/components/AttachmentAssistantHelper';
import { RECEIVE_REACTION_FORM_MODEL, SAVE_REACTION_SUCCESS } from '../actions/ReactionFormActions';
import { RECEIVE_CREATE_CANDIDATE_MODEL } from '../actions/CreateCandidateActions';
import { SAVE_CANDIDATE_GDPR_AGREEMENT_SUCCESS } from '../actions/ActionWithCandidateActions';
import { RECEIVE_RECOMMENDATION_FORM_MODEL } from '../actions/RecommendationFormActions';
import { RECEIVE_CLOSE_JOB_MODEL } from '../actions/CloseJobActions';
import { OPEN_CANDIDATE_CARD } from '../actions/CandidateCardActions';
import { REQUEST_AUTO_ANSWER_EDITOR_MODEL } from '../actions/AutoAnswerEditorActions';
import { REQUEST_SEND_EMAIL_MODEL } from '../actions/SendEmailActions';

const defaultState = {
    fullHashId: null,
    duplicatedHashId: null,
    emailHashId: null,
    formHashId: null,
    recommendationFormHashId: null,
    fileUploadHashId: null,
    candidateCardModelId: null,
    fullModel: {},
    duplicatedModel: {},
    emailModel: {},
    formModel: {},
    recommendationFormModel: {},
    fileUploadModel: {},
    candidateCardModel: {},
};

const commonReducer = (state, action) => {
    switch (action.type) {
        case REQUEST_ATTACHMENT_ASSISTANT_MODEL:
            return {
                candidateId: action.payload.candidateId,
            };

        case RECEIVE_ATTACHMENT_ASSISTANT_MODEL:
            return {
                ...state,
                settings: action.payload.settings,
                candidateAttachments: action.payload.candidateAttachments,
                templateAttachments: action.meta.requestPayload.templateAttachments,
                bulkUploadAttachments: [],
                liveUploadAttachments: [],
            };

        case REQUEST_MULTI_ATTACHMENT_ASSISTANT_MODEL:
            return {};

        case RECEIVE_MULTI_ATTACHMENT_ASSISTANT_MODEL:
            return {
                ...state,
                settings: action.payload.settings,
                selectedAttachmentTypes: action.payload.selectedAttachmentTypes,
                selectedAttachmentTypesSizes: action.payload.selectedAttachmentTypesSizes,
                templateAttachments: action.meta.requestPayload.templateAttachments,
                bulkUploadAttachments: [],
            };
        default:
            return state;
    }
};

const fullReducer = (state, action) => {
    switch (action.type) {
        case ADD_ATTACHMENTS_IN_BULK:
            return Object.assign({}, state, {
                bulkUploadAttachments: state.bulkUploadAttachments.concat(
                    action.payload.attachments
                ),
            });
        case REMOVE_ATTACHMENT_FROM_BULK:
            const bulkUploadAttachments = state.bulkUploadAttachments;
            bulkUploadAttachments.splice(action.payload.attachmentKey, 1);

            return Object.assign({}, state, {
                bulkUploadAttachments: bulkUploadAttachments,
            });
        case ADD_ATTACHMENT_TO_EMAIL:
            const candidateAttachmentsNew = state.candidateAttachments.concat(
                action.payload.attachment
            );

            return Object.assign({}, state, {
                candidateAttachments: candidateAttachmentsNew,
            });
        case REMOVE_ATTACHMENT_FROM_EMAIL:
            const candidateAttachments = state.candidateAttachments.filter(
                (attachment) => attachment.attachmentId !== action.payload.attachmentId
            );

            return Object.assign({}, state, {
                candidateAttachments: candidateAttachments,
            });
        case UPLOAD_ATTACHMENTS_LIVE:
            return Object.assign({}, state, {
                liveUploadAttachments: state.liveUploadAttachments.concat(
                    action.payload.attachments
                ),
            });
        case UPLOAD_ATTACHMENTS_LIVE_SUCCESS:
            const uploadHash = action.meta.requestPayload.uploadHash;
            const uploadedCandidateAttachments = action.payload.candidateAttachments.filter(
                (uploadedAttachment) => {
                    return (
                        state.candidateAttachments
                            .map((candidateAttachment) => candidateAttachment.attachmentId)
                            .indexOf(uploadedAttachment.attachmentId) === -1
                    );
                }
            );

            return Object.assign({}, state, {
                liveUploadAttachments: state.liveUploadAttachments.filter(
                    (attachment) => attachment.uploadHash !== uploadHash
                ),
                candidateAttachments: state.candidateAttachments.concat(
                    uploadedCandidateAttachments
                ),
            });
        case REMOVE_CANDIDATE_ATTACHMENT:
            return Object.assign({}, state, {
                candidateAttachments: state.candidateAttachments.map((attachment) =>
                    parseInt(attachment.attachmentId) === parseInt(action.payload.attachmentId)
                        ? Object.assign({}, attachment, { removed: true })
                        : attachment
                ),
            });
        case REMOVE_CANDIDATE_ATTACHMENT_SUCCESS:
            return Object.assign({}, state, {
                candidateAttachments: state.candidateAttachments.filter(
                    (attachment) =>
                        parseInt(attachment.attachmentId) !==
                        parseInt(action.meta.requestPayload.attachmentId)
                ),
            });
        case REMOVE_CANDIDATE_ATTACHMENT_FAILURE:
            return Object.assign({}, state, {
                candidateAttachments: state.candidateAttachments.map((attachment) =>
                    parseInt(attachment.attachmentId) ===
                    parseInt(action.meta.requestPayload.attachmentId)
                        ? Object.assign({}, attachment, { removed: false })
                        : attachment
                ),
            });
        case ADD_INTO_SELECTED_ATTACHMENT_TYPES:
            return {
                ...state,
                selectedAttachmentTypes: state.selectedAttachmentTypes.map((selectedType) => ({
                    ...selectedType,
                    selected:
                        selectedType.typeId === action.payload.typeId
                            ? true
                            : selectedType.selected,
                })),
            };
        case REMOVE_FROM_SELECTED_ATTACHMENT_TYPES:
            return {
                ...state,
                selectedAttachmentTypes: state.selectedAttachmentTypes.map((selectedType) => ({
                    ...selectedType,
                    selected:
                        selectedType.typeId === action.payload.typeId
                            ? false
                            : selectedType.selected,
                })),
            };
        case REMOVE_TEMPLATE_ATTACHMENT:
            return {
                ...state,
                templateAttachments: state.templateAttachments.map((attachment) =>
                    attachment.id === action.payload.attachmentId
                        ? {
                              ...attachment,
                              removed: true,
                          }
                        : attachment
                ),
            };
        case SAVE_REACTION_SUCCESS:
            return Object.assign({}, state, {
                bulkUploadAttachments: [],
            });

        case SAVE_CANDIDATE_GDPR_AGREEMENT_SUCCESS:
            return {
                ...state,

                candidateAttachments: action.payload.candidateAttachments,
            };

        default:
            return state;
    }
};

const generalModelReducer = (state = {}, action, isFull = true) => {
    state = commonReducer(state, action);

    if (isFull === true) {
        state = fullReducer(state, action);
    }

    return state;
};

const canUpdateModelOfType = (action, compareId) => {
    return (
        compareId !== null &&
        ((action.hasOwnProperty('payload') === true &&
            action.payload.hasOwnProperty('hashId') === true &&
            action.payload.hashId === compareId) ||
            (action.hasOwnProperty('meta') === true &&
                action.meta.hasOwnProperty('requestPayload') === true &&
                action.meta.requestPayload.hasOwnProperty('hashId') === true &&
                action.meta.requestPayload.hashId === compareId))
    );
};

export const isFetchingMultiAttachmentAssistantModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_MULTI_ATTACHMENT_ASSISTANT_MODEL:
            return true;

        case RECEIVE_MULTI_ATTACHMENT_ASSISTANT_MODEL:
            return false;

        default:
            return state;
    }
};

export const isFetchingAttachmentAssistantModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_ATTACHMENT_ASSISTANT_MODEL:
            return true;

        case RECEIVE_ATTACHMENT_ASSISTANT_MODEL:
            return false;

        default:
            return state;
    }
};

export const attachmentAssistantModel = (state = defaultState, action) => {
    switch (action.type) {
        case NO_MODAL_OPEN:
            return {
                ...state,

                fullHashId:
                    action.payload.modalType === 'DetailCandidateNoModal'
                        ? AttachmentAssistantHelper.createAttachmentAssistantHashIdForCandidates([
                              action.payload.modalProps.candidateId,
                          ])
                        : state.fullHashId,
            };

        case NO_MODAL_SECOND_OPEN:
        case MODAL_SECOND_OPEN:
            if (
                action.payload.modalProps === undefined ||
                action.payload.modalProps.candidateId === undefined
            ) {
                return state;
            }

            return {
                ...state,
                duplicatedHashId:
                    AttachmentAssistantHelper.createAttachmentAssistantHashIdForCandidates([
                        action.payload.modalProps.candidateId,
                    ]),
            };

        case MODAL_OPEN:
            return {
                ...state,

                emailHashId:
                    [
                        'SendEmailModal',
                        'CopyCandidateModal',
                        'ChangeStageOfCandidatesModal',
                        'ScheduleCandidateEventModal',
                        'RefuseCandidateWithEmailModal',
                        'RequestCandidateQuestionnaireModal',
                        'SettingsPageEmailTemplateEditorModal',
                        'SettingsPageAutoAnswerEditorModal',
                    ].includes(action.payload.modalType) === true
                        ? AttachmentAssistantHelper.createAttachmentAssistantHashIdForEmail()
                        : state.emailHashId,
                fileUploadHashId:
                    ['CandidateGdprAgreementModal'].includes(action.payload.modalType) === true
                        ? AttachmentAssistantHelper.createAttachmentAssistantHashIdForFileUpload()
                        : state.fileUploadHashId,

                formModel:
                    action.payload.modalType === 'CreateCandidateModal' ? {} : state.formModel,
            };

        case RECEIVE_CLOSE_JOB_MODEL:
            return {
                ...state,
                emailHashId: AttachmentAssistantHelper.createAttachmentAssistantHashIdForEmail(),
            };

        case REQUEST_SEND_EMAIL_MODEL:
        case REQUEST_AUTO_ANSWER_EDITOR_MODEL:
            return {
                ...state,
                emailModel: {},
                emailHashId: AttachmentAssistantHelper.createAttachmentAssistantHashIdForEmail(),
            };

        case RECEIVE_REACTION_FORM_MODEL:
        case RECEIVE_CREATE_CANDIDATE_MODEL:
            return {
                ...state,
                formHashId: AttachmentAssistantHelper.createAttachmentAssistantHashIdForForm(),
            };

        case RECEIVE_RECOMMENDATION_FORM_MODEL:
            return {
                ...state,
                recommendationFormHashId:
                    AttachmentAssistantHelper.createAttachmentAssistantHashIdForRecommendationForm(),
            };

        case OPEN_CANDIDATE_CARD:
            return {
                ...state,
                candidateCardModelId:
                    AttachmentAssistantHelper.createAttachmentAssistantHashIdForCandidateCard(
                        action.payload.candidateId
                    ),
            };

        default:
            return {
                ...state,

                fullModel:
                    canUpdateModelOfType(action, state.fullHashId) === true
                        ? generalModelReducer(state.fullModel, action)
                        : state.fullModel,

                duplicatedModel:
                    canUpdateModelOfType(action, state.duplicatedHashId) === true
                        ? generalModelReducer(state.duplicatedModel, action, false)
                        : state.duplicatedModel,

                emailModel:
                    canUpdateModelOfType(action, state.emailHashId) === true
                        ? generalModelReducer(state.emailModel, action)
                        : state.emailModel,

                formModel:
                    canUpdateModelOfType(action, state.formHashId) === true
                        ? generalModelReducer(state.formModel, action)
                        : state.formModel,

                fileUploadModel:
                    canUpdateModelOfType(action, state.fileUploadHashId) === true
                        ? generalModelReducer(state.fileUploadModel, action)
                        : state.fileUploadModel,

                recommendationFormModel:
                    canUpdateModelOfType(action, state.recommendationFormHashId) === true
                        ? generalModelReducer(state.recommendationFormModel, action)
                        : state.recommendationFormModel,

                candidateCardModel:
                    canUpdateModelOfType(action, state.candidateCardModelId) === true
                        ? generalModelReducer(state.candidateCardModel, action)
                        : state.candidateCardModel,
            };
    }
};
