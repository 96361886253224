import React, { Component } from 'react';
import Link from '../../helpers/overridden/Link';
import { Modal, Radio } from 'semantic-ui-react';
import Checkbox from '@profesia/adamui/components/common/input/checkbox/Checkbox';
import Button from '../Button';
import Image from '../Image';
import Heading from '../Heading';
import Icon from '../Icon';
import Translations from '../../helpers/Translations';
import deleteImage from '../../../img/icons/delete-large.svg';
import { connect } from 'react-redux';
import Number from '../../helpers/Number';
import LoadingComponent from '../LoadingComponent';
import md5 from 'md5';

class MarkDialog extends Component {
    state = {
        open: true,
        title: this.props.title || '',
        message: this.props.message || {},
        actions: this.props.actions || [],
        closeOnDimmerClick: this.props.closeOnDimmerClick,
    };

    checkActionsChange = (prevProps, props) => {
        const getRequiredPropsFromAction = (action) => ({
            disabled: action.disabled || false,
            loading: action.loading || false,
            title: action.title || '',
            type: action.type,
        });

        if (
            prevProps.hasOwnProperty('actions') === true &&
            props.hasOwnProperty('actions') === true
        ) {
            const jsonedPrevPropsActions = JSON.stringify(
                prevProps.actions.map((action) => getRequiredPropsFromAction(action))
            );
            const jsonedPropsActions = JSON.stringify(
                props.actions.map((action) => getRequiredPropsFromAction(action))
            );

            if (
                jsonedPrevPropsActions !== undefined &&
                jsonedPropsActions !== undefined &&
                md5(JSON.stringify(jsonedPrevPropsActions)) !==
                    md5(JSON.stringify(jsonedPropsActions))
            ) {
                return true;
            }
        }

        return false;
    };

    checkMessageChange = (prevProps, props) => {
        const getRequiredPropsFromMessage = (message) => ({
            iconName: message.iconName || '',
            infoLines: message.infoLines || [],
            title: message.title || '',
            checkbox: message.checkbox || null,
            radioBoxes: message.radioBoxes || [],
        });

        if (
            prevProps.hasOwnProperty('message') === true &&
            props.hasOwnProperty('message') === true
        ) {
            const jsonedPrevPropsMessage = JSON.stringify(
                getRequiredPropsFromMessage(prevProps.message)
            );
            const jsonedPropsMessage = JSON.stringify(getRequiredPropsFromMessage(props.message));

            if (
                jsonedPrevPropsMessage !== undefined &&
                jsonedPropsMessage !== undefined &&
                md5(JSON.stringify(jsonedPrevPropsMessage)) !==
                    md5(JSON.stringify(jsonedPropsMessage))
            ) {
                return true;
            }
        }

        return false;
    };

    componentDidUpdate = (prevProps) => {
        if (
            this.checkActionsChange(prevProps, this.props) ||
            this.checkMessageChange(prevProps, this.props)
        ) {
            this.setState({
                actions: this.props.actions,
                message: this.props.message,
            });
        }
    };

    show = (dimmer) => () => {
        this.setState({
            dimmer,
            open: true,
        });
    };

    close = () => {
        this.setState({
            open: false,
        });
    };

    render() {
        const { dimmer, open, title, message, actions, closeOnDimmerClick = true } = this.state;
        const { showProgress, isLoading } = this.props;

        const actionButton = (action, index) => {
            if (action.as === 'Link') {
                return (
                    <Link
                        key={'modal-action-' + index}
                        className="ui button secondary-button"
                        to={action.to}
                        gtmTag={'modal-action-button-' + action.title}
                    >
                        {action.title}
                    </Link>
                );
            } else if (action.as === 'a') {
                return (
                    <a key={'modal-action-' + index} className="ui link" href={action.to}>
                        {action.title}
                    </a>
                );
            }

            const loading = action.hasOwnProperty('loading') === true && action.loading === true;
            let content = action.title;

            const icon =
                action.hasOwnProperty('icon') === true && action.icon !== null ? action.icon : null;

            if (loading === true) {
                if (showProgress === true) {
                    let { processedChunksCount, totalChunksCount } = this.props.chunkStatus;

                    if (processedChunksCount === undefined || totalChunksCount === undefined) {
                        processedChunksCount = 0;
                        totalChunksCount = 1;
                    }

                    content =
                        Number.format((processedChunksCount / totalChunksCount) * 100, 0) + '%';
                }
            }

            let color = action.hasOwnProperty('color') === true ? action.color : '';

            if (color === '' && action.type === 'negative') {
                color = 'red';
            }

            return (
                <Button
                    key={'modal-action-' + index}
                    className={'secondary-button ' + color}
                    negative={action.type === 'negative'}
                    positive={action.type === 'positive'}
                    content={content}
                    icon={icon}
                    loading={loading === true && showProgress === false}
                    disabled={
                        (action.hasOwnProperty('disabled') === true && action.disabled === true) ||
                        loading === true
                    }
                    onClick={action.action ?? null}
                />
            );
        };

        return (
            <div>
                <Modal
                    size={'small'}
                    dimmer={dimmer}
                    open={open}
                    closeOnDimmerClick={closeOnDimmerClick}
                >
                    {title && <Modal.Header>{title}</Modal.Header>}

                    <Modal.Content image>
                        {message.type === 'exclamation' ? (
                            <Image wrapped src={deleteImage} size="massive" />
                        ) : (
                            <Icon
                                className={
                                    message.iconName === undefined
                                        ? 'exclamation circle'
                                        : message.iconName
                                }
                                size="massive"
                            />
                        )}

                        <Modal.Description>
                            {isLoading === true ? (
                                <LoadingComponent />
                            ) : (
                                <>
                                    {message.title && <Heading as="h2">{message.title}</Heading>}
                                    {message.infoLines &&
                                        message.infoLines.map((info, index) => (
                                            <p
                                                key={'infoline-' + index}
                                                dangerouslySetInnerHTML={{ __html: info }}
                                            >
                                                {}
                                            </p>
                                        ))}
                                    {message.checkbox && message.checkbox.show === true && (
                                        <>
                                            <br />
                                            <Checkbox
                                                label={message.checkbox.label}
                                                checked={message.checkbox.value}
                                                onChange={message.checkbox.onChange}
                                            />
                                        </>
                                    )}
                                    {message.radioBoxes && message.radioBoxes.length > 0 && (
                                        <>
                                            <br />
                                            {message.radioBoxes.map((radioBox, i) => (
                                                <Radio
                                                    key={i}
                                                    value={radioBox.value}
                                                    label={radioBox.label}
                                                    checked={radioBox.checked}
                                                    onChange={radioBox.onChange}
                                                />
                                            ))}
                                            <br />
                                        </>
                                    )}
                                </>
                            )}
                        </Modal.Description>
                    </Modal.Content>

                    <Modal.Actions>
                        {actions === undefined || actions.length === 0 ? (
                            <Button className="secondary-button">
                                {Translations.getStatic('ok')}
                            </Button>
                        ) : (
                            actions.map((action, index) => actionButton(action, index))
                        )}
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

MarkDialog.defaultProps = {
    showProgress: false,
    isLoading: false,
    checkbox: {
        show: false,
    },
};

const mapStateToProps = (state) => ({
    chunkStatus: state.chunkStatus,
});

export default connect(mapStateToProps)(MarkDialog);
