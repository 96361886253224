import React from 'react';
import PropTypes from 'prop-types';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

const Popup = ({ trigger, content, position, children, positionStrategy, width }) => {
    const anchorId = Date.now() + Math.floor(Math.random() * 1000001).toString();

    return (
        <>
            <span className="tooltip-trigger" id={anchorId}>
                {trigger}
            </span>
            <div className="tooltip-container">
                <Tooltip
                    anchorId={anchorId}
                    place={position}
                    variant="light"
                    positionStrategy={positionStrategy}
                    style={{ width }}
                >
                    {children && <span className="tooltip-html">{children}</span>}
                    {content && <span className="tooltip-text">{content}</span>}
                </Tooltip>
            </div>
        </>
    );
};

Popup.propTypes = {
    trigger: PropTypes.object.isRequired,
    children: PropTypes.object,
    content: PropTypes.string,
    position: PropTypes.string,
    positionStrategy: PropTypes.string,
    width: PropTypes.string,
};

Popup.defaultProps = {
    position: 'top',
    positionStrategy: 'absolute',
};

export default Popup;
