import Form from '../Form';
import SendEmailHelper from './SendEmailHelper';
import Translations from '../Translations';
import Constants from '../Constants';
import Variables from '../Variables';
import DateFormatted from '../DateFormatted';

export default class SendEmailValidatorHelper {
    constructor(
        recipients,
        message,
        subject,
        sendAt,
        candidateIds,
        excludedCandidateIdsFromSendEmail = [],
        emailType = Constants.EMAIL_TYPE_TO_COLLEAGUE
    ) {
        this.recipients = recipients.filter(
            (recipient) =>
                recipient.hasOwnProperty('candidateId') === false ||
                excludedCandidateIdsFromSendEmail.indexOf(recipient.candidateId) === -1
        );
        this.message = message.trim();
        this.subject = subject.trim();
        this.sendAt = sendAt;
        this.emailType = emailType;
        this.candidateIds = candidateIds.filter(
            (candidateId) => excludedCandidateIdsFromSendEmail.indexOf(candidateId) === -1
        );
    }

    checkRecipients() {
        const emails = SendEmailHelper.filterNotEmptyEmailsFromRecipients(this.recipients);

        const correctEmails = emails.filter(
            (email) =>
                Form.check({
                    fieldType: 'email',
                    fieldValue: email.value,
                }) === true
        );

        if (this.emailType === Constants.EMAIL_TYPE_TO_CANDIDATE) {
            if (this.candidateIds.length === 1) {
                const recipientEmail =
                    emails.length > 0 && emails[0].hasOwnProperty('value') === true
                        ? emails[0].value
                        : '';

                return Form.multiCheck([
                    {
                        fieldName: 'recipients',
                        fieldType: 'email',
                        fieldValue: recipientEmail,
                    },
                ]);
            }

            if (correctEmails.length === 0) {
                return ['allRecipientsWithoutEmails'];
            }

            if (correctEmails.length < this.candidateIds.length) {
                return ['someRecipientsWithoutEmails'];
            }
        }

        const fieldsToCheck = [
            {
                fieldName: 'recipients',
                fieldType: 'notEmpty',
                fieldValue: SendEmailHelper.filterNotEmptyEmailsFromRecipients(this.recipients),
            },
        ];

        const errors = Form.multiCheck(fieldsToCheck);

        if (errors.length > 0) {
            return errors;
        }

        if (correctEmails.length < emails.length) {
            return ['recipients'];
        }

        return [];
    }

    checkEmail() {
        const fieldsToCheck = [
            {
                fieldName: 'subject',
                fieldType: 'boolean',
                fieldValue: this.subject !== '<p></p>',
            },
        ];

        if (this.emailType !== Constants.EMAIL_TYPE_TO_COLLEAGUE) {
            fieldsToCheck.push({
                fieldName: 'message',
                fieldType: 'boolean',
                fieldValue: this.message !== '<p></p>',
            });
        }

        if (this.sendAt.type === Constants.SEND_EMAIL_LATER) {
            fieldsToCheck.push({
                fieldName: 'sendAt',
                fieldType: 'boolean',
                fieldValue:
                    this.sendAt.datetime !== null &&
                    DateFormatted.getTimestamp(this.sendAt.datetime) > DateFormatted.getTimestamp(),
            });
        }

        return Form.multiCheck(fieldsToCheck);
    }

    checkSendEmail(
        withAlerts = true,
        variableCheckParams = {
            variables: [],
            filledVariables: {},
            requiredVariables: {},
            forbiddenVariables: {},
        }
    ) {
        let errors = this.checkEmail();

        const filledVariables = variableCheckParams?.filledVariables ?? {};
        const requiredVariables = variableCheckParams?.requiredVariables ?? {};
        const forbiddenVariables = variableCheckParams?.forbiddenVariables ?? {};

        if (Object.keys(requiredVariables).length > 0) {
            const variableErrors = Variables.checkRequiredVariables(
                variableCheckParams.variables,
                [this.subject, this.message],
                requiredVariables.actionTypesToCheck
            );

            if (variableErrors.length > 0) {
                variableErrors.forEach((variableError) => {
                    if (withAlerts === true) {
                        alert(Translations.getStatic(variableError));
                    }

                    errors.push(variableError);
                });
            }
        }

        if (Object.keys(forbiddenVariables).length > 0) {
            const variableErrors = Variables.checkForbiddenVariables(
                variableCheckParams.variables,
                [this.subject, this.message],
                forbiddenVariables.actionTypesToCheck
            );

            if (variableErrors.length > 0) {
                variableErrors.forEach((variableError) => {
                    if (withAlerts === true) {
                        alert(Translations.getStatic(variableError));
                    }

                    errors.push(variableError);
                });
            }
        }

        if (Object.keys(filledVariables).length > 0) {
            const variableErrors = Variables.checkFilledVariables(
                variableCheckParams.variables,
                [this.subject, this.message],
                filledVariables.actionTypesToCheck,
                filledVariables.dataToCheck
            );

            if (variableErrors.length > 0) {
                variableErrors.forEach((variableError) => {
                    if (withAlerts === true) {
                        alert(Translations.getStatic(variableError));
                    }

                    errors.push(variableError);
                });
            }
        }

        return errors.concat(this.checkRecipients());
    }
}
