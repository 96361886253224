import React from 'react';
import Translations from '../../helpers/Translations';
import MarkDialog from '../Modal/MarkDialog';
import { connect } from 'react-redux';
import { closeNoJobAccessDialog } from '../../actions/DialogActions';

const NoJobAccessDialog = (props) => {
    return (
        props.noJobAccessDialogOpen === true && (
            <MarkDialog
                closeOnDimmerClick={false}
                message={{
                    iconName: 'icon-permission',
                    title: Translations.getStatic('noJobAccessTitle'),
                    infoLines: [Translations.getStatic('noJobAccessText')],
                }}
                actions={[
                    {
                        title: Translations.getStatic('close'),
                        action: () => props.closeNoJobAccessDialog(),
                    },
                ]}
            />
        )
    );
};

const mapStateToProps = (state) => ({
    noJobAccessDialogOpen: state.noJobAccessDialogOpen,
});

const mapDispatchToProps = {
    closeNoJobAccessDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(NoJobAccessDialog);
