import {
    REQUEST_COMPANY_SETTINGS_MODEL,
    RECEIVE_COMPANY_SETTINGS_MODEL,
    REQUEST_COMPANY_PRICE_LIST_MODEL,
    RECEIVE_COMPANY_PRICE_LIST_MODEL,
    CANCEL_SUBSCRIPTION,
    CANCEL_SUBSCRIPTION_SUCCESS,
    CANCEL_SUBSCRIPTION_FAILURE,
    DEACTIVATE_MARK,
    DEACTIVATE_MARK_SUCCESS,
    DEACTIVATE_MARK_FAILURE,
    RESTORE_SUBSCRIPTION,
    RESTORE_SUBSCRIPTION_SUCCESS,
    RESTORE_SUBSCRIPTION_FAILURE,
    CANCEL_TRIAL_SUBSCRIPTION,
    CANCEL_TRIAL_SUBSCRIPTION_SUCCESS,
    CANCEL_TRIAL_SUBSCRIPTION_FAILURE,
    ACTIVATE_COMPANY_EXPORT,
    ACTIVATE_COMPANY_EXPORT_SUCCESS,
    ACTIVATE_COMPANY_EXPORT_FAILURE,
    DEACTIVATE_COMPANY_EXPORT,
    DEACTIVATE_COMPANY_EXPORT_SUCCESS,
    DEACTIVATE_COMPANY_EXPORT_FAILURE,
    SET_COMPANY_PUSH_EXPORT_URL,
    SET_COMPANY_PUSH_EXPORT_URL_SUCCESS,
    SET_COMPANY_PUSH_EXPORT_URL_FAILURE,
    SEND_PUSH_EXPORT_TEST_REACTION,
    SEND_PUSH_EXPORT_TEST_REACTION_SUCCESS,
    SEND_PUSH_EXPORT_TEST_REACTION_FAILURE,
} from '../actions/CompanySettingsActions';
import {
    SAVE_COMPANY_CANDIDATES_GDPR_EXPIRATION_SETTINGS,
    SAVE_COMPANY_CANDIDATES_GDPR_EXPIRATION_SETTINGS_FAILURE,
    SAVE_COMPANY_CANDIDATES_GDPR_EXPIRATION_SETTINGS_SUCCESS,
} from '../actions/CandidatesGdprSettingsActions';
import Objects from '../helpers/Objects';

export const companySettingsModel = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_COMPANY_SETTINGS_MODEL:
            return {};

        case RECEIVE_COMPANY_SETTINGS_MODEL:
            return {
                ...state,
                ...action.payload,
            };

        case SAVE_COMPANY_CANDIDATES_GDPR_EXPIRATION_SETTINGS_SUCCESS:
            return {
                ...state,
                candidatesGdprExpirationMonths: action.payload.candidatesGdprExpirationMonths,
            };

        case CANCEL_SUBSCRIPTION_SUCCESS:
        case DEACTIVATE_MARK_SUCCESS:
        case RESTORE_SUBSCRIPTION_SUCCESS:
        case CANCEL_TRIAL_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                futurePackages: action.payload.futurePackages,
            };

        case ACTIVATE_COMPANY_EXPORT_SUCCESS:
        case DEACTIVATE_COMPANY_EXPORT_SUCCESS:
        case SET_COMPANY_PUSH_EXPORT_URL_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };

        case SEND_PUSH_EXPORT_TEST_REACTION:
            return {
                ...state,
                testReactionResponse: null,
            };

        case SEND_PUSH_EXPORT_TEST_REACTION_SUCCESS:
        case SEND_PUSH_EXPORT_TEST_REACTION_FAILURE:
            return {
                ...state,
                testReactionResponse: action.payload.testReactionResponse,
            };

        default:
            return state;
    }
};

export const companyPriceListModel = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_COMPANY_PRICE_LIST_MODEL:
            return {};

        case RECEIVE_COMPANY_PRICE_LIST_MODEL:
            return {
                ...state,
                ...action.payload,
                errors: [],
            };

        default:
            return state;
    }
};

export const isFetchingCompanySettingsModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_COMPANY_SETTINGS_MODEL:
            return true;

        case RECEIVE_COMPANY_SETTINGS_MODEL:
            return false;

        default:
            return state;
    }
};

export const isSavingCompanyCandidatesGdprExpirationSettings = (state = false, action) => {
    switch (action.type) {
        case SAVE_COMPANY_CANDIDATES_GDPR_EXPIRATION_SETTINGS:
            return true;

        case SAVE_COMPANY_CANDIDATES_GDPR_EXPIRATION_SETTINGS_SUCCESS:
        case SAVE_COMPANY_CANDIDATES_GDPR_EXPIRATION_SETTINGS_FAILURE:
            return false;

        default:
            return state;
    }
};

export const isFetchingCompanyPriceListModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_COMPANY_PRICE_LIST_MODEL:
            return true;

        case RECEIVE_COMPANY_PRICE_LIST_MODEL:
            return false;

        default:
            return state;
    }
};

export const isFetchingCompanyExportModel = (state = false, action) => {
    switch (action.type) {
        case ACTIVATE_COMPANY_EXPORT:
        case DEACTIVATE_COMPANY_EXPORT:
            return true;

        case ACTIVATE_COMPANY_EXPORT_SUCCESS:
        case ACTIVATE_COMPANY_EXPORT_FAILURE:
        case DEACTIVATE_COMPANY_EXPORT_SUCCESS:
        case DEACTIVATE_COMPANY_EXPORT_FAILURE:
            return false;

        default:
            return state;
    }
};

export const isModifyingServices = (state = false, action) => {
    switch (action.type) {
        case CANCEL_SUBSCRIPTION:
        case CANCEL_TRIAL_SUBSCRIPTION:
        case DEACTIVATE_MARK:
        case RESTORE_SUBSCRIPTION:
        case SET_COMPANY_PUSH_EXPORT_URL:
        case SEND_PUSH_EXPORT_TEST_REACTION:
            return true;

        case CANCEL_SUBSCRIPTION_SUCCESS:
        case CANCEL_SUBSCRIPTION_FAILURE:
        case CANCEL_TRIAL_SUBSCRIPTION_SUCCESS:
        case CANCEL_TRIAL_SUBSCRIPTION_FAILURE:
        case DEACTIVATE_MARK_SUCCESS:
        case DEACTIVATE_MARK_FAILURE:
        case RESTORE_SUBSCRIPTION_SUCCESS:
        case RESTORE_SUBSCRIPTION_FAILURE:
        case SET_COMPANY_PUSH_EXPORT_URL_SUCCESS:
        case SET_COMPANY_PUSH_EXPORT_URL_FAILURE:
        case SEND_PUSH_EXPORT_TEST_REACTION_SUCCESS:
        case SEND_PUSH_EXPORT_TEST_REACTION_FAILURE:
            return false;

        default:
            return state;
    }
};
