import React from 'react';
import { connect } from 'react-redux';
import User from '../../helpers/User';
import iconDictionary from '../../../img/icons/icon-dictionary.png';
import iconHammer from '../../../img/icons/icon-hammer.gif';
import Storages from '../../helpers/Storages';
import Constants from '../../helpers/Constants';
import {
    disableTranslationKeysInfo,
    enableTranslationKeysInfo,
    enableInternalInfo,
    disableInternalInfo,
} from '../../actions/InternalInfoActions';
import { updateDimensions } from '../../actions/DisplaySizeActions';

const InternalInfoController = (props) => {
    const enableTranslationKeysInfo = () => {
        props.enableTranslationKeysInfo();

        Storages.setLocal(Constants.TRANSLATION_KEYS_INFO_LOCAL_STORAGE_KEY, true);

        props.updateDimensions();
    };

    const disableTranslationKeysInfo = () => {
        props.disableTranslationKeysInfo();

        Storages.setLocal(Constants.TRANSLATION_KEYS_INFO_LOCAL_STORAGE_KEY, false);

        props.updateDimensions();
    };

    const enableInternalInfo = () => {
        props.enableInternalInfo();

        Storages.setLocal(Constants.INTERNAL_INFO_LOCAL_STORAGE_KEY, true);
    };

    const disableInternalInfo = () => {
        props.disableInternalInfo();

        Storages.setLocal(Constants.INTERNAL_INFO_LOCAL_STORAGE_KEY, false);
    };

    let internalInfoController = null;
    let translationKeysInfoController = null;

    if (User.internalInfoEnabled(props.loginManagerModel) === true) {
        translationKeysInfoController = (
            <img
                src={iconDictionary}
                alt="Toggle strings"
                title="Toggle strings"
                className={
                    'translation-keys-info-controller' +
                    (props.internalInfoModel.showTranslationKeysInfo ? ' active' : '')
                }
                height={38}
                width={38}
                onClick={
                    props.internalInfoModel.showTranslationKeysInfo
                        ? disableTranslationKeysInfo
                        : enableTranslationKeysInfo
                }
            />
        );

        internalInfoController = (
            <img
                src={iconHammer}
                alt="Toggle internal info"
                title="Toggle internal info"
                className={
                    'internal-info-controller' +
                    (props.internalInfoModel.showInternalInfo ? ' active' : '')
                }
                height={38}
                width={38}
                onClick={
                    props.internalInfoModel.showInternalInfo
                        ? disableInternalInfo
                        : enableInternalInfo
                }
            />
        );
    }

    return (
        <>
            {translationKeysInfoController}
            {internalInfoController}
            {props.children}
        </>
    );
};

const mapStateToProps = (state) => ({
    loginManagerModel: state.loginManagerModel,
    internalInfoModel: state.internalInfoModel,
});

const mapDispatchToProps = {
    enableTranslationKeysInfo,
    disableTranslationKeysInfo,
    enableInternalInfo,
    disableInternalInfo,
    updateDimensions,
};

export default connect(mapStateToProps, mapDispatchToProps)(InternalInfoController);
