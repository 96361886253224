import React from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material';
import Translations from '../helpers/Translations';
import User from '../helpers/User';
import Link from '../helpers/overridden/Link';
import Header4MenuEllipsisButton from './Header4MenuEllipsisButton';
import Header4MenuPositionDetailButton from './Header4MenuPositionDetailButton';
import Header4MenuPublishPositionButton from './Header4MenuPublishPositionButton';
import Header4MenuCopyPositionButton from './Header4MenuCopyPositionButton';
import Header4MenuJobStatus from './Header4MenuJobStatus';
import MobileHeaderNavBar from './MobileHeaderNavBar';

interface MobileHeader4MenuNewDetailProps {
    backButton: any;
    loginManagerModel: any;
    jobModel: any;
    isFetchingJobModel: boolean;
    displaySize: any;
}

interface HeaderTitleProps {
    job: any;
    jobDetailHeader: any;
    isFetchingJobModel: boolean;
    id: string | number;
}
// @TODO: Extract HeaderTitle to a separate file MARK-3044
const HeaderTitle = ({ job, jobDetailHeader, isFetchingJobModel, id }: HeaderTitleProps) => {
    let jobTitle;

    if (isFetchingJobModel === true) {
        jobTitle = jobDetailHeader === null ? job.jobTitle : jobDetailHeader.jobTitle;
    } else {
        jobTitle = job.jobTitle;
    }

    return jobTitle === undefined || jobTitle.trim() === '' ? (
        <h3>
            <em>{Translations.getStatic('missingPositionName') + ' (jobId: ' + id + ')'}</em>
        </h3>
    ) : (
        <h3 className="ellipsis">{jobTitle}</h3>
    );
};

interface BackButtonProps {
    backButton: {
        location: string;
    };
}
// @TODO: Extract BackButtonComponent to a separate file MARK-3044
const BackButtonComponent = ({ backButton }: BackButtonProps) => {
    return <BackButton className="square-button">{backButton.location.startsWith('mark') ? <Link {...({ to: backButton.location, gtmTag: 'menu-back-button' } as any)}></Link> : <a href={backButton.location} data-gtm-tag="menu-back-button"></a>}</BackButton>;
};

const MobileHeader4MenuNewDetail = (props: MobileHeader4MenuNewDetailProps) => {
    const { backButton, loginManagerModel, jobModel, isFetchingJobModel, displaySize } = props;

    const jobDetailHeader = useSelector((state: any) => state.jobDetailHeader);
    const isFetchingGetPositionData = useSelector((state: any) => state.isFetchingGetPositionData);

    const { job = {}, candidates = [] } = jobModel || {};
    const { stateId, id, isTalentPool, offer } = job;
    const isExternalVisitor = User.isExternalVisitor(loginManagerModel, jobModel);
    const isOfferNotPublished = !offer || (offer.isApproved && !offer.isActive);

    const positionActionButton = isOfferNotPublished ? <Header4MenuPublishPositionButton displaySize={displaySize} /> : <Header4MenuPositionDetailButton isLoading={isFetchingGetPositionData || !job?.position} />;

    return (
        <HeaderContainer className={'template-header header-extended_dark' + (User.isSuperAdmin(loginManagerModel) ? ' admin' : '')}>
            <TopRow>
                <LeftSide className="nav-grid">
                    <BackButtonComponent backButton={backButton} />
                    <TitleContainer className="nav-grid__title">
                        <HeaderTitle job={job} jobDetailHeader={jobDetailHeader} isFetchingJobModel={isFetchingJobModel} id={id} />
                    </TitleContainer>
                </LeftSide>

                {!isTalentPool && (
                    <StyledEllipsisButton>
                        <Header4MenuEllipsisButton stateId={stateId} jobId={id} isExternalVisitor={isExternalVisitor} candidates={candidates} positionActionButton={positionActionButton} copyPositionButton={<Header4MenuCopyPositionButton isLoading={isFetchingGetPositionData || !job?.position} />} />
                    </StyledEllipsisButton>
                )}
            </TopRow>

            {!isTalentPool && (
                <MiddleRow>
                    <Header4MenuJobStatus offer={offer} />
                </MiddleRow>
            )}

            {isExternalVisitor === false && <MobileHeaderNavBar jobId={id} hasStatistics={job.hasStatistics} />}
        </HeaderContainer>
    );
};

const HeaderContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '127px',
});

const TopRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '52px',
});

const MiddleRow = styled('div')({
    width: '100%',
    height: '24px',
    marginBottom: '0',
    paddingLeft: '40px',
    boxSizing: 'content-box',
});

const LeftSide = styled('div')({
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
});

const StyledEllipsisButton = styled('div')({
    width: '48px',
    height: '52px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',

    '& .extended-action__icon-status': {
        paddingTop: '12px',
    },
});

const BackButton = styled('div')({
    width: '48px !important',
    minWidth: '48px !important',
    height: '52px !important',
});

const TitleContainer = styled('div')({
    marginLeft: '8px',
    overflow: 'hidden',
});

export default MobileHeader4MenuNewDetail;
