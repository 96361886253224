import React, { Component } from 'react';
import { connect } from 'react-redux';
import DashboardCandidatesInOffersNoModal from '../Modals/DashboardCandidatesInOffersNoModal';
import { closeNoModalDashboard } from '../../actions/ModalActions';
import GoogleAnalytics from '../../helpers/GoogleAnalytics';
import Constants from '../../helpers/Constants';
import StageCandidatesModal from '../Job/components/Pipeline/Modal/StageCandidatesModal';

class NoModalDashboardManager extends Component {
    state = {
        openingNoModalDashboardType: null,
        openingNoModalDashboardProps: null,
    };

    handleClose = () => {
        const { closeNoModalDashboard } = this.props;
        const { openingNoModalDashboardProps } = this.state;

        if (openingNoModalDashboardProps?.onClose) {
            openingNoModalDashboardProps.onClose();
        }

        closeNoModalDashboard();
    };

    componentDidUpdate(prevProps) {
        if (
            prevProps.currentNoModalDashboard.noModalDashboardIsOpen === false &&
            this.props.currentNoModalDashboard.noModalDashboardIsOpen === true
        ) {
            this.setState({
                openingNoModalDashboardType:
                    this.props.currentNoModalDashboard.noModalDashboardType,
                openingNoModalDashboardProps:
                    this.props.currentNoModalDashboard.noModalDashboardProps,
            });

            GoogleAnalytics.sendModalView(this.props.currentNoModalDashboard.noModalDashboardType);
        } else if (
            prevProps.currentNoModalDashboard.noModalDashboardIsOpen === true &&
            this.props.currentNoModalDashboard.noModalDashboardIsOpen === false
        ) {
            setTimeout(() => {
                this.setState({
                    openingNoModalDashboardType: null,
                    openingNoModalDashboardProps: null,
                });
            }, Constants.MODAL_ANIMATION_TIME_CLOSE);
        }
    }

    render() {
        const { openingNoModalDashboardType, openingNoModalDashboardProps } = this.state;

        const modalLookup = {
            DashboardCandidatesInOffersNoModal,
            StageCandidatesModal,
        };

        if (openingNoModalDashboardType !== null) {
            const ModalComponent = modalLookup[openingNoModalDashboardType];

            return (
                <ModalComponent
                    {...openingNoModalDashboardProps}
                    isOpen={true}
                    onClose={this.handleClose}
                />
            );
        }

        return null;
    }
}

const mapStateToProps = (state) => ({
    currentNoModalDashboard: state.noModalDashboardLogic,
});

const mapDispatchToProps = {
    closeNoModalDashboard,
};

export default connect(mapStateToProps, mapDispatchToProps)(NoModalDashboardManager);
