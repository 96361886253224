import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '../../Grid';
import Item from '../../Item';
import Icon from '../../Icon';
import Url from '../../../helpers/Url';
import Translations from '../../../helpers/Translations';
import DateFormatted from '../../../helpers/DateFormatted';
import User from '../../../helpers/User';
import { openNoJobAccessDialog } from '../../../actions/DialogActions';
import WithRouterProp from '../../../helpers/WithRouterProp';
import Constants from '../../../helpers/Constants';

class DashboardOffersListRow extends Component {
    openJob = (event, offer) => {
        if (offer.hasLockedCandidates === true) {
            this.props.openNoJobAccessDialog();
        } else {
            const { loginManagerModel, navigate } = this.props;

            navigate(Url.buildJobLink(offer.jobId, User.getDefaultJobViewId(loginManagerModel)));
        }
    };

    render = () => {
        const { offer, displaySize } = this.props;

        const getLocation = (offer) => {
            let location = '';
            let workplaceType = '';

            if (offer.location.text !== '') {
                location = offer.location.text;
            }

            if (offer.location.workplaceTypeId !== null) {
                workplaceType = Translations.getWorkplaceType(offer.location.workplaceTypeId);
            }

            if (location === '') {
                return workplaceType;
            }

            if (offer.location.workplaceTypeId === Constants.OFFER_WORKPLACE_TYPE_ONSITE) {
                return location;
            }

            return location + ' (' + workplaceType + ')';
        };

        return (
            <Item className="card-job">
                <Item.Content>
                    <Grid verticalAlign="middle">
                        <Grid.Column>
                            <div className="content_left">
                                <Item.Header
                                    as={'a'}
                                    href={offer.link}
                                    className="ellipsis"
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            offer.title ||
                                            '<em>' +
                                                Translations.getStatic('missingPositionName') +
                                                ' (offerId: ' +
                                                offer.id +
                                                ')</em>',
                                    }}
                                />
                                <Item.Description className="text-paragraph">
                                    <span>{getLocation(offer)}</span>
                                </Item.Description>
                                <Item.Meta className="text-paragraph">
                                    <Icon className="icon-time-small" />
                                    {Translations.getStatic('activeOfferPeriodInfo')
                                        .replace(
                                            /%validFrom%/g,
                                            DateFormatted.getDate(offer.validFrom)
                                        )
                                        .replace(
                                            /%validUntil%/g,
                                            DateFormatted.getDate(offer.validTill)
                                        )}
                                </Item.Meta>
                            </div>
                            <div className="content_right">
                                <div className="candidates-box">
                                    {displaySize.isMobileProfesia === false && (
                                        <div className="candidates-box__number">
                                            <div className="number">
                                                {offer.hasLockedCandidates === true && (
                                                    <Icon className="icon-locked small" />
                                                )}
                                                {[
                                                    offer.counts.newCandidatesCount,
                                                    offer.counts.activeCandidatesCount,
                                                ].join('/')}
                                            </div>
                                        </div>
                                    )}
                                    <div className={'candidates-box__text ellipsis'}>
                                        {displaySize.isMobileProfesia === true ? (
                                            <>
                                                <span
                                                    className="link"
                                                    role="link"
                                                    onClick={(event) => this.openJob(event, offer)}
                                                >
                                                    {[
                                                        offer.counts.newCandidatesCount,
                                                        offer.counts.activeCandidatesCount,
                                                    ].join('/')}{' '}
                                                    {Translations.getStatic('candidatesGenitive')}
                                                </span>
                                                {offer.hasLockedCandidates === true && (
                                                    <Icon className="icon-locked mini" />
                                                )}
                                            </>
                                        ) : (
                                            <span
                                                className="link"
                                                role="link"
                                                onClick={(event) => this.openJob(event, offer)}
                                            >
                                                {Translations.getStatic('candidatesGenitive')}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Grid.Column>
                    </Grid>
                </Item.Content>
            </Item>
        );
    };
}

const mapStateToProps = (state) => ({
    displaySize: state.displaySize,
    loginManagerModel: state.loginManagerModel,
});

const mapDispatchToProps = {
    openNoJobAccessDialog,
};

DashboardOffersListRow.propTypes = {
    offer: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(WithRouterProp(DashboardOffersListRow));
