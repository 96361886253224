import React, { Component } from 'react';
import MarkModal from '../Modal/MarkModal';
import { connect } from 'react-redux';
import Form from '../Form';
import Heading from '../Heading';
import { closeModal } from '../../actions/ModalActions';
import Translations from '../../helpers/Translations';
import MarkLiveAgent from '../../helpers/MarkLiveAgent';

class SendSimpleEmailModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            contactFormLoaded: false,
        };
    }

    componentDidMount() {
        if (this.state.contactFormLoaded === false) {
            MarkLiveAgent.getContactForm();

            this.setState({
                contactFormLoaded: true,
            });
        }
    }

    render() {
        const { isOpen, closeModal, isFetchingSendEmailModel } = this.props;

        return (
            <MarkModal
                actionButton={{
                    visible: false,
                }}
                isOpen={isOpen}
                closeModal={closeModal}
                title={Translations.getStatic('yourRequest')}
                isLoadingData={isFetchingSendEmailModel}
                content={
                    <Form className={isFetchingSendEmailModel ? 'loading' : ''}>
                        <Heading as="h3">{Translations.getStatic('yourRequest')}</Heading>

                        <div id="liveAgentContactForm"></div>
                    </Form>
                }
            />
        );
    }
}

SendSimpleEmailModal.propTypes = {};

const mapStateToProps = (state) => ({
    isOpen: state.modalLogic.isOpen,
});

const mapDispatchToProps = {
    closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(SendSimpleEmailModal);
