import Url from './Url';
import Log from './Log';

const LOCAL_STORAGE: string = 'localStorage';
const SESSION_STORAGE: string = 'sessionStorage';

class Storages {
    static get LOCAL_STORAGE(): string {
        return LOCAL_STORAGE;
    }

    static get SESSION_STORAGE(): string {
        return SESSION_STORAGE;
    }

    static isAvailable(type: string): boolean {
        try {
            let storage: Storage;

            switch (type) {
                case this.SESSION_STORAGE:
                    storage = window.sessionStorage;
                    break;

                case this.LOCAL_STORAGE:
                default:
                    storage = window.localStorage;
            }

            const x: string = '__storage_test__';

            storage.setItem(x, x);
            storage.removeItem(x);

            return true;
        } catch (e) {
            return false;
        }
    }

    static setLocal(name: string, value: any): boolean {
        if (this.isAvailable(this.LOCAL_STORAGE)) {
            if (this.getLocal(name, '') !== value) {
                localStorage.setItem(name, JSON.stringify(value));

                if (Url.isNotOnline()) {
                    Log.storage(
                        this.LOCAL_STORAGE + ': WRITE ' + name + ': ' + JSON.stringify(value)
                    );
                }
            }

            return true;
        }

        return false;
    }

    static getLocal(name: string, defaultValue: any = null): any {
        let value: any = defaultValue;

        if (this.isAvailable(this.LOCAL_STORAGE)) {
            try {
                const itemValue: string | null = localStorage.getItem(name);

                if (itemValue !== null) {
                    value = JSON.parse(itemValue);
                }
            } catch (e) {
                localStorage.removeItem(name);
            }
        }

        return value;
    }

    static removeLocal(name: string): boolean {
        if (this.isAvailable(this.LOCAL_STORAGE)) {
            localStorage.removeItem(name);

            if (Url.isNotOnline()) {
                Log.storage(this.LOCAL_STORAGE + ': REMOVE ' + name);
            }

            return true;
        }

        return false;
    }

    static setSession(name: string, value: any): boolean {
        if (this.isAvailable(this.SESSION_STORAGE)) {
            if (this.getSession(name, '') !== value) {
                sessionStorage.setItem(name, JSON.stringify(value));

                if (Url.isNotOnline()) {
                    Log.storage(
                        this.SESSION_STORAGE + ': WRITE ' + name + ': ' + JSON.stringify(value)
                    );
                }
            }

            return true;
        }

        return false;
    }

    static getSession(name: string, defaultValue: any = null): any {
        let value: any = defaultValue;

        if (this.isAvailable(this.SESSION_STORAGE)) {
            try {
                const itemValue: string | null = sessionStorage.getItem(name);

                if (itemValue !== null) {
                    value = JSON.parse(itemValue);
                }
            } catch (e) {
                sessionStorage.removeItem(name);
            }
        }

        return value;
    }

    static removeSession(name: string): boolean {
        if (this.isAvailable(this.SESSION_STORAGE)) {
            sessionStorage.removeItem(name);

            if (Url.isNotOnline()) {
                Log.storage(this.SESSION_STORAGE + ': REMOVE ' + name);
            }

            return true;
        }

        return false;
    }
}

export default Storages;
