import React from 'react';
import { SkeletonRectangular } from '@profesia/adamui/components/common/skeleton';
import { Stack } from '@mui/material';

const JobsListFilterSkeleton = () => (
    <div className="filter">
        <div className="filter__header">
            <Stack mt={1} mb={6}>
                <SkeletonRectangular width={80} height={16} borderRadius={26} />
            </Stack>
        </div>

        <div className="filter__items skeleton">
            <Stack spacing={5}>
                {[...Array(4)].map((e, i) => (
                    <Stack spacing={1} key={i}>
                        <SkeletonRectangular width={64} height={10} borderRadius={26} />
                        <SkeletonRectangular width={288} height={32} borderRadius={4} />
                    </Stack>
                ))}
            </Stack>
        </div>
    </div>
);

export default JobsListFilterSkeleton;
