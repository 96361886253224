import React, { Component } from 'react';
import { connect } from 'react-redux';
import Form from '../Form';
import Grid from '../Grid';
import Divider from '../Divider';
import MarkModal from '../Modal/MarkModal';
import { closeModal } from '../../actions/ModalActions';
import PropTypes from 'prop-types';
import Translations from '../../helpers/Translations';
import sanitizeHtml from 'sanitize-html';
import DateFormatted from '../../helpers/DateFormatted';
import Constants from '../../helpers/Constants';
import { cancelScheduledEmail } from '../../actions/ScheduledEmailActions';
import SuccessMessage from '../../helpers/SuccessMessage';
import DetailEmailAttachmentColumn from '../AttachmentsAssistant/DetailEmailAttachmentColumn';

class ScheduledEmailDetailModal extends Component {
    componentDidUpdate = (prevProps) => {
        if (
            SuccessMessage.canShowSuccessMessage(
                prevProps,
                this.props,
                'isCancellingScheduledEmail'
            ) &&
            this.props.scheduledEmailModel.hasOwnProperty('isSuccess') === true &&
            this.props.scheduledEmailModel.isSuccess === true
        ) {
            this.props.closeModal();
        }
    };

    render() {
        const {
            scheduledEmail: { id, body, subject, sendAt, emailData, isWaitingForSend, candidateId },
            actionType,
            title,
            isOpenModal,
            closeModal,
        } = this.props;

        const ColumnTo = () => {
            return (
                <Grid.Column computer={12} tablet={16}>
                    {emailData.recipients.join(', ')}
                </Grid.Column>
            );
        };

        const actionButton =
            actionType === Constants.SCHEDULED_EMAIL_ACTION_TYPE_SCHEDULED &&
            isWaitingForSend === true
                ? {
                      loading: this.props.isCancellingScheduledEmail === true,
                      disabled: this.props.isCancellingScheduledEmail === true,
                      colorRed: true,
                      visible: true,
                      text: Translations.getStatic('cancelScheduledEmail'),
                      onClick: () => this.props.cancelScheduledEmail(candidateId, id),
                  }
                : {
                      visible: false,
                  };

        return (
            <MarkModal
                isOpen={isOpenModal}
                closeModal={closeModal}
                title={title}
                actionButton={actionButton}
                entityOption={{
                    visible: false,
                }}
                content={
                    <Form className={'detail-email'}>
                        <Grid>
                            <Grid.Row className="horizontal-row">
                                <Grid.Column computer={4} tablet={16}>
                                    <label>{Translations.getStatic('emailFrom')}</label>
                                </Grid.Column>
                                <Grid.Column computer={12} tablet={16}>
                                    {emailData.from}
                                </Grid.Column>
                            </Grid.Row>
                            {actionType !== Constants.SCHEDULED_EMAIL_ACTION_TYPE_SENT && (
                                <Grid.Row className="horizontal-row">
                                    <Grid.Column computer={4} tablet={16}>
                                        <label>{Translations.getStatic('scheduledSendAt')}</label>
                                    </Grid.Column>

                                    <Grid.Column computer={12} tablet={16}>
                                        {DateFormatted.getDate(sendAt) +
                                            ' ' +
                                            Translations.getStatic('at') +
                                            ' ' +
                                            DateFormatted.getTime(sendAt)}
                                    </Grid.Column>
                                </Grid.Row>
                            )}
                            <Grid.Row className="horizontal-row">
                                <Grid.Column computer={4} tablet={16}>
                                    <label>{Translations.getStatic('emailRecipients')}</label>
                                </Grid.Column>

                                <ColumnTo />
                            </Grid.Row>
                            {emailData.hasOwnProperty('bcc') === true &&
                                emailData.bcc.length > 0 && (
                                    <Grid.Row className="horizontal-row">
                                        <Grid.Column computer={4} tablet={16}>
                                            <label>{Translations.getStatic('bcc')}</label>
                                        </Grid.Column>
                                        <Grid.Column computer={12} tablet={16}>
                                            {emailData.bcc.join(', ')}
                                        </Grid.Column>
                                    </Grid.Row>
                                )}
                            <Grid.Row className="horizontal-row">
                                <Grid.Column computer={4} tablet={16}>
                                    <label>{Translations.getStatic('emailSubject')}</label>
                                </Grid.Column>
                                <Grid.Column computer={12} tablet={16}>
                                    {sanitizeHtml(subject, {
                                        allowedTags: [],
                                    })}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className="horizontal-row width_auto-children">
                                <Grid.Column width={16}>
                                    <label>{Translations.getStatic('emailContent')}</label>
                                    <div dangerouslySetInnerHTML={{ __html: body }} />
                                </Grid.Column>
                            </Grid.Row>
                            {emailData.hasOwnProperty('attachments') === true &&
                                emailData.attachments.length > 0 && (
                                    <>
                                        <Divider />
                                        <Grid.Row className="horizontal-row">
                                            <Grid.Column className="list attachment-assistant">
                                                <Grid className="data-block data-block_primary">
                                                    <Grid.Row className="padding-bottom_none">
                                                        <Grid.Column className="padding-left_none padding-right_none">
                                                            <label>
                                                                {Translations.getStatic(
                                                                    'attachments'
                                                                )}
                                                            </label>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row className="padding-top_none">
                                                        <Grid.Column className="padding-left_none padding-right_none">
                                                            <div className="ui items">
                                                                {emailData.attachments.map(
                                                                    (attachment, i) => (
                                                                        <DetailEmailAttachmentColumn
                                                                            key={
                                                                                'candidateAttachment' +
                                                                                i
                                                                            }
                                                                            attachment={attachment}
                                                                        />
                                                                    )
                                                                )}
                                                            </div>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </>
                                )}
                        </Grid>
                    </Form>
                }
            />
        );
    }
}

const mapStateToProps = (state) => ({
    isOpenModal: state.modalLogic.isOpen,
    scheduledEmailModel: state.scheduledEmailModel,
    isCancellingScheduledEmail: state.isCancellingScheduledEmail,
});

const mapDispatchToProps = {
    closeModal,
    cancelScheduledEmail,
};

ScheduledEmailDetailModal.propTypes = {
    title: PropTypes.string.isRequired,
    actionType: PropTypes.number.isRequired,
    scheduledEmail: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledEmailDetailModal);
