import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import { closeModal, closeModalSecond } from '../../actions/ModalActions';
import Translations from '../../helpers/Translations';
import Constants from '../../helpers/Constants';
import Form from '../Form';
import Select from '@profesia/adamui/components/common/dropdown/SelectDefault';
import Grid from '../Grid';
import Divider from '../Divider';
import Icon from '../Icon';
import LoadingComponent from '../LoadingComponent';

class SettingsPageTransformStagesModal extends Component {
    state = {
        errors: [],

        pipelineStagesToBeChanged: this.props.pipelineStagesToBeChanged.map((stage) => ({
            fromStage: stage,
            toStageId: Constants.CANDIDATE_STAGE_NEW_ID,
        })),
    };

    closeModal = () => {
        if (this.props.isModalSecond === true) {
            this.props.closeModalSecond();
        } else {
            this.props.closeModal();
        }
    };

    transformStages = () => {
        const changedStages = this.state.pipelineStagesToBeChanged.map((stage) => ({
            fromStageId: stage.fromStage.id,
            toStageId: stage.toStageId,
        }));

        this.props.onTransformStages(changedStages);
    };

    changeToStage = (fromStageId, toStageId) => {
        this.setState({
            pipelineStagesToBeChanged: this.state.pipelineStagesToBeChanged.map((stage) => ({
                ...stage,
                toStageId: stage.fromStage.id === fromStageId ? toStageId : stage.toStageId,
            })),
        });
    };

    render() {
        const {
            isModalSecond,
            isOpen,
            isOpenSecond,
            newPipelineStages,
            isSettingPipelineToOpenJobs,
            isApplyingPipelineTemplateToJob,
            isProcessingPipelineTemplateForJob,
        } = this.props;

        const isPerformingAction =
            isSettingPipelineToOpenJobs === true ||
            isApplyingPipelineTemplateToJob === true ||
            isProcessingPipelineTemplateForJob === true;

        const toStageOptions = newPipelineStages.map((stage) => ({
            key: stage.stageId === null ? stage.tempStageId : stage.stageId,
            id: stage.stageId === null ? stage.tempStageId : stage.stageId,
            label: stage.stageName,
        }));

        return (
            <MarkModal
                isModalSecond={isModalSecond}
                isOpen={isModalSecond ? isOpenSecond : isOpen}
                closeModal={this.closeModal}
                actionButton={{
                    text: Translations.getStatic('save'),
                    disabled: isPerformingAction,
                    loading: isPerformingAction,
                    onClick: this.transformStages,
                }}
                title={Translations.getStatic('selectionProcessSettings')}
                content={
                    isPerformingAction === true ? (
                        <LoadingComponent text={Translations.getStatic('setting')} />
                    ) : (
                        <Form>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <h5
                                            dangerouslySetInnerHTML={{
                                                __html: Translations.getStatic(
                                                    'relocationOfCandidatesDescription'
                                                ),
                                            }}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <Grid verticalAlign="middle">
                                <Grid.Row>
                                    <Grid.Column width={9}>
                                        <h3 className="txt-blue txt-regular">
                                            {Translations.getStatic('candidatesFromStep')}
                                        </h3>
                                    </Grid.Column>

                                    <Grid.Column width={1} />

                                    <Grid.Column width={6}>
                                        <h3 className="txt-blue txt-regular">
                                            {Translations.getStatic('moveToStep')}
                                        </h3>
                                    </Grid.Column>
                                </Grid.Row>

                                <Divider hidden className="size_12" />

                                {this.state.pipelineStagesToBeChanged.map((stage) => (
                                    <Grid.Row key={stage.fromStage.id}>
                                        <Grid.Column width={9}>
                                            <div className="segment stage">
                                                <div className="vertical-align">
                                                    <h5 className="txt-regular txt-medium">
                                                        {stage.fromStage.name}
                                                    </h5>
                                                </div>
                                            </div>
                                        </Grid.Column>

                                        <Grid.Column width={1} textAlign="center">
                                            <Icon className="icon-arrow_right vertical-align" />
                                        </Grid.Column>

                                        <Grid.Column width={6}>
                                            <Select
                                                value={stage.toStageId}
                                                options={toStageOptions}
                                                onChange={(value) =>
                                                    this.changeToStage(stage.fromStage.id, value)
                                                }
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                ))}
                            </Grid>
                        </Form>
                    )
                }
            />
        );
    }
}

SettingsPageTransformStagesModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    pipelineStagesToBeChanged: PropTypes.array.isRequired,
    newPipelineStages: PropTypes.array.isRequired,
    onTransformStages: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    isOpen: state.modalLogic.isOpen,
    isOpenSecond: state.modalSecondLogic.isOpen,
    isSettingPipelineToOpenJobs: state.isSettingPipelineToOpenJobs,
    isApplyingPipelineTemplateToJob: state.isApplyingPipelineTemplateToJob,
    isProcessingPipelineTemplateForJob: state.isProcessingPipelineTemplateForJob,
});

const mapDispatchToProps = {
    closeModal,
    closeModalSecond,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPageTransformStagesModal);
