export const SAVE_CANDIDATES_LIST_COLUMNS_ORDER = 'SAVE_CANDIDATES_LIST_COLUMNS_ORDER';
export const SAVE_CANDIDATES_LIST_COLUMNS_ORDER_SUCCESS =
    'SAVE_CANDIDATES_LIST_COLUMNS_ORDER_SUCCESS';
export const SAVE_CANDIDATES_LIST_COLUMNS_ORDER_FAILURE =
    'SAVE_CANDIDATES_LIST_COLUMNS_ORDER_FAILURE';

export const saveCandidatesListColumnsOrder = (actionData, columns, listType) => ({
    type: SAVE_CANDIDATES_LIST_COLUMNS_ORDER,

    payload: {
        columns,
        listType,
    },

    meta: {
        requestRoute: 'v1/user/action/save-candidates-list-columns-order',
        requestMethod: 'POST',
        requestBody: actionData,
        onRequestSuccess: SAVE_CANDIDATES_LIST_COLUMNS_ORDER_SUCCESS,
        onRequestFailure: SAVE_CANDIDATES_LIST_COLUMNS_ORDER_FAILURE,
    },
});
