import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import Divider from './Divider';
import Url from '../helpers/Url';
import MenuItem from './Menu/MenuItem';
import Translations from '../helpers/Translations';
import User from '../helpers/User';
import Constants from '../helpers/Constants';
import HeaderHelper from '../helpers/components/HeaderHelper';
import Link from '../helpers/overridden/Link';
import Header4MenuEllipsisButton from './Header4MenuEllipsisButton';
import Header4MenuPositionDetailButton from './Header4MenuPositionDetailButton';
import Header4MenuPublishPositionButton from './Header4MenuPublishPositionButton';
import Header4MenuCopyPositionButton from './Header4MenuCopyPositionButton';
import Header4MenuJobStatus from './Header4MenuJobStatus';
import Header4MenuDetailSkeleton from '../skeletons/components/Header4MenuDetailSkeleton';
import Skeletons from '../helpers/Skeletons';
import { previousPage } from '../actions/HeaderActions';
import loginManagerModelSkeleton from '../skeletons/models/loginManagerModelSkeleton';

const Header4MenuNewDetail = (props) => {
    const [indexTabStopRender, setIndexTabStopRender] = useState(null);
    const location = useLocation();
    const dispatch = useDispatch();
    const resizeTimeoutRef = useRef(null);

    const TAB_CHAR_WIDTH = 7;
    const ELLIPSIS_WIDTH = 30;
    const MIN_VISIBLE_TABS = 1;
    const RESIZE_DEBOUNCE_DELAY = 50;

    const getMenuItems = useCallback(() => {
        const menuItems = [
            {
                action: 'detail',
                activeActions: ['job'],
                clickable: true,
                visible: true,
                name: 'dashboard',
            },
            {
                action: 'candidates',
                activeActions: ['job'],
                clickable: true,
                visible: true,
                name: 'filter',
            },
            {
                action: 'candidate-sources',
                activeActions: ['job'],
                clickable: true,
                visible: true,
            },
        ];

        if (props.jobModel.job.hasStatistics === true) {
            menuItems.push({
                action: 'statistics',
                activeActions: ['job'],
                clickable: true,
            });
        }

        return menuItems;
    }, [props.jobModel?.job?.hasStatistics]);

    const findOverflowTabIndex = useCallback(
        (navGrid) => {
            if (!navGrid) {
                return null;
            }

            const menuItemsStrings = HeaderHelper.getMenuItemsStrings();
            const menuItems = getMenuItems();
            const availableWidth = navGrid.offsetWidth - ELLIPSIS_WIDTH;
            let totalWidth = 0;

            for (let i = 0; i < menuItems.length; i++) {
                const { action, name } = menuItems[i];
                const itemText = menuItemsStrings[action] || menuItemsStrings[name] || '';
                const tabWidth = itemText.length * TAB_CHAR_WIDTH;

                totalWidth += tabWidth;

                if (totalWidth > availableWidth) {
                    return Math.max(MIN_VISIBLE_TABS, i);
                }
            }

            return null;
        },
        [getMenuItems]
    );

    useEffect(() => {
        const calculateOverflow = () => {
            const navGrid = document.querySelector('.nav-grid__tabs');
            if (!navGrid) return;

            const newIndex = findOverflowTabIndex(navGrid);
            if (indexTabStopRender !== newIndex) {
                setIndexTabStopRender(newIndex);
            }
        };

        calculateOverflow();

        const handleResize = () => {
            calculateOverflow(); 

            if (resizeTimeoutRef.current) {
                clearTimeout(resizeTimeoutRef.current);
            }

            resizeTimeoutRef.current = setTimeout(() => {
                calculateOverflow();
            }, RESIZE_DEBOUNCE_DELAY);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            if (resizeTimeoutRef.current) {
                clearTimeout(resizeTimeoutRef.current);
            }
        };
    }, [findOverflowTabIndex, indexTabStopRender]); 

    const getReferrer = () => {
        const searchParams = new URLSearchParams(location.search);
        return searchParams.get('referrer');
    };

    useEffect(() => {
        const referrer = getReferrer();
        const offerLink = props.jobModel.job?.offer?.offerLink ?? null;

        if (referrer === 'list_offers') {
            dispatch(previousPage(`/company_details.php?action=list_offers`));
        }

        if (referrer === 'offer' && offerLink !== null) {
            const url = new URL(offerLink);

            dispatch(previousPage(url.pathname));
        }
    }, [props.jobModel.job]);

    const { backButton, loginManagerModel, jobModel, jobDetailHeader, isFetchingJobModel, isFetchingGetPositionData, displaySize } = props;

    const { job, candidates } = jobModel;

    const { addDate, validTill, stateId, id, endDate, isTalentPool, offer } = job;

    const isExternalVisitor = User.isExternalVisitor(loginManagerModel, jobModel);

    const isOfferNotPublished = !offer || (offer.isApproved && !offer.isActive);

    const positionActionButton = isOfferNotPublished ? <Header4MenuPublishPositionButton /> : <Header4MenuPositionDetailButton jobId={id} isLoading={isFetchingGetPositionData || !job?.position} />;

    const ResponsiveTabs = () => {
        const menuItems = getMenuItems();
        const menuItemsStrings = HeaderHelper.getMenuItemsStrings();
        const tabStopIndex = indexTabStopRender || menuItems.length;

        return (
            <>
                {menuItems.slice(0, tabStopIndex).map((item) => (
                    <MenuItem
                        key={'tab-item-' + item.action}
                        action={item.action}
                        item={item}
                        jobId={jobModel.job.id + '?new'}
                        menuItemsStrings={menuItemsStrings}
                    />
                ))}

                {menuItems.length > tabStopIndex ? (
                    <li className="tab__item">
                        <Dropdown
                            icon={{ className: 'ellipsis horizontal txt-white' }}
                            direction="left"
                        >
                            <Dropdown.Menu>
                                {menuItems.slice(tabStopIndex).map((item) => (
                                    <Dropdown.Item key={item.action}>
                                        <Link to={Url.build(item.action, jobModel.job.id === undefined ? {} : { job: jobModel.job.id + '?new' })} className="txt-navy" gtmTag="menu-link">
                                            {menuItemsStrings[item.name || item.action]}
                                        </Link>
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                ) : null}
            </>
        );
    };

    const HeaderTitle = () => {
        let jobTitle;

        if (isFetchingJobModel === true) {
            jobTitle = jobDetailHeader === null ? job.jobTitle : jobDetailHeader.jobTitle;
        } else {
            jobTitle = job.jobTitle;
        }

        return jobTitle === undefined || jobTitle.trim() === '' ? (
            <h3>
                <em>{Translations.getStatic('missingPositionName') + ' (jobId: ' + id + ')'}</em>
            </h3>
        ) : (
            <h3 className="ellipsis">{jobTitle}</h3>
        );
    };

    return isFetchingJobModel === true ? (
        <Header4MenuDetailSkeleton backButton={backButton} />
    ) : (
        <div
            className={
                'template-header header-extended_dark' +
                (User.isSuperAdmin(loginManagerModel) ? ' admin' : '')
            }
        >
            <div className="header__row">
                <div className="col-left">
                    <div className="square-button">
                        {backButton.location.startsWith('mark') ? (
                            <Link to={backButton.location} gtmTag="menu-back-button">
                                {Translations.getStatic('back')}
                            </Link>
                        ) : (
                            <a href={backButton.location} gtmTag="menu-back-button">
                                {Translations.getStatic('back')}
                            </a>
                        )}
                    </div>
                    <div className="nav-grid">
                        <div className="nav-grid__title">
                            {isExternalVisitor === true && <Divider hidden className="size_12" />}
                            <HeaderTitle />
                        </div>

                        {isExternalVisitor === false && (
                            <ul className="nav-grid__tabs">
                                <ResponsiveTabs />
                            </ul>
                        )}
                    </div>
                </div>

                {isTalentPool === true || (
                    <div className="col-right">
                        <Header4MenuJobStatus offer={offer} />

                        {!displaySize.isMobile && isExternalVisitor === false && displaySize.width > Constants.RESOLUTION_MOBILE && positionActionButton}

                        {!displaySize.isMobile && <Header4MenuCopyPositionButton isLoading={isFetchingGetPositionData || !job?.position} />}

                        <Header4MenuEllipsisButton addDate={addDate} validTill={validTill} endDate={endDate} stateId={stateId} jobId={id} isExternalVisitor={isExternalVisitor} candidates={candidates} positionActionButton={positionActionButton} copyPositionButton={<Header4MenuCopyPositionButton isLoading={isFetchingGetPositionData || !job?.position} />} />
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    loginManagerModel: Skeletons.getData(state.loginManagerModel, loginManagerModelSkeleton),
    jobModel: Skeletons.getData(state.jobModel, []),
    jobDetailHeader: state.jobDetailHeader,
    isFetchingJobModel: state.isFetchingJobModel,
    isFetchingGetPositionData: state.isFetchingGetPositionData,
    displaySize: state.displaySize,
    backButton: state.backButton,
});

export default connect(mapStateToProps)(Header4MenuNewDetail);
