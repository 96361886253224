import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import { closeModal, openModalSecond } from '../../actions/ModalActions';
import { processQuestionnaire } from '../../actions/QuestionnairesSettingsActions';
import SettingsQuestionnaireEditor from '../Settings/SettingsQuestionnaireEditor';
import QuestionnaireValidatorHelper from '../../helpers/components/QuestionnaireValidatorHelper';
import SuccessMessage from '../../helpers/SuccessMessage';
import TemplateAssistantHelper from '../../helpers/components/TemplateAssistantHelper';
import Translations from '../../helpers/Translations';

class SettingsPageQuestionnaireEditorModal extends Component {
    componentDidUpdate(prevProps) {
        if (
            SuccessMessage.canShowSuccessMessage(
                prevProps,
                this.props,
                'isProcessingQuestionnaire'
            ) &&
            this.props.questionnairesSettingsModel.hasOwnProperty('isSuccess') === true &&
            this.props.questionnairesSettingsModel.isSuccess === true
        ) {
            this.props.closeModal();
        }
    }

    processQuestionnaire = () => {
        const {
            questionnaireId,
            questionnaireEditorModel,
            questionnairesSettingsModel,
            templateAssistant,
        } = this.props;
        const { questionnaireData } = questionnaireEditorModel;
        const { questionnaires } = questionnairesSettingsModel;

        const templateSavingMode = TemplateAssistantHelper.getTemplateSavingMode(
            templateAssistant.questionnaire
        );

        let checkAgainst =
            templateSavingMode === TemplateAssistantHelper.TEMPLATE_SAVING_MODE_UPDATE
                ? questionnaires.filter(
                      (questionnaire) => parseInt(questionnaire.id) !== parseInt(questionnaireId)
                  )
                : questionnaires;
        checkAgainst = checkAgainst.map((questionnaire) => questionnaire.name);

        const validator = new QuestionnaireValidatorHelper(questionnaireData);
        const errors = validator.checkQuestionnaire(true, true, checkAgainst);

        this.setState({ errors });

        if (errors.length === 0) {
            let formData = new FormData();

            formData.append('questionnaireData', JSON.stringify(questionnaireData));
            formData.append('questionnaireSavingMode', templateSavingMode);

            this.props.processQuestionnaire(
                formData,
                parseInt(questionnaireId),
                templateSavingMode
            );
        }
    };

    render() {
        const {
            isOpen,
            closeModal,
            questionnaireId,
            isCopy,
            questionnaireEditorModel,
            isFetchingQuestionnaireEditorModel,
            isProcessingQuestionnaire,
            modalType,
        } = this.props;

        const isPerformingAction =
            isFetchingQuestionnaireEditorModel === true || isProcessingQuestionnaire === true;

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={closeModal}
                isLoadingData={isFetchingQuestionnaireEditorModel}
                actionButton={{
                    text: Translations.getStatic('saveQuestionnaire'),
                    disabled: isPerformingAction,
                    loading: isPerformingAction,
                    onClick: this.processQuestionnaire,
                }}
                entityOption={{
                    text: Translations.getStatic('showPreview'),
                    visible:
                        questionnaireEditorModel.hasOwnProperty('questionnaireData') === true
                            ? questionnaireEditorModel.questionnaireData.questions.length > 0
                            : false,
                    disabled: isFetchingQuestionnaireEditorModel === true,
                    loading: isFetchingQuestionnaireEditorModel === true,
                    onClick: () =>
                        this.props.openModalSecond('DetailQuestionnaireModal', {
                            questionnaireData: questionnaireEditorModel.questionnaireData,
                        }),
                }}
                title={Translations.getStatic('questionnaireSettings')}
                content={
                    <SettingsQuestionnaireEditor
                        questionnaireId={questionnaireId}
                        isCopy={isCopy}
                        modalType={modalType}
                    />
                }
            />
        );
    }
}

SettingsPageQuestionnaireEditorModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isCopy: PropTypes.bool.isRequired,
    questionnaireId: PropTypes.number.isRequired,
    modalType: PropTypes.string,
};

SettingsPageQuestionnaireEditorModal.defaultProps = {
    questionnaireId: 0,
    isCopy: false,
};

const mapStateToProps = (state) => ({
    isOpen: state.modalLogic.isOpen,
    questionnaireEditorModel: state.questionnaireEditorModel,
    questionnairesSettingsModel: state.questionnairesSettingsModel,
    templateAssistant: state.templateAssistant,
    isFetchingQuestionnaireEditorModel: state.isFetchingQuestionnaireEditorModel,
    isProcessingQuestionnaire: state.isProcessingQuestionnaire,
    modalType: state.modalLogic.modalType,
});

const mapDispatchToProps = {
    closeModal,
    openModalSecond,
    processQuestionnaire,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPageQuestionnaireEditorModal);
