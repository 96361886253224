import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useFormContext } from 'react-hook-form';
import { Stack as StackUI, styled } from '@mui/material';
import { tabletDown } from '@profesia/adamui/styles/theme';
import { CardNotification } from '@profesia/adamui/components/common/card';
import { getConfiguration } from '@profesia/mark-offer-form/configuration';
import { useOptionsContext } from '@profesia/mark-offer-form/services/OptionsContext';
import { usePostForm, useUpdateForm } from '@profesia/mark-offer-form/services/form';
import { useTranslationInPageLanguage } from '@profesia/mark-offer-form/i18n';
import useToastWithTranslation from '@profesia/mark-offer-form/services/useToastWithTranslation';
import { ContainerSize } from '@profesia/mark-offer-form/types';
import JobTitle from '../../JobTitle';
import PositionDescription from '../../PositionDescription';
import PositionPlace from '../../PositionPlace';
import Salary from '../../Salary';
import Skills from '../../Skills';
import SelectionProcedure from '../../SelectionProcessInfo';
import JobSettings from '../../JobSettings';
import ContactDetails from '../../ContactDetails';
import CompanyDetails from '../../CompanyDetails';
import Footer from './Footer';
import { NotificationBox } from './NotificationBox';
import LogoProvider from '@profesia/mark-offer-form/services/LogoContext';
const Content = forwardRef((props, ref) => {
    const { form } = getConfiguration();
    const { trigger, handleSubmit, formState: { isDirty }, getValues, } = useFormContext();
    const { isEditMode, isSingleStep, isExtendedFormVisible } = useOptionsContext();
    const addToast = useToastWithTranslation();
    const t = useTranslationInPageLanguage('createNewJobInfo');
    const { mutate: postForm, isPending: isFormPosting, isError: isFormPostError, } = usePostForm({
        onSuccess: (positionId) => {
            form.onSubmit(positionId);
        },
    });
    const { mutate: updateForm, isError: isFormUpdateError } = useUpdateForm({
        onSuccess: (positionId) => {
            form.onSubmit(positionId);
        },
    });
    const onSubmit = (data) => {
        isEditMode ? updateForm(data) : postForm(data);
    };
    const handleEnterKey = (e) => {
        if (e.key === 'Enter' &&
            e.target instanceof HTMLElement &&
            e.target.tagName !== 'TEXTAREA') {
            e.preventDefault();
        }
    };
    useEffect(() => {
        if (isFormPostError || isFormUpdateError) {
            addToast({ message: 'common.responseError.postForm', variant: 'error' });
        }
    }, [isFormPostError, isFormUpdateError]);
    const prepareValidDataForSave = async () => {
        const isValid = await trigger();
        if (isValid) {
            return getValues();
        }
        return null;
    };
    useImperativeHandle(ref, () => ({
        handleSubmit: () => {
            handleSubmit(onSubmit)();
        },
        preparePositionDataForSave: async () => {
            return await prepareValidDataForSave();
        },
        isDirty,
    }), [isDirty]);
    return (_jsxs(Stack, { isEditMode: isEditMode, component: "form", onSubmit: handleSubmit(onSubmit), onKeyDown: handleEnterKey, children: [isEditMode ? null : (_jsx(NotificationBox, { mb: 3, children: _jsx(CardNotification, { title: t('title'), description: _jsx("span", { dangerouslySetInnerHTML: {
                            __html: t('description'),
                        } }), variant: "info" }) })), _jsx(JobTitle, {}), _jsx(PositionDescription, {}), _jsx(PositionPlace, {}), _jsx(Salary, {}), _jsx(Skills, {}), isExtendedFormVisible ? (_jsxs(_Fragment, { children: [_jsx(SelectionProcedure, {}), _jsx(JobSettings, {}), _jsx(ContactDetails, {}), _jsx(LogoProvider, { base64Logo: null, isEdited: false, children: _jsx(CompanyDetails, {}) })] })) : null, isEditMode ? null : isSingleStep ? _jsx(Footer, { isPending: isFormPosting }) : null] }));
});
const Stack = styled(StackUI, {
    shouldForwardProp: (prop) => prop !== 'isEditMode',
})(({ theme: { spacing }, isEditMode }) => ({
    ...(!isEditMode
        ? {
            width: ContainerSize.formContent,
        }
        : {
            maxWidth: ContainerSize.formContent,
        }),
    paddingTop: isEditMode ? 0 : spacing(3),
    [tabletDown]: {
        width: ['-webkit-fill-available', '-moz-available'],
    },
}));
export default Content;
