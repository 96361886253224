import {
    RECEIVE_DASHBOARD_MODEL,
    REQUEST_DASHBOARD_MODEL,
    GET_DATA_FOR_USER,
    GET_DATA_FOR_USER_FAILURE,
    GET_DATA_FOR_USER_SUCCESS,
    GET_CANDIDATES_DATA_FOR_MODAL,
    GET_CANDIDATES_DATA_FOR_MODAL_FAILURE,
    GET_CANDIDATES_DATA_FOR_MODAL_SUCCESS,
    GET_CANDIDATES_SUCCESS,
} from '../../actions/b2b/DashboardActions';
import {
    COPY_CANDIDATES_SUCCESS,
    MOVE_CANDIDATES_SUCCESS,
    REFUSE_CANDIDATE_AND_SEND_EMAIL_SUCCESS,
} from '../../actions/ActionWithCandidateActions';
import CopyCandidatesReducer from '../../helpers/generalReducers/actionWithCandidatesReducer/CopyCandidatesReducer';
import MoveCandidatesReducer from '../../helpers/generalReducers/actionWithCandidatesReducer/MoveCandidatesReducer';
import { CHANGE_CANDIDATE_STAGE } from '../../actions/JobDetailActions';
import ChangeCandidateStageReducer from '../../helpers/generalReducers/actionWithCandidatesReducer/ChangeCandidateStageReducer';
import RefuseCandidateReducer from '../../helpers/generalReducers/actionWithCandidatesReducer/RefuseCandidateReducer';

export const isFetchingDashboardModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_DASHBOARD_MODEL:
            return true;

        case RECEIVE_DASHBOARD_MODEL:
            return false;

        default:
            return state;
    }
};

export const isFetchingDashboardDataForUser = (state = false, action) => {
    switch (action.type) {
        case GET_DATA_FOR_USER:
            return true;

        case GET_DATA_FOR_USER_SUCCESS:
        case GET_DATA_FOR_USER_FAILURE:
            return false;

        default:
            return state;
    }
};

export const isFetchingDashboardDataForModal = (state = false, action) => {
    switch (action.type) {
        case GET_CANDIDATES_DATA_FOR_MODAL:
            return true;

        case GET_CANDIDATES_DATA_FOR_MODAL_SUCCESS:
        case GET_CANDIDATES_DATA_FOR_MODAL_FAILURE:
            return false;

        default:
            return state;
    }
};

const defaultState = {};

export const dashboardModel = (state = defaultState, action) => {
    switch (action.type) {
        case REQUEST_DASHBOARD_MODEL:
            return defaultState;

        case RECEIVE_DASHBOARD_MODEL:
            return {
                ...state,
                ...action.payload,
                fetchedCandidatesFromJobIds: [],
                candidates: undefined,
            };

        case GET_DATA_FOR_USER_SUCCESS:
            return {
                ...state,
                ...action.payload,
                fetchedCandidatesFromJobIds: [],
                candidates: undefined,
                candidateIdsPairs: undefined,
            };

        case GET_CANDIDATES_DATA_FOR_MODAL:
            return {
                ...state,
                candidates: undefined,
                candidateIdsPairs: undefined,
                changedNewCandidates: undefined,
            };

        case GET_CANDIDATES_DATA_FOR_MODAL_FAILURE:
            return {
                ...state,
                candidateIdsPairs: [],
                candidates: [],
            };

        case GET_CANDIDATES_DATA_FOR_MODAL_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };

        case GET_CANDIDATES_SUCCESS:
            return {
                ...state,
                candidates: state.candidates.concat(action.payload.candidates),
            };

        case COPY_CANDIDATES_SUCCESS:
            return CopyCandidatesReducer.process(state, action);

        case MOVE_CANDIDATES_SUCCESS:
            return MoveCandidatesReducer.process(state, action);

        case CHANGE_CANDIDATE_STAGE:
            return ChangeCandidateStageReducer.process(state, action);

        case REFUSE_CANDIDATE_AND_SEND_EMAIL_SUCCESS:
            return RefuseCandidateReducer.process(state, action);

        default:
            return state;
    }
};
