export const REQUEST_JOB_ORDER_TIERS = 'REQUEST_JOB_ORDER_TIERS';
export const RECIEVE_JOB_ORDER_TIERS = 'RECIEVE_JOB_ORDER_TIERS';
export const REQUEST_JOB_ORDER_ADDITIONAL_SERVICES = 'REQUEST_JOB_ORDER_ADDITIONAL_SERVICES';
export const RECIEVE_JOB_ORDER_ADDITIONAL_SERVICES = 'RECIEVE_JOB_ORDER_ADDITIONAL_SERVICES';
export const SET_JOB_ORDER_SELECTED_TIER = 'SET_JOB_ORDER_SELECTED_TIER';
export const TOGGLE_JOB_ORDER_SELECTED_ADDITIONAL_SERVICE =
    'TOGGLE_JOB_ORDER_SELECTED_ADDITIONAL_SERVICE';
export const CLEAR_JOB_ORDER_SELECTED_ADDITIONAL_SERVICE =
    'CLEAR_JOB_ORDER_SELECTED_ADDITIONAL_SERVICE';
export const UPDATE_JOB_ORDER_FINAL_PRICE = 'UPDATE_JOB_ORDER_FINAL_PRICE';
export const CLEAR_JOB_ORDER_DATA = 'CLEAR_JOB_ORDER_DATA';
export const SET_JOB_ORDER_FORM_VALIDATION = 'SET_JOB_ORDER_FORM_VALIDATION';
export const SET_JOB_ORDER_VALIDATION_DATA = 'SET_JOB_ORDER_VALIDATION_DATA';

export const requestOrderTiers = () => ({
    type: REQUEST_JOB_ORDER_TIERS,
});

export const recieveOrderTiers = (data) => ({
    type: RECIEVE_JOB_ORDER_TIERS,
    payload: data,
});

export const requestAdditionalServices = () => ({
    type: REQUEST_JOB_ORDER_ADDITIONAL_SERVICES,
});

export const recieveAdditionalServices = (data) => ({
    type: RECIEVE_JOB_ORDER_ADDITIONAL_SERVICES,
    payload: data,
});

export const setSelectedTier = (tierId) => ({
    type: SET_JOB_ORDER_SELECTED_TIER,
    payload: tierId,
});

export const toggleSelectedAdditionalService = (data) => ({
    type: TOGGLE_JOB_ORDER_SELECTED_ADDITIONAL_SERVICE,
    payload: data,
});

export const clearSelectedAdditionalServices = () => ({
    type: CLEAR_JOB_ORDER_SELECTED_ADDITIONAL_SERVICE,
});

export const updateFinalPrice = () => ({
    type: UPDATE_JOB_ORDER_FINAL_PRICE,
});

export const clearJobOrderData = () => ({
    type: CLEAR_JOB_ORDER_DATA,
});

export const setJobOrderFormValidation = () => ({ 
    type: SET_JOB_ORDER_FORM_VALIDATION,
});

export const setJobOrderValidationData = (isPzrWellSpecified) => ({ 
    type: SET_JOB_ORDER_VALIDATION_DATA,
    payload: isPzrWellSpecified,
});