import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import Button from '../Button';
import Grid from '../Grid';
import Item from '../Item';
import Divider from '../Divider';
import Heading from '../Heading';
import PropTypes from 'prop-types';
import Translations from '../../helpers/Translations';
import { connect } from 'react-redux';
import Constants from '../../helpers/Constants';

const TemplateAssistantActionButton = (props) => {
    if (
        props.templateData.hasOwnProperty('currentChangeHash') === false ||
        props.templateData.currentChangeHash === props.templateData.changeHash
    ) {
        return null;
    }

    const {
        templateData,
        isChangedTemplate,
        isModal,
        strings,
        loginManagerModel,
        allowedTemplateSavingActions,
    } = props;

    if (templateData.id !== null && isChangedTemplate === true) {
        return (
            <>
                <Grid>
                    <Grid.Row className="grid-row__header">
                        <Grid.Column width={8}>
                            <Heading as="h5">
                                {isModal === true
                                    ? Translations.getStatic('changesInTemplateDescriptionModal')
                                    : Translations.getStatic('changesInTemplateDescription')}
                            </Heading>
                        </Grid.Column>
                        <Grid.Column width={8} textAlign="right">
                            <Item
                                as="a"
                                className="primary-link"
                                onClick={props.onDoNotSaveChangesInTemplate}
                            >
                                {strings.doNotSaveChangesInTemplate}
                            </Item>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Grid className="highlighted border">
                    <Grid.Row>
                        <Grid.Column>
                            <p>
                                <i>
                                    {props.customMessageForTemplateChangesWillSaveAfterAction ===
                                    undefined
                                        ? Translations.getStatic(
                                              'templateChangesWillSaveAfterAction'
                                          )
                                        : props.customMessageForTemplateChangesWillSaveAfterAction}
                                </i>
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider className="hidden medium" />
            </>
        );
    }

    if (templateData.id !== null && templateData.isTemplate === true) {
        return (
            <Grid>
                <Grid.Column width={16}>
                    <Dropdown
                        text={Translations.getStatic('templateOptions')}
                        button
                        icon={{ className: 'hidden' }}
                        className="dropdown-button_secondary"
                    >
                        <Dropdown.Menu>
                            {allowedTemplateSavingActions.includes(
                                Constants.TEMPLATE_ASSISTANT_SAVE_AS_CHANGED_OPTION
                            ) === true &&
                                (templateData.isEditable === true ||
                                    parseInt(loginManagerModel.userId) ===
                                        parseInt(templateData.userId)) && (
                                    <Dropdown.Item
                                        text={strings.saveChangesInTemplate}
                                        onClick={props.onSaveChangesInTemplate}
                                    />
                                )}

                            {allowedTemplateSavingActions.includes(
                                Constants.TEMPLATE_ASSISTANT_SAVE_AS_NEW_OPTION
                            ) === true && (
                                <Dropdown.Item
                                    text={strings.saveAsNewTemplate}
                                    onClick={props.onShowNewTemplateHeader}
                                />
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </Grid.Column>
            </Grid>
        );
    }

    return (
        <Grid>
            <Grid.Column width={16}>
                <Button className="secondary-button" onClick={props.onShowNewTemplateHeader}>
                    {strings.saveAsNewTemplate}
                </Button>
            </Grid.Column>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    loginManagerModel: state.loginManagerModel,
});

const TemplateAssistantActionButtonContainer = connect(
    mapStateToProps,
    {}
)(TemplateAssistantActionButton);

TemplateAssistantActionButtonContainer.propTypes = {
    templateData: PropTypes.shape({
        isPrivate: PropTypes.bool.isRequired,
        isTemplate: PropTypes.bool.isRequired,
        isEditable: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,

    strings: PropTypes.shape({
        saveChangesInTemplate: PropTypes.string.isRequired,
        saveAsNewTemplate: PropTypes.string.isRequired,
        doNotSaveChangesInTemplate: PropTypes.string.isRequired,
    }).isRequired,

    isChangedTemplate: PropTypes.bool.isRequired,
    onDoNotSaveChangesInTemplate: PropTypes.func.isRequired,
    onSaveChangesInTemplate: PropTypes.func.isRequired,
    onShowNewTemplateHeader: PropTypes.func.isRequired,

    customMessageForTemplateChangesWillSaveAfterAction: PropTypes.string,
    allowedTemplateSavingActions: PropTypes.array,
};

TemplateAssistantActionButtonContainer.defaultProps = {
    allowedTemplateSavingActions: Constants.TEMPLATE_ASSISTANT_ALL_SAVING_OPTIONS,
};

export default TemplateAssistantActionButtonContainer;
