import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import CreateCandidate from '../Candidate/CreateCandidate';
import { closeModal } from '../../actions/ModalActions';
import { connect } from 'react-redux';
import { saveCandidate, setCandidateErrors } from '../../actions/CreateCandidateActions';
import CandidateValidatorHelper from '../../helpers/components/CandidateValidatorHelper';
import AttachmentAssistantHelper from '../../helpers/components/AttachmentAssistantHelper';
import Translations from '../../helpers/Translations';
import SuccessActionStatus from '../../helpers/SuccessActionStatus';

class CreateCandidateModal extends Component {
    saveCandidate = (jobId, candidateId) => {
        const { candidateData } = this.props.createCandidateModel;
        const candidateValidatorHelper = new CandidateValidatorHelper(candidateData);
        const errors = candidateValidatorHelper.checkCandidate();

        let correctAttachmentsTotalSize = true;
        let bulkUploadAttachments = [];

        if (parseInt(candidateId) === 0) {
            const { settings } = this.props.attachmentAssistantModel;
            const attachmentAssistantHelper = new AttachmentAssistantHelper();

            bulkUploadAttachments = this.props.attachmentAssistantModel.bulkUploadAttachments;
            correctAttachmentsTotalSize = attachmentAssistantHelper.checkTotalFilesSize(
                settings.totalFileSizeUploadLimit,
                bulkUploadAttachments.map((attachment) => attachment.file)
            );
        }

        if (errors.length > 0) {
            this.props.setCandidateErrors(errors);
        } else if (correctAttachmentsTotalSize === true) {
            this.props.saveCandidate(
                jobId,
                candidateId,
                this.prepareCandidateDataForSave(candidateData, bulkUploadAttachments)
            );
        }
    };

    componentDidUpdate(prevProps) {
        if (SuccessActionStatus.isActionSuccessful(prevProps, this.props)) {
            this.props.closeModal();
        }
    }

    prepareCandidateDataForSave = (candidateData, attachments) => {
        let formData = new FormData();

        formData.append('candidateData', JSON.stringify(candidateData));

        attachments.forEach((attachment) => {
            formData.append(attachment.typeId + '[]', attachment.file);
        });

        return formData;
    };

    render() {
        const { isOpen, isFetchingCreateCandidateModel, isSavingCandidate } = this.props;

        const jobId = parseInt(this.props.jobId);
        const candidateId =
            this.props.hasOwnProperty('candidateId') === true
                ? parseInt(this.props.candidateId)
                : 0;

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={this.props.closeModal}
                isLoadingData={isFetchingCreateCandidateModel}
                actionButton={{
                    text:
                        candidateId > 0
                            ? Translations.getStatic('save')
                            : Translations.getStatic('addCandidate'),

                    disabled: isFetchingCreateCandidateModel === true || isSavingCandidate === true,
                    onClick: () => this.saveCandidate(jobId, candidateId),
                }}
                title={
                    candidateId > 0
                        ? Translations.getStatic('changeCandidateData')
                        : Translations.getStatic('addNewCandidate')
                }
                content={<CreateCandidate jobId={jobId} candidateId={candidateId} />}
            />
        );
    }
}

CreateCandidateModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    jobId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
    isOpen: state.modalLogic.isOpen,
    createCandidateModel: state.createCandidateModel,
    attachmentAssistantModel: AttachmentAssistantHelper.getActiveModelFromAssistantAttachmentModel(
        state.attachmentAssistantModel,
        AttachmentAssistantHelper.createAttachmentAssistantHashIdForForm()
    ),
    isFetchingCreateCandidateModel: state.isFetchingCreateCandidateModel,
    isSavingCandidate: state.isSavingCandidate,
    actionSuccessStatus: state.actionSuccessStatus,
});

const mapDispatchToProps = {
    closeModal,
    saveCandidate,
    setCandidateErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCandidateModal);
