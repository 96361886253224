import React, { Component } from 'react';
import { connect } from 'react-redux';
import Image from './Image';
import inviteColleagueIcon from '../../img/icons/invite-colleague.svg';
import UserRights from '../helpers/UserRights';
import User from '../helpers/User';
import { openModal } from '../actions/ModalActions';
import { openNotAllowedFeatureDialog } from '../actions/DialogActions';
import PropTypes from 'prop-types';
import Translations from '../helpers/Translations';
import Constants from '../helpers/Constants';
import Track from '../helpers/Track';
import {
    TRACK_ACTION_FEATURE_NOT_ALLOWED,
    TRACK_LOCATION_HEADER,
    TRACK_NAME_INVITE_A_COLLEAGUE,
} from '../helpers/ConstantsTrack';

class Header4MenuAddColleagueExtendedButton extends Component {
    render() {
        const { loginManagerModel, jobModel, jobId, displaySize } = this.props;

        const hasAllowedColleagueInvitationIntoJob =
            UserRights.hasAllowedColleagueInvitationIntoJob(loginManagerModel.activePackage) ===
                true && User.isExternalVisitor(loginManagerModel, jobModel) === false;

        const hasActiveFreeSubscription =
            UserRights.hasActiveFreeSubscription(loginManagerModel) === true;

        const onClickFunction =
            hasAllowedColleagueInvitationIntoJob === true
                ? () => {
                      Track.event(TRACK_LOCATION_HEADER, TRACK_NAME_INVITE_A_COLLEAGUE);
                      this.props.openModal('InviteColleagueModal', { jobId });
                  }
                : () => {
                      Track.event(
                          TRACK_LOCATION_HEADER,
                          TRACK_NAME_INVITE_A_COLLEAGUE,
                          TRACK_ACTION_FEATURE_NOT_ALLOWED
                      );
                      this.props.openNotAllowedFeatureDialog({
                          title: Translations.getStatic('colleagueInvitationIntoJob'),
                          package: UserRights.getPackageWithAllowedColleagueInvitationIntoJob(),
                      });
                  };

        return (
            <>
                {displaySize.width <= Constants.RESOLUTION_MOBILE ? (
                    <span onClick={onClickFunction} className="txt-navy">
                        {Translations.getStatic('inviteColleagueButton')}
                    </span>
                ) : !hasActiveFreeSubscription ? (
                    <div onClick={onClickFunction} className="extended-action">
                        <div className="extended-action__icon-status">
                            <Image size="tiny" src={inviteColleagueIcon} />
                        </div>
                        {displaySize.isMobile === false && (
                            <div className="invite-colleague-button">
                                {Translations.getStatic('inviteColleagueButton')}
                            </div>
                        )}
                    </div>
                ) : null}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    loginManagerModel: state.loginManagerModel,
    jobModel: state.jobModel,
    displaySize: state.displaySize,
});

const mapDispatchToProps = {
    openModal,
    openNotAllowedFeatureDialog,
};

Header4MenuAddColleagueExtendedButton.propTypes = {
    loginManagerModel: PropTypes.object.isRequired,
    jobModel: PropTypes.object.isRequired,
    jobId: PropTypes.number.isRequired,
    displaySize: PropTypes.shape({
        width: PropTypes.number.isRequired,
        isMobile: PropTypes.bool.isRequired,
    }).isRequired,
    openModal: PropTypes.func.isRequired,
    openNotAllowedFeatureDialog: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header4MenuAddColleagueExtendedButton);
