import React from 'react';
import Translations from '../../helpers/Translations';
import MarkDialog from '../Modal/MarkDialog';
import { connect } from 'react-redux';
import { closeNoOrderAccessDialog } from '../../actions/DialogActions';

const NoOrderAccessDialog = (props) => {
    return (
        props.noOrderAccessDialogOpen === true && (
            <MarkDialog
                closeOnDimmerClick={false}
                message={{
                    iconName: 'icon-permission',
                    title: Translations.getStatic('noAccess'),
                    infoLines: [Translations.getStatic('noAccessBody')],
                }}
                actions={[
                    {
                        title: Translations.getStatic('cancel'),
                        action: () => props.closeNoOrderAccessDialog(),
                    },
                ]}
            />
        )
    );
};

const mapStateToProps = (state) => ({
    noOrderAccessDialogOpen: state.noOrderAccessDialogOpen,
});

const mapDispatchToProps = {
    closeNoOrderAccessDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(NoOrderAccessDialog);
