import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack, styled } from '@mui/material';
import { generateKey } from '@profesia/adamui/utils/input';
import { useMobile } from '@profesia/adamui/utils/responsive';
import { CandidateVariant } from '@profesia/adamui/components/common/card/types';
import { TypographySpan } from '@profesia/adamui/components/common/typography';
import HighlightSpan from '@profesia/adamui/components/common/HighlightSpan';
const Experience = ({ jobTitle, dateRange, companyName, variant }) => {
    const isMobile = useMobile();
    const props = isMobile
        ? { mb: 1, direction: 'column' }
        : { gap: 0.5, direction: 'row' };
    const color = variant === CandidateVariant.unavailableCandidate ? 'neutralLight5' : 'primaryNavy';
    return (_jsx(StyledExperience, { children: _jsxs(Stack, { ...props, children: [_jsx(HighlightSpan, { fontWeight: 700, color: color, shouldNotChangeFontWeight: true, text: jobTitle }), _jsx(TypographySpan, { color: color, children: `(${dateRange})` }), _jsx(TypographySpan, { color: color, children: !isMobile ? _jsx(_Fragment, { children: "\u2022" }) : _jsx(_Fragment, {}) }), _jsx(HighlightSpan, { color: color, text: companyName })] }) }));
};
const WorkExperience = ({ workExperiences, variant, title, noExperienceText, }) => {
    const isAvailable = variant === CandidateVariant.availableCandidate;
    return (_jsxs(Box, { display: "flex", flexDirection: "column", children: [title ? (_jsx(HighlightSpan, { color: isAvailable ? 'neutralDark2' : 'neutralLight5', fontStyle: "italic", weight: "bold", text: title, shouldNotChangeFontWeight: true })) : null, workExperiences?.length ? (workExperiences.map((experience, index) => (_jsx(Experience, { variant: variant, ...experience }, generateKey(index, experience.jobTitle))))) : noExperienceText ? (_jsx(TypographySpan, { color: isAvailable ? 'primaryNavy' : 'neutralLight5', weight: "bold", children: noExperienceText })) : null] }));
};
const StyledExperience = styled(Box)(({ theme: { spacing } }) => ({
    display: '-webkit-box',
    webkitLineClamp: 1,
    webkitBoxOrient: 'vertical',
    overflow: 'hidden',
    '&:not(:last-child)': {
        marginBottom: spacing(0.5),
    },
}));
export default WorkExperience;
