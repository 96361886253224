import React from 'react';
import { SkeletonCircular, SkeletonRectangular } from '@profesia/adamui/components/common/skeleton';
import { Stack } from '@mui/material';

const ResponsiveJobCandidatesListFilterSkeleton = () => (
    <div className="filter">
        <div className="filter__header">
            <Stack mt={1} mb={6}>
                <SkeletonRectangular width={80} height={16} borderRadius={26} />
            </Stack>
        </div>

        <div className="filter__items skeleton">
            <Stack spacing={6}>
                <Stack spacing={1.5}>
                    <Stack spacing={0.5}>
                        <SkeletonRectangular width={64} height={10} borderRadius={26} />
                        <SkeletonRectangular width={288} height={32} borderRadius={4} />
                    </Stack>

                    <Stack spacing={1}>
                        {[...Array(2)].map((e, i) => (
                            <Stack spacing={1.5} direction="row" alignItems="center" key={i}>
                                <SkeletonCircular size={18} />
                                <SkeletonRectangular width={187} height={10} borderRadius={26} />
                            </Stack>
                        ))}
                    </Stack>
                </Stack>

                {[...Array(2)].map((e, i) => (
                    <Stack spacing={1.5} key={i}>
                        <Stack spacing={0.5}>
                            <SkeletonRectangular width={64} height={10} borderRadius={26} />
                            <SkeletonRectangular width={288} height={32} borderRadius={4} />
                        </Stack>

                        <Stack spacing={1.5} direction="row" alignItems="center">
                            <SkeletonCircular size={18} />
                            <SkeletonRectangular width={187} height={10} borderRadius={26} />
                        </Stack>
                    </Stack>
                ))}

                <Stack spacing={0.5}>
                    <SkeletonRectangular width={64} height={10} borderRadius={26} />
                    <SkeletonRectangular width={288} height={32} borderRadius={4} />
                </Stack>

                <Stack spacing={1}>
                    <Stack spacing={1} direction="row">
                        <Stack spacing={0.5}>
                            <SkeletonRectangular width={64} height={10} borderRadius={26} />
                            <SkeletonRectangular width={152} height={32} borderRadius={4} />
                        </Stack>

                        <Stack spacing={0.5}>
                            <SkeletonRectangular width={64} height={10} borderRadius={26} />
                            <SkeletonRectangular width={128} height={32} borderRadius={4} />
                        </Stack>
                    </Stack>

                    <SkeletonCircular size={18} />
                </Stack>

                {[...Array(2)].map((e, i) => (
                    <Stack spacing={0.5} key={i}>
                        <SkeletonRectangular width={64} height={10} borderRadius={26} />
                        <SkeletonRectangular width={288} height={32} borderRadius={4} />
                    </Stack>
                ))}
            </Stack>
        </div>
    </div>
);

export default ResponsiveJobCandidatesListFilterSkeleton;
