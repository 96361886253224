export const REQUEST_LOGIN_MANAGER_MODEL = 'REQUEST_LOGIN_MANAGER_MODEL';
export const RECEIVE_LOGIN_MANAGER_MODEL = 'RECEIVE_LOGIN_MANAGER_MODEL';
export const REQUEST_LOGIN_MANAGER_MODEL_BASIC = 'REQUEST_LOGIN_MANAGER_MODEL_BASIC';
export const RECEIVE_LOGIN_MANAGER_MODEL_BASIC = 'RECEIVE_LOGIN_MANAGER_MODEL_BASIC';
export const RESET_LOGIN_MANAGER_MODEL_BASIC = 'RESET_LOGIN_MANAGER_MODEL_BASIC';
export const CHECK_LOGIN = 'CHECK_LOGIN';
export const CHECK_LOGIN_SUCCESS = 'CHECK_LOGIN_SUCCESS';
export const CHECK_LOGIN_FAILURE = 'CHECK_LOGIN_FAILURE';
export const CHECK_CANDIDATE_FROM_EMAIL = 'CHECK_CANDIDATE_FROM_EMAIL';
export const CHECK_CANDIDATE_FROM_EMAIL_SUCCESS = 'CHECK_CANDIDATE_FROM_EMAIL_SUCCESS';
export const CHECK_CANDIDATE_FROM_EMAIL_FAILURE = 'CHECK_CANDIDATE_FROM_EMAIL_FAILURE';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_COMPANY_DATA = 'SET_COMPANY_DATA';

export const checkLogin = (userId, authLogin, adminId, email, externalUserAuthLogin) => ({
    type: CHECK_LOGIN,

    meta: {
        requestRoute: 'v1/check-login',
        requestMethod: 'POST',

        requestData: {
            userId,
            authLogin,
            adminId,
            email,
            externalUserAuthLogin,
        },

        onRequestSuccess: CHECK_LOGIN_SUCCESS,
        onRequestFailure: CHECK_LOGIN_FAILURE,
    },
});

export const requestLoginManagerModel = () => ({
    type: REQUEST_LOGIN_MANAGER_MODEL,

    meta: {
        requestRoute: 'v1/login/model',
        onRequestSuccess: RECEIVE_LOGIN_MANAGER_MODEL,
    },
});

export const requestLoginManagerModelBasic = () => ({
    type: REQUEST_LOGIN_MANAGER_MODEL_BASIC,

    meta: {
        requestRoute: 'v1/login/model',
        onRequestSuccess: RECEIVE_LOGIN_MANAGER_MODEL_BASIC,
    },
});

export const resetLoginManagerModelBasic = () => ({
    type: RESET_LOGIN_MANAGER_MODEL_BASIC,
});

export const checkCandidateFromEmail = (formData) => ({
    type: CHECK_CANDIDATE_FROM_EMAIL,

    meta: {
        requestRoute: 'v1/check-candidate-from-email',
        requestMethod: 'POST',
        requestBody: formData,
        onRequestSuccess: CHECK_CANDIDATE_FROM_EMAIL_SUCCESS,
        onRequestFailure: CHECK_CANDIDATE_FROM_EMAIL_FAILURE,
    },
});

export const setUserData = (data) => ({
    type: SET_USER_DATA,
    payload: data,
});

export const setCompanyData = (data) => ({
    type: SET_COMPANY_DATA,
    payload: data,
});
