import React from 'react';
import Form from '../Form';
import Grid from '../Grid';
import Divider from '../Divider';
import Heading from '../Heading';
import PropTypes from 'prop-types';
import TemplateDropdownSelector from '../TemplateAssistant/TemplateDropdownSelector';
import QuestionnaireEditor from '../Questionnaire/Editor/QuestionnaireEditor';
import TemplateAssistantSettings from '../TemplateAssistant/TemplateAssistantSettings';
import NotificationMessage from '../NotificationMessage';
import { connect } from 'react-redux';
import {
    requestQuestionnaireEditorModel,
    updateQuestionnaire,
    addQuestionItem,
    deleteQuestionItem,
    updateQuestionItem,
    addAnswerItem,
    deleteAnswerItem,
    updateAnswerItem,
    reorderQuestionItem,
} from '../../actions/QuestionnaireEditorActions';
import Translations from '../../helpers/Translations';
import Constants from '../../helpers/Constants';
import QuestionnaireEditorProfesiaReplysheetQuestion from '../Questionnaire/Editor/QuestionnaireEditorProfesiaReplysheetQuestion';
import FormSkeleton from '../../skeletons/components/FormSkeleton';

class JobQuestionnaireEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedQuestionnaireTemplate:
                props.questionnaireEditorModel.hasOwnProperty('questionnaireData') === true &&
                props.questionnaireEditorModel.questionnaireData.id !== null
                    ? props.questionnaireEditorModel.questionnaireData.id
                    : props.questionnaireId,
        };

        this.selectQuestionnaireTemplate = this.selectQuestionnaireTemplate.bind(this);
        this.disableQuestionnaire = this.disableQuestionnaire.bind(this);
    }

    componentDidMount() {
        let requestQuestionnaireEditorModel = false;

        if (this.props.questionnaireEditorModel.hasOwnProperty('questionnaireData') === false) {
            requestQuestionnaireEditorModel = true;
        } else {
            const id =
                this.props.questionnaireEditorModel.questionnaireData.id === null
                    ? 0
                    : parseInt(this.props.questionnaireEditorModel.questionnaireData.id);
            if (id !== parseInt(this.state.selectedQuestionnaireTemplate)) {
                requestQuestionnaireEditorModel = true;
            }
        }

        if (requestQuestionnaireEditorModel === true) {
            this.props.requestQuestionnaireEditorModel(this.state.selectedQuestionnaireTemplate);
        }
    }

    selectQuestionnaireTemplate(questionnaireId) {
        if (parseInt(questionnaireId) !== parseInt(this.state.selectedQuestionnaireTemplate)) {
            this.setState({
                selectedQuestionnaireTemplate: questionnaireId,
            });

            this.props.requestQuestionnaireEditorModel(questionnaireId);
            this.props.onSaveAsChangedTemplate(false);
            this.props.onSaveAsNewTemplate(false);
        }
    }

    disableQuestionnaire() {
        this.props.requestQuestionnaireEditorModel(0);
        this.props.onDisableQuestionnaire();
    }

    render() {
        const {
            profesiaReplysheet,
            profesiaReplysheetData,
            isFetchingQuestionnaireEditorModel,
            modalType,
            questionnaireEditorModel,
            isModal,
            isChangedTemplate,
            isNewTemplate,
            updateQuestionnaire,
            onSaveAsChangedTemplate,
            onSaveAsNewTemplate,
            onStartFetchingTemplates,
            onDisableQuestionnaire,
        } = this.props;

        if (isFetchingQuestionnaireEditorModel === true) {
            return <FormSkeleton />;
        }

        let questionnaireEditorContent = null;
        let hasChanges = false;
        let profesiaQuestionIndex = 0;

        const questionnaireData = questionnaireEditorModel.questionnaireData || null;

        if (questionnaireEditorModel.hasOwnProperty('questionnaireData') === true) {
            const settings = (
                <TemplateAssistantSettings
                    isModal={isModal}
                    templateData={questionnaireEditorModel.questionnaireData}
                    strings={{
                        saveChangesInTemplate: Translations.getStatic('saveChangesInQuestionnaire'),
                        saveAsNewTemplate: Translations.getStatic('saveAsNewQuestionnaire'),
                        doNotSaveChangesInTemplate: Translations.getStatic(
                            'doNotSaveChangesInQuestionnaire'
                        ),
                    }}
                    isChangedTemplate={isChangedTemplate}
                    isNewTemplate={isNewTemplate}
                    onUpdateTemplate={updateQuestionnaire}
                    onSaveAsChangedTemplate={onSaveAsChangedTemplate}
                    onSaveAsNewTemplate={onSaveAsNewTemplate}
                    templateType={Constants.TEMPLATE_TYPE_QUESTIONNAIRE}
                />
            );

            hasChanges =
                questionnaireEditorModel.questionnaireData.hasOwnProperty('currentChangeHash') ===
                    true &&
                questionnaireEditorModel.questionnaireData.currentChangeHash !==
                    questionnaireEditorModel.questionnaireData.changeHash;

            questionnaireEditorContent = (
                <>
                    {hasChanges === true && (
                        <div className={'template-assistant-settings'}>{settings}</div>
                    )}

                    {modalType && <Divider className="column" />}

                    {questionnaireData !== null && questionnaireData.questions.length > 0 && (
                        <Heading
                            as="h3"
                            className={!modalType ? 'padding-left_none template-assistant' : ''}
                        >
                            {Translations.getStatic('questionnaireQuestions')}
                        </Heading>
                    )}

                    {profesiaReplysheetData !== null &&
                        profesiaReplysheetData.length > 0 &&
                        profesiaReplysheetData
                            .filter((section) => section.hasOwnProperty('questions') === true)
                            .map((section) =>
                                section.questions.map((question) => (
                                    <QuestionnaireEditorProfesiaReplysheetQuestion
                                        key={'P' + ++profesiaQuestionIndex}
                                        index={'P' + profesiaQuestionIndex}
                                        question={{
                                            question: [question.name, section.section]
                                                .filter((question) => question.length !== 0)
                                                .join(' - '),
                                            answers: question.options,
                                        }}
                                    />
                                ))
                            )}

                    <QuestionnaireEditor
                        questionnaireEditorModel={questionnaireEditorModel}
                        onAddQuestionItem={this.props.addQuestionItem}
                        onDeleteQuestionItem={this.props.deleteQuestionItem}
                        onAddAnswerItem={this.props.addAnswerItem}
                        onDeleteAnswerItem={this.props.deleteAnswerItem}
                        onUpdateAnswerItem={this.props.updateAnswerItem}
                        onUpdateQuestionItem={this.props.updateQuestionItem}
                        onReorderQuestionItem={this.props.reorderQuestionItem}
                        modalType={modalType}
                    />
                </>
            );
        }

        return (
            <>
                {profesiaReplysheet === true && (
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <NotificationMessage
                                    title={Translations.getStatic(
                                        'questionnaireContainsProfesiaReplysheetQuestions'
                                    )}
                                    description={Translations.getStatic(
                                        'profesiaReplysheetCanNotBeChanged'
                                    )}
                                />
                            </Grid.Column>
                        </Grid.Row>

                        <Divider hidden className="small" />
                    </Grid>
                )}

                <Heading
                    as="h3"
                    className={!modalType ? 'padding-left_none padding-right_none' : ''}
                >
                    {Translations.getStatic('questionnaire')}

                    {(isModal === undefined || isModal === false) &&
                        onDisableQuestionnaire !== null && (
                            <div
                                role="link"
                                className="primary-link float-right"
                                onClick={this.disableQuestionnaire}
                            >
                                {Translations.getStatic('removeQuestionnaire')}
                            </div>
                        )}
                </Heading>

                <TemplateDropdownSelector
                    onStartFetchingData={onStartFetchingTemplates}
                    onSelectedValue={this.selectQuestionnaireTemplate}
                    emptyItem={null}
                    changeSelectedItem={hasChanges}
                    defaultValue={this.state.selectedQuestionnaireTemplate}
                    templatesDropdownLabel={Translations.getStatic('questionnaireTemplate')}
                    templateType={Constants.TEMPLATE_TYPE_QUESTIONNAIRE}
                />

                {!hasChanges && !modalType && this.state.selectedQuestionnaireTemplate == 0 && (
                    <Divider hidden className="size_16 multiple" />
                )}

                <Form>{questionnaireEditorContent}</Form>

                {!modalType && <Divider hidden className="small" />}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isFetchingQuestionnaireEditorModel: state.isFetchingQuestionnaireEditorModel,
    questionnaireEditorModel: state.questionnaireEditorModel,
    modalType: state.modalLogic.modalType,
});

const mapDispatchToProps = {
    requestQuestionnaireEditorModel,
    updateQuestionnaire,
    addQuestionItem,
    deleteQuestionItem,
    updateQuestionItem,
    addAnswerItem,
    deleteAnswerItem,
    updateAnswerItem,
    reorderQuestionItem,
};

const JobQuestionnaireEditorContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(JobQuestionnaireEditor);

JobQuestionnaireEditorContainer.propTypes = {
    questionnaireId: PropTypes.number.isRequired,
    profesiaReplysheet: PropTypes.bool.isRequired,
    profesiaReplysheetData: PropTypes.array,
    isNewTemplate: PropTypes.bool.isRequired,
    isChangedTemplate: PropTypes.bool.isRequired,
    onSaveAsNewTemplate: PropTypes.func.isRequired,
    onSaveAsChangedTemplate: PropTypes.func.isRequired,
    onDisableQuestionnaire: PropTypes.func,
    onStartFetchingTemplates: PropTypes.func.isRequired,
    modalType: PropTypes.string,
};

JobQuestionnaireEditorContainer.defaultProps = {
    profesiaReplysheet: false,
    profesiaReplysheetData: [],
};

export default JobQuestionnaireEditorContainer;
