import {
    COPY_CANDIDATES,
    COPY_CANDIDATES_INTO_TALENT_POOL_SUCCESS,
    COPY_CANDIDATES_SUCCESS,
    MOVE_CANDIDATES,
    MOVE_CANDIDATES_SUCCESS,
} from '../actions/ActionWithCandidateActions';
import { SAVE_CANDIDATE, SAVE_CANDIDATE_SUCCESS } from '../actions/CreateCandidateActions';
import {
    SET_USER_DEFAULT_JOB_VIEW,
    SET_USER_DEFAULT_JOB_VIEW_SUCCESS,
    SET_USER_LANGUAGE,
    SET_USER_DEFAULT_PUBLIC_NOTES,
    SET_USER_DEFAULT_PUBLIC_NOTES_SUCCESS,
} from '../actions/SettingsActions';
import {
    ACTIVATE_JOB,
    ACTIVATE_JOB_SUCCESS,
    CLOSE_JOB,
    CLOSE_JOB_SUCCESS,
} from '../actions/JobsListActions';
import {
    UPDATE_AUTOANSWER_DATA,
    UPDATE_AUTOANSWER_DATA_SUCCESS,
    UPDATE_JOB,
    UPDATE_JOB_SUCCESS,
    UPDATE_QUESTIONNAIRE_DATA,
    UPDATE_QUESTIONNAIRE_DATA_SUCCESS,
    CLOSE_JOB_DETAIL,
    CLOSE_JOB_SUCCESS_DETAIL,
    ACTIVATE_JOB_DETAIL,
    ACTIVATE_JOB_SUCCESS_DETAIL,
} from '../actions/JobDetailActions';
import {
    CREATE_JOB,
    CREATE_JOB_SUCCESS,
    UPDATE_POSITION_DATA,
    UPDATE_POSITION_SUCCESS,
} from '../actions/CreateJobActions';

const defaultState = false;

export const actionSuccessStatus = (state = defaultState, action) => {
    switch (action.type) {
        case COPY_CANDIDATES:
        case MOVE_CANDIDATES:
        case SAVE_CANDIDATE:
        case UPDATE_AUTOANSWER_DATA:
        case UPDATE_JOB:
        case UPDATE_POSITION_DATA:
        case UPDATE_QUESTIONNAIRE_DATA:
        case SET_USER_LANGUAGE:
        case CLOSE_JOB:
        case CLOSE_JOB_DETAIL:
        case ACTIVATE_JOB:
        case ACTIVATE_JOB_DETAIL:
        case CREATE_JOB:
        case SET_USER_DEFAULT_JOB_VIEW:
        case SET_USER_DEFAULT_PUBLIC_NOTES:
            return defaultState;

        case COPY_CANDIDATES_SUCCESS:
        case MOVE_CANDIDATES_SUCCESS:
        case COPY_CANDIDATES_INTO_TALENT_POOL_SUCCESS:
        case SAVE_CANDIDATE_SUCCESS:
        case UPDATE_AUTOANSWER_DATA_SUCCESS:
        case UPDATE_JOB_SUCCESS:
        case UPDATE_POSITION_SUCCESS:
        case UPDATE_QUESTIONNAIRE_DATA_SUCCESS:
        case CLOSE_JOB_SUCCESS:
        case CLOSE_JOB_SUCCESS_DETAIL:
        case ACTIVATE_JOB_SUCCESS:
        case ACTIVATE_JOB_SUCCESS_DETAIL:
        case CREATE_JOB_SUCCESS:
        case SET_USER_DEFAULT_JOB_VIEW_SUCCESS:
        case SET_USER_DEFAULT_PUBLIC_NOTES_SUCCESS:
            return true;

        default:
            return state;
    }
};
