import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from './Button';
import Container from './Container';
import Image from './Image';
import Divider from './Divider';
import Heading from './Heading';
import Icon from './Icon';
import Url from './../helpers/Url';
import noResultsImage from '../../img/icons/noResults.svg';
import jobsImage from '../../img/icons/jobs.svg';
import noJobsImage from '../../img/icons/noJobs.svg';
import { openNotAllowedFeatureDialog } from '../actions/DialogActions';
import UserRights from '../helpers/UserRights';
import Link from '../helpers/overridden/Link';

const NoResaultImage = ({ isDashboard, imageName }) => {
    if (isDashboard === true) {
        return <Image className="auto" src={noJobsImage} />;
    } else {
        return <Image className="auto" src={imageName === 'jobs' ? jobsImage : noResultsImage} />;
    }
};

const NoResults = (props) => {
    const {
        title,
        subtitle,
        button,
        link,
        icon,
        imageName,
        loginManagerModel,
        displaySize,
        isDashboard,
        anchor,
    } = props;

    return (
        <Container
            fluid
            className={
                'height_full flex align center' +
                (displaySize.isMobile ? ' no-results_minHeight' : '')
            }
        >
            <Heading as="h3" icon textAlign="center">
                {icon && <NoResaultImage isDashboard={isDashboard} imageName={imageName} />}

                {title}

                {subtitle && (
                    <>
                        <Divider className="hidden size_4" />
                        <Heading.Subheader>
                            <span>{subtitle} </span>
                            {link && (
                                <Link
                                    to={Url.build(link.action)}
                                    className="primary-link"
                                    gtmTag={'no-results-' + link.label}
                                >
                                    {link.label}
                                </Link>
                            )}
                        </Heading.Subheader>
                    </>
                )}

                {button && (
                    <>
                        <Divider className="hidden size_4" />
                        <Divider className="hidden size_16" />

                        {UserRights.hasActiveSomeSubscription(loginManagerModel) === true ? (
                            <Link
                                to={Url.build(button.action)}
                                className="primary-button"
                                gtmTag={'no-results-' + button.label}
                            >
                                {button.label}
                            </Link>
                        ) : (
                            <Button
                                className="primary-button icon"
                                onClick={() =>
                                    props.openNotAllowedFeatureDialog({ title: button.label })
                                }
                            >
                                {button.label}
                                <Icon className="icon-upsell-star-invert-navy" size="small" />
                            </Button>
                        )}
                    </>
                )}

                {anchor && (
                    <>
                        <Divider className="hidden size_4" />
                        <Divider className="hidden size_16" />

                        {UserRights.hasActiveSomeSubscription(loginManagerModel) === true ? (
                            <a href={anchor.url} className="primary-button">
                                {anchor.label}
                            </a>
                        ) : (
                            <Button
                                className="primary-button icon"
                                onClick={() =>
                                    props.openNotAllowedFeatureDialog({ title: anchor.label })
                                }
                            >
                                {anchor.label}
                                <Icon className="icon-upsell-star-invert-navy" size="small" />
                            </Button>
                        )}
                    </>
                )}
            </Heading>
        </Container>
    );
};

NoResults.propTypes = {
    icon: PropTypes.bool.isRequired,
    imageName: PropTypes.string,
    isDashboard: PropTypes.bool.isRequired,

    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

    button: PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
        action: PropTypes.string.isRequired,
    }),

    anchor: PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
        url: PropTypes.string.isRequired,
    }),
};

NoResults.defaultProps = {
    icon: true,
    isDashboard: false,
    imageName: noResultsImage,
    title: '',
    subtitle: '',
};

const mapStateToProps = (state) => ({
    loginManagerModel: state.loginManagerModel,
    displaySize: state.displaySize,
});

const mapDispatchToProps = {
    openNotAllowedFeatureDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(NoResults);
