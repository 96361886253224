import React, { Component } from 'react';
import Calendar from 'react-calendar';
import DashboardCalendarTileContent from './DashboardCalendarTileContent';
import PropTypes from 'prop-types';
import DashboardEventsList from './DashboardEventsList';
import moment from 'moment';
import Storages from '../../helpers/Storages';
import Skeletons from '../../helpers/Skeletons';

class DashboardCalendar extends Component {
    constructor(props) {
        super(props);

        const selectedDate = new Date();

        const currentDate = new Date();

        currentDate.setSeconds(0);
        currentDate.setMinutes(0);
        currentDate.setHours(0);
        currentDate.setMilliseconds(0);

        this.state = {
            currentDate,
            selectedDate,
            toggleSlide: false,
            visibleCalendar: this.props.displaySize.isMobile === false,
        };
    }

    onChange = (date) => {
        this.setState({
            toggleSlide: !this.state.toggleSlide,
        });

        setTimeout(() => {
            this.setState({
                selectedDate: date,
                toggleSlide: !this.state.toggleSlide,
            });
        }, this.props.slideAnimationTime);
    };

    toggleVisibleCalendar = () => {
        this.setState({
            visibleCalendar: !this.state.visibleCalendar,
        });
    };

    onActiveDateChange = ({ activeStartDate }) => {
        this.props.onLoadMoreEvents(activeStartDate.getMonth() + 1, activeStartDate.getFullYear());
    };

    filterDayEvents = (events, date) => {
        return events.filter((event) => {
            const eventDate = moment(event.startAt);

            return (
                eventDate.date() === date.getDate() &&
                eventDate.month() === date.getMonth() &&
                eventDate.year() === date.getFullYear()
            );
        });
    };

    render = () => {
        const { currentDate, selectedDate, toggleSlide, visibleCalendar } = this.state;
        const { events, displaySize, dashboardModel, isFetchingMoreEvents } = this.props;

        const dashboardEventsListComponent = (
            <DashboardEventsList
                toggleSlide={toggleSlide}
                date={selectedDate}
                events={this.filterDayEvents(events, selectedDate)}
                toggleVisibleCalendar={this.toggleVisibleCalendar}
                displaySize={displaySize}
                visibleCalendar={visibleCalendar}
                isFetchingMoreEvents={isFetchingMoreEvents}
                dashboardModel={dashboardModel}
            />
        );

        return (
            <>
                {displaySize.isMobile === true && dashboardEventsListComponent}

                {visibleCalendar === true && Skeletons.isSkeleton(dashboardModel) === false && (
                    <Calendar
                        prev2Label={''}
                        next2Label={''}
                        prevLabel={''}
                        nextLabel={''}
                        minDetail={'month'}
                        value={this.state.selectedDate}
                        tileClassName={({ date, view }) =>
                            view === 'month' && date.getTime() < currentDate.getTime()
                                ? 'react-calendar__tile--past'
                                : ''
                        }
                        tileContent={({ date, view }) => (
                            <DashboardCalendarTileContent
                                view={view}
                                events={this.filterDayEvents(events, date)}
                            />
                        )}
                        onChange={this.onChange}
                        onActiveStartDateChange={this.onActiveDateChange}
                        locale={Storages.getLocal('language', 'en')}
                    />
                )}

                {displaySize.isMobile === false && dashboardEventsListComponent}
            </>
        );
    };
}

DashboardCalendar.propTypes = {
    events: PropTypes.array.isRequired,
    onLoadMoreEvents: PropTypes.func.isRequired,
    slideAnimationTime: PropTypes.number.isRequired,
};

DashboardCalendar.defaultProps = {
    slideAnimationTime: 300,
};

export default DashboardCalendar;
