import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext, useWatch } from 'react-hook-form';
import { SelectLanguage } from '@profesia/adamui/components/common/dropdown';
import { FORM_LANGUAGE } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { useGetOfferLanguageOptions } from '@profesia/mark-offer-form/codebooks';
import { useGetLocale, useTranslationInPageLanguage } from '@profesia/mark-offer-form/i18n';
import handleOfferLanguage from '@profesia/mark-offer-form/services/handleOfferLanguage';
import useToastWithTranslation from '@profesia/mark-offer-form/services/useToastWithTranslation';
import { JOB_TITLE } from '@profesia/mark-offer-form/codebooks/dataTestId';
const LanguageDropdown = () => {
    const pageT = useTranslationInPageLanguage('jobTitle.language');
    const { setValue, control } = useFormContext();
    const addToast = useToastWithTranslation();
    const currentLanguage = useGetLocale();
    const languageValue = useWatch({
        name: FORM_LANGUAGE,
        control,
    });
    const offerLanguages = useGetOfferLanguageOptions();
    return (_jsx(SelectLanguage, { options: offerLanguages, value: languageValue, onChange: (language) => {
            setValue(FORM_LANGUAGE, language);
            handleOfferLanguage(language, currentLanguage, addToast);
        }, defaultValue: currentLanguage, label: pageT('label'), "data-testid": JOB_TITLE.LANGUAGE }));
};
export default LanguageDropdown;
