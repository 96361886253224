import appStore from '../../stores/AppStore';
import { openNoModal } from '../../actions/ModalActions';
import { openNoCandidateAccessDialog } from '../../actions/DialogActions';

export default class CandidateDetailOpenHelper {
    static openCandidateDetail(candidate, openParams = {}) {
        const { isAnonymized, isLocked } = candidate;

        if (isLocked === true) {
            appStore.dispatch(openNoCandidateAccessDialog());

            return false;
        }

        if (isAnonymized === true) {
            appStore.dispatch(
                openNoModal('DetailAnonymizedCandidateNoModal', { candidateId: candidate.id })
            );

            return false;
        }

        appStore.dispatch(
            openNoModal('DetailCandidateNoModal', {
                candidateId: candidate.id,
                ...candidate,
                openParams,
            })
        );

        return true;
    }
}
