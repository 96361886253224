import md5 from 'md5';

export default class PipelineHelper {
    static getEnabledFixedStages(fixedStages, stages) {
        return fixedStages.filter(
            (fixedStage) =>
                fixedStage.isSwitchableOnPipeline === false ||
                stages.find((stage) => fixedStage.stageId === stage.stageId) !== undefined
        );
    }

    static getEnabledSwitchableStages(switchableStages, stages) {
        return switchableStages.filter(
            (switchableStage) =>
                stages.find((stage) => switchableStage.stageId === stage.stageId) !== undefined
        );
    }

    static getEnabledSwitchableFixedStages(switchableFixedStages, stages) {
        return this.getEnabledSwitchableStages(
            switchableFixedStages.filter((stage) => stage.isSwitchableOnPipeline === true),
            stages
        );
    }

    static createChangeHash(pipelineStagesForEditor, enabledSwitchableFixedStages) {
        return md5(
            JSON.stringify(pipelineStagesForEditor) + JSON.stringify(enabledSwitchableFixedStages)
        );
    }

    /**
     *
     * @param fixedStagesFromStart
     * @param pipelineStagesForEditor
     * @param enabledSwitchableFixedStages
     */
    static getPipelineFromStages(
        fixedStagesFromStart,
        pipelineStagesForEditor,
        enabledSwitchableFixedStages
    ) {
        const pipelineStages = [];

        fixedStagesFromStart.forEach((fixedStage) => {
            if (fixedStage.isSwitchableOnPipeline === true) {
                if (
                    enabledSwitchableFixedStages.find(
                        (stage) => stage.stageId === fixedStage.stageId
                    ) !== undefined
                ) {
                    pipelineStages.push(fixedStage);
                }
            } else {
                pipelineStages.push(fixedStage);
            }
        });

        pipelineStagesForEditor.forEach((stage) => {
            pipelineStages.push(stage);
        });

        return pipelineStages;
    }
}
