import { GLOBAL_SEARCH } from '../actions/GlobalSearchActions';
import Constants from '../helpers/Constants';
import {
    JOB_CHECK_ALL_CANDIDATES,
    JOB_CHECK_CANDIDATE,
    JOB_CHECK_CANDIDATES_IN_STAGE,
    JOB_UNCHECK_ALL_CANDIDATES,
    JOB_UNCHECK_CANDIDATE,
    JOB_UNCHECK_CANDIDATES,
    JOB_CHECK_CANDIDATES,
    JOB_UNCHECK_CANDIDATES_IN_STAGE,
    SORT_CANDIDATES_BY,
    SELECTED_TABLE_ROW,
} from '../actions/CandidatesListActions';
import { RECEIVE_JOB_MODEL } from '../actions/JobDetailActions';
import { ANONYMIZE_CANDIDATES_SUCCESS } from '../actions/ActionWithCandidateActions';
import Arrays from '../helpers/Arrays';
import {
    CANCEL_FILTER_BY_CANDIDATE_IDS,
    FILTER_CANDIDATES_BY_CANDIDATE_IDS,
    filterCandidatesByRefusedAndNotContacted,
} from '../actions/CandidatesFilterActions';
import Objects from '../helpers/Objects';

export const candidateListTable = (
    state = {
        checkedCandidateIds: [],
        sort: Constants.CANDIDATES_LIST_SORT_BY_DEFAULT,
        sortPipeline: Constants.CANDIDATES_PIPELINE_SORT_BY_DEFAULT,
        selectedRowIndex: -1,
    },
    action
) => {
    let checkedCandidateIds = [];

    switch (action.type) {
        case SORT_CANDIDATES_BY:
            return {
                ...state,

                sort: {
                    sortBy: action.payload.sortBy,
                    sortDirection: action.payload.sortDirection,
                    sortType: action.payload.sortType,
                },
            };

        case RECEIVE_JOB_MODEL:
        case GLOBAL_SEARCH:
            return {
                ...state,
                checkedCandidateIds: [],
            };

        case JOB_CHECK_CANDIDATE:
            checkedCandidateIds = state.checkedCandidateIds;
            checkedCandidateIds.push(parseInt(action.payload.candidateId));

            return {
                ...state,
                checkedCandidateIds,
            };

        case JOB_UNCHECK_CANDIDATE:
            checkedCandidateIds = state.checkedCandidateIds;

            const index = checkedCandidateIds.indexOf(parseInt(action.payload.candidateId));

            if (index > -1) {
                checkedCandidateIds.splice(index, 1);
            }

            return {
                ...state,
                checkedCandidateIds,
            };

        case JOB_CHECK_CANDIDATES:
            return {
                ...state,
                checkedCandidateIds: state.checkedCandidateIds.concat(
                    action.payload.candidates.map((candidate) => candidate.id)
                ),
            };

        case JOB_CHECK_ALL_CANDIDATES:
            return {
                ...state,
                checkedCandidateIds: action.payload.candidates.map((candidate) => candidate.id),
            };

        case JOB_UNCHECK_CANDIDATES:
            return {
                ...state,
                checkedCandidateIds: state.checkedCandidateIds.filter(
                    (candidateId) =>
                        action.payload.candidates.find(
                            (candidate) => candidate.id === candidateId
                        ) === undefined
                ),
            };

        case JOB_UNCHECK_ALL_CANDIDATES:
        case FILTER_CANDIDATES_BY_CANDIDATE_IDS:
            return {
                ...state,
                checkedCandidateIds: [],
            };

        case CANCEL_FILTER_BY_CANDIDATE_IDS:
            if (state.hasOwnProperty('candidateIds') === true) {
                return Objects.deleteProperty(state, 'candidateIds');
            }

            return state;

        case JOB_CHECK_CANDIDATES_IN_STAGE:
            return {
                ...state,

                checkedCandidateIds: Arrays.getUniqueArray(
                    state.checkedCandidateIds.concat(
                        action.payload.candidates
                            .filter(
                                (candidate) =>
                                    candidate.stateId === Constants.CANDIDATE_STATE_ACTIVE &&
                                    candidate.stage.id === action.payload.stageId
                            )
                            .map((candidate) => candidate.id)
                    )
                ),
            };

        case JOB_UNCHECK_CANDIDATES_IN_STAGE:
            return {
                ...state,
                checkedCandidateIds: state.checkedCandidateIds.filter(
                    (candidateId) =>
                        action.payload.candidates.find(
                            (candidate) =>
                                candidate.id === candidateId &&
                                candidate.stage.id === action.payload.stageId
                        ) === undefined
                ),
            };

        case ANONYMIZE_CANDIDATES_SUCCESS:
            return {
                ...state,
                checkedCandidateIds: state.checkedCandidateIds.filter(
                    (candidateId) =>
                        action.payload.anonymizedCandidateIds.includes(candidateId) === false
                ),
            };
        case SELECTED_TABLE_ROW:
            return {
                ...state,
                selectedRowIndex: action.payload.index,
            };

        default:
            return state;
    }
};
