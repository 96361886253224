import React, { Component } from 'react';
import { connect } from 'react-redux';
import CandidateSidebarHistoryAll from '../Candidate/CandidateSidebar/CandidateSidebarHistoryAll';
import LoadingComponent from '../LoadingComponent';
import Grid from '../Grid';
import { requestCandidateHistory, requestCandidateModel } from '../../actions/CandidateActions';
import { closeNoModal, openModal } from '../../actions/ModalActions';
import PropTypes from 'prop-types';
import Translations from '../../helpers/Translations';

class DetailAnonymizedCandidateNoModal extends Component {
    componentDidMount() {
        this.props.requestCandidateModel(this.props.candidateId);
        this.props.requestCandidateHistory(this.props.candidateId);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.candidateId !== this.props.candidateId) {
            this.props.requestCandidateModel(this.props.candidateId);
            this.props.requestCandidateHistory(this.props.candidateId);
        }
    }

    render() {
        const {
            candidateModel: { candidate, history },
            noModalIsOpen,
            isFetchingCandidateHistory,
            noModalType,
        } = this.props;

        const visibility = noModalIsOpen === true ? 'show' : 'hide';

        return (
            <div className={'noModal noModal-wrapper anonymized ' + visibility}>
                <div className="template-header header-extended_light">
                    <div className="header__row">
                        <div className="col-left">
                            <div className="square-button">
                                <div role="link" onClick={this.props.closeNoModal}>
                                    {Translations.getStatic('close')}
                                </div>
                            </div>
                            <div className="nav-grid">
                                <div className="nav-grid__title">
                                    <h3 className="ellipsis">
                                        {Translations.getStatic('anonymizedCandidate')}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isFetchingCandidateHistory === false &&
                history !== undefined &&
                candidate !== undefined ? (
                    <div className="noModal__content">
                        <Grid container>
                            <Grid.Column>
                                <CandidateSidebarHistoryAll
                                    candidateId={candidate.id}
                                    history={history}
                                    sidebarButton={false}
                                    isAnonymized={true}
                                />
                            </Grid.Column>
                        </Grid>
                    </div>
                ) : (
                    <LoadingComponent />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    noModalIsOpen: state.noModalLogic.noModalIsOpen,
    candidateModel: state.candidateModel.anonymizedDetail,
    isFetchingCandidateHistory: state.isFetchingCandidateHistory,
});

const mapDispatchToProps = {
    requestCandidateModel,
    requestCandidateHistory,
    closeNoModal,
    openModal,
};

DetailAnonymizedCandidateNoModal.propTypes = {
    closeNoModal: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailAnonymizedCandidateNoModal);
