import Storages from './Storages';
import md5 from 'md5';
import markVersion from '../version';

class Version {
    static set(markVersion: number): void {
        Storages.setLocal('markVersion', markVersion);
    }

    static get(): number {
        return Storages.getLocal('markVersion', 0);
    }

    static getServerVersionHash(): string {
        return md5('' + this.get()).substring(0, 8);
    }

    static getJsVersion(): number {
        return markVersion;
    }

    static getJsVersionHash(): string {
        return md5('' + this.getJsVersion()).substring(0, 8);
    }
}

export default Version;
