import { jsx as _jsx } from "react/jsx-runtime";
import DOMPurify from 'dompurify';
import { alpha, styled } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import { fontWeights } from '@profesia/adamui/styles/fonts';
import { TypographySpan } from '@profesia/adamui/components/common/typography';
const HighlightSpan = ({ text, highlightTag, shouldNotChangeFontWeight, ...otherProps }) => {
    return (_jsx(StyledSpan, { highlightTag: highlightTag, shouldNotChangeFontWeight: shouldNotChangeFontWeight, dangerouslySetInnerHTML: {
            __html: DOMPurify.sanitize(text),
        }, ...otherProps }));
};
const StyledSpan = styled(TypographySpan, {
    shouldForwardProp: (prop) => !['highlightTag', 'shouldNotChangeFontWeight'].includes(prop),
})(({ highlightTag, shouldNotChangeFontWeight, theme: { palette } }) => {
    const commonStyle = {
        background: alpha(getColor(palette.primaryOrange), 0.3),
        color: getColor(palette.primaryNavy),
        fontStyle: 'normal',
        fontWeight: shouldNotChangeFontWeight ? undefined : fontWeights.fontWeightMedium,
        backgroundSize: 20,
        borderRadius: 2,
    };
    return {
        em: commonStyle,
        ...(highlightTag && {
            [highlightTag]: commonStyle,
        }),
    };
});
export default HighlightSpan;
