import Language from '../helpers/Language';

export const REQUEST_CONTENT_PAGES_MENU_MODEL = 'REQUEST_CONTENT_PAGES_MENU_MODEL';
export const RECEIVE_CONTENT_PAGES_MENU_MODEL = 'RECEIVE_CONTENT_PAGES_MENU_MODEL';
export const REQUEST_CONTENT_PAGE_MODEL = 'REQUEST_CONTENT_PAGE_MODEL';
export const RECEIVE_CONTENT_PAGE_MODEL = 'RECEIVE_CONTENT_PAGE_MODEL';

export const requestContentPagesMenuModel = () => ({
    type: REQUEST_CONTENT_PAGES_MENU_MODEL,
    meta: {
        requestRoute: 'v1/content-pages/model/menu',
        onRequestSuccess: RECEIVE_CONTENT_PAGES_MENU_MODEL,
    },
});

export const requestContentPageModel = (alias, companyId = null, language = Language.get()) => ({
    type: REQUEST_CONTENT_PAGE_MODEL,

    meta: {
        requestData: {
            companyId,
            language,
        },

        requestRoute: 'v1/content-pages/model/page/' + alias,
        onRequestSuccess: RECEIVE_CONTENT_PAGE_MODEL,
    },
});
