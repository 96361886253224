import { isWysiWygEditorEmpty } from '@profesia/adamui/utils/WysiwygEditor';
import { EmploymentType } from '@profesia/mark-offer-form/codebooks';
import createRequiredError from '@profesia/mark-offer-form/components/content/form/resolver/addError/createRequiredError';
import addError from '../addError';
const getLevelOptionsErrors = (items, errorMessage) => {
    let hasError = false;
    const errorData = items.reduce((errors, item) => {
        if (!item.level) {
            errors.push({ level: createRequiredError(errorMessage) });
            hasError = true;
        }
        else {
            errors.push({ level: null });
        }
        return errors;
    }, []) ?? null;
    return hasError ? errorData : null;
};
const getLanguagesLevelOptionsErrors = (languages, errorMessage) => getLevelOptionsErrors(languages?.items ?? [], errorMessage);
const getSkillsLevelOptionsErrors = (skills, errorMessage) => getLevelOptionsErrors(skills?.others ?? [], errorMessage);
const getJobErrors = ({ jobTitle, jobPositions, employmentTypes, jobDescription, positionPlace, salary, educationLevel, languages, skills, }, t) => {
    let errors = {};
    const positionPlaceErrors = {
        ...addError('positionPlace.type', !positionPlace?.type, t('positionPlace.type')),
        ...addError('positionPlace.address', (positionPlace?.type === 1 || positionPlace?.type === 4) &&
            (!positionPlace?.address || positionPlace?.address.length === 0), t('positionPlace.address')),
        ...addError('positionPlace.regions', positionPlace?.type === 2 &&
            (!positionPlace?.regions || positionPlace?.regions.length === 0), t('positionPlace.regions')),
    };
    const salaryErrors = {
        ...addError('salary.type', !(!salary?.type &&
            employmentTypes?.length === 1 &&
            employmentTypes.includes(EmploymentType.Contract)) && !salary?.type, t('salary.type')),
        ...addError('salary.value', salary?.type === 'fixed' && !salary?.value, t('salary.value')),
        ...addError('salary.from', salary?.type === 'range' && !salary?.from, t('salary.from')),
        ...addError('salary.to', salary?.type === 'range' && !!salary?.from && !salary?.to, t('salary.to')),
        ...addError('salary.to', salary !== null && salary?.type === 'range' && salary?.from !== null && salary?.to !== null && salary?.from > salary?.to, t('salary.minMaxCheck')),
    };
    errors = {
        ...addError('jobTitle', !jobTitle, t('jobTitle')),
        ...addError('jobPositions', jobPositions?.length === 0, t('jobPositions')),
        ...addError('employmentTypes', employmentTypes?.length === 0, t('employmentType')),
        ...addError('jobDescription', !jobDescription || isWysiWygEditorEmpty(jobDescription), t('jobDescription')),
        ...positionPlaceErrors,
        ...salaryErrors,
        ...addError('educationLevel', !educationLevel, t('education')),
    };
    const languageErrors = getLanguagesLevelOptionsErrors(languages, t('languages.items.level'));
    if (languageErrors) {
        errors = { ...errors, languages: { items: languageErrors } };
    }
    const skillErrors = getSkillsLevelOptionsErrors(skills, t('skills.others.level'));
    if (skillErrors) {
        errors = { ...errors, skills: { others: skillErrors } };
    }
    return errors;
};
export default getJobErrors;
