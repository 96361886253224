import Authentication from './Authentication';
import Url from './Url';
import Language from './Language';
import Browser from './Browser';
import { CommonObjectType } from '../../interfaces/CommonTypes';

const API_ROUTE_PREFIX: string = '/api4/';

class Ajax {
    static get API_ROUTE_PREFIX(): string {
        return API_ROUTE_PREFIX;
    }

    static getFetchLink(requestMetaData: CommonObjectType): string {
        const language: string = Language.get();

        const link: string = this.getLink(requestMetaData);

        if (
            !requestMetaData.hasOwnProperty('requestData') ||
            requestMetaData.requestData.length === 0
        ) {
            return link + '?language=' + language;
        }

        if (!requestMetaData.requestData.hasOwnProperty('language')) {
            requestMetaData.requestData.language = language;
        }

        return (
            link +
            '?' +
            Object.keys(requestMetaData.requestData)
                .map((key) => key + '=' + encodeURIComponent(requestMetaData.requestData[key]))
                .join('&')
        );
    }

    static getLink(requestMetaData: CommonObjectType): string {
        return requestMetaData.isDefaultApiPrefixDisabled
            ? Url.MARK_SERVER_PREFIX + requestMetaData.requestRoute
            : Url.MARK_SERVER_PREFIX + this.API_ROUTE_PREFIX + requestMetaData.requestRoute;
    }

    static getFetchParams(
        method: string = 'GET',
        body: string = '',
        signal: AbortSignal | null = null
    ): FetchParametersType {
        const headers = this.getStoredAuthorizationTokens();

        headers.append('Accept', 'application/json');

        const params: FetchParametersType = {
            method,
            headers,
            credentials: 'include',
        };

        if (signal !== null) {
            params.signal = signal;
        }

        if (method !== 'GET' && body !== '') {
            params.body = body;
        }

        return params;
    }

    static getStoredAuthorizationTokens(): Headers {
        return new Headers({
            'Mark-Authorization': 'Bearer ' + Authentication.getAccessToken(),
            'Mark-Refresh-Authorization': 'Bearer ' + Authentication.getRefreshToken(),
            'Mark-Application-Id': 'Bearer ' + Authentication.getApplicationId(),
            'Mark-Language': 'Bearer ' + Authentication.getLanguage(),
            'Mark-Browser-Tab-Id': 'Bearer ' + Browser.getBrowserTabId(),
        });
    }
}

interface FetchParametersType {
    credentials: string;
    headers: Headers;
    method: string;
    body?: string;
    signal?: AbortSignal;
}

export default Ajax;
