import React from 'react';
import SidebarSubmenuListItem from './SidebarSubmenuListItem';

const SidebarSubmenuList = ({ item }) => {
    const submenuItems = item.submenu.filter((submenuItem) => {
        return (
            (submenuItem.hasOwnProperty('visible') === false || submenuItem.visible === true) &&
            (submenuItem.hasOwnProperty('title') === true ||
                submenuItem.hasOwnProperty('action') === true)
        );
    });

    return (
        <ul className="submenu-list">
            {submenuItems.map((submenuItem, i) => (
                <SidebarSubmenuListItem key={i} submenuItem={submenuItem} />
            ))}
        </ul>
    );
};

export default SidebarSubmenuList;
