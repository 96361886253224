import Form from '../Form';
import Translations from '../Translations';
import Constants from '../Constants';

export default class EmailTemplateValidatorHelper {
    constructor(emailTemplate, emailType = Constants.EMAIL_TYPE_TO_COLLEAGUE) {
        this.emailTemplate = emailTemplate;
        this.emailType = emailType;
    }

    checkEmailTemplateTitle() {
        const fieldsToCheck = [
            {
                fieldName: 'templateTitle',
                fieldType: 'text',
                fieldValue: this.emailTemplate.name,
            },
        ];

        return Form.multiCheck(fieldsToCheck);
    }

    checkUniqueEmailTemplateTitle(against) {
        const fieldsToCheck = [
            {
                fieldName: 'uniqueEmailTemplateTitle',
                fieldType: 'unique',
                fieldValue: this.emailTemplate.name,
                against: against,
            },
        ];

        return Form.multiCheck(fieldsToCheck);
    }

    checkEmailTemplateMessage() {
        const fieldsToCheck = [
            {
                fieldName: 'subject',
                fieldType: 'wysiwyg',
                fieldValue: this.emailTemplate.subject,
            },
        ];

        if (this.emailType !== Constants.EMAIL_TYPE_TO_COLLEAGUE) {
            fieldsToCheck.push({
                fieldName: 'message',
                fieldType: 'wysiwyg',
                fieldValue: this.emailTemplate.text,
            });
        }

        return Form.multiCheck(fieldsToCheck);
    }

    checkEmailTemplate(withAlerts = true, checkTitle = true, against = []) {
        let errors = [];

        if (checkTitle === true) {
            errors = this.checkEmailTemplateTitle();

            if (errors.length > 0) {
                return errors;
            }

            if (against.length > 0) {
                errors = this.checkUniqueEmailTemplateTitle(against);

                if (errors.length > 0) {
                    if (errors.indexOf('uniqueEmailTemplateTitle') > -1 && withAlerts === true) {
                        alert(Translations.getStatic('uniqueEmailTemplateNameError'));
                    }

                    return errors;
                }
            }
        }

        errors = this.checkEmailTemplateMessage();

        if (errors.length > 0) {
            return errors;
        }

        return [];
    }
}
