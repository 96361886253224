import {
    REQUEST_CANDIDATES_GDPR_SETTINGS_MODEL,
    RECEIVE_CANDIDATES_GDPR_SETTINGS_MODEL,
    CANDIDATES_GDPR_SETTING_UPDATE_SUCCESS,
    CANDIDATES_GDPR_SETTING_CREATE_SUCCESS,
    CANDIDATES_GDPR_SETTING_ADD,
    CANDIDATES_GDPR_SETTING_ADD_CANCEL,
} from '../actions/CandidatesGdprSettingsActions';
import Arrays from '../helpers/Arrays';

export const candidatesGdprSettingsModel = (state = {}, action) => {
    switch (action.type) {
        case RECEIVE_CANDIDATES_GDPR_SETTINGS_MODEL:
            return {
                ...state,
                ...action.payload,
            };

        case CANDIDATES_GDPR_SETTING_UPDATE_SUCCESS:
        case CANDIDATES_GDPR_SETTING_CREATE_SUCCESS:
            return {
                ...state,

                candidatesGdprSettings: state.candidatesGdprSettings.map((candidatesGdprSetting) =>
                    candidatesGdprSetting.id === action.meta.requestPayload.candidatesGdprSettingId
                        ? action.payload.candidatesGdprSetting
                        : candidatesGdprSetting
                ),
            };

        case CANDIDATES_GDPR_SETTING_ADD:
            return {
                ...state,

                candidatesGdprSettings: Arrays.prepend(
                    {
                        id: 'new',
                        companyId: '',
                        monthsToExpire: '',
                    },
                    state.candidatesGdprSettings
                ),
            };

        case CANDIDATES_GDPR_SETTING_ADD_CANCEL:
            return {
                ...state,
                candidatesGdprSettings: state.candidatesGdprSettings.filter(
                    (candidatesGdprSetting) => candidatesGdprSetting.id !== 'new'
                ),
            };

        default:
            return state;
    }
};

export const isFetchingCandidatesGdprSettingsModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_CANDIDATES_GDPR_SETTINGS_MODEL:
            return true;

        case RECEIVE_CANDIDATES_GDPR_SETTINGS_MODEL:
            return false;

        default:
            return state;
    }
};
