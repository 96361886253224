import { ToastContextT } from '@profesia/adamui/components/common/toastService/types';
import { useToastContext } from '@profesia/adamui/components/common/toastService';
import Translations from './Translations';

const useToastWithTranslations = (): ToastContextT<string> => {
    const addToast = useToastContext();

    return ({ message, ...rest }) =>
        addToast({ message: Translations.getStatic(message), ...rest });
};

export default useToastWithTranslations;
