import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    requestQuestionnaireEditorModel,
    updateQuestionnaire,
    addAnswerItem,
    addQuestionItem,
    deleteAnswerItem,
    deleteQuestionItem,
    updateAnswerItem,
    updateQuestionItem,
    reorderQuestionItem,
} from '../../actions/QuestionnaireEditorActions';
import {
    saveAsNewQuestionnaireTemplate,
    saveAsChangedQuestionnaireTemplate,
} from '../../actions/TemplateAssistantActions';
import TemplateAssistantTemplateHeader from '../TemplateAssistant/TemplateAssistantTemplateHeader';
import QuestionnaireEditor from '../Questionnaire/Editor/QuestionnaireEditor';
import Form from '../Form';
import Grid from '../Grid';
import Divider from '../Divider';
import Heading from '../Heading';
import LoadingComponent from '../LoadingComponent';
import Translations from '../../helpers/Translations';
import Constants from '../../helpers/Constants';

class SettingsQuestionnaireEditor extends React.Component {
    componentDidMount = () => {
        this.props.requestQuestionnaireEditorModel(this.props.questionnaireId, this.props.isCopy);
    };

    componentDidUpdate = (prevProps) => {
        if (
            this.props.questionnaireEditorModel.hasOwnProperty('questionnaireData') === true &&
            (prevProps.questionnaireEditorModel.hasOwnProperty('questionnaireData') === false ||
                prevProps.questionnaireEditorModel.questionnaireData.id !==
                    this.props.questionnaireEditorModel.questionnaireData.id ||
                prevProps.isCopy === true)
        ) {
            if (
                this.props.questionnaireEditorModel.questionnaireData.id !== null &&
                prevProps.isCopy === false
            ) {
                prevProps.saveAsChangedQuestionnaireTemplate(true);
            } else {
                prevProps.saveAsNewQuestionnaireTemplate(true);
            }
        }
    };

    render = () => {
        if (
            this.props.isFetchingQuestionnaireEditorModel === true ||
            this.props.questionnaireEditorModel.hasOwnProperty('questionnaireData') === false
        ) {
            return <LoadingComponent />;
        }

        const {
            questionnaireEditorModel: { questionnaireData },
            errors,
        } = this.props;

        return (
            <>
                <Form>
                    <Heading as="h3">{Translations.getStatic('questionnaireTemplate')}</Heading>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <TemplateAssistantTemplateHeader
                                    errors={errors}
                                    templateData={questionnaireData}
                                    templateType={Constants.TEMPLATE_TYPE_QUESTIONNAIRE}
                                    onUpdateTemplate={this.props.updateQuestionnaire}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
                <Divider />
                <>
                    <Heading as="h3">{Translations.getStatic('questionnaireQuestions')}</Heading>
                    <QuestionnaireEditor
                        questionnaireEditorModel={this.props.questionnaireEditorModel}
                        onAddQuestionItem={this.props.addQuestionItem}
                        onDeleteQuestionItem={this.props.deleteQuestionItem}
                        onUpdateQuestionItem={this.props.updateQuestionItem}
                        onAddAnswerItem={this.props.addAnswerItem}
                        onDeleteAnswerItem={this.props.deleteAnswerItem}
                        onUpdateAnswerItem={this.props.updateAnswerItem}
                        onReorderQuestionItem={this.props.reorderQuestionItem}
                        modalType={this.props.modalType}
                    />
                </>
            </>
        );
    };
}

SettingsQuestionnaireEditor.propTypes = {
    questionnaireId: PropTypes.number.isRequired,
    isCopy: PropTypes.bool.isRequired,
    modalType: PropTypes.string,
};

const mapStateToProps = (state) => ({
    questionnaireEditorModel: state.questionnaireEditorModel,
    isFetchingQuestionnaireEditorModel: state.isFetchingQuestionnaireEditorModel,
    modalType: state.modalLogic.modalType,
});

const mapDispatchToProps = {
    requestQuestionnaireEditorModel,
    updateQuestionnaire,
    addQuestionItem,
    addAnswerItem,
    deleteQuestionItem,
    deleteAnswerItem,
    updateQuestionItem,
    updateAnswerItem,
    reorderQuestionItem,
    saveAsNewQuestionnaireTemplate,
    saveAsChangedQuestionnaireTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsQuestionnaireEditor);
