import React from 'react';
import JobPipelineColumnItemSkeleton from './JobPipelineColumnItemSkeleton';

const JobPipelineColumnSkeleton = ({ itemsCount = 3 }) => (
    <div className="pipeline-column">
        {[...Array(itemsCount)].map((e, i) => (
            <JobPipelineColumnItemSkeleton key={i} />
        ))}
    </div>
);

export default JobPipelineColumnSkeleton;
