import md5 from 'md5';

export default class AutoAnswerHelper {
    static createChangeHash(text, subject, type, languageId) {
        return md5(
            JSON.stringify({
                text: text,
                subject: subject,
                languageId: languageId,
            })
        );
    }
}
