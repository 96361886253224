import React from 'react';
import { SkeletonCircular, SkeletonRectangular } from '@profesia/adamui/components/common/skeleton';
import { Stack } from '@mui/material';
import lineGraph from '../../../img/line-graph.svg';

const StatisticsPageSkeleton = () => (
    <div className="page">
        <div className="template-header header-basic">
            <Stack
                flex={1}
                px={4}
                py={3.5}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <SkeletonRectangular width={384} height={24} borderRadius={26} />

                <Stack spacing={2} direction="row">
                    {[...Array(3)].map((e, i) => (
                        <SkeletonRectangular width={120} height={36} borderRadius={4} key={i} />
                    ))}
                </Stack>
            </Stack>
        </div>

        <div className="page-content statistics skeleton">
            <Stack
                flex={1}
                spacing={3}
                direction="row"
                alignItems="start"
                justifyContent="space-between"
            >
                <Stack flex={1} spacing={6} pt={3.5} className="list">
                    <div className="ui items list__jobs">
                        <div className="item">
                            <div className="content">
                                <Stack flex={1} spacing={1} px={3} py={4}>
                                    <SkeletonRectangular
                                        width={244}
                                        height={18}
                                        borderRadius={26}
                                    />
                                    <SkeletonRectangular
                                        width={116}
                                        height={10}
                                        borderRadius={26}
                                    />
                                    <SkeletonRectangular
                                        width={188}
                                        height={10}
                                        borderRadius={26}
                                    />
                                </Stack>

                                <Stack flex={1} spacing={4} p={4}>
                                    <Stack flex={1} spacing={3} direction="row" alignItems="center">
                                        <Stack spacing={4}>
                                            {[...Array(5)].map((e, i) => (
                                                <SkeletonRectangular
                                                    width={20}
                                                    height={10}
                                                    borderRadius={26}
                                                    key={i}
                                                />
                                            ))}
                                        </Stack>

                                        <Stack flex={1} pl={10} pr={7}>
                                            <img
                                                className="skeleton-animation"
                                                src={lineGraph}
                                                height={178}
                                                alt="line-graph"
                                            />
                                        </Stack>
                                    </Stack>

                                    <Stack flex={1} direction="row" justifyContent="space-between">
                                        {[...Array(20)].map((e, i) => (
                                            <SkeletonRectangular
                                                width={20}
                                                height={10}
                                                borderRadius={26}
                                                key={i}
                                            />
                                        ))}
                                    </Stack>
                                </Stack>
                            </div>
                        </div>
                    </div>

                    <Stack flex={1} spacing={3}>
                        {[...Array(5)].map((e, i) => (
                            <Stack
                                flex={1}
                                spacing={1.5}
                                direction="row"
                                alignItems="center"
                                key={i}
                            >
                                <SkeletonCircular size={18} />

                                <Stack flex={1}>
                                    <SkeletonRectangular height={10} borderRadius={26} />
                                </Stack>
                            </Stack>
                        ))}
                    </Stack>
                </Stack>

                <Stack spacing={3} pr={6} pt={3.5} alignItems="end">
                    {[...Array(8)].map((e, i) => (
                        <Stack flex={1} spacing={1.5} direction="row" alignItems="center" key={i}>
                            <SkeletonCircular size={18} />
                            <SkeletonRectangular width={187} height={10} borderRadius={26} />
                        </Stack>
                    ))}
                </Stack>
            </Stack>
        </div>
    </div>
);

export default StatisticsPageSkeleton;
