import React from 'react';
import { SkeletonRectangular } from '@profesia/adamui/components/common/skeleton';
import { Stack } from '@mui/material';

const SettingsPageDataViewSkeleton = ({ itemsCount = 4 }) => (
    <div className="list skeleton">
        <Stack pb={5} pt={0.5}>
            <SkeletonRectangular width={244} height={18} borderRadius={26} />
        </Stack>

        <div className="ui items list__jobs">
            {[...Array(itemsCount)].map((e, i) => (
                <div className="item" key={i}>
                    <div className="content">
                        <Stack flex={1} spacing={1} my={1} py={3}>
                            <SkeletonRectangular width={244} height={18} borderRadius={26} />
                            <SkeletonRectangular width={116} height={10} borderRadius={26} />
                            <SkeletonRectangular width={188} height={10} borderRadius={26} />
                        </Stack>
                    </div>
                </div>
            ))}
        </div>
    </div>
);

export default SettingsPageDataViewSkeleton;
