import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import { closeModal, openModalSecond } from '../../actions/ModalActions';
import { connect } from 'react-redux';
import ChangeStageOfCandidates from '../Candidate/ChangeStageOfCandidates';
import { changeCandidatesStage } from '../../actions/ActionWithCandidateActions';
import { jobUncheckAllCandidates } from '../../actions/CandidatesListActions';
import { setErrorFocus } from '../../actions/ErrorActions';
import SendEmailHelper from '../../helpers/components/SendEmailHelper';
import Constants from '../../helpers/Constants';
import SuccessMessage from '../../helpers/SuccessMessage';
import Translations from '../../helpers/Translations';
import AttachmentAssistantHelper from '../../helpers/components/AttachmentAssistantHelper';
import {
    openAllRecipientsWithoutEmailsDialog,
    openSomeRecipientsWithoutEmailsDialog,
} from '../../actions/DialogActions';
import Arrays from '../../helpers/Arrays';
import {
    removeFromCurrentlyAcceptedCandidates,
    setCurrentlyAcceptedCandidates,
} from '../../actions/CurrentlyAcceptedCandidatesActions';
import Url from '../../helpers/Url';
import LoadingComponent from '../LoadingComponent';
import Variables from '../../helpers/Variables';

class ChangeStageOfCandidatesModal extends Component {
    state = {
        showSuccessMessage: false,
        errors: [],
    };

    componentDidUpdate = (prevProps) => {
        if (
            SuccessMessage.canShowSuccessMessage(
                prevProps,
                this.props,
                'isChangingStageOfCandidates'
            ) &&
            this.props.candidateActionModel.hasOwnProperty('isSuccess') === true &&
            this.props.candidateActionModel.isSuccess === true
        ) {
            prevProps.jobUncheckAllCandidates();
            prevProps.closeModal();
        }
    };

    changeStage = (excludedCandidateIdsFromSendEmail = []) => {
        if (this.props.candidateActionModel.changeStageOfCandidatesParams.stageId === null) {
            const errors = this.state.errors.concat('stageId');

            this.setState({
                errors,
            });

            this.props.setErrorFocus();

            return;
        }

        const {
            candidateActionModel,
            sendEmailModel,
            attachmentAssistantModel,
            templateAssistant,
            templatesForDropdownSelector,
            loginManagerModel,
        } = this.props;

        const candidateIds = Arrays.getUniqueArray(this.props.candidateIds);

        try {
            let actionData = new FormData();

            if (candidateActionModel.changeStageOfCandidatesParams.sendEmail === true) {
                actionData = SendEmailHelper.prepareEmail(
                    sendEmailModel,
                    attachmentAssistantModel,
                    templateAssistant,
                    templatesForDropdownSelector,
                    candidateIds,
                    Constants.EMAIL_TYPE_TO_CANDIDATE,
                    {
                        variables: sendEmailModel.settings.variables,
                        filledVariables: Variables.getFilledVariablesCheckParams(
                            [
                                Constants.VARIABLE_ACTION_TYPE_USER,
                                Constants.VARIABLE_ACTION_TYPE_EVENT,
                                Constants.VARIABLE_ACTION_TYPE_VIDEO_INTERVIEW,
                                Constants.VARIABLE_ACTION_TYPE_QUESTIONNAIRE,
                            ],
                            candidateActionModel.candidates,
                            loginManagerModel,
                            sendEmailModel.settings.videoInterview
                        ),
                        forbiddenVariables: Variables.getForbiddenVariablesCheckParams([
                            Constants.VARIABLE_ACTION_TYPE_MULTIPLE_FORWARD,
                        ]),
                        requiredVariables: {},
                    },
                    excludedCandidateIdsFromSendEmail
                );
            }

            actionData.append(
                'changeStageOfCandidatesParams',
                JSON.stringify(candidateActionModel.changeStageOfCandidatesParams)
            );
            actionData.append('candidateIds', JSON.stringify(candidateIds));

            this.props.changeCandidatesStage(
                actionData,
                candidateIds,
                candidateActionModel.stages.find(
                    (stage) =>
                        parseInt(stage.stageId) ===
                        parseInt(candidateActionModel.changeStageOfCandidatesParams.stageId)
                ),
                candidateActionModel.changeStageOfCandidatesParams.sendEmail === true
            );

            this.setState({
                errors: [],
            });

            if (
                parseInt(candidateActionModel.changeStageOfCandidatesParams.stageId) ===
                Constants.CANDIDATE_STAGE_ACCEPTED_ID
            ) {
                this.props.setCurrentlyAcceptedCandidates(
                    this.props.jobId,
                    candidateIds,
                    Url.getUrl()
                );
            } else {
                this.props.removeFromCurrentlyAcceptedCandidates(candidateIds);
            }
        } catch (error) {
            if (error !== false && Array.isArray(error) === true) {
                SendEmailHelper.processRecipientsErrors(
                    error,
                    this.props.openAllRecipientsWithoutEmailsDialog,
                    () =>
                        this.props.openSomeRecipientsWithoutEmailsDialog({
                            candidateIds: candidateIds,
                            onSkip: (excludedCandidateIdsFromSendEmail) =>
                                this.changeStage(excludedCandidateIdsFromSendEmail),
                        })
                );

                this.setState({
                    errors: error,
                });

                this.props.setErrorFocus();
            }
        }
    };

    render() {
        const {
            isChangingStageOfCandidates,
            isFetchingChangeStageModel,
            isGettingVideoInterviewData,
            isFetchingCandidatesForAction,
            isFetchingAttachmentAssistantModel,
            isFetchingSendEmailModel,
            isOpen,
            candidateIds,
            candidateActionModel,
            sendEmailModel,
            attachmentAssistantModel,
            jobId,
        } = this.props;

        const { errors } = this.state;

        const children =
            isChangingStageOfCandidates === true ? (
                <LoadingComponent
                    text={Translations.getStatic('saving') + '...'}
                    showProgress={candidateIds.length > Constants.POST_CHUNK_SIZE}
                />
            ) : (
                <ChangeStageOfCandidates
                    errors={errors}
                    candidateIds={candidateIds}
                    jobId={jobId}
                />
            );

        const emailData = {
            from:
                sendEmailModel.settings && sendEmailModel.settings.companyUsers
                    ? sendEmailModel.settings.companyUsers.find(
                          (user) => user.id === sendEmailModel.sender
                      ).email
                    : '',
            to: sendEmailModel.recipients.map((recipient) => recipient.value),
            bcc: sendEmailModel.bcc.map((bccEmail) => bccEmail.value),
            subject: sendEmailModel.emailTemplateData
                ? sendEmailModel.emailTemplateData.subject
                : '',
            body: sendEmailModel.emailTemplateData ? sendEmailModel.emailTemplateData.text : '',
            attachments:
                AttachmentAssistantHelper.getAttachmentsForEmailPreview(attachmentAssistantModel),
            attachmentSettings: attachmentAssistantModel.settings,
            languageId: sendEmailModel.emailTemplateData
                ? sendEmailModel.emailTemplateData.languageId
                : 1,
            candidateId: candidateIds.length > 0 ? candidateIds[0] : 0,
            senderId: sendEmailModel.sender,
        };

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={this.props.closeModal}
                isLoadingData={
                    isFetchingChangeStageModel === true ||
                    isFetchingCandidatesForAction === true ||
                    isGettingVideoInterviewData === true ||
                    isFetchingAttachmentAssistantModel === true ||
                    isFetchingSendEmailModel === true
                }
                actionButton={{
                    text: Translations.getStatic('saveChanges'),
                    disabled: this.props.isChangingStageOfCandidates,
                    loading: this.props.isChangingStageOfCandidates,
                    onClick: () => this.changeStage(),
                }}
                entityOption={{
                    text: Translations.getStatic('emailPreview'),
                    visible: candidateActionModel.changeStageOfCandidatesParams.sendEmail,
                    disabled: this.props.isChangingStageOfCandidates,
                    loading: this.props.isChangingStageOfCandidates,
                    onClick: () =>
                        this.props.openModalSecond('DetailEmailModal', {
                            title: Translations.getStatic('emailPreview'),
                            emailData,
                        }),
                }}
                title={Translations.getStatic('editStage')}
                content={children}
            />
        );
    }
}

ChangeStageOfCandidatesModal.propTypes = {
    jobId: PropTypes.number.isRequired,
    candidateIds: PropTypes.array.isRequired,
    isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    isOpen: state.modalLogic.isOpen,
    candidateActionModel: state.candidateActionModel,
    isChangingStageOfCandidates: state.isChangingStageOfCandidates,
    sendEmailModel: state.sendEmailModel,

    attachmentAssistantModel: AttachmentAssistantHelper.getActiveModelFromAssistantAttachmentModel(
        state.attachmentAssistantModel,
        AttachmentAssistantHelper.createAttachmentAssistantHashIdForEmail()
    ),

    templateAssistant: state.templateAssistant,
    templatesForDropdownSelector: state.templatesForDropdownSelector.templates,
    loginManagerModel: state.loginManagerModel,
    isFetchingChangeStageModel: state.isFetchingChangeStageModel,
    isFetchingCandidatesForAction: state.isFetchingCandidatesForAction,
    isGettingVideoInterviewData: state.isGettingVideoInterviewData,
    isFetchingAttachmentAssistantModel: state.isFetchingAttachmentAssistantModel,
    isFetchingSendEmailModel: state.isFetchingSendEmailModel,
});

const mapDispatchToProps = {
    closeModal,
    openModalSecond,
    changeCandidatesStage,
    jobUncheckAllCandidates,
    setErrorFocus,
    openAllRecipientsWithoutEmailsDialog,
    openSomeRecipientsWithoutEmailsDialog,
    setCurrentlyAcceptedCandidates,
    removeFromCurrentlyAcceptedCandidates,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeStageOfCandidatesModal);
