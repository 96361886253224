import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Stack } from '@mui/material';
import { Typography } from '@profesia/adamui/components/common/typography';
import { SimpleLink } from '@profesia/adamui/components/common/Link';
import { useMobile } from '@profesia/adamui/utils/responsive';
import { SectionWrapperColorVariant } from '@profesia/adamui/components/common/types';
import { CONTACT_DETAILS_SECTION } from '@profesia/mark-offer-form/codebooks/dataTestId';
import BoxedSectionWrapper from '@profesia/mark-offer-form/components/content/form/components/BoxedSectionWrapper';
import { useGetFixedT, useTranslationInPageLanguage } from '@profesia/mark-offer-form/i18n';
import { FORM_CONTACT_PERSON_ID } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import getContactPersonById from '@profesia/mark-offer-form/services/contactPersons/getContactPersonById/index';
import { useOptionsContext } from '@profesia/mark-offer-form/services/OptionsContext';
import { getConfiguration } from '@profesia/mark-offer-form/configuration';
import ContactModal from '../Modal';
import ContactCard from '../Card';
const Content = ({ contactPersons: contactPersonList, onFetchContactPersonsAgain, }) => {
    const t = useGetFixedT('contactDetails');
    const pageT = useTranslationInPageLanguage('contactDetails');
    const { control, setValue } = useFormContext();
    const [contactPersons, setContactPersons] = useState(contactPersonList ?? []);
    const [selectedContactPerson, setSelectedContactPerson] = useState();
    const { isEditMode } = useOptionsContext();
    const { form } = getConfiguration();
    const isMobile = useMobile('sm');
    const editLink = pageT(isMobile ? 'links.editLinkShort' : 'links.editLinkFull');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => {
        setIsModalOpen(true);
        isEditMode && form?.openModalSecond();
    };
    const handleSubmitData = (data) => {
        const updatedContactPersons = contactPersons.map((person) => person.id === data.id ? data : person);
        setContactPersons(updatedContactPersons);
        setValue(FORM_CONTACT_PERSON_ID, data.id);
        setSelectedContactPerson(data);
        handleCloseModal();
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
        isEditMode && form?.closeModalSecond();
    };
    const contactPersonId = useWatch({
        name: FORM_CONTACT_PERSON_ID,
        control,
    });
    useEffect(() => {
        const contactPerson = getContactPersonById(contactPersons, contactPersonId);
        setSelectedContactPerson(contactPerson);
    }, [contactPersons, contactPersonId]);
    useEffect(() => {
        setContactPersons(contactPersonList ?? []);
    }, [contactPersonList]);
    return (_jsxs(_Fragment, { children: [_jsx(BoxedSectionWrapper, { title: t('title'), testId: CONTACT_DETAILS_SECTION, endComponent: selectedContactPerson ? (_jsx(SimpleLink, { variantType: "primary", variant: "body2", onClick: handleOpenModal, children: editLink })) : null, variant: SectionWrapperColorVariant.Dark, children: selectedContactPerson ? (_jsx(ContactCard, { contactPersons: contactPersons, selectedContactPerson: selectedContactPerson })) : (_jsxs(Stack, { gap: 2, direction: "row", alignItems: "center", children: [_jsx(Typography, { variant: "body2", children: pageT('error.body') }), _jsx(SimpleLink, { variantType: "primary", variant: "body2", onClick: onFetchContactPersonsAgain, children: pageT('error.button') })] })) }), isModalOpen && selectedContactPerson ? (_jsx(ContactModal, { contactPerson: selectedContactPerson, onSubmit: handleSubmitData, onClose: handleCloseModal, isOpen: isModalOpen })) : null] }));
};
export default Content;
