export const REQUEST_ATTACHMENT_ASSISTANT_MODEL = 'REQUEST_ATTACHMENT_ASSISTANT_MODEL';
export const RECEIVE_ATTACHMENT_ASSISTANT_MODEL = 'RECEIVE_ATTACHMENT_ASSISTANT_MODEL';
export const ADD_ATTACHMENTS_IN_BULK = 'ADD_ATTACHMENTS_IN_BULK';
export const UPLOAD_ATTACHMENTS_LIVE = 'UPLOAD_ATTACHMENTS_LIVE';
export const UPLOAD_ATTACHMENTS_LIVE_SUCCESS = 'UPLOAD_ATTACHMENTS_LIVE_SUCCESS';
export const UPLOAD_ATTACHMENTS_LIVE_FAILURE = 'UPLOAD_ATTACHMENTS_LIVE_FAILURE';
export const REMOVE_ATTACHMENT_FROM_BULK = 'REMOVE_ATTACHMENT_FROM_BULK';
export const ADD_ATTACHMENT_TO_EMAIL = 'ADD_ATTACHMENT_TO_EMAIL';
export const REMOVE_ATTACHMENT_FROM_EMAIL = 'REMOVE_ATTACHMENT_FROM_EMAIL';
export const REMOVE_CANDIDATE_ATTACHMENT = 'REMOVE_CANDIDATE_ATTACHMENT';
export const REMOVE_CANDIDATE_ATTACHMENT_SUCCESS = 'REMOVE_CANDIDATE_ATTACHMENT_SUCCESS';
export const REMOVE_CANDIDATE_ATTACHMENT_FAILURE = 'REMOVE_CANDIDATE_ATTACHMENT_FAILURE';
export const REQUEST_MULTI_ATTACHMENT_ASSISTANT_MODEL = 'REQUEST_MULTI_ATTACHMENT_ASSISTANT_MODEL';
export const RECEIVE_MULTI_ATTACHMENT_ASSISTANT_MODEL = 'RECEIVE_MULTI_ATTACHMENT_ASSISTANT_MODEL';
export const ADD_INTO_SELECTED_ATTACHMENT_TYPES = 'ADD_INTO_SELECTED_ATTACHMENT_TYPES';
export const REMOVE_FROM_SELECTED_ATTACHMENT_TYPES = 'REMOVE_FROM_SELECTED_ATTACHMENT_TYPES';
export const REMOVE_TEMPLATE_ATTACHMENT = 'REMOVE_TEMPLATE_ATTACHMENT';

export const requestAttachmentAssistantModel = (
    candidateId = 0,
    hashId,
    language = '',
    templateAttachments = []
) => ({
    type: REQUEST_ATTACHMENT_ASSISTANT_MODEL,
    payload: {
        candidateId,
        hashId,
        templateAttachments,
    },
    meta: {
        requestRoute: 'v1/attachment/model/assistant/' + candidateId,

        requestData: {
            language,
        },

        onRequestSuccess: RECEIVE_ATTACHMENT_ASSISTANT_MODEL,
    },
});

export const addAttachmentsInBulk = (attachments, hashId) => ({
    type: ADD_ATTACHMENTS_IN_BULK,
    payload: {
        attachments,
        hashId,
    },
});

export const removeAttachmentFromBulk = (attachmentKey, hashId) => ({
    type: REMOVE_ATTACHMENT_FROM_BULK,
    payload: {
        attachmentKey,
        hashId,
    },
});

export const uploadAttachmentsLive = (candidateId, attachments, formData, uploadHash, hashId) => ({
    type: UPLOAD_ATTACHMENTS_LIVE,
    meta: {
        requestRoute: 'v1/attachment/action/upload/' + candidateId,
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: UPLOAD_ATTACHMENTS_LIVE_SUCCESS,
        onRequestFailure: UPLOAD_ATTACHMENTS_LIVE_FAILURE,
    },
    payload: {
        attachments,
        uploadHash,
        candidateId,
        hashId,
    },
});

export const removeCandidateAttachment = (candidateId, attachmentId, hashId) => ({
    type: REMOVE_CANDIDATE_ATTACHMENT,
    meta: {
        requestRoute: 'v1/attachment/action/remove/' + candidateId + '/' + attachmentId,
        requestMethod: 'POST',

        onRequestSuccess: REMOVE_CANDIDATE_ATTACHMENT_SUCCESS,
        onRequestFailure: REMOVE_CANDIDATE_ATTACHMENT_FAILURE,
    },
    payload: {
        attachmentId,
        candidateId,
        hashId,
    },
});

export const addAttachmentToEmail = (attachment, hashId) => ({
    type: ADD_ATTACHMENT_TO_EMAIL,
    payload: {
        attachment,
        hashId,
    },
});

export const removeAttachmentFromEmail = (attachmentId, hashId) => ({
    type: REMOVE_ATTACHMENT_FROM_EMAIL,
    payload: {
        attachmentId,
        hashId,
    },
});

export const requestMultiAttachmentAssistantModel = (
    candidateIds = [],
    hashId,
    templateAttachments = []
) => ({
    type: REQUEST_MULTI_ATTACHMENT_ASSISTANT_MODEL,
    meta: {
        requestRoute: 'v1/attachment/model/multi-assistant',
        requestData: {
            candidateIds: JSON.stringify(candidateIds),
        },

        onRequestSuccess: RECEIVE_MULTI_ATTACHMENT_ASSISTANT_MODEL,
    },

    payload: {
        hashId,
        candidateIds,
        templateAttachments,
    },
});

export const addIntoSelectedAttachmentTypes = (typeId, hashId) => ({
    type: ADD_INTO_SELECTED_ATTACHMENT_TYPES,
    payload: {
        typeId,
        hashId,
    },
});

export const removeFromSelectedAttachmentTypes = (typeId, hashId) => ({
    type: REMOVE_FROM_SELECTED_ATTACHMENT_TYPES,
    payload: {
        typeId,
        hashId,
    },
});

export const removeTemplateAttachment = (attachmentId, hashId) => ({
    type: REMOVE_TEMPLATE_ATTACHMENT,
    payload: {
        attachmentId,
        hashId,
    },
});
