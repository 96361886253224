import React from 'react';
import { SkeletonRectangular } from '@profesia/adamui/components/common/skeleton';
import { Stack } from '@mui/material';

const FormSkeleton = ({ itemsCount = 4 }) => (
    <Stack flex={1} p={4} spacing={4.5}>
        {[...Array(itemsCount)].map((e, i) => (
            <Stack flex={1} spacing={3} key={i}>
                <SkeletonRectangular width={110} height={18} borderRadius={26} />

                <Stack flex={1} spacing={2}>
                    {[...Array(3)].map((e, j) => (
                        <SkeletonRectangular height={8} borderRadius={26} key={j} />
                    ))}
                </Stack>
            </Stack>
        ))}
    </Stack>
);

export default FormSkeleton;
