import Form from '../Form';
import DateFormatted from '../DateFormatted';

export default class CandidateGdprAgreementValidatorHelper {
    constructor(candidateGdprAgreement, attachments) {
        this.candidateGdprAgreement = candidateGdprAgreement;
        this.attachments = attachments;
    }

    checkCandidateGdprAgreement(totalFilesSize, checkAgreementConfirmation = false) {
        const fieldsToCheck = [
            {
                fieldName: 'agreementExpirationAt',
                fieldType: 'date',
                fieldValue: this.candidateGdprAgreement.agreementExpirationAt,
            },
            {
                fieldName: 'agreementExpirationAtInFuture',
                fieldType: 'boolean',
                fieldValue:
                    DateFormatted.getTimestamp(this.candidateGdprAgreement.agreementExpirationAt) >
                    DateFormatted.getTimestamp(),
            },
            {
                fieldName: 'attachmentsTotalSize',
                fieldType: 'filesTotalSize',
                fieldValue: this.attachments,
                against: totalFilesSize,
            },
        ];

        if (checkAgreementConfirmation === true) {
            fieldsToCheck.push({
                fieldName: 'agreementConfirmation',
                fieldType: 'boolean',
                fieldValue: this.candidateGdprAgreement.hasConfirmedAgreement === true,
            });
        }

        const errors = Form.multiCheck(fieldsToCheck);

        if (errors.length > 0) {
            throw errors;
        }

        return true;
    }
}
