import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TemplateAssistantTemplateHeader from '../TemplateAssistant/TemplateAssistantTemplateHeader';
import { requestSendEmailModel, updateEmailTemplate } from '../../actions/SendEmailActions';
import {
    saveAsNewEmailTemplate,
    saveAsChangedEmailTemplate,
} from '../../actions/TemplateAssistantActions';
import Form from '../Form';
import Grid from '../Grid';
import Divider from '../Divider';
import Heading from '../Heading';
import LoadingComponent from '../LoadingComponent';
import Translations from '../../helpers/Translations';
import Language from '../../helpers/Language';
import Dropdown from '../Dropdown';
import Constants from '../../helpers/Constants';
import AttachmentAssistantHelper from '../../helpers/components/AttachmentAssistantHelper';
import AttachmentAssistant from '../AttachmentsAssistant/AttachmentsAssistant';
import Variables from '../../helpers/Variables';
import EmailMessageVariablesContainerEditor from '../Email/EmailMessageVariablesContainerEditor';

class SettingsEmailTemplateEditor extends React.Component {
    componentDidMount = () => {
        const predefinedLanguageId = this.props.languages.find(
            (language) => language.languageCode === Language.get()
        ).languageId;

        this.props.requestSendEmailModel(
            this.props.emailTemplateId,
            null,
            [],
            [],
            predefinedLanguageId,
            this.props.isCopy
        );
    };

    componentDidUpdate = (prevProps) => {
        if (
            this.props.sendEmailModel.hasOwnProperty('emailTemplateData') === true &&
            (prevProps.sendEmailModel.hasOwnProperty('emailTemplateData') === false ||
                prevProps.sendEmailModel.emailTemplateData.id !==
                    this.props.sendEmailModel.emailTemplateData.id ||
                prevProps.isCopy === true)
        ) {
            if (
                this.props.sendEmailModel.emailTemplateData.id !== null &&
                prevProps.isCopy === false
            ) {
                prevProps.saveAsChangedEmailTemplate(true);
            } else {
                prevProps.saveAsNewEmailTemplate(true);
            }
        }
    };

    updateEmailTemplateLanguage = (intoLanguage, fromLanguage) => {
        const {
            sendEmailModel: { emailTemplateData, settings },
            languages,
        } = this.props;

        const fromLanguageCode = languages.find(
            (language) => parseInt(language.languageId) === parseInt(fromLanguage)
        ).languageCode;
        const intoLanguageCode = languages.find(
            (language) => parseInt(language.languageId) === parseInt(intoLanguage)
        ).languageCode;

        let text = emailTemplateData.text;
        let subject = emailTemplateData.subject;

        const originalVariables = Variables.getEditorVariables(
            Variables.getVariablesForAction(settings.variables, fromLanguageCode),
            false
        );
        const translatedVariables = Variables.getEditorVariables(
            Variables.getVariablesForAction(settings.variables, intoLanguageCode),
            false
        );

        for (let i = 0; i < originalVariables.length; i++) {
            text = text.replace(originalVariables[i], translatedVariables[i]);
            subject = subject.replace(originalVariables, translatedVariables[i]);
        }

        this.props.updateEmailTemplate({
            languageId: intoLanguage,
            text: text,
            subject: subject,
        });
    };

    render = () => {
        if (
            this.props.isFetchingSendEmailModel === true ||
            this.props.sendEmailModel.hasOwnProperty('emailTemplateData') === false
        ) {
            return <LoadingComponent />;
        }

        const {
            sendEmailModel: { emailTemplateData, settings },
            languages,
            errors,
        } = this.props;

        return (
            <>
                <Form>
                    <Heading as="h3">{Translations.getStatic('emailTemplate')}</Heading>
                    <Grid>
                        <Grid.Row className="horizontal-row">
                            <Grid.Column width={8} verticalAlign="middle">
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <div className="field">
                                                <Dropdown
                                                    label={Translations.getStatic('emailLanguage')}
                                                    defaultValue={emailTemplateData.languageId}
                                                    list={languages.map((language) => ({
                                                        value: language.languageId,
                                                        text: language.language,
                                                    }))}
                                                    selectedItem={(value) =>
                                                        this.updateEmailTemplateLanguage(
                                                            value,
                                                            emailTemplateData.languageId
                                                        )
                                                    }
                                                />
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                            <Grid.Column width={8} />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <TemplateAssistantTemplateHeader
                                    errors={errors}
                                    templateData={emailTemplateData}
                                    templateType={Constants.TEMPLATE_TYPE_EMAIL}
                                    settings={settings}
                                    onUpdateTemplate={this.props.updateEmailTemplate}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
                <Divider />
                <Form>
                    <Heading as="h3">{Translations.getStatic('message')}</Heading>
                    <Grid>
                        <EmailMessageVariablesContainerEditor
                            errors={errors}
                            emailData={emailTemplateData}
                            settings={settings}
                            onUpdateEmailTemplate={this.props.updateEmailTemplate}
                            languageId={emailTemplateData.languageId}
                        />

                        <Grid.Row>
                            <Grid.Column>
                                <AttachmentAssistant
                                    candidateId={0}
                                    templateAttachments={emailTemplateData.attachments}
                                    modelHashId={AttachmentAssistantHelper.createAttachmentAssistantHashIdForEmail()}
                                    isFileUploadOnly={true}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </>
        );
    };
}

SettingsEmailTemplateEditor.propTypes = {
    emailTemplateId: PropTypes.number.isRequired,
    isCopy: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    languages: state.loginManagerModel.languages,
    sendEmailModel: state.sendEmailModel,
    isFetchingSendEmailModel: state.isFetchingSendEmailModel,
});

const mapDispatchToProps = {
    requestSendEmailModel,
    updateEmailTemplate,
    saveAsNewEmailTemplate,
    saveAsChangedEmailTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsEmailTemplateEditor);
