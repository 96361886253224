import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import User from '../../helpers/User';

const InternalInfo = ({ loginManagerModel, internalInfoModel, text = '' }) =>
    internalInfoModel.showInternalInfo === true &&
    User.internalInfoEnabled(loginManagerModel) && (
        <span className="internal-info" title={'Internal info shown to admins or on locals only'}>
            ({text})
        </span>
    );

InternalInfo.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

const mapStateToProps = (state) => ({
    loginManagerModel: state.loginManagerModel,
    internalInfoModel: state.internalInfoModel,
});

export default connect(mapStateToProps, null)(InternalInfo);
