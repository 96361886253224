import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import { closeModal, openModalSecond } from '../../actions/ModalActions';
import { connect } from 'react-redux';
import { jobUncheckAllCandidates } from '../../actions/CandidatesListActions';
import { candidateAddTag } from '../../actions/CandidateActions';
import Translations from '../../helpers/Translations';
import SuccessMessage from '../../helpers/SuccessMessage';
import Arrays from '../../helpers/Arrays';
import AddTags from '../Candidate/AddTags';
import { isFetchingCandidateTag } from '../../reducers/CandidateReducer';

class AddTagsModal extends Component {
    state = {
        errors: [],
        tags: [],
    };

    componentDidUpdate = (prevProps) => {
        if (
            SuccessMessage.canShowSuccessMessage(prevProps, this.props, 'isFetchingCandidateTag') &&
            this.props.candidateActionModel.hasOwnProperty('isSuccess') === true &&
            this.props.candidateActionModel.isSuccess === true
        ) {
            this.props.jobUncheckAllCandidates();
            this.props.closeModal();
        }
    };

    addTags = () => {
        const candidateIds = Arrays.getUniqueArray(this.props.candidateIds);
        const tags = this.state.tags.map((tag) => tag.tagName);

        const errors = [];

        if (tags.length === 0) {
            errors.push('candidateTags');
        }

        if (errors.length === 0) {
            const formData = new FormData();

            formData.append('candidateIds', JSON.stringify(candidateIds));
            formData.append('tags', JSON.stringify(tags));

            this.props.candidateAddTag(candidateIds, formData);
        }

        this.setState({
            errors,
        });
    };

    addTag = (tag) => {
        if (tag.tagId !== null) {
            this.setState({
                tags: Arrays.append(this.state.tags, tag),
                errors: [],
            });
        }
    };

    removeTag = (tagId) => {
        this.setState({
            tags: this.state.tags.filter((tag) => tag.tagId !== tagId),
        });
    };

    closeModal = () => {
        this.props.closeModal();
    };

    render() {
        const { isOpen, isFetchingCandidateTag, isFetchingCandidatesForAction } = this.props;
        const { errors } = this.state;

        const candidateIds = Arrays.getUniqueArray(this.props.candidateIds);

        return (
            <MarkModal
                title={Translations.getStatic('candidateAddTagsModalTitle')}
                isOpen={isOpen}
                closeModal={this.closeModal}
                isLoadingData={
                    isFetchingCandidateTag === true || isFetchingCandidatesForAction === true
                }
                actionButton={{
                    text: Translations.getStatic('save'),
                    disabled: this.props.isFetchingCandidateTag,
                    loading: this.props.isFetchingCandidateTag,
                    onClick: () => this.addTags(),
                }}
                content={
                    <AddTags
                        candidateIds={candidateIds}
                        errors={errors}
                        tags={this.state.tags}
                        onAddTag={(tag) => this.addTag(tag)}
                        onRemoveTag={(tagId) => this.removeTag(tagId)}
                    />
                }
            />
        );
    }
}

AddTagsModal.propTypes = {
    candidateIds: PropTypes.array.isRequired,
    isOpen: PropTypes.bool.isRequired,
};

AddTagsModal.defaultProps = {};

const mapStateToProps = (state) => ({
    isOpen: state.modalLogic.isOpen,
    isFetchingCandidateTag: state.isFetchingCandidateTag,
    isFetchingCandidatesForAction: state.isFetchingCandidatesForAction,
    candidateActionModel: state.candidateActionModel,
    candidateModel: state.candidateModel,
});

const mapDispatchToProps = {
    closeModal,
    openModalSecond,
    jobUncheckAllCandidates,
    candidateAddTag,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTagsModal);
