import React from 'react';
import { connect } from 'react-redux';
import { Link as ReactLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { setTakeMeTo } from '../../actions/TransitionActions';
import Url from '../Url';

const Link = (props) => {
    const handleOnClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (props.hasOwnProperty('onClick') === true) {
            props.onClick();
        }

        if (
            (props?.to ?? '' !== '') &&
            props.to !== Url.getPathname() &&
            props.to !== props.transitionLogic?.takeMeTo
        ) {
            props.setTakeMeTo(props.to);
        }
    };

    const { children } = props;

    const properties = Object.keys(props)
        .filter((key) => ['gtmTag', 'setTakeMeTo', 'transitionLogic'].includes(key) === false)
        .reduce((obj, key) => {
            obj[key] = props[key];

            return obj;
        }, {});

    return (
        <ReactLink {...properties} onClick={handleOnClick}>
            {children && children}
        </ReactLink>
    );
};

Link.propTypes = {
    gtmTag: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    transitionLogic: state.transitionLogic,
});

const mapDispatchToProps = {
    setTakeMeTo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Link);
