import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setCandidateGdprAgreementParams } from '../../actions/ActionWithCandidateActions';
import Form from '../Form';
import Checkbox from '@profesia/adamui/components/common/input/checkbox/Checkbox';
import Message from '../Message';
import Grid from '../Grid';
import Divider from '../Divider';
import Icon from '../Icon';
import Translations from '../../helpers/Translations';
import AttachmentAssistantHelper from '../../helpers/components/AttachmentAssistantHelper';
import AttachmentAssistant from '../AttachmentsAssistant/AttachmentsAssistant';
import DatetimePicker from '../DatetimePicker';
import NotificationMessage from '../NotificationMessage';
import DateFormatted from '../../helpers/DateFormatted';
import Validation from '../../helpers/Validation';

class CandidateGdprAgreement extends React.Component {
    componentDidMount() {
        if (this.props.gdprAgreement !== null) {
            this.props.setCandidateGdprAgreementParams({
                hasConfirmedAgreement:
                    this.props.gdprAgreement.hasOwnProperty('hasConfirmedAgreement') === true
                        ? this.props.gdprAgreement.hasConfirmedAgreement
                        : this.props.candidateActionModel.candidateGdprAgreementParams
                              .hasConfirmedAgreement,
                agreementExpirationAt:
                    this.props.gdprAgreement.hasOwnProperty('agreementExpirationAt') === true
                        ? this.props.gdprAgreement.agreementExpirationAt
                        : this.props.candidateActionModel.candidateGdprAgreementParams
                              .agreementExpirationAt,
            });
        }
    }

    render = () => {
        const { errors, candidateActionModel, moveCandidate, jobTitle } = this.props;
        const { candidateGdprAgreementParams } = candidateActionModel;
        const { hasConfirmedAgreement, agreementExpirationAt } = candidateGdprAgreementParams;

        return (
            <>
                <Form>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <NotificationMessage
                                    title={Translations.getStatic('personalDataProcessing')}
                                    description={
                                        moveCandidate === true
                                            ? Translations.getStatic(
                                                  'candidateGdprAgreementMoveExplanationText'
                                              ).replace('%jobTitle%', jobTitle)
                                            : Translations.getStatic(
                                                  'candidateGdprAgreementManualExplanationText'
                                              )
                                    }
                                />
                            </Grid.Column>
                        </Grid.Row>

                        <Divider hidden />

                        <Grid.Row columns={1}>
                            <Grid.Column>
                                <Form.Field width={8}>
                                    <label htmlFor={'candidateGdprAgreementExpirationDate'}>
                                        {Translations.getStatic('date')}
                                    </label>
                                    <DatetimePicker
                                        id="candidateGdprAgreementExpirationDate"
                                        name="candidateGdprAgreementExpirationDate"
                                        error={
                                            errors.includes('agreementExpirationAt') === true ||
                                            errors.includes('agreementExpirationAtInFuture') ===
                                                true
                                                ? Validation.getMessage('date')
                                                : false
                                        }
                                        placeholder={DateFormatted.getDate(
                                            agreementExpirationAt,
                                            'Y-m-d'
                                        )}
                                        defaultValue={DateFormatted.getDate(
                                            agreementExpirationAt,
                                            'Y-m-d'
                                        )}
                                        timeEnabled={false}
                                        allowDatesInPast={true}
                                        onChange={(dateFrom) =>
                                            this.props.setCandidateGdprAgreementParams({
                                                agreementExpirationAt: dateFrom,
                                            })
                                        }
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={1}>
                            <Grid.Column>
                                <Form.Field>
                                    <AttachmentAssistant
                                        candidateId={0}
                                        modelHashId={AttachmentAssistantHelper.createAttachmentAssistantHashIdForFileUpload()}
                                        isFileUploadOnly={true}
                                    />

                                    {errors.includes('attachmentsTotalSize') === true ? (
                                        <Message icon negative>
                                            <Icon className="icon-exclamation" size="mini" />
                                            <Message.Content>
                                                {Translations.getStatic(
                                                    'totalFileSizeUploadLimitExceeded'
                                                )}
                                                .
                                            </Message.Content>
                                        </Message>
                                    ) : null}
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={1}>
                            <Grid.Column>
                                <Form.Field>
                                    <Checkbox
                                        label={Translations.getStatic(
                                            'candidateGdprAgreementManualCheckboxLabel'
                                        )}
                                        id="agreementConfirmation"
                                        className={
                                            errors.includes('agreementConfirmation') ? 'error' : ''
                                        }
                                        checked={hasConfirmedAgreement}
                                        onChange={(e) =>
                                            this.props.setCandidateGdprAgreementParams({
                                                hasConfirmedAgreement: e.target.checked,
                                            })
                                        }
                                    />

                                    {errors.includes('agreementConfirmation') === true && (
                                        <Message icon negative>
                                            <Icon className="icon-exclamation" size="mini" />
                                            <Message.Content>
                                                {Validation.getMessage('agreement')}
                                            </Message.Content>
                                        </Message>
                                    )}
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </>
        );
    };
}

CandidateGdprAgreement.propTypes = {
    candidateId: PropTypes.number.isRequired,
    gdprAgreement: PropTypes.object,
    moveCandidate: PropTypes.bool,
    jobTitle: PropTypes.string,
};

CandidateGdprAgreement.defaultProps = {
    gdprAgreement: null,
    errors: [],
    moveCandidate: false,
    jobTitle: '',
};

const mapStateToProps = (state) => ({
    candidateActionModel: state.candidateActionModel,
});

const mapDispatchToProps = {
    setCandidateGdprAgreementParams,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateGdprAgreement);
