import React from 'react';
import Translations from '../../helpers/Translations';
import MarkDialog from '../Modal/MarkDialog';
import { connect } from 'react-redux';
import { closeAllRecipientsWithoutEmailsDialog } from '../../actions/DialogActions';

const AllRecipientsWithoutEmailsDialog = (props) =>
    props.allRecipientsWithoutEmailsDialog.open === true ? (
        <MarkDialog
            closeOnDimmerClick={false}
            message={{
                iconName: 'icon-permission',
                title: Translations.getStatic('allRecipientsWithoutEmailsDialogTitle'),
                infoLines: [Translations.getStatic('allRecipientsWithoutEmailsDialogText')],
            }}
            actions={[
                {
                    title: Translations.getStatic('cancel'),
                    action: this.props.closeAllRecipientsWithoutEmailsDialog,
                },
            ]}
        />
    ) : null;

const mapStateToProps = (state) => ({
    allRecipientsWithoutEmailsDialog: state.allRecipientsWithoutEmailsDialog,
});

const mapDispatchToProps = {
    closeAllRecipientsWithoutEmailsDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllRecipientsWithoutEmailsDialog);
