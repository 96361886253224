import React from 'react';
import Translations from '../../../../../helpers/Translations';

interface ModalHeaderProps {
    onClose: () => void;
    stageName: string;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ onClose, stageName }) => (
    <div className="template-header header-extended_light">
        <div className="header__row">
            <div className="col-left">
                <div className="square-button">
                    <div role="link" onClick={onClose}>
                        {Translations.getStatic('close')}
                    </div>
                </div>
                <div className="nav-grid flex align center">
                    <div className="nav-grid__title">
                        <h3 className="ellipsis">{stageName}</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default ModalHeader;
