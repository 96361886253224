import { useEffect, useState } from 'react';
import { AutocompleteItem } from '@profesia/adamui/components/common/input/types';
import { Groups } from '@profesia/adamui/components/common/treeList/tagFieldAdapter/types';
import { Languages } from '@profesia/mark-offer-form/i18n';
import Url from '../../../../../assets/jsx/helpers/Url';
import Constants from '../../../helpers/Constants';
import Language from '../../../helpers/Language';
import { OptionItem, PositionItem } from './types';

export const remapOptionItem = (options: OptionItem[]) =>
    options.map(({ id, name }) => ({ id: id.toString(), value: name }));

export const remapPositions = (positions: PositionItem[]): any => {
    const mappedPositions: AutocompleteItem[] = [];
    const groups: Groups<string> = {};

    positions.forEach(({ id, value, categoryId, categoryName }) => {
        const categoryIdString = categoryId.toString();

        mappedPositions.push({ id: id.toString(), value, categoryId: categoryIdString });

        if (!groups[categoryIdString]) {
            groups[categoryIdString] = { name: categoryName, count: 1, startIndex: 0 };
        } else {
            groups[categoryIdString].count += 1;
        }
    });

    return {
        positions: mappedPositions,
        groups: groups,
    };
};

export function getTranslations(language: string): Promise<{ [key: string]: any }> {
    switch (language) {
        case Constants.LANGUAGE_SK:
            return import('../../../translations/jobAdvertisement/sk.json');

        case Constants.LANGUAGE_CS:
            return import('../../../translations/jobAdvertisement/cs.json');

        case Constants.LANGUAGE_HU:
            return import('../../../translations/jobAdvertisement/hu.json');

        case Constants.LANGUAGE_DE:
            return import('../../../translations/jobAdvertisement/de.json');

        case Constants.LANGUAGE_EN:
        default:
            return import('../../../translations/jobAdvertisement/en.json');
    }
}

export const useTranslations = () => {
    const currentLanguage: Languages = Language.get() as Languages;
    const [translations, setTranslations] = useState(null);

    useEffect(() => {
        const loadTranslations = async () => {
            const asyncTranslations = await getTranslations(currentLanguage);
            setTranslations(JSON.parse(JSON.stringify(asyncTranslations)));
        };

        loadTranslations();
    }, [currentLanguage]);

    return translations;
};

export const stripBase64Prefix = (base64String: string | null | undefined) => {
    if (!base64String) return null;
    return base64String.replace(/^data:image\/[a-z]+;base64,/, "");
};

export const isOrderStepEnabled = !Url.isOnlineOrMaster();
export const isSummaryStepEnabled = !Url.isOnlineOrMaster();
