import React from 'react';
import PropTypes from 'prop-types';
import Form from '../Form';
import Checkbox from '@profesia/adamui/components/common/input/checkbox/Checkbox';
import AttachmentAssistantHelper from '../../helpers/components/AttachmentAssistantHelper';

const MultiAttachmentAssistantTypesSelector = (props) => {
    const { settings, selectedAttachmentTypes } = props;

    if (selectedAttachmentTypes.length === 0) {
        return null;
    }

    const dropdownItemsTitles = AttachmentAssistantHelper.getAttachmentTypeLabels();

    let checkboxList = [];

    settings.attachmentTypes.forEach((attachmentType) => {
        const selectedType = selectedAttachmentTypes.find(
            (type) => type.typeId === attachmentType.id
        );

        if (selectedType !== undefined) {
            checkboxList.push(
                <Form.Field key={attachmentType.id} className="padding-bottom_none">
                    <Checkbox
                        onChange={() =>
                            props.onHandleAttachmentTypeCheckboxChange(
                                attachmentType.id,
                                !selectedType.selected
                            )
                        }
                        checked={selectedType.selected}
                        label={dropdownItemsTitles[attachmentType.id]}
                    />
                </Form.Field>
            );
        }
    });

    return checkboxList;
};

MultiAttachmentAssistantTypesSelector.propTypes = {
    selectedAttachmentTypes: PropTypes.array.isRequired,
    selectedAttachmentTypesSizes: PropTypes.array.isRequired,
    settings: PropTypes.object.isRequired,
    onHandleAttachmentTypeCheckboxChange: PropTypes.func.isRequired,
};

export default MultiAttachmentAssistantTypesSelector;
