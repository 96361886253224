import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import Language from '../../../../helpers/Language';
import Url from '../../../../helpers/Url';
import Translations from '../../../../helpers/Translations';
import loginManagerModelSkeleton from '../../../../skeletons/models/loginManagerModelSkeleton';
import Skeletons from '../../../../helpers/Skeletons';
import Constants from '../../../../helpers/Constants';

class FooterMenu extends React.Component {
    setLanguage = (language) => {
        Language.set(language);
        Url.reload('Language changed - reloading to apply');
    };

    render() {
        const { languages } = this.props;
        const currLanguage = Language.get();

        const technicalSpecificationsLink = [Constants.LANGUAGE_SK, Constants.LANGUAGE_CS].includes(
            currLanguage
        )
            ? 'https://intercom.help/profesia-1e3213bf0a3a/cs/articles/9767256-profesia-technicka-specifikacia-a-zaistenie-bezpecnosti'
            : 'https://intercom.help/profesia-1e3213bf0a3a/cs/articles/9766688-profesia-technical-specifications-and-security-assurance-en';

        return (
            <div className="row">
                <div className="col col-8">
                    <ul className="menu footer-menu">
                        <li>
                            <NavLink to={Url.build('articles', { article: 'copyright' })}>
                                {Translations.getStatic('secondaryNavCopyright')}
                            </NavLink>
                        </li>
                        <li>
                            <a href="https://www.almacareer.com/legal/sk/klienti">
                                {Translations.getStatic('secondaryNavVop')}
                            </a>
                        </li>
                        <li>
                            <a href="https://www.almacareer.com/gdpr">
                                {Translations.getStatic('secondaryNavPrivacy')}
                            </a>
                        </li>
                        <li>
                            <NavLink to={Url.build('contact')}>
                                {Translations.getStatic('secondaryNavContact')}
                            </NavLink>
                        </li>
                        <li>
                            <a href={technicalSpecificationsLink}>
                                {Translations.getStatic('technicalSpecification')}
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="col col-4 align right footer-language">
                    {languages !== undefined ? (
                        <Dropdown
                            icon={{ className: 'none' }}
                            text={
                                languages.find((language) => language.languageCode === currLanguage)
                                    .language
                            }
                        >
                            <Dropdown.Menu>
                                {languages.map((language) => (
                                    <Dropdown.Item
                                        key={language.languageId}
                                        text={language.language}
                                        value={language.languageCode}
                                        onClick={(_e, { value }) => this.setLanguage(value)}
                                    />
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    ) : (
                        <span className="text">{currLanguage}</span>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    languages: Skeletons.getData(
        state.loginManagerModel,
        loginManagerModelSkeleton
    ).languages.filter((language) => language.enabled === true),
});

FooterMenu.propTypes = {
    languages: PropTypes.arrayOf(
        PropTypes.shape({
            languageId: PropTypes.number.isRequired,
            language: PropTypes.string.isRequired,
            languageCode: PropTypes.string.isRequired,
        })
    ),
};

export default connect(mapStateToProps, null)(FooterMenu);
