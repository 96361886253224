import Constants from '../Constants';
import md5 from 'md5';
import Translations from '../Translations';

export default class QuestionnaireHelper {
    static createNewQuestion(type) {
        let answers = [];
        let question = '';
        const emptyAnswer = this.createNewAnswer();

        switch (type) {
            case Constants.QUESTIONNAIRE_QUESTION_TYPE_ONE:
            case Constants.QUESTIONNAIRE_QUESTION_TYPE_MORE:
                answers = [emptyAnswer, emptyAnswer];
                break;
            case Constants.QUESTIONNAIRE_QUESTION_TYPE_TEXT:
            case Constants.QUESTIONNAIRE_QUESTION_TYPE_LONGTEXT:
                answers = [];
                break;
            case Constants.QUESTIONNAIRE_QUESTION_TYPE_SELECTBOX:
                answers = [emptyAnswer, emptyAnswer, emptyAnswer];
                break;
            case Constants.QUESTIONNAIRE_QUESTION_TYPE_SALARY:
                answers = [];
                question = Translations.getStatic('expectedSalaryQuestionText');
                break;
            case Constants.QUESTIONNAIRE_QUESTION_TYPE_DISPOSABLE_DATE:
                answers = [];
                question = Translations.getStatic('disposableDateQuestionText');
                break;
        }

        return {
            id: null,
            question: question,
            type: type,
            answers: answers,
        };
    }

    static createNewAnswer() {
        return {
            answer: '',
            score: 0,
            id: null,
            updated: false,
        };
    }

    static addAnswerIntoQuestion(question, answer) {
        let newQuestion = Object.assign({}, question);
        newQuestion.answers.push(answer);
        return newQuestion;
    }

    static replaceAnswerInQuestion(question, answerId, newAnswer) {
        return Object.assign({}, question, {
            answers: question.answers.map((answer, id) => {
                return id === answerId ? newAnswer : answer;
            }),
        });
    }

    static moveElementInArray(data, fromIndex, toIndex) {
        if (toIndex >= data.length) {
            let k = toIndex - data.length;
            while (k-- + 1) {
                data.push(undefined);
            }
        }

        data.splice(toIndex, 0, data.splice(fromIndex, 1)[0]);

        return data;
    }

    static createChangeHash(questions) {
        return md5(JSON.stringify(questions));
    }

    static getAnswerScorePoints() {
        const scorePoints = [];

        for (let i = 0; i <= 10; i++) {
            scorePoints.push({
                key: i,
                id: i,
                label: i,
            });
        }

        scorePoints.push({
            key: 'requiredAnswer',
            id: Constants.QUESTIONNAIRE_ANSWER_VALUE_REQUIRED,
            label: '*' + Translations.getStatic('required'),
            disabled: false,
        });

        return scorePoints;
    }

    static getDefaultScoreValue(answer) {
        if (answer.hasOwnProperty('isRequired') === true && answer.isRequired === true) {
            return Constants.QUESTIONNAIRE_ANSWER_VALUE_REQUIRED;
        }

        if (answer.hasOwnProperty('score') === true) {
            return answer.score;
        }

        return 0;
    }
}
