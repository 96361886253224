import { toast } from 'react-semantic-toasts';
import ToastMessages from '../helpers/ToastMessages';
import Chunk from '../helpers/Chunk';
import Arrays from '../helpers/Arrays';
import ToastMessageUpdate from '../components/ToastMessageUpdate';
import React from 'react';
import Constants from '../helpers/Constants';

const showToastMessage = (type, text) => {
    const description =
        type === 'error' ? (
            <ToastMessageUpdate
                message={text}
                userActions={[Constants.USER_ACTION_OK]}
                type={type}
            />
        ) : (
            text
        );

    const time =
        type === 'error'
            ? Constants.WEBSOCKET_TIME_DO_NOT_CLOSE
            : Constants.WEBSOCKET_TIME_CLOSE_AUTOMATICALLY;

    setTimeout(() => {
        toast({
            type,
            color: ToastMessages.getColorByMessageType(type),
            title: '',
            description: description,
            animation: 'fade left',
            time,
            size: 'small',
        });
    }, 400);
};

const toastMessagesMiddleware = (store) => (next) => (action) => {
    let nextAction = { ...action };

    if (
        action.hasOwnProperty('payload') === true &&
        action.payload.hasOwnProperty('toastMessages') === true
    ) {
        const toastMessages = action.payload.toastMessages;

        if (toastMessages.length > 0) {
            let conditionalMessages = [];
            const standardMessages = [];

            toastMessages.forEach((message) => {
                if (message.text !== null) {
                    standardMessages.push(message);
                } else if (
                    message.hasOwnProperty('conditionalMessages') === true &&
                    message.conditionalMessages.length > 0
                ) {
                    conditionalMessages = conditionalMessages.concat(message.conditionalMessages);
                }
            });

            if (standardMessages.length > 0) {
                Arrays.getUniqueArrayOfObjects(standardMessages, 'text').forEach((message) =>
                    showToastMessage(message.type, message.text)
                );
            }

            if (conditionalMessages.length > 0) {
                conditionalMessages = Chunk.getToastMessagesFromActionByCondition(
                    action,
                    conditionalMessages
                );
                conditionalMessages = Arrays.getUniqueArrayOfObjects(conditionalMessages, 'key');

                conditionalMessages.forEach((conditionalMessage) =>
                    showToastMessage(conditionalMessage.type, conditionalMessage.text)
                );
            }
        }

        delete nextAction.payload.toastMessages;
    }

    return next(nextAction);
};

export default toastMessagesMiddleware;
