import Authentication from './Authentication';
import User from './User';
import { GdprManagerModelType } from '../../interfaces/GdprManagerModelType';
import { LoginManagerModelType } from '../../interfaces/LoginManagerModelType';

class Gdpr {
    static needGdprAgreement(
        gdprManagerModel: GdprManagerModelType,
        loginManagerModel: LoginManagerModelType,
        action: string
    ): boolean {
        if (User.isExternalUser(loginManagerModel)) {
            return false;
        }

        const hasCompanyGdprAgreement: boolean =
            gdprManagerModel.hasOwnProperty('hasCompanyGdprAgreement') &&
            gdprManagerModel.hasCompanyGdprAgreement;

        if (hasCompanyGdprAgreement) {
            return false;
        }

        return !Authentication.isGdprPublicAction(action);
    }
}

export default Gdpr;
