import 'isomorphic-fetch';
import Ajax from './../helpers/Ajax';
import Time from './../helpers/Time';
import GoogleAnalytics from './../helpers/GoogleAnalytics';
import User from '../helpers/User';
import Log from '../helpers/Log';
import Constants from '../helpers/Constants';
import { resetChunkStatus, updateChunkStatus } from '../actions/ChunkStatusActions';
import { clearCancellation } from '../actions/ChunkCancellationActions';
import Chunk from '../helpers/Chunk';
import AbortControllerHelper from '../helpers/AbortControllerHelper';

const fetchCandidatesInChunksMiddleware = (store) => (next) => (action) => {
    if (
        action.hasOwnProperty('meta') === false ||
        (action.meta.hasOwnProperty('requestMethod') === true &&
            action.meta.requestMethod !== 'GET')
    ) {
        return next(action);
    }

    if (
        action.hasOwnProperty('payload') === false ||
        action.payload.hasOwnProperty('candidateIds') === false
    ) {
        return next(action);
    }

    if (action.payload.candidateIds.length <= Constants.CANDIDATES_CHUNK_SIZE) {
        return next(action);
    }

    if (
        action.hasOwnProperty('meta') === true &&
        (action.meta.hasOwnProperty('requestData') === true ||
            action.meta.hasOwnProperty('requestRoute') === true)
    ) {
        const chunksCountToFetch = Math.ceil(
            action.payload.candidateIds.length / Constants.CANDIDATES_CHUNK_SIZE
        );

        const chunks = [];

        for (let i = 0; i < chunksCountToFetch; i++) {
            chunks.push({
                ...action.meta,
                type: action.type,
                payload: action.hasOwnProperty('payload') ? action.payload : {},

                requestData: {
                    ...action.meta.requestData,
                    candidateIds: JSON.stringify(
                        action.payload.candidateIds.slice(
                            i * Constants.CANDIDATES_CHUNK_SIZE,
                            (i + 1) * Constants.CANDIDATES_CHUNK_SIZE
                        )
                    ),
                },
            });
        }

        fetchChunks(store, chunks);
    }

    return next(action);
};

const fetchChunks = (store, chunks, chunkIndex = 0, finalResult = {}) => {
    if (chunkIndex === 0) {
        store.dispatch(resetChunkStatus());
    }

    if (store.getState().chunkCancellation.isCancelled === true) {
        store.dispatch(updateChunkStatus(1, 1));
        store.dispatch(clearCancellation());
    } else {
        const chunk = chunks[chunkIndex];
        const requestMethod =
            chunk.hasOwnProperty('requestMethod') === true ? chunk.requestMethod : 'GET';
        const requestBody = chunk.hasOwnProperty('requestBody') === true ? chunk.requestBody : '';

        const isLastChunk = chunkIndex + 1 === chunks.length;

        const startTime = Time.getTime();

        fetch(
            Ajax.getFetchLink(chunk),
            Ajax.getFetchParams(requestMethod, requestBody, AbortControllerHelper.getSignal())
        )
            .then((response) => {
                const actionDuration = Time.getTime(startTime, 'milliseconds', 2);

                if (User.consoleLogEnabled(store.getState().loginManagerModel) === true) {
                    Log.time(
                        'AJAX request for ' +
                            chunk.type +
                            '_' +
                            (chunkIndex + 1) +
                            ' performed in ' +
                            actionDuration +
                            ' ms'
                    );
                }

                GoogleAnalytics.sendActionDuration(
                    'Chunk Fetch Actions',
                    chunk.type,
                    actionDuration
                );

                return response.json();
            })
            .then((chunkResult) => {
                finalResult = Chunk.mergeResults(finalResult, chunkResult, isLastChunk);

                store.dispatch(updateChunkStatus(chunkIndex + 1, chunks.length));

                if (isLastChunk === true) {
                    store.dispatch({
                        type: chunk.onRequestSuccess,

                        payload: {
                            ...finalResult,
                            chunked: true,
                        },

                        meta: {
                            requestPayload: chunk.payload,
                        },

                        error: false,
                    });
                } else {
                    return fetchChunks(store, chunks, chunkIndex + 1, finalResult);
                }
            });
    }
};

export default fetchCandidatesInChunksMiddleware;
