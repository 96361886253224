export const REQUEST_STATISTICS_MODEL = 'REQUEST_STATISTICS_MODEL';
export const RECEIVE_STATISTICS_MODEL = 'RECEIVE_STATISTICS_MODEL';
export const REQUEST_STATISTICS_MONTHLY_DATA = 'REQUEST_STATISTICS_MONTHLY_DATA';
export const RECEIVE_STATISTICS_MONTHLY_DATA = 'RECEIVE_STATISTICS_MONTHLY_DATA';

export const requestStatisticsModel = () => ({
    type: REQUEST_STATISTICS_MODEL,
    meta: {
        requestRoute: 'v1/statistics/model',
        onRequestSuccess: RECEIVE_STATISTICS_MODEL,
    },
});

export const requestStatisticsMonthlyData = (year, month) => ({
    type: REQUEST_STATISTICS_MONTHLY_DATA,
    meta: {
        requestRoute: `v1/statistics/data/month/${year}/${month}`,
        onRequestSuccess: RECEIVE_STATISTICS_MONTHLY_DATA,
    },
});
