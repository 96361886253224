import React, { Component } from 'react';
import { connect } from 'react-redux';
import DetailCandidateNoModalTabs from '../Candidate/DetailCandidateNoModalTabs';
import DetailCandidateNoModalContent from '../Candidate/DetailCandidateNoModalContent';
import { Dropdown } from 'semantic-ui-react';
import Icon from '../Icon';
import {
    requestCandidateModel,
    requestCandidateEvents,
    requestCandidateHistory,
    setCandidateAsFavorite,
    unsetCandidateAsFavorite,
    requestCandidateInbox,
    storeCandidatePhoneCallIntoHistory,
} from '../../actions/CandidateActions';
import {
    requestJobModel,
    resetFullCandidatesData,
    setIsClickedCandidate,
} from '../../actions/JobDetailActions';
import { closeNoModal, openModal, closeNoModalSecond } from '../../actions/ModalActions';
import PropTypes from 'prop-types';
import Constants from '../../helpers/Constants';
import { anonymizeCandidates } from '../../actions/ActionWithCandidateActions';
import SuccessMessage from '../../helpers/SuccessMessage';
import CandidatesHelper from '../../helpers/components/CandidatesHelper';
import Translations from '../../helpers/Translations';
import InternalInfo from '../InternalInfo/InternalInfo';
import JobHelper from '../../helpers/components/JobHelper';
import AnonymizeCandidatesDialog from '../Dialogs/AnonymizeCandidatesDialog';
import User from '../../helpers/User';
import {
    openCopyIntoTalentPoolDialog,
    openRemoveFromTalentPoolDialog,
} from '../../actions/DialogActions';
import Url from '../../helpers/Url';
import Form from '../../helpers/Form';
import Popup from '../Popup';
import DetailCandidateNoModalContentSkeleton from '../../skeletons/components/DetailCandidateNoModalContentSkeleton';
import Skeletons from '../../helpers/Skeletons';
import loginManagerModalSkeleton from '../../skeletons/models/loginManagerModelSkeleton';
import UserRights from '../../helpers/UserRights';
import BadgeNewCandidate from '../Job/components/Pipeline/BadgeNewCandidate';

class DetailCandidateNoModal extends Component {
    state = {
        activeMenuItem:
            this.props.noModalProps !== undefined &&
            this.props.noModalProps.hasOwnProperty('openParams') === true &&
            this.props.noModalProps.openParams.hasOwnProperty('tab') === true
                ? this.props.noModalProps.openParams.tab
                : 'attachments',
        dropdownActive: false,
        anonymizeCandidateDialogOpen: false,
        wasAccepted: false,
        sendAnonymizeConfirmationToCandidate: false,
    };

    componentDidMount() {
        this.requestCandidateData(this.props.candidateId);
    }

    componentDidUpdate(prevProps, prevState, snapShot) {
        if (prevProps.candidateId !== this.props.candidateId) {
            this.requestCandidateData(this.props.candidateId);
        }

        if (
            prevProps.candidateModel.hasOwnProperty('candidate') &&
            this.props.candidateModel.hasOwnProperty('candidate')
        ) {
            if (
                prevProps.candidateModel.candidate.stateId === Constants.CANDIDATE_STATE_ACTIVE &&
                (this.props.candidateModel.candidate.stateId ===
                    Constants.CANDIDATE_STATE_REFUSED ||
                    this.props.candidateModel.candidate.stateId ===
                        Constants.CANDIDATE_STATE_DELETED)
            ) {
                if (
                    this.props.candidateActionModel.hasOwnProperty('isSuccess') === true &&
                    this.props.candidateActionModel.isSuccess === true
                ) {
                    this.props.closeNoModal();

                    if (
                        this.props.noModalSecondLogic.hasOwnProperty('noModalSecondIsOpen') ===
                            true &&
                        this.props.noModalSecondLogic.noModalSecondIsOpen === true
                    ) {
                        this.props.closeNoModalSecond();
                    }
                }
            }
        }

        if (
            SuccessMessage.canShowSuccessMessage(
                prevProps,
                this.props,
                'isAnonymizingCandidates'
            ) &&
            this.props.candidateActionModel.hasOwnProperty('isSuccess') === true &&
            this.props.candidateActionModel.isSuccess === true
        ) {
            this.props.closeNoModal();

            if (
                this.props.noModalSecondLogic.hasOwnProperty('noModalSecondIsOpen') === true &&
                this.props.noModalSecondLogic.noModalSecondIsOpen === true
            ) {
                this.props.closeNoModalSecond();
            }
        }

        if (
            SuccessMessage.canShowSuccessMessage(prevProps, this.props, 'isMovingCandidates') &&
            this.props.candidateActionModel.hasOwnProperty('isSuccess') === true &&
            this.props.candidateActionModel.isSuccess === true
        ) {
            this.props.closeNoModal();
        }
    }

    requestCandidateData = (candidateId) => {
        this.props.requestCandidateModel(candidateId);
        this.props.requestCandidateEvents(candidateId);
        this.props.requestCandidateHistory(candidateId);
        this.props.requestCandidateInbox(candidateId);
    };

    handleMenuItemClick = (itemId) => this.setState({ activeMenuItem: itemId });

    openAnonymizeCandidateDialog = () => this.setState({ anonymizeCandidateDialogOpen: true });

    closeAnonymizeCandidateDialog = () => this.setState({ anonymizeCandidateDialogOpen: false });

    anonymizeCandidate = (candidateId) => {
        const actionData = new FormData();

        actionData.append('candidateIds', JSON.stringify([candidateId]));
        actionData.append(
            'sendConfirmation',
            this.state.sendAnonymizeConfirmationToCandidate === true ? 1 : 0
        );

        this.props.anonymizeCandidates(actionData, [candidateId]);
    };

    toggleActiveClass = () => {
        this.setState({
            dropdownActive: !this.state.dropdownActive,
        });
    };

    render() {
        const {
            candidateModel: { candidate, events, inbox },
            noModalIsOpen,
            jobStateId,
            loginManagerModel,
            jobModel,
            displaySize,
            candidateId,
            phone,
            isFetchingCandidateInbox,
        } = this.props;

        if (Skeletons.isSkeleton(loginManagerModel) === true) {
            return null;
        }

        let { candidateName, attachmentsCount, questionnaireResult, hasQuestionnaireResult } =
            this.props;

        const { activeMenuItem, dropdownActive } = this.state;

        const currentBasicCandidateData = CandidatesHelper.getCurrentBasicCandidateData(this.props);

        const fullCandidate = jobModel.fullCandidates?.filter(
            (candidate) => candidate.id === this.props.candidateId
        )[0];

        if (Object.keys(currentBasicCandidateData).length > 0) {
            candidateName = currentBasicCandidateData.candidateName;
            attachmentsCount = currentBasicCandidateData.attachmentsCount;
            questionnaireResult = currentBasicCandidateData.questionnaireResult;
            hasQuestionnaireResult = currentBasicCandidateData.hasQuestionnaireResult;
        }

        const isExternalVisitor = User.isExternalVisitor(loginManagerModel, jobModel);

        const jobClosed = JobHelper.isClosed(candidate ? candidate.jobStateId : jobStateId);
        const visibility = noModalIsOpen === true ? 'show' : 'hide';
        const isPhoneValid =
            typeof phone !== 'string' || phone.trim() === ''
                ? false
                : Form.check({ fieldValue: phone, fieldType: 'phone' });

        const hasJobQuestionnaire =
            this.props.candidateModel.hasOwnProperty('settings') === true &&
            this.props.candidateModel.settings.hasOwnProperty('hasJobQuestionnaire') === true &&
            this.props.candidateModel.settings.hasJobQuestionnaire === true;

        const favoriteIcon =
            candidate !== undefined && candidate.isFavorite === true ? (
                <Popup
                    position="right"
                    content={Translations.getStatic('favoriteCandidate')}
                    trigger={
                        <Icon
                            onClick={() => this.props.unsetCandidateAsFavorite(candidateId)}
                            className="icon-favorite-candidate"
                            size="mini"
                        />
                    }
                />
            ) : (
                <Popup
                    position="right"
                    content={Translations.getStatic('setAsFavorite')}
                    trigger={
                        <Icon
                            onClick={() => this.props.setCandidateAsFavorite(candidateId)}
                            className="icon-favorite-candidate-shape"
                            size="mini"
                        />
                    }
                />
            );

        return (
            <div className={'noModal noModal-wrapper ' + visibility}>
                <div className="template-header header-extended_light">
                    <div className="header__row">
                        <div className="col-left">
                            <div className="square-button square-button-close">
                                <div role="link" onClick={this.props.closeNoModal}>
                                    {Translations.getStatic('close')}
                                </div>
                            </div>
                            <div
                                className={
                                    'nav-grid' +
                                    (displaySize.width < Constants.WIDTH_NO_MODAL
                                        ? ' flex column justify center'
                                        : '')
                                }
                            >
                                <div className="nav-grid__title">
                                    <h3 className="ellipsis">
                                        {candidateName} {favoriteIcon}
                                        <InternalInfo text={candidateId} />
                                    </h3>
                                </div>
                                {displaySize.width >= Constants.WIDTH_NO_MODAL && (
                                    <DetailCandidateNoModalTabs
                                        hasJobQuestionnaire={hasJobQuestionnaire}
                                        activeMenuItem={activeMenuItem}
                                        attachmentsCount={attachmentsCount}
                                        hasQuestionnaireResult={hasQuestionnaireResult}
                                        questionnaireResult={questionnaireResult}
                                        handleMenuItemClick={this.handleMenuItemClick}
                                        candidateInbox={inbox}
                                        isFetchingCandidateInbox={isFetchingCandidateInbox}
                                        hasCompanyActiveInbox={
                                            loginManagerModel.hasCompanyActiveInbox
                                        }
                                        isExternalVisitor={isExternalVisitor}
                                    />
                                )}
                            </div>
                        </div>
                        {isExternalVisitor === false && (
                            <div className="col-right">
                                {fullCandidate && candidate?.isClicked !== undefined && (
                                    //TODO convert to less
                                    <div style={{ marginTop: -18, marginRight: 16 }}>
                                        {
                                            <BadgeNewCandidate
                                                isClicked={fullCandidate.isClicked}
                                                variant="detail"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.props.setIsClickedCandidate(
                                                        candidate.id,
                                                        !fullCandidate.isClicked
                                                    );
                                                }}
                                            />
                                        }
                                    </div>
                                )}

                                {jobClosed === false &&
                                    displaySize.width >= Constants.WIDTH_NO_MODAL && (
                                        <div className={'square-button square-button_email icon'}>
                                            <div
                                                role="link"
                                                onClick={() =>
                                                    this.props.openModal('SendEmailModal', {
                                                        candidateIds: [candidateId],
                                                        emailType:
                                                            Constants.EMAIL_TYPE_TO_CANDIDATE,
                                                    })
                                                }
                                            >
                                                {Translations.getStatic('writeEmail')}
                                            </div>
                                        </div>
                                    )}
                                {displaySize.width >= Constants.WIDTH_NO_MODAL && (
                                    <>
                                        <div className={'square-button square-button_forward icon'}>
                                            <div
                                                role="link"
                                                onClick={() =>
                                                    this.props.openModal('SendEmailModal', {
                                                        candidateIds: [candidateId],
                                                        emailType:
                                                            Constants.EMAIL_TYPE_TO_COLLEAGUE,
                                                    })
                                                }
                                            >
                                                {Translations.getStatic('forward')}
                                            </div>
                                        </div>

                                        {isPhoneValid === true && (
                                            <div
                                                className={'square-button square-button_call icon'}
                                                onClick={() =>
                                                    this.props.storeCandidatePhoneCallIntoHistory(
                                                        candidateId
                                                    )
                                                }
                                            >
                                                <a href={`tel:${phone}`}>
                                                    {Translations.getStatic('call')}
                                                </a>
                                            </div>
                                        )}
                                    </>
                                )}

                                <div
                                    onClick={this.toggleActiveClass}
                                    className={
                                        'dropdown-menu square-button ' +
                                        (dropdownActive === true ? 'active' : 'inactive')
                                    }
                                >
                                    <Dropdown
                                        fluid
                                        direction="left"
                                        icon={{ className: 'icon-more_medium' }}
                                        open={dropdownActive}
                                        onClick={this.toggleActiveClass}
                                        onClose={this.toggleActiveClass}
                                    >
                                        <Dropdown.Menu>
                                            {jobClosed === false && (
                                                <>
                                                    {displaySize.width <
                                                        Constants.WIDTH_NO_MODAL && (
                                                        <Dropdown.Item
                                                            text={Translations.getStatic(
                                                                'writeEmail'
                                                            )}
                                                            key={'writeEmail'}
                                                            onClick={() =>
                                                                this.props.openModal(
                                                                    'SendEmailModal',
                                                                    {
                                                                        candidateIds: [candidateId],
                                                                        emailType:
                                                                            Constants.EMAIL_TYPE_TO_CANDIDATE,
                                                                    }
                                                                )
                                                            }
                                                        />
                                                    )}

                                                    <Dropdown.Item
                                                        text={Translations.getStatic(
                                                            'editCandidateData'
                                                        )}
                                                        key={'edit'}
                                                        onClick={() =>
                                                            this.props.openModal(
                                                                'CreateCandidateModal',
                                                                {
                                                                    candidateId: candidateId,
                                                                    jobId: candidate.jobId,
                                                                }
                                                            )
                                                        }
                                                    />
                                                </>
                                            )}

                                            {displaySize.width < Constants.WIDTH_NO_MODAL && (
                                                <Dropdown.Item
                                                    text={Translations.getStatic('forward')}
                                                    key={'forward'}
                                                    onClick={() =>
                                                        this.props.openModal('SendEmailModal', {
                                                            candidateIds: [candidateId],
                                                            emailType:
                                                                Constants.EMAIL_TYPE_TO_COLLEAGUE,
                                                        })
                                                    }
                                                />
                                            )}

                                            {isPhoneValid === true &&
                                                displaySize.width < Constants.WIDTH_NO_MODAL && (
                                                    <a
                                                        className="txt-navy item"
                                                        href={`tel:${phone}`}
                                                        onClick={() =>
                                                            this.props.storeCandidatePhoneCallIntoHistory(
                                                                candidateId
                                                            )
                                                        }
                                                    >
                                                        {Translations.getStatic('call')}
                                                    </a>
                                                )}

                                            <Dropdown.Item
                                                text={Translations.getStatic('printCandidateCard')}
                                                key={'printCandidateCard'}
                                                onClick={() =>
                                                    window.open(
                                                        Url.build('candidate-card', {
                                                            candidate: candidateId,
                                                        }),
                                                        '_blank'
                                                    )
                                                }
                                            />

                                            <Dropdown.Item
                                                text={Translations.getStatic('copyToAnotherJob')}
                                                key={'copy'}
                                                onClick={
                                                    candidate?.gdprAgreement
                                                        ?.hasConfirmedAgreement === true
                                                        ? () =>
                                                              this.props.openModal(
                                                                  'CopyCandidateModal',
                                                                  { candidateIds: [candidateId] }
                                                              )
                                                        : () =>
                                                              this.props.openModal(
                                                                  'CandidateGdprAgreementModal',
                                                                  {
                                                                      candidateId,
                                                                      gdprAgreement:
                                                                          candidate.gdprAgreement,
                                                                      copyCandidate: true,
                                                                      jobTitle: candidate.jobTitle,
                                                                  }
                                                              )
                                                }
                                            />

                                            <Dropdown.Item
                                                text={Translations.getStatic('moveToAnotherJob')}
                                                key={'move'}
                                                onClick={
                                                    candidate?.gdprAgreement
                                                        ?.hasConfirmedAgreement === true
                                                        ? () =>
                                                              this.props.openModal(
                                                                  'MoveCandidateModal',
                                                                  { candidateIds: [candidateId] }
                                                              )
                                                        : () =>
                                                              this.props.openModal(
                                                                  'CandidateGdprAgreementModal',
                                                                  {
                                                                      candidateId,
                                                                      gdprAgreement:
                                                                          candidate.gdprAgreement,
                                                                      moveCandidate: true,
                                                                      jobTitle: candidate.jobTitle,
                                                                  }
                                                              )
                                                }
                                            />

                                            {UserRights.hasTalentPool(loginManagerModel) === true &&
                                            candidate !== undefined &&
                                            candidate.jobId ===
                                                loginManagerModel.talentPool.talentPoolId ? (
                                                <Dropdown.Item
                                                    text={Translations.getStatic(
                                                        'removeFromTalentPool'
                                                    )}
                                                    key={'removeFromTalentPool'}
                                                    onClick={() =>
                                                        this.props.openRemoveFromTalentPoolDialog({
                                                            candidateId,
                                                            candidateName: candidate.candidateName,
                                                        })
                                                    }
                                                />
                                            ) : (
                                                <Dropdown.Item
                                                    text={Translations.getStatic(
                                                        'copyIntoTalentPool'
                                                    )}
                                                    key={'copyIntoTalentPool'}
                                                    onClick={
                                                        candidate?.gdprAgreement
                                                            .hasConfirmedAgreement === true
                                                            ? () =>
                                                                  this.props.openCopyIntoTalentPoolDialog(
                                                                      { candidateId }
                                                                  )
                                                            : () =>
                                                                  this.props.openModal(
                                                                      'CandidateGdprAgreementModal',
                                                                      {
                                                                          candidateId,
                                                                          gdprAgreement:
                                                                              candidate.gdprAgreement,
                                                                          copyIntoTalentPool: true,
                                                                          jobTitle:
                                                                              candidate.jobTitle,
                                                                      }
                                                                  )
                                                    }
                                                />
                                            )}

                                            <Dropdown.Item
                                                text={Translations.getStatic('anonymize')}
                                                key={'anonymize'}
                                                onClick={this.openAnonymizeCandidateDialog}
                                            />
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>

                                {this.state.anonymizeCandidateDialogOpen === true && (
                                    <AnonymizeCandidatesDialog
                                        infoLines={[
                                            Translations.getStatic(
                                                'confirmCandidateAnonymization'
                                            ) +
                                                ' ' +
                                                candidateName +
                                                '?',
                                        ]}
                                        isAnonymizingCandidates={this.props.isAnonymizingCandidates}
                                        sendConfirmation={
                                            this.state.sendAnonymizeConfirmationToCandidate
                                        }
                                        onAnonymize={() => this.anonymizeCandidate(candidateId)}
                                        onCancel={() => this.closeAnonymizeCandidateDialog()}
                                        onChangeSendConfirmation={() =>
                                            this.setState({
                                                sendAnonymizeConfirmationToCandidate:
                                                    !this.state
                                                        .sendAnonymizeConfirmationToCandidate,
                                            })
                                        }
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>

                {candidate !== undefined && events !== undefined ? (
                    <DetailCandidateNoModalContent
                        hasJobQuestionnaire={hasJobQuestionnaire}
                        candidate={candidate}
                        activeMenuItem={activeMenuItem}
                        attachmentsCount={attachmentsCount}
                        hasQuestionnaireResult={hasQuestionnaireResult}
                        questionnaireResult={questionnaireResult}
                        handleMenuItemClick={this.handleMenuItemClick}
                        candidateInbox={inbox}
                        isFetchingCandidateInbox={isFetchingCandidateInbox}
                        hasCompanyActiveInbox={loginManagerModel.hasCompanyActiveInbox}
                        isExternalVisitor={isExternalVisitor}
                    />
                ) : (
                    <DetailCandidateNoModalContentSkeleton />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loginManagerModel: Skeletons.getData(state.loginManagerModel, loginManagerModalSkeleton),
    jobModel: state.jobModel,
    displaySize: state.displaySize,
    noModalIsOpen: state.noModalLogic.noModalIsOpen,
    noModalProps: state.noModalLogic.noModalProps,
    noModalSecondLogic: state.noModalSecondLogic,
    candidateModel: state.candidateModel.fullDetail,
    candidateActionModel: state.candidateActionModel,
    isAnonymizingCandidates: state.isAnonymizingCandidates,
    isFetchingCandidateInbox: state.isFetchingCandidateInbox,
    isMovingCandidates: state.isMovingCandidates,
});

const mapDispatchToProps = {
    requestCandidateModel,
    requestCandidateEvents,
    requestCandidateHistory,
    requestCandidateInbox,
    closeNoModal,
    closeNoModalSecond,
    openModal,
    anonymizeCandidates,
    openCopyIntoTalentPoolDialog,
    setCandidateAsFavorite,
    unsetCandidateAsFavorite,
    resetFullCandidatesData,
    requestJobModel,
    openRemoveFromTalentPoolDialog,
    storeCandidatePhoneCallIntoHistory,
    setIsClickedCandidate,
};

DetailCandidateNoModal.propTypes = {
    closeNoModal: PropTypes.func.isRequired,
    candidateId: PropTypes.number.isRequired,
    candidateName: PropTypes.string.isRequired,
    attachmentsCount: PropTypes.number.isRequired,
    questionnaireResult: PropTypes.number,
};

DetailCandidateNoModal.defaultProps = {
    hasQuestionnaireResult: false,
    questionnaireResult: null,
    attachmentsCount: 0,
    candidateName: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailCandidateNoModal);
