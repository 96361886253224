import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import Divider from './Divider';
import Url from '../helpers/Url';
import MenuItem from './Menu/MenuItem';
import Header4MenuExtendedButton from './Header4MenuExtendedButton';
import Translations from '../helpers/Translations';
import User from '../helpers/User';
import UserRights from '../helpers/UserRights';
import Constants from '../helpers/Constants';
import HeaderHelper from '../helpers/components/HeaderHelper';
import Link from '../helpers/overridden/Link';
import Header4MenuAddColleagueExtendedButton from './Header4MenuAddColleagueExtendedButton';
import Header4MenuDetailSkeleton from '../skeletons/components/Header4MenuDetailSkeleton';
import Skeletons from '../helpers/Skeletons';
import { previousPage } from '../actions/HeaderActions';
import loginManagerModelSkeleton from '../skeletons/models/loginManagerModelSkeleton';

const Header4MenuDetail = (props) => {
    const [indexTabStopRender, setIndexTabStopRender] = useState(null);
    const location = useLocation();
    const dispatch = useDispatch();

    const TAB_CHAR_WIDTH = 8;
    const TAB_PADDING = 20;
    const ELLIPSIS_WIDTH = 30;
    const MIN_VISIBLE_TABS = 1;

    const findOverflowTabIndex = (navGrid) => {
        if (!displaySize.isMobile || !navGrid?.querySelectorAll) {
            return null;
        }

        const menuItemsStrings = HeaderHelper.getMenuItemsStrings();
        const menuItems = getMenuItems();
        const availableWidth = navGrid.offsetWidth - ELLIPSIS_WIDTH;
        let totalWidth = 0;

        for (let i = 0; i < menuItems.length; i++) {
            const itemText = menuItemsStrings[menuItems[i].action];
            const tabWidth = itemText.length * TAB_CHAR_WIDTH + TAB_PADDING;

            totalWidth += tabWidth;

            if (totalWidth > availableWidth) {
                return Math.max(MIN_VISIBLE_TABS, i);
            }
        }

        return null;
    };

    useEffect(() => {
        const calculateOverflow = () => {
            const navGrid = document.querySelector('.nav-grid__tabs');
            if (!navGrid) return;

            const newIndex = findOverflowTabIndex(navGrid);
            if (indexTabStopRender !== newIndex) {
                setIndexTabStopRender(newIndex);
            }
        };

        calculateOverflow();
        window.addEventListener('resize', calculateOverflow);

        return () => window.removeEventListener('resize', calculateOverflow);
    }, [props.displaySize.isMobile, findOverflowTabIndex, indexTabStopRender]);

    const getReferrer = () => {
        const searchParams = new URLSearchParams(location.search);
        return searchParams.get('referrer');
    };

    useEffect(() => {
        const referrer = getReferrer();
        const offerLink = props.jobModel.job?.offer?.offerLink ?? null;

        if (referrer === 'list_offers') {
            dispatch(previousPage(`/company_details.php?action=list_offers`));
        }

        if (referrer === 'offer' && offerLink !== null) {
            const url = new URL(offerLink);

            dispatch(previousPage(url.pathname));
        }
    }, [props.jobModel.job]);

    const getMenuItems = (clickablePipeline) => {
        let menuItems = [
            {
                action: 'pipeline',
                activeActions: ['job'],
                clickable: clickablePipeline,
                visible: true,
                popup:
                    clickablePipeline === true ? null : Translations.getStatic('pipelineDisabled'),
            },
            {
                action: 'candidates',
                activeActions: ['job'],
                clickable: true,
                visible: true,
            },
        ];

        if (User.getDefaultJobViewId(props.loginManagerModel) === Constants.JOB_VIEW_LIST) {
            menuItems = menuItems.reverse();
        }

        menuItems = menuItems.concat([
            {
                action: 'detail',
                activeActions: ['job'],
                clickable: true,
                visible: true,
            },
            {
                action: 'candidate-sources',
                activeActions: ['job'],
                clickable: true,
                visible: true,
            },
        ]);

        if (props.jobModel.job.hasStatistics) {
            menuItems.push({
                action: 'statistics',
                activeActions: ['job'],
                clickable: true,
            });
        }

        if (props.jobModel.settings.pipeline === null) {
            menuItems = menuItems.filter((item) => item.action !== 'pipeline');
        }

        if (User.isExternalUser(loginManagerModel) === true) {
            menuItems = menuItems.filter((item) => ['statistics'].includes(item.action) === false);
        }

        return menuItems;
    };

    const {
        backButton,
        loginManagerModel,
        jobModel,
        jobDetailHeader,
        isFetchingJobModel,
        displaySize,
    } = props;

    if (
        Skeletons.isSkeleton(loginManagerModel) === true ||
        Skeletons.isSkeleton(jobModel) === true ||
        jobModel.hasOwnProperty('job') === false
    ) {
        return <Header4MenuDetailSkeleton backButton={backButton} />;
    }

    const { job, candidates } = jobModel;

    const { addDate, validTill, stateId, id, endDate, isTalentPool } = job;

    const isExternalVisitor = User.isExternalVisitor(loginManagerModel, jobModel);

    const clickablePipeline = true;

    const hasActiveSomeSubscription = UserRights.hasActiveSomeSubscription(loginManagerModel);

    const ResponsiveTabs = () => {
        const menuItems = getMenuItems(clickablePipeline);
        const menuItemsStrings = HeaderHelper.getMenuItemsStrings();
        const tabStopIndex = indexTabStopRender || menuItems.length;

        return (
            <>
                {menuItems.slice(0, tabStopIndex).map((item) => (
                    <MenuItem
                        key={'tab-item-' + item.action}
                        action={item.action}
                        item={item}
                        jobId={jobModel.job.id}
                        menuItemsStrings={menuItemsStrings}
                    />
                ))}

                {menuItems.length > tabStopIndex ? (
                    <li className="tab__item">
                        <Dropdown
                            icon={{ className: 'ellipsis horizontal txt-white' }}
                            direction="left"
                        >
                            <Dropdown.Menu>
                                {menuItems.slice(tabStopIndex).map((item) => (
                                    <Dropdown.Item key={item.action}>
                                        <Link
                                            to={Url.build(
                                                item.action,
                                                jobModel.job.id === undefined
                                                    ? {}
                                                    : { job: jobModel.job.id }
                                            )}
                                            className="txt-navy"
                                            gtmTag="menu-link"
                                        >
                                            {menuItemsStrings[item.action]}
                                        </Link>
                                    </Dropdown.Item>
                                ))}
                                {isExternalVisitor === false &&
                                    hasActiveSomeSubscription === true &&
                                    displaySize.width <= Constants.RESOLUTION_MOBILE && (
                                        <Dropdown.Item>
                                            <Header4MenuAddColleagueExtendedButton jobId={id} />
                                        </Dropdown.Item>
                                    )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                ) : (
                    isExternalVisitor === false &&
                    hasActiveSomeSubscription === true &&
                    displaySize.width <= Constants.RESOLUTION_MOBILE && (
                        <li className="tab__item">
                            <Dropdown
                                icon={{ className: 'ellipsis horizontal txt-white' }}
                                direction="left"
                            >
                                <Dropdown.Menu>
                                    <Dropdown.Item>
                                        <Header4MenuAddColleagueExtendedButton jobId={id} />
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                    )
                )}
            </>
        );
    };

    const HeaderTitle = () => {
        let jobTitle;

        if (isFetchingJobModel === true) {
            jobTitle = jobDetailHeader === null ? job.jobTitle : jobDetailHeader.jobTitle;
        } else {
            jobTitle = job.jobTitle;
        }

        return jobTitle === undefined || jobTitle.trim() === '' ? (
            <h3>
                <em>{Translations.getStatic('missingPositionName') + ' (jobId: ' + id + ')'}</em>
            </h3>
        ) : (
            <h3 className="ellipsis">{jobTitle}</h3>
        );
    };

    return isFetchingJobModel === true ? (
        <Header4MenuDetailSkeleton backButton={backButton} />
    ) : (
        <div
            className={
                'template-header header-extended_dark' +
                (User.isSuperAdmin(loginManagerModel) ? ' admin' : '')
            }
        >
            <div className="header__row">
                <div className="col-left">
                    <div className="square-button">
                        {backButton.location.startsWith('mark') ? (
                            <Link to={backButton.location} gtmTag="menu-back-button">
                                {Translations.getStatic('back')}
                            </Link>
                        ) : (
                            <a href={backButton.location} gtmTag="menu-back-button">
                                {Translations.getStatic('back')}
                            </a>
                        )}
                    </div>
                    <div className="nav-grid">
                        <div className="nav-grid__title">
                            {isExternalVisitor === true && <Divider hidden className="size_12" />}
                            <HeaderTitle />
                        </div>

                        {isExternalVisitor === false && (
                            <ul className="nav-grid__tabs">
                                <ResponsiveTabs />
                            </ul>
                        )}
                    </div>
                </div>

                {isTalentPool === true || (
                    <div className="col-right">
                        {isExternalVisitor === false &&
                            displaySize.width > Constants.RESOLUTION_MOBILE && (
                                <Header4MenuAddColleagueExtendedButton jobId={id} />
                            )}

                        <Header4MenuExtendedButton
                            addDate={addDate}
                            validTill={validTill}
                            endDate={endDate}
                            stateId={stateId}
                            jobId={id}
                            isExternalVisitor={isExternalVisitor}
                            candidates={candidates}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    loginManagerModel: Skeletons.getData(state.loginManagerModel, loginManagerModelSkeleton),
    jobModel: Skeletons.getData(state.jobModel, []),
    jobDetailHeader: state.jobDetailHeader,
    isFetchingJobModel: state.isFetchingJobModel,
    displaySize: state.displaySize,
    backButton: state.backButton,
});

export default connect(mapStateToProps)(Header4MenuDetail);
