import {
    REQUEST_QUESTIONNAIRE_RESULTS,
    RECEIVE_QUESTIONNAIRE_RESULTS,
} from '../actions/QuestionnaireResultsActions';

export const questionnaireResultsModel = (state = false, action) => {
    switch (action.type) {
        case RECEIVE_QUESTIONNAIRE_RESULTS:
            return Object.assign({}, state, action.payload);
        default:
            return state;
    }
};

export const isFetchingQuestionnaireResults = (state = false, action) => {
    switch (action.type) {
        case REQUEST_QUESTIONNAIRE_RESULTS:
            return true;
        case RECEIVE_QUESTIONNAIRE_RESULTS:
            return false;
        default:
            return state;
    }
};
