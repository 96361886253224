import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import { closeModal } from '../../actions/ModalActions';
import SettingsPageCalendarSettingsMicrosoftCalendar from '../Settings/SettingsPageCalendarSettingsMicrosoftCalendar';
import { microsoftLogOut, setMicrosoftCalendar } from '../../actions/CalendarActions';
import Translations from '../../helpers/Translations';

class SettingsPageMicrosoftCalendarSettingsModal extends Component {
    state = {
        calendarId: null,
    };

    componentDidUpdate(prevProps) {
        if (
            prevProps.isSynchronizingCalendar === true &&
            this.props.isSynchronizingCalendar === false
        ) {
            this.props.closeModal();
        }
    }

    microsoftCalendarChanged = (calendarId) => {
        this.setState({
            calendarId,
        });
    };

    setCalendarId = () => {
        const formData = new FormData();

        formData.append('calendarId', this.state.calendarId);

        this.props.setMicrosoftCalendar(this.state.calendarId, formData);
    };

    render() {
        const {
            isOpen,
            closeModal,
            isLoggedInMicrosoft,
            isSettingCalendar,
            isSynchronizingCalendar,
        } = this.props;

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={closeModal}
                closeButtonText={Translations.getStatic('close')}
                actionButton={{
                    visible: isLoggedInMicrosoft === true && this.state.calendarId !== null,
                    text: Translations.getStatic('saveConnection'),
                    disabled: isSettingCalendar === true || isSynchronizingCalendar === true,
                    onClick: this.setCalendarId,
                }}
                entityOption={{
                    colorRed: true,
                    visible: isLoggedInMicrosoft === true,
                    text: Translations.getStatic('dSignOutMicrosoft'),
                    disabled: isSettingCalendar === true || isSynchronizingCalendar === true,
                    onClick: this.props.microsoftLogOut,
                }}
                title={Translations.getStatic('mCalSettings')}
                content={
                    <SettingsPageCalendarSettingsMicrosoftCalendar
                        {...this.props}
                        calendarId={this.state.calendarId}
                        microsoftCalendarChanged={(calendarId) =>
                            this.microsoftCalendarChanged(calendarId)
                        }
                    />
                }
            />
        );
    }
}

SettingsPageMicrosoftCalendarSettingsModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    isOpen: state.modalLogic.isOpen,
    user: state.settingsModel.user,
    microsoft: state.settingsModel.calendars.microsoft,
    isLoggedInMicrosoft: state.settingsModel.calendars.microsoft.isLoggedInMicrosoft || false,
    isSettingCalendar: state.isSettingCalendar || false,
    isSynchronizingCalendar: state.isSynchronizingCalendar || false,
});

const mapDispatchToProps = {
    closeModal,
    microsoftLogOut,
    setMicrosoftCalendar,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingsPageMicrosoftCalendarSettingsModal);
