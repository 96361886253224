import React from 'react';
import { SkeletonCircular, SkeletonRectangular } from '@profesia/adamui/components/common/skeleton';
import { Stack } from '@mui/material';

const DashboardNewCandidatesSkeleton = () => (
    <div className="card card-new-candidates">
        <Stack flex={1} direction="row" justifyContent="space-between">
            <Stack spacing={1} direction="row" alignItems="center">
                <SkeletonCircular size={48} />

                <Stack spacing={0.5}>
                    <SkeletonRectangular width={54} height={24} borderRadius={26} />
                    <SkeletonRectangular width={116} height={18} borderRadius={26} />
                </Stack>
            </Stack>
            <Stack spacing={-2} direction="row" alignItems="center">
                <SkeletonCircular size={40} />
                <SkeletonCircular size={40} />
                <SkeletonCircular size={40} />
                <SkeletonCircular size={40} />
                <SkeletonCircular size={40} />
                <SkeletonCircular size={40} />
                <SkeletonCircular size={40} />
                <SkeletonCircular size={40} />
            </Stack>
        </Stack>
    </div>
);

export default DashboardNewCandidatesSkeleton;
