import React from 'react';
import { SkeletonCircular, SkeletonRectangular } from '@profesia/adamui/components/common/skeleton';
import { Stack } from '@mui/material';

const JobCandidatesListSkeleton = ({ isVisibleControls = true }) => (
    <div className="list">
        {isVisibleControls && (
            <div className="list__controls_extended">
                <Stack pt={1} direction="row" alignItems="center" justifyContent="space-between">
                    <Stack spacing={1}>
                        <SkeletonRectangular width={244} height={18} borderRadius={26} />
                        <SkeletonRectangular width={116} height={10} borderRadius={26} />
                    </Stack>

                    <Stack spacing={2} direction="row" justifyContent="right">
                        <SkeletonRectangular width={120} height={48} borderRadius={4} />
                        <SkeletonRectangular width={120} height={48} borderRadius={4} />
                    </Stack>
                </Stack>
            </div>
        )}

        <div className="list__table candidate-list skeleton">
            <Stack spacing={3} mt={isVisibleControls ? 1 : 3 }>
                {[...Array(20)].map((e, i) => (
                    <Stack flex={1} spacing={2} direction="row" alignItems="center" key={i}>
                        <SkeletonCircular size={18} />
                        <Stack flex={1}>
                            <SkeletonRectangular height={10} borderRadius={26} />
                        </Stack>
                    </Stack>
                ))}
            </Stack>
        </div>
    </div>
);

export default JobCandidatesListSkeleton;
