import React from 'react';
import WebSocketWatcher from '../../helpers/WebSocketWatcher';
import WebSocketToastMessage from './WebSocketToastMessage';

const JobChangeWebSocket = ({
    job,
    mercurePublishUrl,
    candidateModel,
    onRequestJobModel,
    onUpdateCandidate,
}) => (
    <WebSocketToastMessage
        key={WebSocketWatcher.buildTopic(WebSocketWatcher.TOPIC_CHANGE_JOB, { jobId: job.id })}
        topic={WebSocketWatcher.buildTopic(WebSocketWatcher.TOPIC_CHANGE_JOB, { jobId: job.id })}
        mercurePublishUrl={mercurePublishUrl}
        update={() => {
            onRequestJobModel(job.id);

            if (candidateModel.fullDetailId !== null) {
                onUpdateCandidate(candidateModel.fullDetailId);
            }
        }}
    />
);

export default JobChangeWebSocket;
