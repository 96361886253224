import React from 'react';
import { connect } from 'react-redux';
import Image from './Image';
import world from '../../img/icons/world.svg';
import Translations from '../helpers/Translations';

const Header4MenuPublishPositionButton = ({ displaySize }) => {
    if (displaySize.isMobile) {
        return <span className="txt-navy">{Translations.getStatic('publishPosition')}</span>;
    } else {
        return (
            <div className="extended-action">
                <div className="extended-action__icon-status">
                    <Image size="tiny" src={world} />
                </div>
                <div className="position-publish-button">{Translations.getStatic('publishPosition')}</div>
            </div>
        );
    }
};

const mapStateToProps = (state) => ({
    displaySize: state.displaySize,
});

export default connect(mapStateToProps, null)(Header4MenuPublishPositionButton);
