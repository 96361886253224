import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, styled } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import { AiStarIcon } from '@profesia/adamui/components/common/icons';
import { TypographySpan } from '@profesia/adamui/components/common/typography';
const AiTag = ({ text, disabled }) => {
    return (_jsxs(TagWrapper, { disabled: disabled, children: [_jsx(AiStarIcon, {}), _jsx(TypographySpan, { children: text })] }));
};
const TagWrapper = styled(Stack, { shouldForwardProp: (prop) => prop != 'disabled' })(({ theme: { spacing, palette }, disabled }) => ({
    borderRadius: spacing(0.5),
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: getColor(palette.neutralLight5),
    backgroundColor: getColor(palette.white),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: spacing(0.5),
    width: 'fit-content',
    padding: spacing(0.25, 1),
    opacity: disabled ? 0.5 : 1,
}));
export default AiTag;
