export const REQUEST_EMAIL_TEMPLATES_SETTINGS_MODEL = 'REQUEST_EMAIL_TEMPLATES_SETTINGS_MODEL';
export const RECEIVE_EMAIL_TEMPLATES_SETTINGS_MODEL = 'RECEIVE_EMAIL_TEMPLATES_SETTINGS_MODEL';
export const PROCESS_EMAIL_TEMPLATE = 'PROCESS_EMAIL_TEMPLATE';
export const PROCESS_EMAIL_TEMPLATE_SUCCESS = 'PROCESS_EMAIL_TEMPLATE_SUCCESS';
export const PROCESS_EMAIL_TEMPLATE_FAILURE = 'PROCESS_EMAIL_TEMPLATE_FAILURE';
export const REMOVE_EMAIL_TEMPLATE = 'REMOVE_EMAIL_TEMPLATE';
export const REMOVE_EMAIL_TEMPLATE_SUCCESS = 'REMOVE_EMAIL_TEMPLATE_SUCCESS';
export const REMOVE_EMAIL_TEMPLATE_FAILURE = 'REMOVE_EMAIL_TEMPLATE_FAILURE';

export const requestEmailTemplatesSettingsModel = () => ({
    type: REQUEST_EMAIL_TEMPLATES_SETTINGS_MODEL,
    meta: {
        requestRoute: 'v1/email-template/model/list',
        onRequestSuccess: RECEIVE_EMAIL_TEMPLATES_SETTINGS_MODEL,
    },
});

export const processEmailTemplate = (formData, id, savingMode) => ({
    type: PROCESS_EMAIL_TEMPLATE,
    payload: {
        id: id,
        savingMode: savingMode,
    },
    meta: {
        requestRoute: 'v1/email-template/action/process',
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: PROCESS_EMAIL_TEMPLATE_SUCCESS,
        onRequestFailure: PROCESS_EMAIL_TEMPLATE_FAILURE,
    },
});

export const removeEmailTemplate = (id) => ({
    type: REMOVE_EMAIL_TEMPLATE,
    payload: {
        id: id,
    },
    meta: {
        requestRoute: 'v1/email-template/action/archive/' + id,
        requestMethod: 'POST',

        onRequestSuccess: REMOVE_EMAIL_TEMPLATE_SUCCESS,
        onRequestFailure: REMOVE_EMAIL_TEMPLATE_FAILURE,
    },
});
