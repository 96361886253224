import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Box, styled } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import { useMobile } from '@profesia/adamui/utils/responsive';
import Avatar from '@profesia/adamui/components/common/Avatar';
import { Typography } from '@profesia/adamui/components/common/typography';
import { LinkToRoute } from '@profesia/adamui/components/common/Link';
import CandidateFooter from '@profesia/adamui/components/common/card/CardFooter/variants/Candidate';
import CardContainer from '@profesia/adamui/components/common/card/CardContainer';
import WorkExperience from './WorkExperience';
import SoughtJobSection from './SoughtJob';
import LastLogin from './LastLogin';
import ReportedCandidateInfoBox from './ReportedCandidateInfoBox';
import AdditionalItems from './AdditionalItems';
import AiTags from './AiTags';
// @TODO nejak odtialto vyclenit LinkToRoute, lebo to zavadza zavislost nad routrom (syncnut stav s Milanom)
const CardCandidate = ({ candidate, variant, footerContent, dataCy, isSelected, navigationProps, reportedCandidateContent, tagTooltipText, }) => {
    const { city, isReported, age, experiences, id, fullName, seen, soughtJobs, soughtJobLocations, revealedAt, lastLogin, additionalHighlightedItems, cvSummaryTags, } = candidate;
    const isMobile = useMobile();
    const containerProps = {
        isSelected,
        component: LinkToRoute,
        ...navigationProps,
    };
    return (_jsx(CardCandidateContainer, { "data-cy": dataCy, ...containerProps, children: _jsxs(Stack, { height: "100%", direction: "column", children: [_jsx(HeaderContainer, { isMobile: isMobile, children: _jsxs(Stack, { display: "flex", direction: "row", alignItems: "flex-start", justifyContent: "space-between", children: [_jsxs(Box, { display: "flex", gap: 2, flexWrap: "wrap", children: [_jsx(AvatarStyled, { candidate: candidate }), _jsxs(Stack, { direction: "column", gap: isMobile ? 0.5 : 1, children: [_jsx(FullName, { variant: "h3Medium", seen: seen, children: fullName }), _jsxs(Stack, { direction: "row", gap: 1, children: [city || age ? (_jsx(Typography, { color: "primaryNavy", children: [city, age].filter((item) => item).join(', ') })) : null, _jsx(LastLogin, { lastLogin: lastLogin })] })] })] }), _jsx(Box, { children: isReported ? (_jsx(ReportedCandidateInfoBox, { ...reportedCandidateContent })) : null })] }) }), _jsxs(Stack, { mt: 1.5, mr: 4, ml: isMobile ? 2 : 11, children: [_jsxs(Stack, { spacing: 1.5, mb: 2, children: [_jsx(WorkExperience, { variant: variant, workExperiences: experiences?.items, noExperienceText: experiences?.noExperienceText }), _jsx(SoughtJobSection, { items: soughtJobs?.positions ?? null, title: soughtJobs?.title, variant: variant }), _jsx(SoughtJobSection, { items: soughtJobLocations?.locations ?? null, title: soughtJobLocations?.title, variant: variant }), _jsx(AdditionalItems, { items: additionalHighlightedItems?.items, title: additionalHighlightedItems?.title, variant: variant }), _jsx(AiTags, { tags: cvSummaryTags, tooltipText: tagTooltipText, variant: variant })] }), _jsx(CandidateFooter, { id: id, variant: variant, revealedAt: revealedAt, ...footerContent })] })] }) }));
};
const CardCandidateContainer = styled(CardContainer, {
    shouldForwardProp: (propName) => propName !== 'isSelected',
})(({ isSelected, theme: { palette: { neutralLight4, neutralLight0, primaryBlue }, }, }) => ({
    width: '100%',
    position: 'relative',
    borderColor: getColor(neutralLight4),
    outline: isSelected ? `2px solid ${getColor(primaryBlue)}` : undefined,
    outlineOffset: isSelected ? -2 : undefined,
    backgroundColor: getColor(neutralLight0),
    boxSizing: 'content-box',
    '& > .MuiBox-root': {
        position: 'absolute',
        top: -2,
        left: 11,
    },
    '&:hover .MuiTypography-h3Medium': {
        borderBottomStyle: 'solid',
    },
}));
const HeaderContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'isMobile' })(({ isMobile, theme: { spacing } }) => ({
    padding: spacing(2, 4, 0, isMobile ? 2 : 3),
}));
const AvatarStyled = styled(Avatar)(() => ({
    marginTop: 4,
}));
const FullName = styled(Typography, { shouldForwardProp: (prop) => prop !== 'seen' })(({ seen, theme: { palette: { primaryBlue, extendedRoyal2 }, }, }) => ({
    width: 'fit-content',
    color: getColor(seen ? extendedRoyal2 : primaryBlue),
    borderBottom: '1px dashed',
    height: 28,
}));
export default CardCandidate;
