export const REQUEST_SETTINGS_MODEL = 'REQUEST_SETTINGS_MODEL';
export const RECEIVE_SETTINGS_MODEL = 'RECEIVE_SETTINGS_MODEL';
export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';
export const SET_NEW_CANDIDATES_EMAILS = 'SET_NEW_CANDIDATES_EMAILS';
export const SET_NEW_CANDIDATES_EMAILS_SUCCESS = 'SET_NEW_CANDIDATES_EMAILS_SUCCESS';
export const SET_NEW_CANDIDATES_EMAILS_FAILURE = 'SET_NEW_CANDIDATES_EMAILS_FAILURE';
export const SET_USER_DEFAULT_JOB_VIEW = 'SET_USER_DEFAULT_JOB_VIEW';
export const SET_USER_DEFAULT_JOB_VIEW_SUCCESS = 'SET_USER_DEFAULT_JOB_VIEW_SUCCESS';
export const SET_USER_DEFAULT_JOB_VIEW_FAILURE = 'SET_USER_DEFAULT_JOB_VIEW_FAILURE';
export const SET_USER_DEFAULT_PUBLIC_NOTES = 'SET_USER_DEFAULT_PUBLIC_NOTES';
export const SET_USER_DEFAULT_PUBLIC_NOTES_SUCCESS = 'SET_USER_DEFAULT_PUBLIC_NOTES_SUCCESS';
export const SET_USER_DEFAULT_PUBLIC_NOTES_FAILURE = 'SET_USER_DEFAULT_PUBLIC_NOTES_FAILURE';
export const SET_USER_EVENT_TO_EMAIL_FORWARDING = 'SET_USER_EVENT_TO_EMAIL_FORWARDING';
export const SET_USER_EVENT_TO_EMAIL_FORWARDING_SUCCESS =
    'SET_USER_EVENT_TO_EMAIL_FORWARDING_SUCCESS';
export const SET_USER_EVENT_TO_EMAIL_FORWARDING_FAILURE =
    'SET_USER_EVENT_TO_EMAIL_FORWARDING_FAILURE';

export const requestSettingsModel = () => ({
    type: REQUEST_SETTINGS_MODEL,

    meta: {
        requestRoute: 'v1/settings/model/detail',
        onRequestSuccess: RECEIVE_SETTINGS_MODEL,
    },
});

export const setUserLanguage = (languageId, languageCode, formData) => ({
    type: SET_USER_LANGUAGE,

    payload: {
        languageId,
    },

    meta: {
        requestRoute: 'v1/user/action/set-language',
        requestMethod: 'POST',

        requestBody: formData,

        requestData: {
            language: languageCode,
        },
    },
});

export const setNewCandidatesEmails = (
    newCandidatesEmails,
    newCandidatesEmailsBefore,
    formData
) => ({
    type: SET_NEW_CANDIDATES_EMAILS,

    payload: {
        newCandidatesEmails,
        newCandidatesEmailsBefore,
    },

    meta: {
        requestRoute: 'v1/user/action/set-new-candidates-emails',
        requestMethod: 'POST',

        requestBody: formData,

        onRequestSuccess: SET_NEW_CANDIDATES_EMAILS_SUCCESS,
        onRequestFailure: SET_NEW_CANDIDATES_EMAILS_FAILURE,
    },
});

export const setUserDefaultJobView = (defaultJobViewId, formData) => ({
    type: SET_USER_DEFAULT_JOB_VIEW,

    payload: {
        defaultJobViewId,
    },

    meta: {
        requestRoute: 'v1/user/action/set-default-job-view',
        requestMethod: 'POST',

        requestBody: formData,

        onRequestSuccess: SET_USER_DEFAULT_JOB_VIEW_SUCCESS,
        onRequestFailure: SET_USER_DEFAULT_JOB_VIEW_FAILURE,
    },
});

export const setUserDefaultPublicNotes = (defaultPublicNotes, formData) => ({
    type: SET_USER_DEFAULT_PUBLIC_NOTES,

    payload: {
        defaultPublicNotes,
    },

    meta: {
        requestRoute: 'v1/user/action/set-default-public-notes',
        requestMethod: 'POST',

        requestBody: formData,

        onRequestSuccess: SET_USER_DEFAULT_PUBLIC_NOTES_SUCCESS,
        onRequestFailure: SET_USER_DEFAULT_PUBLIC_NOTES_FAILURE,
    },
});

export const setUserEventToEmailForwarding = (active, formData) => ({
    type: SET_USER_EVENT_TO_EMAIL_FORWARDING,

    payload: {
        active,
    },

    meta: {
        requestRoute: 'v1/user/action/set-event-to-email-forwarding',
        requestMethod: 'POST',

        requestBody: formData,

        onRequestSuccess: SET_USER_EVENT_TO_EMAIL_FORWARDING_SUCCESS,
        onRequestFailure: SET_USER_EVENT_TO_EMAIL_FORWARDING_FAILURE,
    },
});
