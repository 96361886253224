import {
    DELETE_USER_OUT_OF_OFFICE_EMAIL,
    DELETE_USER_OUT_OF_OFFICE_EMAIL_FAILURE,
    DELETE_USER_OUT_OF_OFFICE_EMAIL_SUCCESS,
    RECEIVE_OUT_OF_OFFICE_EMAIL_MODEL,
    REQUEST_OUT_OF_OFFICE_EMAIL_MODEL,
    SAVE_USER_OUT_OF_OFFICE_EMAIL,
    SAVE_USER_OUT_OF_OFFICE_EMAIL_FAILURE,
    SAVE_USER_OUT_OF_OFFICE_EMAIL_SUCCESS,
    SET_OUT_OF_OFFICE_EMAIL_PARAM,
} from '../actions/OutOfOfficeEmailActions';
import { MODAL_SECOND_OPEN } from '../actions/ModalActions';
import WysiwygHelper from '../helpers/components/WysiwygHelper';
import User from '../helpers/User';
import {
    SCHEDULE_CANDIDATE_EVENT,
    SCHEDULE_CANDIDATE_EVENT_FAILURE,
    SCHEDULE_CANDIDATE_EVENT_SUCCESS,
} from '../actions/ActionWithCandidateActions';

export const isSavingUserOutOfOfficeEmail = (state = false, action) => {
    switch (action.type) {
        case SAVE_USER_OUT_OF_OFFICE_EMAIL:
            return true;

        case SAVE_USER_OUT_OF_OFFICE_EMAIL_SUCCESS:
        case SAVE_USER_OUT_OF_OFFICE_EMAIL_FAILURE:
            return false;

        default:
            return false;
    }
};

export const isDeletingUserOutOfOfficeEmail = (state = false, action) => {
    switch (action.type) {
        case DELETE_USER_OUT_OF_OFFICE_EMAIL:
            return true;

        case DELETE_USER_OUT_OF_OFFICE_EMAIL_SUCCESS:
        case DELETE_USER_OUT_OF_OFFICE_EMAIL_FAILURE:
            return false;

        default:
            return false;
    }
};

export const isFetchingOutOfOfficeEmailModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_OUT_OF_OFFICE_EMAIL_MODEL:
            return true;
        case RECEIVE_OUT_OF_OFFICE_EMAIL_MODEL:
            return false;
        default:
            return state;
    }
};

const defaultState = {
    isSuccess: false,
    outOfOfficeEmailData: {
        emailData: {
            bcc: [],
        },
    },
};

export const outOfOfficeEmailModel = (state = defaultState, action) => {
    switch (action.type) {
        case MODAL_SECOND_OPEN:
            if (
                action.hasOwnProperty('payload') === true &&
                ['EditOutOfOfficeEmailModal'].includes(action.payload.modalType) === true
            ) {
                return defaultState;
            }

            return state;

        case REQUEST_OUT_OF_OFFICE_EMAIL_MODEL:
            return {
                ...state,

                isSuccess: false,

                outOfOfficeEmailData: {
                    ...action.payload.outOfOfficeEmailData,
                    emailData: {
                        bcc: action.payload.bcc,
                        senderId: action.payload.senderId,
                    },
                },
            };

        case RECEIVE_OUT_OF_OFFICE_EMAIL_MODEL:
            const { text } = action.payload.outOfOfficeEmailData.emailData;

            const converted = WysiwygHelper.convertOldToNewText(text);

            return {
                ...state,
                ...action.payload,

                outOfOfficeEmailData: {
                    ...action.payload.outOfOfficeEmailData,
                    emailData: {
                        ...action.payload.outOfOfficeEmailData.emailData,
                        text: converted,
                    },
                },
            };

        case SET_OUT_OF_OFFICE_EMAIL_PARAM:
            return {
                ...state,
                outOfOfficeEmailData: {
                    ...state.outOfOfficeEmailData,
                    ...action.payload.param,
                },
            };

        case DELETE_USER_OUT_OF_OFFICE_EMAIL:
        case SAVE_USER_OUT_OF_OFFICE_EMAIL:
            return {
                ...state,
                isSuccess: false,
            };

        case DELETE_USER_OUT_OF_OFFICE_EMAIL_SUCCESS:
        case SAVE_USER_OUT_OF_OFFICE_EMAIL_SUCCESS:
            return {
                ...state,
                isSuccess: true,
            };
        default:
            return state;
    }
};
