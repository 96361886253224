import Constants from '../Constants';

export default class CloseJobHelper {
    /**
     *
     * @param candidates
     * @param candidatesEmailSendingState
     * @returns {*[]|*[]}
     */
    static getCandidateIdsForEmailSending(candidates, candidatesEmailSendingState) {
        let candidatesForSend = [];

        switch (candidatesEmailSendingState) {
            case Constants.CLOSE_JOB_EMAIL_SENDING_ACTIVE:
            case Constants.CLOSE_JOB_EMAIL_SENDING_CUSTOM:
                candidatesForSend = candidates.filter(
                    (candidate) =>
                        candidate.isAnonymized === false &&
                        candidate.stateId === Constants.CANDIDATE_STATE_ACTIVE &&
                        candidate.stage.id !== Constants.CANDIDATE_STAGE_ACCEPTED_ID
                );
                break;
            case Constants.CLOSE_JOB_EMAIL_SENDING_REFUSED:
                candidatesForSend = candidates.filter(
                    (candidate) =>
                        candidate.isAnonymized === false &&
                        candidate.stateId === Constants.CANDIDATE_STATE_REFUSED
                );
                break;
            case Constants.CLOSE_JOB_EMAIL_SENDING_ALL:
                candidatesForSend = candidates.filter(
                    (candidate) =>
                        candidate.isAnonymized === false &&
                        (candidate.stateId === Constants.CANDIDATE_STATE_REFUSED ||
                            candidate.stage.id !== Constants.CANDIDATE_STAGE_ACCEPTED_ID)
                );
                break;
        }

        return candidatesForSend.map((candidate) => candidate.id);
    }

    /**
     *
     * @returns {*}
     */
    static getDefaultEmailSendingState() {
        return Constants.CLOSE_JOB_EMAIL_SENDING_ACTIVE;
    }
}
